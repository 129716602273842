import React from 'react';

export interface Props {
  pageId: string
}

const ProfileSetupLayout: React.FC<Props> = (props) => {
  return <>{props.children}</>;
};

export default ProfileSetupLayout;
