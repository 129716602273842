import { filter, find, get, isEmpty, map } from "lodash";
import constants from "../constants";
import { IFolder } from "../contexts/DocumentContext";
import { IDocument, IDocumentField, IUser } from "../firebaseTypes";
import { format, isPast } from "date-fns";
import { ISelectedDocuments } from "../contexts/DocumentGalleryContext";
import { toDateTime } from "./dateTimeUtils";

export function toDocTypeSlug(docType?: string): string {
  return `${(docType || "").toLowerCase().split(" ").join("-")}`;
}

export function findFolderFromSlug(
  docTypeSlug: string,
  folders: IFolder[]
): IFolder | undefined {
  return find(folders, (f) => {
    return toDocTypeSlug(f.title) === docTypeSlug ? true : false;
  });
}

export function getDocType(response: any) {
  return get(response, "result.doc_type") || constants.docTypes.null;
}

export function getFieldValue(field?: IDocumentField) {
  if (field) {
    let value: any = field.manual
      ? field.manual_value
      : field.manual_value || field.value || field.raw_value;
    return value;
  }
}

// deprecated
export function getFieldDisplayValueFromSeconds(seconds: number): string {
  if (seconds) {
    const epoch = toDateTime(seconds) as Date;
    // value = formatDistance(epoch, new Date(), { addSuffix: true })
    return format(epoch, constants.dateFormat);
    // return formatISO(epoch, { format: "basic" })
  }
  return "";
}

export function getDisplayValue(value: any, field?: IDocumentField): string {
  if (value === null || value === undefined) {
    return value;
  }
  if (isValidDateString(value)) {
    // eslint-disable-next-line no-useless-escape
    const date = new Date(value.replace(/-/g, '\/'))
    return format(date, constants.dateFormat)
  }

  // let it be
  if (
    typeof value === "object" &&
    (value.hasOwnProperty("seconds") || value.hasOwnProperty("_seconds"))
  ) {
    value = getFieldDisplayValueFromSeconds(value.seconds);
  } else if (field?.address) {
    value = value.toString() + " " + (field.apartment || "");
  } else {
    value = value.toString();
  }
  return value;
}

export function getFieldDisplayValue(field?: IDocumentField): string {
  let value = getFieldValue(field);
  return getDisplayValue(value, field)
}

export function isExpired(field?: IDocumentField): boolean {
  const value = getFieldValue(field);
  const parsedDate = isValidDateString(value) ? new Date(value.replace(/-/g, '\/')) : null;

  if (parsedDate === null) {
    return false;
  }
  return isPast(parsedDate);
}

export function getExpirySeconds(document?: IDocument): number {
  if (!document) return 0;
  const field =
    findField(document.doc_info, "Expiry Date") ||
    findField(document.doc_info, "Issue Date");
  if (field) {
    const value = getFieldValue(field);
    if (value === null || value === undefined) {
      return 0;
    }
    if (typeof value === "object" && value.seconds) {
      return value.seconds;
    }
  }
  return 0;
}

export function getSelectedDocuments(
  selectedDocuments: ISelectedDocuments,
  allDocs: IDocument[]
): IDocument[] {
  return filter(allDocs, (doc) => {
    if (selectedDocuments[doc.id]) return true;
    return false;
  });
}

export function getSelectedDocumentIds(
  selectedDocuments: ISelectedDocuments
): string[] {
  let ids: string[] = [];
  for (const key in selectedDocuments) {
    if (Object.prototype.hasOwnProperty.call(selectedDocuments, key)) {
      if (!key.includes("-all") && selectedDocuments[key]) {
        ids.push(key);
      }
    }
  }
  return ids;
}

export function countSelectedDocuments(
  selectedDocuments: ISelectedDocuments
): number {
  let count = 0;
  for (const key in selectedDocuments) {
    if (Object.prototype.hasOwnProperty.call(selectedDocuments, key)) {
      if (!key.includes("-all") && selectedDocuments[key]) {
        count += 1;
      }
    }
  }
  return count;
}

export function findField(
  doc_info: IDocumentField[] | undefined,
  title: string
): IDocumentField | undefined {
  return find(doc_info, (info) => info.title === title);
}

export function findFieldDisplayValue(
  doc_info: IDocumentField[] | undefined,
  field_title: string
) {
  const field = findField(doc_info, field_title);
  if (field) {
    return getFieldDisplayValue(field);
  }
  return null;
}

export function findFoldersFromTitle(
  folders: IFolder[],
  title: string
): IFolder[] {
  return filter(folders, (f) =>
    (f.title || "").toLowerCase()?.trim().includes(title)
  );
}

export function isPassport(doc: IDocument) {
  return (doc.doc_type || "").toLowerCase().includes("passport");
}

export function isExpiryField(info: IDocumentField): boolean {
  return (info.title === constants.document.expiryFieldTitle1 || info.title === constants.document.expiryFieldTitle2 || info.title.startsWith(constants.document.expiryFieldTitle3) || info.title.startsWith(constants.document.expiryFieldTitle4))
}

// check from title
export function isDateField(info: IDocumentField): boolean {
  return info.title.toLowerCase().includes('date') || isExpiryField(info) || info.title.toLowerCase().includes('valid from')
}

function findExpiryField(doc_info?: IDocumentField[]): IDocumentField | undefined {
  return find(doc_info, info => isExpiryField(info))
}

export function getExpiryFieldValue(doc_info?: IDocumentField[]): any {
  const expiryDateField = findExpiryField(doc_info)
  if (expiryDateField) {
    return getFieldValue(expiryDateField)
  }
  return null
}

export function getSimplifiedDocFormat(doc_format?: string): "pdf" | "image" {
  if (doc_format === "image/jpeg" ||
    doc_format === "image/png" ||
    doc_format === "image/jpg") {
    return "image"
  } else {
    return "pdf"
  }
}

export function removePrefix(str: string) {
  const regEx = /^documents\//;
  return (str || "").replace(regEx, "");
}

export function sanitizeField(field: IDocumentField): IDocumentField {
  let manual_value = field.manual_value;
  let raw_value = field.raw_value;
  let value = field.value;
  // sanatize manual
  if (
    field.manual &&
    field.manual_value &&
    typeof field.manual_value === "object" &&
    field.manual_value.hasOwnProperty("_seconds")
  ) {
    // check if it is date && corrupted with value _seconds
    manual_value = {
      ...manual_value,
      seconds: field.manual_value._seconds,
    };
  }

  // sanatize raw_value
  if (
    field.raw_value &&
    typeof field.raw_value === "object" &&
    field.raw_value.hasOwnProperty("_seconds")
  ) {
    raw_value = {
      ...raw_value,
      seconds: field.raw_value._seconds,
    };
  }

  // sanatize value
  if (
    field.value &&
    typeof field.value === "object" &&
    field.value.hasOwnProperty("_seconds")
  ) {
    value = {
      ...value,
      seconds: field.value._seconds,
    };
  }

  return {
    ...field,
    manual_value,
    raw_value,
    value,
  };
}

export function sanitizeDocument(d: IDocument, user: IUser): IDocument {
  return {
    ...d,
    doc_info: map(d.doc_info, (field) => sanitizeField(field)),
    doc_type: d.doc_type == null ? constants.docTypes.null : d.doc_type,
    doc_name: removePrefix(d.doc_name),
    doc_name_autogenerated: generateDocName(d, user as IUser),
  };
}

export function sanitizeDocuments(docs: IDocument[], user: IUser): IDocument[] {
  return map(docs, (d) => sanitizeDocument(d, user));
}

// This functions are used to generate document name

export function _sanitizeDocType(docType: string) {
  let _docType = (docType || '').trim().toLowerCase()
  if (_docType.includes('drivers license')) {
    _docType = 'dl'
  }
  if (_docType.includes('i-797')) {
    _docType = 'i797'
  }
  if (_docType.includes('i-94')) {
    _docType = 'i94'
  }
  if (_docType.includes('i-20')) {
    _docType = 'i20'
  }
  return _docType.replace(/\s|\-/gm, '_')
}

export function _sanitizeExpiryDate(d: Date) {
  try {
    const month = d.getMonth() + 1
    const monthPrefix = month < 10 ? '0' : ''
    const year = d.getFullYear()
    return `${monthPrefix}${month}_${year}`
  } catch (err) {
    console.log("undo: Not a valid date value:", d)
    console.error(err)
    return null
  }
}

export function _sanitizeName(firstName: string, lastName: string) {
  if (firstName && firstName.length > 0 && lastName && lastName.length > 0) {
    return firstName.toLowerCase() + lastName.toLowerCase();

  }
  const part1 = firstName.length > 0 ? firstName[0] : ''
  const part2 = lastName
  return `${part1}${part2}`.toLowerCase()
}

export function isValidDateString(dateStr: string): boolean {
  var regex = /^[0-9]{4}-[0-9]{2}-[0-9]{2}$/ // => YYYY-MM-DD
  return typeof dateStr === "string" && !isEmpty(dateStr) && regex.test(dateStr) && (new Date(dateStr)).toString() !== "Invalid Date"
}

export function parseDate(dateStr: string): Date | null {
  if (isValidDateString(dateStr)) {
    const [year, month, day] = dateStr.split(/-|\//) // split with either - or /
    try {
      const yearInt = parseInt(year)
      const monthInt = parseInt(month) - 1
      const dayInt = parseInt(day)
      return new Date(yearInt, monthInt, dayInt)
    } catch (err) {
      console.error(err)
      return null
    }
  }
  return null
}

// For decending sort
function compare(a: any, b: any) {
  if (a === undefined && b === undefined) {
    return 0;
  }
  if (a === undefined && b !== undefined) {
    return 1;
  }
  if (a !== undefined && b === undefined) {
    return -1;
  }
  return b - a;
}

export function sortDocuments(documents: IDocument[]): IDocument[] {
  let docsCopy = map(documents, (doc) => ({ ...doc }));
  docsCopy.sort((v1, v2) => {
    const expiryDateV1 = getExpiryFieldValue(v1.doc_info);
    const expiryDateV2 = getExpiryFieldValue(v2.doc_info);

    const x1 = isValidDateString(expiryDateV1)
      ? new Date(expiryDateV1).getTime()
      : 0;
    const x2 = isValidDateString(expiryDateV2)
      ? new Date(expiryDateV2).getTime()
      : 0;

    // const e1 = getExpirySeconds(v1);
    // const e2 = getExpirySeconds(v2);?
    return compare(x1, x2);
  });
  return docsCopy;
}

export function generateDocName(doc: IDocument, user: IUser): string {
  const validateDocType = doc.doc_type && doc.doc_type.length > 0;
  
  // handle case for others doc_type
  if (!validateDocType) {
    return doc['local_file_name'] || ""
  }

  const expiryDate = getExpiryFieldValue(doc.doc_info)
  const date = new Date(expiryDate);

  const validateExpiryDate = isValidDateString(expiryDate)
  const validateFirstName = user?.firstName && user.firstName.length > 0
  const validateLastName = user?.lastName && user.lastName.length > 0

  if (validateDocType && validateExpiryDate && validateFirstName && validateLastName) {
    const part1 = _sanitizeDocType(doc.doc_type)
    const part2 = _sanitizeExpiryDate(date)
    const part3 = _sanitizeName(user.firstName[0], user.lastName[0])
    
    return `${part1}_${part2}_${part3}`
  } else if (validateDocType && validateFirstName && validateLastName) {
    const part1 = _sanitizeDocType(doc.doc_type)
    const part2 = _sanitizeName(user.firstName[0], user.lastName[0])
    return `${part1}_${part2}`
  }
  return ""
}
 
export function getDocName(doc: IDocument): string {
  return doc.f_manually_edited_doc_name ? doc.doc_name : doc.doc_name_autogenerated || doc.doc_name
}

export function sortFoldersByPriority(documents: IFolder[]): IFolder[] {
  const priorityMap = new Map<string, number>();

  // Create a map for quick lookup of priority indices
  constants.priorityOrder.forEach((docType, index) => {
    priorityMap.set(docType, index);
  });

  // Compare function for sorting
  const compareDocs = (a: IFolder, b: IFolder) => {
    const indexA = priorityMap.get(a.title);
    const indexB = priorityMap.get(b.title);

    if (indexA !== undefined && indexB !== undefined) {
      return indexA - indexB;
    }
    if (indexA !== undefined) {
      return -1; // a has priority, so it should come before b
    }
    if (indexB !== undefined) {
      return 1; // b has priority, so it should come before a
    }
    return 0; // both have no priority, maintain their order
  };

  // Sort the documents array using the compare function
  return documents.sort(compareDocs);
}
