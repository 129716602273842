import React from "react";
import {
  Container,
  Grid,
  Hidden,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Stack,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import Illustration from "../../assets/images/illustrations/profile_setup_first.svg";
import ProfileIllustration from "../../assets/images/illustrations/profile_setup_second.svg";
import Clouds from "../../assets/images/illustrations/clouds.svg";

import Logo from "../Shared/Logo";
import DCAvatar from "../Shared/DCAvatar";
import OnboardingSteps from "./OnboardingSteps";
import { useAuth } from "../../contexts/AuthContext";
import get from "lodash/get";
import { DcIconButton } from "../../assets/theme/theme";
import LogoutIcon from "@mui/icons-material/Logout";
import { useRouteMatch } from "react-router-dom";
import { useUserContext } from "../../contexts/UserContext";
import { isSubscriptionActive } from "../../utils/userUtils";

export interface Props {}

function getURLIndex(match: any): number {
  if (match && match.isExact) {
    const urlIndex = get(match, "params.index");
    if (urlIndex === "0") {
      return 0;
    } else if (urlIndex === "1") {
      return 1;
    }
  }
  return 0;
}

const ProfileSetup: React.FC<Props> = (props) => {
  const match = useRouteMatch("/profile/setup/:index");
  const { currentUser, logout } = useAuth();
  const { user } = useUserContext();
  const defaultIndex = getURLIndex(match);
  const [activeStep, setActiveStep] = React.useState(defaultIndex);
  const [avatarEl, setAvatarEl] = React.useState<null | HTMLElement>(null);
  const profileMenuOpen = Boolean(avatarEl);

  const handleClose = React.useCallback(() => {
    setAvatarEl(null);
  }, []);

  const onLogoutClick = React.useCallback(() => {
    logout();
  }, [logout]);

  const handleProfileClick = React.useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      setAvatarEl(event.currentTarget);
    },
    []
  );

  return (
    <Container maxWidth="xl" disableGutters className="full-height">
      <Grid container spacing={0} className="full-height">
        <Hidden mdDown>
          <Grid item xs={12} sm={5}>
            <Box
              display="flex"
              flexDirection="column"
              className="full-height"
              alignItems="flex-start"
            >
              <Box mt={6} ml={"52px"}>
                <Logo />
              </Box>
              <Box ml={8} my={8} style={{ width: "calc(100% - 64px)" }}>
                <Typography
                  variant="h1"
                  component="h2"
                  color="primary"
                  mb={0.5}
                >
                  Welcome!
                </Typography>
                <Typography
                  variant="h6"
                  component="p"
                  fontSize={22}
                  fontWeight={400}
                  mb={6}
                >
                  Let’s Setup your account with 2 simple steps.
                </Typography>
                <img
                  src={activeStep === 0 ? Illustration : ProfileIllustration}
                  alt="Happy"
                  width="100%"
                  style={{ maxWidth: 500 }}
                />
              </Box>
            </Box>
          </Grid>
        </Hidden>
        <Grid item sm={12} md={7} flex={1}>
          <Stack position="relative">
            <Box
              my={3}
              mr={4}
              flexDirection="column"
              alignItems="end"
              display="flex"
              height={110}
            >
              <DcIconButton
                size="large"
                aria-label="show more"
                aria-haspopup="true"
                color="inherit"
                onClick={handleProfileClick}
              >
                <DCAvatar
                  name={get(currentUser, "displayName", "No name") as string}
                  email={get(currentUser, "email", "") as string}
                  imgSrc={get(currentUser, "photoURL") as string}
                  premium={isSubscriptionActive(user)}
                />
              </DcIconButton>
              <img
                src={Clouds}
                alt="Clouds"
                height={110}
                style={{ position: "absolute", right: 104, top: 24, zIndex: 0 }}
              />
            </Box>
          </Stack>
          <Menu
            anchorEl={avatarEl}
            open={profileMenuOpen}
            onClose={handleClose}
            onClick={handleClose}
            transformOrigin={{ horizontal: "right", vertical: "top" }}
            anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
            PaperProps={{ style: { paddingBlock: 8 } }}
          >
            <MenuItem style={{ width: 150 }} onClick={onLogoutClick}>
              <ListItemIcon style={{ minWidth: "unset" }}>
                <LogoutIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>Logout</ListItemText>
            </MenuItem>
          </Menu>
          <Box width="80%" ml={4}>
            <OnboardingSteps
              setActiveStep={setActiveStep}
              activeStep={activeStep}
            />
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
};

export default ProfileSetup;
