import {
  Menu,
  MenuItem,
  ListItemText,
  Button,
  ListSubheader,
  Box,
} from "@mui/material";
import React, { useCallback } from "react";
import { useDocumentContext } from "../../contexts/DocumentContext";
import { isEmpty, map } from "lodash";
import { useCustomCardModalContext } from "../../contexts/CustomCardModalContext";
import { getDocName } from "../../utils/documentUtils";

interface Props {}

const AttachDocumentCustomCardModal: React.FC<Props> = (props) => {
  const [anchorEl, setAnchorEl] = React.useState(null as HTMLElement | null);
  const open = Boolean(anchorEl);
  const { folders } = useDocumentContext();
  const { handleAttachDocument, customDocumentEditable } =
    useCustomCardModalContext();

  const _handleClose = () => {
    setAnchorEl(null);
  };
  const handleClose = useCallback(_handleClose, []);

  const _handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClick = useCallback(_handleClick, []);

  return (
    <>
      <Button
        variant="outlined"
        aria-label="attach"
        aria-controls="document-attach"
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      >
        {customDocumentEditable?.attached_document_id ? (
          <>Change document</>
        ) : (
          <>Attach document</>
        )}
      </Button>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        transformOrigin={{ horizontal: "left", vertical: "top" }}
        anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
        MenuListProps={{
          "aria-labelledby": "document-options",
        }}
        sx={{ height: "400px" }}
      >
        {map(folders, (folder, i) => {
          return (
            !isEmpty(folder.docs) && (
              <Box key={i}>
                <ListSubheader color="primary" disableSticky>
                  {folder.title}
                </ListSubheader>
                {map(folder.docs, (document, i) => {
                  return (
                    <MenuItem
                      key={i}
                      onClick={() => {
                        handleAttachDocument(
                          document.id,
                          getDocName(document),
                          document?.doc_format as string
                        );
                        handleClose();
                      }}
                    >
                      <ListItemText>{getDocName(document)}</ListItemText>
                    </MenuItem>
                  );
                })}
              </Box>
            )
          );
        })}
      </Menu>
    </>
  );
};

export default AttachDocumentCustomCardModal;
