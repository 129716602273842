import { Box } from "@mui/material";
import TravelSummaryHeaderNew from "./TravelSummaryHeaderNew";
import { DCDashboardContainer } from "../../assets/theme/theme";
import { TravelSummaryNew } from "./TravelSummaryNew";
import { TravelHistoryTabsNew } from "./TravelHisoryTabsNew";
import { TravelsTableNew } from "./TravelsTableNew";
import { UpdateCurrentLocationModalProvider } from "../../contexts/UpdateCurrentLocationModalContext";
import { EditTravelHistoryModalProviderNew } from "../../contexts/EditTravelHistoryModalContextNew";
import { AddTravelHistoryModalProviderNew } from "../../contexts/AddTravelHistoryModalContextNew";
import { DeleteTravelHistoryModalProviderNew } from "../../contexts/DeleteTravelHistoryModalContextNew";
import { useState } from "react";

interface ITravelHistoryNewProps {}

export const TravelHistoryNew = (props: ITravelHistoryNewProps) => {
  const [travelState, setTravelState] = useState<"usa" | "world">("usa");
  return (
    <Box height="100%" pb="80px">
      <TravelSummaryHeaderNew />
      <DCDashboardContainer>
        <TravelSummaryNew />
        <UpdateCurrentLocationModalProvider travelState={travelState}>
          <AddTravelHistoryModalProviderNew travelState={travelState}>
            <DeleteTravelHistoryModalProviderNew travelState={travelState}>
              <EditTravelHistoryModalProviderNew travelState={travelState}>
                <TravelHistoryTabsNew
                  travelState={travelState}
                  setTravelState={setTravelState}
                />
                <TravelsTableNew travelState={travelState} />
              </EditTravelHistoryModalProviderNew>
            </DeleteTravelHistoryModalProviderNew>
          </AddTravelHistoryModalProviderNew>
        </UpdateCurrentLocationModalProvider>
      </DCDashboardContainer>
    </Box>
  );
};
