import React from "react";
import { DCButton } from "../../assets/theme/theme";
import { Stack, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import constants from "../../constants";
import { postData } from "../../utils/fetchUtils";

export const Success = function () {
  const [sessionId, setSessionId] = React.useState<string | null>(null);
  const [loadingManageBilling, setLoadingManageBilling] = React.useState(false);

  const onClickManageBillingInfo = React.useCallback(async () => {
    if (!sessionId) return;
    try {
      setLoadingManageBilling(true);
      const url = `${constants.middleHost}/stripe/public/create-customer-portal-session`;
      const data = {
        sessionId,
      };
      const res = await postData(url, data);
      setLoadingManageBilling(false);
      window.open(res.data.url, "_blank");
      window.close();
    } catch (error: any) {
      console.error(error);
      setLoadingManageBilling(false);
    }
  }, [sessionId]);

  React.useEffect(() => {
    // Check to see if this is a redirect back from Checkout
    const query = new URLSearchParams(window.location.search);

    if (query.get("session_id")) {
      setSessionId(query.get("session_id"));
    }
  }, []);
  return (
    <Stack
      margin={8}
      direction="column"
      justifyContent="center"
      alignItems="center"
      spacing={2}
      textAlign={"center"}
    >
      <Typography variant="h1" component="h1">
        🎉 Success! You're now part of the immplify family.
      </Typography>
      <Typography variant="h3" component="h3" fontSize={18}>
        Your subscription to our plan is confirmed, and we're excited to have
        you on board as a cherished customer.
      </Typography>
      <Typography variant="h3" component="h3" fontSize={18}>
        Welcome to a world of convenience and premium services!
      </Typography>
      <DCButton
        variant="contained"
        onClick={(evt) => {
          evt.preventDefault();
          window.close();
        }}
      >
        Close Tab
      </DCButton>
      <LoadingButton
        aria-label="manage billing info here"
        variant="text"
        color="primary"
        loading={loadingManageBilling}
        onClick={onClickManageBillingInfo}
      >
        Manage Billing Info here
      </LoadingButton>
    </Stack>
  );
};
