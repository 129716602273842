import React, { useCallback } from "react";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Collapse from "@mui/material/Collapse";
import { Tooltip } from "@mui/material";
import theme, {
  DCBaseCard,
  DcDocumentIconButton,
} from "../../assets/theme/theme";
import { Grid, Typography, Button } from "@mui/material";
import { Box } from "@mui/system";
import { ICustomDocument } from "../../firebaseTypes";
import DCCopyToClipboard from "../Shared/DCCopyToClipboard";
import ChevronDownIcon from "../Shared/Icons/ChevronDownIcon";
import ChevronUpIcon from "../Shared/Icons/ChevronUpIcon";
import { get, map, take, takeRight } from "lodash";
import AttachDocumentCustomCard from "./AttachDocumentCustomCard";
// import { usePdfPreviewModalContext } from "../../contexts/PdfPreviewModalContext";

import { getFieldDisplayValue } from "../../utils/documentUtils";
import ConfirmDialog from "../Shared/ConfirmDialog";
import { useCustomCardModalContext } from "../../contexts/CustomCardModalContext";
import constants from "../../constants";
import BriefcaseIcon from "../Shared/Icons/BriefcaseIcon";
import EditIcon from "../Shared/Icons/EditIcon";
import DeleteIcon from "../Shared/Icons/DeleteIcon";
import PaperclipIcon from "../Shared/Icons/PaperclipIcon";
import DocumentIcon from "../Shared/Icons/DocumentIcon";
import PastAddressIcon from "../Shared/Icons/PastAddressIcon";
import Pagination from "./Pagination";

interface Props {
  cardStack: ICustomDocument[];
}

const CustomCardStack: React.FC<Props> = ({ cardStack }) => {
  const [expanded, setExpanded] = React.useState<boolean>(false);
  const [topDocumentIndex, setTopDocumentIndex] = React.useState(0);
  const [isPageChanged, setIsPageChanged] = React.useState(false);
  const [isOpenConfirmDelete, setIsOpenConfirmDelete] =
    React.useState<boolean>(false);
  const { handleDeleteCustomCard } = useCustomCardModalContext();
  // const { openModalForDocument } = usePdfPreviewModalContext();
  const { openModalForEdit } = useCustomCardModalContext();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const { id, doc_info, doc_title, doc_name, template } = cardStack[topDocumentIndex] as ICustomDocument;


  // const onPreviewBtnClick = React.useCallback(() => {
  //   // undo-todo: change the type of document to custom document
  //   openModalForDocument(cardStack[topDocumentIndex] as any, id);
  // }, [openModalForDocument, cardStack, topDocumentIndex, id]);

  const onEditDocument = React.useCallback(
    (document: ICustomDocument, id: string) => {
      openModalForEdit({ ...document, id });
    },
    [openModalForEdit]
  );

  const onEditBtnClick = React.useCallback(() => {
    onEditDocument(cardStack[topDocumentIndex], cardStack[topDocumentIndex].id as string);
  }, [cardStack, onEditDocument, topDocumentIndex]);

  // Logic for Expand
  const first4Fields = take(doc_info || [], 4);
  const remainingFieldsCount = (doc_info || []).length - 4;
  const emptyFieldsCount = 4 - first4Fields.length;
  const remainingFields =
    remainingFieldsCount > 0
      ? takeRight(doc_info || [], remainingFieldsCount)
      : [];

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const handlePageChange = useCallback((newPage: number) => {
    setTopDocumentIndex(newPage - 1);
    setIsPageChanged(!isPageChanged);
  }, [isPageChanged]);
  
  const templateName = template || "default";
  const templateObj = get(constants.customCardTemplates, templateName);

  if (!id) return null;

  const cardTypeIcon =
    templateName === constants.customCardTemplates.employer.name ? (
      <BriefcaseIcon color="primary" />
    ) : templateName === constants.customCardTemplates.default.name ? (
      <DocumentIcon color="primary" />
    ) : (
      <PastAddressIcon color="primary" />
    );

  return (
    <Grid
      item
      xl={4}
      lg={6}
      sm={8}
      xs={4}
      position="relative"
      // Hardcoded the breakpoint for improving UI just in 14in Macbook
      sx={{
        "@media screen and (min-width: 1440px)": {
          minWidth: "100%",
        },
      }}
    >
      <DCBaseCard
        sx={{
          boxShadow: `0 3px inset ${templateObj.cardBG}, 0 0 0 1px rgb(56 60 67 / 5%), 0 1px 3px 0 rgb(56 60 67 / 15%)`,
          "&:hover": {
            boxShadow: `0 3px inset ${templateObj.cardBG}, 0 0 0 0px rgb(56 60 67 / 5%), 0 3px 10px 0 rgb(56 60 67 / 15%)`,
          },
        }}
        className={`dc-card--custom ${cardStack.length > 1 ? "is-stacked" : ""}`}
      >
        <CardHeader avatar={cardTypeIcon} title={doc_title} action={
          <Pagination
            totalPages={cardStack.length}
            handlePageChange={handlePageChange}
          />
        } />
        <CardContent>
          {doc_name ? (
            <Box sx={{ mb: "10px", display: "flex", alignItems: "center" }}>
              <PaperclipIcon fontSize="small" sx={{ mr: "5px" }} />
              <Typography
                component="span"
                variant={"body1"}
                pr={1.5}
                color={theme.palette.text.secondary}
              >
                Attached document
              </Typography>
              <Typography
                pr={1.5}
                component="span"
                variant={"body1"}
                sx={{
                  wordBreak: "break-all",
                }}
              >
                {doc_name}
              </Typography>
            </Box>
          ) : null}
          <Box display="flex" rowGap={0.5} flexDirection="column">
            {first4Fields.map((field: any) => {
              const displayValue = getFieldDisplayValue(field);
              return (
                <Box display="flex">
                  <Typography
                    component="span"
                    variant={"body1"}
                    pr={1.5}
                    color={theme.palette.text.secondary}
                    flexBasis={166}
                    minWidth={166}
                  >
                    {field.title}:
                  </Typography>
                  <Typography
                    component="span"
                    variant={"body1"}
                    flex={1}
                    overflow="hidden"
                  >
                    <DCCopyToClipboard text={displayValue}>
                      <Typography
                        component="span"
                        display="block"
                        flex={1}
                        noWrap
                      >
                        {displayValue}
                      </Typography>
                    </DCCopyToClipboard>
                  </Typography>
                </Box>
              );
            })}
            {map(new Array(emptyFieldsCount), (x, i) => {
              return <EmptyField key={i} />;
            })}
            {remainingFieldsCount > 0 && (
              <Collapse in={expanded} timeout="auto" unmountOnExit>
                <Box display="flex" rowGap={0.5} flexDirection="column">
                  {remainingFields.map((field: any) => {
                    const displayValue = getFieldDisplayValue(field);
                    return (
                      <Box display="flex">
                        <Typography
                          component="span"
                          variant={"body1"}
                          pr={1.5}
                          color={theme.palette.text.secondary}
                          flexBasis={166}
                          minWidth={166}
                        >
                          {field.title}:
                        </Typography>
                        <Typography
                          component="span"
                          variant={"body1"}
                          flex={1}
                          overflow="hidden"
                        >
                          <DCCopyToClipboard text={displayValue}>
                            <Typography component="span" display="block" noWrap>
                              {displayValue}
                            </Typography>
                          </DCCopyToClipboard>
                        </Typography>
                      </Box>
                    );
                  })}
                </Box>
              </Collapse>
            )}
          </Box>
        </CardContent>
        <CardActions disableSpacing sx={{ marginTop: "auto" }}>
          {remainingFieldsCount > 0 && (
            <Button
              onClick={handleExpandClick}
              variant="contained"
              color="secondary"
              className="showmore-button"
            >
              Show {expanded ? "Less" : `More (${remainingFieldsCount})`}
              {expanded ? (
                <ChevronUpIcon sx={{ fontSize: 14, marginLeft: 1 }} />
              ) : (
                <ChevronDownIcon sx={{ fontSize: 14, marginLeft: 1 }} />
              )}
            </Button>
          )}
          <Box ml="auto">
            <>
              <AttachDocumentCustomCard
                card_id={id as string}
                doc_name={doc_name}
              />

              <Tooltip arrow title="Edit document" placement="top">
                <DcDocumentIconButton
                  aria-label="edit document"
                  onClick={() => {
                    onEditBtnClick();
                  }}
                >
                  <EditIcon fontSize="small" />
                </DcDocumentIconButton>
              </Tooltip>

              <Tooltip arrow title="Delete card" placement="top">
                <DcDocumentIconButton
                  aria-label="delete card"
                  onClick={() => setIsOpenConfirmDelete(true)}
                >
                  <DeleteIcon fontSize="small" />
                </DcDocumentIconButton>
              </Tooltip>
              {isOpenConfirmDelete && (
                <ConfirmDialog
                  heading="Delete this Card?"
                  subHeading="Doing so will permanently delete this card. However, the document attached to the card will remain intact in the document gallery"
                  cardTitle={doc_title}
                  open={isOpenConfirmDelete}
                  setOpen={setIsOpenConfirmDelete}
                  onConfirm={() => handleDeleteCustomCard(id as string)}
                />
              )}
            </>
            {/* {doc_image ? (
              <>
                <Tooltip arrow title="Preview document" placement="top">
                  <DcDocumentIconButton
                    aria-label="preview document"
                    onClick={onPreviewBtnClick}
                  >
                    <EyeIcon fontSize="small" />
                  </DcDocumentIconButton>
                </Tooltip>
                <Tooltip arrow title="Edit document" placement="top">
                  <DcDocumentIconButton
                    aria-label="edit document"
                    onClick={() => {
                      onEditBtnClick();
                    }}
                  >
                    <EditIcon fontSize="small" />
                  </DcDocumentIconButton>
                </Tooltip>
                <DocumentOptionsMenuCustomCard
                  // undo-todo: change type
                  document={document as any}
                  id={id as string}
                />
              </>
            ) : (
             "some"
            )} */}
          </Box>
        </CardActions>
      </DCBaseCard>
    </Grid>
  );
};

function EmptyField() {
  return (
    <Box display="flex" alignItems="center">
      <Typography
        component="span"
        variant={"body1"}
        pr={1.5}
        flexBasis={166}
        minWidth={166}
        color={theme.palette.text.secondary}
      >
        &nbsp;
      </Typography>
    </Box>
  );
}

export default CustomCardStack;
