import { Box, Chip, Tooltip, Typography } from "@mui/material";
import { differenceInDays, format } from "date-fns";
import { FC, ReactNode, useState } from "react";
import { DcDocumentIconButton, DCPaper } from "../../assets/theme/theme";
import { DCTable } from "../Shared/DCTable";
import { KeyValueItem } from "../Shared/KeyValueItem";
import { ITravelModel } from "./hooks/TravelHistory.hook";
import { TravelsTableFilter, ITravelFilterSummary } from "./TravelsTableFilter";
import DeleteIcon from "../Shared/Icons/DeleteIcon";
import constants from "../../constants";

interface Props {
  travelRecords: ITravelModel[];
  loading?: boolean;
  header?(): ReactNode;
  onEditClick(row: ITravelModel): void;
  onDeleteClick(rows: string[]): void;
  onDeleteAllClick(): void;
}
export const TravelsTable: FC<Props> = ({
  travelRecords,
  loading,
  header,
  onEditClick,
  onDeleteClick,
  onDeleteAllClick,
}) => {
  const [filteredTravels, setFilteredTravels] = useState<ITravelModel[]>([]);
  const [filterSummary, setFilterSummary] = useState<ITravelFilterSummary[]>(
    []
  );
  const [filterSummaryTotal, setFilterSummaryTotal] =
    useState<ITravelFilterSummary | null>(null);
  const [selectedRowData, setSelectedRowData] = useState<{
    selectedRows: string[];
    allSelected: boolean;
  }>({ selectedRows: [], allSelected: false });

  const onFilter = (data: ITravelModel[], summary: ITravelFilterSummary[]) => {
    setFilteredTravels(data);
    setFilterSummary(summary);
    setFilterSummaryTotal(
      summary.length
        ? {
            year: "Total Days",
            value: summary.reduce((p, c) => p + c.value, 0),
          }
        : null
    );
  };

  const onDeleteItemClick = () => {
    if (selectedRowData.allSelected) {
      onDeleteAllClick();
    } else {
      onDeleteClick(selectedRowData.selectedRows);
    }
  };

  const warning = (
    <Tooltip title="Failed to parse. Use edit to enter manually.">
      {/* <WarningAmberIcon color="error" /> */}
      <strong>---</strong>
    </Tooltip>
  );

  return (
    <Box mt={4}>
      <DCPaper>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          mb={2}
          px={3}
          pt={2}
        >
          <Box display="flex" alignItems="center">
            <Typography variant="h4" fontSize={20} fontWeight={500}>
              Travels
            </Typography>
            {header && header()}
          </Box>
          <Box display="flex" columnGap={3} alignItems="center">
            <Box display="flex" columnGap={1} alignItems="center">
              {filterSummary.map((f) => (
                <Chip
                  key={f.year}
                  color="info"
                  variant="outlined"
                  label={
                    <KeyValueItem label={`${f.year}:`} marginBottom={0}>
                      {f.value}
                    </KeyValueItem>
                  }
                />
              ))}
              {filterSummaryTotal && (
                <Chip
                  key={filterSummaryTotal.year}
                  color="warning"
                  variant="outlined"
                  label={
                    <KeyValueItem
                      label={`${filterSummaryTotal.year}:`}
                      marginBottom={0}
                    >
                      {filterSummaryTotal.value}
                    </KeyValueItem>
                  }
                />
              )}
            </Box>
            <TravelsTableFilter travels={travelRecords} onFilter={onFilter} />
            {selectedRowData.selectedRows.length > 0 && (
              <DcDocumentIconButton onClick={onDeleteItemClick}>
                <DeleteIcon color="error" />
              </DcDocumentIconButton>
            )}
          </Box>
        </Box>
        <DCTable
          selectedRows={selectedRowData.selectedRows}
          allSelected={selectedRowData.allSelected}
          // loading when actual travel records are zero
          loading={travelRecords.length === 0}
          initialOrder={{ order: "desc", orderBy: "arrival_date" }}
          columns={[
            {
              id: "arrival_loc_label",
              title: "Port Of Entry",
              children: (row: ITravelModel) => (
                <Box>
                  {row.arrival_loc_label ? (
                    row.arrival_loc_label
                  ) : (
                    <Box marginLeft={10}>{warning}</Box>
                  )}
                </Box>
              ),
            },
            {
              id: "departure_loc_label",
              title: "Port Of Exit",
              children: (row: ITravelModel) => (
                <Box>
                  {row.departure_loc_label ? (
                    row.departure_loc_label
                  ) : (
                    <Box marginLeft={10}>{warning}</Box>
                  )}
                </Box>
              ),
            },
            { id: "visa_status", title: "Visa Type" },
            {
              id: "arrival_date",
              title: "Arrival Date",
              children: (row: ITravelModel) => (
                <Box>
                  {row.arrival_date ? (
                    format(row.arrival_date, constants.dateFormat)
                  ) : (
                    <Box marginLeft={5}>{warning}</Box>
                  )}
                </Box>
              ),
            },
            {
              id: "departure_date",
              title: "Departure Date",
              children: (row: ITravelModel) => (
                <Box>
                  {row.isPresent ? (
                    "Currently in the country "
                  ) : row.departure_date ? (
                    format(row.departure_date, constants.dateFormat)
                  ) : (
                    <Box marginLeft={5}>{warning}</Box>
                  )}
                </Box>
              ),
            },
            {
              id: "day_count",
              title: "Day Count",
              children: (row: ITravelModel) => (
                <Box>
                  {row.departure_date && row.arrival_date
                    ? differenceInDays(row.departure_date, row.arrival_date) + 1
                    : ""}
                </Box>
              ),
            },
            {
              id: "type",
              title: "Type",
              children: (row: ITravelModel) => (
                <Box>{row.automated ? "Auto" : "Manual"}</Box>
              ),
            },
          ]}
          rowStyle={(t: ITravelModel) =>
            t.automated ? { backgroundColor: "rgba(69, 93, 238, 0.08)" } : {}
          }
          data={filteredTravels}
          onEditClick={onEditClick}
          hasCheckbox={true}
          onSelectRow={(l, a) => {
            setSelectedRowData({ allSelected: a, selectedRows: l });
          }}
        />
      </DCPaper>
    </Box>
  );
};
