import React from 'react'
import { IFolder, useDocumentContext } from '../../contexts/DocumentContext'
import { filter, groupBy, isEmpty, map } from 'lodash';
import { Accordion, AccordionSummary, Typography, AccordionDetails, Box } from '@mui/material';
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { IDocumentField } from '../../firebaseTypes';
import DCCopyToClipboard from '../Shared/DCCopyToClipboard';
import { useCreateCustomCardContext } from '../../contexts/CreateCustomCardContext';
import constants from '../../constants';
import { format } from 'date-fns';
import { useHistory } from 'react-router-dom';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import theme from '../../assets/theme/theme';
import { grey } from '@mui/material/colors';

export interface DocumentAccordionStackProps {
  folder: IFolder;
}

const DocumentAccordionStack = ({ folder }) => {
  return (
    <Box sx={{ width: '100%' }}>
      <Accordion>
        <AccordionSummary sx={{           
            "&:hover": { 
              bgcolor: theme.palette.grey[100]
            }}}
          expandIcon={<ExpandMoreIcon />}>
          <Box sx={{ display: 'flex', justifyContent: "center", alignItems: "center", gap: 2 }}>
            <Box sx={{ bgcolor: grey[100], width: "24px", height: "24px", borderRadius: "100%", display: 'flex', justifyContent: "center", alignItems: "center", fontSize: 10, fontWeight: 600 }}>
              {folder.count}
            </Box>
            <Typography fontSize={16} fontWeight={600}>{folder.title}</Typography>
          </Box>
        </AccordionSummary>
        <AccordionDetails>
          <Box>
            {map(folder.docs, (doc, i) => {
              if (isEmpty(doc.doc_info)) return null;
              return (
                <Box sx={{ mb: 4 }}>
                  {folder.docs?.length > 1 && <Typography sx={{ pl: 2, fontSize: 16, fontWeight: 500, mb: 1 }}>Document No. {i + 1}</Typography>}
                  <DocField key={`${doc.doc_type}-${i}`} fields={doc.doc_info} />
                </Box>
              )
            })}
          </Box>
        </AccordionDetails>
      </Accordion>
    </Box>
  )
}

const CustomDocumentAccordionStack = ({ stack }) => {
  return (
    <Box sx={{ width: '100%' }}>
      <Accordion>
        <AccordionSummary sx={{           
            "&:hover": { 
              bgcolor: theme.palette.grey[100]
            }}}
            expandIcon={<ExpandMoreIcon />}>
        <Box sx={{ display: 'flex', justifyContent: "center", alignItems: "center", gap: 2 }}>
            <Box sx={{ bgcolor: grey[100], width: "24px", height: "24px", borderRadius: "100%", display: 'flex', justifyContent: "center", alignItems: "center", fontSize: 10, fontWeight: 600 }}>
              {stack.docs?.length}
            </Box>
            <Typography fontSize={16} fontWeight={600}>{stack.template}</Typography>
          </Box>
        </AccordionSummary>
        <AccordionDetails>
          <Box>
            {map(stack.docs, (doc, i) => {
              if (isEmpty(doc.doc_info)) return null;
              return (
                <Box sx={{ mb: 4 }}>
                  <Typography sx={{ pl: 2, fontSize: 16, fontWeight: 500, mb: 1 }}>
                    {doc.doc_title}
                  </Typography>
                  <DocField key={`${doc.template}-${i}`} fields={doc.doc_info} />
                </Box>
              )
            })}
          </Box>
        </AccordionDetails>
      </Accordion>
    </Box>
  )
}

const DocField = ({ fields }: { fields: IDocumentField[] }) => {
  return (
    <Box sx={{ px: 2 }}>
      {map(fields, (field, i) => {
        return (
          <Box key={`${field.title}-${i}`}>
            <Box sx={{ display: 'flex', gap: 1 }}>
              <Typography fontWeight={600} sx={{ minWidth: 140 }}>{field.title} :</Typography>
              <DCCopyToClipboard text={field.value}>
                <Typography>
                  {typeof field.value === 'string' ? field.value : format(field.value.seconds * 1000, 'dd-MM-yyyy')}
                </Typography>
              </DCCopyToClipboard>
            </Box>
          </Box>
        )
      })}
    </Box>
  )
}

const CheatSheet = () => {
  const history = useHistory();
  const { folders } = useDocumentContext();
  const { customCardArray } = useCreateCustomCardContext();
  const groupedCustomCardArray = Object.values(groupBy(customCardArray, (doc) => doc.template));
  // sort the groupedCustomCardArray by template name first pastAddress, employer, default
  groupedCustomCardArray.sort((a, b) => {
    const templateNameA = a[0].template as string;
    const templateNameB = b[0].template as string;
    if (templateNameA > templateNameB) {
      return -1;
    }
    if (templateNameA < templateNameB) {
      return 1;
    }
    return 0;
  });

  const stacks = map(folders, (folder, i) => {
    if (folder.displayOnDashboard) return null;
    const stackableDocs = filter(folder.docs, (doc) => !isEmpty(doc.doc_info));
    if (stackableDocs.length === 0) return null;

    return <DocumentAccordionStack folder={folder} key={`${folder.title}-${i}`} />;
  });

  const customCardStacks = map(groupedCustomCardArray, (cardStack, i) => {
    const stackableDocs = filter(cardStack, (doc) => !isEmpty(doc.template));
    if (stackableDocs.length === 0) return null;
    if (cardStack[0].template === constants.customCardTemplates.default.name) {
      return <CustomDocumentAccordionStack stack={{
        template: "Default",
        docs: cardStack
      }} key={`${cardStack[0].doc_title}-${i}`} />;
    };
    if (cardStack[0].template === constants.customCardTemplates.pastAddress.name) {
      // sort the array by the vale.seconds of Move in Date title in doc_info
      cardStack.sort((a, b) => {
        // Extracting the "Move In Date" value from the objects
        if (!a.doc_info || !b.doc_info || !a.doc_info!.find(info => info.title === 'Move In Date') || !b.doc_info!.find(info => info.title === 'Move In Date')) {
          return 0;
        } else {
          const moveInDateA = a.doc_info.find(info => info.title === 'Move In Date')!.value.seconds;
          const moveInDateB = b.doc_info.find(info => info.title === 'Move In Date')!.value.seconds;
          // Comparing the "Move In Date" values for sorting
          return moveInDateB - moveInDateA;
        }

      });
      return <CustomDocumentAccordionStack stack={{
        template: "Past Address",
        docs: cardStack
      }} key={`${i}`} />;
    }
    if (cardStack[0].template === constants.customCardTemplates.employer.name) {
      // sort the array by the vale.seconds of Start Date title in doc_info
      cardStack.sort((a, b) => {
        // Extracting the "Start Date" value from the objects
        if (!a.doc_info || !b.doc_info || !a.doc_info!.find(info => info.title === 'Start Date') || !b.doc_info!.find(info => info.title === 'Start Date')) {
          return 0;
        } else {
          const startDateA = a.doc_info.find(info => info.title === 'Start Date')!.value.seconds;
          const startDateB = b.doc_info.find(info => info.title === 'Start Date')!.value.seconds;
          // Comparing the "Start Date" values for sorting
          return startDateB - startDateA;
        }

      });

      return <CustomDocumentAccordionStack stack={{
        template: "Employer",
        docs: cardStack
      }} key={`${i}`} />;
    }
  });

  return (
    <Box sx={{ width: "80%", mb: 10 }}>
      <Box sx={{ display: "flex", alignItems: "center", mt: 4, gap: 1, mb: 1 }}>
        <Typography onClick={() => history.push('/')}
          sx={{
            display: "flex", alignItems: "center",
            cursor: "pointer",
            fontSize: 16,
            "&:hover": {
              color: "blue",
              textDecoration: "underline"
            }
          }}>
          <KeyboardArrowLeftIcon fontSize="medium" />
          Back
        </Typography>
      </Box>
      <Typography color={theme.palette.primary.main} component={'h1'} fontSize={28} fontWeight={600}>Documents' cheat sheet</Typography>
      <Typography component={'h2'} fontSize={20} fontWeight={600} my={4}>Auto detected Documents</Typography>
      {stacks}
      <Typography component={'h2'} fontSize={20} fontWeight={600} my={4}>Custom Documents</Typography>
      {customCardStacks}
    </Box>
  )
}

export default CheatSheet