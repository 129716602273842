
import React, { useContext, useState, useEffect, useCallback } from "react";
import { googleAuthProvider, auth, analytics, rtdb, appleAuthProvider } from "../firebase";
import FirebaseAuth, {
  createUserWithEmailAndPassword,
  deleteUser,
  sendEmailVerification,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  signInWithPopup,
  updateProfile,
} from "firebase/auth";
import { setUserId } from "firebase/analytics";
import Spinner from "../components/Spinner";
import {
  getMultiFactorResolver,
  multiFactor,
  PhoneAuthProvider,
  PhoneMultiFactorGenerator,
  RecaptchaVerifier,
  signOut,
} from "firebase/auth";
import { useHistory } from "react-router-dom";
import { IUser } from "../firebaseTypes";
import { get, isEmpty } from "lodash";
import { ref, set } from "firebase/database";

export interface IAuthContext {
  currentUser: FirebaseAuth.User | null;
  login: (email: string, password: string) => any;
  signup: (email: string, password: string, displayName: string) => any;
  signUpWithGoogle: () => any;
  signUpWithApple: () => any;
  logout: () => any;
  resetPassword: (email: string) => any;
  sendEmailVerificationToUser: () => any;
  OTP: string;
  setOTP: React.Dispatch<React.SetStateAction<string>>;
  phoneNumber: string;
  setPhoneNumber: React.Dispatch<React.SetStateAction<string>>;
  sendOTP: () => any;
  verifyOTP: () => any;
  isOTPSent: boolean;
  openError: boolean;
  openErrorMoadal: () => any;
  closeErrorMoadal: (
    event: React.SyntheticEvent | Event,
    reason?: string
  ) => any;
  errMsg: string;
  isMultiFactorAuth: any;
  buttonIsLoading: boolean;
  reSendOTPLogin: () => any;
  verifyMFADelete: (e: any) => any;
  verifyOTPDeleteAccount: () => any;
}

const defaultValues = {
  currentUser: null,
  login: () => { },
  signup: () => { },
  signUpWithGoogle: () => { },
  signUpWithApple: () => { },
  sendEmailVerificationToUser: () => { },
  logout: () => { },
  resetPassword: () => { },
  OTP: "",
  setOTP: () => { },
  phoneNumber: "",
  setPhoneNumber: () => { },
  sendOTP: () => { },
  verifyOTP: () => { },
  isOTPSent: false,
  openError: false,
  openErrorMoadal: () => { },
  closeErrorMoadal: () => { },
  errMsg: "",
  isMultiFactorAuth: "",
  buttonIsLoading: false,
  reSendOTPLogin: () => { },
  verifyMFADelete: () => { },
  verifyOTPDeleteAccount: () => { },
};

const AuthContext = React.createContext<IAuthContext>(defaultValues);

export function useAuth() {
  return useContext(AuthContext);
}

// https://firebase.google.com/docs/reference/js/firebase.User
export function AuthProvider({ children }: any) {
  const [currentUser, setCurrentUser] = useState<FirebaseAuth.User | null>(
    null
  );
  const [loading, setLoading] = useState(true);
  const [multifactorAuth, setMultifactorAuth] = React.useState<any>();
  const [OTP, setOTP] = React.useState<string>(defaultValues.OTP);
  const [phoneNumber, setPhoneNumber] = React.useState<string>(
    defaultValues.phoneNumber
  );
  const [verificationId, setVerificationId] = React.useState<string>("");
  const [mfaResolver, setMfaResolver] =
    React.useState<FirebaseAuth.MultiFactorResolver>();
  const [isOTPSent, setisOtpSent] = React.useState<boolean>(
    defaultValues.isOTPSent
  );
  const [recaptcha, setRecaptcha] = React.useState<RecaptchaVerifier>();
  const [errMsg, setErrMsg] = React.useState<string>(defaultValues.errMsg);
  const [isMultiFactorAuth, setIsMultiFactorAuth] = React.useState<any>(
    defaultValues.isMultiFactorAuth
  );
  const [buttonIsLoading, setButtonIsLoading] = React.useState<boolean>(
    defaultValues.buttonIsLoading
  );

  const history = useHistory();

  //Number Already used for 2-step verification - Snackbar
  const [openError, setOpenError] = React.useState<boolean>(false);

  const createUserProfile = React.useCallback(async (user: IUser) => {
    try {
      const uid = get(user, "id", "");
      if (isEmpty(uid)) return false;

      const userRef = ref(rtdb, `users/${uid}/user`);
      await set(userRef, user);
      return true;
      // remove:
      // const docRef = doc(firestore, `${constants.collections.users}/${get(currentUser, 'uid', '')}`)
      // await setDoc(docRef, user, { merge: true })
      // setUser(user)
      // return true
    } catch (error: any) {
      console.error(error);
      return false;
    }
  }, []);

  const openErrorMoadal = () => {
    setOpenError(true);
  };

  const closeErrorMoadal = (
    event: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenError(false);
  };

  async function _signUpWithGoogle() {
    // Start a sign in process for an unauthenticated user.
    try {
      await signInWithPopup(auth, googleAuthProvider);
    } catch (e: any) {
      verifyMFA(e);
    }
  }
  const signUpWithGoogle = useCallback(_signUpWithGoogle, []);

  async function _signUpWithApple() {
    try {
      await signInWithPopup(auth, appleAuthProvider);
    } catch (e: any) {
      verifyMFA(e);
    }
  }

  const signUpWithApple = useCallback(_signUpWithApple, []);

  async function _sendEmailVerificationToUser() {
    if (currentUser && !currentUser.emailVerified) {
      await sendEmailVerification(currentUser, {
        url: window.location.origin,
        handleCodeInApp: true,
      });
    }
  }
  const sendEmailVerificationToUser = useCallback(
    _sendEmailVerificationToUser,
    [currentUser]
  );

  async function _signup(email: string, password: string, displayName: string) {
    const cu = await createUserWithEmailAndPassword(auth, email, password);
    if (cu && cu.user) {
      await updateProfile(cu.user, { displayName });
    }
  }

  const signup = useCallback(_signup, []);

  function _login(email: string, password: string) {
    return signInWithEmailAndPassword(auth, email, password);
  }
  const login = useCallback(_login, []);

  function _logout() {
    return auth.signOut();
  }
  const logout = useCallback(_logout, []);

  function _resetPassword(email: string) {
    return sendPasswordResetEmail(auth, email);
  }
  const resetPassword = useCallback(_resetPassword, []);

  // function updateEmail(email: string) {
  //     return currentUser.updateEmail(email)
  // }

  // function updatePassword(password: string) {
  //     return currentUser.updatePassword(password)
  // }

  const getMultiSession = React.useCallback(async (user: FirebaseAuth.User) => {
    const multi = user && (await multiFactor(user).getSession());
    setMultifactorAuth(multi);
  }, []);

  const sendOTP = async () => {
    setButtonIsLoading(true);
    try {
      const phoneInfoOptions = {
        phoneNumber: phoneNumber,
        session: multifactorAuth,
      };

      const phoneAuthProvider = new PhoneAuthProvider(auth);
      if (!recaptcha) {
        const recaptchaVerifier = new RecaptchaVerifier(
          auth,
          "recaptcha-container",
          {
            size: "invisible",
          }
        );
        setRecaptcha(recaptchaVerifier);
        const verificationId = await phoneAuthProvider.verifyPhoneNumber(
          phoneInfoOptions,
          recaptchaVerifier as RecaptchaVerifier
        );
        setVerificationId(verificationId);
      } else {
        const verificationId = await phoneAuthProvider.verifyPhoneNumber(
          phoneInfoOptions,
          recaptcha as RecaptchaVerifier
        );
        setVerificationId(verificationId);
      }
      setisOtpSent(true);
      setButtonIsLoading(false);
    } catch (e: any) {
      if (e.code === "auth/requires-recent-login") {
        await signOut(auth);
        history.push("/login");
      }
      if (e.code === "auth/user-token-expired") {
        await signOut(auth);
        history.push("/login");
      }
      if (e.code === "auth/second-factor-already-in-use") {
        setErrMsg("This phone number already in use!");
        openErrorMoadal();
      }
      if (e.code === "auth/too-many-requests") {
        setErrMsg("Too many requests sent. Please try again later.");
        openErrorMoadal();
      }
      console.log(e);
      setButtonIsLoading(false);
    }
  };

  const verifyOTP = async () => {
    try {
      setButtonIsLoading(true);
      const cred = PhoneAuthProvider.credential(verificationId, OTP);
      const multiFactorAssertion = PhoneMultiFactorGenerator.assertion(cred);
      currentUser &&
        (await multiFactor(currentUser).enroll(
          multiFactorAssertion,
          currentUser.displayName
        ));
      currentUser && window.location.reload();
      mfaResolver && (await mfaResolver.resolveSignIn(multiFactorAssertion));
      const count = window.localStorage.getItem("loginCount")
        ? (
          parseInt(window.localStorage.getItem("loginCount") as string) + 1
        ).toString()
        : "0";
      window.localStorage.setItem("loginCount", count);
      mfaResolver && setisOtpSent(false);
      mfaResolver && setButtonIsLoading(false);
      mfaResolver && setOTP("");
      mfaResolver && history.push("/");
    } catch (e: any) {
      if (e.code === "auth/invalid-verification-code") {
        setErrMsg("Wrong OTP entered!");
        openErrorMoadal();
      }
      setButtonIsLoading(false);
    }
  };

  const verifyOTPDeleteAccount = async () => {
    try {
      setButtonIsLoading(true);
      const cred = PhoneAuthProvider.credential(verificationId, OTP);
      const multiFactorAssertion = PhoneMultiFactorGenerator.assertion(cred);
      // verify the OTP
      mfaResolver && (await mfaResolver.resolveSignIn(multiFactorAssertion));
      mfaResolver && setOTP("");
      // delete the user
      currentUser && (await deleteUser(currentUser));
      // redirect to /account-delete-confirmation
      history.push("/account-delete-confirmation");
      setisOtpSent(false);
      setOTP("");
      setButtonIsLoading(false);
    } catch (e: any) {
      if (e.code === "auth/invalid-verification-code") {
        setErrMsg("Wrong OTP entered!");
        openErrorMoadal();
      }
    } finally {
      setisOtpSent(false);
      setOTP("");
      setButtonIsLoading(false);
    }
  };

  const verifyMFA = React.useCallback(async (e) => { 
    if (e.code === "auth/multi-factor-auth-required") {
      setPhoneNumber(e.customData._serverResponse.mfaInfo[0].phoneInfo);
      history.push("/verify-mfa");
      const resolver = getMultiFactorResolver(auth, e);
      setMfaResolver(resolver);
      const multiFactorHints = resolver.hints[0];

      const phoneInfoOptions = {
        multiFactorHint: multiFactorHints,
        session: resolver.session,
      };
      const phoneAuthProvider = new PhoneAuthProvider(auth);
      if (!recaptcha) {
        const recaptchaVerifier = new RecaptchaVerifier(
          auth,
          "recaptcha-container",
          {
            size: "invisible",
          }
        );
        setRecaptcha(recaptchaVerifier);
        const verificationId = await phoneAuthProvider.verifyPhoneNumber(
          phoneInfoOptions,
          recaptchaVerifier as RecaptchaVerifier
        );
        setVerificationId(verificationId);
      } else {
        const verificationId = await phoneAuthProvider.verifyPhoneNumber(
          phoneInfoOptions,
          recaptcha as RecaptchaVerifier
        );
        setVerificationId(verificationId);
      }
      setisOtpSent(true);
    }
  }, [history, recaptcha]);

  const verifyMFADelete = React.useCallback(async (e) => { 
    if (e.code === "auth/multi-factor-auth-required") {
      setPhoneNumber(e.customData._serverResponse.mfaInfo[0].phoneInfo);
      history.push("/verify-mfa-delete");
      const resolver = getMultiFactorResolver(auth, e);
      setMfaResolver(resolver);
      const multiFactorHints = resolver.hints[0];

      const phoneInfoOptions = {
        multiFactorHint: multiFactorHints,
        session: resolver.session,
      };
      const phoneAuthProvider = new PhoneAuthProvider(auth);
      if (!recaptcha) {
        const recaptchaVerifier = new RecaptchaVerifier(
          auth,
          "recaptcha-container",
          {
            size: "invisible",
          }
        );
        setRecaptcha(recaptchaVerifier);
        const verificationId = await phoneAuthProvider.verifyPhoneNumber(
          phoneInfoOptions,
          recaptchaVerifier as RecaptchaVerifier
        );
        setVerificationId(verificationId);
      } else {
        const verificationId = await phoneAuthProvider.verifyPhoneNumber(
          phoneInfoOptions,
          recaptcha as RecaptchaVerifier
        );
        setVerificationId(verificationId);
      }
      setisOtpSent(true);
    }
  }, [history, recaptcha]);

  const reSendOTPLogin = React.useCallback(async () => {
    const multiFactorHints = mfaResolver && mfaResolver.hints[0];
    const phoneInfoOptions = {
      multiFactorHint:
        mfaResolver && (multiFactorHints as FirebaseAuth.MultiFactorInfo),
      session: mfaResolver && (mfaResolver.session as any),
    };
    const phoneAuthProvider = new PhoneAuthProvider(auth);
    if (!recaptcha) {
      const recaptchaVerifier = new RecaptchaVerifier(
        auth,
        "recaptcha-container",
        {
          size: "invisible",
        }
      );
      setRecaptcha(recaptchaVerifier);
      const verificationId = await phoneAuthProvider.verifyPhoneNumber(
        phoneInfoOptions,
        recaptchaVerifier as RecaptchaVerifier
      );
      setVerificationId(verificationId);
    } else {
      const verificationId = await phoneAuthProvider.verifyPhoneNumber(
        phoneInfoOptions,
        recaptcha as RecaptchaVerifier
      );
      setVerificationId(verificationId);
    }
    setisOtpSent(true);
  }, [mfaResolver, recaptcha]);

  useEffect(() => {
    try {
      const unsubscribe = auth.onAuthStateChanged(
        (user) => {
          setCurrentUser(user);
          if (!isEmpty(user)) {
            // successfully signed in
            user?.getIdTokenResult().then((token) => {
              setIsMultiFactorAuth(token.signInSecondFactor);
            });
            setUserId(analytics, user.uid);
            getMultiSession(user);
          }
          setLoading(false);
        },
        (error) => {
          console.error(error);
        }
      );
      return unsubscribe;
    } catch (e) {
      console.log("error: ", e);
    }
  }, [createUserProfile, getMultiSession, verifyMFA]);

  const value = {
    currentUser,
    login,
    signup,
    signUpWithGoogle,
    signUpWithApple,
    logout,
    resetPassword,
    sendEmailVerificationToUser,
    OTP,
    setOTP,
    phoneNumber,
    setPhoneNumber,
    sendOTP,
    verifyOTP,
    isOTPSent,
    openError,
    openErrorMoadal,
    closeErrorMoadal,
    errMsg,
    isMultiFactorAuth,
    buttonIsLoading,
    reSendOTPLogin,
    verifyMFADelete,
    verifyOTPDeleteAccount,
    // updateEmail,
    // updatePassword
  };

  return (
    <AuthContext.Provider value={value}>
      {loading ? <Spinner /> : children}
    </AuthContext.Provider>
  );
}
