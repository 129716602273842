import * as React from "react";
import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

export default function currentColorIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        width="24"
        height="24"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
          d="M12.5 16C12.5 16.2761 12.2761 16.5 12 16.5C11.7239 16.5 11.5 16.2761 11.5 16M12.5 16C12.5 15.7239 12.2761 15.5 12 15.5C11.7239 15.5 11.5 15.7239 11.5 16M12.5 16H11.5M10 10.5V10C10 8.89543 10.8954 8 12 8C13.1046 8 14 8.89543 14 10V10.1213C14 10.6839 13.7765 11.2235 13.3787 11.6213L12 13M22 12C22 17.5228 17.5228 22 12 22C10.8606 22 9.88354 21.8377 8.97627 21.5132C8.11879 21.2065 7.69003 21.0531 7.52552 21.0144C6.0076 20.6574 5.3779 21.6977 4.06965 21.9157C3.42708 22.0228 2.85534 21.4967 2.90866 20.8475C2.95529 20.2798 3.34789 19.7429 3.50454 19.1977C3.83022 18.0644 3.38829 17.2052 2.9214 16.1973C2.33015 14.921 2 13.499 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12Z"
          stroke="currentColor"
        />
      </svg>
    </SvgIcon>
  );
}
