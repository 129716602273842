import { FirebaseApp, initializeApp } from "firebase/app";
import {
  Auth,
  GoogleAuthProvider,
  getAuth,
  setPersistence,
  browserSessionPersistence,
  OAuthProvider,
} from "firebase/auth";
import { Firestore, getFirestore } from "firebase/firestore";
import { FirebaseStorage, getStorage } from "firebase/storage";
import { Database, getDatabase } from "firebase/database";
import { Functions, getFunctions } from "firebase/functions";
// import { getMessaging, getToken, Messaging } from "firebase/messaging"
import { Analytics, getAnalytics } from "firebase/analytics";
import CONST from "./constants";

let app: FirebaseApp,
  auth: Auth,
  googleAuthProvider: GoogleAuthProvider,
  appleAuthProvider: OAuthProvider,
  firestore: Firestore,
  storage: FirebaseStorage,
  uploadStorage: FirebaseStorage,
  functions: Functions,
  rtdb: Database,
  analytics: Analytics;
// messaging: Messaging

export async function firebaseInit() {
  app = initializeApp(CONST.firebaseConfig);
  analytics = getAnalytics(app);
  // Todo: turn on analytics for prod env
  // firebase.analytics();
  auth = getAuth(app);
  setPersistence(auth, browserSessionPersistence);
  // auth.tenantId = "webapp-tenant-b5y69"
  firestore = getFirestore(app);
  storage = getStorage(app);
  uploadStorage = getStorage(app, CONST.firebaseConfig.uploadBucketURL);

  functions = getFunctions(app);
  googleAuthProvider = new GoogleAuthProvider();
  googleAuthProvider.addScope("profile");
  googleAuthProvider.addScope("email");
  appleAuthProvider = new OAuthProvider("apple.com");
  appleAuthProvider.addScope("email");
  appleAuthProvider.addScope("name");
  // messaging = getMessaging(app)
  rtdb = getDatabase(app);

  // hack: making getToken call twice, once here, another in context
  // to cache the results so that the second call resolves immedietely to not display backdrop to give unpleasant flash
  // getToken(messaging).then((token) => {
  //     console.log(`undo: global token:`, token)
  // }).catch((err: any) => {
  //     console.log(`undo: global token error`)
  //     console.error(err)
  // })

  // This is called when a notification is received while the app is in focus.
  // When the app is not in focus or if the tab is closed, this function is not called and the FCM notifications is
  // handled by the Service worker which displays a browser popup notification if the browser supports it.
  // const unsubscribe = onMessage(messaging, (payload) => {
  //     console.log('undo: token Notifications received.', payload);
  //     if (payload.notification) {
  //         // do your work here
  //     }
  // })
}

export {
  auth,
  googleAuthProvider,
  appleAuthProvider,
  firestore,
  storage,
  uploadStorage,
  functions,
  app,
  rtdb,
  analytics,
};