import React from "react"
import SickIcon from '@mui/icons-material/Sick';
import { Box } from "@mui/system";
import { Button, Typography } from "@mui/material";
import Logo from "../Shared/Logo";
import theme from "../../assets/theme/theme";

export interface Props {
}

const Page404: React.FC<Props> = (props) => {
  return (
    <Box sx={{ display: "flex", flexDirection: "column", minHeight: "100vh", justifyContent: "center", alignItems: "center" }}>
      <Logo />
      <Box sx={{ display: "flex", color: theme.palette.primary.main, justifyContent: "center", alignItems: "center", '&:hover': { color: theme.palette.primary.dark } }} >
        <Typography sx={{ fontSize: "150px" }} >4</Typography>
        <SickIcon color="disabled" sx={{ fontSize: "140px", paddingTop: "12px" }} />
        <Typography sx={{ fontSize: "150px", paddingBottom: "10px" }} >4</Typography>
      </Box>
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <Typography sx={{ fontSize: "20px" }}>The page you are looking for could not be found.</Typography>
      </Box>
      <Box mt={5} sx={{ display: "flex", justifyContent: "center" }}>
        <Button variant='outlined' component="a" href="/" >Take me back</Button>
      </Box>
    </Box>
  )
}

export default Page404
