import { Box } from "@mui/material"
import React from "react"
import { DcDocumentIconButton } from "../../assets/theme/theme"
import CloseIcon from '@mui/icons-material/Close'

export interface Props {
  onClick: React.MouseEventHandler<HTMLButtonElement> | undefined
}

const DialogCloseIconBtn: React.FC<Props> = (props) => {
  return (
    <Box sx={{
      position: 'absolute',
      right: 16,
      top: 16
    }}>
      <DcDocumentIconButton onClick={props.onClick}>
        <CloseIcon fontSize='small' />
      </DcDocumentIconButton>
    </Box>

  )
}

export default DialogCloseIconBtn
