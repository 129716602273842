import {
  Alert,
  Button,
  CircularProgress,
  Container,
  FormControl,
  Grid,
  Hidden,
  IconButton,
  InputLabel,
  Snackbar,
  Stack,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import PhoneInput from "react-phone-number-input";
import React, { useState } from "react";
import Illustration from "../../assets/images/illustrations/auth_illustration.svg";
import theme, { DCButton, FormWrapper } from "../../assets/theme/theme";
import { useAuth } from "../../contexts/AuthContext";
import { CountryCode } from "libphonenumber-js/types";
import "react-phone-number-input/style.css";
import CloseIcon from "@mui/icons-material/Close";
import { useHistory } from "react-router-dom";
import Logo from "../Shared/Logo";
import { grey } from "@mui/material/colors";
import OtpInput from "react-otp-input";
import { useUserContext } from "../../contexts/UserContext";

export interface Props { }

const EnrollMFA: React.FC<Props> = (props) => {
  const [countryCode, setCountryCode] = React.useState<
    CountryCode | undefined
  >();
  const [resendTimer, setResendTimer] = React.useState<number>(60);
  const {
    currentUser,
    setPhoneNumber,
    phoneNumber,
    OTP,
    setOTP,
    sendOTP,
    verifyOTP,
    isOTPSent,
    openError,
    closeErrorMoadal,
    errMsg,
    buttonIsLoading,
    isMultiFactorAuth,
  } = useAuth();
  const { updateUserPhoneNumber } = useUserContext();
  const history = useHistory();
  // Action for Snackbar
  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={closeErrorMoadal}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  const getUserCountryCode = React.useCallback(async () => {
    try {
      const res = await fetch("https://geolocation-db.com/json/");
      const data = await res.json();
      setCountryCode(data.country_code);
    } catch {
      // Resolve with "US" as a country code when geolocation is not able to detect the country.
      setCountryCode("US");
      console.log("No data Found. Using Default Country Code US.");
      return { error: "No data Found. Using Default Country Code US." };
    }
  }, []);

  React.useEffect(() => {
    getUserCountryCode();
  }, [getUserCountryCode]);

  const [error] = useState("");

  // async function handleSubmit(e: any) {
  //   e.preventDefault();

  //   try {
  //     setError('');
  //     setLoading(true);
  //     await signup(emailInput, passwordInput, nameInput);
  //     history.push('/email-verify');
  //   } catch (error: any) {
  //     setError(`${error.code}: Failed to create an account.`);
  //   }

  //   setLoading(false);
  // }

  React.useEffect(() => {
    if (isOTPSent) {
      if (resendTimer > 0) {
        setTimeout(() => {
          setResendTimer((prev) => prev - 1);
        }, 1000);
      }
    }
  }, [isOTPSent, resendTimer]);

  React.useEffect(() => {
    if (isMultiFactorAuth !== null) {
      history.push("/");
    }
  }, [history, isMultiFactorAuth]);

  return (
    <Container maxWidth={false} disableGutters className="full-height">
      <div id="recaptcha-container"></div>
      <Grid container spacing={0} className="full-height">
        <Hidden mdDown>
          <Grid item xs={12} sm={7}>
            <Box
              display="flex"
              flex={1}
              flexDirection="column"
              className="full-height"
            >
              <Box mt={6} ml={8}>
                <Logo />
              </Box>
              <Box width="80%" m="auto">
                <img src={Illustration} alt="Happy" width="100%" />
              </Box>
            </Box>
          </Grid>
        </Hidden>
        <Grid item sm={12} md={5} flex={1}>
          <FormWrapper>
            <Box width="70%" mx="auto">
              <Hidden mdUp>
                <Box p={8} textAlign="center">
                  <Logo height={50} />
                </Box>
              </Hidden>
              <Grid container spacing={0} justifyContent="space-between">
                <Grid item>
                  <Typography variant="h3" component="h1" fontWeight={500}>
                    Enroll for 2 factor authentication
                  </Typography>
                </Grid>
              </Grid>
              {error && (
                <Box mt={4} mb={2}>
                  <Alert variant="outlined" severity="error">
                    {error}
                  </Alert>
                </Box>
              )}
              <Stack direction="column" spacing={4} mt={4}>
                <Grid item></Grid>
                {isOTPSent ? (
                  <>
                    <FormControl variant="standard" fullWidth>
                      <InputLabel shrink htmlFor="phoneNumber">
                        Phone Number
                      </InputLabel>
                      <PhoneInput
                        style={{
                          marginTop: "24px",
                        }}
                        international
                        defaultCountry={countryCode}
                        id="phone-number-input"
                        value={phoneNumber}
                        numberInputProps={{
                          style: {
                            background: theme.palette.common.white,
                            borderRadius: 10,
                            position: "relative",
                            border: "1px solid #D3D3D3",
                            fontSize: 16,
                            width: "100%",
                            padding: "10px 12px",
                            height: 22,
                            boxShadow: "0 1px 3px rgba(132, 183, 252, .16)",
                            transition: theme.transitions.create([
                              "border-color",
                              "background-color",
                              "box-shadow",
                            ]),
                            // Use the system font instead of the default Roboto font.
                            fontFamily:
                              'ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"',
                            "&:focus": {
                              borderRadius: 10,
                              borderColor: "#4B84FF",
                            },
                            "&:disabled": {
                              background: "#F7F7F7",
                              color: "#222",
                              WebkitTextFillColor: "#222",
                              pointer: "not-allowed",
                            },
                          },
                        }}
                        withCountryCallingCode
                        onChange={(phoneNumber: any) =>
                          setPhoneNumber(phoneNumber)
                        }
                        disabled
                      />
                    </FormControl>
                    <FormControl
                      variant="standard"
                      sx={{ width: "100%" }}
                      error={!!error}
                    >
                      <InputLabel shrink htmlFor="bootstrap-input">
                        Verification Code
                      </InputLabel>
                      <OtpInput
                        value={OTP}
                        onChange={(otp: any) => setOTP(otp)}
                        numInputs={6}
                        renderInput={(props) => {
                          return <input {...props} className="verification-input" />;
                        }}
                        containerStyle={{
                          marginTop: "32px",
                          justifyContent: "space-between",
                        }}
                        inputStyle={{
                          width: "44px",
                          height: "50px",
                          fontSize: 14,
                          borderRadius: 12,
                          border: "1px solid rgba(0, 0, 0, 0.3)",
                        }}
                        shouldAutoFocus={true}
                      />
                    </FormControl>
                  </>
                ) : (
                  <FormControl variant="standard" fullWidth>
                    <InputLabel shrink htmlFor="phoneNumber">
                      Phone Number
                    </InputLabel>
                    <PhoneInput
                      style={{
                        marginTop: "24px",
                      }}
                      international
                      defaultCountry={countryCode}
                      id="phone-number-input"
                      value={phoneNumber}
                      numberInputProps={{
                        style: {
                          background: theme.palette.common.white,
                          borderRadius: 10,
                          position: "relative",
                          border: "1px solid #D3D3D3",
                          fontSize: 16,
                          width: "100%",
                          padding: "10px 12px",
                          height: 22,
                          boxShadow: "0 1px 3px rgba(132, 183, 252, .16)",
                          transition: theme.transitions.create([
                            "border-color",
                            "background-color",
                            "box-shadow",
                          ]),
                          // Use the system font instead of the default Roboto font.
                          fontFamily:
                            'ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"',
                          "&:focus": {
                            borderRadius: 10,
                            borderColor: "#4B84FF",
                          },
                          "&:disabled": {
                            background: "#F7F7F7",
                            color: "#222",
                            WebkitTextFillColor: "#222",
                            pointer: "not-allowed",
                          },
                        },
                      }}
                      withCountryCallingCode
                      onChange={(phoneNumber: any) =>
                        setPhoneNumber(phoneNumber)
                      }
                    />
                  </FormControl>
                )}
                {isOTPSent ? (
                  <>
                    <DCButton
                      type="submit"
                      size="large"
                      variant="contained"
                      sx={{ minHeight: 44 }}
                      onClick={async (e) => {
                        e.preventDefault();
                        await updateUserPhoneNumber(currentUser?.uid as string, phoneNumber);
                        await verifyOTP();
                      }}
                    > 
                      {buttonIsLoading ? (
                        <CircularProgress size={24} sx={{ color: "white" }} />
                      ) : (
                        "Verify Code"
                      )}
                    </DCButton>
                    <Box
                      sx={{ display: "flex", justifyContent: "center", mt: 2 }}
                    >
                      {resendTimer === 0 ? (
                        <Button
                          sx={{
                            ml: 1,
                            "&.MuiButtonBase-root:hover": {
                              bgcolor: "transparent",
                              color: theme.palette.primary.main,
                              boxShadow: "none",
                            },
                          }}
                          disableRipple
                          onClick={() => {
                            sendOTP();
                            setResendTimer(60);
                          }}
                        >
                          {" "}
                          Resend Verification Code
                        </Button>
                      ) : (
                        <Button
                          sx={{
                            ml: 1,
                            color: grey[600],
                            "&.MuiButtonBase-root:hover": {
                              bgcolor: "transparent",
                              boxShadow: "none",
                            },
                          }}
                          disableRipple
                          disabled
                        >
                          {" "}
                          Resend Verification Code in {resendTimer} seconds
                        </Button>
                      )}
                    </Box>
                  </>
                ) : (
                  <DCButton
                    type="submit"
                    size="large"
                    variant="contained"
                    sx={{ minHeight: 44 }}
                    onClick={async (e) => {
                      e.preventDefault();
                      await sendOTP();
                    }}
                  >
                    {buttonIsLoading ? (
                      <CircularProgress size={24} sx={{ color: "white" }} />
                    ) : (
                      "Send Verification Code"
                    )}
                  </DCButton>
                )}
              </Stack>
            </Box>
          </FormWrapper>
        </Grid>
        <Snackbar
          sx={{
            ".MuiSnackbarContent-root": {
              background: theme.palette.error.main,
            },
          }}
          open={openError}
          autoHideDuration={6000}
          message={errMsg}
          onClose={closeErrorMoadal}
          action={action}
        />
      </Grid>
    </Container>
  );
};

export default EnrollMFA;
