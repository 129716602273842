import * as React from "react";
import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";
function NotificationIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" width="24" height="24">
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" d="M8 19.5455C8.6586 21.0273 10.3784 22 12 22C13.6216 22 15.3414 21.0273 16 19.5455M18 11.1902V8C18 4.68629 15.3137 2 12 2V2C8.68629 2 6 4.68629 6 8V11.1902C6 12.0239 5.64615 12.8185 5.02647 13.3762L3.91854 14.3733C2.59604 15.5636 3.01797 17.7338 4.69009 18.3419L4.90109 18.4186C9.48681 20.0861 14.5132 20.0861 19.0989 18.4186L19.3099 18.3419C20.982 17.7338 21.404 15.5636 20.0815 14.3733L18.9735 13.3762C18.3539 12.8185 18 12.0239 18 11.1902Z" stroke="#2F384C"/>
    </svg>
    </SvgIcon>
  );
}
export default NotificationIcon;
