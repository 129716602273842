const NewFolderIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="122" height="99" fill="none">
    <g clipPath="url(#a)">
      <path fill="#F0F0F0" d="M110.33 6.317H32.396a7 7 0 0 0-7 7v52.105a7 7 0 0 0 7 7h77.934a7 7 0 0 0 7-7V13.317a7 7 0 0 0-7-7Z"/>
      <path fill="#455DEE" stroke="#455DEE" strokeMiterlimit="10" d="M57.751 14.839 50.22 4.687A10.359 10.359 0 0 0 41.902.5h-31.04A10.359 10.359 0 0 0 .5 10.856v76.693a10.359 10.359 0 0 0 10.36 10.359h99.399a10.36 10.36 0 0 0 10.359-10.359V29.385a10.357 10.357 0 0 0-10.356-10.36H66.075a10.362 10.362 0 0 1-8.324-4.186Z"/>
      <path fill="#fff" d="M61 74c8.837 0 16-7.163 16-16s-7.163-16-16-16-16 7.163-16 16 7.163 16 16 16Z"/>
      <path fill="#455DEE" d="M68 59v-2h-6v-6h-2v6h-6v2.029L60 59v6h2v-5.971L68 59Z"/>
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h121.114v98.408H0z"/>
      </clipPath>
    </defs>
  </svg>
)

export default NewFolderIcon
