import { Box, Grow, Button } from "@mui/material";
import { ImageSource, getEditorDefaults } from "@pqina/pintura";
import { PinturaEditor } from "@pqina/react-pintura";
import React from "react";
import ImageViewer from "./ImageViewer";
import "@pqina/pintura/pintura.css";

export interface PinturaImageViewerProps {
  // base64 object src
  src: ImageSource;
  disableEdit?: boolean;
  editProps?: {
    onEditFinish: (imgUrl: string, imgFile: File) => any;
    onEditModeEnter: () => any;
  };
}

export const PinturaImageViewer: React.FC<PinturaImageViewerProps> = ({
  src,
  disableEdit,
  editProps,
}) => {
  const [editState, setEditState] = React.useState<boolean>(false);
  const editorConfig = getEditorDefaults();

  return (
    <>
      {!disableEdit && !editState && (
        <Box
          style={{ display: "flex", justifyContent: "end", marginRight: 30 }}
        >
          <Grow in={true}>
            <Box>
              <Button
                variant="outlined"
                size="small"
                onClick={() => {
                  setEditState(true);
                  editProps?.onEditModeEnter();
                }}
              >
                Edit Image
              </Button>
            </Box>
          </Grow>
        </Box>
      )}

      {editState && (
        <Box sx={{ height: "100%" }} className="imageEditor">
          {/* cropAutoCenterImageSelectionTimeout : Auto center the image while cropping within 1 second.
                    onProcess => The done button in the editor is onProcess butoon. This results the image after export
                  */}
          <PinturaEditor
            key={src.toString()}
            {...editorConfig}
            utils={["crop"]}
            previewUpscale={true}
            cropEnableButtonRotateRight
            cropEnableZoomInput={false}
            cropEnableRotationInput={false}
            cropAutoCenterImageSelectionTimeout={1}
            src={src}
            onProcess={(res) => {
              editProps?.onEditFinish(URL.createObjectURL(res.dest), res.dest);
              setEditState(false);
            }}
          ></PinturaEditor>
        </Box>
      )}

      <ImageViewer image={src} />
    </>
  );
};
