import { ref, set } from "firebase/database";
import { get, isEmpty } from "lodash";
import React from "react";
import { rtdb } from "../firebase";
import { IUser } from "../firebaseTypes";
import { defaultUser } from "../utils/userUtils";
import { useAuth } from "./AuthContext";
import { useUserContext } from "./UserContext";
import AccountDeleteConfirmationModal from "../components/AccountDeleteConfirmationModal";

export interface IUserProfileContext {
  userProfile?: IUser;
  error: string;
  accountDeleteConfirmationModalOpen: boolean;
  setAccountDeleteConfirmationModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setUserProfile: React.Dispatch<React.SetStateAction<IUser>>;
  createUserProfile: (user: IUser) => Promise<boolean>;
}

const defaultValues: IUserProfileContext = {
  error: "",
  accountDeleteConfirmationModalOpen: false,
  setAccountDeleteConfirmationModalOpen: () => { },
  setUserProfile: () => { },
  createUserProfile: () => Promise.resolve(true),
};

const Context = React.createContext<IUserProfileContext>(defaultValues);

export function useUserProfileContext() {
  return React.useContext(Context);
}

export const UserProfileProvider: React.FC = (props) => {
  const { currentUser } = useAuth();
  const { user, setUser } = useUserContext();
  const [userProfile, setUserProfile] = React.useState(
    defaultUser(currentUser)
  );
  const [accountDeleteConfirmationModalOpen, setAccountDeleteConfirmationModalOpen] = React.useState(false);
  const [error, setError] = React.useState(defaultValues.error);

  React.useEffect(() => {
    user && setUserProfile(user);
  }, [user]);

  const createUserProfile = React.useCallback(
    async (user: IUser) => {
      try {
        const uid = get(currentUser, "uid", "");
        if (isEmpty(uid)) return false;

        const userRef = ref(rtdb, `users/${uid}/user`);
        // make the first name and last name of current user camel case
        user.firstName = user.firstName.charAt(0).toUpperCase() + user.firstName.slice(1);
        user.lastName = user.lastName.charAt(0).toUpperCase() + user.lastName.slice(1);
        await set(userRef, user);
        setUser(user);
        return true;
        // remove:
        // const docRef = doc(firestore, `${constants.collections.users}/${get(currentUser, 'uid', '')}`)
        // await setDoc(docRef, user, { merge: true })
        // setUser(user)
        // return true
      } catch (error: any) {
        console.error(error);
        setError("Problem while saving the user. Please retry.");
        return false;
      }
    },
    [currentUser, setUser]
  );

  return (
    <Context.Provider
      value={{
        error,
        accountDeleteConfirmationModalOpen,
        setAccountDeleteConfirmationModalOpen,
        userProfile,
        setUserProfile,
        createUserProfile,
      }}
    >
      {props.children}
      <AccountDeleteConfirmationModal
        open={accountDeleteConfirmationModalOpen}
        setOpen={setAccountDeleteConfirmationModalOpen}
      />
    </Context.Provider>
  );
};
