import * as React from "react";
import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";
function MoreIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        width="24"
        height="24"
      >
        <path
          strokeLinecap="round"
          strokeWidth="1.5"
          d="M11.5 7C12.875 7 14 5.875 14 4.5C14 3.125 12.875 2 11.5 2C10.125 2 9 3.125 9 4.5C9 5.875 10.125 7 11.5 7Z"
          stroke="currentColor"
        />
        <path
          strokeLinecap="round"
          strokeWidth="1.5"
          d="M11.5 9.5C10.125 9.5 9 10.625 9 12C9 13.375 10.125 14.5 11.5 14.5C12.875 14.5 14 13.375 14 12C14 10.625 12.875 9.5 11.5 9.5Z"
          stroke="currentColor"
        />
        <path
          strokeLinecap="round"
          strokeWidth="1.5"
          d="M11.5 17C10.125 17 9 18.125 9 19.5C9 20.875 10.125 22 11.5 22C12.875 22 14 20.875 14 19.5C14 18.125 12.875 17 11.5 17Z"
          stroke="currentColor"
        />
      </svg>
    </SvgIcon>
  );
}
export default MoreIcon;
