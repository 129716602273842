import { DesktopDatePicker, PickersDay } from "@mui/lab";
import { Tooltip } from "@mui/material";
import Box from "@mui/material/Box";
import { isAfter } from "date-fns";
import React, { FC, useRef, useState } from "react";
import { DcInput } from "../../assets/theme/theme";
import constants from "../../constants";
import { toDateTime, dateTimeToSeconds } from "../../utils/dateTimeUtils";
import { isEmpty } from "lodash";

interface Props {
  id?: string;
  value: Date | number;
  disabled?: boolean;
  placeholder?: string;
  onChange(date: Date, ms: number): void;
  shouldDisableDate?(data: Date): boolean;
}
export const DCDatePicker: FC<Props> = ({
  id,
  value,
  disabled,
  placeholder,
  onChange,
  shouldDisableDate,
}) => {
  const [open, setOpen] = useState(false);
  const closed = useRef(false);

  const onChangeDate = (newValue: Date | null) => {
    if (newValue) {
      const seconds = dateTimeToSeconds(newValue as Date);
      const date = toDateTime(seconds);
      date && onChange(date, seconds);
    }
  };

  let _value: any = value;

  if (value === undefined) {
    _value = null;
  } else {
    _value = typeof value === "number" ? toDateTime(value) : value;
  }

  return (
    <DesktopDatePicker
      open={open}
      onClose={() => {
        setOpen(false);
        closed.current = true;
      }}
      value={_value}
      onChange={onChangeDate}
      disableOpenPicker
      disabled={disabled}
      inputFormat={constants.dateFormat}
      shouldDisableDate={shouldDisableDate}
      renderInput={({ inputRef, inputProps }) => (
        <Box>
          {/* @ts-ignore */}
          <DcInput
            {...inputProps}
            ref={inputRef}
            id={id}
            onFocus={() => {
              if (!closed.current) {
                setOpen(true);
              }
              closed.current = false;
            }}
            fullWidth
            placeholder={placeholder || "DD/MM/YYYY"}
          />
        </Box>
      )}
      renderDay={(day, selectedDates, pickersDayProps) => {
        if (pickersDayProps.disabled) {
          if (isAfter(day, new Date())) {
            return <PickersDay {...pickersDayProps} />;
          }
          return (
            <Tooltip title="Looks like you were already traveling on these dates.">
              <span>
                <PickersDay {...pickersDayProps} />
              </span>
            </Tooltip>
          );
        }
        return <PickersDay {...pickersDayProps} />;
      }}
    />
  );
};
