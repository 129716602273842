import constants from "../constants";
import { toDocTypeSlug } from "./documentUtils";

export function feat_ownership(docType: string) {
  const slug = toDocTypeSlug(docType)
  if (slug === constants.docTypeSlugs.others) {
    return false
  }
  return true
}

export function feat_latest(docType: string) {
  const slug = toDocTypeSlug(docType)
  if (slug === constants.docTypeSlugs.others) {
    return false
  }
  return true
}

export function feat_expired(docType: string) {
  const slug = toDocTypeSlug(docType)
  if (slug === constants.docTypeSlugs.others || slug === constants.docTypeSlugs['i-797'] || slug === constants.docTypeSlugs['i-20']) {
    return false
  }
  return true
}