import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { FC, useEffect } from "react";
import { DCButton } from "../../assets/theme/theme";
import UploadIcon from "../Shared/Icons/UploadIcon";
import { useDropzoneContext } from "../../contexts/DropzoneContext";
import useDrivePicker from "../../external/react-google-drive-picker";
import { getAppConfig } from "../../utils/environment";
import { get, map } from "lodash";
import { useDocumentUploadContext } from "../../contexts/DocumentUploadContext";
import { useAuth } from "../../contexts/AuthContext";
import AddToDriveOutlinedIcon from "@mui/icons-material/AddToDriveOutlined";

interface Props {
  open: boolean;
  onClose: () => void;
}

const DISCOVERY_DOCS = [
  "https://www.googleapis.com/discovery/v1/apis/drive/v3/rest",
];

// const SCOPES = "https://www.googleapis.com/auth/drive.file";
const SCOPES = "https://www.googleapis.com/auth/drive.readonly"

export const UploadDocumentModeSelectionModal: FC<Props> = ({
  open,
  onClose,
}) => {
  const { open: openDropZone } = useDropzoneContext();
  const { uploadFiles } = useDocumentUploadContext();
  const [openPicker] = useDrivePicker();
  const { currentUser } = useAuth();

  const initGAPIClient = () => {
    (window as any).gapi.client
      .init({
        apiKey: (getAppConfig() as any).googlePickerApiKey,
        clientId: (getAppConfig() as any).clientId,
        discoveryDocs: DISCOVERY_DOCS,
        scope: SCOPES,
        include_granted_scopes: false,
      })
      .init(
        () => {
          console.log("initiated gapi client successfully!");
        },
        (err: any) => {
          console.log("client error", err);
        }
      );
  };

  useEffect(() => {
    setTimeout(() => {
      console.log(
        "Trying to load gapi here...",
        (currentUser as any).uid,
        (window as any).gapi
      );
      if (currentUser && (window as any).gapi) {
        console.log("Trying to load gapi...");
        (window as any).gapi.load("client", initGAPIClient);
      }
    }, 1000);
  }, [currentUser]);

  const handleOpenPicker = () => {
    openPicker({
      clientId: (getAppConfig() as any).clientId,
      developerKey: (getAppConfig() as any).googlePickerApiKey,
      viewId: "DOCS",
      // token: token, // pass oauth token in case you already have one
      showUploadView: false,
      showUploadFolders: false,
      supportDrives: true,
      multiselect: true,
      viewMimeTypes: "application/pdf,image/jpg,image/png",
      customScopes: [SCOPES],
      // customViews: customViewsArray, // custom view
      callbackFunction: async (data) => {
        if (data.action === "cancel") {
          console.log("User clicked cancel/close button");
        }

        if (data.action === "picked") {
          const documents = data["docs"];

          const promises = map(documents, (document) => {
            const fileId = document.id;
            console.log(fileId);
            return (window as any).gapi.client.drive.files.get({
              fileId: fileId,
              fields: "*",
              alt: "media",
            });
          });

          const downloadedFiles = await Promise.all(promises);
          const files = map(downloadedFiles, (downloadedFile, index) => {
            const fileBuffer = downloadedFile.body; // assuming it's a binary string
            const mimeType = get(documents, [index, "mimeType"]); // adjust according to your file type
            const fileName = get(documents, [index, "name"]);
            const arrayBuffer = new ArrayBuffer(fileBuffer.length);
            const uint8Array = new Uint8Array(arrayBuffer);
            for (let i = 0; i < fileBuffer.length; i++) {
              uint8Array[i] = fileBuffer.charCodeAt(i);
            }

            const blob = new Blob([uint8Array], { type: mimeType });
            const file = new File([blob], fileName, {
              type: mimeType,
            });
            console.log({ file });
            return file;
          });
          uploadFiles(files);
        }
      },
    });
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth={true}
      // sx={{
      //   "& .MuiDialog-container": {
      //     "& .MuiPaper-root": {
      //       width: "100%",
      //       maxWidth: "1000px",
      //     },
      //   },
      // }}
    >
      <DialogTitle>Upload Documents</DialogTitle>
      <DialogContent
        sx={{
          display: "flex",
          gap: 2,
          alignItems: "center",
          justifyContent: "center",
          my: 1,
        }}
      >
        <DCButton
          id="upload-docs-from-computer"
          startIcon={<UploadIcon />}
          variant="contained"
          color="primary"
          onClick={() => {
            openDropZone();
            onClose();
          }}
          size="large"
          sx={{ mb: 1 }}
        >
          Upload Docs from computer
        </DCButton>

        <DCButton
          id="upload-docs-from-drive"
          startIcon={<AddToDriveOutlinedIcon />}
          variant="contained"
          color="primary"
          onClick={() => {
            handleOpenPicker();
            onClose();
          }}
          size="large"
          sx={{ mb: 1 }}
        >
          Upload from Google Drive
        </DCButton>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" aria-label="close window" onClick={onClose}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};
