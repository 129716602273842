import React, { useCallback } from "react";
import {
  Button,
  Container,
  Grid,
  Stack,
  ToggleButton,
  Typography,
  Link as MLink,
  Chip,
  Tooltip,
  FormControlLabel,
  Radio,
} from "@mui/material";
import { Box } from "@mui/system";
import theme, { ViewSelectorButtonGroup } from "../../assets/theme/theme";

import NavTabs, { ITab } from "./NavTabs";
import DocumentsGrid from "./DocumentsGrid";
import DocumentsList from "./DocumentsList";

import DeleteIcon from "../Shared/Icons/DeleteIcon";

import SendIcon from "@mui/icons-material/ShareOutlined";

import { IFolder, useDocumentContext } from "../../contexts/DocumentContext";
import { isEmpty, map } from "lodash";
import {
  countSelectedDocuments,
  getSelectedDocuments,
  toDocTypeSlug,
} from "../../utils/documentUtils";
import { useHistory, useParams } from "react-router";
import ViewUploadDocsTitle from "../ViewUploadDocsTitle";
import { useDocumentGalleryContext } from "../../contexts/DocumentGalleryContext";
import { useDocumentShareModalContext } from "../../contexts/DocumentShareModalContext";
import { Redirect } from "react-router-dom";
import Spinner from "../Spinner";
import { LoadingButton } from "@mui/lab";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useDocumentDeleteModal } from "../../contexts/DocumentDeleteModalContext";
import { usePDFMergeContext } from "../../contexts/PDFMergerContext";
import RadioUncheckedIcon from "../Shared/Icons/RadioUncheckedIcon";
import RadioCheckedIcon from "../Shared/Icons/RadioCheckedIcon";
import PrinterIcon from "../Shared/Icons/PrinterIcon";
import GridIcon from "../Shared/Icons/GridIcon";
import ListIcon from "../Shared/Icons/ListIcon";
import { logEvent } from "firebase/analytics";
import { analytics } from "../../firebase";
import DownloadDocumentIcon from "../Shared/Icons/DownloadIcon";

export interface Props {}

function folderToTabs(folders: IFolder[]): ITab[] {
  return map(folders, (f, i) => ({
    value: toDocTypeSlug(f.title),
    href: `/documents/${toDocTypeSlug(f.title)}`,
    label: f.title,
    disabled: f.docs.length <= 0,
    secondaryLabel: `(${f.docs.length})`,
  }));
}

// aka. DocumentsGallery
// This component works based on the docType param from the URL.
const Documents: React.FC<Props> = (props) => {
  const {
    folders,
    documents,
    initializedDocumentsAndFolders: initalizedDocumentsAndFolders,
    downloadDocuments,
    getDocDownloadUrl,
  } = useDocumentContext();
  const { selectedDocuments, folderInView, clearSelection, onAllSelectClick } =
    useDocumentGalleryContext();

  const { print, isPrintProcessing } = usePDFMergeContext();
  const { openShareModalFor } = useDocumentShareModalContext();
  const { openDeleteModalFor } = useDocumentDeleteModal();
  const [view, setView] = React.useState<string | null>("grid");

  const [loadingDownloadBtn, setLoadingDownloadBtn] = React.useState(false);
  const [downloadURLs, setDownloadURLs] = React.useState<string[]>([]);
  const [downloadImgURLs, setDownloadImgURLs] = React.useState<string[]>([]);

  const { docType } = useParams<{ docType: string }>();
  const history = useHistory();
  const count = countSelectedDocuments(selectedDocuments);
  const navbarWrapperRef = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    const docs = getSelectedDocuments(selectedDocuments, documents);
    const selectedPdf = docs.filter(
      (img) => img.doc_format === "application/pdf"
    );
    const selectedImg = docs.filter(
      (img) =>
        img.doc_format === "image/jpeg" ||
        img.doc_format === "image/png" ||
        img.doc_format === "image/jpg"
    );

    const downloadURLs = map(selectedPdf, (d) => getDocDownloadUrl(d));
    setDownloadURLs(downloadURLs);

    const downloadImgURLs = map(selectedImg, (d) => getDocDownloadUrl(d));
    setDownloadImgURLs(downloadImgURLs);
  }, [selectedDocuments, documents, getDocDownloadUrl]);

  const onPrintDocument = useCallback(async () => {
    logEvent(analytics, "click_print_document", {
      downloadURLs,
      downloadImgURLs,
    });
    await print(downloadURLs, downloadImgURLs);
  }, [print, downloadURLs, downloadImgURLs]);

  const onDownloadDocument = useCallback(async () => {
    setLoadingDownloadBtn(true);
    const docs = getSelectedDocuments(selectedDocuments, documents);
    logEvent(analytics, "click_download_document", {
      documents: JSON.stringify(docs),
    });
    await downloadDocuments(docs);
    setLoadingDownloadBtn(false);
  }, [downloadDocuments, selectedDocuments, documents]);

  const onDeleteDocument = useCallback(async () => {
    const docs = getSelectedDocuments(selectedDocuments, documents);
    logEvent(analytics, "click_delete_document", {
      documents: JSON.stringify(docs),
    });
    openDeleteModalFor(docs);
  }, [selectedDocuments, documents, openDeleteModalFor]);

  const _handleAlignment = (
    event: React.MouseEvent<HTMLElement>,
    newView: string | null
  ) => {
    logEvent(analytics, "click_alignment", { newView });
    setView(newView);
  };
  const handleAlignment = useCallback(_handleAlignment, []);

  const onShareButtonClick = useCallback(() => {
    const docs = getSelectedDocuments(selectedDocuments, documents);
    logEvent(analytics, "click_share_document", {
      documents: JSON.stringify(docs),
    });
    openShareModalFor(docs);
  }, [selectedDocuments, openShareModalFor, documents]);

  if (!initalizedDocumentsAndFolders) {
    return <Spinner />;
  }

  // Redirect to /documents if this folder is empty, due to all docuements were deleted
  if (initalizedDocumentsAndFolders && isEmpty(folderInView?.docs)) {
    return <Redirect to="/documents" />;
  }
  const folderSlug = toDocTypeSlug(folderInView?.docType);
  const all = `${folderSlug}-all`;

  return (
    <Box
      pb={20}
      bgcolor={theme.palette.common.white}
      sx={{ minHeight: "calc(100% - 64px)" }}
    >
      {
        <Container maxWidth={false} disableGutters className="document-gallery-main">
          <Box p={4} pb={2} className="document-back">
            <Grid container justifyContent="space-between">
              <Grid item mb={2} flex={1}>
                <Typography
                  component={MLink}
                  underline="hover"
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    cursor: "pointer",
                    mb: 1,
                  }}
                  onClick={() => {
                    logEvent(analytics, "click_back");
                    history.push("/documents");
                  }}
                >
                  <ArrowBackIcon
                    sx={{ mr: 0.5, fontSize: 18 }}
                    fontSize="small"
                  />
                  Back
                </Typography>
                <Stack direction="row" justifyContent="space-between" className="documents-gallery">
                  <ViewUploadDocsTitle />
                  <Stack alignItems="center" direction="row" gap={1} className="document-blogs">
                    {count > 0 && (
                      <Tooltip title="Clear selection" placement="top" arrow>
                        <Chip
                          color="primary"
                          label={`${count} item${
                            count > 1 ? "s" : ""
                          } selected`}
                          onDelete={clearSelection}
                        />
                      </Tooltip>
                    )}
                    <LoadingButton
                      loading={isPrintProcessing}
                      onClick={onPrintDocument}
                      loadingPosition="start"
                      variant="outlined"
                      size="medium"
                      color="primary"
                      startIcon={<PrinterIcon />}
                      disabled={countSelectedDocuments(selectedDocuments) === 0}
                    >
                      Print
                    </LoadingButton>
                    <LoadingButton
                      loading={loadingDownloadBtn}
                      disabled={countSelectedDocuments(selectedDocuments) === 0}
                      onClick={onDownloadDocument}
                      loadingPosition="start"
                      size="medium"
                      variant="outlined"
                      color="primary"
                      startIcon={<DownloadDocumentIcon />}
                    >
                      Download
                    </LoadingButton>
                    <LoadingButton
                      disabled={countSelectedDocuments(selectedDocuments) === 0}
                      onClick={onDeleteDocument}
                      variant="outlined"
                      size="medium"
                      color="error"
                      startIcon={<DeleteIcon />}
                    >
                      Delete
                    </LoadingButton>
                    <Button
                      variant="outlined"
                      color="primary"
                      size="medium"
                      disabled={countSelectedDocuments(selectedDocuments) === 0}
                      onClick={onShareButtonClick}
                      startIcon={<SendIcon />}
                    >
                      Share
                    </Button>
                    <ViewSelectorButtonGroup
                      value={view}
                      exclusive
                      aria-label="View"
                      color="primary"
                      onChange={handleAlignment}
                    >
                      <ToggleButton value="grid" aria-label="left aligned">
                        <GridIcon />
                      </ToggleButton>
                      <ToggleButton value="list" aria-label="centered">
                        <ListIcon />
                      </ToggleButton>
                    </ViewSelectorButtonGroup>
                  </Stack>
                </Stack>
              </Grid>
            </Grid>
            <Box borderBottom={1} borderColor="divider" ref={navbarWrapperRef}>
              <NavTabs
                selectedValue={toDocTypeSlug(docType)}
                tabs={folderToTabs(folders)}
              />
            </Box>
          </Box>

          <Grid item>
            <Stack spacing={2} direction="row" px={4} alignItems="center">
              <Box sx={{ mr: "auto" }}>
                <FormControlLabel
                  label={<Typography component="span">Select All</Typography>}
                  control={
                    <Radio
                      checked={Boolean(selectedDocuments[all])}
                      onClick={onAllSelectClick}
                      icon={<RadioUncheckedIcon />}
                      checkedIcon={<RadioCheckedIcon />}
                    />
                  }
                />
              </Box>
            </Stack>
          </Grid>
          <Box p={4} pt={2} className="document-gallery-blogs">
              {view === "grid" ? <DocumentsGrid /> : <DocumentsList />}
          </Box>
        </Container>
      }
    </Box>
  );
};

export default Documents;
