import {
  Box,
  Grid,
  Typography,
} from '@mui/material';
import { usePublicDocumentSharePageContext } from '../../contexts/PublicDocumentSharePageContext';
import { map } from 'lodash';
import SharePageCard from './SharePageCard';
import SharePageCardExpired from './SharePageCardExpired';

const leftMargin = '60px';

const GridView = () => {
  const { shareReq, shareReqExpired } = usePublicDocumentSharePageContext();

  
  return (
    <Box m={`30px ${leftMargin}`}>
      <Grid container spacing={3}>
        {map(shareReq?.documents, (doc, i) => {
          return (
            <Grid
              item
              xs={12}
              sm={6}
              md={4}
              display="flex"
              key={i}
            >
              <SharePageCard key={i} index={i} doc={doc} isViewOnly={shareReq?.permission === "viewOnly"} />
            </Grid>
          );
        })}
      </Grid>
      {shareReqExpired!.documents.length > 0 && (
        <>
          <Typography sx={{ fontSize: 16, my: 3 }}>Expired Documents</Typography>
          <Grid container spacing={3}>
            {map(shareReqExpired?.documents, (doc, i) => {
              return (
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={4}
                  display="flex"
                  key={i}
                >
                  <SharePageCardExpired key={i} index={i} doc={doc} isViewOnly={shareReq?.permission === "viewOnly"} />
                </Grid>
              );
            })}
          </Grid>
        </>
      )}
    </Box>
  );
};
export default GridView;
