import { filter, find, isEmpty } from "lodash";
import React, { useCallback } from "react";
import { rtdb }  from "../firebase";
import {
  get,
  onValue,
  ref,
  runTransaction,
  set,
  update,
} from "firebase/database";
import { IUser, IResult, ICustomFolder } from "../firebaseTypes";
import { toDocTypeSlug } from "../utils/documentUtils";
import { useAuth } from "./AuthContext";
import { defaultUser } from "../utils/userUtils";

export interface IUserContext {
  user?: IUser;
  setUser: React.Dispatch<React.SetStateAction<IUser | undefined>>;
  addUserCustomFolder: (folderName: string) => Promise<IResult>;
  deleteUserCustomFolder: (folderName: string) => Promise<IResult>;
  updateUser(id: string, userData: Partial<IUser>): Promise<boolean>;
  updateUserPhoneNumber(id: string, phoneNumber: string): Promise<boolean>;
  initializedUser: boolean;
}

const defaultValues: IUserContext = {
  initializedUser: false,
  setUser: () => { },
  deleteUserCustomFolder: () => Promise.resolve({ success: false, error: "" }),
  addUserCustomFolder: () => Promise.resolve({ success: false, error: "" }),
  updateUser: () => Promise.resolve(true),
  updateUserPhoneNumber: () => Promise.resolve(true),
};

const UserContext = React.createContext<IUserContext>(defaultValues);

export function useUserContext() {
  return React.useContext(UserContext);
}

export const UserProvider: React.FC = (props) => {
  const [user, setUser] = React.useState(defaultValues.user);
  const [initializedUser, setInitializedUser] = React.useState(
    defaultValues.initializedUser
  );
  // contexts
  const { currentUser } = useAuth();

  const addUserCustomFolder = React.useCallback(
    async (folderName: string): Promise<IResult> => {
      try {
        if (currentUser && currentUser.uid) {
          const customFoldersRef = ref(
            rtdb,
            `users/${currentUser.uid}/user/customFolders`
          );
          const result = await runTransaction(
            customFoldersRef,
            (_customFolders) => {
              const customFolders = _customFolders || [];
              const doesAlreadyExist = find(
                customFolders,
                (cf) => toDocTypeSlug(cf.name) === toDocTypeSlug(folderName)
              );
              // I am not checking existence in automatic folders because we allow users to create custom-folders with same names as auto folders and we differentiate both with custom flag in IFolder.
              if (!!doesAlreadyExist) {
                // `undefined` is returned (i.e. you return with no arguments) the * transaction will be aborted and the data at this location will not be * modified.
                return;
              }
              // save folder in db
              const newFolder: ICustomFolder = {
                name: folderName,
              };
              const newCustomFolders = [...customFolders, newFolder];
              return newCustomFolders;
            }
          );
          if (result.committed) {
            return { success: true, error: "" };
          } else {
            return {
              success: false,
              error: "Folder already exists with similar name.",
            };
          }
        }
      } catch (error) {
        console.error(error);
        return { success: false, error: "Something went wrong!" };
      }
      return { success: true, error: "" };
    },
    [currentUser]
  );

  const deleteUserCustomFolder = React.useCallback(
    async (folderName: string): Promise<IResult> => {
      try {
        if (currentUser && currentUser.uid) {
          const customFoldersRef = ref(
            rtdb,
            `users/${currentUser.uid}/user/customFolders`
          );
          const result = await runTransaction(
            customFoldersRef,
            (_customFolders) => {
              const customFolders = _customFolders || [];
              const removed = filter(
                customFolders,
                (cf) => cf.name !== folderName
              );
              return removed;
            }
          );
          if (result.committed) {
            return { success: true, error: "" };
          } else {
            return {
              success: false,
              error: "Sorry! couldn't delete folder, something went wrong!",
            };
          }
        }
      } catch (error) {
        console.error(error);
        return {
          success: false,
          error: "Sorry! couldn't delete folder, something went wrong!",
        };
      }
      return { success: true, error: "" };
    },
    [currentUser]
  );

  const updateUser = useCallback(
    async (id: string, userData: Partial<IUser>) => {
      if (isEmpty(id) || isEmpty(userData)) return false;

      const userRef = ref(rtdb, `users/${id}/user`);
      await update(userRef, userData as IUser);
      return true;

      // const path = `${constants.collections.users}/${id}`;
      // const docRef = doc(firestore, path);
      // await updateDoc(docRef, userData);
      // return true;
    },
    []
  );

  const updateUserPhoneNumber = useCallback(
    async (id: string, phoneNumber: string) => {
      if (isEmpty(id) || isEmpty(phoneNumber)) return false;

      const userRef = ref(rtdb, `users/${id}/user`);
      await update(userRef, { phoneNumber });
      return true;
    }, []
  );


  // React.useEffect(() => {
  //   if (!currentUser?.uid) {
  //     setInitializedUser(true);
  //   }
  // }, [currentUser?.uid]);

  React.useEffect(() => {
    async function init() {
      if (currentUser && currentUser.uid) {
        const userRef = ref(rtdb, `/users/${currentUser?.uid}/user`);
        const unSub = onValue(userRef, (snapshot) => {
          const user = snapshot.val();
          setUser(user);
          console.log("init user");
          setInitializedUser(true);
        });
        // defaultTimer when user is not in db, for first time users, registering this after onValue is registered
        const snapshot = await get(userRef);
        if (snapshot.exists()) {
          setUser(snapshot.val());
          console.log("init user");
          setInitializedUser(true);
        } else {
          console.log("init user - new user");
          // Create user when it doesn't exist for first time users
          console.log(`Creating user profile...`);
          await set(userRef, defaultUser(currentUser));
          setInitializedUser(true);
        }
        // setTimeout(() => {
        //   console.log("init user - settimeout");
        //   setInitializedUser(true);
        // }, 5000);
        return unSub;
      }
    }
    init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser, setUser]);

  return (
    <UserContext.Provider
      value={{
        user,
        setUser,
        addUserCustomFolder,
        deleteUserCustomFolder,
        updateUser,
        updateUserPhoneNumber,
        initializedUser,
      }}
    >
      {props.children}
    </UserContext.Provider>
  );
};
