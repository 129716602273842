import O1EB1DashboardGallery from './O1EB1DashboardGallery'
import { Box, Grid, LinearProgress, Typography } from '@mui/material'
import O1EB1Summary from './O1EB1Summary'
import { DCButton, DCDashboardContainer, DCDashboardPageHeader } from '../../assets/theme/theme'
import Illustration from "../../assets/images/illustrations/page_header_clouds.svg";
import O1EB1DisabledIllustration from "../../assets/images/illustrations/o1eb1_disabled.svg";
import { useO1EB1DocumentsContext } from '../../contexts/O1EB1DocumentsContext';
import { useUserContext } from '../../contexts/UserContext';
import { useState } from 'react';

const O1EB1Loader = () => {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', width: '100%', height: '100%', gap: 2 }} >
      <Typography fontSize={14}>
        Loading O1EB1 Documents...
      </Typography>
      <LinearProgress sx={{ width: '25%', height: 5 }} />
    </Box>
  )
}

const O1EB1 = () => {
  const { initalizedDocumentsAndFolders } = useO1EB1DocumentsContext();
  const { user, updateUser } = useUserContext();
  const [isLoading, setIsLoading] = useState(false);

  if (!initalizedDocumentsAndFolders) return <O1EB1Loader />

  return (
    <Box>
      {user?.o1eb1 ? (
        <>
          <Box>
            <DCDashboardPageHeader elevation={0} sx={{ pb: 16 }}>
              <Grid container mb={1}>
                <Grid item xs={12} >
                  {/* // make the flex direction column if the screen size is small */}
                  <Box sx={{ display: 'flex', width: '100%', justifyContent: 'space-between', 
                    flexDirection: { xs: 'column', sm: 'row' }, alignItems: 'center', gap: 2
                  }}>
                    <Typography
                      variant="h1"
                      component="h1"
                      fontWeight={600}
                      fontSize={24}
                    >
                      O1 / EB1 Documents
                    </Typography>
                    <DCButton variant='contained' color='error' disabled={isLoading} sx={{ position: 'relative',  zIndex: 50}} onClick={async () => {
                      setIsLoading(true);
                      await updateUser(user?.id!, { o1eb1: false })
                      setIsLoading(false);
                    }}>
                      Disable O1 / EB1
                    </DCButton>
                  </Box>
                </Grid>
                <Grid item position="relative" flex={1}>
                  <img
                    src={Illustration}
                    className="page-header--ilustration"
                    alt=""
                  />
                </Grid>
              </Grid>
            </DCDashboardPageHeader>
          </Box>
          <DCDashboardContainer>
            <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
              <O1EB1Summary />
              <O1EB1DashboardGallery />
            </Box>
          </DCDashboardContainer>
        </>
      ) : (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3, justifyContent: 'center', alignItems: 'center', pt: 20 }}>
          <Box>
            <img src={O1EB1DisabledIllustration} alt='O1/EB1 Disabled illustration' style={{ width: 350 }} />
          </Box>
          <Typography component={"h3"} maxWidth={500} textAlign="center" fontSize={14}>
            This is a dedicated tool for managing O1 and EB1 documents. Easily organize, track, and stay on top of your application process. 
            <br />
            <br />
            Activate it now to streamline your journey!
          </Typography>
          <DCButton variant='contained' disabled={isLoading} onClick={async () => {
            setIsLoading(true);
            await updateUser(user?.id!, { o1eb1: true })
            setIsLoading(false);
          }}>
            Enable O1 / EB1
          </DCButton>
        </Box>
      )}
    </Box>
  )
}

export default O1EB1