import React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import Chip from "@mui/material/Chip";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import Typography from "@mui/material/Typography";
import theme from "../../assets/theme/theme";

import LoadingButton from "@mui/lab/LoadingButton";
import { Box } from "@mui/system";
import WarningOutlineIcon from "./Icons/WarningOutlineIcon";
import { Stack } from "@mui/material";

interface Props {
  heading: string;
  subHeading: string;
  cardTitle: string;
  open: boolean;
  setOpen: any;
  onConfirm: any;
}
const ConfirmDialog: React.FC<Props> = (props) => {
  const { heading, subHeading, cardTitle, open, setOpen, onConfirm } = props;

  const truncate = (text: string, maxLength: number) => {
    if (text.length > maxLength) {
      return `${text.substring(0, maxLength)}...`;
    }
    return text;
  };

  return (
    <Dialog maxWidth="sm" open={open}>
      <DialogContent>
        <Stack spacing={2} direction="row" mt={2} mb={2}>
          <Box
            sx={{
              bgcolor: theme.palette.error.light,
              width: 40,
              height: 40,
              borderRadius: 40,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              color: theme.palette.error.main,
            }}
          >
            <WarningOutlineIcon />
          </Box>
          <Stack spacing={1} flex={1}>
            <Typography fontSize={20} fontWeight={500}>
              {heading}
            </Typography>
            <Typography>{subHeading}</Typography>
            <Box>
              <Typography color={theme.palette.text.secondary} mt={1} mb={1}>
                Card Name
              </Typography>
              <Chip label={truncate(cardTitle, 20)} />
            </Box>
          </Stack>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          onClick={() => {
            setOpen(false);
          }}
        >
          Cancel
        </Button>

        <LoadingButton
          loadingPosition="center"
          variant="contained"
          color="error"
          onClick={() => {
            setOpen(false);
            onConfirm();
          }}
          aria-label="delete card"
        >
          Delete
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};
export default ConfirmDialog;
