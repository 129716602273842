import * as React from "react";
import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";
function RouteDuoToneIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        width="24"
        height="24"
      >
        <g opacity=".12">
          <path
            d="M21 5C21 6.65685 19.6569 8 18 8C16.3431 8 15 6.65685 15 5C15 3.34315 16.3431 2 18 2C19.6569 2 21 3.34315 21 5Z"
            fill="currentColor"
          />
          <path
            d="M8 19C8 20.6569 6.65685 22 5 22C3.34315 22 2 20.6569 2 19C2 17.3431 3.34315 16 5 16C6.65685 16 8 17.3431 8 19Z"
            fill="currentColor"
          />
        </g>
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
          d="M8 19H16.5C18.433 19 20 17.433 20 15.5V15.5C20 13.567 18.433 12 16.5 12H6.5C4.567 12 3 10.433 3 8.5V8.5C3 6.567 4.567 5 6.5 5H12M8 19C8 20.6569 6.65685 22 5 22C3.34315 22 2 20.6569 2 19C2 17.3431 3.34315 16 5 16C6.65685 16 8 17.3431 8 19ZM12 5L9.5 2.5M12 5L9.5 7.5M21 5C21 6.65685 19.6569 8 18 8C16.3431 8 15 6.65685 15 5C15 3.34315 16.3431 2 18 2C19.6569 2 21 3.34315 21 5Z"
          stroke="currentColor"
        />
      </svg>
    </SvgIcon>
  );
}
export default RouteDuoToneIcon;

