import { CustomContentProps, SnackbarContent, useSnackbar } from "notistack";
import { forwardRef, useCallback } from "react";
import theme, { DCNotification } from "../../assets/theme/theme";
import { ListItemButton, Box, Typography } from "@mui/material";
import { NotificationAddOutlined } from "@mui/icons-material";

export interface NewNotificationArrivedSnackbarProps
  extends CustomContentProps {}

const NewNotificationArrivedSnackbar = forwardRef<
  HTMLDivElement,
  NewNotificationArrivedSnackbarProps
>(({ id }, ref) => {
  const { closeSnackbar } = useSnackbar();

  const handleDismiss = useCallback(() => {
    closeSnackbar(id);
  }, [id, closeSnackbar]);

  return (
    <SnackbarContent ref={ref}>
      <DCNotification elevation={10}>
        <ListItemButton
          sx={{
            px: 2,
            py: 2,
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            // bgcolor: theme.palette.primary.light,
            // "&:hover": {
            //   bgcolor: theme.palette.primary.light,
            // },
          }}
        >
          <Box display={"flex"}>
            <NotificationAddOutlined
              sx={{
                color: theme.palette.primary.main,
              }}
            />
            <Typography
              sx={{
                mr: 5,
                ml: 1,
              }}
            >
              You have received a <strong>new notification</strong>
            </Typography>
          </Box>
          {/* <Box display="flex">
            <IconButton
              aria-label="close snackbar"
              size="small"
              onClick={handleDismiss}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </Box> */}
        </ListItemButton>
      </DCNotification>
    </SnackbarContent>
  );
});

NewNotificationArrivedSnackbar.displayName = "NewNotificationArrivedSnackbar";

export default NewNotificationArrivedSnackbar;
