import React, { useEffect, useState } from "react";
import {
  DCDashboardContainer,
} from "../../assets/theme/theme";
import TravelSummary from "./TravelSummary";
import { Box, Tabs, Tab, Container } from "@mui/material";
import { UsaTravelsTab } from "./UsaTravelsTab";
import { CustomTravelsTab } from "./WorldTravelsTab";
import { logEvent } from "firebase/analytics";
import { analytics } from "../../firebase";
import TravelSummaryHeader from "./TravelSummaryHeader";

const TravelHistory = () => {
  const [travelState, setTravelState] = useState<"auto" | "custom">("auto");

  useEffect(() => {
    logEvent(analytics, "tab_change", {
      tab_name: "USA Travels",
      page_title: "TravelHistory",
    });
  }, []);

  return (
    <Box height="100%" pb="80px">
      <TravelSummaryHeader />
      <DCDashboardContainer>
        <TravelSummary />
        <Box position="relative" >
          <Box display="flex" my={3}>
            <Tabs value={travelState} aria-label="auto custom changer" >
              <Tab
                value="auto"
                label="USA Travels"
                onClick={() => {
                  logEvent(analytics, "tab_change", {
                    tab_name: "USA Travels",
                    page_title: "TravelHistory",
                  });
                  setTravelState("auto");
                }}
              />
              <Tab
                value="custom"
                label="World Travels"
                onClick={() => {
                  logEvent(analytics, "tab_change", {
                    tab_name: "World Travels",
                    page_title: "TravelHistory",
                  });
                  setTravelState("custom");
                }}
              />
            </Tabs>
          </Box>
          <Container disableGutters maxWidth={false}>
            {travelState === "auto" ? <UsaTravelsTab /> : <CustomTravelsTab />}
          </Container>
        </Box>
      </DCDashboardContainer>
    </Box>
  );
};
export default TravelHistory;
