// Example POST method implementation:
export async function postData(url = "", data = {}, headers?: {}) {
  // Default options are marked with *
  const response = await fetch(url, {
    method: "POST", // *GET, POST, PUT, DELETE, etc.
    mode: "cors", // no-cors, *cors, same-origin
    cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
    credentials: "same-origin", // include, *same-origin, omit
    headers: {
      "Content-Type": "application/json",
      ...headers,
      // 'Content-Type': 'application/x-www-form-urlencoded',
    },
    redirect: "follow", // manual, *follow, error
    referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
    body: JSON.stringify(data), // body data type must match "Content-Type" header
  });
  return await response.json(); // parses JSON response into native JavaScript objects
}

// Example POST method implementation:
export async function patchData(url = "", data = {}, headers?: {}) {
  // Default options are marked with *
  const response = await fetch(encodeURI(url), {
    method: "PATCH", // *GET, POST, PUT, DELETE, etc.
    mode: "cors", // no-cors, *cors, same-origin
    cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
    credentials: "same-origin", // include, *same-origin, omit
    headers: {
      "Content-Type": "application/json",
      ...headers,
      // 'Content-Type': 'application/x-www-form-urlencoded',
    },
    redirect: "follow", // manual, *follow, error
    referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
    body: JSON.stringify(data), // body data type must match "Content-Type" header
  });
  return await response.json(); // parses JSON response into native JavaScript objects
}

export async function downloadResource(
  url: string,
  filename: string,
  headers?: {}
) {
  function forceDownload(blob: any) {
    // Create an invisible anchor element
    const anchor = document.createElement("a");
    anchor.id = "you-are-awesome";
    anchor.style.display = "none";
    anchor.href = window.URL.createObjectURL(blob);
    anchor.setAttribute("download", filename);
    document.body.appendChild(anchor);

    // Trigger the download by simulating click
    anchor.click();

    // Clean up
    window.URL.revokeObjectURL(anchor.href);
    document.body.removeChild(anchor);
  }
  const response = await fetch(url, {
    headers: {
      accept: "image/webp,image/apng,image/svg+xml,image/*,*/*;q=0.8",
      ...headers,
      // 'Content-Type': 'application/x-www-form-urlencoded',
    },
  });
  // const response = await fetch(url, {
  //     headers: new Headers({
  //         Origin: window.location.origin,
  //     }),
  //     credentials: 'same-origin', // include, *same-origin, omit
  //     mode: 'cors',
  //     redirect: 'follow', // manual, *follow, error
  // })
  // @ts-ignore
  const blob = await response.blob();
  forceDownload(blob);
}
