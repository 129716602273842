import React, { PropsWithChildren } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Typography from "@mui/material/Typography";
import { TransitionProps } from "@mui/material/transitions";
import Slide from "@mui/material/Slide";
import { DialogActions } from "@mui/material";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

interface Props {
  open: boolean;
  confirmButtonText: string;
  onClose(): void;
  onConfirm(): void;
}
export const DCConfirmDialog: React.FC<PropsWithChildren<Props>> = ({
  children,
  open,
  confirmButtonText,
  onClose,
  onConfirm,
}) => {
  return (
    <Dialog
      disableEscapeKeyDown
      maxWidth="xs"
      open={open}
      TransitionComponent={Transition}
      onClose={onClose}
    >
      <DialogContent sx={{ mt: 2 }}>
        <Typography variant="h6" fontSize={18}>
          {children}
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          fullWidth
          onClick={() => {
            onClose();
          }}
        >
          CANCEL
        </Button>
        <Button
          variant="contained"
          color="error"
          fullWidth
          onClick={() => {
            onConfirm();
            onClose();
          }}
        >
          {confirmButtonText}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
