import * as React from "react";
import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";
function EditIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
     <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" width="24" height="24">
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" d="M15 5C15 7.20914 16.7909 9 19 9M4 20L4.61997 16.9002C4.76122 16.1939 4.83185 15.8407 4.96101 15.5114C5.07566 15.2192 5.22432 14.9414 5.40392 14.6839C5.60627 14.3937 5.86092 14.1391 6.37023 13.6298L16.0001 3.99998C16.5454 3.45476 16.818 3.18215 17.1121 3.03641C17.6716 2.75911 18.3286 2.75912 18.8882 3.03643C19.1823 3.18217 19.4549 3.45479 20.0001 4.00003V4.00003C20.5453 4.54525 20.8179 4.81786 20.9636 5.11194C21.2409 5.67151 21.2409 6.32848 20.9636 6.88804C20.8179 7.18212 20.5453 7.45472 20.0001 7.99994L10.3702 17.6298C9.86091 18.1391 9.60626 18.3937 9.31613 18.5961C9.05861 18.7757 8.78084 18.9243 8.48856 19.039C8.15927 19.1681 7.80613 19.2388 7.09987 19.38L4 20Z" stroke="currentColor"/>
    </svg>
    </SvgIcon>
  );
}
export default EditIcon;
