import React from "react";
import Box from "@mui/material/Box";
import Step from "@mui/material/Step";
import Button from "@mui/material/Button";
import { DCStepper } from "../../assets/theme/theme";
import { Grid, StepLabel, Alert } from "@mui/material";
import DocumentSavingPreference from "./DocumentSavingPreference";
import ProfileInfoForm from "./ProfileInfoForm";
import { useUserProfileContext } from "../../contexts/UserProfileContext";
import { useHistory } from "react-router-dom";
import { IUser } from "../../firebaseTypes";
import { LoadingButton } from "@mui/lab";
import { useDocumentContext } from "../../contexts/DocumentContext";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { useWalkThroughModalContext } from "../../contexts/WalkThroughModalContext";

const steps = [
  "How do you currently store your documents?",
  "Complete your Profile",
];

export interface Props {
  setActiveStep: React.Dispatch<React.SetStateAction<number>>;
  activeStep: number;
}

const OnboardingSteps: React.FC<Props> = ({ activeStep, setActiveStep }) => {
  const [isSavingUser, setIsSavingUser] = React.useState(false);
  const {
    documents,
    initializedDocumentsAndFolders: initalizedDocumentsAndFolders,
  } = useDocumentContext();
  const { error, createUserProfile, userProfile } = useUserProfileContext();
  const history = useHistory();
  const transitionContainerRef = React.useRef(null);
  const { openWalkThroughModal } = useWalkThroughModalContext();
  const { setAccountDeleteConfirmationModalOpen } =  useUserProfileContext();

  const validateProfileForm = React.useCallback((): boolean => {
    return (
      !!userProfile?.firstName &&
      !!userProfile.lastName &&
      !!userProfile.email &&
      !!userProfile.zipCode &&
      !!userProfile.country &&
      !!userProfile.state &&
      !!userProfile.nationality
    );
  }, [userProfile]);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    history.push(`/profile/setup/1`);
  };

  // const handleBack = () => {
  //   setActiveStep((prevActiveStep) => prevActiveStep - 1);
  //   history.push(`/profile/setup/0`);
  // };

  const handleReset = () => {
    setActiveStep(0);
    history.push(`/profile/setup/0`);
  };

  const gotoDashboard = React.useCallback(async () => {
    setIsSavingUser(true);
    const success = await createUserProfile(userProfile as IUser);
    setIsSavingUser(false);
    if (success) {
      if (initalizedDocumentsAndFolders && documents.length === 0) {
        console.log(`redirecting to /documents`);
        history.push("/documents");
        openWalkThroughModal(1000);
      } else {
        history.push("/");
      }
    }
  }, [
    createUserProfile,
    userProfile,
    initalizedDocumentsAndFolders,
    documents.length,
    history,
    openWalkThroughModal,
  ]);

  function _getStepContent(stepIndex: number) {
    switch (stepIndex) {
      case 0:
        return <DocumentSavingPreference />;
      case 1:
        return <ProfileInfoForm />;
      default:
        return <div>'Unknown stepIndex'</div>;
    }
  }

  const getStepContent = React.useCallback(_getStepContent, []);
  return (
    <Box sx={{ width: '100%' }}  className="profile-blog">
      <DCStepper activeStep={activeStep}>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </DCStepper>

      {error && (
        <Box mt={4} mb={2}>
          <Alert variant="outlined" severity="error">
            {error}
          </Alert>
        </Box>
      )}

      <Box mt={6}>
        {activeStep === steps.length && (
          <Grid item sm={12}>
            <>
              All steps completed which means user is on dashboard
              <Button onClick={handleReset}>Reset</Button>
            </>
          </Grid>
        )}
        <Box className="use-profile">
        {activeStep !== steps.length && (
          <>
            <Box
              id={`profile-setup-${activeStep}`}
              ref={transitionContainerRef}
            >
              {getStepContent(activeStep)}
            </Box>
            <Box mt={5} flex="1" display="flex">
              <Grid container spacing={2} justifyContent="center">
                {/* <Grid item>
                  <Button
                    disabled={activeStep === 0}
                    onClick={handleBack}
                    variant="outlined"
                  >
                    Back
                  </Button>
                </Grid> */}
                <Grid item>
                  {activeStep === steps.length - 1 ? (
                    <LoadingButton
                      loadingPosition="center"
                      loading={isSavingUser}
                      aria-label="goto dashboard"
                      variant="contained"
                      color="primary"
                      disableElevation
                      disabled={!validateProfileForm()}
                      onClick={gotoDashboard}
                      endIcon={
                        initalizedDocumentsAndFolders &&
                        documents.length === 0 ? (
                          <ArrowForwardIcon />
                        ) : null
                      }
                    >
                      {/* {initalizedDocumentsAndFolders && documents.length === 0 ? 'Ready to Upload' : 'Save'} */}
                      Save
                      {/* todo: disable goto dash until atleast one pic is uploaded */}
                      {/* todo: goto dashboard should take user to the dashboard */}
                    </LoadingButton>
                  ) : (
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleNext}
                      disableElevation
                    >
                      Continue
                      {/* todo: disable goto dash until atleast one pic is uploaded */}
                      {/* todo: goto dashboard should take user to the dashboard */}
                    </Button>
                  )}
                </Grid>
                <Grid item>
                   <Button
                      className="goto-dashboard-btn"
                      variant="outlined"
                      // variant="text"
                      color="primary"
                      onClick={gotoDashboard}
                      disableElevation
                    >
                      Go to dashboard
                      {/* todo: disable goto dash until at-least one pic is uploaded */}
                      {/* todo: goto dashboard should take user to the dashboard */}
                    </Button>
                </Grid>
                <Grid item>
                   <Button
                      className="goto-dashboard-btn"
                      variant="contained"
                      // variant="text"
                      color="error"
                      onClick={() => setAccountDeleteConfirmationModalOpen(true)}
                      disableElevation
                    >
                      Delete Account
                      {/* todo: disable goto dash until at-least one pic is uploaded */}
                      {/* todo: goto dashboard should take user to the dashboard */}
                    </Button>
                </Grid>
              </Grid>
            </Box>
          </>
        )}
        </Box>
      </Box>
    </Box>
  );
};

export default OnboardingSteps;
