import { Box } from "@mui/material";
import React from "react";
import theme from "../../assets/theme/theme";
import { getTermsOfServiceUrl } from "../../utils/environment";
import AppBarV2 from "../AppBarV2";
import PDFViewerV2 from "../PDFViewerV2";

export interface Props {}

const TermsAndCondition: React.FC<Props> = (props) => {
  return (
    <Box>
      <AppBarV2 />
      {/* classname=main */}
      <Box
        sx={{
          marginTop: "70px",
          marginBottom: "70px",
          paddingTop: "64px",
          paddingLeft: "100px",
          paddingRight: "100px",
          position: "relative",
          paddingBottom: "20px",
          [theme.breakpoints.down("md")]: {
            marginTop: "20px",
          },
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <PDFViewerV2 url={getTermsOfServiceUrl()} />
        </Box>
      </Box>
    </Box>
  );
};

export default TermsAndCondition;
