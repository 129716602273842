import { Avatar, Badge, Box, Tooltip } from "@mui/material";
import StarsIcon from "@mui/icons-material/Stars";
import theme from "../../assets/theme/theme";

function stringToColor(str: string) {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < str?.length; i += 1) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = "#";

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.substr(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
}

interface IDCAvatarProps {
  className?: string;
  name: string;
  email: string;
  imgSrc?: string;
  premium?: boolean;
}
const ToolTipText: React.FC<IDCAvatarProps> = (props) => {
  return (
    <>
      {props.name}
      <br />
      {props.email}
    </>
  );
};
export default function DCAvatar(props: IDCAvatarProps) {
  return (
    <Tooltip
      sx={{ whiteSpace: "pre-line" }}
      title={<ToolTipText {...props} />}
      placement="bottom"
      arrow
    >
      {props.premium ? (
        <Badge
          overlap="circular"
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          badgeContent={
            <StarsIcon
              sx={{
                color: "#E5B80B",
                backgroundColor: "white",
                borderRadius: "999px",
                fontSize: 18,
              }}
            />
          }
        >
          <Avatar
            className={props.className}
            src={props.imgSrc}
            sx={{
              backgroundColor: stringToColor(props.name),
              width: 36,
              height: 36,
              fontSize: 18,
              border: `2px solid #E5B80B`,
            }}
          >
            {props.name.split(" ")[0][0]}
          </Avatar>
        </Badge>
      ) : (
        <Avatar
          className={props.className}
          src={props.imgSrc}
          sx={{
            backgroundColor: stringToColor(props.name),
            width: 36,
            height: 36,
            fontSize: 18,
          }}
        >
          {props.name?.split(" ")[0][0]}
        </Avatar>
      )}
    </Tooltip>
  );
}
