import React, { createContext, useContext } from "react";
import { DropzoneRootProps, FileRejection, useDropzone } from "react-dropzone";
import { useDocumentUploadContext } from "./DocumentUploadContext";

export interface IDropzoneContext {
  open: () => any;
  getInputProps: () => any;
  getRootProps: (props?: DropzoneRootProps | undefined) => any;
  isDragActive: boolean;
  isDragAccept: boolean;
  isDragReject: boolean;
}

const defaultValues: IDropzoneContext = {
  open: () => {},
  getInputProps: () => {},
  getRootProps: () => {},
  isDragAccept: false,
  isDragActive: false,
  isDragReject: false,
};

const DropzoneContext = createContext(defaultValues);

export function useDropzoneContext() {
  return useContext(DropzoneContext);
}

interface Props {}

export const DropzoneProvider: React.FC<Props> = (props) => {
  const { createUploadRequests, createUploadRequestsFromRejectedFiles } =
    useDocumentUploadContext();

  function onDrop(acceptedFiles: File[], fileRejections: FileRejection[]) {
    // Process accepted

    createUploadRequests(acceptedFiles, "documents");

    // Process rejected
    createUploadRequestsFromRejectedFiles(fileRejections, "documents");
  }

  const {
    getRootProps,
    getInputProps,
    open,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    onDrop,
    // Disable click and keydown behavior
    noClick: true,
    noKeyboard: true,
    // maxFiles: 20,
    maxSize: 20 * 1024 * 1024,
    accept: {
      "image/png": [".png"],
      "image/jpg": [".jpg", ".jpeg"],
      "application/pdf": [".pdf"],
    },
    // accept: '.pdf,application/pdf,image/png,image/jpeg,image/jpg', //"image/png,image/jpeg,image.jpg,.pdf"
  });

  return (
    <DropzoneContext.Provider
      value={{
        open,
        getInputProps,
        getRootProps,
        isDragAccept,
        isDragActive,
        isDragReject,
      }}
    >
      {props.children}
    </DropzoneContext.Provider>
  );
};
