import { PinturaEditor } from "@pqina/react-pintura";
import { Box } from "@mui/material";
import React, { useEffect } from "react";
import { ImageSource, getEditorDefaults } from "@pqina/pintura";
import "@pqina/pintura/pintura.css";

interface Props {
  image: ImageSource;
  onLoadpreview?: (detail: ImageBitmap | ImageData) => void;
}

const editorConfig = getEditorDefaults();

const ImageViewer: React.FC<Props> = ({ image, onLoadpreview }) => {
  useEffect(() => {
    document.documentElement.style.setProperty("--editor-height", "100%");
  });
  return (
    <Box sx={{ height: "100%", width: "100%" }}>
      {/* enableUtils : boolean => controls the multiple ultilites like crop, filter ... etc will be visible or not. While it's off the editor will only do basic editing crop zoom rotate and fliping.
          enableToolbar => enables/disables the toolbar ( toolbar consists => undo, redo button and history button )
          enableToolbar => enables/disables the toolbar ( toolbar consists => undo, redo button and history button )
          enableExportButton => enables/ disables export button aka the Done button on the editor
          cropEnableZoomInput => enables/diables the zoom slider inputbar when in crop mode
          cropRotationInput => enables/diables the rotation slider inputbar when in crop mode
          cropEnableButtonFlipHorizontal => enables/diables the horizontal fliping button when in crop mode
          cropEnableButtonRotateLeft => enables/diables the rotate left button (rotates the image 90 degree) fliping button when in crop mode
          cropEnableImageSelection => enables/diables the crop borders and cropping when in crop mode
        */}
      <PinturaEditor
        {...editorConfig}
        previewUpscale={true}
        enableUtils={false}
        enableToolbar={false}
        enableButtonExport={false}
        cropEnableZoomInput={false}
        cropEnableRotationInput={false}
        cropEnableButtonFlipHorizontal={false}
        cropEnableButtonRotateLeft={false}
        cropEnableImageSelection={false}
        src={image}
        onLoadpreview={onLoadpreview}
      ></PinturaEditor>
    </Box>
  );
};

export default ImageViewer;
