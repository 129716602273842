import Logo from '../Shared/Logo'
import { Box, Typography } from '@mui/material'
import theme, { DCButton } from '../../assets/theme/theme'
import { useHistory } from 'react-router-dom'
import HowToRegIcon from '@mui/icons-material/HowToReg';

const AccountDeleteConfirmationPage = () => {

  const history = useHistory();

  return (
    <Box>
      <Box sx={{ display: 'flex', justifyContent: 'left', padding: 2, borderBottom: 1, borderColor: theme.palette.grey[500] }}>
        <Logo />
      </ Box>
      <Box sx={{ display: 'flex', justifyContent: 'center', flexDirection: "column", marginTop: 8 }}>
        <HowToRegIcon sx={{ fontSize: 100, color: theme.palette.primary.main, margin: 'auto' }} />
        <Typography variant="h4" sx={{ textAlign: 'center', marginTop: 1, marginBottom: 4 }}>
          You account has been deleted successfully.
        </Typography>
        <DCButton
          variant="contained"
          color="primary"
          sx={{ width: 200, margin: 'auto' }}
          onClick={() => {
            history.push('/login')
          }}
        >
          Go to Login
        </DCButton>
      </Box>
    </Box>
  )
}

export default AccountDeleteConfirmationPage