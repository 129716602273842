export const airports = [
  { city: "Aalborg", country: "Denmark", airport_code: "AAL", flag_code: "DK" },
  { city: "Aalesund", country: "Norway", airport_code: "AES", flag_code: "NO" },
  { city: "Aarhus", country: "Denmark", airport_code: "AAR", flag_code: "DK" },
  {
    city: "Abbotsford BC",
    country: "Canada",
    airport_code: "YXX",
    flag_code: "CA",
  },
  {
    city: "Aberdeen",
    country: "Scotland",
    airport_code: "ABZ",
    flag_code: "_scotland",
  },
  { city: "Aberdeen SD", country: "USA", airport_code: "ABR", flag_code: "US" },
  {
    city: "Abidjan",
    country: "Ivory Coast",
    airport_code: "ABJ",
    flag_code: "CI",
  },
  { city: "Abilene TX", country: "USA", airport_code: "ABI", flag_code: "US" },
  {
    city: "Abu Dhabi",
    country: "United Arab Emirates",
    airport_code: "AUH",
    flag_code: "AE",
  },
  { city: "Abuja", country: "Nigeria", airport_code: "ABV", flag_code: "NG" },
  { city: "Acapulco", country: "Mexico", airport_code: "ACA", flag_code: "MX" },
  { city: "Accra", country: "Ghana", airport_code: "ACC", flag_code: "GH" },
  { city: "Adana", country: "Turkey", airport_code: "ADA", flag_code: "TR" },
  {
    city: "Addis Ababa",
    country: "Ethiopia",
    airport_code: "ADD",
    flag_code: "ET",
  },
  {
    city: "Adelaide S.A.",
    country: "Australia",
    airport_code: "ADL",
    flag_code: "AU",
  },
  { city: "Aden", country: "Yemen", airport_code: "ADE", flag_code: "YE" },
  { city: "Adiyaman", country: "Turkey", airport_code: "ADF", flag_code: "TR" },
  { city: "Agadir", country: "Morocco", airport_code: "AGA", flag_code: "MA" },
  { city: "Agana", country: "Guam", airport_code: "GUM", flag_code: "GU" },
  {
    city: "Aguadilla",
    country: "Puerto Rico",
    airport_code: "BQN",
    flag_code: "PR",
  },
  {
    city: "Aguascalientes",
    country: "Mexico",
    airport_code: "AGU",
    flag_code: "MX",
  },
  {
    city: "Ahe",
    country: "French Polynesia",
    airport_code: "AHE",
    flag_code: "PF",
  },
  { city: "Ahmedabad", country: "India", airport_code: "AMD", flag_code: "IN" },
  {
    city: "Ajaccio Corsica",
    country: "France",
    airport_code: "AJA",
    flag_code: "FR",
  },
  { city: "Akita", country: "Japan", airport_code: "AXT", flag_code: "JP" },
  { city: "Akron OH", country: "USA", airport_code: "CAK", flag_code: "US" },
  { city: "Alamosa CO", country: "USA", airport_code: "ALS", flag_code: "US" },
  { city: "Albany GA", country: "USA", airport_code: "ABY", flag_code: "US" },
  { city: "Albany NY", country: "USA", airport_code: "ALB", flag_code: "US" },
  { city: "Albenga", country: "Italy", airport_code: "ALL", flag_code: "IT" },
  {
    city: "Albuquerque NM",
    country: "USA",
    airport_code: "ABQ",
    flag_code: "US",
  },
  {
    city: "Albury N.S.W.",
    country: "Australia",
    airport_code: "ABX",
    flag_code: "AU",
  },
  {
    city: "Alexandria",
    country: "Egypt",
    airport_code: "ALY",
    flag_code: "EG",
  },
  {
    city: "Alexandria",
    country: "Egypt",
    airport_code: "HBE",
    flag_code: "EG",
  },
  {
    city: "Alexandria LA",
    country: "USA",
    airport_code: "AEX",
    flag_code: "US",
  },
  {
    city: "Alexandroupolis",
    country: "Greece",
    airport_code: "AXD",
    flag_code: "GR",
  },
  {
    city: "Alghero Sardinia",
    country: "Italy",
    airport_code: "AHO",
    flag_code: "IT",
  },
  { city: "Algiers", country: "Algeria", airport_code: "ALG", flag_code: "DZ" },
  { city: "Alicante", country: "Spain", airport_code: "ALC", flag_code: "ES" },
  {
    city: "Alice Springs N.T.",
    country: "Australia",
    airport_code: "ASP",
    flag_code: "AU",
  },
  {
    city: "Alice Town North Bimini Island",
    country: "Bahamas",
    airport_code: "NSB",
    flag_code: "BS",
  },
  {
    city: "Allentown PA",
    country: "USA",
    airport_code: "ABE",
    flag_code: "US",
  },
  { city: "Alliance NE", country: "USA", airport_code: "AIA", flag_code: "US" },
  {
    city: "Almaty",
    country: "Kazakhstan",
    airport_code: "ALA",
    flag_code: "KZ",
  },
  { city: "Almeria", country: "Spain", airport_code: "LEI", flag_code: "ES" },
  {
    city: "Alor Setar",
    country: "Malaysia",
    airport_code: "AOR",
    flag_code: "MY",
  },
  { city: "Alpena MI", country: "USA", airport_code: "APN", flag_code: "US" },
  { city: "Alta", country: "Norway", airport_code: "ALF", flag_code: "NO" },
  {
    city: "Altenrhein",
    country: "Switzerland",
    airport_code: "ACH",
    flag_code: "CH",
  },
  { city: "Altoona PA", country: "USA", airport_code: "AOO", flag_code: "US" },
  { city: "Amarillo TX", country: "USA", airport_code: "AMA", flag_code: "US" },
  { city: "Amman", country: "Jordan", airport_code: "AMM", flag_code: "JO" },
  {
    city: "Amsterdam",
    country: "Netherlands",
    airport_code: "AMS",
    flag_code: "NL",
  },
  {
    city: "Anahim Lake BC",
    country: "Canada",
    airport_code: "YAA",
    flag_code: "CA",
  },
  {
    city: "Anchorage AK",
    country: "USA",
    airport_code: "ANC",
    flag_code: "US",
  },
  { city: "Ancona", country: "Italy", airport_code: "AOI", flag_code: "IT" },
  {
    city: "Andros Town",
    country: "Bahamas",
    airport_code: "ASD",
    flag_code: "BS",
  },
  {
    city: "Angeles City",
    country: "Philippines",
    airport_code: "CRK",
    flag_code: "PH",
  },
  {
    city: "Angra do Heroismo Terceira Island",
    country: "Azores",
    airport_code: "TER",
    flag_code: "NN",
  },
  { city: "Ankara", country: "Turkey", airport_code: "ESB", flag_code: "TR" },
  { city: "Annaba", country: "Algeria", airport_code: "AAE", flag_code: "DZ" },
  { city: "Annecy", country: "France", airport_code: "NCY", flag_code: "FR" },
  { city: "Antalya", country: "Turkey", airport_code: "AYT", flag_code: "TR" },
  {
    city: "Antananarivo",
    country: "Madagascar",
    airport_code: "TNR",
    flag_code: "MG",
  },
  {
    city: "Antofagasta",
    country: "Chile",
    airport_code: "ANF",
    flag_code: "CL",
  },
  { city: "Antwerp", country: "Belgium", airport_code: "ANR", flag_code: "BE" },
  { city: "Aomori", country: "Japan", airport_code: "AOJ", flag_code: "JP" },
  { city: "Aosta", country: "Italy", airport_code: "AOT", flag_code: "IT" },
  { city: "Appleton WI", country: "USA", airport_code: "ATW", flag_code: "US" },
  { city: "Ardabil", country: "Iran", airport_code: "ADU", flag_code: "IR" },
  { city: "Arequipa", country: "Peru", airport_code: "AQP", flag_code: "PE" },
  {
    city: "Argostoli Kefalonia Island",
    country: "Greece",
    airport_code: "EFL",
    flag_code: "GR",
  },
  { city: "Arica", country: "Chile", airport_code: "ARI", flag_code: "CL" },
  {
    city: "Arkhangelsk",
    country: "Russia",
    airport_code: "ARH",
    flag_code: "RU",
  },
  {
    city: "Armidale N.S.W.",
    country: "Australia",
    airport_code: "ARM",
    flag_code: "AU",
  },
  {
    city: "Arrecife Lanzarote Island",
    country: "Canary Islands",
    airport_code: "ACE",
    flag_code: "NN",
  },
  { city: "Arusha", country: "Tanzania", airport_code: "ARK", flag_code: "TZ" },
  {
    city: "Arviat NWT",
    country: "Canada",
    airport_code: "YEK",
    flag_code: "CA",
  },
  {
    city: "Arvidsjaur",
    country: "Sweden",
    airport_code: "AJR",
    flag_code: "SE",
  },
  {
    city: "Asheville NC",
    country: "USA",
    airport_code: "AVL",
    flag_code: "US",
  },
  { city: "Asmara", country: "Eritrea", airport_code: "ASM", flag_code: "NN" },
  { city: "Aspen CO", country: "USA", airport_code: "ASE", flag_code: "US" },
  {
    city: "Astana",
    country: "Kazakhstan",
    airport_code: "TSE",
    flag_code: "KZ",
  },
  {
    city: "Asuncion",
    country: "Paraguay",
    airport_code: "ASU",
    flag_code: "PY",
  },
  { city: "Aswan", country: "Egypt", airport_code: "ASW", flag_code: "EG" },
  { city: "Athens", country: "Greece", airport_code: "ATH", flag_code: "GR" },
  { city: "Athens GA", country: "USA", airport_code: "AHN", flag_code: "US" },
  { city: "Atlanta GA", country: "USA", airport_code: "ATL", flag_code: "US" },
  {
    city: "Atlantic City NJ",
    country: "USA",
    airport_code: "ACY",
    flag_code: "US",
  },
  {
    city: "Auckland",
    country: "New Zealand",
    airport_code: "AKL",
    flag_code: "NZ",
  },
  { city: "Augusta GA", country: "USA", airport_code: "AGS", flag_code: "US" },
  { city: "Augusta ME", country: "USA", airport_code: "AUG", flag_code: "US" },
  { city: "Austin TX", country: "USA", airport_code: "AUS", flag_code: "US" },
  {
    city: "Avarua Rarotonga Island",
    country: "Cook Islands",
    airport_code: "RAR",
    flag_code: "NN",
  },
  {
    city: "Ayers Rock N.T.",
    country: "Australia",
    airport_code: "AYQ",
    flag_code: "AU",
  },
  {
    city: "Bacolod",
    country: "Philippines",
    airport_code: "BCD",
    flag_code: "PH",
  },
  { city: "Badajoz", country: "Spain", airport_code: "BJZ", flag_code: "ES" },
  { city: "Baghdad", country: "Iraq", airport_code: "SDA", flag_code: "IQ" },
  {
    city: "Bagotville PQ",
    country: "Canada",
    airport_code: "YBG",
    flag_code: "CA",
  },
  {
    city: "Baie-Comeau PQ",
    country: "Canada",
    airport_code: "YBC",
    flag_code: "CA",
  },
  {
    city: "Bakersfield CA",
    country: "USA",
    airport_code: "BFL",
    flag_code: "US",
  },
  { city: "Baku", country: "Azerbaijan", airport_code: "BAK", flag_code: "AZ" },
  { city: "Baku", country: "Azerbaijan", airport_code: "GYD", flag_code: "AZ" },
  { city: "Balaton", country: "Hungary", airport_code: "SOB", flag_code: "HU" },
  {
    city: "Balephuil",
    country: "Scotland",
    airport_code: "TRE",
    flag_code: "_scotland",
  },
  {
    city: "Balikpapan Kalimantan",
    country: "Indonesia",
    airport_code: "BPN",
    flag_code: "ID",
  },
  {
    city: "Ballina N.S.W.",
    country: "Australia",
    airport_code: "BNK",
    flag_code: "AU",
  },
  { city: "Balmaceda", country: "Chile", airport_code: "BBA", flag_code: "CL" },
  {
    city: "Baltimore MD",
    country: "USA",
    airport_code: "BWI",
    flag_code: "US",
  },
  {
    city: "Ban Me Thuot",
    country: "Vietnam",
    airport_code: "BMV",
    flag_code: "VN",
  },
  {
    city: "Bandar Seri Begawan",
    country: "Brunei",
    airport_code: "BWN",
    flag_code: "NN",
  },
  { city: "Bangalore", country: "India", airport_code: "BLR", flag_code: "IN" },
  { city: "Bangda", country: "China", airport_code: "BPX", flag_code: "CN" },
  {
    city: "Bangkok",
    country: "Thailand",
    airport_code: "BKK",
    flag_code: "TH",
  },
  { city: "Bangor ME", country: "USA", airport_code: "BGR", flag_code: "US" },
  {
    city: "Bangui",
    country: "Central African Republic",
    airport_code: "BGF",
    flag_code: "CF",
  },
  { city: "Banjul", country: "Gambia", airport_code: "BJL", flag_code: "GM" },
  {
    city: "Bar Harbor ME",
    country: "USA",
    airport_code: "BHB",
    flag_code: "US",
  },
  { city: "Barcelona", country: "Spain", airport_code: "BCN", flag_code: "ES" },
  {
    city: "Bardufoss",
    country: "Norway",
    airport_code: "BDU",
    flag_code: "NO",
  },
  { city: "Bari", country: "Italy", airport_code: "BRI", flag_code: "IT" },
  {
    city: "Barisal",
    country: "Bangladesh",
    airport_code: "BZL",
    flag_code: "BD",
  },
  {
    city: "Barranquilla",
    country: "Colombia",
    airport_code: "BAQ",
    flag_code: "CO",
  },
  { city: "Barrow AK", country: "USA", airport_code: "BRW", flag_code: "US" },
  {
    city: "Basel",
    country: "Switzerland",
    airport_code: "BSL",
    flag_code: "CH",
  },
  {
    city: "Basseterre",
    country: "St. Kitts",
    airport_code: "SKB",
    flag_code: "NN",
  },
  {
    city: "Bastia Corsica",
    country: "France",
    airport_code: "BIA",
    flag_code: "FR",
  },
  {
    city: "Bathurst N.S.W.",
    country: "Australia",
    airport_code: "BHS",
    flag_code: "AU",
  },
  {
    city: "Baton Rouge LA",
    country: "USA",
    airport_code: "BTR",
    flag_code: "US",
  },
  { city: "Bauru", country: "Brazil", airport_code: "BAU", flag_code: "BR" },
  { city: "Beaumont TX", country: "USA", airport_code: "BPT", flag_code: "US" },
  { city: "Beauvais", country: "France", airport_code: "BVA", flag_code: "FR" },
  { city: "Beckley WV", country: "USA", airport_code: "BKW", flag_code: "US" },
  { city: "Bedford MA", country: "USA", airport_code: "BED", flag_code: "US" },
  {
    city: "Beef Island Tortola",
    country: "British Virgin Islands",
    airport_code: "EIS",
    flag_code: "NN",
  },
  { city: "Beijing", country: "China", airport_code: "BJS", flag_code: "CN" },
  { city: "Beirut", country: "Lebanon", airport_code: "BEY", flag_code: "LB" },
  { city: "Belem", country: "Brazil", airport_code: "BEL", flag_code: "BR" },
  {
    city: "Belfast",
    country: "Northern Ireland",
    airport_code: "BFS",
    flag_code: "IE",
  },
  {
    city: "Belfast",
    country: "Northern Ireland",
    airport_code: "BHD",
    flag_code: "IE",
  },
  {
    city: "Belgrade",
    country: "Serbia and Montenegro",
    airport_code: "BEG",
    flag_code: "RS",
  },
  {
    city: "Belize City",
    country: "Belize",
    airport_code: "BZE",
    flag_code: "BZ",
  },
  {
    city: "Bellingham WA",
    country: "USA",
    airport_code: "BLI",
    flag_code: "US",
  },
  {
    city: "Belo Horizonte",
    country: "Brazil",
    airport_code: "CNF",
    flag_code: "BR",
  },
  { city: "Bemidji MN", country: "USA", airport_code: "BJI", flag_code: "US" },
  {
    city: "Benbecula",
    country: "Scotland",
    airport_code: "BEB",
    flag_code: "_scotland",
  },
  { city: "Benguela", country: "Angola", airport_code: "BUG", flag_code: "AO" },
  { city: "Bergen", country: "Norway", airport_code: "BGO", flag_code: "NO" },
  { city: "Berlin", country: "Germany", airport_code: "BER", flag_code: "DE" },
  { city: "Berlin", country: "Germany", airport_code: "SXF", flag_code: "DE" },
  { city: "Berlin", country: "Germany", airport_code: "THF", flag_code: "DE" },
  { city: "Berlin", country: "Germany", airport_code: "TXL", flag_code: "DE" },
  {
    city: "Berne",
    country: "Switzerland",
    airport_code: "BRN",
    flag_code: "CH",
  },
  { city: "Bethel AK", country: "USA", airport_code: "BET", flag_code: "US" },
  { city: "Bettles AK", country: "USA", airport_code: "BTT", flag_code: "US" },
  {
    city: "Biak Papua",
    country: "Indonesia",
    airport_code: "BIK",
    flag_code: "ID",
  },
  { city: "Biarritz", country: "France", airport_code: "BIQ", flag_code: "FR" },
  { city: "Bilbao", country: "Spain", airport_code: "BIO", flag_code: "ES" },
  { city: "Billings MT", country: "USA", airport_code: "BIL", flag_code: "US" },
  { city: "Billund", country: "Denmark", airport_code: "BLL", flag_code: "DK" },
  {
    city: "Binghamton NY",
    country: "USA",
    airport_code: "BGM",
    flag_code: "US",
  },
  {
    city: "Bintulu Sarawak",
    country: "Malaysia",
    airport_code: "BTU",
    flag_code: "MY",
  },
  {
    city: "Birmingham",
    country: "England",
    airport_code: "BHX",
    flag_code: "GB",
  },
  {
    city: "Birmingham AL",
    country: "USA",
    airport_code: "BHM",
    flag_code: "US",
  },
  {
    city: "Bishkek",
    country: "Kyrgyzstan",
    airport_code: "FRU",
    flag_code: "KG",
  },
  { city: "Bismarck ND", country: "USA", airport_code: "BIS", flag_code: "US" },
  {
    city: "Bissau",
    country: "Guinea-Bissau",
    airport_code: "OXB",
    flag_code: "NN",
  },
  { city: "Blantyre", country: "Malawi", airport_code: "BLZ", flag_code: "NN" },
  {
    city: "Blenheim",
    country: "New Zealand",
    airport_code: "BHE",
    flag_code: "NZ",
  },
  {
    city: "Bloemfontein",
    country: "South Africa",
    airport_code: "BFN",
    flag_code: "ZA",
  },
  {
    city: "Bloomington IL",
    country: "USA",
    airport_code: "BMI",
    flag_code: "US",
  },
  {
    city: "Bloomington IN",
    country: "USA",
    airport_code: "BMG",
    flag_code: "US",
  },
  {
    city: "Bluefield WV",
    country: "USA",
    airport_code: "BLF",
    flag_code: "US",
  },
  { city: "Bodo", country: "Norway", airport_code: "BOO", flag_code: "NO" },
  { city: "Bogota", country: "Colombia", airport_code: "BOG", flag_code: "CO" },
  { city: "Boise ID", country: "USA", airport_code: "BOI", flag_code: "US" },
  { city: "Bologna", country: "Italy", airport_code: "BLQ", flag_code: "IT" },
  { city: "Bolzano", country: "Italy", airport_code: "BZO", flag_code: "IT" },
  { city: "Bordeaux", country: "France", airport_code: "BOD", flag_code: "FR" },
  { city: "Borlange", country: "Sweden", airport_code: "BLE", flag_code: "SE" },
  { city: "Boston MA", country: "USA", airport_code: "BOS", flag_code: "US" },
  { city: "Bozeman MT", country: "USA", airport_code: "BZN", flag_code: "US" },
  { city: "Brasilia", country: "Brazil", airport_code: "BSB", flag_code: "BR" },
  {
    city: "Bratislava",
    country: "Slovakia",
    airport_code: "BTS",
    flag_code: "SK",
  },
  {
    city: "Brazzaville",
    country: "Congo",
    airport_code: "BZV",
    flag_code: "CG",
  },
  { city: "Bremen", country: "Germany", airport_code: "BRE", flag_code: "DE" },
  { city: "Brest", country: "France", airport_code: "BES", flag_code: "FR" },
  {
    city: "Bridgetown",
    country: "Barbados",
    airport_code: "BGI",
    flag_code: "BB",
  },
  { city: "Brindisi", country: "Italy", airport_code: "BDS", flag_code: "IT" },
  {
    city: "Brisbane Qld.",
    country: "Australia",
    airport_code: "BNE",
    flag_code: "AU",
  },
  { city: "Bristol", country: "England", airport_code: "BRS", flag_code: "GB" },
  {
    city: "Broken Hill N.S.W.",
    country: "Australia",
    airport_code: "BHQ",
    flag_code: "AU",
  },
  {
    city: "Brookings SD",
    country: "USA",
    airport_code: "BKX",
    flag_code: "US",
  },
  {
    city: "Broome W.A.",
    country: "Australia",
    airport_code: "BME",
    flag_code: "AU",
  },
  {
    city: "Brownsville TX",
    country: "USA",
    airport_code: "BRO",
    flag_code: "US",
  },
  {
    city: "Brunswick GA",
    country: "USA",
    airport_code: "BQK",
    flag_code: "US",
  },
  {
    city: "Brussels",
    country: "Belgium",
    airport_code: "BRU",
    flag_code: "BE",
  },
  {
    city: "Bucharest",
    country: "Romania",
    airport_code: "OTP",
    flag_code: "RO",
  },
  {
    city: "Budapest",
    country: "Hungary",
    airport_code: "BUD",
    flag_code: "HU",
  },
  {
    city: "Buenos Aires",
    country: "Argentina",
    airport_code: "EZE",
    flag_code: "AR",
  },
  { city: "Buffalo NY", country: "USA", airport_code: "BUF", flag_code: "US" },
  {
    city: "Bujumbura",
    country: "Burundi",
    airport_code: "BJM",
    flag_code: "BI",
  },
  {
    city: "Bulawayo",
    country: "Zimbabwe",
    airport_code: "BUQ",
    flag_code: "ZW",
  },
  {
    city: "Bundaberg Qld.",
    country: "Australia",
    airport_code: "BDB",
    flag_code: "AU",
  },
  { city: "Burbank CA", country: "USA", airport_code: "BUR", flag_code: "US" },
  {
    city: "Buri Ram",
    country: "Thailand",
    airport_code: "BFV",
    flag_code: "TH",
  },
  {
    city: "Burlington IA",
    country: "USA",
    airport_code: "BRL",
    flag_code: "US",
  },
  {
    city: "Burlington MA",
    country: "USA",
    airport_code: "BBF",
    flag_code: "US",
  },
  {
    city: "Burlington VT",
    country: "USA",
    airport_code: "BTV",
    flag_code: "US",
  },
  {
    city: "Busan",
    country: "Korea Republic of",
    airport_code: "PUS",
    flag_code: "KR",
  },
  { city: "Butte MT", country: "USA", airport_code: "BTM", flag_code: "US" },
  {
    city: "Butuan",
    country: "Philippines",
    airport_code: "BXU",
    flag_code: "PH",
  },
  {
    city: "Bydgoszcz",
    country: "Poland",
    airport_code: "BZG",
    flag_code: "PL",
  },
  { city: "Caen", country: "France", airport_code: "CFR", flag_code: "FR" },
  {
    city: "Cagayan de Oro",
    country: "Philippines",
    airport_code: "CGY",
    flag_code: "PH",
  },
  {
    city: "Cagliari Sardinia",
    country: "Italy",
    airport_code: "CAG",
    flag_code: "IT",
  },
  {
    city: "Cairns Qld.",
    country: "Australia",
    airport_code: "CNS",
    flag_code: "AU",
  },
  { city: "Cairo", country: "Egypt", airport_code: "CAI", flag_code: "EG" },
  { city: "Calcutta", country: "India", airport_code: "CCU", flag_code: "IN" },
  {
    city: "Calgary AB",
    country: "Canada",
    airport_code: "YYC",
    flag_code: "CA",
  },
  { city: "Cali", country: "Colombia", airport_code: "CLO", flag_code: "CO" },
  {
    city: "Calvi Corsica",
    country: "France",
    airport_code: "CLY",
    flag_code: "FR",
  },
  {
    city: "Campbell River BC",
    country: "Canada",
    airport_code: "YBL",
    flag_code: "CA",
  },
  {
    city: "Campbeltown",
    country: "Scotland",
    airport_code: "CAL",
    flag_code: "_scotland",
  },
  { city: "Campeche", country: "Mexico", airport_code: "CPE", flag_code: "MX" },
  {
    city: "Canberra A.C.T.",
    country: "Australia",
    airport_code: "CBR",
    flag_code: "AU",
  },
  { city: "Cancun", country: "Mexico", airport_code: "CUN", flag_code: "MX" },
  {
    city: "Cap-aux-Meules",
    country: "Canada",
    airport_code: "YGR",
    flag_code: "CA",
  },
  {
    city: "Cap-aux-Meules Magdalens Islands PQ",
    country: "Canada",
    airport_code: "YGR",
    flag_code: "CA",
  },
  {
    city: "Cape Girardeau MO",
    country: "USA",
    airport_code: "CGI",
    flag_code: "US",
  },
  {
    city: "Cape Town",
    country: "South Africa",
    airport_code: "CPT",
    flag_code: "ZA",
  },
  {
    city: "Caracas",
    country: "Venezuela",
    airport_code: "CCS",
    flag_code: "VE",
  },
  { city: "Cardiff", country: "Wales", airport_code: "CWL", flag_code: "NN" },
  { city: "Carlsbad CA", country: "USA", airport_code: "CLD", flag_code: "US" },
  { city: "Carlsbad NM", country: "USA", airport_code: "CNM", flag_code: "US" },
  {
    city: "Cartagena",
    country: "Colombia",
    airport_code: "CTG",
    flag_code: "CO",
  },
  {
    city: "Casablanca",
    country: "Morocco",
    airport_code: "CMN",
    flag_code: "MA",
  },
  { city: "Casper WY", country: "USA", airport_code: "CPR", flag_code: "US" },
  {
    city: "Castlegar BC",
    country: "Canada",
    airport_code: "YCG",
    flag_code: "CA",
  },
  {
    city: "Castries",
    country: "St. Lucia",
    airport_code: "SLU",
    flag_code: "LC",
  },
  {
    city: "Catania Sicily",
    country: "Italy",
    airport_code: "CTA",
    flag_code: "IT",
  },
  {
    city: "Cayenne",
    country: "French Guiana",
    airport_code: "CAY",
    flag_code: "GF",
  },
  {
    city: "Cayman Brac",
    country: "Cayman Islands",
    airport_code: "CYB",
    flag_code: "KY",
  },
  {
    city: "Cebu",
    country: "Philippines",
    airport_code: "CEB",
    flag_code: "PH",
  },
  {
    city: "Cedar Rapids IA",
    country: "USA",
    airport_code: "CID",
    flag_code: "US",
  },
  {
    city: "Ceduna S.A.",
    country: "Australia",
    airport_code: "CED",
    flag_code: "AU",
  },
  {
    city: "Champaign IL",
    country: "USA",
    airport_code: "CMI",
    flag_code: "US",
  },
  { city: "Changchun", country: "China", airport_code: "CGQ", flag_code: "CN" },
  { city: "Changsha", country: "China", airport_code: "CSX", flag_code: "CN" },
  {
    city: "Chania Crete",
    country: "Greece",
    airport_code: "CHQ",
    flag_code: "GR",
  },
  {
    city: "Charleston SC",
    country: "USA",
    airport_code: "CHS",
    flag_code: "US",
  },
  {
    city: "Charleston WV",
    country: "USA",
    airport_code: "CRW",
    flag_code: "US",
  },
  {
    city: "Charleville Qld.",
    country: "Australia",
    airport_code: "CTL",
    flag_code: "AU",
  },
  {
    city: "Charlotte Amalie St. Thomas",
    country: "U.S. Virgin Islands",
    airport_code: "STT",
    flag_code: "VI",
  },
  {
    city: "Charlotte NC",
    country: "USA",
    airport_code: "CLT",
    flag_code: "US",
  },
  {
    city: "Charlottesville VA",
    country: "USA",
    airport_code: "CHO",
    flag_code: "US",
  },
  {
    city: "Charlottetown PEI",
    country: "Canada",
    airport_code: "YYG",
    flag_code: "CA",
  },
  {
    city: "Charlottetown Chattanooga TN",
    country: "USA",
    airport_code: "CHA",
    flag_code: "US",
  },
  { city: "Chengdu", country: "China", airport_code: "CTU", flag_code: "CN" },
  {
    city: "Chennai (Madras)",
    country: "India",
    airport_code: "MAA",
    flag_code: "IN",
  },
  { city: "Chetumal", country: "Mexico", airport_code: "CTM", flag_code: "MX" },
  { city: "Cheyenne WY", country: "USA", airport_code: "CYS", flag_code: "US" },
  {
    city: "Chiang Mai",
    country: "Thailand",
    airport_code: "CNX",
    flag_code: "TH",
  },
  {
    city: "Chiang Rai",
    country: "Thailand",
    airport_code: "CEI",
    flag_code: "TH",
  },
  { city: "Chicago IL", country: "USA", airport_code: "MDW", flag_code: "US" },
  { city: "Chicago IL", country: "USA", airport_code: "ORD", flag_code: "US" },
  { city: "Chico CA", country: "USA", airport_code: "CIC", flag_code: "US" },
  {
    city: "Chihuahua",
    country: "Mexico",
    airport_code: "CUU",
    flag_code: "MX",
  },
  { city: "Chios", country: "Greece", airport_code: "JKH", flag_code: "GR" },
  {
    city: "Chisinau",
    country: "Moldova",
    airport_code: "KIV",
    flag_code: "MD",
  },
  { city: "Chongqing", country: "China", airport_code: "CKG", flag_code: "CN" },
  {
    city: "Christchurch",
    country: "New Zealand",
    airport_code: "CHC",
    flag_code: "NZ",
  },
  {
    city: "Christiansted St. Croix",
    country: "U.S. Virgin Islands",
    airport_code: "STX",
    flag_code: "VI",
  },
  {
    city: "Churchill Cincinnati OH",
    country: "USA",
    airport_code: "CVG",
    flag_code: "US",
  },
  {
    city: "Ciudad Bolivar",
    country: "Venezuela",
    airport_code: "CBL",
    flag_code: "VE",
  },
  {
    city: "Ciudad Del Carmen",
    country: "Mexico",
    airport_code: "CME",
    flag_code: "MX",
  },
  {
    city: "Ciudad del Este",
    country: "Paraguay",
    airport_code: "AGT",
    flag_code: "PY",
  },
  {
    city: "Ciudad Juarez",
    country: "Mexico",
    airport_code: "CJS",
    flag_code: "MX",
  },
  {
    city: "Ciudad Obregon",
    country: "Mexico",
    airport_code: "CEN",
    flag_code: "MX",
  },
  {
    city: "Ciudad Victoria",
    country: "Mexico",
    airport_code: "CVM",
    flag_code: "MX",
  },
  {
    city: "Clarksburg WV",
    country: "USA",
    airport_code: "CKB",
    flag_code: "US",
  },
  {
    city: "Clermont-Ferrand",
    country: "France",
    airport_code: "CFE",
    flag_code: "FR",
  },
  {
    city: "Cleveland OH",
    country: "USA",
    airport_code: "CLE",
    flag_code: "US",
  },
  { city: "Clovis NM", country: "USA", airport_code: "CVN", flag_code: "US" },
  {
    city: "Cockburn Town San Salvador Island",
    country: "Bahamas",
    airport_code: "ZSA",
    flag_code: "BS",
  },
  { city: "Cody WY", country: "USA", airport_code: "COD", flag_code: "US" },
  {
    city: "Coffs Harbour N.S.W.",
    country: "Australia",
    airport_code: "CFS",
    flag_code: "AU",
  },
  { city: "Colima", country: "Mexico", airport_code: "CLQ", flag_code: "MX" },
  {
    city: "College Station TX",
    country: "USA",
    airport_code: "CLL",
    flag_code: "US",
  },
  { city: "Cologne", country: "Germany", airport_code: "CGN", flag_code: "DE" },
  {
    city: "Colombo",
    country: "Sri Lanka",
    airport_code: "CMB",
    flag_code: "LK",
  },
  {
    city: "Colorado Springs CO",
    country: "USA",
    airport_code: "COS",
    flag_code: "US",
  },
  { city: "Columbia MO", country: "USA", airport_code: "COU", flag_code: "US" },
  { city: "Columbia SC", country: "USA", airport_code: "CAE", flag_code: "US" },
  { city: "Columbus GA", country: "USA", airport_code: "CSG", flag_code: "US" },
  { city: "Columbus IN", country: "USA", airport_code: "CLU", flag_code: "US" },
  { city: "Columbus MS", country: "USA", airport_code: "GTR", flag_code: "US" },
  { city: "Columbus NE", country: "USA", airport_code: "OLU", flag_code: "US" },
  { city: "Columbus OH", country: "USA", airport_code: "CMH", flag_code: "US" },
  { city: "Conakry", country: "Guinea", airport_code: "CKY", flag_code: "GQ" },
  {
    city: "Concepcion",
    country: "Chile",
    airport_code: "CCP",
    flag_code: "CL",
  },
  {
    city: "Connaught",
    country: "Ireland",
    airport_code: "NOC",
    flag_code: "IE",
  },
  {
    city: "Coober Pedy S.A.",
    country: "Australia",
    airport_code: "CPD",
    flag_code: "AU",
  },
  {
    city: "Copenhagen",
    country: "Denmark",
    airport_code: "CPH",
    flag_code: "DK",
  },
  { city: "Copiapo", country: "Chile", airport_code: "CPO", flag_code: "CL" },
  {
    city: "Cordoba",
    country: "Argentina",
    airport_code: "COR",
    flag_code: "AR",
  },
  { city: "Cordova AK", country: "USA", airport_code: "CDV", flag_code: "US" },
  { city: "Cork", country: "Ireland", airport_code: "ORK", flag_code: "IE" },
  {
    city: "Corpus Christi TX",
    country: "USA",
    airport_code: "CRP",
    flag_code: "US",
  },
  {
    city: "Cotabato",
    country: "Philippines",
    airport_code: "CBO",
    flag_code: "PH",
  },
  { city: "Cozumel", country: "Mexico", airport_code: "CZM", flag_code: "MX" },
  {
    city: "Cranbrook Crotone",
    country: "Italy",
    airport_code: "CRV",
    flag_code: "IT",
  },
  {
    city: "Cubi Point Nas",
    country: "Philippines",
    airport_code: "NCP",
    flag_code: "PH",
  },
  { city: "Culiacan", country: "Mexico", airport_code: "CUL", flag_code: "MX" },
  { city: "Curitiba", country: "Brazil", airport_code: "CWB", flag_code: "BR" },
  { city: "Cusco", country: "Peru", airport_code: "CUZ", flag_code: "PE" },
  { city: "Da Nang", country: "Vietnam", airport_code: "DAD", flag_code: "VN" },
  { city: "Dakar", country: "Senegal", airport_code: "DKR", flag_code: "NN" },
  { city: "Dalaman", country: "Turkey", airport_code: "DLM", flag_code: "TR" },
  { city: "Dali City", country: "China", airport_code: "DLU", flag_code: "CN" },
  { city: "Dalian", country: "China", airport_code: "DLC", flag_code: "CN" },
  { city: "Dallas TX", country: "USA", airport_code: "DAL", flag_code: "US" },
  { city: "Dallas TX", country: "USA", airport_code: "DFW", flag_code: "US" },
  { city: "Damascus", country: "Syria", airport_code: "DAM", flag_code: "SY" },
  { city: "Dangriga", country: "Belize", airport_code: "DGA", flag_code: "NN" },
  {
    city: "Dar es Salaam",
    country: "Tanzania",
    airport_code: "DAR",
    flag_code: "TZ",
  },
  {
    city: "Darwin N.T.",
    country: "Australia",
    airport_code: "DRW",
    flag_code: "AU",
  },
  {
    city: "Davao",
    country: "Philippines",
    airport_code: "DVO",
    flag_code: "PH",
  },
  {
    city: "Dawadmi",
    country: "Saudi Arabia",
    airport_code: "DWD",
    flag_code: "SA",
  },
  { city: "Dayong", country: "China", airport_code: "DYG", flag_code: "CN" },
  { city: "Dayton OH", country: "USA", airport_code: "DAY", flag_code: "US" },
  {
    city: "Daytona Beach FL",
    country: "USA",
    airport_code: "DAB",
    flag_code: "US",
  },
  {
    city: "Deadmans Cay Long Island",
    country: "Bahamas",
    airport_code: "LGI",
    flag_code: "BS",
  },
  {
    city: "Deboyne",
    country: "Papua-New Guinea",
    airport_code: "DOY",
    flag_code: "NN",
  },
  { city: "Decatur IL", country: "USA", airport_code: "DEC", flag_code: "US" },
  { city: "Delhi", country: "India", airport_code: "DEL", flag_code: "IN" },
  {
    city: "Denpasar Bali",
    country: "Indonesia",
    airport_code: "DPS",
    flag_code: "ID",
  },
  { city: "Denver CO", country: "USA", airport_code: "DEN", flag_code: "US" },
  {
    city: "Des Moines IA",
    country: "USA",
    airport_code: "DSM",
    flag_code: "US",
  },
  { city: "Detroit MI", country: "USA", airport_code: "DTW", flag_code: "US" },
  {
    city: "Devils Lake ND",
    country: "USA",
    airport_code: "DVL",
    flag_code: "US",
  },
  {
    city: "Devonport Tas.",
    country: "Australia",
    airport_code: "DPO",
    flag_code: "AU",
  },
  {
    city: "Dhahran",
    country: "Saudi Arabia",
    airport_code: "DHA",
    flag_code: "SA",
  },
  {
    city: "Dhaka",
    country: "Bangladesh",
    airport_code: "DAC",
    flag_code: "BD",
  },
  {
    city: "Dickinson ND",
    country: "USA",
    airport_code: "DIK",
    flag_code: "US",
  },
  {
    city: "Dien-Bien-Phu",
    country: "Vietnam",
    airport_code: "DIN",
    flag_code: "VN",
  },
  {
    city: "Dillingham AK",
    country: "USA",
    airport_code: "DLG",
    flag_code: "US",
  },
  {
    city: "Dipolog",
    country: "Philippines",
    airport_code: "DPL",
    flag_code: "PH",
  },
  {
    city: "Dnepropetrovsk",
    country: "Ukraine",
    airport_code: "DNK",
    flag_code: "UA",
  },
  {
    city: "Dodge City KS",
    country: "USA",
    airport_code: "DDC",
    flag_code: "US",
  },
  { city: "Doha", country: "Qatar", airport_code: "DOH", flag_code: "QA" },
  {
    city: "Doncaster",
    country: "England",
    airport_code: "DSA",
    flag_code: "GB",
  },
  { city: "Donetsk", country: "Ukraine", airport_code: "DOK", flag_code: "UA" },
  {
    city: "Dortmund",
    country: "Germany",
    airport_code: "DTM",
    flag_code: "DE",
  },
  { city: "Dothan AL", country: "USA", airport_code: "DHN", flag_code: "US" },
  { city: "Douala", country: "Cameroon", airport_code: "DLA", flag_code: "NN" },
  { city: "Dresden", country: "Germany", airport_code: "DRS", flag_code: "DE" },
  {
    city: "Dryden Du Bois PA",
    country: "USA",
    airport_code: "DUJ",
    flag_code: "US",
  },
  {
    city: "Dubai",
    country: "United Arab Emirates",
    airport_code: "DXB",
    flag_code: "AE",
  },
  {
    city: "Dubbo N.S.W.",
    country: "Australia",
    airport_code: "DBO",
    flag_code: "AU",
  },
  { city: "Dublin", country: "Ireland", airport_code: "DUB", flag_code: "IE" },
  {
    city: "Dubrovnik",
    country: "Croatia",
    airport_code: "DBV",
    flag_code: "HR",
  },
  { city: "Dubuque IA", country: "USA", airport_code: "DBQ", flag_code: "US" },
  { city: "Duluth MN", country: "USA", airport_code: "DLH", flag_code: "US" },
  {
    city: "Dumaguete",
    country: "Philippines",
    airport_code: "DGT",
    flag_code: "PH",
  },
  {
    city: "Dunedin",
    country: "New Zealand",
    airport_code: "DUD",
    flag_code: "NZ",
  },
  { city: "Durango", country: "Mexico", airport_code: "DGO", flag_code: "MX" },
  { city: "Durango CO", country: "USA", airport_code: "DRO", flag_code: "US" },
  {
    city: "Durban",
    country: "South Africa",
    airport_code: "DUR",
    flag_code: "SA",
  },
  {
    city: "Dushanbe",
    country: "Tajikistan",
    airport_code: "DYU",
    flag_code: "TJ",
  },
  {
    city: "Dusseldorf",
    country: "Germany",
    airport_code: "DUS",
    flag_code: "DE",
  },
  {
    city: "Dutch Harbor Un Island AK",
    country: "USA",
    airport_code: "DUT",
    flag_code: "US",
  },
  {
    city: "East London",
    country: "South Africa",
    airport_code: "ELS",
    flag_code: "SA",
  },
  {
    city: "East Midlands",
    country: "England",
    airport_code: "EMA",
    flag_code: "GB",
  },
  {
    city: "Eau Claire WI",
    country: "USA",
    airport_code: "EAU",
    flag_code: "US",
  },
  {
    city: "Edinburgh",
    country: "Scotland",
    airport_code: "EDI",
    flag_code: "_scotland",
  },
  {
    city: "Edmonton Eek AK",
    country: "USA",
    airport_code: "EEK",
    flag_code: "US",
  },
  {
    city: "Ekaterinburg Siberia",
    country: "Russia",
    airport_code: "SVX",
    flag_code: "RU",
  },
  {
    city: "El Calafate",
    country: "Argentina",
    airport_code: "FTE",
    flag_code: "AR",
  },
  {
    city: "El Centro CA",
    country: "USA",
    airport_code: "IPL",
    flag_code: "US",
  },
  {
    city: "El Dorado AR",
    country: "USA",
    airport_code: "ELD",
    flag_code: "US",
  },
  { city: "El Paso TX", country: "USA", airport_code: "ELP", flag_code: "US" },
  {
    city: "El Salvador",
    country: "Chile",
    airport_code: "ESR",
    flag_code: "CL",
  },
  { city: "Elko NV", country: "USA", airport_code: "EKO", flag_code: "US" },
  { city: "Elmira NY", country: "USA", airport_code: "ELM", flag_code: "US" },
  {
    city: "Emerald Qld.",
    country: "Australia",
    airport_code: "EMD",
    flag_code: "AU",
  },
  { city: "Enid OK", country: "USA", airport_code: "WDG", flag_code: "US" },
  { city: "Entebbe", country: "Uganda", airport_code: "EBB", flag_code: "UG" },
  { city: "Erfurt", country: "Germany", airport_code: "ERF", flag_code: "DE" },
  { city: "Erie PA", country: "USA", airport_code: "ERI", flag_code: "US" },
  { city: "Esbjerg", country: "Denmark", airport_code: "EBJ", flag_code: "DK" },
  { city: "Escanaba MI", country: "USA", airport_code: "ESC", flag_code: "US" },
  { city: "Eugene OR", country: "USA", airport_code: "EUG", flag_code: "US" },
  { city: "Eureka CA", country: "USA", airport_code: "ACV", flag_code: "US" },
  {
    city: "Evansville IN",
    country: "USA",
    airport_code: "EVV",
    flag_code: "US",
  },
  { city: "Evenes", country: "Norway", airport_code: "EVE", flag_code: "NO" },
  {
    city: "Fairbanks AK",
    country: "USA",
    airport_code: "FAI",
    flag_code: "US",
  },
  { city: "Fargo ND", country: "USA", airport_code: "FAR", flag_code: "US" },
  {
    city: "Farmington NM",
    country: "USA",
    airport_code: "FMN",
    flag_code: "US",
  },
  { city: "Faro", country: "Portugal", airport_code: "FAO", flag_code: "PT" },
  {
    city: "Fayetteville AR",
    country: "USA",
    airport_code: "XNA",
    flag_code: "US",
  },
  {
    city: "Fayetteville NC",
    country: "USA",
    airport_code: "FAY",
    flag_code: "US",
  },
  {
    city: "Fernando de Noronha",
    country: "Brazil",
    airport_code: "FEN",
    flag_code: "BR",
  },
  {
    city: "Figari Corsica",
    country: "France",
    airport_code: "FSC",
    flag_code: "FR",
  },
  {
    city: "Flagstaff AZ",
    country: "USA",
    airport_code: "FLG",
    flag_code: "US",
  },
  {
    city: "Flin Flon Flint MI",
    country: "USA",
    airport_code: "FNT",
    flag_code: "US",
  },
  { city: "Florence", country: "Italy", airport_code: "FLR", flag_code: "IT" },
  { city: "Florence SC", country: "USA", airport_code: "FLO", flag_code: "US" },
  {
    city: "Flores",
    country: "Guatemala",
    airport_code: "FRS",
    flag_code: "NN",
  },
  {
    city: "Fort Dodge IA",
    country: "USA",
    airport_code: "FOD",
    flag_code: "US",
  },
  {
    city: "Fort Lauderdale FL",
    country: "USA",
    airport_code: "FLL",
    flag_code: "US",
  },
  {
    city: "Fort Leonard Wood MO",
    country: "USA",
    airport_code: "TBN",
    flag_code: "US",
  },
  {
    city: "Fort McMurray Fort Myers FL",
    country: "USA",
    airport_code: "RSW",
    flag_code: "US",
  },
  {
    city: "Fort Nelson Fort Saint John Fort Smith AR",
    country: "USA",
    airport_code: "FSM",
    flag_code: "US",
  },
  {
    city: "Fort Smith Fort Walton Beach FL",
    country: "USA",
    airport_code: "VPS",
    flag_code: "US",
  },
  {
    city: "Fort Wayne IN",
    country: "USA",
    airport_code: "FWA",
    flag_code: "US",
  },
  {
    city: "Fort Yukon AK",
    country: "USA",
    airport_code: "FYU",
    flag_code: "US",
  },
  {
    city: "Fort-de-France Martinique",
    country: "FR",
    airport_code: "FDF",
    flag_code: "NN",
  },
  {
    city: "Frankfurt",
    country: "Germany",
    airport_code: "FRA",
    flag_code: "DE",
  },
  {
    city: "Fredericton Freeport Grand Bahama Island",
    country: "Bahamas",
    airport_code: "FPO",
    flag_code: "BS",
  },
  {
    city: "Freetown",
    country: "Sierra Leone",
    airport_code: "FNA",
    flag_code: "NN",
  },
  { city: "Fresno CA", country: "USA", airport_code: "FAT", flag_code: "US" },
  {
    city: "Friedrichshafen",
    country: "Germany",
    airport_code: "FDH",
    flag_code: "DE",
  },
  { city: "Fukuoka", country: "Japan", airport_code: "FUK", flag_code: "JP" },
  { city: "Fukushima", country: "Japan", airport_code: "FKS", flag_code: "JP" },
  { city: "Funchal", country: "Madeira", airport_code: "FNC", flag_code: "NN" },
  { city: "Fuzhou", country: "China", airport_code: "FOC", flag_code: "CN" },
  {
    city: "Gainesville FL",
    country: "USA",
    airport_code: "GNV",
    flag_code: "US",
  },
  {
    city: "Gander Garden City KS",
    country: "USA",
    airport_code: "GCK",
    flag_code: "US",
  },
  {
    city: "Gaspe Gdansk",
    country: "Poland",
    airport_code: "GDN",
    flag_code: "PL",
  },
  {
    city: "General Santos",
    country: "Philippines",
    airport_code: "GES",
    flag_code: "PH",
  },
  {
    city: "Geneva",
    country: "Switzerland",
    airport_code: "GVA",
    flag_code: "CH",
  },
  { city: "Genoa", country: "Italy", airport_code: "GOA", flag_code: "IT" },
  {
    city: "George Town Great Exuma Island",
    country: "Bahamas",
    airport_code: "GGT",
    flag_code: "BS",
  },
  {
    city: "Georgetown",
    country: "Guyana",
    airport_code: "GEO",
    flag_code: "EG",
  },
  {
    city: "Georgetown",
    country: "Grand Cayman Island",
    airport_code: "Cayman Islands",
    flag_code: "GCM",
  },
  { city: "Gillette WY", country: "USA", airport_code: "GCC", flag_code: "US" },
  {
    city: "Gisborne",
    country: "New Zealand",
    airport_code: "GIS",
    flag_code: "NZ",
  },
  {
    city: "Gladstone Qld.",
    country: "Australia",
    airport_code: "GLT",
    flag_code: "AU",
  },
  {
    city: "Glasgow",
    country: "Scotland",
    airport_code: "GLA",
    flag_code: "_scotland",
  },
  {
    city: "Glasgow",
    country: "Scotland",
    airport_code: "PIK",
    flag_code: "_scotland",
  },
  { city: "Glendive MT", country: "USA", airport_code: "GDV", flag_code: "US" },
  {
    city: "Gold Coast Qld.",
    country: "Australia",
    airport_code: "OOL",
    flag_code: "AU",
  },
  {
    city: "Gothenburg",
    country: "Sweden",
    airport_code: "GOT",
    flag_code: "SE",
  },
  {
    city: "Gove N.T.",
    country: "Australia",
    airport_code: "GOV",
    flag_code: "AU",
  },
  {
    city: "Governor's Harbour Eleuthera Island",
    country: "Bahamas",
    airport_code: "GHB",
    flag_code: "BS",
  },
  { city: "Granada", country: "Spain", airport_code: "GRX", flag_code: "ES" },
  {
    city: "Grand Forks ND",
    country: "USA",
    airport_code: "GFK",
    flag_code: "US",
  },
  {
    city: "Grand Island NE",
    country: "USA",
    airport_code: "GRI",
    flag_code: "US",
  },
  {
    city: "Grand Junction CO",
    country: "USA",
    airport_code: "GJT",
    flag_code: "US",
  },
  {
    city: "Grand Rapids MI",
    country: "USA",
    airport_code: "GRR",
    flag_code: "US",
  },
  {
    city: "Grande PrairieGraz",
    country: "Austria",
    airport_code: "GRZ",
    flag_code: "AT",
  },
  {
    city: "Great Bend KS",
    country: "USA",
    airport_code: "GBD",
    flag_code: "US",
  },
  {
    city: "Great Falls MT",
    country: "USA",
    airport_code: "GTF",
    flag_code: "US",
  },
  {
    city: "Green Bay WI",
    country: "USA",
    airport_code: "GRB",
    flag_code: "US",
  },
  {
    city: "Greenbrier WV",
    country: "USA",
    airport_code: "LWB",
    flag_code: "US",
  },
  {
    city: "Greensboro NC",
    country: "USA",
    airport_code: "GSO",
    flag_code: "US",
  },
  {
    city: "Greenville MS",
    country: "USA",
    airport_code: "GLH",
    flag_code: "US",
  },
  {
    city: "Greenville NC",
    country: "USA",
    airport_code: "PGV",
    flag_code: "US",
  },
  {
    city: "Greenville SC",
    country: "USA",
    airport_code: "GSP",
    flag_code: "US",
  },
  {
    city: "Griffith N.S.W.",
    country: "Australia",
    airport_code: "GFF",
    flag_code: "AU",
  },
  {
    city: "Guadalajara",
    country: "Mexico",
    airport_code: "GDL",
    flag_code: "MX",
  },
  { city: "Guangzhou", country: "China", airport_code: "CAN", flag_code: "CN" },
  {
    city: "Guatemala City",
    country: "Guatemala",
    airport_code: "GUA",
    flag_code: "NN",
  },
  {
    city: "Guayaquil",
    country: "Ecuador",
    airport_code: "GYE",
    flag_code: "NN",
  },
  { city: "Guaymas", country: "Mexico", airport_code: "GYM", flag_code: "MX" },
  {
    city: "Guernsey",
    country: "Channel Islands",
    airport_code: "GCI",
    flag_code: "NN",
  },
  { city: "Guilin", country: "China", airport_code: "KWL", flag_code: "CN" },
  { city: "Gulfport MS", country: "USA", airport_code: "GPT", flag_code: "US" },
  { city: "Gunnison CO", country: "USA", airport_code: "GUC", flag_code: "US" },
  {
    city: "Gustavia",
    country: "St. Barts",
    airport_code: "SBH",
    flag_code: "NN",
  },
  {
    city: "Hagerstown MD",
    country: "USA",
    airport_code: "HGR",
    flag_code: "US",
  },
  {
    city: "Haikou Hainan Island",
    country: "China",
    airport_code: "HAK",
    flag_code: "CN",
  },
  { city: "Hailar", country: "China", airport_code: "HLD", flag_code: "CN" },
  { city: "Hakodate", country: "Japan", airport_code: "HKD", flag_code: "JP" },
  {
    city: "Halifax Halmstad",
    country: "Sweden",
    airport_code: "HAD",
    flag_code: "SE",
  },
  { city: "Hamburg", country: "Germany", airport_code: "HAM", flag_code: "DE" },
  {
    city: "Hamilton",
    country: "Bermuda",
    airport_code: "BDA",
    flag_code: "BM",
  },
  {
    city: "Hamilton",
    country: "New Zealand",
    airport_code: "HLZ",
    flag_code: "NZ",
  },
  {
    city: "Hamilton Island Whitsunday Island Qld.",
    country: "Australia",
    airport_code: "HTI",
    flag_code: "AU",
  },
  {
    city: "Hamilton Hammerfest",
    country: "Norway",
    airport_code: "HFT",
    flag_code: "NO",
  },
  {
    city: "Hana Maui HI",
    country: "USA",
    airport_code: "HNM",
    flag_code: "US",
  },
  { city: "Hancock MI", country: "USA", airport_code: "CMX", flag_code: "US" },
  {
    city: "Hanga Roa Easter Island",
    country: "Chile",
    airport_code: "IPC",
    flag_code: "CL",
  },
  { city: "Hangzhou", country: "China", airport_code: "HGH", flag_code: "CN" },
  {
    city: "Hannover",
    country: "Germany",
    airport_code: "HAJ",
    flag_code: "DE",
  },
  { city: "Hanoi", country: "Vietnam", airport_code: "HAN", flag_code: "VN" },
  { city: "Harare", country: "Zimbabwe", airport_code: "HRE", flag_code: "ZW" },
  { city: "Harbin", country: "China", airport_code: "HRB", flag_code: "CN" },
  {
    city: "Harlingen TX",
    country: "USA",
    airport_code: "HRL",
    flag_code: "US",
  },
  {
    city: "Harrisburg PA",
    country: "USA",
    airport_code: "MDT",
    flag_code: "US",
  },
  { city: "Harrison AR", country: "USA", airport_code: "HRO", flag_code: "US" },
  { city: "Hartford CT", country: "USA", airport_code: "BDL", flag_code: "US" },
  {
    city: "Hat Yai",
    country: "Thailand",
    airport_code: "HDY",
    flag_code: "TH",
  },
  {
    city: "Haugesund",
    country: "Norway",
    airport_code: "HAU",
    flag_code: "NO",
  },
  { city: "Havana", country: "Cuba", airport_code: "HAV", flag_code: "CU" },
  { city: "Havre MT", country: "USA", airport_code: "HVR", flag_code: "US" },
  { city: "Hays KS", country: "USA", airport_code: "HYS", flag_code: "US" },
  { city: "Hefei", country: "China", airport_code: "HFE", flag_code: "CN" },
  { city: "Helena MT", country: "USA", airport_code: "HLN", flag_code: "US" },
  {
    city: "Helsingborg",
    country: "Sweden",
    airport_code: "AGH",
    flag_code: "SE",
  },
  {
    city: "Helsinki",
    country: "Finland",
    airport_code: "HEL",
    flag_code: "FI",
  },
  { city: "Hemavan", country: "Sweden", airport_code: "HMV", flag_code: "SE" },
  { city: "Hengchun", country: "Taiwan", airport_code: "HCN", flag_code: "TW" },
  {
    city: "Heraklion Crete",
    country: "Greece",
    airport_code: "HER",
    flag_code: "GR",
  },
  {
    city: "Hermosillo",
    country: "Mexico",
    airport_code: "HMO",
    flag_code: "MX",
  },
  { city: "Hibbing MN", country: "USA", airport_code: "HIB", flag_code: "US" },
  { city: "Hilo Hawaii", country: "USA", airport_code: "Big", flag_code: "US" },
  {
    city: "Hilton Head Island SC",
    country: "USA",
    airport_code: "HHH",
    flag_code: "US",
  },
  {
    city: "Ho Chi Minh City (Saigon)",
    country: "Vietnam",
    airport_code: "SGN",
    flag_code: "VN",
  },
  {
    city: "Hobart Tas.",
    country: "Australia",
    airport_code: "HBA",
    flag_code: "AU",
  },
  { city: "Hobbs NM", country: "USA", airport_code: "HOB", flag_code: "US" },
  { city: "Hof", country: "Germany", airport_code: "HOQ", flag_code: "DE" },
  { city: "Hohhot", country: "China", airport_code: "HET", flag_code: "CN" },
  {
    city: "Hokitika",
    country: "New Zealand",
    airport_code: "HKK",
    flag_code: "NZ",
  },
  { city: "Homer AK", country: "USA", airport_code: "HOM", flag_code: "US" },
  { city: "Hong Kong", country: "China", airport_code: "HKG", flag_code: "CN" },
  {
    city: "Honiara Guadalcanal",
    country: "Solomon Islands",
    airport_code: "HIR",
    flag_code: "NN",
  },
  {
    city: "Honolulu Oahu HI",
    country: "USA",
    airport_code: "HNL",
    flag_code: "US",
  },
  {
    city: "Hoolehua Molokai HI",
    country: "USA",
    airport_code: "MKK",
    flag_code: "US",
  },
  {
    city: "Horn Island Qld.",
    country: "Australia",
    airport_code: "HID",
    flag_code: "AU",
  },
  {
    city: "Hot Springs AR",
    country: "USA",
    airport_code: "HOT",
    flag_code: "US",
  },
  {
    city: "Houmt Souk",
    country: "Tunisia",
    airport_code: "DJE",
    flag_code: "TN",
  },
  { city: "Houston TX", country: "USA", airport_code: "EFD", flag_code: "US" },
  { city: "Houston TX", country: "USA", airport_code: "HOU", flag_code: "US" },
  { city: "Houston TX", country: "USA", airport_code: "IAH", flag_code: "US" },
  { city: "Hualien", country: "Taiwan", airport_code: "HUN", flag_code: "TW" },
  { city: "Hue", country: "Vietnam", airport_code: "HUI", flag_code: "VN" },
  {
    city: "Humberside",
    country: "England",
    airport_code: "HUY",
    flag_code: "GB",
  },
  {
    city: "Huntington WV",
    country: "USA",
    airport_code: "HTS",
    flag_code: "US",
  },
  {
    city: "Huntsville AL",
    country: "USA",
    airport_code: "HSV",
    flag_code: "US",
  },
  { city: "Hurghada", country: "Egypt", airport_code: "HRG", flag_code: "EG" },
  { city: "Huron SD", country: "USA", airport_code: "HON", flag_code: "US" },
  { city: "Hyannis MA", country: "USA", airport_code: "HYA", flag_code: "US" },
  { city: "Hyderabad", country: "India", airport_code: "HYD", flag_code: "IN" },
  { city: "Ibadan", country: "Nigeria", airport_code: "IBA", flag_code: "NG" },
  { city: "Ibiza", country: "Spain", airport_code: "IBZ", flag_code: "ES" },
  {
    city: "Idaho Falls ID",
    country: "USA",
    airport_code: "IDA",
    flag_code: "US",
  },
  {
    city: "Ikaria Island",
    country: "Greece",
    airport_code: "JIK",
    flag_code: "GR",
  },
  {
    city: "Iloilo",
    country: "Philippines",
    airport_code: "ILO",
    flag_code: "PH",
  },
  {
    city: "Indianapolis IN IN",
    country: "USA",
    airport_code: "IND",
    flag_code: "US",
  },
  {
    city: "Innsbruck",
    country: "Austria",
    airport_code: "INN",
    flag_code: "AT",
  },
  {
    city: "International Falls MN",
    country: "USA",
    airport_code: "INL",
    flag_code: "US",
  },
  {
    city: "Invercargill",
    country: "New Zealand",
    airport_code: "IVC",
    flag_code: "NZ",
  },
  {
    city: "Inverness",
    country: "Scotland",
    airport_code: "INV",
    flag_code: "_scotland",
  },
  { city: "Inyokern CA", country: "USA", airport_code: "IYK", flag_code: "US" },
  { city: "Ioannina", country: "Greece", airport_code: "IOA", flag_code: "GR" },
  { city: "Ipoh", country: "Malaysia", airport_code: "IPH", flag_code: "MY" },
  {
    city: "Iqaluit Irkutsk Siberia",
    country: "Russia",
    airport_code: "IKT",
    flag_code: "RU",
  },
  {
    city: "Iron Mountain MI",
    country: "USA",
    airport_code: "IMT",
    flag_code: "US",
  },
  { city: "Ironwood MI", country: "USA", airport_code: "IWD", flag_code: "US" },
  {
    city: "Islamabad-Rawalpindi",
    country: "Pakistan",
    airport_code: "ISB",
    flag_code: "PK",
  },
  {
    city: "Islay Island",
    country: "Scotland",
    airport_code: "ILY",
    flag_code: "_scotland",
  },
  { city: "Islip NY", country: "USA", airport_code: "ISP", flag_code: "US" },
  { city: "Istanbul", country: "Turkey", airport_code: "IST", flag_code: "TR" },
  { city: "Istanbul", country: "Turkey", airport_code: "SAW", flag_code: "TR" },
  { city: "Ithaca NY", country: "USA", airport_code: "ITH", flag_code: "US" },
  { city: "Ivalo", country: "Finland", airport_code: "IVL", flag_code: "FI" },
  { city: "Iwami", country: "Japan", airport_code: "IWJ", flag_code: "JP" },
  { city: "Ixtapa", country: "Mexico", airport_code: "ZIH", flag_code: "MX" },
  { city: "Izmir", country: "Turkey", airport_code: "ADB", flag_code: "TR" },
  { city: "Izumo", country: "Japan", airport_code: "IZO", flag_code: "JP" },
  {
    city: "Jackson Hole WY",
    country: "USA",
    airport_code: "JAC",
    flag_code: "US",
  },
  { city: "Jackson MS", country: "USA", airport_code: "JAN", flag_code: "US" },
  { city: "Jackson TN", country: "USA", airport_code: "MKL", flag_code: "US" },
  {
    city: "Jacksonville FL",
    country: "USA",
    airport_code: "JAX",
    flag_code: "US",
  },
  {
    city: "Jacksonville NC",
    country: "USA",
    airport_code: "OAJ",
    flag_code: "US",
  },
  {
    city: "Jakarta Java",
    country: "Indonesia",
    airport_code: "CGK",
    flag_code: "ID",
  },
  {
    city: "Jakarta Java - All Airports",
    country: "Indonesia",
    airport_code: "JKT",
    flag_code: "ID",
  },
  { city: "Jalapa", country: "Mexico", airport_code: "JAL", flag_code: "MX" },
  {
    city: "Jamestown ND",
    country: "USA",
    airport_code: "JMS",
    flag_code: "US",
  },
  {
    city: "Jamestown NY",
    country: "USA",
    airport_code: "JHW",
    flag_code: "US",
  },
  {
    city: "Jeddah",
    country: "Saudi Arabia",
    airport_code: "JED",
    flag_code: "SA",
  },
  {
    city: "Jeju",
    country: "Korea Republic of",
    airport_code: "CJU",
    flag_code: "KR",
  },
  {
    city: "Jerez de La Frontera",
    country: "Spain",
    airport_code: "XRY",
    flag_code: "ES",
  },
  {
    city: "Jersey",
    country: "Channel Islands",
    airport_code: "JER",
    flag_code: "NN",
  },
  { city: "Jiamusi", country: "China", airport_code: "JMU", flag_code: "CN" },
  { city: "Jijiga", country: "Ethiopia", airport_code: "JIJ", flag_code: "ET" },
  { city: "Joensuu", country: "Finland", airport_code: "JOE", flag_code: "FI" },
  {
    city: "Johannesburg",
    country: "South Africa",
    airport_code: "JNB",
    flag_code: "SA",
  },
  {
    city: "Johnstown PA",
    country: "USA",
    airport_code: "JST",
    flag_code: "US",
  },
  {
    city: "Johor Bahru",
    country: "Malaysia",
    airport_code: "JHB",
    flag_code: "MY",
  },
  {
    city: "Jonesboro AR",
    country: "USA",
    airport_code: "JBR",
    flag_code: "US",
  },
  {
    city: "Jonkoping",
    country: "Sweden",
    airport_code: "JKG",
    flag_code: "SE",
  },
  { city: "Joplin MO", country: "USA", airport_code: "JLN", flag_code: "US" },
  { city: "Juneau AK", country: "USA", airport_code: "JNU", flag_code: "US" },
  {
    city: "Jyvaskyla",
    country: "Finland",
    airport_code: "JYV",
    flag_code: "FI",
  },
  {
    city: "Kabul",
    country: "Afghanistan",
    airport_code: "KBL",
    flag_code: "AF",
  },
  { city: "Kagoshima", country: "Japan", airport_code: "KOJ", flag_code: "JP" },
  {
    city: "Kahului Maui HI",
    country: "USA",
    airport_code: "OGG",
    flag_code: "US",
  },
  {
    city: "Kailua-Kona HI",
    country: "USA",
    airport_code: "KOA",
    flag_code: "US",
  },
  {
    city: "Kaitaia",
    country: "New Zealand",
    airport_code: "KAT",
    flag_code: "NZ",
  },
  { city: "Kajaani", country: "Finland", airport_code: "KAJ", flag_code: "FI" },
  {
    city: "Kalamazoo MI",
    country: "USA",
    airport_code: "AZO",
    flag_code: "US",
  },
  {
    city: "Kalaupapa Molokai HI",
    country: "USA",
    airport_code: "LUP",
    flag_code: "US",
  },
  {
    city: "Kalgoorlie W.A.",
    country: "Australia",
    airport_code: "KGI",
    flag_code: "AU",
  },
  {
    city: "Kalibo",
    country: "Philippines",
    airport_code: "KLO",
    flag_code: "PH",
  },
  {
    city: "Kaliningrad",
    country: "Russia",
    airport_code: "KGD",
    flag_code: "RU",
  },
  {
    city: "Kalispell MT",
    country: "USA",
    airport_code: "FCA",
    flag_code: "US",
  },
  { city: "Kalmar", country: "Sweden", airport_code: "KLR", flag_code: "SE" },
  {
    city: "Kamloops Kamuela Hawaii",
    country: "USA",
    airport_code: "KOA",
    flag_code: "US",
  },
  {
    city: "Kansas City MO",
    country: "USA",
    airport_code: "MCI",
    flag_code: "US",
  },
  {
    city: "Kaohsiung",
    country: "Taiwan",
    airport_code: "KHH",
    flag_code: "TW",
  },
  {
    city: "Kapalua Maui HI",
    country: "USA",
    airport_code: "JHM",
    flag_code: "US",
  },
  {
    city: "Karachi",
    country: "Pakistan",
    airport_code: "KHI",
    flag_code: "PK",
  },
  {
    city: "Karaganda",
    country: "Kazakhstan",
    airport_code: "KGF",
    flag_code: "KZ",
  },
  { city: "Karlstad", country: "Sweden", airport_code: "KSD", flag_code: "SE" },
  {
    city: "Karratha W.A.",
    country: "Australia",
    airport_code: "KTA",
    flag_code: "AU",
  },
  { city: "Karup", country: "Denmark", airport_code: "KRP", flag_code: "DK" },
  { city: "Kashi", country: "China", airport_code: "KHG", flag_code: "CN" },
  { city: "Kathmandu", country: "Nepal", airport_code: "KTM", flag_code: "NP" },
  { city: "Katowice", country: "Poland", airport_code: "KTW", flag_code: "PL" },
  { city: "Kearney NE", country: "USA", airport_code: "EAR", flag_code: "US" },
  {
    city: "Kelowna Kenai AK",
    country: "USA",
    airport_code: "ENA",
    flag_code: "US",
  },
  {
    city: "Kenora Kerikeri",
    country: "New Zealand",
    airport_code: "KKE",
    flag_code: "NZ",
  },
  {
    city: "Ketchikan AK",
    country: "USA",
    airport_code: "KTN",
    flag_code: "US",
  },
  { city: "Key West FL", country: "USA", airport_code: "EYW", flag_code: "US" },
  { city: "Kharkov", country: "Ukraine", airport_code: "HRK", flag_code: "UA" },
  { city: "Khartoum", country: "Sudan", airport_code: "KRT", flag_code: "SS" },
  {
    city: "Khon Kaen",
    country: "Thailand",
    airport_code: "KKC",
    flag_code: "TH",
  },
  { city: "Kiel", country: "Germany", airport_code: "KEL", flag_code: "DE" },
  { city: "Kiev", country: "Ukraine", airport_code: "KBP", flag_code: "UA" },
  { city: "Kigali", country: "Rwanda", airport_code: "KGL", flag_code: "RW" },
  { city: "Killeen TX", country: "USA", airport_code: "GRK", flag_code: "US" },
  {
    city: "King Salmon AK",
    country: "USA",
    airport_code: "AKN",
    flag_code: "US",
  },
  { city: "Kingman AZ", country: "USA", airport_code: "IGM", flag_code: "US" },
  {
    city: "Kingscote Kangaroo Island S.A.",
    country: "Australia",
    airport_code: "KGC",
    flag_code: "AU",
  },
  {
    city: "Kingston",
    country: "Jamaica",
    airport_code: "KIN",
    flag_code: "JM",
  },
  {
    city: "Kingston",
    country: "Jamaica",
    airport_code: "KTP",
    flag_code: "JM",
  },
  {
    city: "Kingston Kingstown",
    country: "St. Vincent",
    airport_code: "SVD",
    flag_code: "NN",
  },
  { city: "Kinshasa", country: "Zaire", airport_code: "FIH", flag_code: "NN" },
  {
    city: "Kirksville MO",
    country: "USA",
    airport_code: "IRK",
    flag_code: "US",
  },
  {
    city: "Kirkwall Orkney Islands",
    country: "Scotland",
    airport_code: "KOI",
    flag_code: "_scotland",
  },
  { city: "Kiruna", country: "Sweden", airport_code: "KRN", flag_code: "SE" },
  { city: "Kisangani", country: "Zaire", airport_code: "FKI", flag_code: "NN" },
  { city: "Kittila", country: "Finland", airport_code: "KTT", flag_code: "FI" },
  {
    city: "Klagenfurt",
    country: "Austria",
    airport_code: "KLU",
    flag_code: "AT",
  },
  {
    city: "Klamath Falls OR",
    country: "USA",
    airport_code: "LMT",
    flag_code: "US",
  },
  {
    city: "Knoxville TN",
    country: "USA",
    airport_code: "TYS",
    flag_code: "US",
  },
  { city: "Kodiak AK", country: "USA", airport_code: "ADQ", flag_code: "US" },
  {
    city: "Koh Samui",
    country: "Thailand",
    airport_code: "USM",
    flag_code: "TH",
  },
  { city: "Kokkola", country: "Finland", airport_code: "KOK", flag_code: "FI" },
  { city: "Komatsu", country: "Japan", airport_code: "KMQ", flag_code: "JP" },
  { city: "Koror", country: "Palau", airport_code: "ROR", flag_code: "NN" },
  { city: "Kos", country: "Greece", airport_code: "KGS", flag_code: "GR" },
  {
    city: "Kosrae",
    country: "Micronesia",
    airport_code: "KSA",
    flag_code: "NN",
  },
  {
    city: "Kota Bharu",
    country: "Malaysia",
    airport_code: "KBR",
    flag_code: "MY",
  },
  {
    city: "Kota Kinabalu Sabah",
    country: "Malaysia",
    airport_code: "BKI",
    flag_code: "MY",
  },
  { city: "Krabi", country: "Thailand", airport_code: "KBV", flag_code: "TH" },
  { city: "Krakow", country: "Poland", airport_code: "KRK", flag_code: "PL" },
  {
    city: "Kralendijk",
    country: "Bonaire",
    airport_code: "BON",
    flag_code: "NN",
  },
  { city: "Kramfors", country: "Sweden", airport_code: "KRF", flag_code: "SE" },
  {
    city: "Kristiansand",
    country: "Norway",
    airport_code: "KRS",
    flag_code: "NO",
  },
  {
    city: "Kristianstad",
    country: "Sweden",
    airport_code: "KID",
    flag_code: "SE",
  },
  {
    city: "Kuala Lumpur",
    country: "Malaysia",
    airport_code: "EG KUL",
    flag_code: "MY",
  },
  {
    city: "Kuala Terengganu",
    country: "Malaysia",
    airport_code: "TGG",
    flag_code: "MY",
  },
  {
    city: "Kuantan",
    country: "Malaysia",
    airport_code: "KUA",
    flag_code: "MY",
  },
  {
    city: "Kuching Sarawak",
    country: "Malaysia",
    airport_code: "KCH",
    flag_code: "MY",
  },
  { city: "Kumamoto", country: "Japan", airport_code: "KMJ", flag_code: "JP" },
  { city: "Kunming", country: "China", airport_code: "KMG", flag_code: "CN" },
  { city: "Kuopio", country: "Finland", airport_code: "KUO", flag_code: "FI" },
  { city: "Kushiro", country: "Japan", airport_code: "KUH", flag_code: "JP" },
  { city: "Kuwait", country: "Kuwait", airport_code: "KWI", flag_code: "KW" },
  {
    city: "Kzyl Orda",
    country: "Kazakhstan",
    airport_code: "KZO",
    flag_code: "KZ",
  },
  { city: "La Coruna", country: "Spain", airport_code: "LCG", flag_code: "ES" },
  {
    city: "La Crosse WI",
    country: "USA",
    airport_code: "LSE",
    flag_code: "US",
  },
  { city: "La Paz", country: "Mexico", airport_code: "LAP", flag_code: "MX" },
  { city: "La Paz", country: "Bolivia", airport_code: "LPB", flag_code: "NN" },
  {
    city: "La Romana",
    country: "Dominican Republic",
    airport_code: "LRM",
    flag_code: "NN",
  },
  { city: "La Serena", country: "Chile", airport_code: "LSC", flag_code: "CL" },
  {
    city: "Lafayette LA",
    country: "USA",
    airport_code: "LFT",
    flag_code: "US",
  },
  { city: "Lagos", country: "Nigeria", airport_code: "LOS", flag_code: "NG" },
  {
    city: "Lahad Datu Sabah",
    country: "Malaysia",
    airport_code: "LDU",
    flag_code: "MY",
  },
  { city: "Lahore", country: "Pakistan", airport_code: "LHE", flag_code: "PK" },
  {
    city: "Lake Charles LA",
    country: "USA",
    airport_code: "LCH",
    flag_code: "US",
  },
  {
    city: "Lake Havasu City AZ",
    country: "USA",
    airport_code: "HII",
    flag_code: "US",
  },
  {
    city: "Lamezia Terme",
    country: "Italy",
    airport_code: "SUF",
    flag_code: "IT",
  },
  {
    city: "Lanai City HI",
    country: "USA",
    airport_code: "LNY",
    flag_code: "US",
  },
  {
    city: "Lancaster PA",
    country: "USA",
    airport_code: "LNS",
    flag_code: "US",
  },
  {
    city: "Langkawi",
    country: "Malaysia",
    airport_code: "LGK",
    flag_code: "MY",
  },
  { city: "Lannion", country: "France", airport_code: "LAI", flag_code: "FR" },
  { city: "Lansing MI", country: "USA", airport_code: "LAN", flag_code: "US" },
  { city: "Lanzhou", country: "China", airport_code: "LHW", flag_code: "CN" },
  { city: "Laramie WY", country: "USA", airport_code: "LAR", flag_code: "US" },
  { city: "Laredo TX", country: "USA", airport_code: "LRD", flag_code: "US" },
  { city: "Larnaca", country: "Cyprus", airport_code: "LCA", flag_code: "NN" },
  {
    city: "Las Cruces NM",
    country: "USA",
    airport_code: "LRU",
    flag_code: "US",
  },
  {
    city: "Las Palmas Grand Canary Island",
    country: "Canary Islands",
    airport_code: "LPA",
    flag_code: "NN",
  },
  {
    city: "Las Vegas NV",
    country: "USA",
    airport_code: "LAS",
    flag_code: "US",
  },
  { city: "Latrobe PA", country: "USA", airport_code: "LBE", flag_code: "US" },
  {
    city: "Launceston Tas.",
    country: "Australia",
    airport_code: "LST",
    flag_code: "AU",
  },
  { city: "Laurel MS", country: "USA", airport_code: "PIB", flag_code: "US" },
  { city: "Lawton OK", country: "USA", airport_code: "LAW", flag_code: "US" },
  {
    city: "Lazaro Cardenas",
    country: "Mexico",
    airport_code: "LZC",
    flag_code: "MX",
  },
  { city: "Le Havre", country: "France", airport_code: "LEH", flag_code: "FR" },
  { city: "Lebanon NH", country: "USA", airport_code: "LEB", flag_code: "US" },
  { city: "Leeds", country: "England", airport_code: "LBA", flag_code: "GB" },
  {
    city: "Legaspi",
    country: "Philippines",
    airport_code: "LGP",
    flag_code: "PH",
  },
  { city: "Leipzig", country: "Germany", airport_code: "LEJ", flag_code: "DE" },
  { city: "Leon", country: "Mexico", airport_code: "BJX", flag_code: "MX" },
  { city: "Leon", country: "Spain", airport_code: "LEN", flag_code: "ES" },
  {
    city: "Lerwick Shetland Islands",
    country: "Scotland",
    airport_code: "LSI",
    flag_code: "_scotland",
  },
  {
    city: "Lethbridge Lewiston ID",
    country: "USA",
    airport_code: "LWS",
    flag_code: "US",
  },
  { city: "Lewiston ME", country: "USA", airport_code: "LEW", flag_code: "US" },
  {
    city: "Lewistown MT",
    country: "USA",
    airport_code: "LWT",
    flag_code: "US",
  },
  {
    city: "Lexington KY",
    country: "USA",
    airport_code: "LEX",
    flag_code: "US",
  },
  {
    city: "Lhasa Tibet",
    country: "China",
    airport_code: "LXA",
    flag_code: "CN",
  },
  { city: "Liberal KS", country: "USA", airport_code: "LBL", flag_code: "US" },
  {
    city: "Liberia",
    country: "Costa Rica",
    airport_code: "LIR",
    flag_code: "CR",
  },
  {
    city: "Libreville",
    country: "Gabon",
    airport_code: "LBV",
    flag_code: "NN",
  },
  {
    city: "Lihue Kauai HI",
    country: "USA",
    airport_code: "LIH",
    flag_code: "US",
  },
  {
    city: "Lijiang City",
    country: "China",
    airport_code: "LJG",
    flag_code: "CN",
  },
  { city: "Lille", country: "France", airport_code: "LIL", flag_code: "FR" },
  { city: "Lilongwe", country: "Malawi", airport_code: "LLW", flag_code: "NN" },
  { city: "Lima", country: "Peru", airport_code: "LIM", flag_code: "PE" },
  { city: "Limoges", country: "France", airport_code: "LIG", flag_code: "FR" },
  { city: "Lincang", country: "China", airport_code: "LNJ", flag_code: "CN" },
  { city: "Lincoln NE", country: "USA", airport_code: "LNK", flag_code: "US" },
  {
    city: "Linkoping",
    country: "Sweden",
    airport_code: "LPI",
    flag_code: "SE",
  },
  { city: "Linz", country: "Austria", airport_code: "LNZ", flag_code: "AT" },
  { city: "Lisbon", country: "Portugal", airport_code: "LIS", flag_code: "PT" },
  {
    city: "Little Rock AR",
    country: "USA",
    airport_code: "LIT",
    flag_code: "US",
  },
  {
    city: "Liverpool",
    country: "England",
    airport_code: "LPL",
    flag_code: "GB",
  },
  {
    city: "Ljubljana",
    country: "Slovenia",
    airport_code: "LJU",
    flag_code: "NN",
  },
  { city: "Logrono", country: "Spain", airport_code: "RJL", flag_code: "ES" },
  { city: "Lome", country: "Togo", airport_code: "LFW", flag_code: "NN" },
  { city: "London", country: "England", airport_code: "LCY", flag_code: "GB" },
  { city: "London", country: "England", airport_code: "LGW", flag_code: "GB" },
  { city: "London", country: "England", airport_code: "LHR", flag_code: "GB" },
  {
    city: "London - All Airports",
    country: "England",
    airport_code: "LON",
    flag_code: "GB",
  },
  { city: "London", country: "England", airport_code: "STN", flag_code: "GB" },
  {
    city: "Londonderry",
    country: "Northern Ireland",
    airport_code: "LDY",
    flag_code: "IE",
  },
  {
    city: "Long Banga",
    country: "Malaysia",
    airport_code: "LBP",
    flag_code: "MY",
  },
  {
    city: "Long Beach CA",
    country: "USA",
    airport_code: "LGB",
    flag_code: "US",
  },
  {
    city: "Longreach Qld.",
    country: "Australia",
    airport_code: "LRE",
    flag_code: "AU",
  },
  { city: "Longview TX", country: "USA", airport_code: "GGG", flag_code: "US" },
  {
    city: "Longyearbyen Svalbard",
    country: "Norway",
    airport_code: "LYR",
    flag_code: "NO",
  },
  { city: "Loreto", country: "Mexico", airport_code: "LTO", flag_code: "MX" },
  { city: "Lorient", country: "France", airport_code: "LRT", flag_code: "FR" },
  {
    city: "Los Angeles CA",
    country: "USA",
    airport_code: "LAX",
    flag_code: "US",
  },
  {
    city: "Los Angeles CA - ALL",
    country: "USA",
    airport_code: "QLA",
    flag_code: "US",
  },
  {
    city: "Los Cabos",
    country: "Mexico",
    airport_code: "SJD",
    flag_code: "MX",
  },
  {
    city: "Louisville KY USA",
    country: "USA",
    airport_code: "SDF",
    flag_code: "US",
  },
  { city: "Lourdes", country: "France", airport_code: "LDE", flag_code: "FR" },
  { city: "Luanda", country: "Angola", airport_code: "LAD", flag_code: "NN" },
  { city: "Lubbock TX", country: "USA", airport_code: "LBB", flag_code: "US" },
  {
    city: "Lubumbashi",
    country: "Zaire",
    airport_code: "FBM",
    flag_code: "NN",
  },
  {
    city: "Lugano",
    country: "Switzerland",
    airport_code: "LUG",
    flag_code: "CH",
  },
  { city: "Lugansk", country: "Ukraine", airport_code: "VSG", flag_code: "UA" },
  { city: "Lukla", country: "Nepal", airport_code: "LUA", flag_code: "NP" },
  { city: "Lulea", country: "Sweden", airport_code: "LLA", flag_code: "SE" },
  { city: "Lusaka", country: "Zambia", airport_code: "LUN", flag_code: "NN" },
  { city: "Luton", country: "England", airport_code: "LTN", flag_code: "GB" },
  {
    city: "Luxembourg",
    country: "Luxembourg",
    airport_code: "LUX",
    flag_code: "LU",
  },
  { city: "Luxor", country: "Egypt", airport_code: "LXR", flag_code: "EG" },
  { city: "Lycksele", country: "Sweden", airport_code: "LYC", flag_code: "SE" },
  {
    city: "Lynchburg VA",
    country: "USA",
    airport_code: "LYH",
    flag_code: "US",
  },
  { city: "Lyon", country: "France", airport_code: "LYS", flag_code: "FR" },
  {
    city: "Mackay Qld.",
    country: "Australia",
    airport_code: "MKY",
    flag_code: "AU",
  },
  { city: "Macon GA", country: "USA", airport_code: "MCN", flag_code: "US" },
  { city: "Madison WI", country: "USA", airport_code: "MSN", flag_code: "US" },
  { city: "Madrid", country: "Spain", airport_code: "MAD", flag_code: "ES" },
  {
    city: "Mae Hong Son",
    country: "Thailand",
    airport_code: "HGN",
    flag_code: "TH",
  },
  {
    city: "Magdalens Islands PQ Mahebourg",
    country: "Mauritius",
    airport_code: "MRU",
    flag_code: "NN",
  },
  {
    city: "Majuro",
    country: "Marshall Islands",
    airport_code: "MAJ",
    flag_code: "NN",
  },
  {
    city: "Mala Mala",
    country: "South Africa",
    airport_code: "AAM",
    flag_code: "SA",
  },
  {
    city: "Malabo Bioko Island",
    country: "Equatorial Guinea",
    airport_code: "SSG",
    flag_code: "NN",
  },
  { city: "Malaga", country: "Spain", airport_code: "AGP", flag_code: "ES" },
  { city: "Male", country: "Maldives", airport_code: "MLE", flag_code: "MV" },
  {
    city: "Malmo - All Airports",
    country: "Sweden",
    airport_code: "MMA",
    flag_code: "SE",
  },
  { city: "Malmo", country: "Sweden", airport_code: "MMX", flag_code: "SE" },
  {
    city: "Managua",
    country: "Nicaragua",
    airport_code: "MGA",
    flag_code: "NN",
  },
  { city: "Manama", country: "Bahrain", airport_code: "BAH", flag_code: "BH" },
  { city: "Manaus", country: "Brazil", airport_code: "MAO", flag_code: "BR" },
  {
    city: "Manchester",
    country: "England",
    airport_code: "MAN",
    flag_code: "GB",
  },
  {
    city: "Manchester NH",
    country: "USA",
    airport_code: "MHT",
    flag_code: "US",
  },
  {
    city: "Mangrove Cay Andros Island",
    country: "Bahamas",
    airport_code: "MAY",
    flag_code: "BS",
  },
  {
    city: "Manhattan KS",
    country: "USA",
    airport_code: "MHK",
    flag_code: "US",
  },
  {
    city: "Manila",
    country: "Philippines",
    airport_code: "MNL",
    flag_code: "PH",
  },
  { city: "Manistee MI", country: "USA", airport_code: "MBL", flag_code: "US" },
  {
    city: "Manzanillo",
    country: "Mexico",
    airport_code: "ZLO",
    flag_code: "MX",
  },
  {
    city: "Manzini",
    country: "Swaziland",
    airport_code: "MTS",
    flag_code: "NN",
  },
  {
    city: "Mao Minorca",
    country: "Spain",
    airport_code: "MAH",
    flag_code: "ES",
  },
  {
    city: "Maputo",
    country: "Mozambique",
    airport_code: "MPM",
    flag_code: "NN",
  },
  {
    city: "Maracaibo",
    country: "Venezuela",
    airport_code: "MAR",
    flag_code: "NN",
  },
  {
    city: "Mariehamn Aland",
    country: "Finland",
    airport_code: "MHQ",
    flag_code: "FI",
  },
  { city: "Marilia", country: "Brazil", airport_code: "MII", flag_code: "BR" },
  { city: "Marion IL", country: "USA", airport_code: "MWA", flag_code: "US" },
  {
    city: "Mariupol",
    country: "Ukraine",
    airport_code: "MPW",
    flag_code: "UA",
  },
  {
    city: "Maroochydore Qld.",
    country: "Australia",
    airport_code: "MCY",
    flag_code: "AU",
  },
  {
    city: "Marquette MI",
    country: "USA",
    airport_code: "MQT",
    flag_code: "US",
  },
  {
    city: "Marrakech",
    country: "Morocco",
    airport_code: "RAK",
    flag_code: "MA",
  },
  {
    city: "Marseille",
    country: "France",
    airport_code: "MRS",
    flag_code: "FR",
  },
  {
    city: "Marsh Harbour Abaco Island",
    country: "Bahamas",
    airport_code: "MHH",
    flag_code: "BS",
  },
  {
    city: "Martha's Vineyard MA",
    country: "USA",
    airport_code: "MVY",
    flag_code: "US",
  },
  { city: "Maseru", country: "Lesotho", airport_code: "MSU", flag_code: "NN" },
  {
    city: "Mason City IA",
    country: "USA",
    airport_code: "MCW",
    flag_code: "US",
  },
  { city: "Massena NY", country: "USA", airport_code: "MSS", flag_code: "US" },
  { city: "Matsu", country: "Taiwan", airport_code: "MFK", flag_code: "TW" },
  { city: "Matsuyama", country: "Japan", airport_code: "MYJ", flag_code: "JP" },
  { city: "Mazatlan", country: "Mexico", airport_code: "MZT", flag_code: "MX" },
  { city: "McAllen TX", country: "USA", airport_code: "MFE", flag_code: "US" },
  { city: "McCook NE", country: "USA", airport_code: "MCK", flag_code: "US" },
  {
    city: "Medan Sumatra",
    country: "Indonesia",
    airport_code: "MES",
    flag_code: "ID",
  },
  {
    city: "Medellin",
    country: "Colombia",
    airport_code: "MDE",
    flag_code: "CO",
  },
  { city: "Medford OR", country: "USA", airport_code: "MFR", flag_code: "US" },
  {
    city: "Medicine Hat Melbourne FL",
    country: "USA",
    airport_code: "MLB",
    flag_code: "US",
  },
  {
    city: "Melbourne Vic.",
    country: "Australia",
    airport_code: "AVV",
    flag_code: "AU",
  },
  {
    city: "Melbourne Vic.",
    country: "Australia",
    airport_code: "MEL",
    flag_code: "AU",
  },
  { city: "Melilla", country: "Spain", airport_code: "MLN", flag_code: "ES" },
  {
    city: "Memanbetsu",
    country: "Japan",
    airport_code: "MMB",
    flag_code: "JP",
  },
  { city: "Memphis TN", country: "USA", airport_code: "MEM", flag_code: "US" },
  {
    city: "Mendoza",
    country: "Argentina",
    airport_code: "MDZ",
    flag_code: "AR",
  },
  { city: "Merida", country: "Mexico", airport_code: "MID", flag_code: "MX" },
  { city: "Meridian MS", country: "USA", airport_code: "MEI", flag_code: "US" },
  {
    city: "Merimbula N.S.W.",
    country: "Australia",
    airport_code: "MIM",
    flag_code: "AU",
  },
  { city: "Metz", country: "France", airport_code: "ETZ", flag_code: "FR" },
  { city: "Mexicali", country: "Mexico", airport_code: "MXL", flag_code: "MX" },
  {
    city: "Mexico City",
    country: "Mexico",
    airport_code: "MEX",
    flag_code: "MX",
  },
  { city: "Miami FL", country: "USA", airport_code: "MIA", flag_code: "US" },
  { city: "Mian Yang", country: "China", airport_code: "MIG", flag_code: "CN" },
  { city: "Midland TX", country: "USA", airport_code: "MAF", flag_code: "US" },
  { city: "Mikonos", country: "Greece", airport_code: "JMK", flag_code: "GR" },
  { city: "Milan", country: "Italy", airport_code: "LIN", flag_code: "IT" },
  {
    city: "Milan - ALL",
    country: "Italy",
    airport_code: "MIL",
    flag_code: "IT",
  },
  { city: "Milan", country: "Italy", airport_code: "MXP", flag_code: "IT" },
  {
    city: "Mildura Vic.",
    country: "Australia",
    airport_code: "MQL",
    flag_code: "AU",
  },
  {
    city: "Miles City MT",
    country: "USA",
    airport_code: "MLS",
    flag_code: "US",
  },
  {
    city: "Milwaukee WI",
    country: "USA",
    airport_code: "MKE",
    flag_code: "US",
  },
  {
    city: "Minneapolis MN",
    country: "USA",
    airport_code: "MSP",
    flag_code: "US",
  },
  { city: "Minot ND", country: "USA", airport_code: "MOT", flag_code: "US" },
  { city: "Minsk", country: "Belarus", airport_code: "MHP", flag_code: "NN" },
  { city: "Minsk", country: "Belarus", airport_code: "MSQ", flag_code: "NN" },
  {
    city: "Miri Sarawak",
    country: "Malaysia",
    airport_code: "MYY",
    flag_code: "MY",
  },
  { city: "Misawa", country: "Japan", airport_code: "MSJ", flag_code: "JP" },
  { city: "Missoula MT", country: "USA", airport_code: "MSO", flag_code: "US" },
  { city: "Miyazaki", country: "Japan", airport_code: "KMI", flag_code: "JP" },
  { city: "Mobile AL", country: "USA", airport_code: "MOB", flag_code: "US" },
  { city: "Modesto CA", country: "USA", airport_code: "MOD", flag_code: "US" },
  {
    city: "Mogadishu",
    country: "Somalia",
    airport_code: "MGQ",
    flag_code: "NN",
  },
  { city: "Moline IL", country: "USA", airport_code: "MLI", flag_code: "US" },
  { city: "Mombasa", country: "Kenya", airport_code: "MBA", flag_code: "KE" },
  {
    city: "Monastir",
    country: "Tunisia",
    airport_code: "MIR",
    flag_code: "NN",
  },
  { city: "Monclova", country: "Mexico", airport_code: "LOV", flag_code: "MX" },
  {
    city: "Moncton Monroe LA",
    country: "USA",
    airport_code: "MLU",
    flag_code: "US",
  },
  {
    city: "Monrovia",
    country: "Liberia",
    airport_code: "ROB",
    flag_code: "LR",
  },
  {
    city: "Mont-Joli Montego Bay",
    country: "Jamaica",
    airport_code: "MBJ",
    flag_code: "JM",
  },
  { city: "Monterey CA", country: "USA", airport_code: "MRY", flag_code: "US" },
  {
    city: "Monterrey",
    country: "Mexico",
    airport_code: "MTY",
    flag_code: "MX",
  },
  {
    city: "Montevideo",
    country: "Uruguay",
    airport_code: "MVD",
    flag_code: "NN",
  },
  {
    city: "Montgomery AL",
    country: "USA",
    airport_code: "MGM",
    flag_code: "US",
  },
  {
    city: "Montpellier",
    country: "France",
    airport_code: "MPL",
    flag_code: "FR",
  },
  {
    city: "Montreal Montrose CO",
    country: "USA",
    airport_code: "MTJ",
    flag_code: "US",
  },
  { city: "Mora", country: "Sweden", airport_code: "MXX", flag_code: "SE" },
  {
    city: "Moree N.S.W.",
    country: "Australia",
    airport_code: "MRZ",
    flag_code: "AU",
  },
  { city: "Morelia", country: "Mexico", airport_code: "MLM", flag_code: "MX" },
  {
    city: "Morgantown WV",
    country: "USA",
    airport_code: "MGW",
    flag_code: "US",
  },
  {
    city: "Moroni",
    country: "Comoro Islands",
    airport_code: "HAH",
    flag_code: "NN",
  },
  {
    city: "Moruya N.S.W.",
    country: "Australia",
    airport_code: "MYA",
    flag_code: "AU",
  },
  {
    city: "Moscow",
    country: "Russia",
    airport_code: "MOW All",
    flag_code: "RU",
  },
  { city: "Moscow", country: "Russia", airport_code: "SVO", flag_code: "RU" },
  { city: "Moscow", country: "Russia", airport_code: "VKO", flag_code: "RU" },
  {
    city: "Moses Lake WA",
    country: "USA",
    airport_code: "MWH",
    flag_code: "US",
  },
  {
    city: "Mount Gambier S.A.",
    country: "Australia",
    airport_code: "MGB",
    flag_code: "AU",
  },
  {
    city: "Mount Isa Qld.",
    country: "Australia",
    airport_code: "ISA",
    flag_code: "AU",
  },
  {
    city: "Mudanjiang",
    country: "China",
    airport_code: "MDG",
    flag_code: "CN",
  },
  {
    city: "Mulhouse - ALL",
    country: "France",
    airport_code: "EAP",
    flag_code: "FR",
  },
  { city: "Mulhouse", country: "France", airport_code: "MLH", flag_code: "FR" },
  { city: "Mulu", country: "Malaysia", airport_code: "MZV", flag_code: "MY" },
  {
    city: "Mumbai (Bombay)",
    country: "India",
    airport_code: "BOM",
    flag_code: "IN",
  },
  { city: "Munich", country: "Germany", airport_code: "MUC", flag_code: "DE" },
  { city: "Munster", country: "Germany", airport_code: "FMO", flag_code: "DE" },
  { city: "Murcia", country: "Spain", airport_code: "MJV", flag_code: "ES" },
  { city: "Murmansk", country: "Russia", airport_code: "MMK", flag_code: "RU" },
  { city: "Muscat", country: "Oman", airport_code: "MCT", flag_code: "OM" },
  {
    city: "Muscle Shoals AL",
    country: "USA",
    airport_code: "MSL",
    flag_code: "US",
  },
  { city: "Muskegon MI", country: "USA", airport_code: "MKG", flag_code: "US" },
  {
    city: "Myrtle Beach SC",
    country: "USA",
    airport_code: "MYR",
    flag_code: "US",
  },
  {
    city: "Mytilene",
    country: "Lesbos Island",
    airport_code: "Greece",
    flag_code: "MJT",
  },
  { city: "N'Djamena", country: "Chad", airport_code: "NDJ", flag_code: "NN" },
  { city: "Nadi", country: "Fiji", airport_code: "NAN", flag_code: "FJ" },
  { city: "Nador", country: "Morocco", airport_code: "NDR", flag_code: "MA" },
  { city: "Nagasaki", country: "Japan", airport_code: "NGS", flag_code: "JP" },
  { city: "Nagoya", country: "Japan", airport_code: "NGO", flag_code: "JP" },
  { city: "Nagoya", country: "Japan", airport_code: "NKM", flag_code: "JP" },
  {
    city: "Naha Okinawa",
    country: "Japan",
    airport_code: "OKA",
    flag_code: "JP",
  },
  { city: "Nairobi", country: "Kenya", airport_code: "NBO", flag_code: "KE" },
  {
    city: "Nakhon Si Thammarat",
    country: "Thailand",
    airport_code: "NST",
    flag_code: "TH",
  },
  {
    city: "Nanaimo Nanchang",
    country: "China",
    airport_code: "KHN",
    flag_code: "CN",
  },
  { city: "Nangen", country: "Taiwan", airport_code: "LZN", flag_code: "TW" },
  { city: "Nanjing", country: "China", airport_code: "NKG", flag_code: "CN" },
  {
    city: "Nanki Shirahama",
    country: "Japan",
    airport_code: "SHM",
    flag_code: "JP",
  },
  { city: "Nanning", country: "China", airport_code: "NNG", flag_code: "CN" },
  { city: "Nantes", country: "France", airport_code: "NTE", flag_code: "FR" },
  {
    city: "Nantucket MA",
    country: "USA",
    airport_code: "ACK",
    flag_code: "US",
  },
  {
    city: "Napier",
    country: "New Zealand",
    airport_code: "NPE",
    flag_code: "NZ",
  },
  { city: "Naples", country: "Italy", airport_code: "NAP", flag_code: "IT" },
  { city: "Naples FL", country: "USA", airport_code: "APF", flag_code: "US" },
  {
    city: "Narrabri N.S.W.",
    country: "Australia",
    airport_code: "NAA",
    flag_code: "AU",
  },
  {
    city: "Narrandera N.S.W.",
    country: "Australia",
    airport_code: "NRA",
    flag_code: "AU",
  },
  { city: "Narvik", country: "Norway", airport_code: "NVK", flag_code: "NO" },
  {
    city: "Naryan Mar",
    country: "Russia",
    airport_code: "NNM",
    flag_code: "RU",
  },
  {
    city: "Nashville TN",
    country: "USA",
    airport_code: "BNA",
    flag_code: "US",
  },
  { city: "Nassau", country: "Bahamas", airport_code: "NAS", flag_code: "BS" },
  { city: "Nassau", country: "Bahamas", airport_code: "PID", flag_code: "BS" },
  {
    city: "Naze Amami-O Island",
    country: "Japan",
    airport_code: "ASJ",
    flag_code: "JP",
  },
  {
    city: "Nelson",
    country: "New Zealand",
    airport_code: "NSN",
    flag_code: "NZ",
  },
  {
    city: "Nelspruit",
    country: "South Africa",
    airport_code: "MQP",
    flag_code: "SA",
  },
  { city: "New Bern NC", country: "USA", airport_code: "EWN", flag_code: "US" },
  {
    city: "New Haven CT",
    country: "USA",
    airport_code: "HVN",
    flag_code: "US",
  },
  {
    city: "New Orleans LA",
    country: "USA",
    airport_code: "MSY",
    flag_code: "US",
  },
  { city: "New York NY", country: "USA", airport_code: "JFK", flag_code: "US" },
  { city: "New York NY", country: "USA", airport_code: "LGA", flag_code: "US" },
  {
    city: "New York NY-All",
    country: "USA",
    airport_code: "NYC",
    flag_code: "US",
  },
  { city: "Newark NJ", country: "USA", airport_code: "EWR", flag_code: "US" },
  { city: "Newburgh NY", country: "USA", airport_code: "SWF", flag_code: "US" },
  {
    city: "Newcastle",
    country: "England",
    airport_code: "NCL",
    flag_code: "GB",
  },
  {
    city: "Newcastle N.S.W.",
    country: "Australia",
    airport_code: "NTL",
    flag_code: "AU",
  },
  {
    city: "Newman W.A.",
    country: "Australia",
    airport_code: "ZNE",
    flag_code: "AU",
  },
  {
    city: "Newport News VA",
    country: "USA",
    airport_code: "PHF",
    flag_code: "US",
  },
  { city: "Niamey", country: "Niger", airport_code: "NIM", flag_code: "NN" },
  { city: "Nice", country: "France", airport_code: "NCE", flag_code: "FR" },
  { city: "Niigata", country: "Japan", airport_code: "KIJ", flag_code: "JP" },
  { city: "Ningbo", country: "China", airport_code: "NGB", flag_code: "CN" },
  {
    city: "Nizhniy Novgorod",
    country: "Russia",
    airport_code: "GOJ",
    flag_code: "RU",
  },
  { city: "Nome AK", country: "USA", airport_code: "OME", flag_code: "US" },
  { city: "Norfolk VA", country: "USA", airport_code: "ORF", flag_code: "US" },
  {
    city: "Norrkoping",
    country: "Sweden",
    airport_code: "NRK",
    flag_code: "SE",
  },
  {
    city: "North Bay North Bend OR",
    country: "USA",
    airport_code: "OTH",
    flag_code: "US",
  },
  {
    city: "North Eleuthera",
    country: "Bahamas",
    airport_code: "ELH",
    flag_code: "BS",
  },
  {
    city: "North Platte NE",
    country: "USA",
    airport_code: "LBF",
    flag_code: "US",
  },
  { city: "Norwich", country: "England", airport_code: "NWI", flag_code: "GB" },
  {
    city: "Nouakchott",
    country: "Mauritania",
    airport_code: "NKC",
    flag_code: "NN",
  },
  {
    city: "Noumea",
    country: "New Caledonia",
    airport_code: "GEA",
    flag_code: "NN",
  },
  {
    city: "Noumea",
    country: "New Caledonia",
    airport_code: "NOU",
    flag_code: "NN",
  },
  {
    city: "Novosibirsk Siberia",
    country: "Russia",
    airport_code: "OVB",
    flag_code: "RU",
  },
  {
    city: "Nuevo Laredo",
    country: "Mexico",
    airport_code: "NLD",
    flag_code: "MX",
  },
  {
    city: "Nuremberg",
    country: "Germany",
    airport_code: "NUE",
    flag_code: "DE",
  },
  { city: "Oakland CA", country: "USA", airport_code: "OAK", flag_code: "US" },
  { city: "Oaxaca", country: "Mexico", airport_code: "OAX", flag_code: "MX" },
  { city: "Obihiro", country: "Japan", airport_code: "OBO", flag_code: "JP" },
  {
    city: "Ocho Rios",
    country: "Jamaica",
    airport_code: "OCJ",
    flag_code: "JM",
  },
  {
    city: "Odate Noshiro",
    country: "Japan",
    airport_code: "ONJ",
    flag_code: "JP",
  },
  { city: "Odessa", country: "Ukraine", airport_code: "ODS", flag_code: "UA" },
  {
    city: "Ogdensburg NY",
    country: "USA",
    airport_code: "OGS",
    flag_code: "US",
  },
  { city: "Oita", country: "Japan", airport_code: "OIT", flag_code: "JP" },
  {
    city: "Oklahoma City OK",
    country: "USA",
    airport_code: "OKC",
    flag_code: "US",
  },
  {
    city: "Olbia Sardinia",
    country: "Italy",
    airport_code: "OLB",
    flag_code: "IT",
  },
  {
    city: "Olympic Dam S.A.",
    country: "Australia",
    airport_code: "OLP",
    flag_code: "AU",
  },
  { city: "Omaha NE", country: "USA", airport_code: "OMA", flag_code: "US" },
  {
    city: "Omsk Siberia",
    country: "Russia",
    airport_code: "OMS",
    flag_code: "RU",
  },
  { city: "Ontario CA", country: "USA", airport_code: "ONT", flag_code: "US" },
  {
    city: "Orange County CA",
    country: "USA",
    airport_code: "SNA",
    flag_code: "US",
  },
  {
    city: "Orange N.S.W.",
    country: "Australia",
    airport_code: "OAG",
    flag_code: "AU",
  },
  {
    city: "Oranjestad",
    country: "Aruba",
    airport_code: "AUA",
    flag_code: "NN",
  },
  {
    city: "Oranjestad",
    country: "St. Eustatius",
    airport_code: "EUX",
    flag_code: "NN",
  },
  { city: "Orebro", country: "Sweden", airport_code: "ORB", flag_code: "SE" },
  { city: "Orlando FL", country: "USA", airport_code: "MCO", flag_code: "US" },
  {
    city: "Ornskoldsvik",
    country: "Sweden",
    airport_code: "OER",
    flag_code: "SE",
  },
  { city: "Osaka", country: "Japan", airport_code: "ITM", flag_code: "JP" },
  { city: "Osaka", country: "Japan", airport_code: "KIX", flag_code: "JP" },
  {
    city: "Osaka - ALL Airports",
    country: "Japan",
    airport_code: "OSA",
    flag_code: "JP",
  },
  { city: "Oshkosh WI", country: "USA", airport_code: "OSH", flag_code: "US" },
  { city: "Oslo", country: "Norway", airport_code: "OSL", flag_code: "NO" },
  { city: "Osorno", country: "Chile", airport_code: "ZOS", flag_code: "CL" },
  {
    city: "Ostersund",
    country: "Sweden",
    airport_code: "OSD",
    flag_code: "SE",
  },
  {
    city: "Ostrava",
    country: "Czech Republic",
    airport_code: "OSR",
    flag_code: "CZ",
  },
  {
    city: "Ottawa Ouagadougou",
    country: "Burkina Faso",
    airport_code: "OUA",
    flag_code: "NN",
  },
  { city: "Oulu", country: "Finland", airport_code: "OUL", flag_code: "FI" },
  {
    city: "Owensboro KY",
    country: "USA",
    airport_code: "OWB",
    flag_code: "US",
  },
  { city: "Oxnard CA", country: "USA", airport_code: "OXR", flag_code: "US" },
  {
    city: "Paderborn",
    country: "Germany",
    airport_code: "PAD",
    flag_code: "DE",
  },
  { city: "Paducah KY", country: "USA", airport_code: "PAH", flag_code: "US" },
  { city: "Page AZ", country: "USA", airport_code: "PGA", flag_code: "US" },
  {
    city: "Pago Pago Tutuila",
    country: "American Samoa",
    airport_code: "PPG",
    flag_code: "NN",
  },
  { city: "Pajala", country: "Sweden", airport_code: "PJA", flag_code: "SE" },
  {
    city: "Palembang Sumatra",
    country: "Indonesia",
    airport_code: "PLM",
    flag_code: "ID",
  },
  {
    city: "Palermo Sicily",
    country: "Italy",
    airport_code: "PMO",
    flag_code: "IT",
  },
  {
    city: "Palm Springs CA",
    country: "USA",
    airport_code: "PSP",
    flag_code: "US",
  },
  {
    city: "Palma Mallorca",
    country: "Spain",
    airport_code: "PMI",
    flag_code: "ES",
  },
  { city: "Palmas", country: "Brazil", airport_code: "PMW", flag_code: "BR" },
  {
    city: "Palmerston North",
    country: "New Zealand",
    airport_code: "PMR",
    flag_code: "NZ",
  },
  { city: "Pamplona", country: "Spain", airport_code: "PNA", flag_code: "ES" },
  {
    city: "Panama City",
    country: "Panama",
    airport_code: "PTY",
    flag_code: "NN",
  },
  {
    city: "Panama City FL",
    country: "USA",
    airport_code: "PFN",
    flag_code: "US",
  },
  {
    city: "Papeete Tahiti",
    country: "French Polynesia",
    airport_code: "PPT",
    flag_code: "PF",
  },
  { city: "Paphos", country: "Cyprus", airport_code: "PFO", flag_code: "NN" },
  {
    city: "Paraburdoo W.A.",
    country: "Australia",
    airport_code: "PBO",
    flag_code: "AU",
  },
  {
    city: "Paramaribo",
    country: "Suriname",
    airport_code: "PBM",
    flag_code: "NN",
  },
  { city: "Paris", country: "France", airport_code: "CDG", flag_code: "FR" },
  { city: "Paris", country: "France", airport_code: "ORY", flag_code: "FR" },
  {
    city: "Paris -All",
    country: "France",
    airport_code: "PAR",
    flag_code: "FR",
  },
  {
    city: "Parkersburg WV",
    country: "USA",
    airport_code: "PKB",
    flag_code: "US",
  },
  {
    city: "Parkes N.S.W.",
    country: "Australia",
    airport_code: "PKE",
    flag_code: "AU",
  },
  { city: "Pasco WA", country: "USA", airport_code: "PSC", flag_code: "US" },
  { city: "Pau", country: "France", airport_code: "PUF", flag_code: "FR" },
  { city: "Pellston MI", country: "USA", airport_code: "PLN", flag_code: "US" },
  {
    city: "Penang Island",
    country: "Malaysia",
    airport_code: "PEN",
    flag_code: "MY",
  },
  {
    city: "Pendleton OR",
    country: "USA",
    airport_code: "PDT",
    flag_code: "US",
  },
  {
    city: "Pensacola FL",
    country: "USA",
    airport_code: "PNS",
    flag_code: "US",
  },
  {
    city: "Penticton Peoria IL",
    country: "USA",
    airport_code: "PIA",
    flag_code: "US",
  },
  {
    city: "Perpignan",
    country: "France",
    airport_code: "PGF",
    flag_code: "FR",
  },
  {
    city: "Perth W.A.",
    country: "Australia",
    airport_code: "PER",
    flag_code: "AU",
  },
  { city: "Perugia", country: "Italy", airport_code: "PEG", flag_code: "IT" },
  {
    city: "Peshawar",
    country: "Pakistan",
    airport_code: "PEW",
    flag_code: "PK",
  },
  {
    city: "Petropavlovsk-Kamchatsky Siberia",
    country: "Russia",
    airport_code: "PKC",
    flag_code: "RU",
  },
  {
    city: "Philadelphia PA",
    country: "USA",
    airport_code: "PHL",
    flag_code: "US",
  },
  {
    city: "Philipsburg",
    country: "St. Maarten",
    airport_code: "SXM",
    flag_code: "NN",
  },
  {
    city: "Phitsanulok",
    country: "Thailand",
    airport_code: "PHS",
    flag_code: "TH",
  },
  {
    city: "Phnom Penh",
    country: "Cambodia",
    airport_code: "PNH",
    flag_code: "NN",
  },
  { city: "Phoenix AZ", country: "USA", airport_code: "PHX", flag_code: "US" },
  { city: "Phuket", country: "Thailand", airport_code: "HKT", flag_code: "TH" },
  {
    city: "Piedras Negras",
    country: "Mexico",
    airport_code: "PDS",
    flag_code: "MX",
  },
  { city: "Pierre SD", country: "USA", airport_code: "PIR", flag_code: "US" },
  {
    city: "Pinehurst NC",
    country: "USA",
    airport_code: "SOP",
    flag_code: "US",
  },
  { city: "Pisa", country: "Italy", airport_code: "PSA", flag_code: "IT" },
  {
    city: "Pittsburgh PA",
    country: "USA",
    airport_code: "PIT",
    flag_code: "US",
  },
  {
    city: "Placencia",
    country: "Belize",
    airport_code: "PLJ",
    flag_code: "NN",
  },
  {
    city: "Plattsburgh NY",
    country: "USA",
    airport_code: "PLB",
    flag_code: "US",
  },
  { city: "Pleiku", country: "Vietnam", airport_code: "PXU", flag_code: "VN" },
  {
    city: "Pocatello ID",
    country: "USA",
    airport_code: "PIH",
    flag_code: "US",
  },
  {
    city: "Pohnpei",
    country: "Micronesia",
    airport_code: "PNI",
    flag_code: "NN",
  },
  {
    city: "Pointe-A-Pitre Grande-Terre",
    country: "Guadeloupe",
    airport_code: "PTP",
    flag_code: "NN",
  },
  {
    city: "Ponca City OK",
    country: "USA",
    airport_code: "PNC",
    flag_code: "US",
  },
  {
    city: "Ponta Delgada Sao Miguel Island",
    country: "Azores",
    airport_code: "PDL",
    flag_code: "NN",
  },
  {
    city: "Port Hardy Port Hedland W.A.",
    country: "Australia",
    airport_code: "PHE",
    flag_code: "AU",
  },
  {
    city: "Port Lincoln S.A.",
    country: "Australia",
    airport_code: "PLO",
    flag_code: "AU",
  },
  {
    city: "Port Macquarie N.S.W.",
    country: "Australia",
    airport_code: "PQQ",
    flag_code: "AU",
  },
  {
    city: "Port Moresby",
    country: "Papua-New Guinea",
    airport_code: "POM",
    flag_code: "NN",
  },
  {
    city: "Port of Spain Trinidad",
    country: "ES and Tobago",
    airport_code: "POS",
    flag_code: "NN",
  },
  {
    city: "Port Vila Efate Island",
    country: "Vanuatu",
    airport_code: "VLI",
    flag_code: "NN",
  },
  {
    city: "Port-au-Prince",
    country: "Haiti",
    airport_code: "PAP",
    flag_code: "NN",
  },
  { city: "Portland ME", country: "USA", airport_code: "PWM", flag_code: "US" },
  { city: "Portland OR", country: "USA", airport_code: "PDX", flag_code: "US" },
  {
    city: "Portland Vic.",
    country: "Australia",
    airport_code: "PTJ",
    flag_code: "AU",
  },
  { city: "Porto", country: "Portugal", airport_code: "OPO", flag_code: "PT" },
  {
    city: "Porto Alegre",
    country: "Brazil",
    airport_code: "POA",
    flag_code: "BR",
  },
  {
    city: "Porto Santo",
    country: "Madeira",
    airport_code: "PXO",
    flag_code: "NN",
  },
  {
    city: "Portsmouth NH",
    country: "USA",
    airport_code: "PSM",
    flag_code: "US",
  },
  {
    city: "Powell River Poznan",
    country: "Poland",
    airport_code: "POZ",
    flag_code: "PL",
  },
  {
    city: "Prague",
    country: "Czech Republic",
    airport_code: "PRG",
    flag_code: "CR",
  },
  { city: "Prescott AZ", country: "USA", airport_code: "PRC", flag_code: "US" },
  {
    city: "Presque Isle ME",
    country: "USA",
    airport_code: "PQI",
    flag_code: "US",
  },
  {
    city: "Prince George BC",
    country: "Canada",
    airport_code: "YXS",
    flag_code: "CA",
  },
  {
    city: "Prince Rupert Proserpine Qld.",
    country: "Australia",
    airport_code: "PPP",
    flag_code: "AU",
  },
  {
    city: "Providence RI",
    country: "USA",
    airport_code: "PVD",
    flag_code: "US",
  },
  {
    city: "Providenciales Island",
    country: "Turks and Caicos Islands",
    airport_code: "PLS",
    flag_code: "NN",
  },
  {
    city: "Provincetown MA",
    country: "USA",
    airport_code: "PVC",
    flag_code: "US",
  },
  { city: "Pucon", country: "Chile", airport_code: "ZPC", flag_code: "CL" },
  { city: "Puebla", country: "Mexico", airport_code: "PBC", flag_code: "MX" },
  { city: "Pueblo CO", country: "USA", airport_code: "PUB", flag_code: "US" },
  {
    city: "Puerto del Rosario Fuerteventura Island",
    country: "Canary Islands",
    airport_code: "FUE",
    flag_code: "NN",
  },
  {
    city: "Puerto Escondido",
    country: "Mexico",
    airport_code: "PXM",
    flag_code: "MX",
  },
  {
    city: "Puerto Montt",
    country: "Chile",
    airport_code: "PMC",
    flag_code: "CL",
  },
  {
    city: "Puerto Plata",
    country: "Dominican Republic",
    airport_code: "POP",
    flag_code: "NN",
  },
  {
    city: "Puerto Princesa",
    country: "Philippines",
    airport_code: "PPS",
    flag_code: "PH",
  },
  {
    city: "Puerto Vallarta",
    country: "Mexico",
    airport_code: "PVR",
    flag_code: "MX",
  },
  { city: "Pullman WA", country: "USA", airport_code: "PUW", flag_code: "US" },
  {
    city: "Punta Arenas Tierra del Fuego",
    country: "Chile",
    airport_code: "PUQ",
    flag_code: "CL",
  },
  {
    city: "Punta Cana",
    country: "Dominican Republic",
    airport_code: "PUJ",
    flag_code: "NN",
  },
  {
    city: "Punta Gorda",
    country: "Belize",
    airport_code: "PND",
    flag_code: "NN",
  },
  {
    city: "Puttaparthi",
    country: "India",
    airport_code: "PUT",
    flag_code: "IN",
  },
  {
    city: "Pyongyang",
    country: "North Korea",
    airport_code: "FNJ",
    flag_code: "KP",
  },
  { city: "Qingdao", country: "China", airport_code: "TAO", flag_code: "CN" },
  {
    city: "Quebec Queenstown",
    country: "New Zealand",
    airport_code: "ZQN",
    flag_code: "NZ",
  },
  {
    city: "Queretaro",
    country: "Mexico",
    airport_code: "QRO",
    flag_code: "MX",
  },
  {
    city: "Quesnel Quimper",
    country: "France",
    airport_code: "UIP",
    flag_code: "FR",
  },
  { city: "Quincy IL", country: "USA", airport_code: "UIN", flag_code: "US" },
  { city: "Quito", country: "Ecuador", airport_code: "UIO", flag_code: "NN" },
  {
    city: "Rach Gia",
    country: "Vietnam",
    airport_code: "VKG",
    flag_code: "VN",
  },
  { city: "Raleigh NC", country: "USA", airport_code: "RDU", flag_code: "US" },
  {
    city: "Rangoon",
    country: "Myanmar (Burma)",
    airport_code: "RGN",
    flag_code: "MY",
  },
  {
    city: "Rankin Inlet Ranong",
    country: "Thailand",
    airport_code: "UNN",
    flag_code: "TH",
  },
  {
    city: "Rapid City SD",
    country: "USA",
    airport_code: "RAP",
    flag_code: "US",
  },
  { city: "Recife", country: "Brazil", airport_code: "REC", flag_code: "BR" },
  {
    city: "Red Lake Redding CA",
    country: "USA",
    airport_code: "RDD",
    flag_code: "US",
  },
  { city: "Redmond OR", country: "USA", airport_code: "RDM", flag_code: "US" },
  {
    city: "Reggio di Calabria",
    country: "Italy",
    airport_code: "REG",
    flag_code: "IT",
  },
  {
    city: "Regina Rennes",
    country: "France",
    airport_code: "RNS",
    flag_code: "FR",
  },
  { city: "Reno NV", country: "USA", airport_code: "RNO", flag_code: "US" },
  {
    city: "Reykjavik",
    country: "Iceland",
    airport_code: "KEF",
    flag_code: "IS",
  },
  {
    city: "Reykjavik",
    country: "Iceland",
    airport_code: "REK - All Airports",
    flag_code: "IS",
  },
  {
    city: "Reykjavik",
    country: "Iceland",
    airport_code: "RKV",
    flag_code: "IS",
  },
  { city: "Reynosa", country: "Mexico", airport_code: "REX", flag_code: "MX" },
  {
    city: "Rhinelander WI",
    country: "USA",
    airport_code: "RHI",
    flag_code: "US",
  },
  { city: "Rhodes", country: "Greece", airport_code: "RHO", flag_code: "GR" },
  { city: "Richmond VA", country: "USA", airport_code: "RIC", flag_code: "US" },
  { city: "Riga", country: "Latvia", airport_code: "RIX", flag_code: "NN" },
  { city: "Rimini", country: "Italy", airport_code: "RMI", flag_code: "IT" },
  {
    city: "Rio de Janeiro",
    country: "Brazil",
    airport_code: "GIG",
    flag_code: "BR",
  },
  {
    city: "Rio de Janeiro - All Airports",
    country: "Brazil",
    airport_code: "RIO",
    flag_code: "BR",
  },
  {
    city: "Rio de Janeiro",
    country: "Brazil",
    airport_code: "SDU",
    flag_code: "BR",
  },
  { city: "Riverton WY", country: "USA", airport_code: "RIW", flag_code: "US" },
  {
    city: "Riyadh",
    country: "Saudi Arabia",
    airport_code: "RUH",
    flag_code: "SA",
  },
  { city: "Roanoke VA", country: "USA", airport_code: "ROA", flag_code: "US" },
  {
    city: "Roberval Rochester MN",
    country: "USA",
    airport_code: "RST",
    flag_code: "US",
  },
  {
    city: "Rochester NY",
    country: "USA",
    airport_code: "ROC",
    flag_code: "US",
  },
  {
    city: "Rock Sound",
    country: "Bahamas",
    airport_code: "RSD",
    flag_code: "BS",
  },
  {
    city: "Rock Springs WY",
    country: "USA",
    airport_code: "RKS",
    flag_code: "US",
  },
  { city: "Rockford IL", country: "USA", airport_code: "RFD", flag_code: "US" },
  {
    city: "Rockhampton Qld.",
    country: "Australia",
    airport_code: "ROK",
    flag_code: "AU",
  },
  { city: "Rockland ME", country: "USA", airport_code: "RKD", flag_code: "US" },
  { city: "Rodez", country: "France", airport_code: "RDZ", flag_code: "FR" },
  {
    city: "Roma Qld.",
    country: "Australia",
    airport_code: "RMA",
    flag_code: "AU",
  },
  { city: "Rome", country: "Italy", airport_code: "CIA", flag_code: "IT" },
  {
    city: "Rome",
    country: "Italy (FCO-Leonardo Da Vinci",
    airport_code: "FCO",
    flag_code: "IT",
  },
  { city: "Rome", country: "Italy", airport_code: "ROM", flag_code: "IT" },
  { city: "Ronne", country: "Denmark", airport_code: "RNN", flag_code: "DK" },
  { city: "Ronneby", country: "Sweden", airport_code: "RNB", flag_code: "SE" },
  { city: "Roseau", country: "Dominica", airport_code: "DOM", flag_code: "NN" },
  { city: "Rostov", country: "Russia", airport_code: "ROV", flag_code: "RU" },
  { city: "Roswell NM", country: "USA", airport_code: "ROW", flag_code: "US" },
  {
    city: "Rotorua",
    country: "New Zealand",
    airport_code: "ROT",
    flag_code: "NZ",
  },
  {
    city: "Rotterdam",
    country: "Netherlands",
    airport_code: "RTM",
    flag_code: "NL",
  },
  { city: "Rouen", country: "France", airport_code: "URO", flag_code: "FR" },
  {
    city: "Rouyn-NorandaRovaniemi",
    country: "Finland",
    airport_code: "RVN",
    flag_code: "FI",
  },
  {
    city: "Roxas City",
    country: "Philippines",
    airport_code: "RXS",
    flag_code: "PH",
  },
  { city: "Rutland VT", country: "USA", airport_code: "RUT", flag_code: "US" },
  { city: "Rzeszow", country: "Poland", airport_code: "RZE", flag_code: "PL" },
  {
    city: "Saba Island",
    country: "Saba",
    airport_code: "SAB",
    flag_code: "NN",
  },
  {
    city: "Sacramento CA",
    country: "USA",
    airport_code: "SMF",
    flag_code: "US",
  },
  { city: "Saga", country: "Japan", airport_code: "HSG", flag_code: "JP" },
  { city: "Saginaw MI", country: "USA", airport_code: "MBS", flag_code: "US" },
  {
    city: "Saint John Saint-Denis",
    country: "Reunion",
    airport_code: "RUN",
    flag_code: "NN",
  },
  {
    city: "Saint-Pierre",
    country: "Reunion",
    airport_code: "ZSE",
    flag_code: "NN",
  },
  {
    city: "Saipan",
    country: "Northern Mariana Islands",
    airport_code: "SPN",
    flag_code: "NN",
  },
  { city: "Salina KS", country: "USA", airport_code: "SLN", flag_code: "US" },
  {
    city: "Salisbury MD",
    country: "USA",
    airport_code: "SBY",
    flag_code: "US",
  },
  {
    city: "Salt Lake City UT",
    country: "USA",
    airport_code: "SLC",
    flag_code: "US",
  },
  { city: "Saltillo", country: "Mexico", airport_code: "SLW", flag_code: "MX" },
  { city: "Salvador", country: "Brazil", airport_code: "SSA", flag_code: "BR" },
  {
    city: "Salzburg",
    country: "Austria",
    airport_code: "SZG",
    flag_code: "AT",
  },
  { city: "Samsun", country: "Turkey", airport_code: "SZF", flag_code: "TR" },
  {
    city: "San Andres",
    country: "Colombia",
    airport_code: "ADZ",
    flag_code: "CO",
  },
  {
    city: "San Andros",
    country: "Bahamas",
    airport_code: "SAQ",
    flag_code: "BS",
  },
  {
    city: "San Angelo TX",
    country: "USA",
    airport_code: "SJT",
    flag_code: "US",
  },
  {
    city: "San Antonio TX",
    country: "USA",
    airport_code: "SAT",
    flag_code: "US",
  },
  {
    city: "San Carlos de Bariloche",
    country: "Argentina",
    airport_code: "BRC",
    flag_code: "AR",
  },
  {
    city: "San Diego CA",
    country: "USA",
    airport_code: "SAN",
    flag_code: "US",
  },
  {
    city: "San Francisco CA - All",
    country: "USA",
    airport_code: "QSF",
    flag_code: "US",
  },
  {
    city: "San Francisco CA",
    country: "USA",
    airport_code: "SFO",
    flag_code: "US",
  },
  {
    city: "San Jose",
    country: "Costa Rica",
    airport_code: "SJO",
    flag_code: "CR",
  },
  { city: "San Jose CA", country: "USA", airport_code: "SJC", flag_code: "US" },
  {
    city: "San Juan",
    country: "Puerto Rico",
    airport_code: "SJU",
    flag_code: "PR",
  },
  {
    city: "San Luis Obispo CA",
    country: "USA",
    airport_code: "SBP",
    flag_code: "US",
  },
  {
    city: "San Luis Potosi",
    country: "Mexico",
    airport_code: "SLP",
    flag_code: "MX",
  },
  {
    city: "San Salvador",
    country: "El Salvador",
    airport_code: "SAL",
    flag_code: "NN",
  },
  {
    city: "San Sebastian",
    country: "Spain",
    airport_code: "EAS",
    flag_code: "ES",
  },
  { city: "Sanaa", country: "Yemen", airport_code: "SAH", flag_code: "YE" },
  {
    city: "Sand Point AK",
    country: "USA",
    airport_code: "SDP",
    flag_code: "US",
  },
  {
    city: "Sandakan Sabah Mal",
    country: "Malaysia",
    airport_code: "SDK",
    flag_code: "MY",
  },
  {
    city: "Sandef GUjord",
    country: "Norway",
    airport_code: "TRF",
    flag_code: "NO",
  },
  {
    city: "Sandspit Queen Charlotte Islands Santa Barbara CA",
    country: "USA",
    airport_code: "SBA",
    flag_code: "US",
  },
  {
    city: "Santa Cruz",
    country: "Spain",
    airport_code: "SPC",
    flag_code: "ES",
  },
  {
    city: "Santa Cruz de Tenerife",
    country: "Canary Islands",
    airport_code: "TFN",
    flag_code: "NN",
  },
  { city: "Santa Fe NM", country: "USA", airport_code: "SAF", flag_code: "US" },
  {
    city: "Santa Maria CA",
    country: "USA",
    airport_code: "SMX",
    flag_code: "US",
  },
  {
    city: "Santa Maria Sal Island",
    country: "Cape Verde",
    airport_code: "SID",
    flag_code: "NN",
  },
  {
    city: "Santa Rosa",
    country: "Argentina",
    airport_code: "RSA",
    flag_code: "AR",
  },
  {
    city: "Santa Rosa CA",
    country: "USA",
    airport_code: "STS",
    flag_code: "US",
  },
  { city: "Santander", country: "Spain", airport_code: "SDR", flag_code: "ES" },
  { city: "Santiago", country: "Chile", airport_code: "SCL", flag_code: "CL" },
  {
    city: "Santiago",
    country: "Dominican Republic",
    airport_code: "STI",
    flag_code: "NN",
  },
  {
    city: "Santo Domingo",
    country: "Dominican Republic",
    airport_code: "SDQ",
    flag_code: "NN",
  },
  { city: "Sanya", country: "China", airport_code: "SYX", flag_code: "CN" },
  {
    city: "Sao Jose So Rio Preto",
    country: "Brazil",
    airport_code: "SJP",
    flag_code: "BR",
  },
  {
    city: "Sao Paulo",
    country: "Brazil",
    airport_code: "CGH",
    flag_code: "BR",
  },
  {
    city: "Sao Paulo",
    country: "Brazil",
    airport_code: "GRU",
    flag_code: "BR",
  },
  {
    city: "Sao Paulo - All Airports",
    country: "Brazil",
    airport_code: "SAO",
    flag_code: "BR",
  },
  { city: "Sapporo", country: "Japan", airport_code: "CTS", flag_code: "JP" },
  { city: "Sapporo", country: "Japan", airport_code: "OKD", flag_code: "JP" },
  {
    city: "Sarajevo",
    country: "Bosnia and Herzegovina",
    airport_code: "SJJ",
    flag_code: "NN",
  },
  {
    city: "Saranac Lake NY",
    country: "USA",
    airport_code: "SLK",
    flag_code: "US",
  },
  { city: "Sarasota FL", country: "USA", airport_code: "SRQ", flag_code: "US" },
  {
    city: "Sarnia Saskatoon Sault Ste. Marie MI",
    country: "USA",
    airport_code: "CIU",
    flag_code: "US",
  },
  {
    city: "Sault Ste. Marie Savannah GA",
    country: "USA",
    airport_code: "SAV",
    flag_code: "US",
  },
  {
    city: "Scottsbluff NE",
    country: "USA",
    airport_code: "BFF",
    flag_code: "US",
  },
  { city: "Seattle WA", country: "USA", airport_code: "SEA", flag_code: "US" },
  {
    city: "Semipalatinsk",
    country: "Kazakhstan",
    airport_code: "PLX",
    flag_code: "KZ",
  },
  {
    city: "Seoul",
    country: "Korea Republic of",
    airport_code: "GMP",
    flag_code: "KR",
  },
  {
    city: "Seoul",
    country: "Korea Republic of",
    airport_code: "ICN",
    flag_code: "KR",
  },
  {
    city: "Sept-Iles Seville",
    country: "Spain",
    airport_code: "SVQ",
    flag_code: "ES",
  },
  { city: "Shanghai", country: "China", airport_code: "PVG", flag_code: "CN" },
  { city: "Shanghai", country: "China", airport_code: "SHA", flag_code: "CN" },
  { city: "Shannon", country: "Ireland", airport_code: "SNN", flag_code: "IE" },
  { city: "Shantou", country: "China", airport_code: "SWA", flag_code: "CN" },
  {
    city: "Sharjah",
    country: "United Arab Emirates",
    airport_code: "SHJ",
    flag_code: "AE",
  },
  {
    city: "Sharm El Sheikh",
    country: "Egypt",
    airport_code: "SSH",
    flag_code: "EG",
  },
  {
    city: "Shenandoah Valley Airport VA",
    country: "USA",
    airport_code: "SHD",
    flag_code: "US",
  },
  { city: "Shenyang", country: "China", airport_code: "SHE", flag_code: "CN" },
  { city: "Shenzhen", country: "China", airport_code: "SZX", flag_code: "CN" },
  { city: "Sheridan WY", country: "USA", airport_code: "SHR", flag_code: "US" },
  {
    city: "Shijiazhuang",
    country: "China",
    airport_code: "SJW",
    flag_code: "CN",
  },
  {
    city: "Shreveport LA",
    country: "USA",
    airport_code: "SHV",
    flag_code: "US",
  },
  {
    city: "Sibu Sarawak",
    country: "Malaysia",
    airport_code: "SBW",
    flag_code: "MY",
  },
  { city: "Sidney MT", country: "USA", airport_code: "SDY", flag_code: "US" },
  {
    city: "Silver City NM",
    country: "USA",
    airport_code: "SVC",
    flag_code: "US",
  },
  {
    city: "Singapore",
    country: "Singapore",
    airport_code: "SIN",
    flag_code: "SG",
  },
  {
    city: "Sioux City IA",
    country: "USA",
    airport_code: "SUX",
    flag_code: "US",
  },
  {
    city: "Sioux Falls SD",
    country: "USA",
    airport_code: "FSD",
    flag_code: "US",
  },
  {
    city: "Sioux Lookout Sitka AK",
    country: "USA",
    airport_code: "SIT",
    flag_code: "US",
  },
  { city: "Skagway AK", country: "USA", airport_code: "SGY", flag_code: "US" },
  {
    city: "Skelleftea",
    country: "Sweden",
    airport_code: "SFT",
    flag_code: "SE",
  },
  {
    city: "Skopje",
    country: "Macedonia",
    airport_code: "SKP",
    flag_code: "NN",
  },
  {
    city: "Smithers Sofia",
    country: "Bulgaria",
    airport_code: "SOF",
    flag_code: "NN",
  },
  {
    city: "Sonderborg",
    country: "Denmark",
    airport_code: "SGD",
    flag_code: "DK",
  },
  {
    city: "Songpan Jiuzhai Huanglong",
    country: "China",
    airport_code: "JZH",
    flag_code: "CN",
  },
  {
    city: "South Andros",
    country: "Bahamas",
    airport_code: "TZN",
    flag_code: "BS",
  },
  {
    city: "South Bend IN",
    country: "USA",
    airport_code: "SBN",
    flag_code: "US",
  },
  {
    city: "Southampton",
    country: "England",
    airport_code: "SOU",
    flag_code: "GB",
  },
  { city: "Split", country: "Croatia", airport_code: "SPU", flag_code: "NN" },
  { city: "Spokane WA", country: "USA", airport_code: "GEG", flag_code: "US" },
  {
    city: "Springfield IL",
    country: "USA",
    airport_code: "SPI",
    flag_code: "US",
  },
  {
    city: "Springfield MA",
    country: "USA",
    airport_code: "CEF",
    flag_code: "US",
  },
  {
    city: "Springfield MO",
    country: "USA",
    airport_code: "SGF",
    flag_code: "US",
  },
  {
    city: "Springfield VT",
    country: "USA",
    airport_code: "VSF",
    flag_code: "US",
  },
  {
    city: "St. Cloud MN",
    country: "USA",
    airport_code: "STC",
    flag_code: "US",
  },
  {
    city: "St. George UT",
    country: "USA",
    airport_code: "SGU",
    flag_code: "US",
  },
  {
    city: "St. George's",
    country: "Grenada",
    airport_code: "GND",
    flag_code: "NN",
  },
  {
    city: "St. John's",
    country: "Antigua",
    airport_code: "ANU",
    flag_code: "NN",
  },
  {
    city: "St. John's St. Louis MO",
    country: "USA",
    airport_code: "STL",
    flag_code: "US",
  },
  {
    city: "St. Petersburg",
    country: "Russia",
    airport_code: "LED",
    flag_code: "RU",
  },
  {
    city: "St. Petersburg FL",
    country: "USA",
    airport_code: "PIE",
    flag_code: "US",
  },
  {
    city: "State College PA",
    country: "USA",
    airport_code: "SCE",
    flag_code: "US",
  },
  {
    city: "Stavanger",
    country: "Norway",
    airport_code: "SVG",
    flag_code: "NO",
  },
  {
    city: "Steamboat Springs CO",
    country: "USA",
    airport_code: "SBS",
    flag_code: "US",
  },
  {
    city: "Stella Maris Long Island",
    country: "Bahamas",
    airport_code: "SML",
    flag_code: "BS",
  },
  {
    city: "Stockholm",
    country: "Sweden",
    airport_code: "ARN",
    flag_code: "SE",
  },
  {
    city: "Stockholm",
    country: "Sweden",
    airport_code: "BMA",
    flag_code: "SE",
  },
  {
    city: "Stockholm-ALL",
    country: "Sweden",
    airport_code: "STO",
    flag_code: "SE",
  },
  {
    city: "Stornoway Isle of Lewis",
    country: "Scotland",
    airport_code: "SYY",
    flag_code: "_scotland",
  },
  { city: "Storuman", country: "Sweden", airport_code: "SQO", flag_code: "SE" },
  {
    city: "Strasbourg",
    country: "France",
    airport_code: "SXB",
    flag_code: "FR",
  },
  {
    city: "Stuttgart",
    country: "Germany",
    airport_code: "STR",
    flag_code: "DE",
  },
  {
    city: "Subic Bay",
    country: "Philippines",
    airport_code: "SFS",
    flag_code: "PH",
  },
  {
    city: "Sudbury Sukhothai",
    country: "Thailand",
    airport_code: "THS",
    flag_code: "TH",
  },
  {
    city: "Sun Valley ID",
    country: "USA",
    airport_code: "SUN",
    flag_code: "US",
  },
  {
    city: "Sundsvall",
    country: "Sweden",
    airport_code: "SDL",
    flag_code: "SE",
  },
  {
    city: "Surabaya Java",
    country: "Indonesia",
    airport_code: "SUB",
    flag_code: "ID",
  },
  {
    city: "Surat Thani",
    country: "Thailand",
    airport_code: "URT",
    flag_code: "TH",
  },
  { city: "Suva", country: "Fiji", airport_code: "SUV", flag_code: "FJ" },
  {
    city: "Sydney Cape Breton Island",
    country: "Australia",
    airport_code: "SYD",
    flag_code: "AU",
  },
  { city: "Syracuse NY", country: "USA", airport_code: "SYR", flag_code: "US" },
  { city: "Szczecin", country: "Poland", airport_code: "SZZ", flag_code: "PL" },
  { city: "Taba", country: "Egypt", airport_code: "TCP", flag_code: "EG" },
  { city: "Tabriz", country: "Iran", airport_code: "TBZ", flag_code: "IR" },
  {
    city: "Tacloban",
    country: "Philippines",
    airport_code: "TAC",
    flag_code: "PH",
  },
  {
    city: "Taegu",
    country: "South Korea",
    airport_code: "TAE",
    flag_code: "KR",
  },
  {
    city: "Tagbilaran",
    country: "Philippines",
    airport_code: "EGTAG",
    flag_code: "PH",
  },
  { city: "Taichung", country: "Taiwan", airport_code: "RMQ", flag_code: "TW" },
  { city: "Taipei", country: "Taiwan", airport_code: "TPE", flag_code: "TW" },
  { city: "Taipei", country: "Taiwan", airport_code: "TSA", flag_code: "TW" },
  { city: "Taiyuan", country: "China", airport_code: "TYN", flag_code: "CN" },
  { city: "Takamatsu", country: "Japan", airport_code: "TAK", flag_code: "JP" },
  {
    city: "Tallahassee FL",
    country: "USA",
    airport_code: "TLH",
    flag_code: "US",
  },
  { city: "Tallinn", country: "Estonia", airport_code: "TLL", flag_code: "NN" },
  { city: "Tampa FL", country: "USA", airport_code: "TPA", flag_code: "US" },
  { city: "Tampere", country: "Finland", airport_code: "TMP", flag_code: "FI" },
  { city: "Tampico", country: "Mexico", airport_code: "TAM", flag_code: "MX" },
  {
    city: "Tamworth N.S.W.",
    country: "Australia",
    airport_code: "TMW",
    flag_code: "AU",
  },
  { city: "Tangier", country: "Morocco", airport_code: "TNG", flag_code: "MA" },
  {
    city: "Tapachula",
    country: "Mexico",
    airport_code: "TAP",
    flag_code: "MX",
  },
  {
    city: "Tashkent",
    country: "Uzbekistan",
    airport_code: "TAS",
    flag_code: "NN",
  },
  {
    city: "Taupo",
    country: "New Zealand",
    airport_code: "TUO",
    flag_code: "NZ",
  },
  {
    city: "Tauranga",
    country: "New Zealand",
    airport_code: "TRG",
    flag_code: "NZ",
  },
  {
    city: "Tawau",
    country: "Sabah",
    airport_code: "Malaysia",
    flag_code: "TWU",
  },
  { city: "Tbilisi", country: "Georgia", airport_code: "TBS", flag_code: "NN" },
  {
    city: "Teesside",
    country: "England",
    airport_code: "MME",
    flag_code: "GB",
  },
  {
    city: "Tegucigalpa",
    country: "Honduras",
    airport_code: "TGU",
    flag_code: "NN",
  },
  { city: "Tehran", country: "Iran", airport_code: "THR", flag_code: "IR" },
  { city: "Tel Aviv", country: "Israel", airport_code: "TLV", flag_code: "IL" },
  {
    city: "Telluride CO",
    country: "USA",
    airport_code: "TEX",
    flag_code: "US",
  },
  { city: "Temuco", country: "Chile", airport_code: "ZCO", flag_code: "CL" },
  {
    city: "Tenerife Island",
    country: "Canary Islands",
    airport_code: "TCI - All Airports",
    flag_code: "NN",
  },
  {
    city: "Tenerife Island",
    country: "Canary Islands",
    airport_code: "TFS",
    flag_code: "NN",
  },
  {
    city: "Tenerife Island",
    country: "Canary Islands",
    airport_code: "TVI - All Airports",
    flag_code: "NN",
  },
  { city: "Tepic", country: "Mexico", airport_code: "TPQ", flag_code: "MX" },
  {
    city: "Termez",
    country: "Uzbekistan",
    airport_code: "TMJ",
    flag_code: "NN",
  },
  {
    city: "Terrace Texarkana AR",
    country: "USA",
    airport_code: "TXK",
    flag_code: "US",
  },
  {
    city: "The Valley",
    country: "Anguilla",
    airport_code: "AXA",
    flag_code: "NN",
  },
  {
    city: "Thessaloniki",
    country: "Greece",
    airport_code: "SKG",
    flag_code: "GR",
  },
  {
    city: "Thief River Falls MN",
    country: "USA",
    airport_code: "TVF",
    flag_code: "US",
  },
  {
    city: "Thira",
    country: "Santorini Island",
    airport_code: "Greece",
    flag_code: "JTR",
  },
  {
    city: "Thompson Thunder Bay Tianjin",
    country: "China",
    airport_code: "TSN",
    flag_code: "CN",
  },
  { city: "Tijuana", country: "Mexico", airport_code: "TIJ", flag_code: "MX" },
  {
    city: "Timaru",
    country: "New Zealand",
    airport_code: "TIU",
    flag_code: "NZ",
  },
  {
    city: "Timmins Tirana",
    country: "Albania",
    airport_code: "TIA",
    flag_code: "NN",
  },
  {
    city: "Tobago",
    country: "Trinidad and Tobago",
    airport_code: "TAB",
    flag_code: "NN",
  },
  {
    city: "Toksook Bay AK",
    country: "USA",
    airport_code: "OOK",
    flag_code: "US",
  },
  { city: "Tokyo", country: "Japan", airport_code: "HND", flag_code: "JP" },
  { city: "Tokyo", country: "Japan", airport_code: "NRT", flag_code: "JP" },
  { city: "Tokyo", country: "Japan", airport_code: "TYO", flag_code: "JP" },
  { city: "Toledo OH", country: "USA", airport_code: "TOL", flag_code: "US" },
  { city: "Toluca", country: "Mexico", airport_code: "TLC", flag_code: "MX" },
  { city: "Tongren", country: "China", airport_code: "TEN", flag_code: "CN" },
  { city: "Topeka KS", country: "USA", airport_code: "TOP", flag_code: "US" },
  {
    city: "Toronto Torreon",
    country: "Mexico",
    airport_code: "TRC",
    flag_code: "MX",
  },
  { city: "Toulon", country: "France", airport_code: "TLN", flag_code: "FR" },
  { city: "Toulouse", country: "France", airport_code: "TLS", flag_code: "FR" },
  {
    city: "Townsville Qld.",
    country: "Australia",
    airport_code: "TSV",
    flag_code: "AU",
  },
  { city: "Trang", country: "Thailand", airport_code: "TST", flag_code: "TH" },
  { city: "Trat", country: "Thailand", airport_code: "TDX", flag_code: "TH" },
  {
    city: "Traverse City MI",
    country: "USA",
    airport_code: "TVC",
    flag_code: "US",
  },
  {
    city: "Treasure Cay Abaco Island",
    country: "Bahamas",
    airport_code: "TCB",
    flag_code: "BS",
  },
  { city: "Trenton NJ", country: "USA", airport_code: "TTN", flag_code: "US" },
  {
    city: "Tri-City Airport TN",
    country: "USA",
    airport_code: "TRI",
    flag_code: "US",
  },
  { city: "Trieste", country: "Italy", airport_code: "TRS", flag_code: "IT" },
  { city: "Tripoli", country: "Libya", airport_code: "TIP", flag_code: "NN" },
  { city: "Tromso", country: "Norway", airport_code: "TOS", flag_code: "NO" },
  {
    city: "Trondheim",
    country: "Norway",
    airport_code: "TRD",
    flag_code: "NO",
  },
  { city: "Truk", country: "Micronesia", airport_code: "TKK", flag_code: "NN" },
  { city: "Tucson AZ", country: "USA", airport_code: "TUS", flag_code: "US" },
  { city: "Tulsa OK", country: "USA", airport_code: "TUL", flag_code: "US" },
  { city: "Tunis", country: "Tunisia", airport_code: "TUN", flag_code: "NN" },
  { city: "Tupelo MS", country: "USA", airport_code: "TUP", flag_code: "US" },
  { city: "Turin", country: "Italy", airport_code: "TRN", flag_code: "IT" },
  { city: "Turku", country: "Finland", airport_code: "TKU", flag_code: "FI" },
  {
    city: "Tuxtla Gutierrez",
    country: "Mexico",
    airport_code: "TGZ",
    flag_code: "MX",
  },
  {
    city: "Twin Falls ID",
    country: "USA",
    airport_code: "TWF",
    flag_code: "US",
  },
  { city: "Tyler TX", country: "USA", airport_code: "TYR", flag_code: "US" },
  {
    city: "Ubon Ratchathani",
    country: "Thailand",
    airport_code: "UBP",
    flag_code: "TH",
  },
  {
    city: "Udon Thani",
    country: "Thailand",
    airport_code: "UTH",
    flag_code: "TH",
  },
  {
    city: "Ujung Pandang Sulawesi",
    country: "Indonesia",
    airport_code: "UPG",
    flag_code: "ID",
  },
  {
    city: "Ulan Bator",
    country: "Mongolia",
    airport_code: "ULN",
    flag_code: "NN",
  },
  { city: "Umea", country: "Sweden", airport_code: "UME", flag_code: "SE" },
  { city: "Una", country: "Brazil", airport_code: "UNA", flag_code: "BR" },
  {
    city: "Unalakleet AK",
    country: "USA",
    airport_code: "UNK",
    flag_code: "US",
  },
  {
    city: "Urumqi Sinkiang",
    country: "China",
    airport_code: "URC",
    flag_code: "CN",
  },
  {
    city: "Ushuaia Tierra del Fuego",
    country: "Argentina",
    airport_code: "USH",
    flag_code: "AR",
  },
  {
    city: "Ust Kamenogorsk",
    country: "Kazakhstan",
    airport_code: "UKK",
    flag_code: "NN",
  },
  { city: "Utapao", country: "Thailand", airport_code: "UTP", flag_code: "TH" },
  { city: "Vaasa", country: "Finland", airport_code: "VAA", flag_code: "FI" },
  { city: "Vail CO", country: "USA", airport_code: "EGE", flag_code: "US" },
  {
    city: "Val-d'Or Valdez AK",
    country: "USA",
    airport_code: "VDZ",
    flag_code: "US",
  },
  { city: "Valdivia", country: "Chile", airport_code: "ZAL", flag_code: "CL" },
  { city: "Valdosta GA", country: "USA", airport_code: "VLD", flag_code: "US" },
  { city: "Valencia", country: "Spain", airport_code: "VLC", flag_code: "ES" },
  {
    city: "Valladolid",
    country: "Spain",
    airport_code: "VLL",
    flag_code: "ES",
  },
  { city: "Valletta", country: "Malta", airport_code: "MLA", flag_code: "NN" },
  { city: "Varanasi", country: "India", airport_code: "VNS", flag_code: "IN" },
  { city: "Varna", country: "Bulgaria", airport_code: "VAR", flag_code: "NN" },
  { city: "Vasteras", country: "Sweden", airport_code: "VST", flag_code: "SE" },
  { city: "Vaxjo", country: "Sweden", airport_code: "VXO", flag_code: "SE" },
  { city: "Venice", country: "Italy", airport_code: "VCE", flag_code: "IT" },
  { city: "Veracruz", country: "Mexico", airport_code: "VER", flag_code: "MX" },
  { city: "Verona", country: "Italy", airport_code: "VRN", flag_code: "IT" },
  {
    city: "Victoria Mahe Island",
    country: "Seychelles",
    airport_code: "SEZ",
    flag_code: "NN",
  },
  { city: "Victoria TX", country: "USA", airport_code: "VCT", flag_code: "US" },
  { city: "Vienna", country: "Austria", airport_code: "VIE", flag_code: "AT" },
  { city: "Vientiane", country: "Laos", airport_code: "VTE", flag_code: "NN" },
  {
    city: "Vieques",
    country: "Puerto Rico",
    airport_code: "VQS",
    flag_code: "NN",
  },
  {
    city: "Vieux Fort",
    country: "St. Lucia",
    airport_code: "UVF",
    flag_code: "NN",
  },
  { city: "Vigo", country: "Spain", airport_code: "VGO", flag_code: "ES" },
  {
    city: "Vilhelmina",
    country: "Sweden",
    airport_code: "VHM",
    flag_code: "SE",
  },
  {
    city: "Villahermosa",
    country: "Mexico",
    airport_code: "VSA",
    flag_code: "MX",
  },
  {
    city: "Vilnius",
    country: "Lithuania",
    airport_code: "VNO",
    flag_code: "NN",
  },
  {
    city: "Vinh City",
    country: "Vietnam",
    airport_code: "VII",
    flag_code: "VN",
  },
  { city: "Visalia CA", country: "USA", airport_code: "VIS", flag_code: "US" },
  { city: "Visby", country: "Sweden", airport_code: "VBY", flag_code: "SE" },
  { city: "Vitoria", country: "Spain", airport_code: "VIT", flag_code: "ES" },
  {
    city: "Vladivostok Siberia",
    country: "Russia",
    airport_code: "VVO",
    flag_code: "RU",
  },
  {
    city: "Volgograd",
    country: "Russia",
    airport_code: "VOG",
    flag_code: "RU",
  },
  {
    city: "Wabush Labrador Waco TX",
    country: "USA",
    airport_code: "ACT",
    flag_code: "US",
  },
  {
    city: "Wagga-Wagga N.S.W.",
    country: "Australia",
    airport_code: "WGA",
    flag_code: "AU",
  },
  { city: "Wajima", country: "Japan", airport_code: "NTQ", flag_code: "JP" },
  {
    city: "Walla Walla WA",
    country: "USA",
    airport_code: "ALW",
    flag_code: "US",
  },
  {
    city: "Wanganui",
    country: "New Zealand",
    airport_code: "WAG",
    flag_code: "NZ",
  },
  { city: "Warri", country: "Nigeria", airport_code: "QRW", flag_code: "NG" },
  { city: "Warsaw", country: "Poland", airport_code: "WAW", flag_code: "PL" },
  {
    city: "Washington DC",
    country: "USA",
    airport_code: "DCA",
    flag_code: "US",
  },
  {
    city: "Washington DC",
    country: "USA",
    airport_code: "IAD",
    flag_code: "US",
  },
  {
    city: "Washington DC - All Airports",
    country: "USA",
    airport_code: "WAS",
    flag_code: "US",
  },
  { city: "Waterloo IA", country: "USA", airport_code: "ALO", flag_code: "US" },
  {
    city: "Watertown NY",
    country: "USA",
    airport_code: "EGT",
    flag_code: "US",
  },
  {
    city: "Watertown SD",
    country: "USA",
    airport_code: "ATY",
    flag_code: "US",
  },
  { city: "Wausau WI", country: "USA", airport_code: "CWA", flag_code: "US" },
  {
    city: "Weipa Qld.",
    country: "Australia",
    airport_code: "WEI",
    flag_code: "AU",
  },
  {
    city: "Wellington",
    country: "New Zealand",
    airport_code: "WLG",
    flag_code: "NZ",
  },
  {
    city: "Wenatchee WA",
    country: "USA",
    airport_code: "EAT",
    flag_code: "US",
  },
  {
    city: "West Palm Beach FL",
    country: "USA",
    airport_code: "PBI",
    flag_code: "US",
  },
  {
    city: "West Yellowstone MT",
    country: "USA",
    airport_code: "WYS",
    flag_code: "US",
  },
  {
    city: "Westport",
    country: "New Zealand",
    airport_code: "WSZ",
    flag_code: "NZ",
  },
  {
    city: "Whakatane",
    country: "New Zealand",
    airport_code: "WHK",
    flag_code: "NZ",
  },
  {
    city: "Whangarei",
    country: "New Zealand",
    airport_code: "WRE",
    flag_code: "NZ",
  },
  {
    city: "White Plains NY",
    country: "USA",
    airport_code: "HPN",
    flag_code: "US",
  },
  {
    city: "Whitehorse Whyalla S.A.",
    country: "Australia",
    airport_code: "WYA",
    flag_code: "AU",
  },
  {
    city: "Wichita Falls TX",
    country: "USA",
    airport_code: "SPS",
    flag_code: "US",
  },
  { city: "Wichita KS", country: "USA", airport_code: "ICT", flag_code: "US" },
  {
    city: "Wick",
    country: "Scotland",
    airport_code: "WIC",
    flag_code: "_scotland",
  },
  {
    city: "Wilkes-Barre PA",
    country: "USA",
    airport_code: "AVP",
    flag_code: "US",
  },
  {
    city: "Willemstad",
    country: "Curacao",
    airport_code: "CUR",
    flag_code: "NN",
  },
  {
    city: "Williams Lake Williamsport PA",
    country: "USA",
    airport_code: "IPT",
    flag_code: "US",
  },
  {
    city: "Williston ND",
    country: "USA",
    airport_code: "ISN",
    flag_code: "US",
  },
  {
    city: "Wilmington DE",
    country: "USA",
    airport_code: "ILG",
    flag_code: "US",
  },
  {
    city: "Wilmington NC",
    country: "USA",
    airport_code: "ILM",
    flag_code: "US",
  },
  {
    city: "Windsor Winnipeg Wolf Point MT",
    country: "USA",
    airport_code: "OLF",
    flag_code: "US",
  },
  { city: "Worland WY", country: "USA", airport_code: "WRL", flag_code: "US" },
  { city: "Wrangell AK", country: "USA", airport_code: "WRG", flag_code: "US" },
  { city: "Wroclaw", country: "Poland", airport_code: "WRO", flag_code: "PL" },
  { city: "Wuhan", country: "China", airport_code: "WUH", flag_code: "CN" },
  { city: "Xi'an", country: "China", airport_code: "XIY", flag_code: "CN" },
  { city: "Xiamen", country: "China", airport_code: "XMN", flag_code: "CN" },
  { city: "Xichang", country: "China", airport_code: "XIC", flag_code: "CN" },
  { city: "Yakima WA", country: "USA", airport_code: "YKM", flag_code: "US" },
  { city: "Yakutat AK", country: "USA", airport_code: "YAK", flag_code: "US" },
  { city: "Yamagata", country: "Japan", airport_code: "GAJ", flag_code: "JP" },
  {
    city: "Yangyang",
    country: "Korea Republic of",
    airport_code: "YNY",
    flag_code: "KR",
  },
  { city: "Yanji", country: "China", airport_code: "YNJ", flag_code: "CN" },
  { city: "Yantai", country: "China", airport_code: "YNT", flag_code: "CN" },
  {
    city: "Yaounde",
    country: "Cameroon",
    airport_code: "NSI",
    flag_code: "NN",
  },
  {
    city: "Yaounde",
    country: "Cameroon",
    airport_code: "YAO",
    flag_code: "NN",
  },
  { city: "Yap", country: "Micronesia", airport_code: "YAP", flag_code: "NN" },
  {
    city: "Yellowknife Yerevan",
    country: "Armenia",
    airport_code: "EVN",
    flag_code: "NN",
  },
  {
    city: "Yogyakarta Java",
    country: "Indonesia",
    airport_code: "JOG",
    flag_code: "ID",
  },
  { city: "Yuma AZ", country: "USA", airport_code: "YUM", flag_code: "US" },
  {
    city: "Zacatecas",
    country: "Mexico",
    airport_code: "ZCL",
    flag_code: "MX",
  },
  { city: "Zadar", country: "Croatia", airport_code: "ZAD", flag_code: "NN" },
  { city: "Zagreb", country: "Croatia", airport_code: "ZAG", flag_code: "NN" },
  {
    city: "Zamboanga",
    country: "Philippines",
    airport_code: "ZAM",
    flag_code: "PH",
  },
  {
    city: "Zanzibar",
    country: "Tanzania",
    airport_code: "ZNZ",
    flag_code: "NN",
  },
  { city: "Zaragoza", country: "Spain", airport_code: "ZAZ", flag_code: "ES" },
  { city: "Zhengzhou", country: "China", airport_code: "CGO", flag_code: "CN" },
  { city: "Zhoushan", country: "China", airport_code: "HSN", flag_code: "CN" },
  {
    city: "Zurich",
    country: "Switzerland",
    airport_code: "ZRH",
    flag_code: "CH",
  },
];
