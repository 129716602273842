import React, { ReactNode, useCallback, useState } from "react";
import { useAuth } from "./AuthContext";
import { IResult, ITravelHistoryExtended } from "../firebaseTypes";
import { analytics, rtdb } from "../firebase";
import { ref, update } from "firebase/database";
import { logEvent } from "firebase/analytics";
import UpdateCurrentLocationModal from "../components/TravelHistoryNew/UpdateCurrentLocationModal";

export interface IUpdateCurrentLocationModalContext {
  // States
  open: boolean | undefined; // uses this to open/close modal
  selectedTravelHistory: ITravelHistoryExtended | null; // selected travel log for updating the current location
  isUpdatingCurrentLocation: boolean; // uses this to give loading state to the update button
  setSelectedTravelHistory: React.Dispatch<
    React.SetStateAction<ITravelHistoryExtended | null>
  >; // uses this to set selected travel history

  // Functions
  handleUpdateCurrentLocation: (
    sortedTravelRecords: ITravelHistoryExtended[]
  ) => void; // use this to check if the current location needs to be updated
  openUpdateCurrentLocationModalFor: (
    travelHistory: ITravelHistoryExtended
  ) => void; // uses this to open modal for a travel history
  closeUpdateCurrentLocationModal: () => void; // uses this to close modal
  updateCurrentLocation: () => Promise<IResult>; // uses this to update a travel history
  setIsUpdateLocationPromptSkipped: (value: boolean) => Promise<IResult>; // uses this to update a travel history
  isSaveButtonDisabled: () => boolean; // uses this to enable/disable the update button
}

const defaultValues: IUpdateCurrentLocationModalContext = {
  // States
  open: undefined,
  selectedTravelHistory: null,
  isUpdatingCurrentLocation: false,
  setSelectedTravelHistory: () => {}, // state --> setSelectedTravelHistory

  // Functions
  handleUpdateCurrentLocation: () => null,
  openUpdateCurrentLocationModalFor: () => {},
  closeUpdateCurrentLocationModal: () => {},
  updateCurrentLocation: () => Promise.resolve({ success: false, error: "" }),
  setIsUpdateLocationPromptSkipped: () =>
    Promise.resolve({ success: false, error: "" }),
  isSaveButtonDisabled: () => false,
};

const UpdateCurrentLocationModalContext =
  React.createContext<IUpdateCurrentLocationModalContext>(defaultValues);

export const useUpdateCurrentLocationModal = () => {
  return React.useContext(UpdateCurrentLocationModalContext);
};

export interface Props {
  travelState: "usa" | "world";
  children: ReactNode | string;
}

export const UpdateCurrentLocationModalProvider = ({
  travelState,
  children,
}: Props) => {
  // uses this to open/close modal
  const [open, setOpen] = useState<boolean | undefined>(defaultValues.open);
  // latest travel log for updating the current location
  const [selectedTravelHistory, setSelectedTravelHistory] =
    useState<ITravelHistoryExtended | null>(null);
  // uses this to give loading state to the update button
  const [isUpdatingCurrentLocation, setIsUpdatingTravelHistory] =
    useState(false);
  const { currentUser } = useAuth();

  // Open modal for a travel history
  const openUpdateCurrentLocationModalFor = useCallback(
    (travelHistory: ITravelHistoryExtended) => {
      logEvent(analytics, "open_modal", {
        type: `update_${travelState}_travel_current_location`,
      }); // log event to analytics
      setSelectedTravelHistory(travelHistory);
      setOpen(true);
    },
    [travelState]
  );

  // use this to check if the current location should be updated
  const handleUpdateCurrentLocation = useCallback(
    (sortedTravelRecords: ITravelHistoryExtended[]) => {
      if (sortedTravelRecords.length < 1) return null;
      if (
        sortedTravelRecords[0].departure_date === "none" &&
        !sortedTravelRecords[0].isPresent &&
        !sortedTravelRecords[0].isUpdateLocationPromptSkipped
      ) {
        openUpdateCurrentLocationModalFor(sortedTravelRecords[0]);
      }
    },
    [openUpdateCurrentLocationModalFor]
  );

  // Close modal
  const closeUpdateCurrentLocationModal = useCallback(() => {
    logEvent(analytics, "close_modal", {
      type: `update_${travelState}_travel_current_location`,
    }); // log event to analytics
    setSelectedTravelHistory(null);
    setOpen(false);
  }, [travelState]);

  // Update travel history
  const updateCurrentLocation = useCallback(async () => {
    // Check if user is logged in and if a travel history is null
    if (
      !currentUser?.uid ||
      !selectedTravelHistory ||
      !selectedTravelHistory.id
    ) {
      return {
        success: false,
        error: "User not logged in or selected travel history is null",
      };
    }

    try {
      setIsUpdatingTravelHistory(true);
      const refStr = `users/${currentUser.uid}/usa-travel-records/${selectedTravelHistory.id}`;
      const travelRef = ref(rtdb, refStr);

      // adding the only required fields in database
      // Days are counted at front-end so no need to add it here
      await update(travelRef, {
        departure_date: selectedTravelHistory.departure_date || null,
        departure_loc: selectedTravelHistory.departure_loc || null,
        automated: false, // this is to make sure that the travel history is manually added
        isPresent: selectedTravelHistory.isPresent || false,
        isUpdateLocationPromptSkipped: false,
      });
      // update travel history in rtdb
      setIsUpdatingTravelHistory(false); // set loading state to false
      setSelectedTravelHistory(null);
      setOpen(false); // close modal
      return { success: true, error: "" };
    } catch (error: any) {
      console.error(error);
      // update travel history in rtdb
      setIsUpdatingTravelHistory(false); // set loading state to false
      setSelectedTravelHistory(null);
      setOpen(false); // close modal
      return { success: false, error: error.message };
    }
  }, [currentUser?.uid, selectedTravelHistory]);

  // set isUpdateLocationPromptSkipped for a travel record
  const setIsUpdateLocationPromptSkipped = useCallback(
    async (value: boolean) => {
      // Check if user is logged in and if a travel history is null
      if (
        !currentUser?.uid ||
        !selectedTravelHistory ||
        !selectedTravelHistory.id
      ) {
        return {
          success: false,
          error: "User not logged in or selected travel history is null",
        };
      }

      try {
        const refStr = `users/${currentUser.uid}/usa-travel-records/${selectedTravelHistory.id}`;
        const travelRef = ref(rtdb, refStr);

        // adding the only required fields in database
        // Days are counted at front-end so no need to add it here
        await update(travelRef, {
          isUpdateLocationPromptSkipped: value || false,
        });
        setOpen(false); // close modal
        return { success: true, error: "" };
      } catch (error: any) {
        console.error(error);
        return { success: false, error: error.message };
      }
    },
    [currentUser?.uid, selectedTravelHistory]
  );

  // enables/disables the update current location button
  const isSaveButtonDisabled = useCallback(() => {
    // Check if user is logged in and if a travel history is null
    if (!selectedTravelHistory) return true;
    // Disable submission if the departure date is not selected
    if (
      selectedTravelHistory.departure_date === "none" ||
      selectedTravelHistory.departure_loc === "none"
    )
      return true;
    else return false;
  }, [selectedTravelHistory]);

  return (
    <UpdateCurrentLocationModalContext.Provider
      value={{
        open,
        handleUpdateCurrentLocation,
        updateCurrentLocation,
        setIsUpdateLocationPromptSkipped,
        openUpdateCurrentLocationModalFor,
        closeUpdateCurrentLocationModal,
        selectedTravelHistory,
        setSelectedTravelHistory,
        isSaveButtonDisabled,
        isUpdatingCurrentLocation,
      }}
    >
      <UpdateCurrentLocationModal />
      {children}
    </UpdateCurrentLocationModalContext.Provider>
  );
};
