import { LocalizationProvider, LoadingButton } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import {
  DialogContent,
  Box,
  DialogActions,
  DialogTitle,
  Grid,
  InputLabel,
  Typography,
} from "@mui/material";
import {
  DCAutocomplete,
  DCAutocompleteTextField,
  DCButton,
  DCDialog,
} from "../../assets/theme/theme";
import { DCDatePicker } from "../Shared/DCDatePicker";
import { dateFormat } from "../../utils/dateTimeUtils";

import { useCallback, useEffect, useState } from "react";
import { useUpdateCurrentLocationModal } from "../../contexts/UpdateCurrentLocationModalContext";
import { useAuth } from "../../contexts/AuthContext";
import { format } from "date-fns";
import { isEmpty } from "lodash";
import {
  usaAirportList,
  useTravelDatesValidationNew,
} from "../../utils/travelHistoryUtils";
import { Flag } from "../Shared/Icons/Flag";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import QuestionAnswerOutlinedIcon from "@mui/icons-material/QuestionAnswerOutlined";

const UpdateCurrentLocationModal: React.FC = () => {
  const {
    open,
    closeUpdateCurrentLocationModal,
    selectedTravelHistory,
    setSelectedTravelHistory,
    updateCurrentLocation,
    isSaveButtonDisabled,
    isUpdatingCurrentLocation,
    setIsUpdateLocationPromptSkipped,
  } = useUpdateCurrentLocationModal();
  const { shouldDisableDepartureDate } = useTravelDatesValidationNew(
    selectedTravelHistory
  );
  const { currentUser } = useAuth();
  const [showDepartureDetails, setShowDepartureDetails] = useState(false);

  // handles the change of the departure place
  const onChangeDeparturePlace = useCallback(
    (val: any) => {
      setSelectedTravelHistory((prev: any) => ({
        ...prev,
        departure_loc_label: val?.label,
        departure_loc: val?.airport_code,
      }));
    },
    [setSelectedTravelHistory]
  );

  // handles the change of the departure date
  const onChangeDepartureDate = useCallback(
    (_data: Date, val: number) => {
      const formattedDate = dateFormat(val); // format the date in YYYY-MM-DD format
      setSelectedTravelHistory((prev: any) => ({
        ...prev,
        departure_date: formattedDate,
        departure_date_parsed: _data,
      }));
    },
    [setSelectedTravelHistory]
  );

  // handles the change of the isPresent checkbox
  const setIsPresentFieldValue = useCallback(() => {
    setSelectedTravelHistory((prev) => {
      const isPresent = true;
      if (prev)
        return {
          ...prev,
          isPresent,
          departure_loc_label: isPresent ? "" : prev.departure_loc_label,
          departure_loc: isPresent ? "" : prev.departure_loc,
          departure_date: isPresent ? "" : prev.departure_date,
          departure_date_parsed: isPresent ? null : prev.departure_date_parsed,
        };
      return null;
    });
  }, [setSelectedTravelHistory]);

  useEffect(() => {
    if (selectedTravelHistory?.isPresent) {
      updateCurrentLocation();
    }
  }, [selectedTravelHistory?.isPresent, updateCurrentLocation]);

  return (
    <DCDialog
      open={Boolean(open)}
      maxWidth="sm"
      fullWidth
      disableEscapeKeyDown={true}
      onClose={closeUpdateCurrentLocationModal}
    >
      <DialogTitle
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "flex-start",
          gap: 1,
        }}
      >
        <QuestionAnswerOutlinedIcon color="primary" />{" "}
        {showDepartureDetails
          ? "Enter Departure Details"
          : "Pick Your Current Country of Residence"}
      </DialogTitle>
      <DialogContent>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          {!showDepartureDetails ? (
            <>
              {currentUser?.displayName}, looks like you've been in{" "}
              <strong>{selectedTravelHistory?.country}</strong> since{" "}
              <strong>
                {selectedTravelHistory?.arrival_date_parsed
                  ? `${format(
                      selectedTravelHistory.arrival_date_parsed,
                      "MMMM dd, uuuu"
                    )}`
                  : null}
                .
              </strong>
              <br />
              <Typography mt={1} component="p">
                Are you currently located within the country (
                {selectedTravelHistory?.country})?
              </Typography>
            </>
          ) : (
            <>
              Please enter the departure details.{" "}
              {selectedTravelHistory?.arrival_date_parsed
                ? `Your arrival date in ${
                    selectedTravelHistory?.country
                  } was ${format(
                    selectedTravelHistory.arrival_date_parsed,
                    "MMMM dd, uuuu"
                  )}.`
                : null}
              <Grid item xs={6} mt={2}>
                <InputLabel shrink htmlFor="visiting-city-country">
                  Port Of Exit
                </InputLabel>
                <DCAutocomplete
                  id="visiting-city-country"
                  key="visiting-city-country"
                  options={usaAirportList}
                  disabled={selectedTravelHistory?.isPresent}
                  value={selectedTravelHistory?.departure_loc_label}
                  onChange={(_, val: any) => onChangeDeparturePlace(val)}
                  renderInput={(params) => (
                    <DCAutocompleteTextField
                      {...params}
                      error={isEmpty(selectedTravelHistory?.departure_loc)}
                      helperText={
                        isEmpty(selectedTravelHistory?.departure_loc)
                          ? "Required"
                          : ""
                      }
                      required
                      placeholder="Select City, Country"
                    />
                  )}
                />
              </Grid>
              <Box position="relative" mt={2}>
                <InputLabel shrink htmlFor="departure-date">
                  Departure Date
                  {selectedTravelHistory?.country
                    ? ` (${selectedTravelHistory?.country})`
                    : ""}
                  <Flag
                    code={selectedTravelHistory?.country || ""}
                    width={20}
                    style={{ marginLeft: 8, marginBottom: -1 }}
                  />
                </InputLabel>
                <DCDatePicker
                  id="departure-date"
                  value={
                    (selectedTravelHistory?.departure_date_parsed as Date) ||
                    null
                  }
                  disabled={
                    !selectedTravelHistory?.arrival_date_parsed ||
                    selectedTravelHistory.isPresent
                  }
                  onChange={onChangeDepartureDate}
                  shouldDisableDate={shouldDisableDepartureDate}
                />
              </Box>
            </>
          )}
        </LocalizationProvider>
      </DialogContent>
      <DialogActions
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: showDepartureDetails ? "space-between" : "flex-end",
        }}
      >
        {showDepartureDetails ? (
          <DCButton
            variant="text"
            aria-label="Back to confirm country"
            onClick={() => setShowDepartureDetails(false)}
            startIcon={<ArrowBackIcon />}
          >
            Back
          </DCButton>
        ) : null}

        <Box>
          <DCButton
            variant="outlined"
            aria-label="close window"
            onClick={() => {
              if (showDepartureDetails) {
                setIsUpdateLocationPromptSkipped(true);
                closeUpdateCurrentLocationModal();
              } else {
                setShowDepartureDetails(true);
              }
            }}
          >
            {showDepartureDetails ? "Skip" : "No"}
          </DCButton>

          {showDepartureDetails && (
            <LoadingButton
              loadingPosition="center"
              aria-label="save"
              variant="contained"
              loading={isUpdatingCurrentLocation}
              onClick={updateCurrentLocation}
              disabled={isSaveButtonDisabled()}
              sx={{ ml: 1 }}
            >
              Save
            </LoadingButton>
          )}
          {!showDepartureDetails && (
            <LoadingButton
              loadingPosition="center"
              aria-label="save"
              variant="contained"
              loading={isUpdatingCurrentLocation}
              onClick={setIsPresentFieldValue}
              sx={{ ml: 1 }}
            >
              {`Yes${
                selectedTravelHistory?.country
                  ? `, I'm in ${selectedTravelHistory.country}`
                  : ""
              }`}
            </LoadingButton>
          )}
        </Box>
      </DialogActions>
    </DCDialog>
  );
};

export default UpdateCurrentLocationModal;
