import { Box, Stack, Typography, Button } from "@mui/material";
import React from "react";
import theme from "../../assets/theme/theme";
import NewFolderIcon from "../Shared/Icons/NewFolderIcon";

export interface Props {
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
}

const createFolder = {
  title: "Create Folder",
  icon: <NewFolderIcon />,
  count: 0,
  docs: [],
};

const CreateFolder: React.FC<Props> = (props) => {
  return (
    <Box
      component={Button}
      onClick={props.onClick}
      disableRipple
      sx={{
        "&:hover": {
          background: "none !important",
        },
        color: theme.palette.text.secondary
      }}
      p={0}
    >
      <Box display="inline-block">
        <Stack p="20px" position="relative" width="120px" display="contents">
          {createFolder.icon}
          <Typography textAlign="center" lineHeight={1.5}>
            {createFolder.title}
          </Typography>
        </Stack>
      </Box>
    </Box>
  );
};

export default CreateFolder;
