import { get, isEmpty, sortBy } from "lodash";
import React from "react";
import DocumentModal from "../components/DocumentModal";
import { IDocument, IDocumentField } from "../firebaseTypes";
import { useDocumentContext } from "./DocumentContext";
import { PDFMergerProvider } from "./PDFMergerContext";
// Main consumers
// - Document Modal
// - Modal host components to open/close modal
export interface IDocumentModalContext {
  // For modal
  documentQueue: IDocument[];
  index: number;
  openDocumentModalState: boolean;
  isSavingDocument: boolean;
  downloadURL: string;
  docInfo: IDocumentField[];
  next: boolean;
  //created_at: any;
  saveDocument: (document: IDocument, docInfo: IDocumentField[]) => any;
  onChange: (index: number, manualValue: any) => any;
  // for hosts
  openDocumentModalFor: (doc: IDocument[]) => void;
  closeDocumentModal: () => void;
}

const defaultValues: IDocumentModalContext = {
  documentQueue: [],
  index: 0,
  openDocumentModalState: false,
  isSavingDocument: false,
  downloadURL: "",
  docInfo: [],
  next: false,
  saveDocument: () => {},
  onChange: () => {},
  openDocumentModalFor: () => {},
  closeDocumentModal: () => {},
};

const DocumentModalContext =
  React.createContext<IDocumentModalContext>(defaultValues);

export function useDocumentModalContext() {
  return React.useContext(DocumentModalContext);
}

// This modal is going to exist on all the pages which has header
export const DocumentModalProvider: React.FC = (props) => {
  // context
  const [openDocumentModalState, setOpenDocumentModalState] = React.useState(
    defaultValues.openDocumentModalState
  );
  const { updateDocumentInTransaction } = useDocumentContext();
  const { getDocDownloadUrl } = useDocumentContext();
  // state
  const [documentQueue, setDocumentQueue] = React.useState(
    defaultValues.documentQueue
  );
  const [index, setIndex] = React.useState(defaultValues.index);
  const [isSavingDocument, setIsSavingDocument] = React.useState(
    defaultValues.isSavingDocument
  );
  // create a copy of doc_info object so that we can edit it and save it back to db
  const [docInfo, setDocInfo] = React.useState(defaultValues.docInfo);
  const [downloadURL, setDownloadURL] = React.useState(
    defaultValues.downloadURL
  );
  const [next, setNext] = React.useState(defaultValues.next);

  // set next
  React.useEffect(() => {
    setNext(index < documentQueue.length - 1);
  }, [index, documentQueue]);

  // set downloadURL
  React.useEffect(() => {
    (async function () {
      if (get(documentQueue, [index])) {
        const url = getDocDownloadUrl(get(documentQueue, [index]));
        setDownloadURL(url);
      }
    })();
  }, [documentQueue, getDocDownloadUrl, index]);

  // set docInfo
  React.useEffect(() => {
    if (get(documentQueue, [index, "doc_info"])) {
      const doc_info = sortBy(
        get(documentQueue, [index, "doc_info"]),
        (f) => f.priority || 1
      );
      const copy_doc_info = JSON.parse(JSON.stringify(doc_info));
      setDocInfo(copy_doc_info);
    }
  }, [documentQueue, index]);

  const onChange = React.useCallback((index: number, manualValue: any) => {
    setDocInfo((prev) => {
      prev[index].manual_value = manualValue;
      prev[index].manual = true;
      // prev[index].edited_at = serverTimestamp();
      return [...prev];
    });
  }, []);

  const closeDocumentModal = React.useCallback(() => {
    // clean up all states
    setDocumentQueue(defaultValues.documentQueue);
    setOpenDocumentModalState(defaultValues.openDocumentModalState);
    setIsSavingDocument(defaultValues.isSavingDocument);
    setNext(defaultValues.next);
    setDownloadURL(defaultValues.downloadURL);
    setDocInfo(defaultValues.docInfo);
  }, []);

  const saveDocument = React.useCallback(
    async (document: IDocument, docInfo: IDocumentField[]) => {
      if (isEmpty(document) || isEmpty(docInfo)) return;
      setIsSavingDocument(true);
      const success = await updateDocumentInTransaction(
        document,
        (current) => {
          return { doc_info: docInfo };
        },
        !next
      );
      setIsSavingDocument(false);
      if (success) {
        if (next) {
          // go next
          setIndex((prev) => {
            const _new = prev + 1;
            return _new >= documentQueue.length ? prev : _new;
          });
        } else {
          closeDocumentModal();
        }
      }
    },
    [
      updateDocumentInTransaction,
      closeDocumentModal,
      documentQueue,
      index,
      next,
    ]
  );

  const openDocumentModalFor = React.useCallback((documents: IDocument[]) => {
    setOpenDocumentModalState(true);
    setDocumentQueue(documents);
    setIndex(defaultValues.index);
  }, []);

  return (
    <DocumentModalContext.Provider
      value={{
        documentQueue,
        index,
        openDocumentModalState,
        isSavingDocument,
        docInfo,
        downloadURL,
        next,
        onChange,
        saveDocument,
        openDocumentModalFor,
        closeDocumentModal,
      }}
    >
      <PDFMergerProvider>
        <DocumentModal />
      </PDFMergerProvider>
      {props.children}
    </DocumentModalContext.Provider>
  );
};
