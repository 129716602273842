import React from "react";
import PdfPreviewModal from "../components/PdfPreviewModal";
import { IDocument } from "../firebaseTypes";
import { ITravelDocument } from "./DocumentContext";

export interface IPdfPreviewContext {
  // for host
  openModalForDocument: (document: IDocument, id?: string) => any;
  openModalForTravelDocument: (document: ITravelDocument) => any;
  // for modal
  open: boolean;
  document: IDocument | null;
  travelDocument: ITravelDocument | null;
  handleClose: () => any;
  handlePrintDocument: () => any;
}

const defaultValues: IPdfPreviewContext = {
  open: false,
  document: null,
  travelDocument: null,
  openModalForDocument: () => {},
  openModalForTravelDocument: () => {},
  handleClose: () => {},
  handlePrintDocument: () => {},
};

const Context = React.createContext(defaultValues);

export function usePdfPreviewModalContext() {
  return React.useContext(Context);
}

export const PdfPreviewModalProvider: React.FC = (props) => {
  const [open, setOpen] = React.useState(defaultValues.open);
  const [document, setDocument] = React.useState(null as IDocument | null);
  const [travelDocument, setTravelDocument] = React.useState(
    null as ITravelDocument | null
  );

  const openModalForDocument = React.useCallback((d: IDocument) => {
    setOpen(true);
    setDocument(d);
  }, []);

  const openModalForTravelDocument = React.useCallback(
    (td: ITravelDocument) => {
      setOpen(true);
      setTravelDocument(td);
    },
    []
  );

  const handleClose = React.useCallback(() => {
    setOpen(false);
    setDocument(null);
    setTravelDocument(null);
  }, []);

  const handlePrintDocument = React.useCallback(() => {
    window.print();
  }, []);

  return (
    <Context.Provider
      value={{
        open,
        document,
        travelDocument,
        handleClose,
        handlePrintDocument,
        openModalForDocument,
        openModalForTravelDocument,
      }}
    >
      {open && <PdfPreviewModal />}
      {props.children}
    </Context.Provider>
  );
};
