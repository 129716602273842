import { Box } from "@mui/material"
import React from "react"
import Logo from "../Shared/Logo"

export interface Props {
}

const Spinner: React.FC<Props> = (props) => {
  return <Box className="spiner-wrapper">
    <Box className="spinner"> <Logo /> </Box>
  </Box>
}

export default Spinner
