import * as React from "react";
import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";
function UserSettingsIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        width="24"
        height="24"
      >
        <path
          d="M10.009,10.75A4.25,4.25,0,1,0,5.759,6.5,4.255,4.255,0,0,0,10.009,10.75Zm0-7A2.75,2.75,0,1,1,7.259,6.5,2.753,2.753,0,0,1,10.009,3.75Zm2.872,10.06A5.634,5.634,0,0,0,12,13.75H8a3.959,3.959,0,0,0-4.25,4.27c0,1.563.673,2.23,2.25,2.23h5a.75.75,0,0,1,0,1.5H6c-2.418,0-3.75-1.325-3.75-3.73,0-2.662,1.506-5.77,5.75-5.77h4a6.957,6.957,0,0,1,1.119.08.748.748,0,0,1,.621.859A.756.756,0,0,1,12.881,13.81ZM22.233,17.5l.133-.074a.75.75,0,0,0,.279-1.039l-.916-1.538a.75.75,0,0,0-1.011-.271l-.133.074a.659.659,0,0,1-.644,0,.583.583,0,0,1-.3-.5.9.9,0,0,0-.9-.9H17.21a.9.9,0,0,0-.9.9.584.584,0,0,1-.3.5.659.659,0,0,1-.644,0,.91.91,0,0,0-1.218.325l-.763,1.28a.894.894,0,0,0,.327,1.24l.02.011a.567.567,0,0,1-.019,1,.91.91,0,0,0-.426.551.89.89,0,0,0,.1.687l.766,1.283a.9.9,0,0,0,1.218.323.66.66,0,0,1,.643,0,.58.58,0,0,1,.3.5.9.9,0,0,0,.9.9h1.527a.9.9,0,0,0,.9-.9.583.583,0,0,1,.3-.5.659.659,0,0,1,.644,0,.913.913,0,0,0,1.219-.324l.767-1.288a.9.9,0,0,0-.332-1.233l-.018-.01a.569.569,0,0,1,.012-1Zm-1.224,1.931-.238.4a2.175,2.175,0,0,0-1.566.216,2.1,2.1,0,0,0-.976,1.207h-.514a2.094,2.094,0,0,0-.976-1.207,2.168,2.168,0,0,0-1.566-.216l-.237-.4a2.057,2.057,0,0,0,0-2.859l.237-.4a2.17,2.17,0,0,0,1.566-.216,2.1,2.1,0,0,0,.976-1.207h.514a2.1,2.1,0,0,0,.976,1.207,2.172,2.172,0,0,0,1.566.216l.238.4a2.058,2.058,0,0,0,0,2.858ZM19.02,18a1,1,0,1,1-1.01-1h.01A1,1,0,0,1,19.02,18Z"
          fill="currentColor"
        />
      </svg>
    </SvgIcon>
  );
}
export default UserSettingsIcon;
