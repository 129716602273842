import { format } from "date-fns";
import { logEvent } from "firebase/analytics";
import { useMemo, useState } from "react";
import { airports } from "../../../assets/airportData";
import { usaAirports } from "../../../assets/usaAirportData";
import { useTravelHistoryContext } from "../../../contexts/TravelHistoryContext";
import { analytics } from "../../../firebase";
import { ITravelHistory } from "../../../firebaseTypes";
import { AirportModel } from "../../../models/AirportModel";
import { dateTimeToSeconds, parseDate, toDateTime } from "../../../utils/dateTimeUtils";

export interface ITravelModel {
  id?: string;
  arrival_date: Date | null;
  arrival_loc: string;
  arrival_loc_label: string;
  automated: boolean;
  country: string;
  departure_date: Date | null;
  departure_loc: string;
  departure_loc_label: string;
  isPresent: boolean;
  visa_status: string;
  hasError: boolean;
}

const useTravelHistory = () => {
  const {
    usaTravelRecords,
    loadingStatusUSATravels,
    addUsaTravelRecord,
    deleteUsaTravelRecord,
    deleteAllUsaTravelRecord,
    updateUsaTravelRecord,

    travelRecords,
    loadingStatusWorldTravels,
    addTravelRecord,
    deleteTravelRecord,
    deleteAllTravelRecord,
    updateTravelRecord,
  } = useTravelHistoryContext();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isAllDialogOpen, setIsAllDialogOpen] = useState(false);
  const [selectedTravel, setSelectedTravel] = useState<ITravelModel | null>(
    null
  );
  const [selectedRows, setSelectedRows] = useState<string[]>([]);

  const modelToDto = (model: ITravelModel): ITravelHistory => {
    return {
      arrival_date: model.arrival_date
        ? format(model.arrival_date, "yyyy-MM-dd")
        : "none",
      departure_date: model.isPresent
        ? "Present"
        : model.departure_date
          ? format(model.departure_date, "yyyy-MM-dd")
          : "none",
      visa_status: model.visa_status,
      arrival_loc: model.arrival_loc,
      automated: model.automated,
      country: model.country,
      departure_loc: model.isPresent ? "" : model.departure_loc,
      isPresent: model.isPresent
    };
  };

  const getDate = (val: string) => {
    if (!val || val === "none") {
      return null;
    }
    if (val === "Present") {
      return new Date();
    }

    return parseDate(val);
  };

  const checkError = (t: ITravelHistory) => {
    return (
      t.arrival_loc === "Unavailable" ||
      t.arrival_loc === "none" ||
      t.departure_loc === "Unavailable" ||
      t.departure_loc === "none" ||
      t.arrival_date === "none" ||
      t.departure_date === "none" ||
      t.arrival_date === null ||
      t.departure_date === null ||
      t.arrival_loc === null ||
      t.departure_loc === null
    );
  };

  const getAirportLabel = (airport: AirportModel): string =>
    `${airport.city} (${airport.airport_code}), ${airport.country}`;

  const getLocationLabel = (loc: string, list: AirportModel[]): string => {
    const airport = list.find((a) => a.airport_code === loc);
    return airport ? getAirportLabel(airport) : "";
  };

  const worldTravels = useMemo<ITravelModel[]>(
    () =>
      travelRecords.map((t) => ({
        ...t,
        arrival_date: parseDate(t.arrival_date),
        departure_date: parseDate(t.departure_date),
        hasError: checkError(t),
        isPresent: t.departure_date === "Present",
        arrival_loc_label: getLocationLabel(
          t.arrival_loc,
          airports as AirportModel[]
        ),
        departure_loc_label:
          t.departure_date === "Present"
            ? "Currently in the country"
            : getLocationLabel(t.departure_loc, airports as AirportModel[]),
      })),
    [travelRecords]
  );

  const usaTravels = useMemo<ITravelModel[]>(
    () =>
      usaTravelRecords.map((t) => ({
        ...t,
        arrival_date: getDate(t.arrival_date),
        departure_date: getDate(t.departure_date),
        hasError: checkError(t),
        isPresent: t.departure_date === "Present",
        country: "USA",
        arrival_loc_label: getLocationLabel(
          t.arrival_loc,
          usaAirports as AirportModel[]
        ),
        departure_loc_label:
          t.departure_date === "Present"
            ? "Currently in the country"
            : getLocationLabel(t.departure_loc, usaAirports as AirportModel[]),
      })),
    [usaTravelRecords]
  );

  const usaAirportList = useMemo<AirportModel[]>(() => {
    return (usaAirports as AirportModel[]).map((a) => ({
      ...a,
      label: getAirportLabel(a),
    }));
  }, []);

  const airportList = useMemo<AirportModel[]>(() => {
    return (airports as AirportModel[])
      .filter((a) => a.flag_code !== "US")
      .map((a) => ({
        ...a,
        label: getAirportLabel(a),
      }));
  }, []);

  const onOpenModal = () => {
    logEvent(analytics, "open_modal", { type: "edit_travel" });
    setIsModalOpen(true);
  };

  const onCloseModal = () => {
    logEvent(analytics, "close_modal", { type: "select_travel" });
    setIsModalOpen(false);
    setSelectedTravel(null);
  };

  const onCloseDialog = () => {
    logEvent(analytics, "close_dialog", { type: "delete_travel" });
    setSelectedRows([]);
    setIsDialogOpen(false);
  };

  const onCloseAllDialog = () => {
    logEvent(analytics, "close_all_dialog", { type: "delete_all_travels" });
    setIsAllDialogOpen(false);
  };

  const onSelectTravelForEdit = (travel: ITravelModel) => {
    logEvent(analytics, "button_click", { type: "select_travel" });
    setSelectedTravel(travel);
    onOpenModal();
  };

  const onDeleteRowClick = (selectedItems: string[]) => {
    logEvent(analytics, "button_click", {
      type: "delete_travel",
      ids: selectedItems.join(",id:"),
      count: selectedItems.length,
    });
    logEvent(analytics, "open_dialog", {
      type: "delete_travel",
      ids: selectedItems.join(",id:"),
      count: selectedItems.length,
    });
    setSelectedRows(selectedItems);
    setIsDialogOpen(true);
  };

  const onDeleteAllRowClick = () => {
    logEvent(analytics, "button_click", { type: "delete_all_travels" });
    logEvent(analytics, "open_dialog", { type: "delete_all_travels" });
    setIsAllDialogOpen(true);
  };

  const onSaveUsaTravel = async (travel: ITravelModel) => {
    logEvent(analytics, "save_usa_travel", { ...travel });
    selectedTravel && selectedTravel.id
      ? updateUsaTravelRecord(selectedTravel.id, modelToDto(travel))
      : addUsaTravelRecord(modelToDto(travel));
  };

  const onDeleteUsaTravel = async (id: string) => {
    logEvent(analytics, "delete_usa_travel", { id });
    deleteUsaTravelRecord(id);
    setSelectedTravel(null);
  };

  const onDeleteAllUsaTravels = async () => {
    logEvent(analytics, "delete_all_usa_travel");
    deleteAllUsaTravelRecord();
    setSelectedTravel(null);
  };

  const onSaveWorldTravel = async (travel: ITravelModel) => {
    logEvent(analytics, "save_travel", { ...travel });
    selectedTravel && selectedTravel.id
      ? updateTravelRecord(selectedTravel.id, modelToDto(travel))
      : addTravelRecord(modelToDto(travel));
  };

  const onDeleteWorldTravel = async (id: string) => {
    logEvent(analytics, "delete_travel", { id });
    deleteTravelRecord(id);
    setSelectedTravel(null);
  };

  const onDeleteWorldTravels = async () => {
    logEvent(analytics, "delete_all_travel");
    deleteAllTravelRecord();
    setSelectedTravel(null);
  };

  return {
    airportList,
    usaAirportList,
    usaTravels,
    worldTravels,
    travelRecords,
    usaTravelRecords,
    isModalOpen,
    isDialogOpen,
    selectedTravel,
    isAllDialogOpen,
    selectedRows,
    loadingStatusUSATravels,
    loadingStatusWorldTravels,

    onDeleteAllRowClick,
    onCloseAllDialog,
    onOpenModal,
    onCloseModal,
    onCloseDialog,
    onSelectTravelForEdit,
    onSaveUsaTravel,
    onSaveWorldTravel,
    onDeleteRowClick,
    onDeleteUsaTravel,
    onDeleteAllUsaTravels,
    onDeleteWorldTravel,
    onDeleteWorldTravels,
  };
};

export default useTravelHistory;
