import {

  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import { DCButton, DCDialog } from "../../assets/theme/theme";
import { useLogOutTimer } from "../../contexts/LogOutTimer";

const LogOutTimerModal = () => {
  const { openModal, setOpenModal } = useLogOutTimer();
  const handleClose = () => setOpenModal(false);

  return (
    <div>
      <DCDialog open={openModal} onClose={handleClose} maxWidth="xs" fullWidth>
        <DialogTitle>Inactivity alert!</DialogTitle>
        <DialogContent>
          <Typography variant="body1">
            Your session is going to be logged out in the next 2 minutes, due to
            inactivity.
          </Typography>
        </DialogContent>
        <DialogActions>
          <DCButton
            variant="contained"
            fullWidth
            size="medium"
            onClick={() => {
              setOpenModal(false);
            }}
          >
            I am here!
          </DCButton>
        </DialogActions>
      </DCDialog>
    </div>
  );
};

export default LogOutTimerModal;
