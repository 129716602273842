import { Box, Typography } from '@mui/material'
import theme, { DCButton } from '../../assets/theme/theme'

const Error = () => {

  // reload the page and send to "/"
  const reload = () => {  
    window.location.href = "/"
  }

  return (
    <Box sx={{ display: 'flex', justifyContent: "center", alignItems: "center", height: "100vh", flexDirection: "column", gap: 3, bgcolor : theme.palette.primary.light }}>
      <Typography component={'h1'} sx={{ fontSize: 36 }}>There is something wrong. Please retry!</Typography>
      <DCButton onClick={reload} variant="contained" color="primary">Retry</DCButton>
    </Box>
  )
}

export default Error