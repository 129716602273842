import { Box, Button, Collapse, Typography } from '@mui/material'
import React from 'react'
import theme, { DCPageSummary } from '../../assets/theme/theme'
import { useO1EB1DocumentsContext } from '../../contexts/O1EB1DocumentsContext'
import constants from '../../constants';
import { Close, Done } from '@mui/icons-material';
import ChevronUpIcon from '../Shared/Icons/ChevronUpIcon';
import ChevronDownIcon from '../Shared/Icons/ChevronDownIcon';
import { logEvent } from 'firebase/analytics';
import { analytics } from '../../firebase';
import { buildStyles, CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

const O1EB1Summary = () => {

  const { O1EB1Progress, O1EB1AutoFolders, O1EB1ManualFolders } = useO1EB1DocumentsContext();
  const [expanded, setExpanded] = React.useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
    logEvent(analytics, "o1eb1_summary_expand", { expanded });
  };

  return (
    // make the flex direction column if the screen size is small
    <Box sx={{ mt: -16, position: "relative", display: 'flex', gap: 2, 
      flexDirection: { xs: 'column', sm: 'row' }, justifyContent: 'center', width: '100%', zIndex: 1
     }}>
      <DCPageSummary>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '90%'}}>
          <Box sx={{p: 2}}>
              <Typography
                fontSize={20}
                lineHeight={1.4}
                fontWeight={500}
                component="h2"
                zIndex={1}
                color={theme.palette.primary.main}
              >
                O1 / EB1 <br />  Progress
              </Typography>
          </Box>
          <Box sx={{ px: 1, display: 'flex', height: 180, my: 0.85}}>
              <CircularProgressbar 
              value={O1EB1Progress} 
              text={`${O1EB1Progress}%`}
              styles={buildStyles({
                // Colors
                pathColor: theme.palette.primary.main,
                textColor: theme.palette.primary.main,
              })}
              />
          </Box>
        </Box>
      </DCPageSummary>
      <DCPageSummary>
        <Box sx={{ width: '100%'}}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%', px: 2 }}>
            <Box sx={{width: '100%'}}>
              <Typography fontSize={16} sx={{ mt: 1 }} fontWeight={600}>
                Main Documents
              </Typography>
              {constants.O1EB1FoldersListAuto.slice(0, 3).map((docType) => {
                return (
                  <Box mt={2}>
                    <Typography fontSize={14} sx={{ display: "flex", justifyItems: "center", gap: 1.5, whiteSpace: 'nowrap', alignItems: 'center' }}>
                      {O1EB1AutoFolders.filter(doc => doc.title === docType)[0].count > 0 ? (
                        <Done fontSize="small" sx={{ color: theme.palette.success.main }} />
                      ) : (
                        <Close fontSize="small" sx={{ color: theme.palette.error.main }} />
                      )}
                      {docType}
                    </Typography>
                  </Box>
                )
              })}
            </Box>
            <Box sx={{width: '100%'}}>
              <Typography fontSize={16} sx={{ mt: 1 }} fontWeight={600}>
                Supporting Documents
              </Typography>
              {constants.O1EB1FoldersListManual.slice(0, 3).map((docType) => {
                return (
                  <Box mt={2}>
                    <Typography fontSize={14} sx={{ display: "flex", justifyItems: "center", gap: 1, whiteSpace: 'nowrap' }}>
                      {O1EB1ManualFolders.filter(doc => doc.title === docType)[0].count > 0 ? (
                        <Done fontSize="small" sx={{ color: theme.palette.success.main }} />
                      ) : (
                        <Close fontSize="small" sx={{ color: theme.palette.error.main }} />
                      )}
                      {docType}
                    </Typography>
                  </Box>
                )
              })}
            </Box>
          </Box>
          <Collapse in={expanded} timeout="auto" unmountOnExit>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%', px: 2 }}>
              <Box sx={{width: '100%'}}>
                {constants.O1EB1FoldersListAuto.slice(3).map((docType) => {
                  return (
                    <Box mt={2}>
                      <Typography fontSize={14} sx={{ display: "flex", justifyItems: "center", gap: 1.5, whiteSpace: 'nowrap', alignItems: 'center' }}>
                        {O1EB1AutoFolders.filter(doc => doc.title === docType)[0].count > 0 ? (
                          <Done fontSize="small" sx={{ color: theme.palette.success.main }} />
                        ) : (
                          <Close fontSize="small" sx={{ color: theme.palette.error.main }} />
                        )}
                        {docType}
                      </Typography>
                    </Box>
                  )
                })}
              </Box>
              <Box sx={{width: '100%'}}>
                {constants.O1EB1FoldersListManual.slice(3).map((docType) => {
                  return (
                    <Box mt={2}>
                      <Typography fontSize={14} sx={{ display: "flex", justifyItems: "center", gap: 1, whiteSpace: 'nowrap' }}>
                        {O1EB1ManualFolders.filter(doc => doc.title === docType)[0].count > 0 ? (
                          <Done fontSize="small" sx={{ color: theme.palette.success.main }} />
                        ) : (
                          <Close fontSize="small" sx={{ color: theme.palette.error.main }} />
                        )}
                        {docType}
                      </Typography>
                    </Box>
                  )
                })}
              </Box>
            </Box>
          </Collapse>
          <Box sx={{ float: "left", marginTop: "5px" }}>
            <Button
              onClick={handleExpandClick}
              variant="contained"
              color="inherit"
              className="showmore-button"
              // stop all hover effects
              sx={{ height: 30, mt: 2, borderRadius: 15, "&:hover": { boxShadow: 0 } }}
            >
              Show {expanded ? "Less" : `More`}
              {expanded ? (
                <ChevronUpIcon sx={{ fontSize: 14, marginLeft: 1 }} />
              ) : (
                <ChevronDownIcon sx={{ fontSize: 14, marginLeft: 1 }} />
              )}
            </Button>
          </Box>
        </Box>
      </DCPageSummary>
    </Box>
  )
}

export default O1EB1Summary