import React from "react";
import { Box, Typography } from "@mui/material";
import theme from "../../assets/theme/theme";
import PDFIcon from "../../assets/images/icons/pdf_icon.png";
import { map } from "lodash";

interface Props {
  selectedFileType: string;
  selectedIndex: number;
  onPdfClick: (index: number) => void;
  onDeletePdfClick: (index: number) => void;
  pdfFiles: File[];
}

const PdfSidebar: React.FC<Props> = ({
  selectedIndex,
  selectedFileType,
  pdfFiles,
  onDeletePdfClick,
  onPdfClick,
}) => {
  const backgroundStyle = { backgroundColor: "#e2ebff" };

  const imageSideBarComponentStyle = {
    display: "flex",
    alignItems: "center",
    padding: "0.7rem 1rem 0.7rem 1rem",
    borderBottom: 1,
    borderColor: "grey.100",
    cursor: "pointer",
    "&:hover": { backgroundColor: "#e2ebff" },
    "&:active": { backgroundColor: "#e2ebff" },
  };

  return (
    <>
      {map(pdfFiles, (file, i) => {
        return (
          <Box
            key={i}
            sx={{
              ...imageSideBarComponentStyle,
              // Apply background style if selectedIndex === index
              ...(selectedIndex === i && selectedFileType === "pdf"
                ? backgroundStyle
                : {}),
            }}
            onClick={() => onPdfClick(i)}
          >
            <img
              src={PDFIcon}
              alt=""
              style={{
                width: 48,
                height: 48,
                marginRight: 10,
                borderRadius: 5,
              }}
            />
            <Box className="pdf-section">
              <Typography
                className="pdf-file-text"
                onClick={() => onPdfClick(i)}
                sx={{
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  cursor: "pointer",
                  width: 225,
                }}
              >
                {file.name}
              </Typography>
              <Typography
                component="span"
                onClick={() => onDeletePdfClick(i)}
                sx={{
                  textDecoration: "underline",
                  cursor: "pointer",
                  fontSize: "14px",
                  color: "grey.500",
                  "&:hover": { color: theme.palette.primary.main },
                }}
              >
                Delete
              </Typography>
            </Box>
          </Box>
        );
      })}
    </>
  );
};

export default PdfSidebar;
