import { Box, Typography, Tooltip, IconButton } from "@mui/material";
import { getEditorDefaults, blobToFile, ImageSource } from "@pqina/pintura";
import { PinturaEditor } from "@pqina/react-pintura";
import "@pqina/pintura/pintura.css";
import { logEvent } from "firebase/analytics";
import React, { useRef, useEffect, useCallback } from "react";
import { pdfjs } from "react-pdf";
import { analytics } from "../../firebase";
import DownloadIcon from "./Icons/DownloadIcon";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import PrintIcon from "@mui/icons-material/Print";

const DEFAULT_PAGE_NUMBER = 1;
const DEFAULT_NUMBER_OF_PAGES = 0;

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  "pdfjs-dist/build/pdf.worker.min.js",
  import.meta.url
).toString();

export interface PinturaPDFViewerProps {
  src: ImageSource;
  disablePrint?: boolean;
  disableDownload?: boolean;
  printProps?: {
    isPrintProcessing?: boolean;
    onPrintClick?: () => any;
  };
  downloadProps?: {
    isDownloadingDoc?: boolean;
    onDownloadClick?: () => any;
  };
}

export const PinturaPDFViewer = ({
  src,
  disablePrint,
  disableDownload,
  printProps,
  downloadProps,
}: PinturaPDFViewerProps) => {
  const [numPages, setNumPages] = React.useState(DEFAULT_NUMBER_OF_PAGES);
  const [pageNumber, setPageNumber] = React.useState(DEFAULT_PAGE_NUMBER);
  const viewerRef = useRef<any>(null);
  const previewWrapperRef = useRef<HTMLDivElement>(null);
  const [editorHeight, setEditorHeight] = React.useState("100%");

  useEffect(() => {
    document.documentElement.style.setProperty("--editor-height", "100%");
    setPageNumber(DEFAULT_PAGE_NUMBER);
    setNumPages(DEFAULT_NUMBER_OF_PAGES);
  }, [src]);

  const editorPdfconfig = getEditorDefaults({
    imageReader: {
      preprocessImageFile: async (
        file: File,
        options: any,
        onprogress: any
      ) => {
        // If is not a pdf we return the original file
        if (!/pdf$/.test(file.type)) return file;

        // let's convert the pdf to a png
        const pdf = await pdfjs.getDocument(URL.createObjectURL(file)).promise;

        const numOfpages = pdf.numPages;
        setNumPages(numOfpages);

        // get first page
        const page = await pdf.getPage(pageNumber);

        // get a scaled viewport for the pdf
        const viewport = page.getViewport({ scale: 5 });

        // create the target canvas to draw on
        const canvas = document.createElement("canvas");
        canvas.width = viewport.width;
        canvas.height = viewport.height;

        // ask pdfjs to draw to the canvas
        await page.render({
          canvasContext: canvas.getContext("2d") as CanvasRenderingContext2D,
          transform: [],
          viewport: viewport,
        }).promise;

        // we turn the canvas into a blob
        const blob: any = await new Promise((resolve) =>
          canvas.toBlob(resolve)
        );

        try {
          // Pintura Image Editor expects a File
          return blobToFile(blob, file.name);
        } catch (err) {
          console.error(err);
          return null;
        }
      },
    },
  });

  useEffect(() => {
    try {
      viewerRef.current?.editor?.updateImage(src);
    } catch (err) {
      console.error(err);
    }
  }, [pageNumber, src]);

  const changePage = useCallback(
    (offset: number) => {
      document.documentElement.style.setProperty("--editor-height", "100%");
      setPageNumber((prevPageNumber) => prevPageNumber + offset);
      logEvent(analytics, "page_change", {
        component: "pDFViewer",
        page: pageNumber + offset,
        src,
      });
    },
    [pageNumber, src]
  );

  const previousPage = useCallback(() => {
    changePage(-1);
  }, [changePage]);

  const nextPage = useCallback(() => {
    changePage(1);
  }, [changePage]);

  return (
    <Box
      className="page-number-preview-main"
      style={{
        height: "100%",
        width: "100%",
        overflow: "scroll",
      }}
      ref={previewWrapperRef}
    >
      <Box
      className="page-numbers-preview"
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
          px: "32px",
        }}
      >
        <Typography component="p">
          Page {pageNumber || "--"} of {numPages || "--"}
        </Typography>
        <Box>
          <Tooltip title="Previous page" arrow placement="top">
            <span>
              <IconButton
                className="page-number-buttons"
                aria-label="previous page dd"
                disabled={pageNumber <= 1}
                onClick={previousPage}
              >
                <NavigateBeforeIcon />
              </IconButton>
            </span>
          </Tooltip>
          <Tooltip title="Next page" arrow placement="top">
            <span>
              <IconButton
                className="page-number-buttons"
                aria-label="next page"
                disabled={pageNumber >= numPages}
                onClick={nextPage}
              >
                <NavigateNextIcon />
              </IconButton>
            </span>
          </Tooltip>
          {!disablePrint && (
            <Tooltip title="Print" arrow placement="top">
              <span>
                <IconButton
                  className="page-number-buttons"
                  aria-label="print document"
                  onClick={printProps?.onPrintClick}
                  // disabled={isPrintProcessing || !isDocFetched}
                  disabled={printProps?.isPrintProcessing}
                >
                  <PrintIcon />
                </IconButton>
              </span>
            </Tooltip>
          )}
          {!disableDownload && (
            <Tooltip title="Download" arrow placement="top">
              <span>
                <IconButton
                  className="page-number-buttons"
                  aria-label="download document"
                  // disabled={isDownloadingDoc || !isDocFetched}
                  disabled={downloadProps?.isDownloadingDoc}
                  onClick={downloadProps?.onDownloadClick}
                >
                  <DownloadIcon />
                </IconButton>
              </span>
            </Tooltip>
          )}
        </Box>
      </Box>
      <PinturaEditor
        key={pageNumber}
        {...editorPdfconfig}
        previewUpscale={true}
        src={src}
        ref={viewerRef}
        enableUtils={false}
        enableToolbar={false}
        enableButtonExport={false}
        cropEnableZoomInput={false}
        cropEnableRotationInput={false}
        cropEnableButtonFlipHorizontal={false}
        cropEnableButtonRotateLeft={false}
        cropEnableImageSelection={false}
        onReady={() => {
          console.log("onRenderReady************", editorHeight);
          document.documentElement.style.setProperty(
            "--editor-height",
            editorHeight
          );
        }}
        onLoad={({ size: { width, height } }) => {
          // Manually computing the preview document height considering the viewport dimension and document aspect ratio for maximum resolution
          if (previewWrapperRef?.current?.clientWidth) {
            // todo: update only if diff is more than +- 50
            setEditorHeight(
              `${
                (previewWrapperRef.current.clientWidth - 32) / (width / height)
              }px`
            );
            console.log(
              "onRenderLoadPreview: ",
              { width, height },
              (previewWrapperRef.current.clientWidth - 32) / (width / height)
            );
          }
        }}
      ></PinturaEditor>
    </Box>
  );
};
