import React, { useCallback, useState } from "react";
import {
  Grid,
  TextField,
  CardHeader,
  CardActionArea,
  Typography,
  Chip,
  Stack,
  Skeleton,
  CardContent,
  Tooltip,
  // styled,
  // tooltipClasses,
  // TooltipProps,
  useMediaQuery,
} from "@mui/material";
import theme, { DCDocumentCard } from "../../assets/theme/theme";
import { Box } from "@mui/system";
import { IDocument } from "../../firebaseTypes";
import { get, isEmpty, map } from "lodash";
import { useDocumentGalleryContext } from "../../contexts/DocumentGalleryContext";
import DocumentOptionsMenu from "../DocumentOptionsMenu";
import {
  getKeyForThumbnailUrl,
  useDocumentContext,
} from "../../contexts/DocumentContext";
import {
  getExpiryFieldValue,
  getDisplayValue,
  toDocTypeSlug,
  getDocName,
} from "../../utils/documentUtils";

import { usePdfPreviewModalContext } from "../../contexts/PdfPreviewModalContext";
import { isFuture } from "date-fns";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import {
  feat_expired,
  feat_latest,
} from "../../utils/docTypeFeatureToggleUtils";
import constants from "../../constants";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import CardCheckbox from "../Shared/DCCardCheckBox";

import { maxLimitMsg, forbidSpecialCharMsg } from "../Shared/InputValidation";
import InfoIcon from "../Shared/Icons/InfoIcon";
import { isValidDateString, storageGetItem } from "../../utils/utils";

export interface Props { }

// const truncate = (text: string, len: number): string => {
//   return len < text.length ? take(text, len).join("") + "..." : text;
// };

const O1EB1DocumentsGrid: React.FC<Props> = (props) => {
  const { folderInView } = useDocumentGalleryContext();

  if (!folderInView) return null;

  return (
    <Box>
      <Grid
        className="document-grid-main"
        container
        spacing={3}
        columns={{ xs: 4, sm: 8, md: 12, lg: 12, xl: 12 }}
      >
        {map(folderInView.docs, (doc, i) => {
          return (
            <Grid
              item
              xs={4}
              sm={8}
              md={6}
              lg={4}
              xl={3}
              display="flex"
              key={`${folderInView.docType} ${doc.id}`}
            >
              <DocumentCard document={doc} index={i} />
            </Grid>
          );
        })}
      </Grid>
    </Box>
  );
};
export default O1EB1DocumentsGrid;

function calculateDocNameEditableMaxWidth(
  _: boolean,
  matchUpSm: boolean,
  matchUpMd: boolean,
  matchUpLg: boolean,
  matchUpXl: boolean
): string {
  if (matchUpXl) {
    return "260px";
  }
  if (matchUpLg) {
    return "250px";
  }
  if (matchUpMd) {
    return "240px";
  }
  if (matchUpSm) {
    return "230px";
  }
  return "230px";
}

function DocumentNameEditable(props: { document: IDocument }) {
  const [openEditMode, setOpenEditMode] = React.useState(false);
  const inputRef = React.useRef(null as any);
  const [isSaving, setIsSaving] = React.useState(false);
  const { updateDocumentInTransaction } = useDocumentContext();

  const maxLimit = constants.inputText.maxInputLimit;
  const [isCrossedMaxLimit, setIsCrossedMaxLimit] = useState<boolean>(false);
  const [isSpecialChar, setIsSpecialChar] = useState<boolean>(false);
  const specialChars = constants.inputText.specialChars;
  const matchUpXs = useMediaQuery(theme.breakpoints.up("xs"));
  const matchUpSm = useMediaQuery(theme.breakpoints.up("sm"));
  const matchUpMd = useMediaQuery(theme.breakpoints.up("md"));
  const matchUpLg = useMediaQuery(theme.breakpoints.up("lg"));
  const matchUpXl = useMediaQuery(theme.breakpoints.up("xl"));
  const [maxWidth, setMaxWidth] = React.useState(
    calculateDocNameEditableMaxWidth(
      matchUpXs,
      matchUpSm,
      matchUpMd,
      matchUpLg,
      matchUpXl
    )
  );

  React.useEffect(() => {
    setMaxWidth(
      calculateDocNameEditableMaxWidth(
        matchUpXs,
        matchUpSm,
        matchUpMd,
        matchUpLg,
        matchUpXl
      )
    );
  }, [matchUpXs, matchUpSm, matchUpMd, matchUpLg, matchUpXl]);

  const onDocNameSave = async () => {
    if (!isSpecialChar && isSpecialChar !== undefined) {
      setIsSaving(true);
      await updateDocumentInTransaction(
        props.document,
        () => {
          return {
            doc_name: inputRef.current.value || props.document.doc_name,
            f_manually_edited_doc_name: true,
          };
        },
        false
      );
      setIsSaving(false);
      setOpenEditMode(false);
    }
  };

  return openEditMode ? (
    <>
      <TextField
        inputRef={inputRef}
        spellCheck={false}
        disabled={isSaving}
        sx={{ marginTop: 1 }}
        onKeyPress={(evt) => {
          if (evt.key === "Enter") onDocNameSave();
        }}
        onBlur={onDocNameSave}
        defaultValue={getDocName(props.document)}
        onChange={(evt: React.ChangeEvent<HTMLInputElement>) => {
          evt.target.value?.match(specialChars)
            ? setIsSpecialChar(true)
            : setIsSpecialChar(false);
        }}
        onInput={(evt: React.ChangeEvent<HTMLInputElement>) => {
          evt.target.value = evt.target.value.slice(0, maxLimit);
          evt.target.value.length === maxLimit
            ? setIsCrossedMaxLimit(true)
            : setIsCrossedMaxLimit(false);
        }}
        inputProps={{
          min: 0,
          style: { lineHeight: "2", fontSize: 22 },
        }}
        required
        size="small"
        variant="standard"
        autoFocus
        placeholder="Document Name"
        fullWidth={false}
        helperText={
          isCrossedMaxLimit
            ? maxLimitMsg()
            : isSpecialChar
              ? forbidSpecialCharMsg()
              : null
        }
      />
    </>
  ) : (
    <Box
      display={"flex"}
      alignItems={"center"}
      sx={{
        maxWidth,
        cursor: "pointer",
        textOverflow: "ellipsis",
        overflow: "hidden",
        "&:hover": {
          color: theme.palette.primary.main,
          ".hover-edit-icon": {
            display: "inline",
          },
        },
        "& .hover-edit-icon": {
          display: "none",
          float: "right",

          fontSize: "22px",
          marginLeft: 0.5,
          marginTop: "2px",
        },
      }}
      onClick={() => setOpenEditMode(true)}
    >
      <Typography fontSize={22} noWrap>
        {getDocName(props.document)}
      </Typography>
      <EditOutlinedIcon className="hover-edit-icon" />
    </Box>
  );
}

function DocumentCard({
  document,
  index,
}: {
  document: IDocument;
  index: number;
}) {
  const { cache } = useDocumentContext();
  const { selectDeselectDocument, isDocumentSelected } =
    useDocumentGalleryContext();
  const { openModalForDocument } = usePdfPreviewModalContext();
  const isSelected = isDocumentSelected(document);
  // const tooltip = `Preview ${document.doc_name}`
  const [showCheckboxState, setShowCheckboxState] = useState(false);

  const showCheckbox = useCallback(() => {
    setShowCheckboxState(true);
  }, []);

  const hideCheckbox = useCallback(() => {
    setShowCheckboxState(false);
  }, []);

  const onCheckboxClick = useCallback(
    (evt: any) => {
      evt.stopPropagation();
      selectDeselectDocument(document);
    },
    [document, selectDeselectDocument]
  );

  const onCardClick = useCallback(
    (evt: any) => {
      evt.stopPropagation();
      openModalForDocument(document);
    },
    [document, openModalForDocument]
  );

  const imageSrc =
    get(cache, getKeyForThumbnailUrl(document.id)) ||
    storageGetItem(getKeyForThumbnailUrl(document.id));

  const expiryDateStr = getExpiryFieldValue(document.doc_info);
  const expiryDate = isValidDateString(expiryDateStr)
    ? new Date(expiryDateStr.replace(/-/g, "/"))
    : null;
  const expirySeconds = expiryDate ? expiryDate.getTime() : 0;
  const expiryDisplayValue = getDisplayValue(expiryDateStr);
  const isExpiryInFuture = expiryDate && isFuture(expiryDate);

  return (
    <DCDocumentCard
      className="document-cards"
      sx={{
        minWidth: "320px",
        // maxWidth: "380px",
        "& .MuiCardHeader-action": {
          alignSelf: "end",
        },
      }}
      variant="outlined"
      onMouseLeave={hideCheckbox}
      onMouseEnter={showCheckbox}
    >
      {/* <Tooltip title={tooltip} arrow followCursor placement="top"> */}
      <CardActionArea onClick={onCardClick}>
        <CardCheckbox
          show={showCheckboxState}
          checked={isSelected}
          onClick={onCheckboxClick}
        />

        {imageSrc ? (
          <Box
            sx={{
              height: "222px",
              textAlign: "center",
              // background: "rgb(231, 235, 240)"
              background: `url('${imageSrc}')`,
              backgroundRepeat: "no-repeat",
              backgroundSize: `cover`,
            }}
          >
            {/* <img src={imageSrc}
            alt={tooltip}
            height="222"
            style={{
              maxWidth: "320px"
            }} /> */}
          </Box>
        ) : (
          <Skeleton variant="rectangular" height={222} />
        )}
      </CardActionArea>
      {/* </Tooltip> */}
      <CardContent
        className="document-card-contain"
        sx={{
          pb: 0,
          pt: 1.5,
        }}
      >
        <Typography color={theme.palette.text.secondary} variant="caption">
          {/* <PassportIcon fontSize='small' viewBox='0 0 34.399 34.399' sx={{
              mr: 1
            }} /> */}
          {document.doc_type}
        </Typography>
      </CardContent>
      <CardHeader
        sx={{
          overflowWrap: "anywhere",
          pt: 0,
          pb: 3,
          width: "100%"
        }}
        // action={
        //   <DocumentOptionsMenu
        //     document={document}
        //     showMoveTo={
        //       toDocTypeSlug(document.doc_type) === constants.docTypeSlugs.others
        //     }
        //   />
        // }
        title={
          <Box>
            <Box
              display="flex"
              alignItems={"center"}
              justifyContent="space-between"
              width="100%"
            >
              {isEmpty(getDocName(document)) ? (
                <Skeleton variant="text" />
              ) : (
                <DocumentNameEditable document={document} />
              )}
              <DocumentOptionsMenu
                document={document}
                showMoveTo={
                  toDocTypeSlug(document.doc_type) ===
                  constants.docTypeSlugs.others
                }
              />
            </Box>
            <Stack
              direction="row"
              alignItems={"center"}
              spacing={0.5}
              pt={"5px"}
            >
              {feat_latest(document.doc_type) &&
                expirySeconds > 0 &&
                isExpiryInFuture &&
                index === 0 && (
                  <Tooltip
                    title={`Valid until ${expiryDisplayValue}`}
                    arrow
                    placement="top"
                  >
                    <Chip
                      label={<Typography variant="caption">Latest</Typography>}
                      variant="outlined"
                      icon={
                        <Box color="none" display="inline-flex">
                          <CheckCircleOutlineIcon
                            color="success"
                            fontSize="small"
                          />
                        </Box>
                      }
                    ></Chip>
                  </Tooltip>
                )}
              {feat_expired(document.doc_type) &&
                expirySeconds > 0 &&
                !isExpiryInFuture && (
                  <Tooltip
                    title={`Expired on ${expiryDisplayValue}`}
                    arrow
                    placement="top"
                  >
                    <Chip
                      label={<Typography variant="caption">Expired</Typography>}
                      variant="outlined"
                      icon={
                        <Box color="none" display="inline-flex">
                          <InfoIcon color="error" fontSize="small" />
                        </Box>
                      }
                    ></Chip>
                  </Tooltip>
                )}
              {/* O1 / EB1 Documents tags  */}
              {document.tags && document.tags.some((tag) => constants.tagKeys.includes(tag._id.split('/')[1])) && (
                <Tooltip
                  title={`O1 / EB1 Document`}
                  arrow
                  placement="top"
                >
                  <Chip
                    label={<Typography variant="caption">O1 / EB1</Typography>}
                    variant="outlined"
                    icon={
                      <Box color="none" display="inline-flex">
                        <InfoIcon color="primary" fontSize="small" />
                      </Box>
                    }
                  ></Chip>
                </Tooltip>
              )}
              {/* {feat_ownership(document.doc_type) && !document.doc_ownership && (
                <OwnershipMarker document={document} />
              )} */}
              {/* {document.doc_ownership && <Chip label="My document" variant='outlined'
              avatar={<DCAvatar
                name={get(currentUser, 'displayName', 'No name') as string}
                imgSrc={get(currentUser, 'photoURL') as string}
              />} />} */}
            </Stack>
          </Box>
        }
      ></CardHeader>
      {/* <CardContent sx={{
      pt: "0px",
    }}>
      {isExpiryInFuture && <Chip label="latest" color="primary" variant="outlined"></Chip>}
    </CardContent> */}
    </DCDocumentCard>
  );
}

export interface IOwnershipMarker {
  document: IDocument;
}

// const NoMaxWidthTooltip = styled(({ className, ...props }: TooltipProps) => (
//   <Tooltip {...props} classes={{ popper: className }} />
// ))({
//   [`& .${tooltipClasses.tooltip}`]: {
//     backgroundColor: theme.palette.common.white,
//     boxShadow: theme.shadows[3],
//     maxWidth: "none",
//     padding: 24,
//     textAlign: "center",
//     borderRadius: 16,
//     color: theme.palette.text.primary,
//   },
// });

// function OwnershipMarker(props: IOwnershipMarker) {
//   const { user } = useUserContext();
//   const [open, setOpen] = React.useState(false);
//   const [isSavingDoc, setIsSavingDoc] = React.useState(false);
//   const { updateDocumentInTransaction } = useDocumentContext();

//   const handleClose = React.useCallback(() => {
//     setOpen(false);
//   }, []);

//   const handleOpen = React.useCallback(() => {
//     setOpen(true);
//   }, []);

//   const handleChecked = async (evt: any) => {
//     const checked = true; //evt.target.checked;
//     setIsSavingDoc(true);
//     await updateDocumentInTransaction(
//       props.document,
//       (current) => {
//         return { doc_ownership: checked };
//       },
//       false
//     );
//     setIsSavingDoc(false);
//   };

//   return (
//     <NoMaxWidthTooltip
//       open={open || isSavingDoc}
//       onClose={handleClose}
//       onOpen={handleOpen}
//       placement="top"
//       title={
//         <>
//           <Box
//             sx={{
//               bgcolor: theme.palette.primary.light,
//               width: 32,
//               height: 32,
//               borderRadius: 40,
//               display: "flex",
//               alignItems: "center",
//               justifyContent: "center",
//               color: theme.palette.error.main,
//               mx: "auto",
//               mb: 2,
//             }}
//           >
//             <WarningOutlineIcon fontSize="small" color="primary" />
//           </Box>
//           <Stack flexDirection="row" alignItems="center" gap={1}>
//             <Typography
//               sx={{
//                 color: theme.palette.primary.main,
//               }}
//               fontSize={16}
//             >
//               {user?.firstName}, looks like this is not your document!
//             </Typography>
//           </Stack>
//           <Box>
//             <FormControlLabel
//               sx={{
//                 // background: 'red',
//                 m: "0",
//                 px: 1.5,
//                 font: "inherit",
//               }}
//               value="start"
//               control={
//                 <Box>
//                   <LoadingButton
//                     sx={{ mt: 2 }}
//                     loadingPosition="center"
//                     variant="contained"
//                     loading={isSavingDoc}
//                     onClick={handleChecked}
//                   >
//                     Confirm Ownership
//                   </LoadingButton>
//                 </Box>
//               }
//               label={<>Do you want to mark this as your document?</>}
//               labelPlacement="top"
//             />
//           </Box>
//         </>
//       }
//     >
//       <span>
//         {
//           <IconButton
//             disabled={isSavingDoc}
//             sx={{
//               color: theme.palette.primary.main,
//             }}
//           >
//             <WarningOutlineIcon fontSize="small" />
//           </IconButton>
//         }
//       </span>
//     </NoMaxWidthTooltip>
//   );
// }
