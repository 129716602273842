import {
  Dialog,
  DialogContent,
  DialogTitle,
  Box,
  Typography,
  Alert,
  DialogActions,
} from "@mui/material";
import React, { useCallback } from "react";
import theme, { DCButton, DcIconButton } from "../../assets/theme/theme";
import "react-calendar/dist/Calendar.css";
import CloseIcon from "@mui/icons-material/Close";
import ImageSidebar from "./ImageSidebar";
import PdfSidebar from "./PdfSidebar";
import { useDocumentUploadContext } from "../../contexts/DocumentUploadContext";
import constants from "../../constants";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import { DCTooltip } from "../Shared/DCTooltip";
import { get } from "lodash";
import { PinturaPDFViewer } from "../Shared/PinturaPDFViewer";
import { PinturaImageViewer } from "../Shared/PinturaImageViewer";
import { useDocumentContext } from "../../contexts/DocumentContext";

const MAX_LIMIT_UPLOAD_BTN_TOOLTIP = "Max limit for file upload exceeds.";
const EDIT_MODE_UPLOAD_BTN_TOOLTIP =
  "You are in edit mode. Finish editing to start upload.";

export interface Props {
  modalState: boolean;
  handleModalClose: () => void;
}

const ImageUploadModal: React.FC<Props> = ({
  modalState,
  handleModalClose,
}) => {
  const {
    imageFiles,
    pdfFiles,
    makeFinalUploadRequest,
    handleDeletePdf,
    handleDeleteImage,
    imgPreview,
  } = useDocumentUploadContext();
  // base64 encoded data url
  const [selectedImg, setSelectedImg] = React.useState<string>("");
  const [selectedPdf, setSelectedPdf] = React.useState<string>("");
  const [index, setIndex] = React.useState<number>(0);
  const [indexPdf, setIndexPdf] = React.useState<number>(0);
  const [selectedFileType, setSelectedFileType] =
    React.useState<string>("image");
  const [editState, setEditState] = React.useState(false);
  // const [completedCrop, setCompletedCrop] = React.useState<PixelCrop>();
  const [errorReadingFileFromDisk, setErrorReadingFileFromDisk] =
    React.useState(false);
  const { documents } = useDocumentContext();

  const resetModalState = useCallback(() => {
    setSelectedImg("");
    setSelectedPdf("");
    setIndex(0);
    setIndexPdf(0);
    setSelectedFileType("image");
    setEditState(false);
    setErrorReadingFileFromDisk(false);
  }, []);

  const onClose = React.useCallback(
    (evt: any, reason: string) => {
      // reason could be "backdropClick" | "escapeKeyDown"
      if (reason === "escapeKeyDown" || reason === "backdropClick") {
        return;
      }
      handleModalClose();
    },
    [handleModalClose]
  );

  const handleChangeImage = React.useCallback(
    async (i: number) => {
      try {
        if (get(imageFiles, [i])) {
          setIndex(i);
          setErrorReadingFileFromDisk(false);
          const reader = new FileReader();
          reader.onload = () => {
            if (reader.error) {
              setErrorReadingFileFromDisk(true);
              return;
            }
            setSelectedImg(reader.result as string);
            setSelectedFileType("image"); //if Image Url is found then setting the view to image view
          };
          reader.readAsDataURL(imageFiles[i]);
        }
      } catch (err) {
        console.error(err);
      }
    },
    [imageFiles]
  );

  const onImageEditFinish = useCallback(
    (imgUrl: string, imgFile: File) => {
      imgPreview(imageFiles[index], index, imgUrl, imgFile);
      handleChangeImage(index);
      setEditState(false);
    },
    [handleChangeImage, imageFiles, imgPreview, index]
  );

  const onImageEditModeEnter = useCallback(() => {
    setEditState(true);
  }, []);

  const handleChangePdf = React.useCallback(
    async (index: number) => {
      try {
        if (get(pdfFiles, [index])) {
          setIndexPdf(index);
          setErrorReadingFileFromDisk(false);
          const reader = new FileReader();
          reader.onload = () => {
            if (reader.error) {
              setErrorReadingFileFromDisk(true);
              return;
            }
            setSelectedPdf(reader.result as string);
            setSelectedFileType("pdf"); //if Image Url is found then setting the view to image view
          };
          reader.readAsDataURL(pdfFiles[index]);
        }
      } catch (err) {
        console.error(err);
      }
    },
    [pdfFiles]
  );

  React.useEffect(() => {
    console.log("from use effect", {
      imageFiles,
      pdfFiles,
    });
    //Load the very first file
    if (imageFiles.length > 0) {
      // load zero'th index
      handleChangeImage(0);
    } else if (pdfFiles.length > 0) {
      handleChangePdf(0);
    }
    // modalState is intentional here. becuase imageFiles and pdfFiles doesn't get cleared when closing the modal in docuploadcontext.
  }, [imageFiles.length, pdfFiles.length, modalState]);

  React.useEffect(() => {
    // handle delete all files
    if (imageFiles.length === 0 && pdfFiles.length === 0) {
      handleModalClose();
      return;
    }
  }, [handleModalClose, imageFiles.length, pdfFiles.length]);

  const maxLimitError =
    pdfFiles.length + imageFiles.length > constants.maxLimitToUploadDocAtTime;

  return (
    <Dialog
      disableEscapeKeyDown
      open={modalState}
      onClose={onClose}
      scroll="paper"
      aria-labelledby=""
      aria-describedby=""
      sx={{
        "& .MuiDialog-container": {
          "& .MuiDialog-paper": {
            height: "800px",
            width: "100%",
            maxWidth: "1200px",
          },
        },
        ".App .PinturaUtilMain, .PinturaUtilPanel, .PinturaUtilFooter": {
          padding: 0,
        },
        ".App .PinturaRootWrapper": {
          display: "flex",
          alignItems: "flex-start",
          padding: "0 16px",
        },
        ".imageEditor .PinturaRoot": {
          height: "100%",
        },
        ".imageEditor .PinturaNavTools": {
          paddingTop: "0",
        },
        ".PinturaUtilFooter>[slot]:empty": {
          minHeight: "4px",
        },
      }}
    >
      <DialogTitle
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        Preview Documents
        <DcIconButton
          onClick={() => {
            handleModalClose();
            resetModalState();
          }}
        >
          <CloseIcon />
        </DcIconButton>
      </DialogTitle>
      <Box>
        {maxLimitError && (
          <Alert
            variant="outlined"
            severity="warning"
            sx={{
              marginLeft: "20px",
              marginRight: "20px",
              marginBottom: "20px",
              marginTop: "0px",
            }}
          >
            <Typography
              sx={{
                fontSize: "13px",
                fontWeight: 500,
              }}
            >
              Max limit for file selection is{" "}
              {constants.maxLimitToUploadDocAtTime}. Please delete the rest from
              the list.
            </Typography>
          </Alert>
        )}
      </Box>
      <DialogContent sx={{ padding: "0 !important", overflow: "hidden" }} className="document-upload-main-section">
        <Box display="flex" height="100%" className="document-upload-inner">
          <Box
            className="pdf-upload"
            sx={{
              width: "30%",
              borderRight: 1,
              borderColor: "grey.100",
              overflowY: "scroll",
              px: 3,
              pb: 8,
            }}
          >
            <Box
              sx={{
                padding: "1rem 0",
                borderBottom: 1,
                borderColor: "grey.100",
                position: "sticky",
                top: 0,
                background: "white",
                zIndex: 5,
              }}
            >
              <Typography
                sx={{
                  font: "inherit",
                  fontWeight: 500,
                  color: theme.palette.text.primary,
                }}
              >
                DOCUMENTS TO UPLOAD ({pdfFiles.length + imageFiles.length})
              </Typography>
            </Box>
            <ImageSidebar
              selectedIndex={index}
              selectedFileType={selectedFileType}
              onImageClick={handleChangeImage}
              onDeleteImageClick={handleDeleteImage}
              imageFiles={imageFiles}
            />
            {pdfFiles.length > 0 && (
              <Alert severity="info" sx={{ mb: 1 }}>
                PDF Files can not be edited
              </Alert>
            )}
            <Box className="pdf-siderbar">
              <PdfSidebar
                selectedIndex={indexPdf}
                selectedFileType={selectedFileType}
                onPdfClick={handleChangePdf}
                onDeletePdfClick={handleDeletePdf}
                pdfFiles={pdfFiles}
              />
            </Box>
          </Box>

          {/*//////////////// Right Part of Modal /////////////*/}
          <Box
            className="pdf-upload-text"
            sx={{
              width: "70%",
            }}
          >
            {errorReadingFileFromDisk ? (
              <Alert variant="filled" sx={{ mx: 4, my: 2 }} severity="error">
                Error reading file from disk.
              </Alert>
            ) : selectedFileType === "pdf" ? (
              <PinturaPDFViewer
                src={selectedPdf}
                disableDownload={true}
                disablePrint={true}
              />
            ) : (
              <PinturaImageViewer
                src={selectedImg}
                editProps={{
                  onEditFinish: onImageEditFinish,
                  onEditModeEnter: onImageEditModeEnter,
                }}
              />
            )}
          </Box>
        </Box>
      </DialogContent>
      <DialogActions sx={{ paddingY: "20px" }}>
        <DCTooltip
          title={
            (editState && EDIT_MODE_UPLOAD_BTN_TOOLTIP) ||
            (maxLimitError && MAX_LIMIT_UPLOAD_BTN_TOOLTIP)
          }
          hide={!editState || !maxLimitError}
        >
          <Box sx={{display: "flex", alignItems: "center", gap: 2}}>
            <Typography color="red">
              { documents.length + pdfFiles.length + imageFiles.length > 50 && "You have reached the maximum limit for file upload 50 documents."}
            </Typography>
            <DCButton
              variant="contained"
              size="medium"
              // todo:
              disabled={editState || maxLimitError || documents.length + pdfFiles.length + imageFiles.length > 50}
              startIcon={<FileUploadIcon />}
              onClick={() => {
                makeFinalUploadRequest();
                setSelectedImg("");
                setSelectedPdf("");
                setSelectedFileType("image");
              }}
            >
              Start Upload
            </DCButton>
          </Box>
        </DCTooltip>
      </DialogActions>
    </Dialog>
  );
};

export default ImageUploadModal;

export interface PinturaEditorWrapperProps {
  // url is a base64data url of image or pdf file
  url: string;
  fileType: "image" | "pdf";
  onImageEditFinish: (imgUrl: string, imgFile: File) => any;
}
