import React, {
  ChangeEvent,
  FC,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { FilterList } from "@mui/icons-material";
import {
  Box,
  Badge,
  Popover,
  Checkbox,
  ListItemText,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
  Button,
  IconButton,
} from "@mui/material";
import { DcInput } from "../../assets/theme/theme";
import { DCFormLabel } from "../Shared/DCFormLabel";
import { differenceInCalendarYears, differenceInDays } from "date-fns";
import { ITravelHistoryExtended } from "../../firebaseTypes";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export type ITravelFilterSummary = { year: string; value: number };

interface Props {
  travels: ITravelHistoryExtended[];
  onFilter(travels: ITravelHistoryExtended[], summary: ITravelFilterSummary[]): void;
}
export const TravelHistoryTableFilterNew: FC<Props> = ({ travels, onFilter }) => {
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [isBadgeVisible, setIsBadgeVisible] = useState(false);
  const [yearsHasError, setYearsHasError] = useState(false);
  const [search, setSearch] = useState("");
  const filterButtonRef = useRef(null);
  const [selectedYears, setSelectedYears] = React.useState<string[]>([]);

  const years = useMemo(() => {
    const yearsSet = new Set<string>();
    travels.forEach((t) => {
      t.arrival_date_parsed && yearsSet.add(t.arrival_date_parsed.getFullYear().toString());
      t.departure_date_parsed &&
        yearsSet.add(t.departure_date_parsed.getFullYear().toString());
    });
    const yearsArr = Array.from(yearsSet).sort();
    setSelectedYears(["ALL", ...yearsArr]);
    return yearsArr;
  }, [travels]);

  const onOpenFilter = useCallback(() => {
    setIsFilterOpen(true);
  }, []);

  const onCloseFilter = useCallback(() => {
    setIsFilterOpen(false);
  }, []);

  const onChangeSearch = useCallback(
    ({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
      setSearch(value);
    },
    []
  );

  const isAllYearsSelected = useCallback(
    (arr: string[]): boolean => {
      return years.every((y) => arr.indexOf(y) > -1);
    },
    [years]
  );

  const handleChangeYears = useCallback(
    ({ target: { value } }: SelectChangeEvent<typeof selectedYears>) => {
      if (yearsHasError) {
        setYearsHasError(false);
      }

      const yearsArr = typeof value === "string" ? value.split(",") : value;

      if (selectedYears.indexOf("ALL") > -1 && yearsArr.indexOf("ALL") === -1) {
        return setSelectedYears([]);
      } else if (
        selectedYears.indexOf("ALL") === -1 &&
        yearsArr.indexOf("ALL") > -1
      ) {
        return setSelectedYears(["ALL", ...years]);
      }

      if (isAllYearsSelected(yearsArr)) {
        yearsArr.unshift("ALL");
      } else if (yearsArr.indexOf("ALL") > -1) {
        yearsArr.shift();
      }
      setSelectedYears(yearsArr);
    },
    [isAllYearsSelected, selectedYears, years, yearsHasError]
  );

  const getFilterSummary = (
    filteredTravels: ITravelHistoryExtended[]
  ): ITravelFilterSummary[] => {
    const summary: ITravelFilterSummary[] = [];
    filteredTravels.forEach((travel) => {
      if (travel.arrival_date && travel.departure_date) {
        const travelArrival = travel.arrival_date_parsed;
        const travelDeparture = travel.departure_date_parsed;

        const arrivalYear = travelArrival
          ? travelArrival.getFullYear().toString()
          : "";

        const yearsDiff = differenceInCalendarYears(
          travelDeparture as Date,
          travelArrival as Date
        );

        if (yearsDiff > 0) {
          for (let i = 0; i < yearsDiff + 1; i++) {
            const from =
              i === 0 ? travelArrival : new Date(+arrivalYear + i, 0, 1);

            const to =
              i === yearsDiff
                ? travelDeparture
                : new Date(+arrivalYear + i, 11, 31);

            const fromYear = from ? from.getFullYear().toString() : "";
            const existYear = summary.find((c) => c.year === fromYear);

            if (existYear) {
              existYear.value += differenceInDays(to as Date, from as Date) + 1;
            } else {
              summary.push({
                year: fromYear,
                value: differenceInDays(to as Date, from as Date) + 1,
              });
            }
          }
        } else {
          const existCurrentYear = summary.find((c) => c.year === arrivalYear);
          if (existCurrentYear) {
            existCurrentYear.value +=
              differenceInDays(travelDeparture as Date, travelArrival as Date) +
              1;
          } else {
            summary.push({
              year: arrivalYear,
              value:
                differenceInDays(
                  travelDeparture as Date,
                  travelArrival as Date
                ) + 1,
            });
          }
        }
      }
    });

    return summary.sort((a, b) => +a.year - +b.year);
  };

  const onApply = useCallback(
    (_search = search, _selectedYears = selectedYears) => {
      if (_selectedYears.length === 0) {
        return setYearsHasError(true);
      }

      setIsFilterOpen(false);

      if (_search.length === 0 && isAllYearsSelected(_selectedYears)) {
        setIsBadgeVisible(false);
        return onFilter([...travels], []);
      }

      setIsBadgeVisible(true);
      const searchTxt = _search.toLocaleLowerCase();

      const filteredTravels = travels.filter((travel) => {
        let searchInc = true;
        if (searchTxt) {
          searchInc =
            (travel.arrival_loc_label || "")
              .toLocaleLowerCase()
              .includes(searchTxt) ||
            (travel.departure_loc_label || "")
              .toLocaleLowerCase()
              .includes(searchTxt) ||
            (travel.visa_status || "").toLocaleLowerCase().includes(searchTxt);
        }

        let yearInc = false;
        if (travel.departure_date) {
          const travelDepartureYear = travel.departure_date_parsed
            ? travel.departure_date_parsed.getFullYear().toString()
            : "";
          yearInc = _selectedYears.includes(travelDepartureYear);
        }

        if (travel.arrival_date) {
          const travelArrivalYear = travel.arrival_date_parsed
            ? travel.arrival_date_parsed.getFullYear().toString()
            : "";
          yearInc = yearInc || _selectedYears.includes(travelArrivalYear);
        }

        return searchInc && yearInc;
      });

      const summary = getFilterSummary(filteredTravels).filter((s) =>
        _selectedYears.includes(s.year)
      );

      return onFilter(filteredTravels, summary);
    },
    [isAllYearsSelected, onFilter, search, selectedYears, travels]
  );

  const clear = useCallback(() => {
    setSearch("");
    setSelectedYears(["ALL", ...years]);
    onApply("", years);
  }, [onApply, years]);

  useEffect(() => {
    onApply();
    // eslint-disable-next-line
  }, [travels]);

  return (
    <Box className="filter-popover">
      <IconButton ref={filterButtonRef} onClick={onOpenFilter}>
        <Badge color="primary" variant="dot" invisible={!isBadgeVisible}>
          <FilterList />
        </Badge>
      </IconButton>
      <Popover
        className="filter-wrapper"
        open={isFilterOpen}
        anchorEl={filterButtonRef.current}
        onClose={onCloseFilter}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <Box className="filter-popover">
          <Box
            className="filter-model"
            display="flex"
            flexDirection="column"

            width={500}
            rowGap={2}
            padding={3}
          >
            <Box>
              <Typography variant="h4" fontSize={20} fontWeight={500}>Filter</Typography>
            </Box>
            <Box>
              <DCFormLabel htmlFor="visa-status">Search</DCFormLabel>
              <Box>
                <DcInput
                  fullWidth
                  value={search}
                  onChange={onChangeSearch}
                  placeholder="Search in Port Of Entry, Port Of Exit or Visa Type"
                />
              </Box>
            </Box>

            <Box>
              <DCFormLabel htmlFor="visa-status">Select Years</DCFormLabel>
              <Box>
                <Select
                  multiple
                  fullWidth
                  error={yearsHasError}
                  value={selectedYears}
                  onChange={handleChangeYears}
                  input={<DcInput />}
                  renderValue={(selected) => {
                    if (selected.some((s) => s === "ALL")) {
                      return "ALL";
                    }
                    return selected.join(", ");
                  }}
                  MenuProps={MenuProps}
                >
                  <MenuItem key="ALL" value="ALL">
                    <Checkbox checked={selectedYears.indexOf("ALL") > -1} />
                    <ListItemText primary="ALL" />
                  </MenuItem>
                  {years.map((year) => (
                    <MenuItem key={year} value={year}>
                      <Checkbox checked={selectedYears.indexOf(year) > -1} />
                      <ListItemText primary={year} />
                    </MenuItem>
                  ))}
                </Select>
              </Box>
            </Box>
            <Box display="flex" justifyContent="flex-end" columnGap={2}>
              <Button variant="outlined" onClick={clear}>
                Clear
              </Button>
              <Button variant="contained" onClick={() => onApply()}>
                Apply
              </Button>
            </Box>
          </Box>
          </Box>
      </Popover>
    </Box>
  );
};
