import React from "react"

export interface Props {
}

const ForgotPassword: React.FC<Props> = (props) => {
  return (
    <>ForgotPassword</>
  )
}

export default ForgotPassword
