import React from "react"
import ScanningTipsModal from "../components/ScanningTipsModal"

export interface IDocumentDeleteModalContext {
  // host
  openScanningTipsModal: (duration?: number) => any
}

const defaultValues: IDocumentDeleteModalContext = {
  openScanningTipsModal: () => { },
}

const context = React.createContext(defaultValues)

export function useScanningTipsModalContext() {
  return React.useContext(context)
}

export const ScanningTipsModalProvider: React.FC = (props) => {
  const [open, setOpen] = React.useState(false)

  const onClose = React.useCallback(() => {
    setOpen(false)
  }, [])

  const openScanningTipsModal = React.useCallback((duration = 0) => {
    setTimeout(() => {
      setOpen(true)
    }, duration)
  }, [])

  return <context.Provider value={{
    openScanningTipsModal
  }}>
    <ScanningTipsModal open={open} handleClose={onClose} />
    {props.children}
  </context.Provider>
}