import {
  DialogContent,
  Dialog,
  DialogActions,
  Button,
  Typography,
  Box,
  Stack,
  Snackbar,
  IconButton,
} from "@mui/material";
import React, { useCallback } from "react";

import theme from "../../assets/theme/theme";
import LoadingButton from "@mui/lab/LoadingButton";
import WarningOutlineIcon from "../Shared/Icons/WarningOutlineIcon";
import { useAuth } from "../../contexts/AuthContext";
import { reauthenticateWithPopup } from "@firebase/auth";
import { appleAuthProvider, googleAuthProvider } from "../../firebase"
import CloseIcon from "@mui/icons-material/Close";

export interface Props {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const AccountDeleteConfirmationModal: React.FC<Props> = ({
  open,
  setOpen,
}) => {
  const [isDeleting, setIsDeleting] = React.useState(false);
  const { currentUser, verifyMFADelete } = useAuth();
  const [openSnackBar, setOpenSnackBar] = React.useState(false);

  const handleSnackbarClose = useCallback(() => {
    setOpenSnackBar(false);
  }, [setOpenSnackBar]);

  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleSnackbarClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  const deleteCurrentUser = useCallback(async () => {
    try {
      if (currentUser) {
        console.log("provider: ", currentUser.providerId);
        if (currentUser.providerData[0].providerId === "google.com") {
          await reauthenticateWithPopup(currentUser, googleAuthProvider);
        }
        if (currentUser.providerData[0].providerId === "apple.com") {
          await reauthenticateWithPopup(currentUser, appleAuthProvider);
        }
      }
    } catch (e: any) {
      if (e.code === "auth/user-mismatch") {
        // open error snackbar
        setOpenSnackBar(true);
      }
      await verifyMFADelete(e);
    }
  }, [currentUser, verifyMFADelete]);

  const onClose = React.useCallback(async () => {
    setIsDeleting(false);
    setOpen(false);
  }, [setOpen]);

  return (
    <Box>
      <Dialog
        className="delete-account"
        maxWidth="sm"
        open={open}
        onClose={onClose}
        disableEscapeKeyDown={isDeleting}
      >
        <DialogContent
          sx={{
            padding: "32px",
          }}
        >
          <Stack spacing={2} direction="row" mt={2}>
            <Box
              sx={{
                bgcolor: theme.palette.error.light,
                width: 40,
                height: 40,
                borderRadius: 40,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                color: theme.palette.error.main,
              }}
            >
              <WarningOutlineIcon />
            </Box>
            <Stack spacing={1} flex={1}>
              <Typography fontSize={20} fontWeight={500}>
                Delete your account ?
              </Typography>
              <Typography>
                {" "}
                Deleting your account will permanently erase all your data and documents. This action cannot be undone. To confirm, you need to re-authenticate due to verification purposes.
              </Typography>
              {/* <FormControl
              sx={{
                paddingTop: 2,
              }}
              fullWidth
            >
              <FormLabel
                sx={{
                  mb: -2,
                }}
              >
                <Typography color={theme.palette.text.secondary}>
                  Confirm that you want to delete your account by typing{" "}
                  <span
                    style={{
                      fontWeight: 600,
                      color: theme.palette.text.primary,
                    }}
                  >
                    DELETE
                  </span>
                </Typography>
              </FormLabel>
              <DcInput
                value={textField}
                placeholder="DELETE"
                onChange={(evt) => setTextField(evt.target.value)}
                inputProps={{
                  style: {
                    textTransform: "uppercase",
                  },
                }}
              />
            </FormControl> */}
            </Stack>
          </Stack>
        </DialogContent>
        <DialogActions className="cancel-btn">
          <Button variant="outlined" onClick={onClose} disabled={isDeleting}>
            Cancel
          </Button>
          <LoadingButton
            loading={isDeleting}
            loadingPosition="center"
            variant="contained"
            color="error"
            onClick={deleteCurrentUser}
            aria-label="delete document"
          >
            Authenticate
          </LoadingButton>
        </DialogActions>
      </Dialog>
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        sx={{
          ".MuiSnackbarContent-root": {
            background: theme.palette.error.main,
          },
        }}
        open={openSnackBar}
        autoHideDuration={6000}
        message="Wrong user account selected for deletion. Please try again."
        onClose={handleSnackbarClose}
        action={action}
      />
    </Box>
  );
};

export default AccountDeleteConfirmationModal;
