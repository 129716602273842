import { CircularProgress, Grid, styled, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import { differenceInDays, format } from "date-fns";
import { FC, useEffect } from "react";
import { DCPaper } from "../../assets/theme/theme";
import { usePaymentContext } from "../../contexts/PaymentContext";
import { DCTable } from "../Shared/DCTable";
import PriceCheckIcon from "@mui/icons-material/PriceCheck";
import { useHistory } from "react-router-dom";
import { PaymentStatus } from "../../firebaseTypes";
import CheckIcon from "@mui/icons-material/Check";

const StyledPaper = styled(DCPaper)({
  padding: 32,
});
const StyledTitle = styled(Typography)({
  fontSize: 16,
  fontWeight: 700,
});
const StyledLabel = styled(Typography)(({ theme }) => ({
  fontSize: 16,
  fontWeight: 700,
  color: theme.palette.text.secondary,
}));
const StyledValueLabel = styled(Typography)(({ theme }) => ({
  fontSize: 16,
  fontWeight: 500,
  color: theme.palette.text.primary,
}));

interface Props {}
const PaymentUser: FC<Props> = () => {
  const { loading, validPayments, activePayment } = usePaymentContext();
  const history = useHistory();

  const getStatus = (status: PaymentStatus) => {
    switch (status) {
      case "error":
        return "Error";
      case "success":
        return "Success";
      case "user-canceled":
        return "User Canceled";
      default:
        break;
    }
  };

  const InfoLabel = ({ label, value }: { label: string; value: any }) => (
    <Grid container marginBottom={2}>
      <Grid item md={6}>
        <StyledLabel>{label} :</StyledLabel>
      </Grid>
      <Grid item md={6}>
        <StyledValueLabel>{value}</StyledValueLabel>
      </Grid>
    </Grid>
  );

  useEffect(() => {
    if (!loading && !activePayment) {
      history.push("/payment");
    }
  }, [validPayments, activePayment, loading, history]);

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" mt={10}>
        <CircularProgress />
      </Box>
    );
  }

  if (!activePayment) {
    return null;
  }

  const benifits = [
    "Unlimited access for 3 years",
    "Early & exclusive access to new features such as family vault, travel check etc.",
    "Prompt personal support",
    "Opportunity to co-design new features",
    "Give back by helping us reach other immigrants",
  ];

  return (
    <Box px={12}>
      <Typography variant="h1" textAlign="center" fontWeight={500} marginY={5}>
        Thank you for supporting us and thousands of immigrants!
      </Typography>
      <Box>
        <Box>
          <StyledPaper elevation={0}>
            <StyledTitle>Active Payment</StyledTitle>
            <Grid container mt={2}>
              <Grid item md={4} display="flex" alignItems="center">
                <PriceCheckIcon sx={{ fontSize: 128, ml: 2 }} color="primary" />
              </Grid>
              <Grid item md={8}>
                <InfoLabel
                  label="Status"
                  value={getStatus(activePayment.status)}
                />
                <InfoLabel
                  label="Subscription Plan"
                  value={activePayment.subscriptionPlan}
                />
                <InfoLabel
                  label="Price"
                  value={`$${activePayment.price || "0.00"}`}
                />
                <InfoLabel
                  label="Payment Date"
                  value={format(activePayment.paymentDate, "MM-dd-yyy HH:mm")}
                />
                {activePayment.status === "user-canceled" ? (
                  <InfoLabel
                    label="Cancelled Date"
                    value={format(
                      activePayment.userCanceledDate!,
                      "MM-dd-yyy HH:mm"
                    )}
                  />
                ) : (
                  <InfoLabel
                    label="Expire Date"
                    value={format(activePayment.expireDate, "MM-dd-yyy HH:mm")}
                  />
                )}
                <InfoLabel
                  label="Duration"
                  value={
                    differenceInDays(activePayment.expireDate, new Date()) +
                    1 +
                    " days left"
                  }
                />
              </Grid>
            </Grid>
          </StyledPaper>
          <StyledPaper elevation={0} sx={{ minHeight: "100%", marginTop: 5 }}>
            <StyledTitle>Payment History</StyledTitle>
            <DCTable
              columns={[
                {
                  id: "status",
                  title: "Status",
                  children: (row) => getStatus(row.status),
                },
                {
                  id: "price",
                  title: "Price",
                  children: (row) => `$${row.price || "0.00"}`,
                },
                {
                  id: "paymentDate",
                  title: "Payment Date",
                  children: (row) => format(row.paymentDate, "MM-dd-yyy HH:mm"),
                },
                {
                  id: "expireDate",
                  title: "Expire Date",
                  children: (row) => format(row.expireDate, "MM-dd-yyy HH:mm"),
                },
              ]}
              data={validPayments}
            />
          </StyledPaper>
        </Box>
        <StyledPaper elevation={0} sx={{ marginY: 5 }}>
          <Box>
            <StyledTitle>What you are paying for?</StyledTitle>
            {benifits.map((text) => (
              <Box display="flex" alignItems="center" my={2}>
                <CheckIcon color="primary" />
                <Typography ml={1}>{text}</Typography>
              </Box>
            ))}
          </Box>
        </StyledPaper>
      </Box>
    </Box>
  );
};

export default PaymentUser;
