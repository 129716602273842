import { Typography } from "@mui/material";
import React from "react";

export interface Props {
  title?: string;
}

const ViewUploadDocsTitle: React.FC<Props> = (props) => {
  return (
    <Typography variant="h1" component="h1" fontWeight={600} fontSize={24}>
      {props.title || "Document Gallery"}
    </Typography>
  );
};

export default ViewUploadDocsTitle;
