interface IconProps {
  width?: string
  height?: string
  style?: Record<string, string | number>
}

const LogoIcon = ({ width, height, style }: IconProps) => (
  <svg width={width ?? "185"} height={height ?? "34"} viewBox="0 0 886 187" fill="none" xmlns="http://www.w3.org/2000/svg" style={style}>
    <g clipPath="url(#clip0_1_138)">
      <path d="M-0.30249 130.45C-0.30249 156.335 22.2244 177.438 50.1939 177.438C78.1635 177.438 102.615 156.476 102.615 130.45H-0.30249Z" fill="url(#paint0_linear_1_138)"/>
      <path d="M163.943 32.8104C161.813 32.8104 159.542 32.9524 157.413 33.0943C146.197 13.5034 125.045 0.442688 100.911 0.442688C64.9942 0.442688 35.7496 29.5452 35.7496 65.604C35.7496 98.9655 67.3143 130.448 102.542 130.448C96.1365 168.632 73.0112 174.395 48.8115 177.425L163.801 177.04C203.835 177.04 236.344 145.53 236.344 105.496C236.486 65.3201 203.977 32.8104 163.943 32.8104Z" fill="#BBD0EA"/>
      <path d="M163.943 32.8104C161.813 32.8104 159.542 32.9524 157.413 33.0943C146.197 13.5034 125.045 0.442688 100.911 0.442688C64.9942 0.442688 35.7496 29.5452 35.7496 65.604C35.7496 98.9655 67.3143 130.448 102.542 130.448C96.1365 168.632 73.0112 174.395 48.8115 177.425L163.801 177.04C203.835 177.04 236.344 145.53 236.344 105.496C236.486 65.3201 203.977 32.8104 163.943 32.8104Z" fill="url(#paint1_linear_1_138)"/>
      <path d="M157.842 33.0944H101.675L102.527 129.914C102.527 130.056 102.527 130.295 102.527 130.437C138.585 130.437 166.36 101.521 166.36 65.604C166.36 54.3889 163.52 43.7416 158.41 34.514L157.842 33.0944Z" fill="#455DEE"/>
    </g>
    <path d="M276.758 71.7457H300.491V153.595H276.758V71.7457ZM288.625 60.3354C284.263 60.3354 280.714 59.0676 277.975 56.532C275.237 53.9964 273.867 50.8522 273.867 47.0995C273.867 43.3468 275.237 40.2027 277.975 37.6671C280.714 35.1315 284.263 33.8636 288.625 33.8636C292.986 33.8636 296.536 35.0807 299.274 37.5149C302.013 39.9491 303.382 42.9919 303.382 46.6431C303.382 50.5987 302.013 53.895 299.274 56.532C296.536 59.0676 292.986 60.3354 288.625 60.3354Z" fill="#2C2F47"/>
    <path d="M419.733 70.5286C429.977 70.5286 438.091 73.5713 444.075 79.6568C450.16 85.6408 453.203 94.6676 453.203 106.737V153.595H429.47V110.388C429.47 103.897 428.101 99.0796 425.362 95.9354C422.725 92.6898 418.922 91.067 413.952 91.067C408.374 91.067 403.962 92.8927 400.716 96.5439C397.47 100.094 395.848 105.419 395.848 112.518V153.595H372.114V110.388C372.114 97.5075 366.942 91.067 356.596 91.067C351.12 91.067 346.758 92.8927 343.513 96.5439C340.267 100.094 338.644 105.419 338.644 112.518V153.595H314.911V71.7457H337.579V81.1781C340.622 77.7297 344.324 75.0927 348.685 73.267C353.148 71.4414 358.016 70.5286 363.29 70.5286C369.072 70.5286 374.295 71.695 378.961 74.0277C383.626 76.2591 387.379 79.5553 390.219 83.9166C393.566 79.6568 397.775 76.3605 402.846 74.0277C408.019 71.695 413.648 70.5286 419.733 70.5286Z" fill="#2C2F47"/>
    <path d="M534.292 70.5286C544.536 70.5286 552.65 73.5713 558.634 79.6568C564.719 85.6408 567.762 94.6676 567.762 106.737V153.595H544.029V110.388C544.029 103.897 542.66 99.0796 539.921 95.9354C537.284 92.6898 533.481 91.067 528.511 91.067C522.933 91.067 518.521 92.8927 515.275 96.5439C512.029 100.094 510.407 105.419 510.407 112.518V153.595H486.673V110.388C486.673 97.5075 481.501 91.067 471.155 91.067C465.678 91.067 461.317 92.8927 458.072 96.5439C454.826 100.094 453.203 105.419 453.203 112.518L453.203 153.595H429.47V71.7457L439.758 95.0974L452.138 81.1781C455.181 77.7297 458.883 75.0927 463.244 73.267C467.707 71.4414 472.575 70.5286 477.849 70.5286C483.631 70.5286 488.854 71.695 493.519 74.0277C498.185 76.2591 501.938 79.5553 504.778 83.9166C508.125 79.6568 512.334 76.3605 517.405 74.0277C522.578 71.695 528.207 70.5286 534.292 70.5286Z" fill="#2C2F47"/>
    <path d="M628.224 70.5286C635.831 70.5286 642.728 72.3035 648.915 75.8534C655.203 79.3018 660.122 84.2209 663.672 90.6106C667.222 96.8989 668.997 104.252 668.997 112.67C668.997 121.089 667.222 128.493 663.672 134.882C660.122 141.171 655.203 146.09 648.915 149.64C642.728 153.088 635.831 154.812 628.224 154.812C617.777 154.812 609.562 151.516 603.578 144.923V183.11H579.845V71.7457H602.513V81.1781C608.396 74.0784 616.966 70.5286 628.224 70.5286ZM624.116 135.339C630.202 135.339 635.172 133.31 639.026 129.253C642.981 125.095 644.959 119.567 644.959 112.67C644.959 105.774 642.981 100.297 639.026 96.2397C635.172 92.0813 630.202 90.0021 624.116 90.0021C618.031 90.0021 613.01 92.0813 609.055 96.2397C605.201 100.297 603.274 105.774 603.274 112.67C603.274 119.567 605.201 125.095 609.055 129.253C613.01 133.31 618.031 135.339 624.116 135.339Z" fill="#455DEE"/>
    <path d="M677.278 40.7098H701.011V153.595H677.278V40.7098Z" fill="#455DEE"/>
    <path d="M715.431 73.5456H739.164V153.595H715.431V73.5456ZM727.297 60.3354C722.936 60.3354 719.386 59.0676 716.648 56.532C713.909 53.9964 712.54 50.8522 712.54 47.0995C712.54 43.3468 713.909 40.2027 716.648 37.6671C719.386 35.1315 722.936 33.8636 727.297 33.8636C731.658 33.8636 735.208 35.0807 737.947 37.5149C740.685 39.9491 742.054 42.9919 742.054 46.6431C742.054 50.5987 740.685 53.895 737.947 56.532C735.208 59.0676 731.658 60.3354 727.297 60.3354Z" fill="#455DEE"/>
    <path d="M779.599 73.5713H800.594V91.8277H780.207V153.595H756.474V91.8277H739.164V73.5713H756.474V69.92C756.474 60.589 759.213 53.185 764.69 47.7081C770.268 42.2312 778.078 39.4927 788.119 39.4927C791.668 39.4927 795.015 39.8984 798.16 40.7098C801.405 41.4198 804.093 42.4847 806.223 43.9047L799.985 61.0961C797.247 59.169 794.052 58.2055 790.401 58.2055C783.199 58.2055 779.599 62.1611 779.599 70.0722V73.5713Z" fill="#455DEE"/>
    <path d="M886 73.5713L849.031 160.441C845.278 169.874 840.613 176.517 835.034 180.371C829.557 184.225 822.914 186.152 815.104 186.152C810.845 186.152 806.636 185.493 802.477 184.175C798.319 182.856 794.921 181.03 792.284 178.698L800.956 161.811C802.781 163.433 804.861 164.701 807.193 165.614C809.628 166.527 812.011 166.983 814.344 166.983C817.589 166.983 820.226 166.172 822.255 164.549C824.283 163.028 826.109 160.441 827.732 156.79L828.036 156.029L792.588 73.5713H817.082L840.055 129.101L863.18 73.5713H886Z" fill="#455DEE"/>
    <defs>
      <linearGradient id="paint0_linear_1_138" x1="102.538" y1="153.873" x2="19.4793" y2="136.57" gradientUnits="userSpaceOnUse">
        <stop stopColor="#455DEE"/>
        <stop offset="1" stopColor="#6391EC"/>
      </linearGradient>
      <linearGradient id="paint1_linear_1_138" x1="193.862" y1="160.33" x2="43.1465" y2="44.2558" gradientUnits="userSpaceOnUse">
        <stop stopColor="#455DEE"/>
        <stop offset="0.368186" stopColor="#6999F5"/>
        <stop offset="1" stopColor="#85E1F5"/>
      </linearGradient>
      <clipPath id="clip0_1_138">
        <rect width="236" height="177" fill="white"/>
      </clipPath>
    </defs>
  </svg>

)

export default LogoIcon
