export const usaAirports = [

  {
    "airport_code": "709",
    "country": "USA",
    "flag_code": "US",
    "city": "Chief Mountain via Babb, MT"
  },
  {
    "airport_code": "705",
    "country": "USA",
    "flag_code": "US",
    "city": "Sweetgrass, MT"
  },
  {
    "airport_code": "708",
    "country": "USA",
    "flag_code": "US",
    "city": "Del Bonita (via Cut Bank), MT"
  },
  {
    "airport_code": "706",
    "country": "USA",
    "flag_code": "US",
    "city": "Whitlash, MT"
  },
  {
    "airport_code": "711",
    "country": "USA",
    "flag_code": "US",
    "city": "Simpson, MT"
  },
  {
    "airport_code": "711",
    "country": "USA",
    "flag_code": "US",
    "city": "Havre, MT"
  },
  {
    "airport_code": "832",
    "country": "USA",
    "flag_code": "US",
    "city": "Northport, WA"
  },
  {
    "airport_code": "841",
    "country": "USA",
    "flag_code": "US",
    "city": "Lynden, WA"
  },
  // {
  //   "airport_code": "",
  //   "country": "USA",
  //   "flag_code": "US",
  //   "city": "Point Roberts, WA"
  // },
  {
    "airport_code": "840",
    "country": "USA",
    "flag_code": "US",
    "city": "Blaine, WA"
  },
  {
    "airport_code": "829",
    "country": "USA",
    "flag_code": "US",
    "city": "Trail Creek,"
  },
  {
    "airport_code": "817",
    "country": "USA",
    "flag_code": "US",
    "city": "Sumas, WA"
  },
  {
    "airport_code": "813",
    "country": "USA",
    "flag_code": "US",
    "city": "Blaine, WA"
  },
  {
    "airport_code": "824",
    "country": "USA",
    "flag_code": "US",
    "city": "Eureka, MT"
  },
  {
    "airport_code": "822",
    "country": "USA",
    "flag_code": "US",
    "city": "Porthill, ID"
  },
  {
    "airport_code": "816",
    "country": "USA",
    "flag_code": "US",
    "city": "Laurier, WA"
  },
  // {
  //   "airport_code": "",
  //   "country": "USA",
  //   "flag_code": "US",
  //   "city": "Danville, WA"
  // },
  {
    "airport_code": "818",
    "country": "USA",
    "flag_code": "US",
    "city": "Eastport, ID"
  },
  {
    "airport_code": "835",
    "country": "USA",
    "flag_code": "US",
    "city": "Ferry, WA"
  },
  {
    "airport_code": "828",
    "country": "USA",
    "flag_code": "US",
    "city": "Metaline Falls, WA"
  },
  {
    "airport_code": "819",
    "country": "USA",
    "flag_code": "US",
    "city": "Oroville, WA"
  },
  {
    "airport_code": "507",
    "country": "USA",
    "flag_code": "US",
    "city": "Dunseith, ND"
  },
  // {
  //   "airport_code": "",
  //   "country": "USA",
  //   "flag_code": "US",
  //   "city": "Warroad, MN"
  // },
  {
    "airport_code": "506",
    "country": "USA",
    "flag_code": "US",
    "city": "Roseau, MN"
  },
  {
    "airport_code": "521",
    "country": "USA",
    "flag_code": "US",
    "city": "Hansboro, ND"
  },
  {
    "airport_code": "524",
    "country": "USA",
    "flag_code": "US",
    "city": "Westhope, ND"
  },
  {
    "airport_code": "520",
    "country": "USA",
    "flag_code": "US",
    "city": "Sarles, ND"
  },
  {
    "airport_code": "502",
    "country": "USA",
    "flag_code": "US",
    "city": "Pembina, ND"
  },
  // {
  //   "airport_code": "",
  //   "country": "USA",
  //   "flag_code": "US",
  //   "city": "Antler, ND"
  // },
  // {
  //   "airport_code": "",
  //   "country": "USA",
  //   "flag_code": "US",
  //   "city": "Carbury, ND"
  // },
  {
    "airport_code": "503",
    "country": "USA",
    "flag_code": "US",
    "city": "Neche, ND"
  },
  // {
  //   "airport_code": "",
  //   "country": "USA",
  //   "flag_code": "US",
  //   "city": "Walhalla, ND"
  // },
  {
    "airport_code": "522",
    "country": "USA",
    "flag_code": "US",
    "city": "St. John, ND"
  },
  {
    "airport_code": "517",
    "country": "USA",
    "flag_code": "US",
    "city": "Pinecreek, MN"
  },
  {
    "airport_code": "509",
    "country": "USA",
    "flag_code": "US",
    "city": "Hannah, ND"
  },
  {
    "airport_code": "516",
    "country": "USA",
    "flag_code": "US",
    "city": "Lancaster, MN"
  },
  {
    "airport_code": "519",
    "country": "USA",
    "flag_code": "US",
    "city": "Maida, ND"
  },
  {
    "airport_code": "211",
    "country": "USA",
    "flag_code": "US",
    "city": "Calais, ME"
  },
  {
    "airport_code": "211",
    "country": "USA",
    "flag_code": "US",
    "city": "Calais, ME"
  },
  {
    "airport_code": "230",
    "country": "USA",
    "flag_code": "US",
    "city": "Calais, ME"
  },
  {
    "airport_code": "218",
    "country": "USA",
    "flag_code": "US",
    "city": "Van Buren, ME"
  },
  {
    "airport_code": "212",
    "country": "USA",
    "flag_code": "US",
    "city": "Houlton, ME"
  },
  {
    "airport_code": "215",
    "country": "USA",
    "flag_code": "US",
    "city": "Bridgewater, ME"
  },
  {
    "airport_code": "216",
    "country": "USA",
    "flag_code": "US",
    "city": "Fort Kent, ME"
  },
  {
    "airport_code": "214",
    "country": "USA",
    "flag_code": "US",
    "city": "Fort Fairfield, ME"
  },
  {
    "airport_code": "213",
    "country": "USA",
    "flag_code": "US",
    "city": "Madawaska, ME"
  },
  {
    "airport_code": "219",
    "country": "USA",
    "flag_code": "US",
    "city": "Limestone, ME"
  },
  // {
  //   "airport_code": "",
  //   "country": "USA",
  //   "flag_code": "US",
  //   "city": "Hamlin, ME"
  // },
  // {
  //   "airport_code": "",
  //   "country": "USA",
  //   "flag_code": "US",
  //   "city": "Vanceboro, ME"
  // },
  {
    "airport_code": "405",
    "country": "USA",
    "flag_code": "US",
    "city": "Morristown, NY"
  },
  {
    "airport_code": "409",
    "country": "USA",
    "flag_code": "US",
    "city": "Messena, NY"
  },
  {
    "airport_code": "410",
    "country": "USA",
    "flag_code": "US",
    "city": "Buffalo, NY"
  },
  {
    "airport_code": "456",
    "country": "USA",
    "flag_code": "US",
    "city": "Alexandria Bay, NY"
  },
  // {
  //   "airport_code": "",
  //   "country": "USA",
  //   "flag_code": "US",
  //   "city": "Waddington, NY"
  // },
  {
    "airport_code": "427",
    "country": "USA",
    "flag_code": "US",
    "city": "Lewiston, NY"
  },
  // {
  //   "airport_code": "",
  //   "country": "USA",
  //   "flag_code": "US",
  //   "city": "Roberts Landing, MI"
  // },
  {
    "airport_code": "439",
    "country": "USA",
    "flag_code": "US",
    "city": "Ogdensburg, NY"
  },
  {
    "airport_code": "440",
    "country": "USA",
    "flag_code": "US",
    "city": "Port Huron, MI"
  },
  {
    "airport_code": "465",
    "country": "USA",
    "flag_code": "US",
    "city": "Marine City, MI"
  },
  {
    "airport_code": "478",
    "country": "USA",
    "flag_code": "US",
    "city": "International Falls, MN"
  },
  {
    "airport_code": "411",
    "country": "USA",
    "flag_code": "US",
    "city": "Ranier, MN"
  },
  {
    "airport_code": "427",
    "country": "USA",
    "flag_code": "US",
    "city": "Niagara Falls, NY"
  },
  // {
  //   "airport_code": "",
  //   "country": "USA",
  //   "flag_code": "US",
  //   "city": "Grand Portage, MN"
  // },
  {
    "airport_code": "436",
    "country": "USA",
    "flag_code": "US",
    "city": "Baudette, MN"
  },
  {
    "airport_code": "441",
    "country": "USA",
    "flag_code": "US",
    "city": "Sault Ste. Marie, MI"
  },
  {
    "airport_code": "450",
    "country": "USA",
    "flag_code": "US",
    "city": "Algonac, MI"
  },
  {
    "airport_code": "453",
    "country": "USA",
    "flag_code": "US",
    "city": "Detroit, MI"
  },
  {
    "airport_code": "452",
    "country": "USA",
    "flag_code": "US",
    "city": "Detroit, MI"
  },
  {
    "airport_code": "370",
    "country": "USA",
    "flag_code": "US",
    "city": "East Richford, VT"
  },
  // {
  //   "airport_code": "",
  //   "country": "USA",
  //   "flag_code": "US",
  //   "city": "Jamieson Line, NY"
  // },
  // {
  //   "airport_code": "",
  //   "country": "USA",
  //   "flag_code": "US",
  //   "city": "Newport, VT"
  // },
  // {
  //   "airport_code": "",
  //   "country": "USA",
  //   "flag_code": "US",
  //   "city": "Jackman, ME"
  // },
  {
    "airport_code": "367",
    "country": "USA",
    "flag_code": "US",
    "city": "Morses Line, VT"
  },
  // {
  //   "airport_code": "",
  //   "country": "USA",
  //   "flag_code": "US",
  //   "city": "Hogansburg, NY"
  // },
  {
    "airport_code": "318",
    "country": "USA",
    "flag_code": "US",
    "city": "Richford, VT"
  },
  {
    "airport_code": "329",
    "country": "USA",
    "flag_code": "US",
    "city": "Jackman, ME"
  },
  // {
  //   "airport_code": "",
  //   "country": "USA",
  //   "flag_code": "US",
  //   "city": "Franklin, VT"
  // },
  {
    "airport_code": "376",
    "country": "USA",
    "flag_code": "US",
    "city": "Beebe Plain, VT"
  },
  // {
  //   "airport_code": "",
  //   "country": "USA",
  //   "flag_code": "US",
  //   "city": "Rouses Point, NY"
  // },
  {
    "airport_code": "365",
    "country": "USA",
    "flag_code": "US",
    "city": "Connecticut Lakes, NH"
  },
  {
    "airport_code": "337",
    "country": "USA",
    "flag_code": "US",
    "city": "Alburg Springs, VT"
  },
  // {
  //   "airport_code": "",
  //   "country": "USA",
  //   "flag_code": "US",
  //   "city": "Island Pond, VT"
  // },
  // {
  //   "airport_code": "",
  //   "country": "USA",
  //   "flag_code": "US",
  //   "city": "Beecher Falls, VT"
  // },
  {
    "airport_code": "330",
    "country": "USA",
    "flag_code": "US",
    "city": "Fort Covington, NY"
  },
  // {
  //   "airport_code": "",
  //   "country": "USA",
  //   "flag_code": "US",
  //   "city": "Churubusco, NY"
  // },
  // {
  //   "airport_code": "",
  //   "country": "USA",
  //   "flag_code": "US",
  //   "city": "East Franklin, VT"
  // },
  {
    "airport_code": "332",
    "country": "USA",
    "flag_code": "US",
    "city": "West Berkshire, VT"
  },
  // {
  //   "airport_code": "",
  //   "country": "USA",
  //   "flag_code": "US",
  //   "city": "East Richford, VT"
  // },
  {
    "airport_code": "333",
    "country": "USA",
    "flag_code": "US",
    "city": "Mooers, NY"
  },
  // {
  //   "airport_code": "",
  //   "country": "USA",
  //   "flag_code": "US",
  //   "city": "Chateaugay, NY"
  // },
  {
    "airport_code": "366",
    "country": "USA",
    "flag_code": "US",
    "city": "Canaan, VT"
  },
  {
    "airport_code": "334",
    "country": "USA",
    "flag_code": "US",
    "city": "North Troy, VT"
  },
  // {
  //   "airport_code": "",
  //   "country": "USA",
  //   "flag_code": "US",
  //   "city": "Malone, NY"
  // },
  {
    "airport_code": "395",
    "country": "USA",
    "flag_code": "US",
    "city": "Burlington, VT"
  },
  {
    "airport_code": "368",
    "country": "USA",
    "flag_code": "US",
    "city": "Alburg, VT"
  },
  {
    "airport_code": "328",
    "country": "USA",
    "flag_code": "US",
    "city": "Highgate Springs, VT"
  },
  {
    "airport_code": "351",
    "country": "USA",
    "flag_code": "US",
    "city": "Champlain, NY"
  },
  {
    "airport_code": "354",
    "country": "USA",
    "flag_code": "US",
    "city": "Norton, VT"
  },
  {
    "airport_code": "314",
    "country": "USA",
    "flag_code": "US",
    "city": "Derby Line, VT"
  },
  // {
  //   "airport_code": "",
  //   "country": "USA",
  //   "flag_code": "US",
  //   "city": "Trout River, NY"
  // },
  // {
  //   "airport_code": "",
  //   "country": "USA",
  //   "flag_code": "US",
  //   "city": "Havre, MT"
  // },
  {
    "airport_code": "724",
    "country": "USA",
    "flag_code": "US",
    "city": "Morgan via Loring, MT"
  },
  {
    "airport_code": "616",
    "country": "USA",
    "flag_code": "US",
    "city": "Fortuna, ND"
  },
  // {
  //   "airport_code": "",
  //   "country": "USA",
  //   "flag_code": "US",
  //   "city": "Whitetail, MT"
  // },
  // {
  //   "airport_code": "",
  //   "country": "USA",
  //   "flag_code": "US",
  //   "city": "Scobey, MT"
  // },
  // {
  //   "airport_code": "",
  //   "country": "USA",
  //   "flag_code": "US",
  //   "city": "Sherwood, ND"
  // },
  {
    "airport_code": "610",
    "country": "USA",
    "flag_code": "US",
    "city": "Noonan, ND"
  },
  // {
  //   "airport_code": "",
  //   "country": "USA",
  //   "flag_code": "US",
  //   "city": "Ambrose, ND"
  // },
  {
    "airport_code": "602",
    "country": "USA",
    "flag_code": "US",
    "city": "Portal, ND"
  },
  // {
  //   "airport_code": "",
  //   "country": "USA",
  //   "flag_code": "US",
  //   "city": "Raymond, MT"
  // },
  {
    "airport_code": "618",
    "country": "USA",
    "flag_code": "US",
    "city": "Opheim, MT"
  },
  {
    "airport_code": "833",
    "country": "USA",
    "flag_code": "US",
    "city": "Northport, WA"
  },
  {
    airport_code: "ABE",
    city: "Aberdeen, WA",
    country: "USA",
    flag_code: "US",
  },
  { airport_code: "ABG", city: "Alburg, VT", country: "USA", flag_code: "US" },
  {
    airport_code: "ABQ",
    city: "Albuquerque, NM",
    country: "USA",
    flag_code: "US",
  },
  {
    airport_code: "ABS",
    city: "Alburg Springs, VT",
    country: "USA",
    flag_code: "US",
  },
  {
    airport_code: "ADT",
    city: "Amistad Dam, TX",
    country: "USA",
    flag_code: "US",
  },
  { airport_code: "AGM", city: "Algoma, WI", country: "USA", flag_code: "US" },
  { airport_code: "ALP", city: "Alpena, MI", country: "USA", flag_code: "US" },
  { airport_code: "AGA", city: "Agana, Guam", country: "USA", flag_code: "US" },
  { airport_code: "AGN", city: "Algonac, MI", country: "USA", flag_code: "US" },
  {
    airport_code: "AGU",
    city: "Mayaguez, PR",
    country: "USA",
    flag_code: "US",
  },
  { airport_code: "AKR", city: "Akron, OH", country: "USA", flag_code: "US" },
  { airport_code: "ALB", city: "Albany, NY", country: "USA", flag_code: "US" },
  { airport_code: "ALC", city: "Alcan, AK", country: "USA", flag_code: "US" },
  { airport_code: "AMB", city: "Ambrose, ND", country: "USA", flag_code: "US" },
  {
    airport_code: "ANA",
    city: "Anacortes, WA",
    country: "USA",
    flag_code: "US",
  },
  {
    airport_code: "ANC",
    city: "Anchorage, AK",
    country: "USA",
    flag_code: "US",
  },
  { airport_code: "AND", city: "Andrade, CA", country: "USA", flag_code: "US" },
  {
    airport_code: "ANP",
    city: "Antelope Wells, NM",
    country: "USA",
    flag_code: "US",
  },
  { airport_code: "ANT", city: "Antler, ND", country: "USA", flag_code: "US" },
  {
    airport_code: "ANZ",
    city: "Anzaldaus, TX",
    country: "USA",
    flag_code: "US",
  },
  { airport_code: "ARU", city: "Aruba", country: "USA", flag_code: "US" },
  { airport_code: "AST", city: "Astoria, OR", country: "USA", flag_code: "US" },
  {
    airport_code: "ATB",
    city: "Ashtabula, OH",
    country: "USA",
    flag_code: "US",
  },
  { airport_code: "ATL", city: "Atlanta, GA", country: "USA", flag_code: "US" },
  {
    airport_code: "ATW",
    city: "Appleton, WI",
    country: "USA",
    flag_code: "US",
  },
  { airport_code: "AUS", city: "Austin, TX", country: "USA", flag_code: "US" },
  {
    airport_code: "AXB",
    city: "Alexandria Bay, NY",
    country: "USA",
    flag_code: "US",
  },
  {
    airport_code: "BAL",
    city: "Baltimore, MD",
    country: "USA",
    flag_code: "US",
  },
  {
    airport_code: "BAU",
    city: "Baudette, MN",
    country: "USA",
    flag_code: "US",
  },
  {
    airport_code: "BAY",
    city: "Bayfield, WI",
    country: "USA",
    flag_code: "US",
  },
  {
    airport_code: "BBM",
    city: "Brownsville, B&M Bridge",
    country: "USA",
    flag_code: "US",
  },
  {
    airport_code: "BCK",
    city: "Bucksport, ME",
    country: "USA",
    flag_code: "US",
  },
  {
    airport_code: "BCY",
    city: "Bay City, MI",
    country: "USA",
    flag_code: "US",
  },
  {
    airport_code: "BCM",
    city: "Battle Creek and Kalamazoo County Airport, MI",
    country: "USA",
    flag_code: "US",
  },
  {
    airport_code: "BEA",
    city: "Beaumont, TX",
    country: "USA",
    flag_code: "US",
  },
  {
    airport_code: "BEB",
    city: "Beebe Plain, VT",
    country: "USA",
    flag_code: "US",
  },
  {
    airport_code: "BEE",
    city: "Beecher Falls, VT",
    country: "USA",
    flag_code: "US",
  },
  {
    airport_code: "BEL",
    city: "Bellingham, WA",
    country: "USA",
    flag_code: "US",
  },
  {
    airport_code: "BGC",
    city: "Bridgeport, CT",
    country: "USA",
    flag_code: "US",
  },
  { airport_code: "BGM", city: "Bangor, ME", country: "USA", flag_code: "US" },
  {
    airport_code: "BHM",
    city: "Bar Harbor, ME Ferry Terminal",
    country: "USA",
    flag_code: "US",
  },
  {
    airport_code: "BIL",
    city: "Billings, MT",
    country: "USA",
    flag_code: "US",
  },
  { airport_code: "BLA", city: "Blaine, WA", country: "USA", flag_code: "US" },
  {
    airport_code: "BOA",
    city: "El Paso, TX Bridge of Americas",
    country: "USA",
    flag_code: "US",
  },
  {
    airport_code: "BOC",
    city: "Boca Grande, FL",
    country: "USA",
    flag_code: "US",
  },
  {
    airport_code: "BOO",
    city: "Boothbay Harbor, ME",
    country: "USA",
    flag_code: "US",
  },
  { airport_code: "BOS", city: "Boston, MA", country: "USA", flag_code: "US" },
  {
    airport_code: "BRG",
    city: "Burlington, VT",
    country: "USA",
    flag_code: "US",
  },
  {
    airport_code: "BRO",
    city: "Brownsville, TX",
    country: "USA",
    flag_code: "US",
  },
  {
    airport_code: "BRU",
    city: "Brunswick, GA",
    country: "USA",
    flag_code: "US",
  },
  { airport_code: "BTM", city: "Butte, MT", country: "USA", flag_code: "US" },
  {
    airport_code: "BTN",
    city: "Baton Rouge, LA",
    country: "USA",
    flag_code: "US",
  },
  { airport_code: "BUF", city: "Buffalo, NY", country: "USA", flag_code: "US" },
  {
    airport_code: "BWA",
    city: "Boundary, WA",
    country: "USA",
    flag_code: "US",
  },
  {
    airport_code: "BWM",
    city: "Bridgewater, ME",
    country: "USA",
    flag_code: "US",
  },
  {
    airport_code: "CAL",
    city: "Calexico, CA",
    country: "USA",
    flag_code: "US",
  },
  {
    airport_code: "CAN",
    city: "Cape Canaveral, FL",
    country: "USA",
    flag_code: "US",
  },
  {
    airport_code: "CAP",
    city: "Cape Vincent, NY",
    country: "USA",
    flag_code: "US",
  },
  {
    airport_code: "CHA",
    city: "Charlotte Amalie, St. Thomas, VI",
    country: "USA",
    flag_code: "US",
  },
  {
    airport_code: "CBH",
    city: "Churubusco, IN",
    country: "USA",
    flag_code: "US",
  },
  {
    airport_code: "CHF",
    city: "Chief Mountain, MT",
    country: "USA",
    flag_code: "US",
  },
  { airport_code: "CHI", city: "Chicago, IL", country: "USA", flag_code: "US" },
  {
    airport_code: "CHL",
    city: "Charleston, SC",
    country: "USA",
    flag_code: "US",
  },
  {
    airport_code: "CHM",
    city: "Champlain, NY",
    country: "USA",
    flag_code: "US",
  },
  {
    airport_code: "CHR",
    city: "Christiansted, St. Croix, VI",
    country: "USA",
    flag_code: "US",
  },
  {
    airport_code: "CHT",
    city: "Chateaugay, NY",
    country: "USA",
    flag_code: "US",
  },
  {
    airport_code: "CIN",
    city: "Cincinnati, OH",
    country: "USA",
    flag_code: "US",
  },
  { airport_code: "CLA", city: "Clayton, NY", country: "USA", flag_code: "US" },
  {
    airport_code: "CLE",
    city: "Cleveland, OH",
    country: "USA",
    flag_code: "US",
  },
  {
    airport_code: "CLG",
    city: "Calgary, Alberta Canada",
    country: "USA",
    flag_code: "US",
  },
  { airport_code: "CLS", city: "Calais, ME", country: "USA", flag_code: "US" },
  {
    airport_code: "CLT",
    city: "Charlotte, NC",
    country: "USA",
    flag_code: "US",
  },
  { airport_code: "CNA", city: "Canaan, VT", country: "USA", flag_code: "US" },
  {
    airport_code: "CNC",
    city: "Cannon Corners, NY",
    country: "USA",
    flag_code: "US",
  },
  { airport_code: "CNJ", city: "Camden, NJ", country: "USA", flag_code: "US" },
  {
    airport_code: "COB",
    city: "Coburn Gore, ME",
    country: "USA",
    flag_code: "US",
  },
  {
    airport_code: "COL",
    city: "Columbus, NM",
    country: "USA",
    flag_code: "US",
  },
  {
    airport_code: "COO",
    city: "Coos Bay, OR",
    country: "USA",
    flag_code: "US",
  },
  {
    airport_code: "COR",
    city: "Corpus Christi, TX",
    country: "USA",
    flag_code: "US",
  },
  {
    airport_code: "COW",
    city: "HQ, Washington DC",
    country: "USA",
    flag_code: "US",
  },
  {
    airport_code: "CRA",
    city: "Crane Lake, MN",
    country: "USA",
    flag_code: "US",
  },
  { airport_code: "CRB", city: "Caribou, ME", country: "USA", flag_code: "US" },
  {
    airport_code: "CRU",
    city: "Cruz Bay, St. John, VI",
    country: "USA",
    flag_code: "US",
  },
  { airport_code: "CSP", city: "Casper, WY", country: "USA", flag_code: "US" },
  { airport_code: "CRY", city: "Carbury, ND", country: "USA", flag_code: "US" },
  {
    airport_code: "CUT",
    city: "Cut Bank, MT",
    country: "USA",
    flag_code: "US",
  },
  { airport_code: "DAL", city: "Dallas, TX", country: "USA", flag_code: "US" },
  {
    airport_code: "DAC",
    city: "Dalton Cache, AK",
    country: "USA",
    flag_code: "US",
  },
  {
    airport_code: "DBL",
    city: "Dublin, Ireland",
    country: "USA",
    flag_code: "US",
  },
  { airport_code: "DCA", city: "Detroit, MI", country: "USA", flag_code: "US" },
  {
    airport_code: "DCB",
    city: "Detroit, Ambassador Bridge",
    country: "USA",
    flag_code: "US",
  },
  {
    airport_code: "DCT",
    city: "Detroit, Windsor Tunnel",
    country: "USA",
    flag_code: "US",
  },
  { airport_code: "DEN", city: "Denver, CO", country: "USA", flag_code: "US" },
  { airport_code: "DER", city: "Del Rio, TX", country: "USA", flag_code: "US" },
  { airport_code: "DET", city: "Detroit, MI", country: "USA", flag_code: "US" },
  { airport_code: "DIB", city: "Detroit, MI", country: "USA", flag_code: "US" },
  {
    airport_code: "DLB",
    city: "Del Bonita, MT",
    country: "USA",
    flag_code: "US",
  },
  { airport_code: "DLR", city: "Del Rio, TX", country: "USA", flag_code: "US" },
  { airport_code: "DMA", city: "Detroit, MI", country: "USA", flag_code: "US" },
  { airport_code: "DNA", city: "Donna, TX", country: "USA", flag_code: "US" },
  {
    airport_code: "DNS",
    city: "Dunseith, ND",
    country: "USA",
    flag_code: "US",
  },
  { airport_code: "DOU", city: "Douglas, AZ", country: "USA", flag_code: "US" },
  {
    airport_code: "DSM",
    city: "Des Moines, IA",
    country: "USA",
    flag_code: "US",
  },
  { airport_code: "DUL", city: "Duluth, MN", country: "USA", flag_code: "US" },
  {
    airport_code: "DVD",
    city: "Dover, AFB DE",
    country: "USA",
    flag_code: "US",
  },
  {
    airport_code: "DVL",
    city: "Danville, WA",
    country: "USA",
    flag_code: "US",
  },
  {
    airport_code: "EDA",
    city: "Edmonton, AB Canada",
    country: "USA",
    flag_code: "US",
  },
  {
    airport_code: "EDN",
    city: "South Texas, IAP at Edinburg",
    country: "USA",
    flag_code: "US",
  },
  {
    airport_code: "EGP",
    city: "Eagle Pass, TX",
    country: "USA",
    flag_code: "US",
  },
  { airport_code: "ELP", city: "El Paso, TX", country: "USA", flag_code: "US" },
  { airport_code: "ELY", city: "Ely, MN", country: "USA", flag_code: "US" },
  {
    airport_code: "ENS",
    city: "Ensenada, PR",
    country: "USA",
    flag_code: "US",
  },
  {
    airport_code: "EPI",
    city: "Eastport, ID",
    country: "USA",
    flag_code: "US",
  },
  {
    airport_code: "EPM",
    city: "Eastport, ME",
    country: "USA",
    flag_code: "US",
  },
  {
    airport_code: "ERC",
    city: "East Richford, VT",
    country: "USA",
    flag_code: "US",
  },
  { airport_code: "ERI", city: "Erie, PA", country: "USA", flag_code: "US" },
  { airport_code: "EVE", city: "Everett, WA", country: "USA", flag_code: "US" },
  { airport_code: "FAB", city: "Fabens, TX", country: "USA", flag_code: "US" },
  { airport_code: "FAJ", city: "Fajardo, PR", country: "USA", flag_code: "US" },
  {
    airport_code: "FAL",
    city: "Falcon Dam, TX",
    country: "USA",
    flag_code: "US",
  },
  { airport_code: "FAR", city: "Fargo, ND", country: "USA", flag_code: "US" },
  {
    airport_code: "FBA",
    city: "Freeport, Bahamas",
    country: "USA",
    flag_code: "US",
  },
  {
    airport_code: "FDE",
    city: "Eagle Pass, Fort Duncan Bridge",
    country: "USA",
    flag_code: "US",
  },
  { airport_code: "FER", city: "Ferry, WA", country: "USA", flag_code: "US" },
  {
    airport_code: "FHK",
    city: "Fort Hancock, TX",
    country: "USA",
    flag_code: "US",
  },
  {
    airport_code: "FPT",
    city: "Freeport, TX",
    country: "USA",
    flag_code: "US",
  },
  {
    airport_code: "FRB",
    city: "Fairbanks, AK",
    country: "USA",
    flag_code: "US",
  },
  { airport_code: "FRE", city: "Fresno, CA", country: "USA", flag_code: "US" },
  {
    airport_code: "FRI",
    city: "Friday Harbor, WA",
    country: "USA",
    flag_code: "US",
  },
  {
    airport_code: "FRK",
    city: "Frederiksted, St. Croix, VI",
    country: "USA",
    flag_code: "US",
  },
  {
    airport_code: "FRN",
    city: "Fernandina, FL",
    country: "USA",
    flag_code: "US",
  },
  { airport_code: "FRT", city: "Fortuna, ND", country: "USA", flag_code: "US" },
  {
    airport_code: "FTC",
    city: "Fort Covington, NY",
    country: "USA",
    flag_code: "US",
  },
  {
    airport_code: "FTF",
    city: "Fort Fairfield, ME",
    country: "USA",
    flag_code: "US",
  },
  {
    airport_code: "FTH",
    city: "Fort Hancock, NM",
    country: "USA",
    flag_code: "US",
  },
  {
    airport_code: "FTK",
    city: "Fort Kent, ME",
    country: "USA",
    flag_code: "US",
  },
  {
    airport_code: "FTL",
    city: "Fort Lauderdale, FL",
    country: "USA",
    flag_code: "US",
  },
  {
    airport_code: "FTP",
    city: "Fort Pierce, FL",
    country: "USA",
    flag_code: "US",
  },
  {
    airport_code: "FTM",
    city: "Fort Meyers, FL",
    country: "USA",
    flag_code: "US",
  },
  {
    airport_code: "FWA",
    city: "Frontier, WA",
    country: "USA",
    flag_code: "US",
  },
  {
    airport_code: "GAL",
    city: "Galveston, TX",
    country: "USA",
    flag_code: "US",
  },
  { airport_code: "GAR", city: "Gary, IN", country: "USA", flag_code: "US" },
  {
    airport_code: "GEO",
    city: "Georgetown, SC",
    country: "USA",
    flag_code: "US",
  },
  { airport_code: "GGW", city: "Glasgow, MT", country: "USA", flag_code: "US" },
  {
    airport_code: "GLO",
    city: "Gloucester, MA",
    country: "USA",
    flag_code: "US",
  },
  {
    airport_code: "GLP",
    city: "Gulfport, MS",
    country: "USA",
    flag_code: "US",
  },
  {
    airport_code: "GPM",
    city: "Grand Portage, MN",
    country: "USA",
    flag_code: "US",
  },
  {
    airport_code: "GRB",
    city: "Green Bay, WI",
    country: "USA",
    flag_code: "US",
  },
  {
    airport_code: "GRE",
    city: "Great Falls, MT",
    country: "USA",
    flag_code: "US",
  },
  {
    airport_code: "GRF",
    city: "Grand Forks, ND",
    country: "USA",
    flag_code: "US",
  },
  {
    airport_code: "GRO",
    city: "Grosse Isle, MI",
    country: "USA",
    flag_code: "US",
  },
  {
    airport_code: "GRP",
    city: "Grand Rapids, MI Kent County Intl Airport",
    country: "USA",
    flag_code: "US",
  },
  { airport_code: "GRR", city: "Greer, SC", country: "USA", flag_code: "US" },
  { airport_code: "GRT", city: "Groton, CT", country: "USA", flag_code: "US" },
  {
    airport_code: "GSN",
    city: "Saipan Island, Saipan Intl Arpt, MP, USA",
    country: "USA",
    flag_code: "US",
  },
  {
    airport_code: "GTW",
    city: "Brownsville, Gateway Bridge",
    country: "USA",
    flag_code: "US",
  },
  {
    airport_code: "GUL",
    city: "Gulfport, MS",
    country: "USA",
    flag_code: "US",
  },
  { airport_code: "HAI", city: "Haines, AK", country: "USA", flag_code: "US" },
  {
    airport_code: "HAL",
    city: "Halifax, Nova Scotia",
    country: "USA",
    flag_code: "US",
  },
  {
    airport_code: "HAM",
    city: "Hamilton, Bermuda",
    country: "USA",
    flag_code: "US",
  },
  {
    airport_code: "HAR",
    city: "Harrisburg, PA",
    country: "USA",
    flag_code: "US",
  },
  { airport_code: "HEL", city: "Helena, MT", country: "USA", flag_code: "US" },
  {
    airport_code: "HHW",
    city: "Honolulu, HI",
    country: "USA",
    flag_code: "US",
  },
  { airport_code: "HID", city: "Hidalgo, TX", country: "USA", flag_code: "US" },
  {
    airport_code: "HIG",
    city: "Highgate Springs, VT",
    country: "USA",
    flag_code: "US",
  },
  {
    airport_code: "HLG",
    city: "Harlingen, TX",
    country: "USA",
    flag_code: "US",
  },
  { airport_code: "HML", city: "Hamlin, ME", country: "USA", flag_code: "US" },
  { airport_code: "HMM", city: "Hammond, IN", country: "USA", flag_code: "US" },
  { airport_code: "HNN", city: "Hannah, ND", country: "USA", flag_code: "US" },
  {
    airport_code: "HNS",
    city: "Hansboro, ND",
    country: "USA",
    flag_code: "US",
  },
  { airport_code: "HOB", city: "Hoboken, NJ", country: "USA", flag_code: "US" },
  { airport_code: "HOM", city: "Homer, AK", country: "USA", flag_code: "US" },
  {
    airport_code: "HOP",
    city: "Hopewell, VA",
    country: "USA",
    flag_code: "US",
  },
  { airport_code: "HOU", city: "Houston, TX", country: "USA", flag_code: "US" },
  {
    airport_code: "HSB",
    city: "Harrisburg, PA",
    country: "USA",
    flag_code: "US",
  },
  { airport_code: "HTM", city: "Houlton, ME", country: "USA", flag_code: "US" },
  { airport_code: "HUM", city: "Humacao, PR", country: "USA", flag_code: "US" },
  {
    airport_code: "HVR",
    city: "Wild Horse, MT",
    country: "USA",
    flag_code: "US",
  },
  { airport_code: "HYD", city: "Hyder, AK", country: "USA", flag_code: "US" },
  { airport_code: "IND", city: "Los Indios", country: "USA", flag_code: "US" },
  {
    airport_code: "INP",
    city: "Indianapolis, IN",
    country: "USA",
    flag_code: "US",
  },
  {
    airport_code: "INT",
    city: "International Falls, MN",
    country: "USA",
    flag_code: "US",
  },
  {
    airport_code: "ISB",
    city: "Port Isabel, TX",
    country: "USA",
    flag_code: "US",
  },
  {
    airport_code: "ISL",
    city: "Isle Royale, MI",
    country: "USA",
    flag_code: "US",
  },
  {
    airport_code: "IVP",
    city: "Calexico, Truck Crossing",
    country: "USA",
    flag_code: "US",
  },
  {
    airport_code: "JAC",
    city: "Jacksonville, FL",
    country: "USA",
    flag_code: "US",
  },
  {
    airport_code: "JER",
    city: "Jersey City, NJ",
    country: "USA",
    flag_code: "US",
  },
  { airport_code: "JKM", city: "Jackman, ME", country: "USA", flag_code: "US" },
  { airport_code: "JOB", city: "Jobos, PR", country: "USA", flag_code: "US" },
  { airport_code: "JUN", city: "Juneau, AK", country: "USA", flag_code: "US" },
  {
    airport_code: "KAL",
    city: "Kalispell, MT",
    country: "USA",
    flag_code: "US",
  },
  {
    airport_code: "KAN",
    city: "Kansas City, MO",
    country: "USA",
    flag_code: "US",
  },
  {
    airport_code: "KET",
    city: "Ketchikan, AK",
    country: "USA",
    flag_code: "US",
  },
  {
    airport_code: "KEY",
    city: "Key West, FL",
    country: "USA",
    flag_code: "US",
  },
  { airport_code: "KLM", city: "Kalama, WA", country: "USA", flag_code: "US" },
  { airport_code: "KOA", city: "Kona, HI", country: "USA", flag_code: "US" },
  {
    airport_code: "LAN",
    city: "Lancaster, MN",
    country: "USA",
    flag_code: "US",
  },
  {
    airport_code: "LAR",
    city: "Laredo, TX Juarez-Lincoln Bridge",
    country: "USA",
    flag_code: "US",
  },
  { airport_code: "LAU", city: "Laurier, WA", country: "USA", flag_code: "US" },
  {
    airport_code: "LCB",
    city: "Laredo, Columbia Bridge",
    country: "USA",
    flag_code: "US",
  },
  { airport_code: "LEB", city: "Lebanon, ME", country: "USA", flag_code: "US" },
  {
    airport_code: "LEW",
    city: "Lewiston, NY",
    country: "USA",
    flag_code: "US",
  },
  {
    airport_code: "LIM",
    city: "Limestone, ME",
    country: "USA",
    flag_code: "US",
  },
  {
    airport_code: "LKC",
    city: "Lake Charles, LA",
    country: "USA",
    flag_code: "US",
  },
  {
    airport_code: "LLB",
    city: "Laredo Juarez-Lincoln Bridge 2, TX",
    country: "USA",
    flag_code: "US",
  },
  {
    airport_code: "LNB",
    city: "Long Beach, CA",
    country: "USA",
    flag_code: "US",
  },
  { airport_code: "LOI", city: "Los Indios", country: "USA", flag_code: "US" },
  {
    airport_code: "LON",
    city: "Longview, WA",
    country: "USA",
    flag_code: "US",
  },
  { airport_code: "LOR", city: "Lorain, OH", country: "USA", flag_code: "US" },
  {
    airport_code: "LOS",
    city: "Los Angeles, CA",
    country: "USA",
    flag_code: "US",
  },
  {
    airport_code: "LOU",
    city: "Louisville, KY",
    country: "USA",
    flag_code: "US",
  },
  {
    airport_code: "LSE",
    city: "Los Ebanos, TX",
    country: "USA",
    flag_code: "US",
  },
  { airport_code: "LUB", city: "Lubec, ME", country: "USA", flag_code: "US" },
  {
    airport_code: "LUK",
    city: "Lukeville, AZ",
    country: "USA",
    flag_code: "US",
  },
  {
    airport_code: "LVG",
    city: "Las Vegas, NV",
    country: "USA",
    flag_code: "US",
  },
  {
    airport_code: "LWT",
    city: "Laredo, World Trade Bridge",
    country: "USA",
    flag_code: "US",
  },
  { airport_code: "LYN", city: "Lynden, WA", country: "USA", flag_code: "US" },
  {
    airport_code: "MAA",
    city: "Abu Dhabi INTL",
    country: "USA",
    flag_code: "US",
  },
  {
    airport_code: "MAD",
    city: "Madawaska, ME",
    country: "USA",
    flag_code: "US",
  },
  {
    airport_code: "MAG",
    city: "McGuire AFB, NJ",
    country: "USA",
    flag_code: "US",
  },
  { airport_code: "MAI", city: "Maida, ND", country: "USA", flag_code: "US" },
  {
    airport_code: "MAP",
    city: "Mariposa, AZ",
    country: "USA",
    flag_code: "US",
  },
  { airport_code: "MAS", city: "Massena, NY", country: "USA", flag_code: "US" },
  {
    airport_code: "MAY",
    city: "Mayaguez, PR",
    country: "USA",
    flag_code: "US",
  },
  { airport_code: "MCI", city: "McAllen, TX", country: "USA", flag_code: "US" },
  { airport_code: "MEM", city: "Memphis, TN", country: "USA", flag_code: "US" },
  {
    airport_code: "MET",
    city: "Metaline Falls, WA",
    country: "USA",
    flag_code: "US",
  },
  {
    airport_code: "MGC",
    city: "Morgan City, LA",
    country: "USA",
    flag_code: "US",
  },
  { airport_code: "MGM", city: "Morgan, MT", country: "USA", flag_code: "US" },
  { airport_code: "MIA", city: "Miami, FL", country: "USA", flag_code: "US" },
  {
    airport_code: "MIL",
    city: "Milwaukee, WI",
    country: "USA",
    flag_code: "US",
  },
  {
    airport_code: "MND",
    city: "Minot, ND (Int'l Airport)",
    country: "USA",
    flag_code: "US",
  },
  {
    airport_code: "MNW",
    city: "Manitowoc, WI",
    country: "USA",
    flag_code: "US",
  },
  { airport_code: "MOB", city: "Mobile, AL", country: "USA", flag_code: "US" },
  {
    airport_code: "MON",
    city: "Montreal, Quebec",
    country: "USA",
    flag_code: "US",
  },
  { airport_code: "MOO", city: "Mooers, NY", country: "USA", flag_code: "US" },
  {
    airport_code: "MOR",
    city: "Morses Line, VT",
    country: "USA",
    flag_code: "US",
  },
  {
    airport_code: "MRC",
    city: "Marine City, MI",
    country: "USA",
    flag_code: "US",
  },
  {
    airport_code: "MRH",
    city: "Morehead City, NC",
    country: "USA",
    flag_code: "US",
  },
  {
    airport_code: "MRR",
    city: "Morristown, NY",
    country: "USA",
    flag_code: "US",
  },
  {
    airport_code: "MRY",
    city: "Marysville, MI",
    country: "USA",
    flag_code: "US",
  },
  {
    airport_code: "MSP",
    city: "Minneapolis/St. Paul (Int'l Airport)",
    country: "USA",
    flag_code: "US",
  },
  {
    airport_code: "MTH",
    city: "Marathon ARPT, FL",
    country: "USA",
    flag_code: "US",
  },
  {
    airport_code: "MYR",
    city: "Myrtle Beach, SC",
    country: "USA",
    flag_code: "US",
  },
  { airport_code: "NAC", city: "Naco, AZ", country: "USA", flag_code: "US" },
  {
    airport_code: "NAS",
    city: "Nassau, Bahamas",
    country: "USA",
    flag_code: "US",
  },
  {
    airport_code: "NEA",
    city: "Neah Bay, WA",
    country: "USA",
    flag_code: "US",
  },
  { airport_code: "NEC", city: "Neche, ND", country: "USA", flag_code: "US" },
  { airport_code: "NEW", city: "Newark, NJ", country: "USA", flag_code: "US" },
  {
    airport_code: "NIA",
    city: "Niagara Falls, NY",
    country: "USA",
    flag_code: "US",
  },
  {
    airport_code: "NIG",
    city: "Nighthawk, WA",
    country: "USA",
    flag_code: "US",
  },
  { airport_code: "NIK", city: "Nikiski, AK", country: "USA", flag_code: "US" },
  { airport_code: "NOG", city: "Nogales, AZ", country: "USA", flag_code: "US" },
  {
    airport_code: "NOL",
    city: "New Orleans, LA",
    country: "USA",
    flag_code: "US",
  },
  { airport_code: "NOM", city: "Nome, AK", country: "USA", flag_code: "US" },
  { airport_code: "NOO", city: "Noonan, ND", country: "USA", flag_code: "US" },
  { airport_code: "NOR", city: "Norfolk, VA", country: "USA", flag_code: "US" },
  { airport_code: "NOY", city: "Noyes, MN", country: "USA", flag_code: "US" },
  { airport_code: "NPV", city: "Newport, VT", country: "USA", flag_code: "US" },
  {
    airport_code: "NRG",
    city: "Northgate, ND",
    country: "USA",
    flag_code: "US",
  },
  { airport_code: "NRN", city: "Norton, VT", country: "USA", flag_code: "US" },
  {
    airport_code: "NRT",
    city: "North Troy, VT",
    country: "USA",
    flag_code: "US",
  },
  {
    airport_code: "NSV",
    city: "Nashville, TN",
    country: "USA",
    flag_code: "US",
  },
  {
    airport_code: "NWH",
    city: "New Haven, CT",
    country: "USA",
    flag_code: "US",
  },
  {
    airport_code: "NWL",
    city: "New London, CT",
    country: "USA",
    flag_code: "US",
  },
  {
    airport_code: "NWN",
    city: "Newport News, VA",
    country: "USA",
    flag_code: "US",
  },
  {
    airport_code: "NYC",
    city: "New York, NY",
    country: "USA",
    flag_code: "US",
  },
  {
    airport_code: "NPW",
    city: "Northport, WA",
    country: "USA",
    flag_code: "US",
  },
  { airport_code: "OAK", city: "Oakland, CA", country: "USA", flag_code: "US" },
  {
    airport_code: "OGD",
    city: "Ogdensburg, NY",
    country: "USA",
    flag_code: "US",
  },
  {
    airport_code: "OKC",
    city: "Oklahoma City, OK",
    country: "USA",
    flag_code: "US",
  },
  { airport_code: "OLY", city: "Olympia, WA", country: "USA", flag_code: "US" },
  { airport_code: "OMA", city: "Omaha, NE", country: "USA", flag_code: "US" },
  { airport_code: "OPH", city: "Opheim, MT", country: "USA", flag_code: "US" },
  { airport_code: "ORL", city: "Orlando, FL", country: "USA", flag_code: "US" },
  {
    airport_code: "ORO",
    city: "Oroville, WA",
    country: "USA",
    flag_code: "US",
  },
  { airport_code: "OSW", city: "Oswego, NY", country: "USA", flag_code: "US" },
  {
    airport_code: "OTM",
    city: "Otay Mesa POE, CA",
    country: "USA",
    flag_code: "US",
  },
  {
    airport_code: "OTT",
    city: "Ottawa, Canada",
    country: "USA",
    flag_code: "US",
  },
  {
    airport_code: "PAN",
    city: "Panama City, FL",
    country: "USA",
    flag_code: "US",
  },
  {
    airport_code: "PAR",
    city: "Port Arthur, TX",
    country: "USA",
    flag_code: "US",
  },
  {
    airport_code: "PAS",
    city: "Pascagoula, MS",
    country: "USA",
    flag_code: "US",
  },
  {
    airport_code: "PAX",
    city: "Patuxent River, MD",
    country: "USA",
    flag_code: "US",
  },
  {
    airport_code: "PBB",
    city: "Peace Bridge, NY",
    country: "USA",
    flag_code: "US",
  },
  {
    airport_code: "PCF",
    city: "Port Canaveral, FL",
    country: "USA",
    flag_code: "US",
  },
  {
    airport_code: "PDN",
    city: "Paso del Norte, El Paso, TX",
    country: "USA",
    flag_code: "US",
  },
  { airport_code: "PEM", city: "Pembina, ND", country: "USA", flag_code: "US" },
  {
    airport_code: "PEN",
    city: "Pensacola, FL",
    country: "USA",
    flag_code: "US",
  },
  {
    airport_code: "PER",
    city: "Perth Amboy, NJ",
    country: "USA",
    flag_code: "US",
  },
  {
    airport_code: "PEV",
    city: "Port Everglades, FL",
    country: "USA",
    flag_code: "US",
  },
  {
    airport_code: "PGR",
    city: "Progreso, TX",
    country: "USA",
    flag_code: "US",
  },
  {
    airport_code: "PHI",
    city: "Philadelphia, PA",
    country: "USA",
    flag_code: "US",
  },
  { airport_code: "PHO", city: "Phoenix, AZ", country: "USA", flag_code: "US" },
  { airport_code: "PHR", city: "Pharr, TX", country: "USA", flag_code: "US" },
  {
    airport_code: "PHU",
    city: "Port Huron, M",
    country: "USA",
    flag_code: "US",
  },
  {
    airport_code: "PHY",
    city: "Pacific Highway POE, WA",
    country: "USA",
    flag_code: "US",
  },
  {
    airport_code: "PIB",
    city: "Paradise, Island, Bahamas",
    country: "USA",
    flag_code: "US",
  },
  { airport_code: "PIE", city: "Piegan, MT", country: "USA", flag_code: "US" },
  {
    airport_code: "PIN",
    city: "Pine Creek, MN",
    country: "USA",
    flag_code: "US",
  },
  {
    airport_code: "PIT",
    city: "Pittsburgh, PA",
    country: "USA",
    flag_code: "US",
  },
  {
    airport_code: "PIV",
    city: "Pinnacle POE, VT",
    country: "USA",
    flag_code: "US",
  },
  {
    airport_code: "PKC",
    city: "Poker Creek, AK",
    country: "USA",
    flag_code: "US",
  },
  {
    airport_code: "PNG",
    city: "Port Angeles, WA",
    country: "USA",
    flag_code: "US",
  },
  {
    airport_code: "PNH",
    city: "Pittsburg, NH",
    country: "USA",
    flag_code: "US",
  },
  {
    airport_code: "POE",
    city: "Port of El Paso, TX",
    country: "USA",
    flag_code: "US",
  },
  {
    airport_code: "POM",
    city: "Portland, ME",
    country: "USA",
    flag_code: "US",
  },
  { airport_code: "PON", city: "Ponce, PR", country: "USA", flag_code: "US" },
  {
    airport_code: "POO",
    city: "Portland, OR",
    country: "USA",
    flag_code: "US",
  },
  { airport_code: "POR", city: "Portal, ND", country: "USA", flag_code: "US" },
  {
    airport_code: "PRC",
    city: "Prince Rupert BC Canada",
    country: "USA",
    flag_code: "US",
  },
  {
    airport_code: "PRE",
    city: "Presidio, TX",
    country: "USA",
    flag_code: "US",
  },
  {
    airport_code: "PRO",
    city: "Providence, RI",
    country: "USA",
    flag_code: "US",
  },
  {
    airport_code: "PSJ",
    city: "Port St. Joe, FL",
    country: "USA",
    flag_code: "US",
  },
  {
    airport_code: "PSM",
    city: "Portsmouth, NH",
    country: "USA",
    flag_code: "US",
  },
  {
    airport_code: "PTL",
    city: "Porthill, ID",
    country: "USA",
    flag_code: "US",
  },
  {
    airport_code: "PTO",
    city: "Port Townsend, WA",
    country: "USA",
    flag_code: "US",
  },
  {
    airport_code: "PTR",
    city: "Point Roberts, WA",
    country: "USA",
    flag_code: "US",
  },
  {
    airport_code: "PUT",
    city: "Put-in-Bay, OH",
    country: "USA",
    flag_code: "US",
  },
  { airport_code: "RAN", city: "Ranier, MN", country: "USA", flag_code: "US" },
  { airport_code: "RAY", city: "Raymond, MT", country: "USA", flag_code: "US" },
  {
    airport_code: "RBB",
    city: "Niagara Falls, Rainbow Bridge, NY",
    country: "USA",
    flag_code: "US",
  },
  {
    airport_code: "RBT",
    city: "Roberts Landing, MI",
    country: "USA",
    flag_code: "US",
  },
  {
    airport_code: "RCM",
    city: "Richmond, VA",
    country: "USA",
    flag_code: "US",
  },
  {
    airport_code: "RDU",
    city: "Raleigh-Durham, NC",
    country: "USA",
    flag_code: "US",
  },
  { airport_code: "REN", city: "Reno, NV", country: "USA", flag_code: "US" },
  {
    airport_code: "RIF",
    city: "Richford, VT",
    country: "USA",
    flag_code: "US",
  },
  {
    airport_code: "RIO",
    city: "Rio Grande City, TX",
    country: "USA",
    flag_code: "US",
  },
  { airport_code: "RIT", city: "Rome, Italy", country: "USA", flag_code: "US" },
  {
    airport_code: "ROC",
    city: "Rochester, NY",
    country: "USA",
    flag_code: "US",
  },
  { airport_code: "ROM", city: "Roma, TX", country: "USA", flag_code: "US" },
  {
    airport_code: "ROO",
    city: "Roosville, MT",
    country: "USA",
    flag_code: "US",
  },
  { airport_code: "ROS", city: "Roseau, MN", country: "USA", flag_code: "US" },
  {
    airport_code: "ROU",
    city: "Rouses Point, NY",
    country: "USA",
    flag_code: "US",
  },
  {
    airport_code: "SAC",
    city: "Sacramento, CA",
    country: "USA",
    flag_code: "US",
  },
  { airport_code: "SAG", city: "Saginaw, MI", country: "USA", flag_code: "US" },
  {
    airport_code: "SAJ",
    city: "San Juan, PR",
    country: "USA",
    flag_code: "US",
  },
  { airport_code: "SAR", city: "Sarles, ND", country: "USA", flag_code: "US" },
  { airport_code: "SAS", city: "Sasabe, AZ", country: "USA", flag_code: "US" },
  {
    airport_code: "SAU",
    city: "St. Augustine, FL",
    country: "USA",
    flag_code: "US",
  },
  {
    airport_code: "SAV",
    city: "Savannah, GA",
    country: "USA",
    flag_code: "US",
  },
  {
    airport_code: "SBR",
    city: "South Brewer, ME",
    country: "USA",
    flag_code: "US",
  },
  {
    airport_code: "SCL",
    city: "St. Clair, MI",
    country: "USA",
    flag_code: "US",
  },
  { airport_code: "SCO", city: "Scobey, MT", country: "USA", flag_code: "US" },
  {
    airport_code: "SDP",
    city: "San Diego Intl. Airport, CA",
    country: "USA",
    flag_code: "US",
  },
  {
    airport_code: "SDY",
    city: "Sandusky, OH",
    country: "USA",
    flag_code: "US",
  },
  { airport_code: "SEA", city: "Seattle, WA", country: "USA", flag_code: "US" },
  {
    airport_code: "SET",
    city: "Seattle-Tacoma, WA",
    country: "USA",
    flag_code: "US",
  },
  { airport_code: "SFB", city: "Sanford, FL", country: "USA", flag_code: "US" },
  {
    airport_code: "SPE",
    city: "St. Petersburg, FL",
    country: "USA",
    flag_code: "US",
  },
  { airport_code: "SPN", city: "Saipan", country: "USA", flag_code: "US" },
  {
    airport_code: "SFR",
    city: "San Francisco, CA",
    country: "USA",
    flag_code: "US",
  },
  {
    airport_code: "SHA",
    city: "Shannon, Ireland",
    country: "USA",
    flag_code: "US",
  },
  {
    airport_code: "SHR",
    city: "Sherwood, ND",
    country: "USA",
    flag_code: "US",
  },
  {
    airport_code: "SJO",
    city: "St. John, ND",
    country: "USA",
    flag_code: "US",
  },
  { airport_code: "SKA", city: "Skagway, AK", country: "USA", flag_code: "US" },
  {
    airport_code: "SLC",
    city: "Salt Lake City, UT",
    country: "USA",
    flag_code: "US",
  },
  {
    airport_code: "SLO",
    city: "San Luis Obispo, CA",
    country: "USA",
    flag_code: "US",
  },
  {
    airport_code: "SLU",
    city: "San Luis, AZ",
    country: "USA",
    flag_code: "US",
  },
  {
    airport_code: "SNA",
    city: "San Antonio, TX",
    country: "USA",
    flag_code: "US",
  },
  {
    airport_code: "SND",
    city: "San Diego, CA",
    country: "USA",
    flag_code: "US",
  },
  {
    airport_code: "SNJ",
    city: "San Jose, CA",
    country: "USA",
    flag_code: "US",
  },
  {
    airport_code: "SNN",
    city: "Shannon, Ireland",
    country: "USA",
    flag_code: "US",
  },
  {
    airport_code: "SPA",
    city: "St. Pamphile, ME",
    country: "USA",
    flag_code: "US",
  },
  {
    airport_code: "SPC",
    city: "San Pedro, CA",
    country: "USA",
    flag_code: "US",
  },
  {
    airport_code: "SPM",
    city: "St. Paul, MN",
    country: "USA",
    flag_code: "US",
  },
  { airport_code: "SPO", city: "Spokane, WA", country: "USA", flag_code: "US" },
  {
    airport_code: "SRL",
    city: "St. Aurelie, ME",
    country: "USA",
    flag_code: "US",
  },
  { airport_code: "SSB", city: "Sanford, Fl", country: "USA", flag_code: "US" },
  {
    airport_code: "SSM",
    city: "Sault Ste. Marie, MI",
    country: "USA",
    flag_code: "US",
  },
  {
    airport_code: "STA",
    city: "St. Albans, VT",
    country: "USA",
    flag_code: "US",
  },
  {
    airport_code: "STJ",
    city: "St. John, USVI",
    country: "USA",
    flag_code: "US",
  },
  {
    airport_code: "STL",
    city: "St. Louis, MO",
    country: "USA",
    flag_code: "US",
  },
  {
    airport_code: "STR",
    city: "Santa Teresa, NM",
    country: "USA",
    flag_code: "US",
  },
  {
    airport_code: "STZ",
    city: "St. Zacharie, ME",
    country: "USA",
    flag_code: "US",
  },
  { airport_code: "SUM", city: "Sumas, WA", country: "USA", flag_code: "US" },
  {
    airport_code: "SWE",
    city: "Sweetgrass, MT",
    country: "USA",
    flag_code: "US",
  },
  {
    airport_code: "SYR",
    city: "Syracuse, NY",
    country: "USA",
    flag_code: "US",
  },
  {
    airport_code: "SYS",
    city: "San Ysidro, CA",
    country: "USA",
    flag_code: "US",
  },
  { airport_code: "TAC", city: "Tacoma, WA", country: "USA", flag_code: "US" },
  { airport_code: "TAM", city: "Tampa, FL", country: "USA", flag_code: "US" },
  { airport_code: "TEC", city: "Tecate, CA", country: "USA", flag_code: "US" },
  {
    airport_code: "THO",
    city: "Thousand Islands Bridge, NY",
    country: "USA",
    flag_code: "US",
  },
  { airport_code: "TOL", city: "Toledo, OH", country: "USA", flag_code: "US" },
  {
    airport_code: "TOR",
    city: "Toronto, Ontario Canada",
    country: "USA",
    flag_code: "US",
  },
  {
    airport_code: "TRL",
    city: "Trail Creek, MT",
    country: "USA",
    flag_code: "US",
  },
  {
    airport_code: "TRO",
    city: "Trout River, NY",
    country: "USA",
    flag_code: "US",
  },
  {
    airport_code: "TRV",
    city: "Travis AFB, CA",
    country: "USA",
    flag_code: "US",
  },
  { airport_code: "TUC", city: "Tucson, AZ", country: "USA", flag_code: "US" },
  { airport_code: "TUR", city: "Turner, MT", country: "USA", flag_code: "US" },
  {
    airport_code: "VAN",
    city: "Vancouver, WA",
    country: "USA",
    flag_code: "US",
  },
  {
    airport_code: "VCB",
    city: "Vanceboro, ME",
    country: "USA",
    flag_code: "US",
  },
  {
    airport_code: "VCV",
    city: "Vancouver, BC Canada",
    country: "USA",
    flag_code: "US",
  },
  {
    airport_code: "VIB",
    city: "Veterans International Bridge",
    country: "USA",
    flag_code: "US",
  },
  {
    airport_code: "VIC",
    city: "Victoria, BC",
    country: "USA",
    flag_code: "US",
  },
  {
    airport_code: "VNB",
    city: "Van Buren, ME",
    country: "USA",
    flag_code: "US",
  },
  {
    airport_code: "WAL",
    city: "Walhalla, ND",
    country: "USA",
    flag_code: "US",
  },
  { airport_code: "WAR", city: "Warroad, MN", country: "USA", flag_code: "US" },
  {
    airport_code: "WAS",
    city: "Washington, DC",
    country: "USA",
    flag_code: "US",
  },
  {
    airport_code: "WBE",
    city: "West Berkshire, VT",
    country: "USA",
    flag_code: "US",
  },
  {
    airport_code: "WCM",
    city: "Willow Creek, MT",
    country: "USA",
    flag_code: "US",
  },
  {
    airport_code: "WHI",
    city: "Whitetail, MT",
    country: "USA",
    flag_code: "US",
  },
  {
    airport_code: "WHM",
    city: "Wildhorse, MT",
    country: "USA",
    flag_code: "US",
  },
  {
    airport_code: "WHO",
    city: "Westhope, ND",
    country: "USA",
    flag_code: "US",
  },
  {
    airport_code: "WHR",
    city: "Whirlpool Bridge",
    country: "USA",
    flag_code: "US",
  },
  {
    airport_code: "WHT",
    city: "Whitlash, MT",
    country: "USA",
    flag_code: "US",
  },
  {
    airport_code: "WIL",
    city: "Wilmington, NC",
    country: "USA",
    flag_code: "US",
  },
  {
    airport_code: "WIN",
    city: "Winnipeg, SK. Canada",
    country: "USA",
    flag_code: "US",
  },
  {
    airport_code: "WLL",
    city: "Wilmington, DE",
    country: "USA",
    flag_code: "US",
  },
  {
    airport_code: "WRB",
    city: "Whirlpool Bridge",
    country: "USA",
    flag_code: "US",
  },
  {
    airport_code: "WND",
    city: "Williston, ND (Int'l Airport)",
    country: "USA",
    flag_code: "US",
  },
  {
    airport_code: "WPB",
    city: "West Palm Beach, FL",
    country: "USA",
    flag_code: "US",
  },
  {
    airport_code: "WRA",
    city: "Wrangell, AK",
    country: "USA",
    flag_code: "US",
  },
  { airport_code: "YAK", city: "Yakima, WA", country: "USA", flag_code: "US" },
  {
    airport_code: "YOU",
    city: "Youngstown, NY",
    country: "USA",
    flag_code: "US",
  },
  { airport_code: "YSL", city: "Ysleta, TX", country: "USA", flag_code: "US" },
  { airport_code: "YUI", city: "Yuma, AZ", country: "USA", flag_code: "US" },
];
