import constants from "../../constants";
// import AssistanceIcon from "../Shared/Icons/AssistanceIcon";
// import LegalSupportIcon from "../Shared/Icons/LegalSupportIcon";
import { Link } from "react-router-dom";
import SupportPersonIcon from "../Shared/Icons/SupportPersonIcon";
import PresentIcon from "../Shared/Icons/PresentIcon";
import FAQIcon from "../Shared/Icons/FAQIcon";
import DashboardIcon from "../Shared/Icons/DashboardIcon";
import DashboardSolidIcon from "../Shared/Icons/DashboardSolidIcon";
import RouteIcon from "../Shared/Icons/RouteIcon";
import RouteDuoToneIcon from "../Shared/Icons/RouteDuoToneIcon";
import DocumentStackIcon from "../Shared/Icons/DocumentStackIcon";
// import PaymentIcon from "../Shared/Icons/PaymentIcon";
import MessageIcon from "../Shared/Icons/MessageIcon";
import EmojiEventsOutlinedIcon from '@mui/icons-material/EmojiEventsOutlined';

export interface IDrawerItem {
  text: string;
  href: string;
  attributes?: any; // html tag attributes
  disabled?: boolean;
  pageId: string;
  getIcon: () => any;
  getActiveIcon: () => any;
  getBadge?: () => any;
  type?: "button";
  component: any; // values could be Link or "a"
}

export const DrawerItems: IDrawerItem[] = [
  // {
  //   text: 'View/Upload Docs',
  //   href: '/documents',
  //   pageId: constants.pages.viewUploadDocs, // pageId has to be in sync with pageId in App.tsx
  //   getIcon: () => <UploadIcon />,
  //   type: 'button',
  // },
  {
    text: "Dashboard",
    href: "/",
    pageId: constants.pages.dashboard,
    getIcon: () => <DashboardIcon />,
    getActiveIcon: () => <DashboardSolidIcon color="primary" />,
    // getIcon: () => <img alt="" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAABmJLR0QA/wD/AP+gvaeTAAAAp0lEQVRIid2UwQ3DIAxFH1HGaDpPV2ABhmoHyArZJ4M4F1AjBKptpZGSdwL9D8g2NlydUBYSeQEf4NHwrUAKM4vVO+yEd+cAwJR1s3esBML8jaogESm61TvUhqP5+wPjb0uTFZhyOno64I8g7S9pXJ7KxhVB/oJPjffUGqjz6m00dV6xNaUdiUgv0lo7rwaWvFrwDjs13mGn5l7DTt1oFq+30Szei7MBuxpJoIhO+OcAAAAASUVORK5CYII="></img>,
    component: Link,
  },
  {
    text: "Documents",
    href: "/documents",
    pageId: constants.pages.viewUploadDocs,
    getIcon: () => <DocumentStackIcon />,
    getActiveIcon: () => <DocumentStackIcon color="primary" />,
    // getIcon: () => <img alt="" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAABmJLR0QA/wD/AP+gvaeTAAAAjUlEQVRIie2VwQ2AIBAEB0MZ0o8t0ABFaQG0YD8Ugg8xUSOgEHwxT+7Yyy4QoJNBPC16ja/QdIARlhVgqBCKoYC5ge6O1/hzAi0cXJBh6gQswFigccn8zuFgLhSHTOby1ISwz7cqRchbxerNz6AP6APqkfmWb9zf0m8OHKAq/gEXKxwOTKrphbgp3NuBDdpcG6s3RaAUAAAAAElFTkSuQmCC"></img>,
    component: Link,
  },
  {
    text: "Travel History",
    href: "/travel-history",
    disabled: false,
    pageId: constants.pages.travelHistory,
    getIcon: () => <RouteIcon />,
    getActiveIcon: () => <RouteDuoToneIcon color="primary" />,
    // getIcon: () => <img alt="" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAABmJLR0QA/wD/AP+gvaeTAAABbUlEQVRIie3UvWtUQRQF8N+8LBIhxtKPws4qf0IEiwQJaCeL+1aR1f9BG0FimS4EK4s0QhKIWKrFWgQtQootLAS1ELQRRPALDMTkWrj58Jl9eYk2Yk4399w5Z+YeZtjHXhENZ6PpQeRu/olO1pPp81EYw61ouh3jJb0lSGVk5O7hfLdzxoBWumNlNwblp6q5huWfbpo+ux91B/+aQbrrNSY3C86peRR1h6salI4I4qpDvnmJo1t2dawYS/PeR0u/ZcOSUWFEZsiayTTnRiUDiNwVTBfKryRvhGH0F7gvadYgW0YUF5yJhmYPjyV8KNROCiPbiH/H1PqitlHOXMTlaDglM4HTXYFRHC+5IDyXtK15bNVCmvdpndgYUeSO4AWVAnyHJ13Rh2nO216Nv2QQueuY2KbvKxYlbbTN6CSiwkEKBnUH1DzDCaGDpzJtAxZ2+8B6Ii45Fq3fgvuPUQy5UnA7is5u6u7pC97Hv4UfB4NhSfJXalIAAAAASUVORK5CYII="></img>,
    component: Link,
  },
  {
    text: "O1 / EB1",
    href: "/o1-eb1",
    disabled: false,
    pageId: constants.pages.o1eb1,
    getIcon: () => <EmojiEventsOutlinedIcon sx={{ color: '#707070', strokeWidth: 1.5 }} />,
    getActiveIcon: () => <EmojiEventsOutlinedIcon color="primary" />,
    // getIcon: () => <img alt="" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAABmJLR0QA/wD/AP+gvaeTAAABbUlEQVRIie3UvWtUQRQF8N+8LBIhxtKPws4qf0IEiwQJaCeL+1aR1f9BG0FimS4EK4s0QhKIWKrFWgQtQootLAS1ELQRRPALDMTkWrj58Jl9eYk2Yk4399w5Z+YeZtjHXhENZ6PpQeRu/olO1pPp81EYw61ouh3jJb0lSGVk5O7hfLdzxoBWumNlNwblp6q5huWfbpo+ux91B/+aQbrrNSY3C86peRR1h6salI4I4qpDvnmJo1t2dawYS/PeR0u/ZcOSUWFEZsiayTTnRiUDiNwVTBfKryRvhGH0F7gvadYgW0YUF5yJhmYPjyV8KNROCiPbiH/H1PqitlHOXMTlaDglM4HTXYFRHC+5IDyXtK15bNVCmvdpndgYUeSO4AWVAnyHJ13Rh2nO216Nv2QQueuY2KbvKxYlbbTN6CSiwkEKBnUH1DzDCaGDpzJtAxZ2+8B6Ii45Fq3fgvuPUQy5UnA7is5u6u7pC97Hv4UfB4NhSfJXalIAAAAASUVORK5CYII="></img>,
    component: Link,
  },
  {
    text: "Get Expert Help",
    href: "/get-expert-help",
    // href: "https://forms.gle/suw4jXddCk67v3e3A",
    // attributes: {
    //   target: "_blank",
    // },
    pageId: constants.pages.getExpertHelp,
    getIcon: () => (
      <MessageIcon
        sx={{
          color: "inherit",
        }}
      />
    ),
    getActiveIcon: () => <MessageIcon color="primary" />,
    component: Link,
  },
  {
    text: "FAQs",
    href: "/faqs",
    attributes: {
      target: "_blank",
    },
    disabled: false,
    pageId: constants.pages.faqs,
    getIcon: () => <FAQIcon />,
    getActiveIcon: () => <FAQIcon color="primary" />,
    // getIcon: () => <img alt="" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAABmJLR0QA/wD/AP+gvaeTAAABV0lEQVRIie3Uv0qcQRQF8N/oomyRNZ2FPoEGogi+gI2B2Eg2ujFFmiCWpskb+AY2YiksskUIYqGVvkGEkKClfyAW2ms2mRT7oSLMt366jZjTzNyZc869dy4Mjx2hHSG+0qvPvOAdXmTH3wVrflsJDZf3ThCrBpRs4mWC8k3T69BwkvLoyq382vwIb5RVlFVE09jHiJKNWNWT8ikly+8zf2XeNBIazm/cfolVu0r2MKrbRywX6kAwl+0Wb5m3rhvOBZ+y8H3KJp2AIVC2nVPEVrYOpyjJJwp1z3KSt3CpN3O4SFHyOmiPbpPZ7keKkh5yG8Safiy1Aqsp3r06iB88xyYGseuXescSxDkVF7YwhgPMhB3NjiSIBH+tYVxwqMtEqDvN0xSbway3mMKZPybDuuN2kmJPFCxk6+ew7uddJEVn0PpNo68Fdf/RIcSaGGtiEc3D/qIngX9g9UpOZexMSQAAAABJRU5ErkJggg=="></img>,
    component: Link,
  },
  {
    text: "Request New Feature",
    href: "https://forms.gle/MpjVHXQWB3pFGLHv8",
    attributes: {
      target: "_blank",
    },
    pageId: "none",
    getIcon: () => <PresentIcon />,
    getActiveIcon: () => <PresentIcon color="primary" />,
    component: "a",
  },
  {
    text: "Contact Support",
    href: "mailto:support@docucomb.com",
    attributes: {
      target: "_blank",
    },
    pageId: "none",
    getIcon: () => <SupportPersonIcon />,
    getActiveIcon: () => <SupportPersonIcon color="primary" />,
    // getIcon: () => <img alt="" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAABmJLR0QA/wD/AP+gvaeTAAABl0lEQVRIie3Vv0/TURQF8M9tapCkdVWjNToZFzdmFg0JMuiAadHBOLAxuOkE/AWusrnYH5oYB5UgE6OOLhgXTUoiMyWhidrnQEmo8m1r6mQ447vnnvPOu3nvcYwBiFGaU9kNYQXnfitt6ZiPhtXcKAbCkyPE4bycFcgP3CGLwhXJppylqHrbI4So9Z5EqkgoQWaCdMeM8FqYQEGYkLxJc6aHCXeA7CPqWAThobwCHu07d9eHROaQU8UOin4oxgu76b6iPTtoRc2pLif10W5GzYXsBMknkLeQZhW0LXQrm0NsvIn5QQmuY+0PTsd0NKx2OUmyFHXLWTqZCaJmXZjBB+zi/WHxfZLlfuL/B46cQSoryZmTTOESzmAbX4Q1HdWoa/61QSorCY9xS787Qgcv5TyIZ7aGMkh3XfbTBk5jD6/wXPjou29OOCu5itu4iXFs65iMhs+DDSrWcQ0bkntR9zWrKZVdFJ5iUngXVVPDGLQxdnBz+8WGNKsgr4V21Ixn8Q6/pmPdlVaqDJLvwcl+xdH+g2P8C/wCavx0/0JjA7YAAAAASUVORK5CYII="></img>,
    component: "a",
  },
  // {
  //   text: "Payment",
  //   href: "/payment",
  //   disabled: false,
  //   pageId: constants.pages.payment,
  //   getIcon: () => <PaymentIcon />,
  //   getActiveIcon: () => <PaymentIcon color="primary" />,
  //   component: Link,
  // },
];
