import { LoadingButton } from "@mui/lab";
import {
  Dialog,
  DialogTitle,
  Typography,
  Button,
  DialogActions,
  Box,
  DialogContent,
  Divider,
  Grid,
  Hidden,
  Tooltip,
  Stack,
  FormControl,
  InputLabel,
} from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import theme, { DcDocumentIconButton, DcInput } from "../../assets/theme/theme";
import { useCustomCardModalContext } from "../../contexts/CustomCardModalContext";
import AttachDocumentCustomCardModal from "../DashboardMain/AttachDocumentCustomCardModal";
import ConfirmDialog from "../Shared/ConfirmDialog";
import { get, isEmpty, map } from "lodash";
import { ICustomDocument, IDocumentField } from "../../firebaseTypes";
import CustomCardField from "../CustomCardField";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import constants from "../../constants";
import { usePDFMergeContext } from "../../contexts/PDFMergerContext";
import { downloadResource } from "../../utils/fetchUtils";
import { useAuth } from "../../contexts/AuthContext";
import { usePreventRefreshDialogContext } from "../../contexts/PreventRefreshDialogContext";
import { AttachDocumentEmptyState } from "../Shared/Illustrations";
import { useFetchDocumentFromURL } from "../Shared/FetchDocumentFromUrl";
import { getSimplifiedDocFormat } from "../../utils/documentUtils";
import { PinturaPDFViewer } from "../Shared/PinturaPDFViewer";
import { PinturaImageViewer } from "../Shared/PinturaImageViewer";

export interface Props {
  openCloseState: boolean;
  onModalClose: (reason?: string) => void;
  mode: "create" | "edit";
}

const CustomCardModal: React.FC<Props> = (props) => {
  const {
    customDocumentEditable,
    handleDeleteCustomCard,
    setCustomDocumentEditable,
    downloadURL,
    handleSave,
    setDownloadURL,
    detachDocument,
  } = useCustomCardModalContext();
  const { print, isPrintProcessing } = usePDFMergeContext();
  const { showPreventRefreshDialog, hidePreventRefreshDialog } =
    usePreventRefreshDialogContext();
  const { currentUser } = useAuth();

  const [formError, setFormError] = useState(false);
  const [isSavingCard, setIsSavingCard] = useState(false);
  const [isOpenConfirmDelete, setIsOpenConfirmDelete] = useState(false);
  const [isDownloadingDoc, setIsDownloadingDoc] = useState(false);
  const simpleDocFormat = getSimplifiedDocFormat(
    customDocumentEditable?.doc_format
  );
  const { src, isLoading } = useFetchDocumentFromURL({
    url: downloadURL,
    isSignedURL: false,
    fileFormat: simpleDocFormat,
  });

  const onPrintClick = useCallback(async () => {
    if (customDocumentEditable?.doc_format && downloadURL) {
      let downloadURLs: Array<string> = [];
      let downloadImgURLs: Array<string> = [];
      if (customDocumentEditable?.doc_format === "application/pdf") {
        downloadURLs.push(downloadURL);
      } else {
        downloadImgURLs.push(downloadURL);
      }
      await print(downloadURLs, downloadImgURLs);
    }
  }, [customDocumentEditable?.doc_format, downloadURL, print]);

  const onDownloadClick = useCallback(async () => {
    if (currentUser) {
      setIsDownloadingDoc(true);
      const token = await currentUser?.getIdToken();
      await downloadResource(
        downloadURL,
        customDocumentEditable?.doc_name || "",
        {
          Authorization: `Bearer ${token}`,
        }
      );
      setIsDownloadingDoc(false);
    }
  }, [currentUser, customDocumentEditable?.doc_name, downloadURL]);

  React.useEffect(() => {
    const validateForm = () => {
      const docInfo = customDocumentEditable?.doc_info || [];
      const title = customDocumentEditable?.doc_title;
      const emptyTitle = docInfo.findIndex((doc) => doc.title === "");
      const emptyValue = docInfo.findIndex((doc) => doc.value === "");

      setFormError(false);
      if (emptyValue !== -1) {
        setFormError(true);
      }
      if (emptyTitle !== -1) {
        setFormError(true);
      }
      if (title === "") {
        setFormError(true);
      }
    };

    validateForm();
  }, [customDocumentEditable?.doc_info, customDocumentEditable?.doc_title]);

  useEffect(() => {
    if (props.openCloseState)
      showPreventRefreshDialog(
        "All the changes made will be lost, are you sure want to continue?"
      );
    else hidePreventRefreshDialog();
  }, [
    hidePreventRefreshDialog,
    showPreventRefreshDialog,
    props.openCloseState,
  ]);

  if (!customDocumentEditable) return null;

  const templateName = get(customDocumentEditable, "template", "default");
  const template = get(constants.customCardTemplates, templateName);
  const templateTitle = get(template, "modalTitle") || "";

  return (
    <Dialog
      disableEscapeKeyDown
      open={props.openCloseState}
      onClose={(evt, reason) => props.onModalClose(reason)}
      aria-labelledby="custom card create and edit form"
      aria-describedby="custom card create and edit form"
      fullWidth
      maxWidth="xl"
      className="custom-card-model"
      sx={{
        "& .MuiDialog-container": {
          "& .MuiPaper-root": {
            maxHeight: "90%",
          },
        },
        ".PinturaUtilMain, .PinturaUtilPanel, .PinturaUtilFooter": {
          padding: 0,
        },
        ".PinturaRootWrapper": {
          display: "flex",
          alignItems: "flex-start",
          padding: "0 10px",
        },
      }}
    >
      <DialogTitle>
        {templateTitle ||
          `Custom Card ${props.mode === "create" ? "Create" : "Edit"}`}

        <Typography
          sx={{
            font: "inherit",
            fontWeight: 600,
            color: theme.palette.primary.main,
            ml: 1,
          }}
          component="span"
        >
          {customDocumentEditable.doc_title}
        </Typography>
      </DialogTitle>
      <DialogContent sx={{ paddingRight: "0 !important", overflow: "hidden" }} className="custom-card-main">
        <Grid container wrap="nowrap" className="custom-card-blog">
          <Grid
          className="custom-card-blog-inner"
            item
            sm={6}
            sx={{
              height: "600px",
              position: "relative",
              overflow: "auto",
            }}
          >
            <Box
              sx={{
                height: "100%",
              }}
            >
              {downloadURL ? (
                <>
                  {!isLoading && simpleDocFormat === "pdf" ? (
                    <PinturaPDFViewer
                      src={src}
                      printProps={{
                        isPrintProcessing: isPrintProcessing,
                        onPrintClick: onPrintClick,
                      }}
                      downloadProps={{
                        isDownloadingDoc: isDownloadingDoc,
                        onDownloadClick: onDownloadClick,
                      }}
                    />
                  ) : (
                    <PinturaImageViewer src={src} disableEdit />
                  )}
                </>
              ) : (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                  }}
                >
                  <Stack direction="column" spacing={2}>
                    <AttachDocumentEmptyState width="150px" />
                    <AttachDocumentCustomCardModal />
                  </Stack>
                </Box>
              )}
            </Box>
          </Grid>
          <Hidden mdDown>
            <Divider orientation="vertical" flexItem />
          </Hidden>
          <Grid
            className="custom-card-blog-form"
            item
            sm={6}
            sx={{
              overflow: "auto",
              maxHeight: "calc(100vh - 302px)",
              minHeight: 450,
              paddingRight: 3,
            }}
          >
            <Box
              className="custom-card-form-inner-section"
              sx={{
                display: "flex",
                flexDirection: "column",
                marginLeft: "35px",
                marginBottom: "20px",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  marginBottom: "20px",
                }}
              >
                <FormControl fullWidth focused>
                  <InputLabel>Card Name:</InputLabel>
                  <DcInput
                    defaultValue={customDocumentEditable.doc_title}
                    error={isEmpty(customDocumentEditable.doc_title)}
                    value={customDocumentEditable.doc_title}
                    placeholder="New Card"
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      setCustomDocumentEditable((prev) => ({
                        ...prev,
                        doc_title: e.target.value,
                      }));
                    }}
                    autoFocus={true}
                    fullWidth
                    multiline
                  />
                </FormControl>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <form className="card-form-inner-blogs">
                  {map(
                    customDocumentEditable?.doc_info,
                    (field: IDocumentField, index: number) => {
                      return (
                        <CustomCardField
                          field={field}
                          index={index}
                          key={index}
                        />
                      );
                    }
                  )}
                  <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                    <Tooltip arrow title="Add Item" placement="top">
                      <DcDocumentIconButton
                        sx={{
                          marginTop: "-15px",
                        }}
                        aria-label="add item"
                        onClick={() => {
                          setCustomDocumentEditable((prev: ICustomDocument) => {
                            const oldDocInfo = prev.doc_info || [];
                            const newDocInfo = [
                              ...oldDocInfo,
                              {
                                title: "",
                                value: "",
                              },
                            ];
                            return {
                              ...prev,
                              doc_info: newDocInfo,
                            };
                          });
                        }}
                      >
                        {/* Add */}
                        <AddOutlinedIcon />
                      </DcDocumentIconButton>
                    </Tooltip>
                  </Box>
                </form>
              </Box>
              {formError && (
                <Typography sx={{ ml: "10px" }} color="error">
                  **Please fill all the required fields.
                </Typography>
              )}
            </Box>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions sx={{ pt: 3 }} className="detach-btn">
        <Stack className="detach-btn-containt" direction="row" spacing={2} sx={{ mr: "auto" }}>
          {downloadURL && (
            <Button
              className="detach-btn"
              variant="outlined"
              onClick={() => {
                document.documentElement.style.setProperty(
                  "--editor-height",
                  "100%"
                );
                setDownloadURL("");
                detachDocument();
              }}
            >
              Detach Document
            </Button>
          )}
          {customDocumentEditable?.id && props.mode === "edit" && (
            <Button
              className="delete-btn"
              color="error"
              variant="outlined"
              aria-label="close window"
              onClick={() => setIsOpenConfirmDelete(true)}
            >
              Delete Card
            </Button>
          )}
        </Stack>
        <Box className="address-btn">
        <Button
          variant="outlined"
          aria-label="close window"
          onClick={() => {
            document.documentElement.style.setProperty(
              "--editor-height",
              "100%"
            );
            props.onModalClose();
          }}
          disabled={isSavingCard}
        >
          Close
        </Button>
        {customDocumentEditable?.id && props.mode === "edit" && (
          <>
            {isOpenConfirmDelete && (
              <ConfirmDialog
                heading="Delete this Card?"
                subHeading="Doing so will permanently delete this card. However, the document attached to the card will remain intact in the document gallery"
                cardTitle={customDocumentEditable.doc_title}
                open={isOpenConfirmDelete}
                setOpen={setIsOpenConfirmDelete}
                onConfirm={() => {
                  customDocumentEditable?.id &&
                    handleDeleteCustomCard(customDocumentEditable.id);
                }}
              />
            )}
          </>
        )}
        <LoadingButton
          loadingPosition="start"
          aria-label="save custom card"
          autoFocus
          variant="contained"
          // color='primary'
          loading={isSavingCard}
          onClick={async (reason) => {
            setIsSavingCard(true);
            handleSave();
            setIsSavingCard(false);
            props.onModalClose();
          }}
          disabled={isSavingCard || formError}
        >
          Save
        </LoadingButton>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default CustomCardModal;
