import { filter, get, map, reduce } from "lodash";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { analytics, rtdb, uploadStorage } from "../firebase";
import * as ArrayUtils from "../utils/arrayUtils";
import { ref, uploadBytesResumable } from "@firebase/storage";
import { useAuth } from "./AuthContext";
import { FileRejection } from "react-dropzone";
import { getDocType } from "../utils/documentUtils";
import constants from "../constants";
import DockedUploadWindow from "../components/Documents/DockedUploadWindow";
import ImageUploadModal from "../components/ImageUploadModal";
import { logEvent } from "firebase/analytics";
import { usePreventRefreshDialogContext } from "./PreventRefreshDialogContext";
import { v4 as uuidv4 } from "uuid";
import { onValue, ref as rtdbRef, query } from "firebase/database";
import DocumentMaybeWrongWarning from "../components/DocumentMaybeWrongWarning";

export type IStoreType = "documents";
export type IAllUploadStates =
  | "pending_upload"
  | "preview_upload"
  | "uploading"
  | "upload_fail"
  | "upload_success"
  | "encrypting_documents"
  | "running_doctype_detection"
  | "doctype_detection_fail"
  | "doctype_detection_success";

export interface IUploadRequest {
  index: number;
  file: File;
  uuid?: string;
  state: IAllUploadStates;
  progress: number;
  error: string | null;
  doctypeDetectionRespose?: any;
  downloadPath?: string;
  isCancelled?: boolean;
}

export interface IDocumentUploadContext {
  uploadingUSATravelHistory: boolean; // #hack to know in travel history component to show loading state
  uploadRequests: IUploadRequest[];
  recentlyUpdatedDocTypeSignal: {
    recentlyUpdatedDocType: string;
    count: number;
  };
  imageFiles: File[];
  pdfFiles: File[];
  clearRecentlyUpdatedDocTypeSignal: () => any;
  createUploadRequests: (files: File[], store: IStoreType) => any;
  createUploadRequestsFromRejectedFiles: (
    rej: FileRejection[],
    store: IStoreType
  ) => any;
  cancelUploadRequest: (req: IUploadRequest) => any;
  closeWindow: () => any;
  imgPreview: (
    // image: HTMLImageElement,
    // crop: PixelCrop,
    // scale: number,
    // rotate: number,
    // index: number,
    prevFile: File,
    index: number,
    imgUrl: string,
    imgFile: File
  ) => any;
  handleDeleteImage: (index: number) => any;
  makeFinalUploadRequest: () => any;
  handleDeletePdf: (index: number) => any;
  tasksInProgressCount: number;
  setUploadingUSATravelHistory: React.Dispatch<React.SetStateAction<boolean>>;
  uploadFiles: (files: File[]) => any;
}

const defaultValues: IDocumentUploadContext = {
  uploadingUSATravelHistory: false,
  uploadRequests: [],
  recentlyUpdatedDocTypeSignal: {
    recentlyUpdatedDocType: "",
    count: 0,
  },
  imageFiles: [],
  pdfFiles: [],
  clearRecentlyUpdatedDocTypeSignal: () => {},
  createUploadRequests: () => {},
  createUploadRequestsFromRejectedFiles: () => {},
  cancelUploadRequest: () => {},
  closeWindow: () => {},
  imgPreview: () => {},
  handleDeleteImage: () => {},
  makeFinalUploadRequest: () => {},
  handleDeletePdf: () => {},
  tasksInProgressCount: -1,
  setUploadingUSATravelHistory: () => {},
  uploadFiles: () => {},
};

const DocumentUploadContext = React.createContext(defaultValues);

export function useDocumentUploadContext() {
  return React.useContext(DocumentUploadContext);
}

const DocumentUploadProvider: React.FC = (props) => {
  const { showPreventRefreshDialog, hidePreventRefreshDialog } =
    usePreventRefreshDialogContext();
  const [uploadingUSATravelHistory, setUploadingUSATravelHistory] =
    React.useState(false);
  const [uploadRequests, setUploadRequests] = React.useState(
    defaultValues.uploadRequests
  );
  const [recentlyUpdatedDocTypeSignal, setRecentlyUpdatedDocTypeSignal] =
    useState(defaultValues.recentlyUpdatedDocTypeSignal);
  const [modalState, setModalState] = React.useState<boolean>(false);
  const [imageFiles, setImageFiles] = React.useState<File[]>(
    defaultValues.imageFiles
  );
  const [pdfFiles, setPdfFiles] = React.useState<File[]>(
    defaultValues.pdfFiles
  );
  const [tasksInProgressCount, setTasksInProgressCount] = React.useState(
    defaultValues.tasksInProgressCount
  );
  const [warningModalOpen, setWarningModalOpen] = React.useState(false);
  const storeType = useRef<IStoreType | null>(null);
  const { currentUser } = useAuth();

  function ctx_patchUploadRequest(
    index: number,
    patch: Partial<IUploadRequest>
  ) {
    setUploadRequests((prev) => {
      return ArrayUtils.patchElement(prev, index, patch);
    });
  }

  async function cancelUploadRequest(uploadRequest: IUploadRequest) {
    ctx_patchUploadRequest(uploadRequest.index, { isCancelled: true });
  }

  const closeWindow = React.useCallback(() => {
    setUploadRequests(defaultValues.uploadRequests);
  }, []);

  // "https://firebasestorage.googleapis.com/v0/b/docucomb-app1.appspot.com/o/documents%2F1637350725493_anant_pp_old_sanatized%20(1).pdf?alt=media&token=9b13237d-1c53-4c3f-9341-20c4344ca273"
  async function _startDocTypeDetection(uploadRequest: IUploadRequest) {
    const ref = rtdbRef(
      rtdb,
      `users/${currentUser?.uid}/upload-requests/${uploadRequest.uuid}`
    );
    const q = query(ref);
    onValue(q, async (snapshot) => {
      console.log("snapshot");
      console.log("snapshot:", snapshot.val());
      const req = snapshot.val();
      if (!req) return;
      if (req.state === "doctype_detection_success") {
        const response = get(req, "doctypeDetectionRespose");
        ctx_patchUploadRequest(uploadRequest.index, {
          state: "doctype_detection_success",
          doctypeDetectionRespose: response,
        });

        const docType = getDocType(response);
        const docPath = get(response, "result.doc_path");
        const docFormat = get(response, "result.doc_format");

        if (docType === "Travel History USA") {
          console.log("detected");
          setUploadingUSATravelHistory(true);
          // setTimeout(() => {
          //   setUploadingUSATravelHistory(false);
          // }, 3000);
          logEvent(analytics, "post_travels_call", { ...uploadRequest });
        }
        setRecentlyUpdatedDocTypeSignal((prev) => {
          return {
            recentlyUpdatedDocType: docType,
            count: prev.count + 1,
          };
        });
      }
      if (req.state === "doctype_detection_fail") {
        ctx_patchUploadRequest(uploadRequest.index, {
          state: "doctype_detection_fail",
          error: "Document processing error. Please retry the upload.",
        });
      }
      // create a variable in local storage that checks if this is the first time the user is uploading a document
      if (!localStorage.getItem(currentUser?.uid + "firstUpload")) {
        setWarningModalOpen(true);
        localStorage.setItem(currentUser?.uid + "firstUpload", "false");
      }
    });
  }

  const startDocTypeDetection = useCallback(_startDocTypeDetection, [
    currentUser?.uid,
  ]);

  function clearRecentlyUpdatedDocTypeSignal() {
    setRecentlyUpdatedDocTypeSignal(defaultValues.recentlyUpdatedDocTypeSignal);
  }

  async function _startUploading(uploadRequest: IUploadRequest) {
    if (!currentUser?.uid) return;

    const uuid = uuidv4();

    ctx_patchUploadRequest(uploadRequest.index, { state: "uploading", uuid });

    const storageRef = ref(
      uploadStorage,
      `/users/${currentUser?.uid}/documents/${uuid}`
    );

    // Create the file metadata
    var metadata = {
      // contentType: 'application/pdf',
      // contentEncoding: 'gzip',
      // 'Content-Encoding': 'gzip',
      // 'Content-Type': 'application/pdf',
      // cacheControl: 'public, max-age=15768000', // 6 months cache data, for one year use 31536000
      customMetadata: {
        uid: currentUser ? currentUser.uid : "",
        localFileName: uploadRequest.file.name,
      },
    };

    const uploadTask = uploadBytesResumable(
      storageRef,
      uploadRequest.file,
      metadata
    );

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
        const progress =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        console.log("Upload is " + progress + "% done");
        // set progress
        ctx_patchUploadRequest(uploadRequest.index, { progress });
        switch (snapshot.state) {
          case "paused":
            console.log("Upload is paused");
            break;
          case "running":
            console.log("Upload is running");
            break;
        }
      },
      (error) => {
        // A full list of error codes is available at
        // https://firebase.google.com/docs/storage/web/handle-errors
        switch (error.code) {
          case "storage/unauthorized":
            // User doesn't have permission to access the object
            console.error(
              `${error.code}: User doesn't have permission to access the object`
            );
            break;
          case "storage/canceled":
            // User canceled the upload
            console.error(`${error.code}: User canceled the upload`);
            break;

          // ...

          case "storage/unknown":
            // Unknown error occurred, inspect error.serverResponse
            console.error(
              `${error.code}: Unknown error occurred, inspect error.serverResponse`
            );
            break;
        }

        // set error
        ctx_patchUploadRequest(uploadRequest.index, {
          error: "Failed to upload due to connection issues. Please retry.",
          state: "upload_fail",
        });
      },
      async () => {
        // Upload completed successfully, now we can get the download URL
        const downloadPath = uploadTask.snapshot.ref.fullPath;
        console.log("File available at", downloadPath, uploadRequest.file);
        uploadRequest.downloadPath = downloadPath;
        uploadRequest.uuid = uuid;
        logEvent(analytics, "upload_file_complete", { ...uploadRequest });

        // set success
        ctx_patchUploadRequest(uploadRequest.index, {
          state: "running_doctype_detection",
          downloadPath: downloadPath,
        });

        // start doctype_detection
        startDocTypeDetection(uploadRequest);
      }
    );
  }
  const startUploading = useCallback(_startUploading, [
    currentUser,
    startDocTypeDetection,
  ]);

  useEffect(() => {
    map(uploadRequests, (req) => {
      if (req.state === "pending_upload") {
        startUploading(req);
      }
    });
  }, [uploadRequests, startUploading]);

  useEffect(() => {
    if (uploadRequests.length === 0) {
      setTasksInProgressCount(-1);
    }
    const tasksInProgressCount = reduce(
      uploadRequests,
      (acc, req) => {
        switch (req.state) {
          case "pending_upload":
          case "uploading":
          case "running_doctype_detection":
            return acc + 1;
          default:
            return acc;
        }
      },
      0
    );

    setTasksInProgressCount(tasksInProgressCount);
  }, [uploadRequests]);

  useEffect(() => {
    if (tasksInProgressCount > 0)
      showPreventRefreshDialog(
        "The documents are still uploading, are you sure want to cancel and refresh?"
      );
    else hidePreventRefreshDialog();
  }, [
    hidePreventRefreshDialog,
    showPreventRefreshDialog,
    tasksInProgressCount,
  ]);

  function createUploadRequests(files: File[], store: IStoreType) {
    storeType.current = store;
    logEvent(analytics, "upload_file", { store_type: store });
    setUploadRequests((prev) => {
      let index = prev.length;
      const newUploadRequests: IUploadRequest[] = map(files, (file) => ({
        file,
        state: "preview_upload",
        progress: 0,
        error: null,
        index: index++,
      }));
      const allUploadRequests = [...prev, ...newUploadRequests];
      ctx_previewUpload(files);
      return allUploadRequests;
    });
  }

  function createUploadRequestsFromRejectedFiles(
    fileRejections: FileRejection[],
    store: IStoreType
  ) {
    storeType.current = store;
    setUploadRequests((prev) => {
      let index = prev.length;
      function getErrorMsg(rej: FileRejection) {
        const error = get(rej.errors, [0, "message"]);
        if ((error || "").includes("File type must be .pdf,application/pdf")) {
          return "File format must be PDF";
        }
        if ((error || "").toLowerCase().includes("too many files")) {
          return `Max limit for file selection is ${constants.maxLimitToUploadDocAtTime}. Please re-select files.`;
        }
        if ((error || "").includes("File is larger than")) {
          return "File size is larger than 20 MB";
        }
        return error;
      }
      const newUploadRequests: IUploadRequest[] = map(
        fileRejections,
        (rej) => ({
          file: rej.file,
          state: "upload_fail",
          progress: 0,
          error: getErrorMsg(rej),
          index: index++,
        })
      );
      const allUploadRequests = [...prev, ...newUploadRequests];

      return allUploadRequests;
    });
  }

  ///////////// The main edit functionality starts /////////////////////
  // let previewUrl = "";

  function ctx_previewUpload(files: File[]) {
    //Filtering images and pdf
    const images = files.filter(
      (file) =>
        file.type === "image/png" ||
        file.type === "image/jpeg" ||
        file.type === "image/jpg"
    );
    const pdf = files.filter((file) => file.type === "application/pdf");
    setImageFiles(images);
    setPdfFiles(pdf);
    const total = images.length + pdf.length;
    if (total > 0) {
      setModalState(true);
    }
  }

  //Saves the edited Image
  function imgPreview(
    prevFile: File,
    index: number,
    imgUrl: string,
    imgFile: File
  ) {
    // previewUrl = imgUrl; //Getting a preview URL (Not required)
    // console.log(prevFile.type);

    const newImgFile = new File([imgFile], prevFile.name, {
      type: prevFile.type,
    });
    //Replacing the new edited image with the old one
    setImageFiles((files: File[]) => {
      files[index] = newImgFile;
      return [...files];
    });

    // return previewUrl;
  }

  //This makes the final upload request after preview
  function makeFinalUploadRequest() {
    const finalFiles = [...imageFiles, ...pdfFiles];
    setUploadRequests((prev) => {
      let index = prev.length;
      const newUploadRequests: IUploadRequest[] = map(finalFiles, (file) => ({
        file,
        state: "pending_upload",
        progress: 0,
        error: null,
        index: index++,
      }));
      setModalState(false);

      const allUploadRequests = [...prev, ...newUploadRequests];

      return allUploadRequests;
    });
  }

  function uploadFiles(files: File[]) {
    setUploadRequests((prev) => {
      const newUploadRequests: IUploadRequest[] = map(files, (file, i) => ({
        file,
        state: "pending_upload",
        progress: 0,
        error: null,
        index: i,
      }));
      return newUploadRequests;
    });
  }

  ///////////// The main edit functionality ends /////////////////////

  //This is to delete the Image form Preview Modal
  const handleDeleteImage = React.useCallback((id: number) => {
    setImageFiles((file) => {
      return filter(file, (prevDocs: any, index: number) => {
        return index !== id;
      });
    });
  }, []);

  //This is to delete the Pdf form Preview Modal
  const handleDeletePdf = React.useCallback((id: number) => {
    setPdfFiles((file) => {
      return filter(file, (prevDocs: any, index: number) => {
        return index !== id;
      });
    });
  }, []);

  //This closes the modal and clears all the state
  function handleModalClose() {
    setModalState(false);
    // setPdfFiles([]);
    // setImageFiles([]);
  }

  return (
    <DocumentUploadContext.Provider
      value={{
        uploadingUSATravelHistory,
        uploadRequests,
        recentlyUpdatedDocTypeSignal,
        clearRecentlyUpdatedDocTypeSignal,
        createUploadRequests,
        createUploadRequestsFromRejectedFiles,
        cancelUploadRequest,
        closeWindow,
        imageFiles,
        pdfFiles,
        imgPreview,
        handleDeleteImage,
        makeFinalUploadRequest,
        handleDeletePdf,
        tasksInProgressCount,
        setUploadingUSATravelHistory,
        uploadFiles,
      }}
    >
      <DocumentMaybeWrongWarning
        open={warningModalOpen}
        setOpen={setWarningModalOpen}
      />
      <ImageUploadModal
        modalState={modalState}
        handleModalClose={handleModalClose}
      />
      <DockedUploadWindow />
      {props.children}
    </DocumentUploadContext.Provider>
  );
};

export default DocumentUploadProvider;
