import { Box, Tooltip } from "@mui/material";
import React, { Dispatch, SetStateAction } from "react";
import theme from "../../assets/theme/theme";
import LockOpenOutlinedIcon from "@mui/icons-material/LockOpenOutlined";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";

interface Props {
  isInfoVisible: boolean;
  setIsInfoVisible: Dispatch<SetStateAction<boolean>>;
  fieldLabel: String | null;
}
const DisplayLockUnlock: React.FC<Props> = ({
  isInfoVisible,
  setIsInfoVisible,
  fieldLabel,
}) => {
  const styles = {
    color: isInfoVisible
      ? theme.palette.error.main
      : theme.palette.success.main,
    fontSize: 20,
  };
  const boxStyles = {
    marginTop: "3px",
    marginLeft: "7px",
    height: 24,
    width: 24,
    minHeight: 24,
    minWidth: 24,
    borderRadius: 24,
    background: isInfoVisible
      ? theme.palette.error.light
      : theme.palette.success.light,
    cursor: "pointer",
    display: "inline-flex",
    justifyContent: "center",
    alignItems: "center",
  };
  return (
    <Box
      sx={boxStyles}
      component="span"
      onClick={() => setIsInfoVisible(!isInfoVisible)}
    >
      {isInfoVisible ? (
        <Tooltip arrow title={`Hide ${fieldLabel}`} placement="top">
          <LockOpenOutlinedIcon sx={styles} />
        </Tooltip>
      ) : (
        <Tooltip arrow title={`View ${fieldLabel}`} placement="top">
          <LockOutlinedIcon sx={styles} />
        </Tooltip>
      )}
    </Box>
  );
};

export default DisplayLockUnlock;
