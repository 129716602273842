import React from "react";
import { DCButton } from "../../assets/theme/theme";
import { Stack, Typography } from "@mui/material";

export const CancelPayment = function () {
  return (
    <Stack
      margin={8}
      direction="column"
      justifyContent="center"
      alignItems="center"
      spacing={2}
      textAlign={"center"}
    >
      <Typography variant="h1" component="h1">
        Payment Unsuccessful!!!
      </Typography>
      <Typography variant="h3" component="h3" fontSize={18}>
        We're sorry, but it appears that your recent payment attempt was not
        successful. Please retry.
      </Typography>
      <DCButton
        variant="contained"
        onClick={(evt) => {
          evt.preventDefault();
          window.close();
        }}
      >
        Close Tab
      </DCButton>
    </Stack>
  );
};
