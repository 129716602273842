import React, { FC, HTMLProps } from "react";
// @ts-ignore
import Flags from "country-flag-icons/react/3x2";
// @ts-ignore
import { hasFlag } from "country-flag-icons";

interface Props extends HTMLProps<HTMLOrSVGElement> {
  code: string;
}
export const Flag: FC<Props> = ({ code, ...props }) => {
  if (!hasFlag(code)) {
    return null;
  }
  
  const FlagComponent = Flags[code];
  return <FlagComponent {...props} />;
};
