import { doc, getDoc } from "firebase/firestore"
import { get, map } from "lodash"
import React from "react"
import { useParams } from "react-router-dom"
import Spinner from "../components/Spinner"
import constants from "../constants"
import { firestore } from "../firebase"
import { IShareReq } from "../firebaseTypes"

type IErrors = 'FAILED_TO_LOAD_SHARE_REQ' | ''

export interface IPublicDocumentSharePageContext {
  shareReq?: IShareReq,
  shareReqExpired?: IShareReq,
  documentInViewIndex: number,
  error: IErrors,
  selectedDocumentIndices: boolean[],
  view: "grid" | "list",
  docDownloadUrls: { docUrls: string[]; imgUrls: string[] };
  setView: React.Dispatch<React.SetStateAction<"grid" | "list">>,
  setDocumentInViewIndex: React.Dispatch<React.SetStateAction<number>>,
  selectDeselectDocument: (index: number) => void,
  selectAll: () => void,
  deselectAll: () => void,
}

const defaultValues: IPublicDocumentSharePageContext = {
  error: '',
  documentInViewIndex: -1,
  selectedDocumentIndices: [],
  view: "grid",
  docDownloadUrls: {
    docUrls: [],
    imgUrls: [],
  },
  setView: () => { },
  setDocumentInViewIndex: () => { },
  selectDeselectDocument: () => { },
  selectAll: () => { },
  deselectAll: () => { },
}

const Context = React.createContext<IPublicDocumentSharePageContext>(defaultValues)

export function usePublicDocumentSharePageContext() {
  return React.useContext(Context)
}

async function sanatizeShareReq(shareReq: IShareReq) : Promise<IShareReq[]> {
  if(shareReq.signedURLs){
    // check if the signed urls are expired
    await Promise.all(
      shareReq.signedURLs.map(async (url, i) => {
        const res = await fetch(url);
        if (res.status === 400) { // while manually testing add res.status === 403 as well if you have changes the urls from database
          shareReq.documents[i].is_expired = true; // if the url is expired, set the is_expired flag to true
        } else {
          shareReq.documents[i].is_expired = false; // if the url is not expired, set the is_expired flag to false
        }
      })
    ) 
  }

  // filter out the not expired documents
  const _shareReq = {
    ...shareReq,
    documents : shareReq?.documents.filter((doc) => {return doc.is_expired === false}), // filter out the not expired documents
    signedURLs : shareReq?.signedURLs?.filter((url, i) => {if(shareReq.documents[i].is_expired === false){return url}else{return null} }), // filter out the not expired urls
    signedThumbnailURLs : shareReq?.signedThumbnailURLs?.filter((url, i) => {if(shareReq.documents[i].is_expired === false){return url}else{return null} }) // filter out the not expired thumbnail urls
  }  

  const _shareReqExpired = {
    ...shareReq,
    documents : shareReq?.documents.filter((doc) => {return doc.is_expired === true}), // filter out the expired documents
    signedURLs : shareReq?.signedURLs?.filter((url, i) => {if(shareReq.documents[i].is_expired === true){return url}else{return null} }), // filter out the expired urls
    signedThumbnailURLs : shareReq?.signedThumbnailURLs?.filter((url, i) => {if(shareReq.documents[i].is_expired === true){return url}else{return null} }) // filter out the expired thumbnail urls
  }

  return [_shareReq, _shareReqExpired] // return the not expired and expired documents in ana array
}

export const PublicDocumentSharePageProvider: React.FC = (props) => {
  const isFetchingShareReq = React.useRef(false)
  const { shareReqId } = useParams<{ shareReqId: string }>()
  const [shareReq, setShareReq] = React.useState(defaultValues.shareReq);
  const [shareReqExpired, setShareReqExpired] = React.useState(defaultValues.shareReqExpired)
  const [error, setError] = React.useState(defaultValues.error)
  const [isLoading, setIsLoading] = React.useState(true)
  const [documentInViewIndex, setDocumentInViewIndex] = React.useState(defaultValues.documentInViewIndex)
  const [selectedDocumentIndices, setSelectedDocumentIndices] = React.useState(defaultValues.selectedDocumentIndices)
  const [view, setView] = React.useState<"grid" | "list">("grid")

  React.useEffect(() => {
    async function getShareReq() {
      if (shareReqId && !isFetchingShareReq.current
      ) {
        try {
          isFetchingShareReq.current = true
          setError("")
          const docRef = doc(firestore, `${constants.collections.docShareRequest}/${shareReqId}`)
          const snapshot = await getDoc(docRef)
          const _data = snapshot.data() as IShareReq
          const [shareReqNotExpired, shareReqExpired] = await sanatizeShareReq(_data); // get the not expired and expired documents by destructuring the array
          setShareReq(shareReqNotExpired) 
          setShareReqExpired(shareReqExpired)
          setSelectedDocumentIndices(map(shareReqNotExpired.documents, doc => { return false }))
        } catch (err) {
          console.error(err)
          setError('FAILED_TO_LOAD_SHARE_REQ')
        } finally {
          setIsLoading(false)
          isFetchingShareReq.current = false
        }
      }
    }
    getShareReq()
  }, [shareReqId])

  const selectDeselectDocument = React.useCallback((index: number) => {
    setSelectedDocumentIndices(prev => {
      const result = map(prev, (val, i) => {
        if (i === index) return !val
        return !!val
      })
      return result
    })
  }, [])

  const selectAll = React.useCallback(() => {
    setSelectedDocumentIndices(prev => {
      return map(prev, val => true)
    })
  }, [])

  const deselectAll = React.useCallback(() => {
    setSelectedDocumentIndices(prev => {
      return map(prev, val => false)
    })
  }, [])

  const docDownloadUrls = React.useMemo((): {
    docUrls: string[];
    imgUrls: string[];
  } => {
    const res: { docUrls: string[]; imgUrls: string[] } = {
      docUrls: [],
      imgUrls: [],
    };

    shareReq?.documents.forEach((doc, i) => {
      if (selectedDocumentIndices[i]) {
        if (doc.doc_format === "application/pdf") {
          res.docUrls.push(get(shareReq, ["signedURLs", i]));
        } else {
          res.imgUrls.push(get(shareReq, ["signedURLs", i]));
        }
      }
    });

    return res;
  }, [shareReq, selectedDocumentIndices]);

  return <Context.Provider value={{
    shareReq,
    shareReqExpired,
    selectedDocumentIndices,
    documentInViewIndex,
    error,
    view,
    docDownloadUrls,
    setDocumentInViewIndex,
    selectDeselectDocument,
    selectAll,
    deselectAll,
    setView
  }}>
    {isLoading ? <Spinner /> : props.children}
  </Context.Provider>
}