import React from "react";
import { Grid, Grow } from "@mui/material";
import DCRadioButton from "./DCRadioButton";
import CloudStorage from "../../assets/images/radio-icons/cloud_storage.svg";
import EmailStorage from "../../assets/images/radio-icons/email_storage.svg";
import RandomFolderStorage from "../../assets/images/radio-icons/folder_storage.svg";
import UnknownStorage from "../../assets/images/radio-icons/unknown_storage.svg";
import { get } from "lodash";
import { useUserProfileContext } from "../../contexts/UserProfileContext";

export interface Props {}

const DocumentSavingPreference: React.FC<Props> = (props) => {
  const { userProfile, setUserProfile } = useUserProfileContext();

  const handleClick = React.useCallback(
    (event) => {
      setUserProfile((prev) => {
        const old = prev?.docSavingPreference;
        const docSavingPreference = {
          ...old,
          [event.target.name]: !get(old, [event.target.name], false),
        };
        return {
          ...prev,
          docSavingPreference,
        } as any;
      });
    },
    [setUserProfile]
  );

  if (!userProfile) return null;
  return (
    <Grow in={true}>
      <Grid container spacing={4}>
        <Grid item xs={12} sm={6}>
          <DCRadioButton
            label="Cloud Storage - 
Google drive, dropbox, etc."
            name="cloud-storage"
            value="cloud-storage"
            checked={userProfile.docSavingPreference["cloud-storage"]}
            handleClick={handleClick}
            icon={CloudStorage}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <DCRadioButton
            label="In some 
random email chains"
            name="email-chain"
            value="email-chain"
            checked={userProfile.docSavingPreference["email-chain"]}
            handleClick={handleClick}
            icon={EmailStorage}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <DCRadioButton
            label="Meticulously stored
in a file folder in a safe location"
            name="file-folder"
            value="file-folder"
            checked={userProfile.docSavingPreference["file-folder"]}
            handleClick={handleClick}
            icon={RandomFolderStorage}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <DCRadioButton
            label="I don’t know where the 
documents are, but I can find them"
            name="dont-know"
            value="dont-know"
            checked={userProfile.docSavingPreference["dont-know"]}
            handleClick={handleClick}
            icon={UnknownStorage}
          />
        </Grid>
      </Grid>
    </Grow>
  );
};

export default DocumentSavingPreference;
