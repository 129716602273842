import React, { useCallback } from "react";
import { Box } from "@mui/system";
import theme, {
  DCDockedUploadWindow,
  DcDocumentIconButton,
  UploadListItem,
} from "../../assets/theme/theme";
import {
  CircularProgress,
  CircularProgressProps,
  List,
  ListItemAvatar,
  ListItemText,
  IconButton,
  Typography,
  Grid,
  IconButtonProps,
  Stack,
  Button,
  Tooltip,
} from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import PriorityHighRoundedIcon from "@mui/icons-material/PriorityHighRounded";
import {
  IUploadRequest,
  useDocumentUploadContext,
} from "../../contexts/DocumentUploadContext";
import { filter, find, get, map, reduce } from "lodash";
import * as BinaryUtils from "../../utils/binaryUtils";
import { getDocType, toDocTypeSlug } from "../../utils/documentUtils";
import constants from "../../constants";
import {
  MoveToFolderModalProvider,
  useMoveToFolderModalContext,
} from "../../contexts/MoveToFolderModalContext";
import DocumentShield from "../Shared/Icons/DocumentShieldIcon";
import { useDocumentContext } from "../../contexts/DocumentContext";
import { IDocument } from "../../firebaseTypes";

export interface Props {}

// const DismissTimer: React.FC<{
//   tasksInProgressCount: number;
//   uploadRequestsCount: number;
//   failedReqCount: number;
//   countUploading: number;
//   closeWindow: () => any;
// }> = ({
//   tasksInProgressCount,
//   uploadRequestsCount,
//   failedReqCount,
//   countUploading,
//   closeWindow,
// }) => {
//   const [dismisstime, setdismisstime] = React.useState(100);
//   const [timer, settimer] = React.useState<NodeJS.Timeout>();

//   const manageTimer = useCallback(() => {
//     setdismisstime((prev) => {
//       return prev <= 100 && prev > 0 ? prev - 10 : prev;
//     });
//   }, []);

//   //* For The Timer Logic and make sure the dependency to this useEffect is always count(Number) so that we dont want to create unnecessary instances of setInterval
//   useEffect(() => {
//     if (
//       tasksInProgressCount === 0 &&
//       uploadRequestsCount > 0 &&
//       failedReqCount === 0
//     ) {
//       const _timer = setInterval(manageTimer, 1000);
//       settimer(_timer);
//     }

//     return () => timer && clearInterval(timer);
//   }, [tasksInProgressCount, failedReqCount]);

//   if (dismisstime === 0) {
//     timer && clearInterval(timer);
//     closeWindow();
//     setdismisstime(100);
//     settimer(undefined);
//   }

//   //* Resets the Timer when user uploads another document
//   useEffect(() => {
//     setdismisstime(100);
//   }, [countUploading]);

//   return (
//     <>
//       {tasksInProgressCount === 0 && timer ? (
//         <CircularProgress variant="determinate" value={dismisstime} />
//       ) : (
//         <CircularProgress
//           variant="determinate"
//           value={dismisstime}
//           sx={{ visibility: "hidden" }}
//         />
//       )}
//     </>
//   );
// };

function CircularProgressWithLabel(
  props: CircularProgressProps & { value: number; error?: boolean }
) {
  const color = props.error
    ? "error"
    : props.value === 100
    ? "success"
    : "primary";
  return props.value === 100 ? (
    <DocumentShield 
      sx={{ position: "relative", display: "inline-flex", top: 7 }}
      color="success"
      fontSize="large"
    />
  ) : (
    <Box sx={{ position: "relative", display: "inline-flex" }} top={7} className="uploaded-item">
      <CircularProgress
        size={48}
        thickness={2}
        variant="determinate"
        color={color}
        sx={{
          boxShadow: `0 0 0 2px inset ${theme.palette.common.border}`,
          borderRadius: 100,
        }}
        {...props}
      />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: "absolute",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {props.error ? (
          <PriorityHighRoundedIcon
            sx={{ color: theme.palette.error.main }}
            fontSize="small"
          />
        ) : props.value === 100 ? (
          <CheckIcon color="success" fontSize="small" />
        ) : (
          <Typography variant="caption" component="div">{`${Math.round(
            props.value
          )}%`}</Typography>
        )}
      </Box>
    </Box>
  );
}

function FakeProgressBar() {
  // offset for encrypting docs = 32
  // offset for detecting doc type = 9
  // timeline => 0, 16, 32 ... 160 , reset to 0
  // timeline => 0, 16, 32 ... 160, show spinner if > than 160
  const offset1 = 16; // take 10 jumps
  const offset2 = 16; // take 10 jumps
  const [progress, setProgress] = React.useState(0);
  const [showSpinner, setShowSpinner] = React.useState(false);
  const [showEncryption, setShowEncryption] = React.useState(true);

  React.useEffect(() => {
    const timer = setInterval(() => {
      setProgress((prevProgress) => {
        // 160 is total width of file-upload-status
        if (prevProgress >= 160 && !showEncryption) {
          setShowSpinner(true);
          return prevProgress;
        } else if (prevProgress < 160 && showEncryption) {
          return prevProgress + offset1;
        } else if (prevProgress < 160 && !showEncryption) {
          return prevProgress + offset2;
        } else {
          setShowEncryption(false);
          setProgress(0);
          return 0 + offset2;
        }
      });
    }, 400);
    return () => {
      clearInterval(timer);
    };
  }, [showEncryption]);

  return showEncryption ? (
    <div className="file-upload-status">
      {/* <LinearProgress
        color='success'
        sx={{
          width: '100%',
          borderRadius: 100,
        }}
      /> */}
      <Typography zIndex={1} position="relative" variant="caption">
        Encrypting Document
      </Typography>
      {showSpinner && (
        <CircularProgress
          sx={{ ml: "5px", mt: "2px" }}
          color="inherit"
          size={12}
        />
      )}
      <div className="progress" style={{ width: progress }} />
    </div>
  ) : (
    <div className="file-upload-status">
      {
        /* <LinearProgress
        color='success'
        sx={{
          width: '100%',
          borderRadius: 100,
        }}
      /> */ <Typography zIndex={1} position="relative" variant="caption">
          Detecting doc type
          {showSpinner && (
            <CircularProgress
              sx={{ ml: "5px", mt: "2px" }}
              color="inherit"
              size={12}
            />
          )}
        </Typography>
      }
      <div className="progress" style={{ width: progress }} />
    </div>
  );
}

function FakeDisabledProgressBar() {
  return (
    <div className="file-upload-status">
      <Typography zIndex={1} position="relative" variant="caption">
        Will Detect doc type
      </Typography>
      <div className="progress" style={{ width: 0 }} />
    </div>
  );
}

function MoveToFolderButton() {
  const { openModal } = useMoveToFolderModalContext();
  return (
    <Stack direction="row">
      <Tooltip
        title={
          'Document is successfully saved in "Others" folder however we couldn\'t detect the document type.'
        }
        arrow
        placement="left-end"
      >
        <span>
          <Button
            variant="text"
            onClick={openModal}
            fullWidth
            className="file-upload-status file-upload-status--button"
          >
            <Typography
              zIndex={1}
              position="relative"
              variant="caption"
              fontWeight="500"
            >
              Select Document Type
            </Typography>
          </Button>
        </span>
      </Tooltip>
      {/* <InfoIcon color="primary" /> */}
    </Stack>
  );
}

function DocTypeLabel(props: { docType: string }) {
  return (
    <Box className="file-upload-status file-upload-status--success">
      <Typography
        zIndex={1}
        position="relative"
        variant="caption"
        fontWeight="500"
      >
        {props.docType || "Unrecognized"}
      </Typography>
    </Box>
  );
}

function CloseButton(props: { req: IUploadRequest } & IconButtonProps) {
  const { cancelUploadRequest } = useDocumentUploadContext();
  const { req, ...rest } = props;

  return (
    <IconButton
      className="file-cancel-button"
      onClick={() => cancelUploadRequest(req)}
      {...rest}
    >
      <CloseIcon fontSize="small" />
    </IconButton>
  );
}

function Uploading(props: { req: IUploadRequest }) {
  const { documents } = useDocumentContext();
  const associatedDoc = React.useMemo<IDocument | undefined>(() => {
    return find(documents, (d) => {
      return (
        d.doc_path === get(props.req.doctypeDetectionRespose, "result.doc_path")
      );
    });
  }, [documents, props.req.doctypeDetectionRespose]);

  return (
    <UploadListItem
      className="upload-list-items"
      secondaryAction={
        <Grid container alignItems="center">
          {/* Uploading document */}
          {props.req.state === "uploading" && <FakeDisabledProgressBar />}

          {/* Fetching doc type */}
          {props.req.state === "running_doctype_detection" && (
            <FakeProgressBar />
          )}

          {/* doctype found */}
          {props.req.state === "doctype_detection_success" && (
            <DocTypeDetectionSuccess
              req={props.req}
              associatedDoc={associatedDoc}
            />
          )}

          {/* close icon */}
          <CloseButton
            req={props.req}
            disabled={props.req.state !== "doctype_detection_success"}
          />
        </Grid>
      }
    >
      <ListItemAvatar sx={{ marginRight: "12px", minWidth: "unset" }} className="upload-file-avatar">
        <CircularProgressWithLabel value={props.req.progress} />
      </ListItemAvatar>
      <ListItemText
       className="upload-file-list-items"
        primaryTypographyProps={{
          noWrap: true,
          sx: {
            // 225px is the fixed width of the fakeProgressBar & close icon
            width: "calc(100% - 225px)",
          },
          title: props.req.file.name,
        }}
        primary={props.req.file.name}
        secondary={BinaryUtils.humanFileSize(props.req.file.size, true)}
      />
    </UploadListItem>
  );
}

function DocTypeDetectionSuccess(props: {
  req: IUploadRequest;
  associatedDoc?: IDocument;
}) {
  const associatedDoc = props.associatedDoc;
  const docType = associatedDoc
    ? associatedDoc.doc_type || constants.docTypes.null
    : getDocType(props.req.doctypeDetectionRespose);

  const isOthersDocType =
    toDocTypeSlug(docType) === constants.docTypeSlugs.others;

  if (isOthersDocType && associatedDoc) {
    return (
      <MoveToFolderModalProvider document={associatedDoc}>
        <MoveToFolderButton />
      </MoveToFolderModalProvider>
    );
  } else {
    return <DocTypeLabel docType={docType} />;
  }
}

function Error(props: { req: IUploadRequest }) {
  return (
    <>
      <UploadListItem
        secondaryAction={
          <Grid container alignItems="center">
            <CloseButton req={props.req} />
          </Grid>
        }
      >
        <ListItemAvatar sx={{ marginRight: "12px", minWidth: "unset" }} >
          <CircularProgressWithLabel error={true} value={100}  />
        </ListItemAvatar>
        <ListItemText
        className="uploadWindow"
          primary={props.req.file.name}
          secondary={props.req.error}
          secondaryTypographyProps={{
            color: theme.palette.error.main,
            fontSize: ".75rem !important",
          }}
        />
      </UploadListItem>
    </>
  );
}

const DockedUploadWindow: React.FC<Props> = (props) => {
  const [show, setShow] = React.useState(true);
  const { uploadRequests, closeWindow } = useDocumentUploadContext();

  const failedReqCount = filter(uploadRequests, (req) =>
    (req.state || "").toLowerCase().includes("fail")
  ).length;

  const countUploading = reduce(
    uploadRequests,
    (acc, req) => {
      switch (req.state) {
        case "pending_upload":
        case "uploading":
        case "running_doctype_detection":
          return acc + 1;
        default:
          return acc;
      }
    },
    0
  );

  const getUploadWindowTitle = React.useCallback(() => {
    const plural = countUploading > 1 ? "s" : "";
    const isUploading = countUploading > 0;
    if (failedReqCount > 0) {
      return `Problem detected with ${failedReqCount} document${plural}`;
    }
    return isUploading ? (
      <>
        <CircularProgress color="inherit" size={"1.2rem"} sx={{ mr: 1 }}/>
        Uploading {countUploading} document{plural}
      </>
    ) : (
      `Uploaded`
    );
  }, [countUploading, failedReqCount]);

  let reverseUploadRequests: IUploadRequest[] = [];
  for (let index = uploadRequests.length - 1; index >= 0; index--) {
    reverseUploadRequests = [...reverseUploadRequests, uploadRequests[index]];
  }
  const listItems = map(reverseUploadRequests, (req, id) => {
    if (req.isCancelled) return null;
    switch (req.state) {
      // "pending_upload" | "uploading" | "upload_fail" | "upload_success" | "running_doctype_detection" | "doctype_detection_fail" | "doctype_detection_success"
      case "upload_fail":
      case "doctype_detection_fail":
        return <Error req={req} key={id} />;
      case "pending_upload":
      case "uploading":
      case "upload_success":
      case "running_doctype_detection":
      case "doctype_detection_success":
        return <Uploading req={req} key={id} />;
    }
  });

  const _handleToggle = () => {
    setShow(!show);
  };
  const handleToggle = useCallback(_handleToggle, [show]);

  // Return null
  if (filter(uploadRequests, (req) => !req.isCancelled).length <= 0)
    return null;

  return (
    <DCDockedUploadWindow elevation={3} className="doc-upload-window">
      <Box
        className="dockedwindow-header"
        fontWeight={500}
        alignItems="center"
        display="flex"
        flex={1}
        sx={{
          position: "relative",
        }}
      >
        {getUploadWindowTitle()}
        <Box ml={1} onClick={handleToggle}>
          <DcDocumentIconButton>
            {show ? (
              <ExpandMoreIcon fontSize="small" />
            ) : (
              <ExpandLessIcon fontSize="small" />
            )}
          </DcDocumentIconButton>
        </Box>
        {countUploading === 0 && (
          <Box
            sx={{
              right: "15px",
              position: "absolute",
            }}
            onClick={(evt) => {
              evt.stopPropagation();
              closeWindow();
            }}
          >
            <DcDocumentIconButton>
              <CloseIcon fontSize="small" />
            </DcDocumentIconButton>
          </Box>
        )}
      </Box>
      {show && (
        <Box className="dockedwindow-body">
          <List sx={{ padding: "0" }} className="file-upload-list">
            <>{listItems}</>
            {/* <UploadListItem
              className="upload-list-main"
              secondaryAction={
                <Grid container alignItems='center'>
                  <div className='file-upload-status'>
                    <Typography
                      zIndex={1}
                      position='relative'
                      variant='caption'
                      color={theme.palette.text.secondary}
                    >
                      Fetching doc type
                    </Typography>
                    <div className='progress' style={{ width: progress }} />
                  </div>
                  <IconButton className='file-cancel-button'>
                    <CloseIcon fontSize='small' />
                  </IconButton>
                </Grid>
              }
            >
              <ListItemAvatar sx={{ marginRight: '12px' }}>
                <CircularProgressWithLabel value={progress} />
              </ListItemAvatar>
              <ListItemText primary='Vikhyath-Prov appl 1 (Both pages).pdf' secondary='Jan 9, 2014' />
            </UploadListItem>
            <Divider component='li' /> */}

            {/* <UploadListItem
              secondaryAction={
                <Grid container alignItems='center'>
                  <div className='file-upload-status'>
                    <Typography
                      zIndex={1}
                      position='relative'
                      variant='caption'
                      color={theme.palette.text.secondary}
                    >
                      Fetching doc type
                    </Typography>
                    <div className='progress' style={{ width: '50%' }} />
                  </div>
                  <IconButton className='file-cancel-button'>
                    <CloseIcon fontSize='small' />
                  </IconButton>
                </Grid>
              }
            >
              <ListItemAvatar sx={{ marginRight: '12px' }}>
                <CircularProgressWithLabel value={progress} />
              </ListItemAvatar>
              <ListItemText primary='Photos' secondary='Jan 9, 2014' />
            </UploadListItem>
            <Divider component='li' /> */}

            {/* Error */}
            {/* <Error req={{
              file: {
                name: "pooja.jpeg"
              },
              error: "File type must be .pdf,application/pdf"
            } as IUploadRequest} /> */}

            {/* <UploadListItem
              secondaryAction={
                <Grid container alignItems='center'>
                  <div className='file-upload-status file-upload-status--success'>
                    <Typography
                      zIndex={1}
                      position='relative'
                      variant='caption'
                      color={theme.palette.text.secondary}
                      fontWeight='500'
                    >
                      EAD
                    </Typography>
                  </div>
                  <IconButton className='file-cancel-button'>
                    <CloseIcon fontSize='small' />
                  </IconButton>
                </Grid>
              }
            >
              <ListItemAvatar sx={{ marginRight: '12px' }}>
                <CircularProgressWithLabel value={100} />
              </ListItemAvatar>
              <ListItemText primary='Photos' secondary='Jan 9, 2014' />
            </UploadListItem> */}
          </List>
        </Box>
      )}
    </DCDockedUploadWindow>
  );
};

export default DockedUploadWindow;
