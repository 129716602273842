import Box from "@mui/material/Box";
import React, { FC, useEffect, useMemo, useState } from "react";
import { PaymentCard } from "./PaymentCard";
import getStripe from "../../utils/getStripe";
import { getStripeConfig } from "../../utils/environment";
import { logEvent } from "firebase/analytics";
import { analytics } from "../../firebase";
import { usePaymentContext } from "../../contexts/PaymentContext";
import { useLocation, useHistory } from "react-router-dom";
import { CircularProgress, Stack, Typography } from "@mui/material";
import theme, { DCButton } from "../../assets/theme/theme";
import constants from "../../constants";
import { postData } from "../../utils/fetchUtils";
import { useAuth } from "../../contexts/AuthContext";
import { isSubscriptionActive } from "../../utils/userUtils";
import { useUserContext } from "../../contexts/UserContext";
import { ActiveSubscription } from "./ActiveSubsciption";

type StripeStatusType = "success" | "cancel" | null;
interface Props {}
const Payment: FC<Props> = () => {
  const [loadingPlan, setLoadingPlan] = useState<"yearly" | "monthly" | "none">(
    "none"
  );
  const [subType, setSubType] = React.useState("yearly");
  const { search } = useLocation();
  const history = useHistory();
  const { currentUser } = useAuth();
  const { user } = useUserContext();
  const stripeConfig = getStripeConfig();

  const { status, paymentId } = useMemo<{
    status: StripeStatusType;
    paymentId: string | null;
  }>(() => {
    const query = new URLSearchParams(search);
    return {
      status: query.get("status") as StripeStatusType,
      paymentId: query.get("paymentId"),
    };
  }, [search]);

  const {
    loading,
    activePayment,
    onCancelPaymentProcess,
    onCompletePayment,
    onErrorPayment,
    onStartPayment,
  } = usePaymentContext();

  const onSelect = async (type: "Early Adoption Plan") => {
    logEvent(analytics, "begin_checkout", { type });

    if (type === "Early Adoption Plan") {
      const stripe = await getStripe();

      if (stripe) {
        const paymentData = await onStartPayment(
          stripeConfig.PRICE_ID,
          stripeConfig.PRICE
        );

        const { error } = await stripe.redirectToCheckout({
          lineItems: [
            {
              price: stripeConfig.PRICE_ID,
              quantity: 1,
            },
          ],
          mode: "payment",
          successUrl: `${
            window.location.origin
          }/payment?status=success&paymentId=${paymentData?.id || ""}`,
          cancelUrl: `${
            window.location.origin
          }/payment?status=cancel&paymentId=${paymentData?.id || ""}`,
          submitType: "pay",
        });

        if (error) {
          logEvent(analytics, "error_checkout", {
            type,
            status: "error",
            message: error.message,
          });
          console.error(error);
          onErrorPayment(paymentData?.id || "", error);
        }
      }
    } else if (type === "Free") {
      onCompletePayment("Free");
    }
  };

  useEffect(() => {
    if (status && paymentId) {
      history.replace({
        search: "",
      });

      if (status === "success") {
        logEvent(analytics, "begin_checkout", {
          type: "pro",
          status: "success",
        });
        onCompletePayment("Pro", paymentId);
      } else if (status === "cancel") {
        logEvent(analytics, "begin_checkout", {
          type: "pro",
          status: "cancel",
        });
        onCancelPaymentProcess(paymentId);
      }
    }
  }, [status, paymentId, history, onCompletePayment, onCancelPaymentProcess]);

  const onClickEnrollNow = React.useCallback(
    async (plan: "yearly" | "monthly") => {
      try {
        logEvent(analytics, "begin_checkout", { plan });
        setLoadingPlan(plan);
        const url = `${constants.middleHost}/stripe/create-checkout-session`;

        const data = {
          uid: currentUser?.uid,
          priceLookupKey:
            plan === "yearly"
              ? "YEARLY_PRICE_LOOKUP_KEY"
              : "MONTHLY_PRICE_LOOKUP_KEY",
        };
        const token = await currentUser?.getIdToken();
        const headers = {
          Authorization: `Bearer ${token}`,
        };
        const res = await postData(url, data, headers);
        setLoadingPlan("none");
        window.open(res.data.url, "_blank");
      } catch (error: any) {
        console.error(error);
        setLoadingPlan("none");
        logEvent(analytics, "error_checkout", {
          plan,
          status: "error",
          message: error.message,
        });
      }
    },
    [currentUser]
  );

  useEffect(() => {
    if (activePayment) {
      history.replace("/payment/user");
    }
  }, [activePayment, history]);

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" mt={10}>
        <CircularProgress />
      </Box>
    );
  }

  if (isSubscriptionActive(user)) {
    return <ActiveSubscription />;
  }

  return (
    <Box
      className="pay-it-frame"
      sx={{
        backgroundColor: theme.palette.common.white,
        height: "100%",
      }}
      display="flex"
      p={10}
      pt={5}
      alignItems="flex-start"
      justifyContent="center"
    >
      <Stack
        className="pay-it-main"
        direction="column"
        columnGap={5}
        alignItems={"center"}
        justifyContent={"center"}
        gap={5}
      >
        <Box className="pay-it-section">
          <Typography
            align="center"
            fontWeight={500}
            fontSize={"2rem"}
            variant="h2"
            mb={1}
          >
            Pay It{" "}
            <Typography
              display="inline"
              fontWeight="inherit"
              fontSize="inherit"
              color={"primary"}
            >
              Forward
            </Typography>
          </Typography>
          <Typography align="center" fontWeight={500} fontSize={20} mb={2}>
            Help us reach every immigrant, by becoming our early adopter.
          </Typography>
        </Box>
        <Box sx={{ display: "flex" }} className="pay-it-plan">
          <Box className="mask-box">
            <Box
              className="mask"
              style={{
                transform: `translateX(${subType === "monthly" ? 0 : "100px"})`,
              }}
            />
            <DCButton
              disableRipple
              variant="text"
              sx={{
                color:
                  subType === "monthly" ? "#fff" : theme.palette.primary.main,
                bgcolor:
                  subType === "monthly"
                    ? theme.palette.primary.main
                    : "#E2EBFF",
              }}
              onClick={() => setSubType("monthly")}
            >
              Monthly
            </DCButton>
            <DCButton
              disableRipple
              variant="text"
              sx={{
                color:
                  subType === "yearly" ? "#fff" : theme.palette.primary.main,
                bgcolor:
                  subType === "yearly" ? theme.palette.primary.main : "#E2EBFF",
              }}
              onClick={() => setSubType("yearly")}
            >
              Yearly
            </DCButton>
          </Box>
        </Box>
        <Box className="payment-card-main">
        {subType === "yearly" ? (
          <Box className="payment-card-box">
          <PaymentCard
            data={{
              subTitle: "Yearly Subscription Plan",
              type: "yearly",
              title: "immplify Yearly",
              price: "149.99",
              includesTitle: "Why Enroll?",
              includes: [
                "Access exclusive features and priority customer support.",
                "Opportunity to co-design new features",
                "Give back by helping us reach other immigrants",
              ],
            }}
            onSelect={onClickEnrollNow}
            loading={loadingPlan === "yearly"}
            
          />
          </Box>
        ) : (
          <PaymentCard
            data={{
              type: "monthly",
              subTitle: "Monthly Subscription Plan",
              title: "immplify Monthly",
              price: "14.99",
              includesTitle: "Why Enroll?",
              includes: [
                "Access exclusive features and priority customer support.",
                "Opportunity to co-design new features",
                "Give back by helping us reach other immigrants",
              ],
            }}
            onSelect={onClickEnrollNow}
            loading={loadingPlan === "monthly"}
          />
        )}
        </Box>
      </Stack>
    </Box>
  );
};

export default Payment;
