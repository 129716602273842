import * as React from "react";
import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";
function GridIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        width="24"
        height="24"
      >
        <path
          d="M18.5,10.75h-3A2.067,2.067,0,0,1,13.25,8.5v-3A2.067,2.067,0,0,1,15.5,3.25h3A2.067,2.067,0,0,1,20.75,5.5v3A2.067,2.067,0,0,1,18.5,10.75Zm-3-6c-.589,0-.75.161-.75.75v3c0,.589.161.75.75.75h3c.589,0,.75-.161.75-.75v-3c0-.589-.161-.75-.75-.75Zm-7,6h-3A2.067,2.067,0,0,1,3.25,8.5v-3A2.067,2.067,0,0,1,5.5,3.25h3A2.067,2.067,0,0,1,10.75,5.5v3A2.067,2.067,0,0,1,8.5,10.75Zm-3-6c-.589,0-.75.161-.75.75v3c0,.589.161.75.75.75h3c.589,0,.75-.161.75-.75v-3c0-.589-.161-.75-.75-.75Zm13,16h-3a2.067,2.067,0,0,1-2.25-2.25v-3a2.067,2.067,0,0,1,2.25-2.25h3a2.067,2.067,0,0,1,2.25,2.25v3A2.067,2.067,0,0,1,18.5,20.75Zm-3-6c-.589,0-.75.161-.75.75v3c0,.589.161.75.75.75h3c.589,0,.75-.161.75-.75v-3c0-.589-.161-.75-.75-.75Zm-7,6h-3A2.067,2.067,0,0,1,3.25,18.5v-3A2.067,2.067,0,0,1,5.5,13.25h3a2.067,2.067,0,0,1,2.25,2.25v3A2.067,2.067,0,0,1,8.5,20.75Zm-3-6c-.589,0-.75.161-.75.75v3c0,.589.161.75.75.75h3c.589,0,.75-.161.75-.75v-3c0-.589-.161-.75-.75-.75Z"
          fill="currentColor"
        />
      </svg>
    </SvgIcon>
  );
}
export default GridIcon;
