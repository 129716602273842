import { ButtonProps, styled } from "@mui/material";
import React, { FC } from "react";
import { DropzoneOptions, FileRejection, useDropzone } from "react-dropzone";
import { DCButton } from "../../assets/theme/theme";
import constants from "../../constants";
import UploadIcon from "./Icons/UploadIcon";

interface Props extends ButtonProps {
  uploadOptions?: DropzoneOptions;
}

const Input = styled("input")({
  display: "none",
});

export const UploadButton: FC<Props> = ({
  uploadOptions,
  children,
  ...props
}) => {
  const onDrop = (acceptedFiles: File[], fileRejections: FileRejection[]) => {
    console.log("Upload File", acceptedFiles, fileRejections);
  };

  const { getInputProps, open } = useDropzone({
    onDrop,
    noClick: true,
    noKeyboard: true,
    maxFiles: constants.maxLimitToUploadDocAtTime,
    maxSize: 1024 * 1000 * 1000,
    accept: {
      "image/png": [".png"],
      "image/jpg": [".jpg", ".jpeg"],
      "application/pdf": [".pdf"],
    },
    noDrag: true,
    ...uploadOptions,
  });

  return (
    <DCButton
      variant="outlined"
      color="primary"
      startIcon={<UploadIcon />}
      onClick={open}
      {...props}
    >
      <Input {...getInputProps()} />
      {children}
    </DCButton>
  );
};
