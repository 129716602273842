import React, { useCallback, useEffect, useState } from "react";
import { Backdrop, Typography, Box, Divider } from "@mui/material";
import { DCMenu } from "../../assets/theme/theme";

import {
  INotification,
  useNotifications,
} from "../../contexts/NotificationsContext";
import { filter, isEmpty, map, reduce } from "lodash";
import { INotificationsEnum } from "../../firebaseTypes";

import NotificationDocExpiry from "./NotificationDocExpiry";
import NotificationManualEdit from "./NotificationManualEdit";
import { useSnackbar } from "notistack";
import { ISelectedDocuments } from "../../contexts/DocumentGalleryContext";
import { getSelectedDocuments } from "../../utils/documentUtils";
import { useDocumentModalContext } from "../../contexts/DocumentModalContext";
import { useDocumentContext } from "../../contexts/DocumentContext";
import constants from "../../constants";
import DCSnackbar from "../Shared/DCSnackbar";
import NotificationDocRelation from "./NotificationDocRelation";
import { usePdfPreviewModalContext } from "../../contexts/PdfPreviewModalContext";

export interface Props {
  anchorEl?: any;
  open?: any;
  handleClose?: any;
  pageId: string;
}

const Notifications: React.FC<Props> = (props) => {
  const { notifications, markAllNotificationsSeen } = useNotifications();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [openSnackBar, setOpenSnackBar] = useState(false);

  const { openDocumentModalFor } = useDocumentModalContext();
  const { openModalForDocument } = usePdfPreviewModalContext();
  const { documents } = useDocumentContext();

  const onNotificationClick = React.useCallback(
    (note: INotification) => {
      if (
        note.type === INotificationsEnum["edit-action-required"]
        // note.type === INotificationsEnum["doc-relation"]
      ) {
        const selectedDocuments = reduce(
          note.associatedDocs,
          (acc, id) => {
            acc[id] = true;
            return acc;
          },
          {} as ISelectedDocuments
        );
        const docs = getSelectedDocuments(selectedDocuments, documents);
        closeSnackbar();
        openDocumentModalFor(docs);
      }
      if (note.type === INotificationsEnum["doc-expiry"] || note.type === INotificationsEnum["doc-relation"]) {
        const selectedDocuments = reduce(
          note.associatedDocs,
          (acc, id) => {
            acc[id] = true;
            return acc;
          },
          {} as ISelectedDocuments
        );
        const docs = getSelectedDocuments(selectedDocuments, documents);
        closeSnackbar();
        openModalForDocument(docs[0]);
      }
    },
    [documents, closeSnackbar, openDocumentModalFor, openModalForDocument]
  );

  useEffect(() => {
    const unseenLength = filter(notifications, (note) => note.archived === false).filter(
      (note) => note.seen !== true
    ).length;
    if (unseenLength > 0) {
      setOpenSnackBar(true);
    }
  }, [notifications]);

  // useEffect(
  //   () => {
  //     if (
  //       haveDisplayedCombinedSnackbars ||
  //       props.pageId !== constants.pages.dashboard
  //     ) {
  //       closeSnackbar(manualEditCombinedSnackbarId);
  //       closeSnackbar(docExpiryCombinedSnackbarId);
  //       return;
  //     }

  //     const manualEditNotes = filter(notifications, (n) => {
  //       if (n.type === INotificationsEnum["edit-action-required"]) {
  //         return true;
  //       }
  //     });
  //     const docExpiryNotes = filter(notifications, (n) => {
  //       if (n.type === INotificationsEnum["doc-expiry"]) {
  //         return true;
  //       }
  //     });
  //     // remove any already visible snackbar
  //     if (manualEditCombinedSnackbarId) {
  //       closeSnackbar(manualEditCombinedSnackbarId);
  //     }

  //     // remove any already visible snackbar
  //     if (docExpiryCombinedSnackbarId) {
  //       closeSnackbar(docExpiryCombinedSnackbarId);
  //     }

  //     if (!isEmpty(manualEditNotes)) {
  //       const id = enqueueSnackbar({
  //         variant: "manualEditRequiredCombinedSnackbar",
  //         notes: manualEditNotes,
  //         onNotificationClick,
  //         autoHideDuration: 7000,
  //         // persist: true,
  //         anchorOrigin: {
  //           vertical: "top",
  //           horizontal: "right",
  //         },
  //       });
  //       setManualEditCombinedSnackbarId(id);
  //     }

  //     if (!isEmpty(docExpiryNotes)) {
  //       const id = enqueueSnackbar({
  //         variant: "documentExpiryCombinedSnackbar",
  //         notes: docExpiryNotes,
  //         onNotificationClick,
  //         autoHideDuration: 7000,
  //         // persist: true,
  //         anchorOrigin: {
  //           vertical: "top",
  //           horizontal: "right",
  //         },
  //       });
  //       setDocExpiryCombinedSnackbarId(id);
  //     }

  //     if (notifications.length > 0) {
  //       setHaveDisplayedCombinedSnackbars(true);
  //     }
  //   },
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  //   [
  //     // closeSnackbar,
  //     // enqueueSnackbar,
  //     // manualEditCombinedSnackbarId,
  //     props.pageId,
  //     notifications,
  //     // onNotificationClick,
  //   ]
  // );

  const manualEditNotes = filter(filter(notifications, (note) => note.archived === false), (n) => {
    if (n.type === INotificationsEnum["edit-action-required"]) {
      return true;
    }
  });
  const docExpiryNotes = filter(filter(notifications, (note) => note.archived === false), (n) => {
    if (n.type === INotificationsEnum["doc-expiry"]) {
      return true;
    }
  });

  // const docRelations = filter(filter(notifications, (note) => note.archived === false), (n) => {
  //   if (n.type === INotificationsEnum["doc-relation"]) {
  //     return true;
  //   }
  // });

  useEffect(() => {
    if (props.pageId !== constants.pages.dashboard) return;
    if (!isEmpty(manualEditNotes)) {
      enqueueSnackbar({
        variant: "manualEditRequiredCombinedSnackbar",
        notes: manualEditNotes,
        onNotificationClick,
        autoHideDuration: 7000,
        // persist: true,
        anchorOrigin: {
          vertical: "top",
          horizontal: "right",
        },
      });
      // setManualEditCombinedSnackbarId(id);
    }

    if (!isEmpty(docExpiryNotes)) {
      enqueueSnackbar({
        variant: "documentExpiryCombinedSnackbar",
        notes: docExpiryNotes,
        onNotificationClick,
        autoHideDuration: 7000,
        // persist: true,
        anchorOrigin: {
          vertical: "top",
          horizontal: "right",
        },
      });
      // setDocExpiryCombinedSnackbarId(id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [!isEmpty(manualEditNotes), !isEmpty(docExpiryNotes)]);

  const handleSnackbarClose = useCallback(() => {
    setOpenSnackBar(false);
  }, []);

  return (
    <>
      <DCSnackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={openSnackBar}
        title="You have received a new notification"
        onClose={handleSnackbarClose}
      />
      <Backdrop sx={{ color: "#fff", zIndex: 2000 }} open={props.open}>
        <DCMenu
          anchorEl={props.anchorEl}
          open={props.open}
          onClose={() => {
            props.handleClose();
            markAllNotificationsSeen();
          }}
          transformOrigin={{ horizontal: "right", vertical: "top" }}
          anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        >
          {filter(notifications, (note) => note.archived === false).length === 0 ? (
            <Typography
              component="h4"
              sx={{ fontWeight: 500, lineHeight: 3.25, paddingInline: 2.5 }}
            >
              You're all caught up!
            </Typography>
          ) : (
            <>
              <Typography
                variant="subtitle1"
                sx={{
                  fontWeight: 500,
                  lineHeight: 2.5,
                  paddingInline: 2.5,
                }}
              >
                Notifications
              </Typography>
              <Divider component="li" />
            </>
          )}
          {/* todo check for you are all done  */}
          {/* todo check for spinner while loading documents */}
          {map(filter(notifications, (note) => note.archived === false), (note, index) => {
            return (
              <>
                {note.archived === false && (
                  <NotificationListItem
                    note={note}
                    key={index}
                    onNotificationClick={onNotificationClick}
                    setOpenSnackBar={setOpenSnackBar}
                  />
                )}
              </>
            );
          })}
          {/* <Box
        sx={{
          textAlign: "center",
          height: 40,
          marginTop: "10px",
          marginBottom: "-5px",
        }}
      >
         <Link
          to="/"
          style={{
            textDecoration: "none",
            fontWeight: 500,
            fontSize: "0.9rem",
          }}
        >
          View More
        </Link>
      </Box> */}
        </DCMenu>
      </Backdrop>
    </>
  );
};

export default Notifications;

function NotificationListItem({
  note,
  onNotificationClick,
  setOpenSnackBar,
}: {
  note: INotification;
  onNotificationClick: (note: INotification) => void;
  setOpenSnackBar: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  return (
    <Box>
      {note.type === INotificationsEnum["edit-action-required"] && (
        <NotificationManualEdit
          onNotificationClick={onNotificationClick}
          note={note}
        />
      )}
      {note.type === INotificationsEnum["doc-expiry"] && (
        <NotificationDocExpiry
          onNotificationClick={onNotificationClick}
          note={note}
        />
      )}
      {note.type === INotificationsEnum["doc-relation"] && (
        // <NotificationDocRelation
        //   onNotificationClick={onNotificationClick}
        //   note={note}
        // />
        null
      )}
      {/* {index !== notifications.length - 1 && (
    <Divider component="li" />
  )} */}
    </Box>
  );
}
