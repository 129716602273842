import React, { useCallback } from "react";
import { useAuth } from "./AuthContext";
import { analytics, rtdb } from "../firebase";
import { logEvent } from "firebase/analytics";
import DeleteTravelHistoryModalNew from "../components/TravelHistoryNew/DeleteTravelHistoryModalNew";
import { remove, ref } from "firebase/database";
import { map } from "lodash";
import { useTravelHistoryContextNew } from "../components/TravelHistoryNew/TravelHistoryContextNew";

export interface IDeleteTravelHistoryModalContextNew {
  // States
  openDelete: boolean; // uses this to open/close delete modal
  selectedRowData: {
    selectedRowsUsa: string[];
    selectedRowsWorld: string[];
    allSelectedUsa: boolean; // is a UI checkbox state
    allSelectedWorld: boolean; // is a UI checkbox state
    deleteAllUsaRecords: boolean;
    deleteAllWorldRecords: boolean;
  }; // selected for editing
  setSelectedRowData: React.Dispatch<
    React.SetStateAction<{
      selectedRowsUsa: string[];
      selectedRowsWorld: string[];
      allSelectedUsa: boolean;
      allSelectedWorld: boolean;
      deleteAllUsaRecords: boolean;
      deleteAllWorldRecords: boolean;
    }>
  >; // uses this to set selected travel history

  // Functions
  openDeleteTravelHistoryModal: () => void; // uses this to open modal for a travel history delete
  closeDeleteTravelHistoryModal: () => void; // uses this to close modal for a travel history delete
  deleteSelectedTravelRecords: () => void; // uses this to delete travel history
}

export const defaultValues: IDeleteTravelHistoryModalContextNew = {
  // States
  openDelete: false,
  selectedRowData: {
    selectedRowsUsa: [],
    selectedRowsWorld: [],
    allSelectedUsa: false,
    allSelectedWorld: false,
    deleteAllUsaRecords: false,
    deleteAllWorldRecords: false,
  },
  setSelectedRowData: () => {}, // state --> setSelectedRowData

  // Functions
  openDeleteTravelHistoryModal: () => {},
  closeDeleteTravelHistoryModal: () => {},
  deleteSelectedTravelRecords: () =>
    Promise.resolve({ success: false, error: "" }),
};

const DeleteTravelHistoryModalContextNew =
  React.createContext<IDeleteTravelHistoryModalContextNew>(defaultValues);

export function useDeleteTravelHistoryModalContextNew() {
  return React.useContext(DeleteTravelHistoryModalContextNew);
}

export interface Props {
  travelState: "usa" | "world";
}

export const DeleteTravelHistoryModalProviderNew: React.FC<Props> = (props) => {
  const [openDelete, setOpenDelete] = React.useState(defaultValues.openDelete); // uses this to open/close delete modal
  const [selectedRowData, setSelectedRowData] = React.useState(
    defaultValues.selectedRowData
  );
  const { currentUser } = useAuth();
  const {
    setLoadingStatusUSATravels,
    setLoadingStatusWorldTravels,
    getUsaTravelRecords,
    getWorldTravelRecords,
  } = useTravelHistoryContextNew();
  const userID = currentUser?.uid;

  // useEffect(() => {
  //   setSelectedRowData(defaultValues.selectedRowData);
  // }, [props.travelState]);

  // Open modal for a travel history
  const openDeleteTravelHistoryModal = useCallback(() => {
    logEvent(analytics, "open_modal", { type: "delete_travel" }); // log event to analytics
    setOpenDelete(true);
  }, []);

  // Close modal
  const closeDeleteTravelHistoryModal = useCallback(() => {
    logEvent(analytics, "close_modal", { type: "delete_travel" }); // log event to analytics
    setOpenDelete(false);
  }, []);

  const deleteTravelRecords = useCallback(
    async (refStr: string) => {
      if (userID) {
        // if user is logged in
        try {
          logEvent(analytics, "delete_travel_history", { refStr }); // log event to analytics
          const travelRecordRef = ref(rtdb, refStr);
          await remove(travelRecordRef); // remove from database
          return { success: true, error: "" };
        } catch (error: any) {
          return { success: false, error: error.message };
        }
      } else {
        return { success: false, error: "User not logged in" };
      }
    },
    [userID]
  );

  const deleteSelectedTravelRecords = useCallback(async () => {
    if (!userID) return;
    if (props.travelState === "usa") {
      if (selectedRowData.deleteAllUsaRecords) {
        await deleteTravelRecords(`users/${userID}/usa-travel-records`);
      } else {
        const promises = map(
          selectedRowData.selectedRowsUsa,
          async (recordID) => {
            return await deleteTravelRecords(
              `users/${userID}/usa-travel-records/${recordID}`
            );
          }
        );
        await Promise.all(promises);
        setSelectedRowData(defaultValues.selectedRowData);
        setLoadingStatusUSATravels("loading");
        await getUsaTravelRecords();
      }
    } else if (props.travelState === "world") {
      if (selectedRowData.deleteAllWorldRecords) {
        deleteTravelRecords(`users/${userID}/travel-records`);
      } else {
        const promises = map(
          selectedRowData.selectedRowsWorld,
          async (recordID) => {
            return await deleteTravelRecords(
              `users/${userID}/travel-records/${recordID}`
            );
          }
        );
        await Promise.all(promises);
        setSelectedRowData(defaultValues.selectedRowData);
        setLoadingStatusWorldTravels("loading");
        await getWorldTravelRecords();
      }
    }
  }, [
    deleteTravelRecords,
    props.travelState,
    selectedRowData.deleteAllUsaRecords,
    selectedRowData.deleteAllWorldRecords,
    selectedRowData.selectedRowsUsa,
    selectedRowData.selectedRowsWorld,
    setLoadingStatusUSATravels,
    setLoadingStatusWorldTravels,
    userID,
  ]);

  return (
    <DeleteTravelHistoryModalContextNew.Provider
      value={{
        openDelete,
        openDeleteTravelHistoryModal,
        closeDeleteTravelHistoryModal,
        selectedRowData,
        setSelectedRowData,
        deleteSelectedTravelRecords,
      }}
    >
      <DeleteTravelHistoryModalNew travelState={props.travelState} />
      {props.children}
    </DeleteTravelHistoryModalContextNew.Provider>
  );
};
