import * as React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
function CheckboxCheckedIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <g
        id='Group_13869'
        data-name='Group 13869'
        transform='translate(-326 -590)'
      >
        <rect
          id='Rectangle_17707'
          data-name='Rectangle 17707'
          width='24'
          height='24'
          transform='translate(326 590)'
          fill='none'
        />
        <g
          id='Group_13868'
          data-name='Group 13868'
          transform='translate(28 322)'
        >
          <g
            id='Rectangle_17667'
            data-name='Rectangle 17667'
            transform='translate(300 270)'
            fill='#f2732f'
            stroke='#f2732f'
            strokeWidth='1'
          >
            <rect width='20' height='20' rx='4' stroke='none' />
            <rect x='0.5' y='0.5' width='19' height='19' rx='3.5' fill='none' />
          </g>
          <path
            id='Path_25511'
            data-name='Path 25511'
            d='M18641,2053.875l2.875,2.875,5.75-5.75'
            transform='translate(-18334.648 -1773.875)'
            fill='none'
            stroke='#fff'
            strokeLinecap='round'
            strokeWidth='2'
          />
        </g>
      </g>
    </SvgIcon>
  );
}
export default CheckboxCheckedIcon;
