export const DocTemplateDummyData = {
    defaultTemplate: [
        { title: "", value: "" },
    ],
    pastAddressTemplate: [
        { title: "Address", value: "", address: true },
        { title: "Move In Date", value: "", date: true },
        { title: "Move Out Date", value: "", date: true },
    ],
    employmentTemplate: [
        { title: "Employer Name", value: "" },
        { title: "Employer Address", value: "", address: true },
        { title: "Start Date", value: "", date: true },
        { title: "End Date", value: "", date: true },
        { title: "Supervisor Name", value: "" },
        { title: "Supervisor Email", value: "" },
        { title: "Supervisor Phone", value: "" },
        { title: "Job Title", value: "" },
        { title: "Job Description", value: "" },
    ]
}