import React, { useState } from "react";
import { Box } from "@mui/system";
import {
  Alert,
  Container,
  Grid,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import theme, {
  DCButton,
  DCDashboardContainer,
  DCDashboardPageHeader,
} from "../../assets/theme/theme";
import ImmigrationSummary from "./ImmigrationSummary";
import DocumentStackCard from "./DocumentStackCard";
import CustomCardStack from "./CustomCardStack";
import { useDocumentContext } from "../../contexts/DocumentContext";
import { filter, map, isEmpty, get, groupBy } from "lodash";
import { Button } from "@mui/material";
import EmptyState from "./EmptyState";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { useCreateCustomCardContext } from "../../contexts/CreateCustomCardContext";
import { useCustomCardModalContext } from "../../contexts/CustomCardModalContext";
import { DocTemplateDummyData } from "../../assets/templateData";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import constants from "../../constants";
import { FilterList } from "@mui/icons-material";
import { logEvent } from "firebase/analytics";
import { analytics } from "../../firebase";
import { useUserContext } from "../../contexts/UserContext";
import { DashboardClouds } from "../Shared/Illustrations";
import { useHistory } from "react-router-dom";
import DescriptionIcon from '@mui/icons-material/Description';

export interface Props { }
export interface ITemplateFilterProps {
  template: string;
  onTemplateChange: (template: string) => any;
}

const TemplateFilter: React.FC<ITemplateFilterProps> = ({
  template,
  onTemplateChange,
}) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClickMenu = React.useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      setAnchorEl(event.currentTarget);
    },
    []
  );
  const handleClose = React.useCallback(() => {
    setAnchorEl(null);
  }, []);

  const title =
    get(constants.customCardTemplates, [template, "filterTitle"]) || "All";
  return (
    <>
      <Button
        startIcon={<FilterList />}
        onClick={(e) => {
          handleClickMenu(e);
        }}
      >
        {`Show ${title}`}
      </Button>
      <Menu
        id="basic-menu"
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <MenuItem
          onClick={() => {
            handleClose();
            onTemplateChange("all");
            logEvent(analytics, "click_filter", {
              type: "all",
            });
          }}
          disableRipple
        >
          All
        </MenuItem>

        <MenuItem
          onClick={() => {
            handleClose();
            onTemplateChange(constants.customCardTemplates.default.name);
            logEvent(analytics, "click_filter", {
              type: "default_template",
            });
          }}
          disableRipple
        >
          Default Template
        </MenuItem>

        <MenuItem
          onClick={() => {
            handleClose();
            onTemplateChange(constants.customCardTemplates.pastAddress.name);
            logEvent(analytics, "click_filter", {
              type: "post_address_template",
            });
          }}
          disableRipple
        >
          Past Address Template
        </MenuItem>

        <MenuItem
          onClick={() => {
            handleClose();
            onTemplateChange(constants.customCardTemplates.employer.name);
            logEvent(analytics, "click_filter", {
              type: "employment_template",
            });
          }}
          disableRipple
        >
          Employment Template
        </MenuItem>
      </Menu>
    </>
  );
};

const DashboardMain: React.FC<Props> = () => {
  const [selectedTemplate, setSelectedTemplate] = useState("all");
  const { folders } = useDocumentContext();
  const [dashboardState, setDashboardState] = useState<string>("auto");
  // undo-todo: remove this
  const { customCardArray } = useCreateCustomCardContext();
  const { user } = useUserContext();

  const { openModalForNewCard } = useCustomCardModalContext();
  const groupedCustomCardArray = Object.values(groupBy(customCardArray, (doc) => doc.template));
  // sort the groupedCustomCardArray by template name first pastAddress, employer, default
  groupedCustomCardArray.sort((a, b) => {
    const templateNameA = a[0].template as string;
    const templateNameB = b[0].template as string;
    if (templateNameA > templateNameB) {
      return -1;
    }
    if (templateNameA < templateNameB) {
      return 1;
    }
    return 0;
  });

  const stacks = map(folders, (folder, i) => {
    if (folder.displayOnDashboard) return null;
    const stackableDocs = filter(folder.docs, (doc) => !isEmpty(doc.doc_info));
    if (stackableDocs.length === 0) return null;

    return <DocumentStackCard folder={folder} key={`${folder.title}-${i}`} />;
  });

  const stackCount = filter(stacks, (stack) => !!stack).length;

  const customCardStacks = map(groupedCustomCardArray, (cardStack, i) => {
    const stackableDocs = filter(cardStack, (doc) => !isEmpty(doc.template));
    if (stackableDocs.length === 0) return null;
    if (cardStack[0].template === constants.customCardTemplates.default.name) {
      return cardStack.map((card, i) => {
        return <CustomCardStack cardStack={[card]} key={`${card.doc_title}-${i}`} />;
      });
    };
    if (cardStack[0].template === constants.customCardTemplates.pastAddress.name) {
      // sort the array by the vale.seconds of Move in Date title in doc_info
      cardStack.sort((a, b) => {
        // Extracting the "Move In Date" value from the objects
        if (!a.doc_info || !b.doc_info || !a.doc_info!.find(info => info.title === 'Move In Date') || !b.doc_info!.find(info => info.title === 'Move In Date')) {
          return 0;
        } else {
          const moveInDateA = a.doc_info.find(info => info.title === 'Move In Date')!.value.seconds;
          const moveInDateB = b.doc_info.find(info => info.title === 'Move In Date')!.value.seconds;
          // Comparing the "Move In Date" values for sorting
          return moveInDateB - moveInDateA;
        }

      });
      return <CustomCardStack cardStack={cardStack} key={`${i}`} />;
    }
    if (cardStack[0].template === constants.customCardTemplates.employer.name) {
      // sort the array by the vale.seconds of Start Date title in doc_info
      cardStack.sort((a, b) => {
        // Extracting the "Start Date" value from the objects
        if (!a.doc_info || !b.doc_info || !a.doc_info!.find(info => info.title === 'Start Date') || !b.doc_info!.find(info => info.title === 'Start Date')) {
          return 0;
        } else {
          const startDateA = a.doc_info.find(info => info.title === 'Start Date')!.value.seconds;
          const startDateB = b.doc_info.find(info => info.title === 'Start Date')!.value.seconds;
          // Comparing the "Start Date" values for sorting
          return startDateB - startDateA;
        }

      });
      return <CustomCardStack cardStack={cardStack} key={`${i}`} />;
    }
  });

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const history = useHistory();
  const handleClickMenu = React.useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      setAnchorEl(event.currentTarget);
    },
    []
  );
  const handleClose = React.useCallback(() => {
    setAnchorEl(null);
  }, []);

  const onTemplateChange = React.useCallback((template) => {
    setSelectedTemplate(template);
  }, []);

  const filteredCustomCardArray =
    selectedTemplate === "all"
      ? customCardArray
      : filter(customCardArray, (card) => card.template === selectedTemplate);
  return (
    <Box
      sx={{
        height: "100%",
        pb: "80px",
      }}
    >
      <DCDashboardPageHeader elevation={0} sx={{ pb: 16 }}>
        <Grid container mb={1}>
          <Grid item width={"100%"}>
            <Box sx={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "space-between" }}>
              <Typography
                className="user-text"
                variant="h1"
                component="h1"
                fontWeight={600}
                fontSize={24}
              >
                Hello, {user?.firstName} 👋
              </Typography>
              <DCButton
                variant="contained"
                sx={{ position: "relative", zIndex: 50 }}
                onClick={() => { history.push('/cheat-sheet') }}
                startIcon={<DescriptionIcon />}
              >
                Cheat Sheet
              </DCButton>
            </Box>
          </Grid>
          <Grid item position="relative" flex={1}>
            <DashboardClouds className="page-header--ilustration header-ilustration" />
          </Grid>
        </Grid>
        {/* <ImmigrationSummarySlim /> */}
      </DCDashboardPageHeader>
      <DCDashboardContainer>
        <Container disableGutters maxWidth={false}>
          <Box sx={{ mt: -16, position: "relative" }}>
            <ImmigrationSummary />
          </Box>

          <Box
            className="dashboard-cards"
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              my: 3,
              '@media (max-width:595px)': {
                flexDirection: 'column',
                gap: '10px',
              },
            }}
          >
            <Tabs value={dashboardState} aria-label="auto custom changer">
              <Tab
                value="auto"
                label="Auto generated Cards"
                onClick={() => {
                  setDashboardState("auto");
                }}
              />
              <Tab
                value="custom"
                label="Custom Cards"
                onClick={() => {
                  setDashboardState("custom");
                }}
              />
            </Tabs>

            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Box sx={{ display: "flex" }} alignItems={"center"}>
                {dashboardState === "auto" && (
                  <>
                    <Box
                      mr={1}
                      sx={{
                        height: "10px",
                        width: "10px",
                        backgroundColor: theme.palette.primary.main,
                        borderRadius: "50%",
                        display: "inline-block",
                      }}
                    />
                    <Typography color={theme.palette.text.secondary}>
                      Edited by you
                    </Typography>
                  </>
                )}
                {dashboardState === "custom" && (
                  <TemplateFilter
                    template={selectedTemplate}
                    onTemplateChange={onTemplateChange}
                  />
                )}
              </Box>
              {dashboardState === "custom" && (
                <Box ml={2}>
                  <DCButton
                    className="create-card-btn"
                    id="basic-button"
                    aria-controls={open ? "basic-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? "true" : undefined}
                    variant="contained"
                    endIcon={<ExpandMoreIcon />}
                    onClick={(e) => {
                      logEvent(analytics, "click_create_new_card");
                      handleClickMenu(e);
                    }}
                  >
                    Create New Card
                  </DCButton>
                  <Menu
                    id="basic-menu"
                    MenuListProps={{
                      "aria-labelledby": "basic-button",
                    }}
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "right",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                  >
                    <MenuItem
                      onClick={() => {
                        setDashboardState("custom");
                        openModalForNewCard(
                          DocTemplateDummyData.defaultTemplate,
                          constants.customCardTemplates.default.name
                        );
                        handleClose();
                        logEvent(analytics, "click_create_new_card", {
                          type: "default_template",
                        });
                      }}
                      disableRipple
                    >
                      Default Template
                    </MenuItem>

                    <MenuItem
                      onClick={() => {
                        setDashboardState("custom");
                        openModalForNewCard(
                          DocTemplateDummyData.pastAddressTemplate,
                          constants.customCardTemplates.pastAddress.name
                        );
                        handleClose();
                        logEvent(analytics, "click_create_new_card", {
                          type: "post_address_template",
                        });
                      }}
                      disableRipple
                    >
                      Past Address Template
                    </MenuItem>

                    <MenuItem
                      onClick={() => {
                        setDashboardState("custom");
                        openModalForNewCard(
                          DocTemplateDummyData.employmentTemplate,
                          constants.customCardTemplates.employer.name
                        );
                        handleClose();
                        logEvent(analytics, "click_create_new_card", {
                          type: "employment_template",
                        });
                      }}
                      disableRipple
                    >
                      Employment Template
                    </MenuItem>
                  </Menu>
                </Box>
              )}
            </Box>
          </Box>
          {dashboardState === "auto" && (
            <>
              {stackCount === 0 ? (
                <EmptyState tab="auto" />
              ) : (
                <Grid
                  container
                  columnSpacing={3}
                  rowSpacing={5}
                  columns={{ xs: 4, sm: 8, md: 8, lg: 12, xl: 12 }}
                  // Hardcoded the breakpoint for improving UI just in 14in Macbook
                  sx={{
                    "@media screen and (min-width: 1080px)": {
                      display: "grid",
                      gridTemplateColumns: "repeat(2, 1fr)"
                    },
                    "@media screen and (min-width: 1440px)": {
                      gridTemplateColumns: "repeat(3, 1fr)"
                    },
                  }}
                >
                  {stacks}
                </Grid>
              )}
            </>
          )}
          {dashboardState === "custom" && (
            <Box sx={{ mt: 8 }}>
              {customCardArray.length === 0 ? (
                <EmptyState tab="custom" />
              ) : (
                <Box>
                  {filteredCustomCardArray.length > 0 ? (
                    <Grid
                      container
                      columnSpacing={3}
                      rowSpacing={5}
                      columns={{ xs: 4, sm: 8, md: 8, lg: 12, xl: 12 }}
                      // Hardcoded the breakpoint for improving UI just in 14in Macbook
                      sx={{
                        "@media screen and (min-width: 1440px)": {
                          display: "grid",
                          gridTemplateColumns: "repeat(3, 1fr)"
                        },
                      }}
                    >
                      {customCardStacks}
                    </Grid>
                  ) : (
                    <Alert
                      sx={{ mt: "20px" }}
                      severity="error"
                      title="No results found"
                    >
                      No results found!
                    </Alert>
                  )}
                </Box>
              )}
            </Box>
          )}
          {/* {openDocument && (
            <DocumentModal
              document={openDocument}
              open={true}
              handleClose={handleDocumentModalClose}
              />
            )} */}
          {/* <DCPDFViewer /> */}
        </Container>
      </DCDashboardContainer>
    </Box>
  );
};

export default DashboardMain;
