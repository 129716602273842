import {
  Menu,
  Tooltip,
  MenuItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import React, { useCallback, useState } from "react";
import { useDocumentContext } from "../../contexts/DocumentContext";
import { IDocument } from "../../firebaseTypes";
import DeleteIcon from "../Shared/Icons/DeleteIcon";
import DownloadIcon from "../Shared/Icons/DownloadDocumentIcon";
import ShareIcon from "../Shared/Icons/ShareIcon";
// import SendIcon from '@mui/icons-material/Send';
import theme, { DcDocumentIconButton } from "../../assets/theme/theme";
import { useDocumentShareModalContext } from "../../contexts/DocumentShareModalContext";
import {
  MoveToFolderModalProvider,
  useMoveToFolderModalContext,
} from "../../contexts/MoveToFolderModalContext";
import { useDocumentDeleteModal } from "../../contexts/DocumentDeleteModalContext";
import MoveToFolderIcon from "../Shared/Icons/MoveToFolderIcon";
import BookmarkRemoveOutlinedIcon from '@mui/icons-material/BookmarkRemoveOutlined';
import MoreIcon from "../Shared/Icons/MoreIcon";
import { TagO1EB1ModalContextProvider, useTagO1EB1ModalContext } from "../../contexts/TagO1EB1ModalContext";
import { useAuth } from "../../contexts/AuthContext";
import { patchData } from "../../utils/fetchUtils";
import constants from "../../constants";
import { useUserContext } from "../../contexts/UserContext";

export interface Props {
  document: IDocument;
  hideDelete?: boolean;
  showMoveTo?: boolean;
}

function MoveToMenuItem(props: any) {
  const { openModal } = useMoveToFolderModalContext();
  const onMoveToDocument = useCallback(() => {
    openModal();
  }, [openModal]);

  return (
    <MenuItem onClick={onMoveToDocument} className=" document-options-blog">
      <ListItemIcon style={{ minWidth: "unset" }}>
        <MoveToFolderIcon fontSize="small" />
      </ListItemIcon>
      <ListItemText>Move to another folder</ListItemText>
    </MenuItem>
  );
}

function TagO1EB1MenuItem(props: any) {
  const { openModal } = useTagO1EB1ModalContext();
  const tagO1EB1Document = useCallback(() => {
    openModal();
  }, [openModal]);

  return (
    <MenuItem onClick={tagO1EB1Document} className=" document-options-blog">
      <ListItemIcon style={{ minWidth: "unset" }}>
        <MoveToFolderIcon fontSize="small" />
      </ListItemIcon>
      <ListItemText>Mark as O1/EB1 document</ListItemText>
    </MenuItem>
  );
}

function RemoveTagO1EB1MenuItem(props: any) {

  const { currentUser } = useAuth();
  const [disabledBtn, setDisabledBtn] = useState(false);

  const removeTagO1EB1 = useCallback(async () => {
    setDisabledBtn(true);
    try {
      if (currentUser) {
        const token = await currentUser?.getIdToken();
        await patchData(
          `${constants.middleHost}/users/${currentUser.uid}/documents/${props.document.id}/remove-mark-o1-eb1/`,
          {},
          {
            Authorization: `Bearer ${token}`,
          }
        );
      }
    } catch (err) {
      console.error(err);
    }
    setDisabledBtn(false);
  }, [currentUser, props.document.id]);

  return (
    <MenuItem disabled={disabledBtn} onClick={removeTagO1EB1} className=" document-options-blog">
      <ListItemIcon style={{ minWidth: "unset" }}>
        <BookmarkRemoveOutlinedIcon fontSize="small" sx={{ color: '#707070', strokeWidth: 1.5 }} />
      </ListItemIcon>
      <ListItemText>Remove O1/EB1 tag</ListItemText>
    </MenuItem>
  );
}

const DocumentOptionsMenu: React.FC<Props> = ({
  document,
  hideDelete,
  showMoveTo,
}) => {
  const [disableDownloadBtn, setDisableDownloadBtn] = useState(false);
  const { openShareModalFor } = useDocumentShareModalContext();
  const { downloadDocument } = useDocumentContext();
  const { openDeleteModalFor } = useDocumentDeleteModal();
  const [anchorEl, setAnchorEl] = React.useState(null as HTMLElement | null);
  const open = Boolean(anchorEl);

  const { user } = useUserContext();

  const _handleClose = () => {
    setAnchorEl(null);
  };
  const handleClose = useCallback(_handleClose, []);

  const onShareButtonClick = useCallback(() => {
    openShareModalFor([document]);
    handleClose();
  }, [document, openShareModalFor, handleClose]);

  const onDownloadDocument = useCallback(async () => {
    setDisableDownloadBtn(true);
    await downloadDocument(document);
    setDisableDownloadBtn(false);
    handleClose();
  }, [document, downloadDocument, handleClose]);

  const onDeleteDocument = useCallback(async () => {
    openDeleteModalFor([document]);
    handleClose();
  }, [document, openDeleteModalFor, handleClose]);

  const _handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClick = useCallback(_handleClick, []);

  return (
    <>
      <Tooltip title="More Options" arrow placement="top">
        <DcDocumentIconButton
          aria-label="options"
          id={`document-options-${document.id}`}
          aria-controls="document-options"
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          onClick={handleClick}
        >
          <MoreIcon fontSize="small" />
        </DcDocumentIconButton>
      </Tooltip>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        MenuListProps={{
          "aria-labelledby": "document-options",
        }}
      >
        <MoveToFolderModalProvider document={document}>
          <MoveToMenuItem fontSize="small" />
        </MoveToFolderModalProvider>

        {user?.o1eb1 && !document.tags?.some((tag) => tag._id.startsWith("O1EB1") && tag.type === 'manual') && (
          <TagO1EB1ModalContextProvider document={document}>
            <TagO1EB1MenuItem fontSize="small" />
          </TagO1EB1ModalContextProvider>
        )}

        {user?.o1eb1 && document.tags?.some((tag) => tag._id.startsWith("O1EB1") && tag.type === 'manual') && (
            <RemoveTagO1EB1MenuItem document={document} />
        )}

        <MenuItem disabled={disableDownloadBtn} onClick={onDownloadDocument}>
          <ListItemIcon style={{ minWidth: "unset" }}>
            <DownloadIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Download document</ListItemText>
        </MenuItem>

        <MenuItem onClick={onShareButtonClick}>
          <ListItemIcon style={{ minWidth: "unset" }}>
            <ShareIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Share document</ListItemText>
        </MenuItem>

        {!hideDelete && (
          <MenuItem onClick={onDeleteDocument}>
            <ListItemIcon
              style={{ minWidth: "unset", color: theme.palette.error.main }}
            >
              <DeleteIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText sx={{ color: theme.palette.error.main }}>
              Delete document
            </ListItemText>
          </MenuItem>
        )}
      </Menu>
    </>
  );
};

export default DocumentOptionsMenu;
