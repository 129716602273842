/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect, useState } from "react";
import {
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Grow,
  FormHelperText,
} from "@mui/material";
import theme, { DcInput } from "../../assets/theme/theme";
import { find, isEmpty, map, sortBy } from "lodash";
import countries from "../../utils/countries.json";
import statesByCountry from "../../utils/states_by_country.json";
import countryCodeToNationality from "../../utils/country_code_to_nationality.json";
import { useUserProfileContext } from "../../contexts/UserProfileContext";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import { CountryCode } from "libphonenumber-js/types";
import get from "lodash/get";

export interface Props {}

const nationalities = sortBy(Object.values(countryCodeToNationality));

function findCountryNameByCountryCode(countryCode: string) {
  const country = find(countries, (c) => c.code === countryCode);
  return get(country, "name", "");
}

function findCountryCodeByCountryName(countryName: string) {
  const country = find(countries, (c) => c.name === countryName);
  return get(country, "code", "");
}

const ProfileInfoForm: React.FC<Props> = (props) => {
  const { userProfile, setUserProfile } = useUserProfileContext();

  const handleChange = React.useCallback(
    (evt) => {
      const changes: any = {
        [evt.target.name]: evt.target.value,
      };
      setUserProfile((prev) => ({
        ...prev,
        ...changes,
      }));
    },
    [setUserProfile]
  );

  if (!userProfile) return null;

  //Getting Your Country Code

  const [countryCode, setCountryCode] = useState<CountryCode | undefined>();

  const getUserCountryCode = React.useCallback(async () => {
    try {
      const res = await fetch("https://geolocation-db.com/json/");
      const data = await res.json();
      setCountryCode("IN");
    } catch {
      // Resolve with "US" as a country code when geolocation is not able to detect the country.
      setCountryCode("US");
      return { error: "No data Found. Using Default Country Code US." };
    }
  }, []);

  useEffect(() => {
    getUserCountryCode();
  }, [getUserCountryCode]);

  // special change, when user changes the country residing, if phone number is empty let's change the flag.

  useEffect(() => {
    // when user profile is loaded
    if (userProfile.email) {
      if (isEmpty(userProfile.nationality) && countryCode) {
        const nationality = get(countryCodeToNationality, countryCode, "");
        setUserProfile((prev) => ({ ...prev, nationality }));
      }
      if (isEmpty(userProfile.country) && countryCode) {
        const country = findCountryNameByCountryCode(countryCode);
        setUserProfile((prev) => ({ ...prev, country }));
      }
    }
  }, [countryCode, userProfile, setUserProfile]);

  useEffect(() => {
    // when change in country, update countryCode only if phoneNumber is empty
    if (isEmpty(userProfile.phoneNumber)) {
      const code = findCountryCodeByCountryName(userProfile.country);
      if (!isEmpty(code)) {
        setCountryCode(code as any);
      }
    }
  }, [userProfile.country, userProfile.phoneNumber]);

  return (
    <>
      <Grow in={true}>
        <Grid container rowSpacing={2} columnSpacing={4} minHeight={357}>
          <Grid item xs={12} sm={6}>
            <FormControl variant="standard" required fullWidth>
              <InputLabel shrink htmlFor="firstName">
                Given Name
              </InputLabel>
              <DcInput
                id="firstName"
                name="firstName"
                placeholder="Enter given name"
                value={userProfile.firstName}
                onChange={handleChange}
                fullWidth
              />
              <FormHelperText
                sx={
                  {
                    // color: '#ff5900'
                  }
                }
              >
                Please use the same name as per your passport.
              </FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl variant="standard" required fullWidth>
              <InputLabel shrink htmlFor="zipCode">
                Zip Code
              </InputLabel>
              <DcInput
                id="zipCode"
                name="zipCode"
                type="number"
                placeholder="Enter zip code"
                value={userProfile.zipCode}
                onChange={handleChange}
                fullWidth
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl variant="standard" required fullWidth>
              <InputLabel shrink htmlFor="lastName">
                Last Name
              </InputLabel>
              <DcInput
                id="lastName"
                placeholder="Enter last name"
                onChange={handleChange}
                value={userProfile.lastName}
                name="lastName"
                fullWidth
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl variant="standard" required fullWidth>
              <InputLabel shrink htmlFor="country">
                Country Residing
              </InputLabel>
              <Select
                name="country"
                onChange={handleChange}
                inputProps={{
                  name: "country",
                  id: "country",
                }}
                displayEmpty
                value={userProfile.country || ""}
                input={<DcInput />}
              >
                <MenuItem key="empty-label-country" value="">
                  <em>Select Country</em>
                </MenuItem>
                {map(countries, (c, i) => {
                  return (
                    <MenuItem value={c.name} key={`${c.name}-${i}`}>
                      {c.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl variant="standard" required fullWidth>
              <InputLabel shrink htmlFor="email">
                Email
              </InputLabel>
              <DcInput
                id="email"
                disabled
                placeholder="Enter email"
                fullWidth
                onChange={handleChange}
                name="email"
                value={userProfile.email}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth variant="standard" required>
              <InputLabel shrink htmlFor="state">
                State
              </InputLabel>
              <Select
                onChange={handleChange}
                value={userProfile.state}
                inputProps={{
                  name: "state",
                  id: "state",
                }}
                input={<DcInput />}
                displayEmpty
                disabled={isEmpty(userProfile.country)}
              >
                <MenuItem key="empty-label-state" value="">
                  <em>Select State</em>
                </MenuItem>
                {map(
                  (statesByCountry as any)[userProfile.country] || [],
                  (s, i) => {
                    return (
                      <MenuItem value={s.name} key={`${s.name}-${i}`}>
                        {s.name}
                      </MenuItem>
                    );
                  }
                )}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            {/* nationality */}
            <FormControl variant="standard" required fullWidth>
              <InputLabel shrink htmlFor="country">
                Nationality
              </InputLabel>
              <Select
                name="nationality"
                onChange={handleChange}
                inputProps={{
                  name: "nationality",
                  id: "nationality",
                }}
                displayEmpty
                value={userProfile.nationality || ""}
                input={<DcInput />}
              >
                <MenuItem key="empty-label-country" value="">
                  <em>Select Nationality</em>
                </MenuItem>
                {map(nationalities, (n, i) => {
                  return (
                    <MenuItem value={n} key={`${n}-${i}`}>
                      {n}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl variant="standard" fullWidth>
              <InputLabel shrink htmlFor="phoneNumber">
                Phone Number (optional)
              </InputLabel>
              <PhoneInput
                style={{
                  marginTop: "24px",
                  background: theme.palette.common.white,
                  borderRadius: 6,
                  position: "relative",
                  border: "1px solid #D3D3D3",
                  padding: "10px 0 10px 12px",
                  height: 22,
                  boxShadow: "0 1px 3px rgba(132, 183, 252, .16)",
                  transition: theme.transitions.create([
                    "border-color",
                    "background-color",
                    "box-shadow",
                  ]),
                }}
                international
                defaultCountry={countryCode}
                id="phone-number-input"
                value={userProfile.phoneNumber}
                numberInputProps={{
                  style: {
                    background: theme.palette.common.white,
                    border: 0,
                    fontSize: 14,
                    width: "100%",
                    padding: "10px 12px",
                    height: 22,
                    boxShadow: "none",
                    outline: "none",

                    // Use the system font instead of the default Roboto font.
                    fontFamily: theme.typography.fontFamily,
                    "&:focus-visible": {
                      borderRadius: 6,
                      borderColor: "#4B84FF",
                    },
                    "&:disabled": {
                      background: "#F7F7F7",
                      color: "#222",
                      WebkitTextFillColor: "#222",
                      pointer: "not-allowed",
                    },
                  },
                }}
                withCountryCallingCode
                onChange={(phoneNumber: any) =>
                  setUserProfile((prev) => ({
                    ...prev,
                    phoneNumber: phoneNumber || "",
                  }))
                }
              />
              {/* <DcInput id='phoneNumber'
            placeholder='Enter phone name'
            value={userProfile.phoneNumber}
            onChange={handleChange}
            name='phoneNumber'
            fullWidth /> */}
            </FormControl>
            {/* <FormControl variant='standard' required fullWidth>
            <InputLabel shrink htmlFor='city'>
              City
            </InputLabel>
            <DcInput id='city'
              placeholder='Enter city'
              fullWidth
              onChange={handleChange}
              name='city'
              value={userProfile.city} />
          </FormControl> */}
          </Grid>
        </Grid>
      </Grow>
    </>
  );
};

export default ProfileInfoForm;
