import { map } from "lodash"

export function push<T>(arr: T[], item: T): { array: T[], index: number } {
    return {
        array: [
            ...arr,
            item
        ],
        index: arr.length
    }
}

export function patchElement<T>(arr: T[], index: number, patch: Partial<T>): T[] {
    return map(arr, (item, i) => {
        if (i === index) {
            return {
                ...item,
                ...patch
            }
        }
        return item
    })
}

export function stringArrayContains(arr: string[], str: string): boolean {
    for (let i = 0; i < arr.length; i++) {
      if (arr[i] === str) return true
    }
    return false
  }
  