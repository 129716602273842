import { isAfter, isBefore, isSameDay } from "date-fns";
import { useCallback, useMemo } from "react";
import { toDateTime } from "../../../utils/dateTimeUtils";
import useTravelHistory, { ITravelModel } from "./TravelHistory.hook";

const useTravelValidation = () => {
  const { usaTravels, worldTravels } = useTravelHistory();

  const travels = useMemo(
    () => [...worldTravels, ...usaTravels],
    [usaTravels, worldTravels]
  );

  const shouldDisableArrivalDate = useCallback(
    (date: Date) => {
      // Can't select future dates;
      const isFuture = isAfter(date, new Date());
      if (isFuture) {
        return true;
      }

      // Can't select date between any of travels arrival - departure dates;
      return travels.some(
        (t) =>
          t.arrival_date &&
          isAfter(date, t.arrival_date) &&
          t.departure_date &&
          isBefore(date, t.departure_date)
      );
    },
    [travels]
  );

  const shouldDisableDepartureDate = useCallback(
    (date: Date, arrivalDate: number) => {
      const arrivalDt = toDateTime(arrivalDate)!;
      // Can't select date before arrival
      const isBeforeArrival = isBefore(date, arrivalDt);
      if (isBeforeArrival) {
        return true;
      }

      // Can't select future dates;
      const isFuture = isAfter(date, new Date());
      if (isFuture) {
        return true;
      }

      travels.forEach(
        (t) =>
          !t.automated &&
          console.log(
            "Date",
            t.arrival_date,
            date,
            isAfter(t.arrival_date!, arrivalDt),
            isAfter(date, t.arrival_date!),
            "disabled: ",
            isAfter(t.arrival_date!, arrivalDt) &&
              isAfter(date, t.arrival_date!)
          )
      );

      // Can't select date after any of travels arrival dates
      return travels.some(
        (t) =>
          t.arrival_date &&
          ((isAfter(t.arrival_date, arrivalDt) &&
            isAfter(date, t.arrival_date)) ||
            (isSameDay(t.arrival_date, arrivalDt) &&
              isAfter(date, t.arrival_date)))
      );
    },
    [travels]
  );

  const shouldPresentActive = useCallback(
    (travel: ITravelModel | null) =>
      travels.some((t) => t.isPresent && t.id !== travel?.id),
    [travels]
  );

  return {
    shouldPresentActive,
    shouldDisableArrivalDate,
    shouldDisableDepartureDate,
  };
};

export default useTravelValidation;
