import React from "react";
import DeleteConfimationModal from "../components/DeleteConfimationModal";
import { IDocument } from "../firebaseTypes";
import { useDocumentContext } from "./DocumentContext";
import { useDocumentGalleryContext } from "./DocumentGalleryContext";

export interface IDocumentDeleteModalContext {
  // host
  openDeleteModalFor: (documents: IDocument[]) => any;
}

const defaultValues: IDocumentDeleteModalContext = {
  openDeleteModalFor: () => {},
};

const context = React.createContext(defaultValues);

export function useDocumentDeleteModal() {
  return React.useContext(context);
}

export const DocumentDeleteModalProvider: React.FC = (props) => {
  const [documents, setDocuments] = React.useState<IDocument[]>([]);
  const [open, setOpen] = React.useState(false);
  const { deleteDocuments } = useDocumentContext();
  const { clearSelection } = useDocumentGalleryContext();

  const openDeleteModalFor = React.useCallback((documents: IDocument[]) => {
    setDocuments(documents);
    setOpen(true);
  }, []);

  const onClose = React.useCallback(() => {
    setOpen(false);
    setDocuments([]);
  }, []);

  const onDelete = React.useCallback(async () => {
    if (documents) {
      await deleteDocuments(documents);
      clearSelection();
      onClose();
    }
  }, [documents, onClose, deleteDocuments]);

  return (
    <context.Provider
      value={{
        openDeleteModalFor,
      }}
    >
      <DeleteConfimationModal
        open={open}
        documents={documents}
        onDelete={onDelete}
        onClose={onClose}
      />
      {props.children}
    </context.Provider>
  );
};
