import {
  Menu,
  Tooltip,
  MenuItem,
  ListItemText,
  Box,
  ListSubheader,
} from "@mui/material";
import React, { useCallback } from "react";
import { DcDocumentIconButton } from "../../assets/theme/theme";

import { useDocumentContext } from "../../contexts/DocumentContext";
import { isEmpty, map } from "lodash";
import { useCustomCardModalContext } from "../../contexts/CustomCardModalContext";
import PaperclipIcon from "../Shared/Icons/PaperclipIcon";
import { getDocName } from "../../utils/documentUtils";

interface Props {
  card_id: string;
  doc_name: string;
}

const AttachDocumentCustomCard: React.FC<Props> = ({ card_id }) => {
  const [anchorEl, setAnchorEl] = React.useState(null as HTMLElement | null);
  const open = Boolean(anchorEl);
  const { folders } = useDocumentContext();
  const { handleAttachDocumentCard } = useCustomCardModalContext();

  const _handleClose = () => {
    setAnchorEl(null);
  };
  const handleClose = useCallback(_handleClose, []);

  const _handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClick = useCallback(_handleClick, []);

  return (
    <>
      <Tooltip title="Attach document" arrow placement="top">
        <DcDocumentIconButton
          aria-label="attach"
          // id={`document-options-${document.id}`}
          aria-controls="document-attach"
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          onClick={handleClick}
        >
          <PaperclipIcon fontSize="small" />
        </DcDocumentIconButton>
      </Tooltip>

      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        MenuListProps={{
          "aria-labelledby": "document-options",
        }}
        sx={{ height: "400px" }}
      >
        {map(folders, (folder, i) => {
          return (
            !isEmpty(folder.docs) && (
              <Box key={i}>
                <ListSubheader color="primary" disableSticky>
                  {folder.title}
                </ListSubheader>
                {map(folder.docs, (document, i) => {
                  return (
                    <MenuItem
                      key={i}
                      onClick={() => {
                        handleAttachDocumentCard(
                          card_id,
                          document.id,
                          getDocName(document),
                          document?.doc_format as string
                        );
                        handleClose();
                      }}
                    >
                      <ListItemText>{getDocName(document)}</ListItemText>
                    </MenuItem>
                  );
                })}
              </Box>
            )
          );
        })}
      </Menu>
    </>
  );
};

export default AttachDocumentCustomCard;
