import {
  createContext,
  FC,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useAuth } from "./AuthContext";
import { rtdb } from "../firebase";
import {
  ref,
  set,
  push,
  onValue,
  update,
  remove,
  DataSnapshot,
} from "firebase/database";
import { ITravelHistory, LoadingStatus } from "../firebaseTypes";
import { debounce } from "lodash";
import { isObject } from "../utils/utils";
import { useDocumentUploadContext } from "./DocumentUploadContext";

interface ITravelHistoryContext {
  loadingStatusUSATravels: LoadingStatus;
  loadingStatusWorldTravels: LoadingStatus;
  travelRecords: ITravelHistory[];
  addTravelRecord: (travelData: ITravelHistory) => void;
  updateTravelRecord: (id: string, travelData: ITravelHistory) => void;
  deleteTravelRecord: (recordID: string) => void;
  deleteAllTravelRecord: () => void;

  usaTravelRecords: ITravelHistory[];
  addUsaTravelRecord: (travelData: ITravelHistory) => void;
  updateUsaTravelRecord: (id: string, travelData: ITravelHistory) => void;
  deleteUsaTravelRecord: (recordID: string) => void;
  deleteAllUsaTravelRecord: () => void;
}
const defaultValues: ITravelHistoryContext = {
  loadingStatusUSATravels: "loading",
  loadingStatusWorldTravels: "loading",

  travelRecords: [],
  addTravelRecord: () => {},
  updateTravelRecord: () => {},
  deleteTravelRecord: () => {},
  deleteAllTravelRecord: () => {},

  usaTravelRecords: [],
  addUsaTravelRecord: () => {},
  updateUsaTravelRecord: () => {},
  deleteUsaTravelRecord: () => {},
  deleteAllUsaTravelRecord: () => {},
};
const context = createContext(defaultValues);

export function useTravelHistoryContext() {
  return useContext(context);
}

export const TravelHistoryProvider: FC<PropsWithChildren<any>> = (props) => {
  const { currentUser } = useAuth();
  const { uploadingUSATravelHistory } = useDocumentUploadContext();
  const [loadingStatusUSATravels, setLoadingStatusUSATravels] = useState(
    defaultValues.loadingStatusUSATravels
  );
  const [loadingStatusWorldTravels, setLoadingStatusWorldTravels] = useState(
    defaultValues.loadingStatusWorldTravels
  );
  const userID = useMemo(() => currentUser?.uid, [currentUser?.uid]);
  const [travels, setTravels] = useState<ITravelHistory[]>([]);
  const [usaTravels, setUsaTravels] = useState<ITravelHistory[]>([]);

  useEffect(() => {
    if (uploadingUSATravelHistory) {
      setLoadingStatusUSATravels("loading");
    }
  }, [uploadingUSATravelHistory]);

  const showTravelRecords = useCallback(() => {
    const tRef = ref(rtdb, `users/${userID}/travel-records`);
    onValue(tRef, (snapshot) => {
      setLoadingStatusWorldTravels("loading");
      if (snapshot.exists()) {
        setTravels([]);
        const travels = [] as ITravelHistory[];
        snapshot.forEach((data) => {
          const val = data.val();
          if (isObject(val)) {
            travels.push({
              id: data.key,
              ...val,
            });
          }
        });
        setTravels(travels);
        if (travels.length > 0) {
          setLoadingStatusWorldTravels("results");
        } else {
          setLoadingStatusWorldTravels("empty-results");
        }
      } else {
        setTravels([]);
        setLoadingStatusWorldTravels("empty-results");
      }
    });
  }, [userID]);

  const _debouncedOnValue = async (snapshot: DataSnapshot) => {
    setLoadingStatusUSATravels("loading");
    if (snapshot.exists()) {
      setUsaTravels([]);
      const tokenized_usaTravels: ITravelHistory[] = [];
      snapshot.forEach((data: any) => {
        const val = data.val();
        if (isObject(val)) {
          tokenized_usaTravels.push({
            id: data.key,
            ...val,
          });
        }
      });
      setUsaTravels(tokenized_usaTravels);
      setLoadingStatusUSATravels("results");
    } else {
      setUsaTravels([]);
      setLoadingStatusUSATravels("empty-results");
    }
  };

  const debouncedOnValue = useCallback(
    debounce(_debouncedOnValue, 5000, {
      leading: true,
    }),
    [currentUser]
  );

  const showUsaTravelRecords = useCallback(() => {
    const tRef = ref(rtdb, `users/${userID}/usa-travel-records`);
    onValue(tRef, debouncedOnValue);
  }, [userID, debouncedOnValue]);

  const addTravelRecord = useCallback(
    async (travelData: ITravelHistory) => {
      if (userID) {
        const travelRecordRef = ref(rtdb, `users/${userID}/travel-records`);
        const newRef = push(travelRecordRef);
        await set(newRef, {
          ...travelData,
        });
      }
    },
    [userID]
  );

  const addUsaTravelRecord = useCallback(
    async (travelData: ITravelHistory) => {
      if (userID) {
        const travelRecordRef = ref(rtdb, `users/${userID}/usa-travel-records`);
        const newRef = push(travelRecordRef);
        await set(newRef, {
          ...travelData,
        });
      }
    },
    [userID]
  );

  const updateTravelRecord = useCallback(
    async (id: string, travelData: ITravelHistory) => {
      if (userID) {
        const travelRecordRef = ref(
          rtdb,
          `users/${userID}/travel-records/${id}`
        );
        await update(travelRecordRef, {
          ...travelData,
        });
      }
    },
    [userID]
  );

  const updateUsaTravelRecord = useCallback(
    async (id: string, travelData: ITravelHistory) => {
      if (userID) {
        const travelRecordRef = ref(
          rtdb,
          `users/${userID}/usa-travel-records/${id}`
        );
        await update(travelRecordRef, {
          ...travelData,
        });
      }
    },
    [userID]
  );

  const deleteTravelRecord = useCallback(
    async (recordID: string) => {
      if (userID) {
        const travelRecordRef = ref(
          rtdb,
          `users/${userID}/travel-records/${recordID}`
        );
        await remove(travelRecordRef);
      }
    },
    [userID]
  );

  const deleteAllTravelRecord = useCallback(async () => {
    if (userID) {
      const travelRecordRef = ref(rtdb, `users/${userID}/travel-records`);
      await remove(travelRecordRef);
      setTravels([]);
    }
  }, [userID]);

  const deleteUsaTravelRecord = useCallback(
    async (recordID: string) => {
      if (userID) {
        const travelRecordRef = ref(
          rtdb,
          `users/${userID}/usa-travel-records/${recordID}`
        );
        await remove(travelRecordRef);
      }
    },
    [userID]
  );

  const deleteAllUsaTravelRecord = useCallback(async () => {
    if (userID) {
      const travelRecordRef = ref(rtdb, `users/${userID}/usa-travel-records`);
      await remove(travelRecordRef);
      setUsaTravels([]);
    }
  }, [userID]);

  useEffect(() => {
    showTravelRecords();
    showUsaTravelRecords();
  }, [showTravelRecords, showUsaTravelRecords]);

  return (
    <context.Provider
      value={{
        loadingStatusUSATravels,
        loadingStatusWorldTravels,
        travelRecords: travels,
        addTravelRecord,
        updateTravelRecord,
        deleteTravelRecord,
        deleteAllTravelRecord,
        usaTravelRecords: usaTravels,
        addUsaTravelRecord,
        updateUsaTravelRecord,
        deleteUsaTravelRecord,
        deleteAllUsaTravelRecord,
      }}
    >
      {props.children}
    </context.Provider>
  );
};
