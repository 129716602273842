import React from "react";

export interface IPreventRefreshDialogContext {
  showPreventRefreshDialog: (message?: string) => void,
  hidePreventRefreshDialog: () => void
}

const defaultValues: IPreventRefreshDialogContext = {
  showPreventRefreshDialog: (message?: string) => null,
  hidePreventRefreshDialog: () => null,
};

const PreventRefreshDialogContext = React.createContext(defaultValues);

export function usePreventRefreshDialogContext() {
  return React.useContext(PreventRefreshDialogContext);
}

const PreventRefreshDialogProvider: React.FC = (props) => {
  const showPreventRefreshDialog = (message?: string) => {
    window.onbeforeunload = () => message ?? "Data will be lost if you leave the page, are you sure?"
  }

  const hidePreventRefreshDialog = () => {
    window.onbeforeunload = () => null
  }

  return (
    <PreventRefreshDialogContext.Provider value={{ showPreventRefreshDialog, hidePreventRefreshDialog }}>
      {props.children}
    </PreventRefreshDialogContext.Provider>
  );
};

export default PreventRefreshDialogProvider;
