import React, { FC, FunctionComponent, ReactNode, useMemo } from "react";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { TableCellProps } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Skeleton from "@mui/material/Skeleton";

import { visuallyHidden } from "@mui/utils";
import { DcDocumentIconButton } from "../../assets/theme/theme";
import { CSSProperties } from "@mui/styled-engine";
import useTheme from "@mui/material/styles/useTheme";
import Tooltip from "@mui/material/Tooltip";
import Checkbox from "@mui/material/Checkbox";
import DeleteIcon from "./Icons/DeleteIcon";
import EditIcon from "./Icons/EditIcon";
import { FixedSizeList, ListChildComponentProps } from "react-window";

type Order = "asc" | "desc";

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key
): (
  a: { [key in Key]: number | string },
  b: { [key in Key]: number | string }
) => number {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

interface DCTableItemProps extends TableCellProps {
  id: string;
  children?(row: any): ReactNode;
}

interface TableData {
  id?: string;
  [key: string]: any;
}

interface Props {
  loading?: boolean;
  data: TableData[];
  columns: DCTableItemProps[];
  summary?: { data: any[]; columns: DCTableItemProps[] };
  initialOrder?: { order: Order; orderBy: string };
  onEditClick?(row: any): void;
  onDeleteClick?(row: any): void;
  onDeleteAllClick?(): void;
  rowStyle?(row: any): CSSProperties;
  // Use this carefully. This prop disables the pagination and displays the entire data in a memory optimized FixedSizeList.
  disablePagination?: boolean;
  // Checkbox Props
  hasCheckbox?: boolean;
  selectedRows?: string[];
  allSelected?: boolean;
  onSelectRow?(idList: string[], allSelected: boolean): void;
}

const Loading: FC = () => {
  return (
    <Skeleton
      variant="rectangular"
      sx={{
        height: 500,
      }}
    ></Skeleton>
  );
};

export const DCTable: FC<Props> = ({
  data,
  loading,
  columns,
  summary,
  initialOrder,
  onEditClick,
  onDeleteClick,
  onDeleteAllClick,
  rowStyle,
  disablePagination,
  // Checkbox Props
  hasCheckbox,
  selectedRows,
  allSelected,
  onSelectRow,
}) => {
  const [order, setOrder] = React.useState<Order>(
    initialOrder ? initialOrder.order : "asc"
  );
  const [orderBy, setOrderBy] = React.useState(
    initialOrder ? initialOrder.orderBy : ""
  );
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const rowCount = data.length;

  const serializedData = useMemo(() => {
    if (disablePagination) {
      return data.sort(getComparator(order, orderBy));
    }
    return data
      .sort(getComparator(order, orderBy))
      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
  }, [data, disablePagination, order, orderBy, page, rowsPerPage]);

  const handleRequestSort = (
    _event: React.MouseEvent<unknown>,
    property: string
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (_event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const onSelectAllClick = (checked: boolean) => {
    const list = checked ? data.map((d) => d.id!) : [];
    onSelectRow && onSelectRow(list, checked);
  };

  const onSelectClick = (checked: boolean, id: string) => {
    const list = checked
      ? [...(selectedRows || []), id]
      : (selectedRows || []).filter((l) => l !== id);
    onSelectRow && onSelectRow(list, list.length === rowCount);
  };

  if (loading) return <Loading />;

  return (
    <Box mx={2}>
      <TableContainer>
        <Table aria-labelledby="tableTitle" size="small">
          <DCTableHeader
            order={order}
            orderBy={orderBy}
            hasCheckbox={hasCheckbox}
            allSelected={allSelected}
            indeterminateCheckbox={
              (selectedRows || []).length > 0 &&
              (selectedRows || []).length < rowCount
            }
            onSelectAllClick={onSelectAllClick}
            onRequestSort={handleRequestSort}
            columns={columns}
            onEditClick={onEditClick}
            onDeleteClick={onDeleteClick}
            onDeleteAllClick={onDeleteAllClick}
          />
          <DCTableBody
            loading={loading}
            rows={serializedData}
            columns={columns}
            summary={summary}
            page={page}
            hasCheckbox={hasCheckbox}
            selectedRows={selectedRows}
            onSelectClick={onSelectClick}
            rowsPerPage={rowsPerPage}
            onEditClick={onEditClick}
            onDeleteClick={onDeleteClick}
            rowStyle={rowStyle}
          />
        </Table>
      </TableContainer>
      {!disablePagination && (
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={data.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      )}
    </Box>
  );
};

interface HeaderProps {
  order: Order;
  orderBy: string;
  columns: DCTableItemProps[];
  onRequestSort: (event: React.MouseEvent<unknown>, property: string) => void;
  onEditClick?(row: any): void;
  onDeleteClick?(row: any): void;
  onDeleteAllClick?(): void;
  // Checkbox Props
  hasCheckbox?: boolean;
  indeterminateCheckbox?: boolean;
  allSelected?: boolean;
  onSelectAllClick(checked: boolean): void;
}
export const DCTableHeader: FC<HeaderProps> = ({
  columns,
  order,
  orderBy,
  onRequestSort,
  onEditClick,
  onDeleteClick,
  onDeleteAllClick,
  // Checkbox Props
  hasCheckbox,
  indeterminateCheckbox,
  allSelected,
  onSelectAllClick,
}) => {
  const createSortHandler =
    (property: string) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  return (
    <TableHead>
      <TableRow>
        {hasCheckbox ? (
          <TableCell padding="checkbox">
            <Checkbox
              color="primary"
              indeterminate={indeterminateCheckbox}
              checked={allSelected}
              onChange={(_, c) => onSelectAllClick(c)}
              inputProps={{
                "aria-label": "select all desserts",
              }}
            />
          </TableCell>
        ) : null}
        {columns.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.align}
            sortDirection={orderBy === headCell.id ? order : false}
            sx={{ fontSize: 14 }}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.title}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
        {(onEditClick || onDeleteClick) && (
          <TableCell key="dc_table_actions_header" align="right">
            {onDeleteAllClick && (
              <Tooltip title="Delete All">
                <DcDocumentIconButton onClick={() => onDeleteAllClick()}>
                  <DeleteIcon sx={{ fontSize: 24 }} color="error" />
                </DcDocumentIconButton>
              </Tooltip>
            )}
          </TableCell>
        )}
      </TableRow>
    </TableHead>
  );
};

interface BodyProps {
  page: number;
  rowsPerPage: number;
  rows: TableData[];
  columns: DCTableItemProps[];
  summary?: { data: any[]; columns: DCTableItemProps[] };
  onEditClick?(row: any): void;
  onDeleteClick?(row: any): void;
  rowStyle?(row: any): CSSProperties;
  // Checkbox Props
  hasCheckbox?: boolean;
  loading?: boolean;
  selectedRows?: string[];
  onSelectClick(checked: boolean, id: string): void;
}
export const DCTableBody: FC<BodyProps> = ({
  rows,
  columns,
  summary,
  page,
  rowsPerPage,
  onEditClick,
  onDeleteClick,
  rowStyle,
  loading,
  // Checkbox Props
  hasCheckbox,
  selectedRows,
  onSelectClick,
}) => {
  const theme = useTheme();

  const itemData = useMemo(() => {
    return {
      rows,
      columns,
      onEditClick,
      onDeleteClick,
      rowStyle,
      hasCheckbox,
      selectedRows,
      onSelectClick,
    };
  }, [
    rows,
    columns,
    onEditClick,
    onDeleteClick,
    rowStyle,
    hasCheckbox,
    selectedRows,
    onSelectClick,
  ]);

  // const emptyRows = page > 0 ? Math.max(0, rowsPerPage - rows.length) : 0;
  if (loading) {
    return (
      <TableBody>
        {[1, 2, 3, 4, 5].map((row) => {
          return (
            <TableRow key={row}>
              {columns.map((col, i) => {
                return (
                  <TableCell key={i}>
                    <Skeleton
                      variant="text"
                      height={50}
                      sx={{
                        width: "100%",
                      }}
                    />
                  </TableCell>
                );
              })}
              <TableCell key={"last"}>
                <Skeleton
                  variant="text"
                  height={50}
                  sx={{
                    width: "100%",
                  }}
                />
              </TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    );
  }
  return (
    <>
      <FixedSizeList
        itemData={itemData}
        innerElementType={React.Fragment}
        outerElementType={TableBody}
        itemCount={rows.length}
        itemSize={rows.length}
        height={rows.length * 60}
        width={"100%"}
      >
        {Row}
      </FixedSizeList>
      {summary && summary.data.length > 0 && (
        <>
          {summary.data.map((row, index) => (
            <TableRow key={"summary_row_" + row[summary.columns[0].id]}>
              {index === 0 && (
                <TableCell
                  rowSpan={summary.data.length}
                  colSpan={columns.length + 1 - summary.columns.length}
                />
              )}
              {summary.columns.map((col) => (
                <TableCell
                  key={col.id}
                  {...col}
                  sx={{ fontSize: 14, color: theme.palette.grey[600] }}
                >
                  {col.children ? col.children(row) : row[col.id]}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </>
      )}
    </>
  );
};

const Row: FunctionComponent<ListChildComponentProps<TableData>> = ({
  data,
  index,
}) => {
  const row = data.rows[index];
  const columns = data.columns;
  const onEditClick = data.onEditClick;
  const onDeleteClick = data.onDeleteClick;
  const rowStyle = data.rowStyle;
  const hasCheckbox = data.hasCheckbox;
  const selectedRows = data.selectedRows;
  const onSelectClick = data.onSelectClick;

  return (
    <TableRow key={row.id} sx={rowStyle ? rowStyle(row) : undefined}>
      {hasCheckbox && (
        <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            checked={selectedRows.includes(row.id!)}
            onChange={(_, c) => onSelectClick(c, row.id!)}
          />
        </TableCell>
      )}
      {columns.map((col: any) => (
        <TableCell key={col.id} {...col} sx={{ fontSize: 14 }}>
          {col.children ? col.children(row) : row[col.id]}
        </TableCell>
      ))}
      {(onEditClick || onDeleteClick) && (
        <TableCell key="dc_table_actions" sx={{ width: 10 }}>
          <Box display="flex" columnGap={1}>
            {onEditClick && (
              <DcDocumentIconButton onClick={() => onEditClick(row)}>
                <EditIcon />
              </DcDocumentIconButton>
            )}
            {onDeleteClick && (
              <DcDocumentIconButton onClick={() => onDeleteClick(row)}>
                <DeleteIcon color="error" />
              </DcDocumentIconButton>
            )}
          </Box>
        </TableCell>
      )}
    </TableRow>
  );
};
