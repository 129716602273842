import React from 'react';
import { InputLabel, Radio, Typography } from '@mui/material';

import { DCStorageRadioButton } from '../../assets/theme/theme';
import RadioUncheckedIcon from '../Shared/Icons/RadioUncheckedIcon';
import RadioCheckedIcon from '../Shared/Icons/RadioCheckedIcon';
export interface Props {
  label: string;
  value: string;
  name: string;
  icon: string;
  checked: boolean;
  handleClick: React.MouseEventHandler<HTMLButtonElement>
}
const DCRadioButton: React.FC<Props> = (props) => {
  return (
    <InputLabel>
      <DCStorageRadioButton>
        <Radio
          value={props.value}
          name={props.name}
          id={props.name}
          checked={props.checked}
          onClick={props.handleClick}
          icon={<RadioUncheckedIcon />}
          checkedIcon={<RadioCheckedIcon />}
        />
        <Typography mt={4} whiteSpace='pre-wrap'>
          {props.label}
        </Typography>
        <img className='storage-icon' src={props.icon} alt={props.value} />
      </DCStorageRadioButton>
    </InputLabel>
  );
};

export default DCRadioButton;
