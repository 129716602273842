import React, { useCallback } from "react";
import { useAuth } from "./AuthContext";
import { IResult, ITravelHistoryExtended } from "../firebaseTypes";
import { analytics, rtdb } from "../firebase";
import { push, ref, set } from "firebase/database";
import { logEvent } from "firebase/analytics";
import AddTravelHistoryModalNew from "../components/TravelHistoryNew/AddTravelHistoryModalNew";
import { Box } from "@mui/material";

export interface IAddTravelHistoryModalContextNew {
  // States
  open: boolean; // uses this to open/close modal
  newTravelHistory: ITravelHistoryExtended | null; // selected for editing
  setNewTravelHistory: React.Dispatch<
    React.SetStateAction<ITravelHistoryExtended | null>
  >; // uses this to set selected travel history

  // Functions
  openAddTravelHistoryModal: () => void; // uses this to open modal for a travel history
  closeAddTravelHistoryModal: () => void; // uses this to close modal
  addTravelHistory: () => Promise<IResult>; // uses this to add a travel history
  isSaveButtonDisabled: () => boolean; // uses this to enable/disable the add button
}

const defaultValues: IAddTravelHistoryModalContextNew = {
  // States
  open: false,
  newTravelHistory: null,
  setNewTravelHistory: () => {}, // state --> setNewTravelHistory

  // Functions
  openAddTravelHistoryModal: () => {},
  closeAddTravelHistoryModal: () => {},
  addTravelHistory: () => Promise.resolve({ success: false, error: "" }),
  isSaveButtonDisabled: () => false,
};

const AddTravelHistoryModalContextNew =
  React.createContext<IAddTravelHistoryModalContextNew>(defaultValues);

export function useAddTravelHistoryModalContextNew() {
  return React.useContext(AddTravelHistoryModalContextNew);
}

export interface Props {
  travelState: "usa" | "world";
}

export const AddTravelHistoryModalProviderNew: React.FC<Props> = (props) => {
  const [open, setOpen] = React.useState(defaultValues.open); // uses this to open/close modal
  const [newTravelHistory, setNewTravelHistory] =
    React.useState<ITravelHistoryExtended | null>(null); // selected for editing
  const { currentUser } = useAuth();

  // Open modal for a travel history
  const openAddTravelHistoryModal = useCallback(() => {
    logEvent(analytics, "open_modal", {
      type: `add_${props.travelState}_travels`,
    }); // log event to analytics
    setOpen(true);
  }, [props.travelState]);

  // Close modal
  const closeAddTravelHistoryModal = useCallback(() => {
    logEvent(analytics, "close_modal", {
      type: `add_${props.travelState}_travels`,
    }); // log event to analytics
    setNewTravelHistory(null);
    setOpen(false);
  }, [props.travelState]);

  // add travel history
  const addTravelHistory = useCallback(async () => {
    // Check if user is logged in and if a travel history is null
    if (!currentUser?.uid || !newTravelHistory) {
      return {
        success: false,
        error: "User not logged in or selected travel history is null",
      };
    }
    try {
      const refStr =
        props.travelState === "usa"
          ? `users/${currentUser.uid}/usa-travel-records`
          : `users/${currentUser.uid}/travel-records`;
      const travelRecordRef = ref(rtdb, refStr);
      const newRef = push(travelRecordRef);

      // adding the only required fields in database
      // Days are counted at front-end so no need to add it here
      // null because undefined is not allowed in rtdb
      await set(newRef, {
        arrival_date: newTravelHistory.arrival_date || null,
        arrival_loc: newTravelHistory.arrival_loc || null,
        departure_date: newTravelHistory.departure_date || null,
        departure_loc: newTravelHistory.departure_loc || null,
        country: newTravelHistory.country || null,
        visa_status: newTravelHistory?.visa_status
          ? newTravelHistory?.visa_status.toUpperCase()
          : "", // convert to uppercase
        automated: false, // this is to make sure that the travel history is manually added
        isPresent: newTravelHistory.isPresent || null,
      });
      // add travel history in rtdb
      closeAddTravelHistoryModal();
      return { success: true, error: "" };
    } catch (error: any) {
      console.error(error);
      // add travel history in rtdb
      closeAddTravelHistoryModal();
      return { success: false, error: error.message };
    }
  }, [
    closeAddTravelHistoryModal,
    currentUser?.uid,
    newTravelHistory,
    props.travelState,
  ]);

  // All the modal validations start here

  // save (add) travel history button handler ( enables/disables the button)
  const isSaveButtonDisabled = useCallback(() => {
    // Check if user is logged in and if a travel history is null
    if (!newTravelHistory) {
      return true;
    }
    // Check if all the required fields are filled
    // doesn't check for visa status because it is not required
    if (newTravelHistory.isPresent) {
      if (!newTravelHistory.arrival_date || !newTravelHistory.arrival_loc) {
        // doesn't have all the required fields
        return true;
      } else {
        return false;
      }
    }
    if (
      !newTravelHistory.arrival_date ||
      !newTravelHistory.arrival_loc ||
      !newTravelHistory.departure_date ||
      !newTravelHistory.departure_loc
    ) {
      // doesn't have all the required fields
      return true;
    } else {
      return false;
    }
  }, [newTravelHistory]);

  return (
    <Box>
    <AddTravelHistoryModalContextNew.Provider
      value={{
        open,
        addTravelHistory,
        closeAddTravelHistoryModal,
        newTravelHistory,
        openAddTravelHistoryModal,
        setNewTravelHistory,
        isSaveButtonDisabled,
      }}
    >
      
      <AddTravelHistoryModalNew travelState={props.travelState} />
      {props.children}
    </AddTravelHistoryModalContextNew.Provider>
    </Box>
  );
};
