import { get } from "lodash";
import { IUser } from "../firebaseTypes";

export function validateEmail(email: string) {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

export function defaultUser(currentUser: any): IUser {
  const displayName = (currentUser && currentUser.displayName) || '';
  const lastName = displayName.substring(displayName.lastIndexOf(' ') + 1)?.trim();
  const firstName = displayName.replace(lastName, '')?.trim();
  return {
    id: get(currentUser, 'uid', ''),
    firstName,
    lastName,
    email: get(currentUser, 'email', ''),
    phoneNumber: get(currentUser, 'phoneNumber', ''),
    zipCode: '',
    nationality: '',
    state: '',
    country: '',
    subscription: {
      status: "incomplete"
    },
    docSavingPreference: {
      'cloud-storage': false,
      'email-chain': false,
      'file-folder': false,
      'dont-know': false
    },
    customFolders: [],
    totalLogin: 0
  }
}

export function isSubscriptionActive(user?: IUser) {
  const status = get(user, 'subscription.status')
  return status === "active" || status === "trialing"
}