import { Grow, Box, IconButton, TextField, Typography } from "@mui/material";
import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import { useUserContext } from "../../contexts/UserContext";
import theme, { CancelBtnBadge } from "../../assets/theme/theme";
import { find } from "lodash";
import { toDocTypeSlug } from "../../utils/documentUtils";
import constants from "../../constants";
import FolderPassportIcon from "../Shared/Icons/FolderPassportIcon";

const MAX_FOLDER_NAME_LEN = 50;
export interface Props {
  setNewFolderChildren: React.Dispatch<React.SetStateAction<React.ReactNode[]>>;
}

const FolderInCreation: React.FC<Props> = ({ setNewFolderChildren }) => {
  const [removeSelf, setRemoveSelf] = React.useState(false);
  const [folderName, setFolderName] = React.useState("");
  const [isSavingFolder, setIsSavingFolder] = React.useState(false);
  const [successfullyCreated, setSuccessfullyCreated] = React.useState(false);
  const [error, setError] = React.useState("");
  const { addUserCustomFolder } = useUserContext();
  const { user, deleteUserCustomFolder } = useUserContext();

  const validateFolderName = React.useCallback(
    (folderName: string) => {
      //cannot be empty
      if (!folderName || folderName.length === 0) {
        return "Required!";
      }
      if (folderName.length > MAX_FOLDER_NAME_LEN) {
        return `Folder name must contain atmost ${MAX_FOLDER_NAME_LEN} characters.`;
      }
      const doesAlreadyExist = find(
        user?.customFolders,
        (cf) => toDocTypeSlug(cf.name) === toDocTypeSlug(folderName)
      );
      // I am not checking existance in automatic folders because we allow users to create custom-folders with same names as auto folders and we differentiate both with custom flag in IFolder.
      if (!!doesAlreadyExist) {
        return `Folder already exists with similar name "${doesAlreadyExist.name}"`;
      }
      if (constants.folders.includes(folderName)) {
        return `Reserved Keyword, try with other name "${folderName}"`;
      }
      return "";
    },
    [user]
  );

  const onFolderNameChange = React.useCallback(
    (evt: any) => {
      const val = evt.target.value;
      const err = validateFolderName(val);
      setError(err);
      setFolderName(val);
    },
    [validateFolderName]
  );

  const onFolderSave = React.useCallback(async () => {
    const validationError = validateFolderName(folderName);
    if (validationError) {
      setError(validationError);
      return;
    }

    // create folder
    setIsSavingFolder(true);
    setNewFolderChildren([]);
    const { success, error } = await addUserCustomFolder(folderName);
    if (success) {
      setError("");
      setSuccessfullyCreated(true);
    } else {
      setError(error);
      setSuccessfullyCreated(false);
    }
    setIsSavingFolder(false);
  }, [
    validateFolderName,
    folderName,
    addUserCustomFolder,
    setNewFolderChildren,
  ]);

  const onKeyPress = React.useCallback(
    (evt: React.KeyboardEvent) => {
      if (evt.key === "Enter") {
        onFolderSave();
      }
    },
    [onFolderSave]
  );

  if (removeSelf) {
    return null;
  }

  return (
    <Grow in={true}>
      <Box
        display="flex"
        alignItems="baseline"
        justifyContent="center"
        px={1}
        pt={5}
        minHeight={196}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "121.114px",
            color: theme.palette.text.secondary,
          }}
        >
          <CancelBtnBadge
            badgeContent={
              <IconButton
                onClick={async () => {
                  if (successfullyCreated) {
                    await deleteUserCustomFolder(folderName);
                    setRemoveSelf(true);
                  } else {
                    setRemoveSelf(true);
                  }
                  setNewFolderChildren([]);
                }}
              >
                <CloseIcon fontSize="small" />
              </IconButton>
            }
          >
            <FolderPassportIcon />
          </CancelBtnBadge>
          {successfullyCreated ? (
            <Typography textAlign="center" lineHeight={1.5} mt="8px">
              {folderName}
            </Typography>
          ) : (
            <TextField
              spellCheck={false}
              sx={{ marginTop: 1 }}
              error={!!error}
              FormHelperTextProps={{ style: { textAlign: "center" } }}
              helperText={error}
              disabled={isSavingFolder}
              onKeyPress={onKeyPress}
              onBlur={onFolderSave}
              value={folderName}
              onChange={onFolderNameChange}
              inputProps={{
                min: 0,
                style: { textAlign: "center", lineHeight: "2" },
                maxLength: MAX_FOLDER_NAME_LEN,
              }}
              required
              size="small"
              variant="standard"
              autoFocus
              placeholder="Folder Name"
            />
          )}
        </Box>
      </Box>
    </Grow>
  );
};

export default FolderInCreation;
