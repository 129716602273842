import {
  DialogContent,
  Dialog,
  Chip,
  FormControl,
  DialogActions,
  Button,
  Typography,
  FormLabel,
  Box,
  ListItem,
  Stack,
} from "@mui/material";
import React from "react";

import { IDocument } from "../../firebaseTypes";
import theme, { DcInput } from "../../assets/theme/theme";
import { isEmpty, map } from "lodash";
import LoadingButton from "@mui/lab/LoadingButton";
import WarningOutlineIcon from "../Shared/Icons/WarningOutlineIcon";
import { getDocName } from "../../utils/documentUtils";

export interface Props {
  open: boolean;
  documents: IDocument[];
  onClose: () => any;
  onDelete: () => Promise<any>;
}

const DeleteConfimationModal: React.FC<Props> = ({
  onClose,
  documents,
  onDelete,
  open,
}) => {
  const [textField, setTextField] = React.useState("");
  const [isDeleting, setIsDeleting] = React.useState(false);
  const thisOrThese = documents.length > 1 ? `these` : `this`;
  const documentz = documents.length > 1 ? `documents` : `document`;
  const documentNamez =
    documents.length > 1 ? `Document Names` : `Document Name`;

  const this_onDelete = React.useCallback(async () => {
    setIsDeleting(true);
    await onDelete();
    setIsDeleting(false);
    setTextField("");
  }, [onDelete]);

  const this_onClose = React.useCallback(async () => {
    setIsDeleting(false);
    setTextField("");
    onClose();
  }, [onClose]);

  if (isEmpty(documents)) {
    return null;
  }

  return (
    <Dialog
      className="delete-document-blog"
      maxWidth="sm"
      open={open}
      onClose={this_onClose}
      disableEscapeKeyDown={isDeleting}
    >
      <DialogContent
        sx={{
          padding: "32px",
        }}
      >
        <Stack spacing={2} direction="row" mt={2} mb={2}>
          <Box
            sx={{
              bgcolor: theme.palette.error.light,
              width: 40,
              height: 40,
              borderRadius: 40,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              color: theme.palette.error.main,
            }}
          >
            <WarningOutlineIcon />
          </Box>
          <Stack spacing={1} flex={1}>
            <Typography fontSize={20} fontWeight={500}>
              {`Delete ${thisOrThese} ${documentz} ?`}
            </Typography>
            <Typography>
              {" "}
              {`Doing so will permanently delete ${thisOrThese} ${documentz}, which cannot be recovered later.`}
            </Typography>
            <Box>
              <Typography color={theme.palette.text.secondary} mt={1} mb={1}>
                {`${documentNamez}`}
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  // justifyContent: 'center',
                  flexWrap: "wrap",
                  listStyle: "none",
                  gap: "10px",
                  p: 0,
                  m: 0,
                }}
                component="ul"
              >
                {map(documents, (doc, i) => {
                  const truncate = (text: string, maxLength: number) => {
                    if (text.length > maxLength) {
                      return `${text.substring(0, maxLength)}...`;
                    }
                    return text;
                  };

                  return (
                    <ListItem sx={{ p: 0, width: "unset" }} key={i}>
                      <Chip key={i} label={truncate(getDocName(doc), 20)} />
                    </ListItem>
                  );
                })}
                {/* <Chip label='passport_082018_adogra.pdf' /> */}
                {/* <Chip label='passport_082018_adogra.pdf' /> */}
              </Box>
            </Box>

            <FormControl
              sx={{
                paddingTop: 2,
              }}
              fullWidth
            >
              {/* <Typography color={theme.palette.text.secondary} mb="10px">
            Confirm that you want to delete {`${thisOrThese}`} {`${documentz}`} by typing <span style={{ fontWeight: 600, color: theme.palette.text.primary }}>DELETE</span>
          </Typography> */}
              <FormLabel
                sx={{
                  mb: -2,
                }}
              >
                <Typography color={theme.palette.text.secondary}>
                  Confirm that you want to delete {`${thisOrThese}`}{" "}
                  {`${documentz}`} by typing{" "}
                  <span
                    style={{
                      fontWeight: 600,
                      color: theme.palette.text.primary,
                    }}
                  >
                    DELETE
                  </span>
                </Typography>
              </FormLabel>
              <DcInput
                value={textField}
                placeholder="DELETE"
                onChange={(evt) => setTextField(evt.target.value)}
                inputProps={{
                  style: {
                    textTransform: "uppercase",
                  },
                }}
              />
            </FormControl>
          </Stack>
        </Stack>

        {/* <Box component="ul" sx={{
          display: 'flex',
          justifyContent: 'left',
          flexWrap: 'wrap',
        }} className="clear-list-style">
          {map(documents, (doc, i) => {
            return <li key={`doc-delete-list-item-${i}`} style={{
              marginRight: '10px',
              marginBottom: '10px'
            }}>
              <Chip label={doc.doc_name} />
            </li>
          })}
        </Box> */}
      </DialogContent>
      <DialogActions className="cancel-btn">
        <Button variant="outlined" onClick={this_onClose} disabled={isDeleting}>
          Cancel
        </Button>

        <LoadingButton
          loading={isDeleting}
          loadingPosition="center"
          variant="contained"
          color="error"
          onClick={this_onDelete}
          disabled={(textField || "").toLowerCase() !== "delete" || isDeleting}
          aria-label="delete document"
        >
          Delete
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteConfimationModal;
