import { Box } from "@mui/material";
import LogoIcon from "./Icons/LogoIcon";

export default function Logo(props: { width?: any; height?: any }) {
  return (
    <Box component="a" href="/" m={0} p={0} display="inline-flex" className="logo-img-wrapper">
      <LogoIcon width={props.width} height={props.height} style={{ maxWidth: "165px" }} />
    </Box>
  );
}
