import Radio from "@mui/material/Radio";
import React from "react"
import RadioUncheckedIcon from "./Icons/RadioUncheckedIcon";
import RadioCheckedIcon from "./Icons/RadioCheckedIcon";

export default function CardCheckbox({
  show,
  checked,
  onClick,
}: {
  show: boolean;
  checked: boolean;
  onClick: React.MouseEventHandler<HTMLButtonElement> | undefined;
}) {
  return show || checked ? (
    <Radio
      checked={checked}
      onClick={onClick}
      sx={{
        position: "absolute",
      }}
      color="default"
      icon={<RadioUncheckedIcon />}
      checkedIcon={<RadioCheckedIcon />}
    />
  ) : null;
}
