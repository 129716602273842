import React from "react"
import { Redirect } from "react-router-dom";
import { useAuth } from "../../contexts/AuthContext";

export interface Props {
}

const LogOut: React.FC<Props> = (props) => {
  const { logout } = useAuth();
  logout();
  return <Redirect to={"/"} />;
}

export default LogOut
