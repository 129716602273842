import React, { useState, useCallback } from "react";
import { GridColDef } from "@mui/x-data-grid";
import { DcDataGrid, DcDocumentIconButton } from "../../assets/theme/theme";
import DownloadIcon from "../Shared/Icons/DownloadDocumentIcon";
import DeleteIcon from "../Shared/Icons/DeleteIcon";
import SendIcon from "@mui/icons-material/ShareOutlined";
import { useDocumentGalleryContext } from "../../contexts/DocumentGalleryContext";
import { getDocName, getFieldDisplayValueFromSeconds } from "../../utils/documentUtils";
import * as BinaryUtils from "../../utils/binaryUtils";
import { map } from "lodash";
import { IDocument } from "../../firebaseTypes";
import { get } from "lodash";
import { useDocumentShareModalContext } from "../../contexts/DocumentShareModalContext";
import { useDocumentContext } from "../../contexts/DocumentContext";
import { usePdfPreviewModalContext } from "../../contexts/PdfPreviewModalContext";
import { Link } from "@mui/material";
import { useDocumentDeleteModal } from "../../contexts/DocumentDeleteModalContext";

function ActionsMenu({ document }: { document: IDocument }) {
  const [disableDownloadBtn, setDisableDownloadBtn] = useState(false);
  const { openShareModalFor } = useDocumentShareModalContext();
  const { downloadDocument } = useDocumentContext();
  const { openDeleteModalFor } = useDocumentDeleteModal();

  const [documents, setDocuments] = useState<IDocument[]>([]);
  const onShareButtonClick = useCallback(() => {
    openShareModalFor([document]);
  }, [document, openShareModalFor]);

  const onDownloadDocument = useCallback(async () => {
    setDisableDownloadBtn(true);
    await downloadDocument(document);
    setDisableDownloadBtn(false);
  }, [document, downloadDocument]);

  React.useEffect(() => {
    setDocuments((prev) => [...prev, document]);
  }, [document]);

  const onDeleteDocument = useCallback(async () => {
    openDeleteModalFor(documents);
  }, [documents, openDeleteModalFor]);

  return (
    <>
      <DcDocumentIconButton
        aria-label="download"
        disabled={disableDownloadBtn}
        onClick={onDownloadDocument}
      >
        <DownloadIcon sx={{ fontSize: 24 }} />
      </DcDocumentIconButton>
      <DcDocumentIconButton onClick={onShareButtonClick}>
        <SendIcon sx={{ fontSize: 24 }} />
      </DcDocumentIconButton>
      <DcDocumentIconButton
        aria-label="delete"
        onClick={() => onDeleteDocument()}
      >
        <DeleteIcon sx={{ fontSize: 24 }} />
      </DcDocumentIconButton>
    </>
  );
}

function FileNameLink(props: { document: IDocument }) {
  const { openModalForDocument } = usePdfPreviewModalContext();
  return (
    <Link
      sx={{
        cursor: "pointer",
      }}
      onClick={() => openModalForDocument(props.document)}
    >
      {getDocName(props.document)}
    </Link>
  );
}

const columns: GridColDef[] = [
  {
    field: "filename",
    headerName: "File Name",
    width: 400,
    renderCell: (cellValues) => {
      return <FileNameLink document={cellValues.row.doc} />;
    },
  },
  {
    field: "size",
    headerName: "Size",
    width: 150,
  },
  {
    field: "uploadedOn",
    headerName: "Uploaded On",
    width: 250,
  },
  {
    field: "actions",
    headerName: "Action",
    width: 200,
    sortable: false,
    renderCell: (cellValues) => {
      return <ActionsMenu document={cellValues.row.doc} />;
    },
  },
];

function getRows(documents: IDocument[]) {
  return map(documents, (doc) => ({
    id: doc.id,
    doc,
    filename: getDocName(doc),
    size: BinaryUtils.humanFileSize(doc.doc_size, true),
    uploadedOn: getFieldDisplayValueFromSeconds(doc.created_time || 0),
    actions: "",
  }));
}

export interface Props {}

const DocumentsList: React.FC<Props> = (props) => {
  const { folderInView, selectDocIds, selectionModal } =
    useDocumentGalleryContext();

  const onSelectionModalChange = React.useCallback(
    (newSelectionModal) => {
      selectDocIds(newSelectionModal, folderInView?.docType);
    },
    [selectDocIds, folderInView]
  );

  if (!folderInView) return null;
  return (
    <>
      {folderInView.docs && folderInView.docs.length && (
        <DcDataGrid
        
          selectionModel={
            selectionModal[get(folderInView, "docType", "")] || []
          }
          onSelectionModelChange={onSelectionModalChange}
          rows={getRows(folderInView.docs)}
          columns={columns}
          checkboxSelection
          hideFooterPagination
          autoHeight
          disableColumnMenu
          disableColumnSelector
          disableColumnFilter
          disableSelectionOnClick
        />
      )}
    </>
  );
};
export default DocumentsList;
