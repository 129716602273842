import { isAfter, isBefore, isWithinInterval, toDate } from "date-fns";
import { useCallback, useMemo } from "react";
import { airports } from "../assets/airportData";
import { usaAirports } from "../assets/usaAirportData";
import { useTravelHistoryContextNew } from "../components/TravelHistoryNew/TravelHistoryContextNew";
import { AirportModel } from "../models/AirportModel";
import { ITravelHistoryExtended } from "../firebaseTypes";

const getAirportLabel = (airport: AirportModel): string =>
  `${airport.city} (${airport.airport_code}), ${airport.country}`;

export const usaAirportList = (usaAirports as AirportModel[]).map((a) => ({
  ...a,
  label: getAirportLabel(a),
}));

export const airportList = (airports as AirportModel[])
  .filter((a) => a.flag_code !== "US")
  .map((a) => ({
    ...a,
    label: getAirportLabel(a),
  }));

export const useTravelDatesValidationNew = (selectedTravelHistory: ITravelHistoryExtended | null) => {
  const { usaTravelRecords, worldTravelRecords } = useTravelHistoryContextNew();

  const travels = useMemo(() => [...usaTravelRecords, ...worldTravelRecords], [usaTravelRecords, worldTravelRecords]);
  // const travels = usaTravelRecords

  // Remember that this function runs for each and every date displayed in calender
  // arrival date 
  // arrival cannot be after today
  // arrival date should not coincide between with saved travel logs
  // if there is a departure date, then the arrival should be before departure

  const shouldDisableArrivalDate = useCallback(
    (date: Date) => {
      const _travel = travels.filter(object => {
        // remove self
        if (object.id === selectedTravelHistory?.id) return false;
        // remove any invalid travels where sometimes arrival or departure is parsed null by machine learning
        if (object.arrival_date_parsed === null || (object.departure_date_parsed === null && !object.isPresent)) {
          return false
        }
        return true
      });
      return isAfter(date, new Date()) ||
        (selectedTravelHistory?.departure_date_parsed && isAfter(date, selectedTravelHistory.departure_date_parsed)) ||
        _travel.some(
          (t) => {
            const interval = { start: t.arrival_date_parsed as Date, end: t.departure_date_parsed as Date }
            const startTime = toDate(interval.start).getTime();
            const endTime = toDate(interval.end).getTime();

            // Throw an exception if start date is after end date or if any date is `Invalid Date`
            if (!(startTime <= endTime)) {
              return false
            }
            // isWithinInterval throw an exception if start date is after end date or if any date is `Invalid Date`
            return isWithinInterval(date, interval)
          }

        );
    },
    [selectedTravelHistory?.departure_date_parsed, selectedTravelHistory?.id, travels]
  );

  // departure date 
  // departure cannot be after today
  // departure date should be disabled if arrival date is null  ----- > this validation will be on datepicker disabled prop 
  // departure date cannot be before arrival date 
  // departure date should not coincide between with saved travel logs
  // departure date should be disabled if currently in the country is enabled ----- > this validation will be on datepicker disabled prop

  const shouldDisableDepartureDate = useCallback(
    (date: Date) => {
      const _travel = travels.filter(object => {
        // remove self
        if (object.id === selectedTravelHistory?.id) return false;
        // remove any invalid travels where sometimes arrival or departure is parsed null by machine learning
        if (object.arrival_date_parsed === null || (object.departure_date_parsed === null && !object.isPresent)) {
          return false
        }
        return true
      });

      return isAfter(date, new Date()) ||
        isBefore(date, selectedTravelHistory?.arrival_date_parsed as Date) ||
        _travel.some(
          (t) => {
            const interval = { start: t.arrival_date_parsed as Date, end: t.departure_date_parsed as Date }
            const startTime = toDate(interval.start).getTime();
            const endTime = toDate(interval.end).getTime();

            // Throw an exception if start date is after end date or if any date is `Invalid Date`
            if (!(startTime <= endTime)) {
              return false
            }
            // isWithinInterval throw an exception if start date is after end date or if any date is `Invalid Date`
            return isWithinInterval(date, interval)
          }
        );
    },
    [selectedTravelHistory?.arrival_date_parsed, selectedTravelHistory?.id, travels]
  );

  // checks if any of the travel records has isPresent = true
  const shouldDisableIsPresent = useCallback(() => {
    const _travel = travels.filter(object => {
      // remove self
      if (object.id === selectedTravelHistory?.id) return false;
      // remove any invalid travels where sometimes arrival or departure is parsed null by machine learning
      if (object.arrival_date_parsed === null || (object.departure_date_parsed === null && !object.isPresent)) {
        return false
      }
      return true
    });

    return !selectedTravelHistory?.arrival_date_parsed || _travel.some((t) =>
      t.isPresent
    )
    // return travels.some((t) => {
    //   if (t.isPresent) {
    //     // if any of the travel records has isPresent = true, return true means disable the isPresent checkbox
    //     return true;
    //   } else {
    //     // if the selected travel record has isPresent = false, check if the selected travel record has arrival date
    //     if (selectedTravelHistory?.arrival_date_parsed === undefined) return true;
    //     // if the selected travel record has isPresent = false, check if any of the arrival date is after the selected travel record's arrival date
    //     if (isAfter(t.arrival_date_parsed as Date, selectedTravelHistory?.arrival_date_parsed as Date)) {
    //       return true;
    //     }
    //     return false;
    //   }
    // });
  }, [selectedTravelHistory?.arrival_date_parsed, selectedTravelHistory?.id, travels]);

  return { shouldDisableArrivalDate, shouldDisableDepartureDate, shouldDisableIsPresent };
};
