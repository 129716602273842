import React, { useCallback, useState } from "react";
import { ICustomDocument } from "../firebaseTypes";
import { ref, onValue } from "firebase/database";
import { useAuth } from "./AuthContext";
import { rtdb } from "../firebase";
import { debounce } from "lodash";
import constants from "../constants";
import { IGetCustomDocumentsResponse } from "../middleTypes";

export interface createCustomCardContext {
  customCardArray: ICustomDocument[];
}

const defaultValues: createCustomCardContext = {
  customCardArray: [],
};

const context = React.createContext(defaultValues);

export function useCreateCustomCardContext() {
  return React.useContext(context);
}

export const CustomCardProvider: React.FC = (props: any) => {
  const [customCardArray, setCustomCardArray] = React.useState<
    ICustomDocument[]
  >([]);
  const [isFetchingDocuments, setIsFetchingDocuments] = useState(false);

  const { currentUser } = useAuth();

  const onValueCallback = useCallback(async () => {
    if (!currentUser?.uid) return;
    if (isFetchingDocuments) return;
    try {
      console.log("onValue start");
      setIsFetchingDocuments(true);
      const url = `${constants.middleHost}/users/${currentUser.uid}/customDocuments?page=1&itemsPerPage=100`;
      const token = await currentUser.getIdToken();
      // fetch the image
      const res: Response = await fetch(url, {
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: "application/json",
        },
      });
      const { data }: IGetCustomDocumentsResponse = await res.json();
      const docs = data;
      // map(docs, (doc) => {
      //   getDocThumbnailUrl(doc);
      // });
      console.log(`undo: custom docs`, docs);
      setCustomCardArray(docs);
      setIsFetchingDocuments(false);
      console.log("onValue end");
    } catch (error) {
      console.error(error);
    }
  }, [currentUser, isFetchingDocuments]);

  // const debouncedOnValue = useCallback(
  //   ,
  //   [currentUser]
  // );

  const debouncedOnValue = debounce(onValueCallback, 500, {
    leading: false,
  });

  React.useEffect(() => {
    // Register onValue
    if (!currentUser?.uid) return;
    const customDocumentsRef = ref(
      rtdb,
      `users/${currentUser.uid}/customDocuments`
    );
    // important: onValueCallback has to be throttled because we don't want to add load to fetch documents api in middle server
    // allow one call in one second
    onValueCallback();
    const unsubscribe = onValue(customDocumentsRef, debouncedOnValue);
    return unsubscribe;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser?.uid]);

  return (
    <context.Provider
      value={{
        customCardArray,
      }}
    >
      {props.children}
    </context.Provider>
  );
};
