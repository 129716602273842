import {
  Snackbar,
  AlertTitle,
  Slide,
  SlideProps,
  SvgIconPropsColorOverrides,
  SnackbarOrigin,
} from "@mui/material";
import React from "react";
import { DCAlertToast } from "../../assets/theme/theme";
import InfoIcon from "@mui/icons-material/Info";
import { OverridableStringUnion } from "@mui/types";

function Transition(props: SlideProps) {
  return <Slide {...props} direction="left" />;
}

interface Props {
  anchorOrigin: SnackbarOrigin;
  open: boolean;
  onClose: () => void;
  title: string;
  body?: string;
  isCloseable?: boolean; // by default isCloseable is false
  icon?: React.ReactNode; // icon is a required prop
  iconColor?:
    | OverridableStringUnion<
        | "inherit"
        | "disabled"
        | "action"
        | "error"
        | "primary"
        | "secondary"
        | "info"
        | "success"
        | "warning",
        SvgIconPropsColorOverrides
      >
    | undefined;
}

const DCSnackbar: React.FC<Props> = ({
  anchorOrigin = {
    vertical: "top",
    horizontal: "right",
  },
  onClose,
  open,
  title,
  body = null,
  isCloseable,
  iconColor = "primary",
  icon = <InfoIcon color={iconColor} />,
}) => {
  return (
    <Snackbar
      sx={{
        marginTop: 6,
      }}
      anchorOrigin={anchorOrigin}
      TransitionComponent={Transition}
      open={open}
      autoHideDuration={5000}
      onClose={onClose}
    >
      <DCAlertToast
        onClose={isCloseable ? onClose : undefined}
        sx={{ width: "100%", minWidth: 250 }}
        icon={icon}
      >
        {title && <AlertTitle sx={{ marginTop: 0.1 }}>{title}</AlertTitle>}
        {body}
      </DCAlertToast>
    </Snackbar>
  );
};

export default DCSnackbar;
