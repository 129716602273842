import { LocalizationProvider, LoadingButton } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import {
  DialogTitle,
  DialogContent,
  Grid,
  Box,
  FormControlLabel,
  Checkbox,
  Select,
  MenuItem,
  ListItemText,
  DialogActions,
} from "@mui/material";
import { DCButton, DCDialog } from "../../assets/theme/theme";
import {
  DCAutocomplete,
  DCAutocompleteTextField,
  DcInput,
} from "../../assets/theme/theme";
import { DCDatePicker } from "../Shared/DCDatePicker";
import { DCFormLabel } from "../Shared/DCFormLabel";
import constants from "../../constants";
import { useAddTravelHistoryModalContextNew } from "../../contexts/AddTravelHistoryModalContextNew";
import { dateFormat } from "../../utils/dateTimeUtils";
import { Flag } from "../Shared/Icons/Flag";
import { useCallback, useEffect } from "react";
import {
  airportList as worldAirportList,
  usaAirportList,
  useTravelDatesValidationNew,
} from "../../utils/travelHistoryUtils";
import { isEmpty } from "lodash";
import { usePreventRefreshDialogContext } from "../../contexts/PreventRefreshDialogContext";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export interface Props {
  travelState: "usa" | "world";
}

const AddTravelHistoryModalNew: React.FC<Props> = (props) => {
  const { showPreventRefreshDialog, hidePreventRefreshDialog } =
    usePreventRefreshDialogContext();
  const {
    open,
    closeAddTravelHistoryModal,
    newTravelHistory,
    setNewTravelHistory,
    addTravelHistory,
    isSaveButtonDisabled,
  } = useAddTravelHistoryModalContextNew();

  const {
    shouldDisableArrivalDate,
    shouldDisableDepartureDate,
    shouldDisableIsPresent,
  } = useTravelDatesValidationNew(newTravelHistory);

  const airportList =
    props.travelState === "usa" ? usaAirportList : worldAirportList;

  // handles the change of the arrival date
  const onChangeArrivalDate = useCallback(
    (_data: Date, val: number) => {
      const formattedDate = dateFormat(val); // format the date in YYYY-MM-DD format
      setNewTravelHistory((prev: any) => ({
        ...prev,
        arrival_date: formattedDate,
        arrival_date_parsed: _data,
      }));
    },
    [setNewTravelHistory]
  );

  // handles the change of the departure date
  const onChangeDepartureDate = useCallback(
    (_data: Date, val: number) => {
      const formattedDate = dateFormat(val); // format the date in YYYY-MM-DD format
      setNewTravelHistory((prev: any) => ({
        ...prev,
        departure_date: formattedDate,
        departure_date_parsed: _data,
      }));
    },
    [setNewTravelHistory]
  );

  // handles the change of the arrival place
  const onChangeArrivalPlace = useCallback(
    (val: any) => {
      setNewTravelHistory((prev: any) => ({
        ...prev,
        country: val?.country,
        arrival_loc_label: val?.label,
        arrival_loc: val?.airport_code,
      }));
    },
    [setNewTravelHistory]
  );

  // handles the change of the departure place
  const onChangeDeparturePlace = useCallback(
    (val: any) => {
      setNewTravelHistory((prev: any) => ({
        ...prev,
        departure_loc_label: val?.label,
        departure_loc: val?.airport_code,
      }));
    },
    [setNewTravelHistory]
  );

  // handles the change of the isPresent checkbox
  const onChangeIsPresent = useCallback(
    (e: any) => {
      setNewTravelHistory((prev: any) => ({
        ...prev,
        isPresent: e.target.checked,
        departure_loc_label: "",
        departure_loc: "",
        departure_date: "",
        departure_date_parsed: null,
      }));
    },
    [setNewTravelHistory]
  );
  const isWorldTravel = props.travelState === "world";

  useEffect(() => {
    if (open)
      showPreventRefreshDialog(
        "All the changes made will be lost, are you sure want to continue?"
      );
    else hidePreventRefreshDialog();
  }, [hidePreventRefreshDialog, showPreventRefreshDialog, open]);
  

  return (
    <DCDialog
      open={open}
      // onClose={closeAddTravelHistoryModal}
      sx={{
        "& .MuiDialog-container": {
          "& .MuiPaper-root": {
            width: "100%",
            maxWidth: "1000px",
          },
        },
      }}
    >
      <DialogTitle>
        Create
        {isWorldTravel ? "" : " USA"} Travel log
      </DialogTitle>
      <DialogContent className="travel-log-popup">
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <Grid container spacing={3} className="travel-log-content">
            <Grid item xs={6} className="travel-log-port-country">
              <DCFormLabel htmlFor="departing-city-country">
                Port Of Entry
              </DCFormLabel>
              <DCAutocomplete
                id="departing-city-country"
                options={airportList}
                value={newTravelHistory?.arrival_loc_label}
                onChange={(_, val: any) => onChangeArrivalPlace(val)}
                renderInput={(params) => (
                  <DCAutocompleteTextField
                    {...params}
                    error={isEmpty(newTravelHistory?.arrival_loc)}
                    helperText={
                      isEmpty(newTravelHistory?.arrival_loc) ? "Required" : ""
                    }
                    required
                    placeholder="Select City, Country"
                  />
                )}
              />
            </Grid>
            <Grid item xs={6} className="travel-log-port-country">
              <DCFormLabel htmlFor="visiting-city-country">
                Port Of Exit
              </DCFormLabel>
              <DCAutocomplete
                id="visiting-city-country"
                key={
                  newTravelHistory?.isPresent
                    ? "departure_present"
                    : "visiting-city-country"
                }
                options={airportList}
                disabled={newTravelHistory?.isPresent}
                value={newTravelHistory?.departure_loc_label}
                onChange={(_, val: any) => onChangeDeparturePlace(val)}
                renderInput={(params) => (
                  <DCAutocompleteTextField
                    {...params}
                    error={
                      newTravelHistory?.isPresent
                        ? false
                        : isEmpty(newTravelHistory?.departure_loc)
                    }
                    helperText={
                      newTravelHistory?.isPresent
                        ? false
                        : isEmpty(newTravelHistory?.departure_loc)
                        ? "Required"
                        : ""
                    }
                    required
                    placeholder={
                      newTravelHistory?.isPresent
                        ? "Currently in the country"
                        : "Select City, Country"
                    }
                  />
                )}
              />
            </Grid>
            <Grid item xs={6} className="travel-log-port-country">
              <DCFormLabel htmlFor="arrival-date">
                Arrival Date
                {newTravelHistory?.country
                  ? ` (${newTravelHistory?.country})`
                  : ""}
                <Flag
                  code={newTravelHistory?.country || ""}
                  width={20}
                  style={{ marginLeft: 8, marginBottom: -1 }}
                />
              </DCFormLabel>
              <DCDatePicker
                id="arrival-date"
                value={newTravelHistory?.arrival_date_parsed as any}
                onChange={onChangeArrivalDate}
                shouldDisableDate={shouldDisableArrivalDate}
              />
            </Grid>
            <Grid item xs={6} className="travel-log-port-country">
              <Box position="relative">
                <DCFormLabel htmlFor="departure-date">
                  Departure Date
                  {newTravelHistory?.country
                    ? ` (${newTravelHistory?.country})`
                    : ""}
                  <Flag
                    code={newTravelHistory?.country || ""}
                    width={20}
                    style={{ marginLeft: 8, marginBottom: -1 }}
                  />
                </DCFormLabel>
                <DCDatePicker
                  id="departure-date"
                  value={newTravelHistory?.departure_date_parsed as Date}
                  disabled={
                    !newTravelHistory?.arrival_date_parsed ||
                    newTravelHistory?.isPresent
                  }
                  placeholder={
                    newTravelHistory?.isPresent ? "In country" : undefined
                  }
                  onChange={onChangeDepartureDate}
                  shouldDisableDate={shouldDisableDepartureDate}
                />
                <Box position="absolute" bottom={-40}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        disabled={shouldDisableIsPresent()}
                        checked={newTravelHistory?.isPresent}
                        onChange={onChangeIsPresent}
                      />
                    }
                    label="Currently in the country"
                  />
                </Box>
              </Box>
            </Grid>
            <Grid item xs={6} className="travel-log-port-country">
              <DCFormLabel htmlFor="visa-status" className="visa-type">Visa Type</DCFormLabel>
              <Box>
                {isWorldTravel ? (
                  <DcInput
                    id="visa-status"
                    fullWidth
                    value={newTravelHistory?.visa_status}
                    onChange={(e) =>
                      setNewTravelHistory((prev: any) => ({
                        ...prev,
                        visa_status: e.target.value,
                      }))
                    }
                    placeholder="e.g. : H1B, F1, B2"
                    inputProps={{ style: { textTransform: "uppercase" } }}
                  />
                ) : (
                  <Select
                    fullWidth
                    value={newTravelHistory?.visa_status}
                    onChange={(e) =>
                      setNewTravelHistory((prev: any) => ({
                        ...prev,
                        visa_status: e.target.value,
                      }))
                    }
                    input={
                      <DcInput
                        id="visa-status"
                        placeholder="e.g. : H1B, F1, B2"
                        inputProps={{ style: { textTransform: "uppercase" } }}
                      />
                    }
                    MenuProps={MenuProps}
                  >
                    {constants.VisaStatusList.map((year) => (
                      <MenuItem key={year} value={year}>
                        <ListItemText primary={year} />
                      </MenuItem>
                    ))}
                  </Select>
                )}
              </Box>
            </Grid>
          </Grid>
        </LocalizationProvider>
      </DialogContent>
      <DialogActions>
        <DCButton
          variant="outlined"
          aria-label="close window"
          onClick={closeAddTravelHistoryModal}
        >
          Close
        </DCButton>

        <LoadingButton
          loadingPosition="center"
          aria-label="save custom card"
          variant="contained"
          // loading={isUpdatingTravelHistory}
          onClick={addTravelHistory}
          disabled={isSaveButtonDisabled()}
        >
          Save
        </LoadingButton>
      </DialogActions>
    </DCDialog>
  );
};

export default AddTravelHistoryModalNew;
