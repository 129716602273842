import { Skeleton, Box, useMediaQuery, Paper } from "@mui/material";
import { map } from "lodash";
import React from "react";
import { Document as PDFDocument, Page as PDFPage } from "react-pdf";
import theme from "../../assets/theme/theme";

export interface Props {
  url: string;
}

// pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

function calculateScale(
  matchUpXs: boolean,
  matchUpSm: boolean,
  matchUpMd: boolean,
  matchUpLg: boolean
): number {
  if (matchUpLg) {
    return 2.3;
  }
  if (matchUpMd) {
    return 1.8;
  }
  if (matchUpSm) {
    return 1;
  }
  return 0.5;
}

// Simple display of PDF with 100% width and height, all pages will be displayed
const PDFViewerV2: React.FC<Props> = ({ url }) => {
  const [numPages, setNumPages] = React.useState(0);
  const matchUpXs = useMediaQuery(theme.breakpoints.up("xs"));
  const matchUpSm = useMediaQuery(theme.breakpoints.up("sm"));
  const matchUpMd = useMediaQuery(theme.breakpoints.up("md"));
  const matchUpLg = useMediaQuery(theme.breakpoints.up("lg"));
  const [scale, setScale] = React.useState(
    calculateScale(matchUpXs, matchUpSm, matchUpMd, matchUpLg)
  );
  const [skeletonNode] = React.useState(
    <Skeleton
      variant="rectangular"
      width={"calc(100vw - 100px)"}
      height={"100vh"}
    />
  );
  // As per recommended here, https://github.com/wojtekmaj/react-pdf/wiki/Frequently-Asked-Questions#react-pdf-reloads-itself-with-every-render-whats-going-on
  const [file, setFile] = React.useState({
    url: "",
  });

  React.useEffect(() => {
    async function init() {
      setFile((prev) => ({
        ...prev,
        url,
      }));
    }
    init();
  }, [url]);

  const onDocumentLoadSuccess = React.useCallback(({ numPages }: any) => {
    setNumPages(numPages);
  }, []);

  React.useEffect(() => {
    setScale(calculateScale(matchUpXs, matchUpSm, matchUpMd, matchUpLg));
  }, [matchUpXs, matchUpSm, matchUpMd, matchUpLg]);

  return (
    <Box component={Paper} elevation={5}>
      <PDFDocument
        file={file}
        loading={skeletonNode}
        // renderMode="svg"

        className="dc-pdf-document"
        onLoadSuccess={onDocumentLoadSuccess}
      >
        {map(
          Array.from({ length: numPages }, (_, i) => i + 1),
          (num) => {
            return (
              <PDFPage
                key={`dc-pdf-document-page-${num}`}
                pageNumber={num}
                scale={scale}
              />
            );
          }
        )}
      </PDFDocument>
      {/* <img src={url} alt="" /> */}
    </Box>
  );
};

export default PDFViewerV2;
