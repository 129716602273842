import React from "react";
import { Tab, Tabs, Typography } from "@mui/material";
import { map } from "lodash";
import { Link } from "react-router-dom";
import theme from "../../assets/theme/theme";

// interface TabPanelProps {
//   children?: React.ReactNode;
//   index: number;
//   value: number;
// }

// function TabPanel(props: TabPanelProps) {
//   const { children, value, index, ...other } = props;

//   return (
//     <div
//       role='tabpanel'
//       hidden={value !== index}
//       id={`simple-tabpanel-${index}`}
//       aria-labelledby={`simple-tab-${index}`}
//       {...other}
//     >
//       {value === index && children}
//     </div>
//   );
// }

function a11yProps(index: number, label: string) {
  return {
    id: `${label}-tab-${index}`,
    "aria-controls": `${label}-tabpanel-${index}`,
  };
}

export interface ITab {
  value: string;
  label: string;
  disabled: boolean;
  href: string;
  secondaryLabel: string;
}

export interface Props {
  tabs: ITab[];
  selectedValue: string;
}

const O1EB1NavTabs = (props: Props) => {
  return (
    <>
      <Tabs
        variant="scrollable"
        scrollButtons={"auto"}
        value={props.selectedValue}
        aria-label="documents category tabs"
      >
        {map(props.tabs, (tab, index) => (
          <Tab
            disabled={tab.disabled}
            component={Link}
            value={tab.value}
            to={tab.href}
            key={index}
            label={tab.label}
            icon={
              <Typography component="span" color={tab.secondaryLabel !== '(0)' ? "primary" : theme.palette.grey[400]}>
                {tab.secondaryLabel}
              </Typography>
            }
            iconPosition="end"
            {...a11yProps(index, tab.label)}
          />
        ))}
      </Tabs>
    </>
  );
};
export default O1EB1NavTabs;
