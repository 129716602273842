import {
  Dialog,
  DialogTitle,
  DialogContent,
  Stack,
  FormControl,
  FormLabel,
  TextField,
  Box,
  Typography,
  RadioGroup,
  FormControlLabel,
  Radio,
  Chip,
  DialogActions,
  Button,
  Alert,
  ListItem,
} from "@mui/material";
import React from "react";
import theme, { DCAutocomplete } from "../../assets/theme/theme";
import RadioCheckedIcon from "../Shared/Icons/RadioCheckedIcon";
import RadioUncheckedIcon from "../Shared/Icons/RadioUncheckedIcon";
import { filter, isEmpty, map } from "lodash";
import { useDocumentShareModalContext } from "../../contexts/DocumentShareModalContext";
import { validateEmail } from "../../utils/userUtils";
import { ISharePermissions } from "../../firebaseTypes";
import { getDocName } from "../../utils/documentUtils";

export interface Props { }

export interface IAutoCompleteOption {
  title: string;
  value: string;
}

// const top100Films = [
//   { title: 'The Shawshank Redemption', value: 'The Shawshank Redemption' },
//   { title: 'The Godfather', value: 'The Godfather' },
//   { title: 'The Godfather: Part II', value: 'The Godfather: Part II' },
//   { title: 'The Dark Knight', value: 'The Dark Knight' },
//   { title: '12 Angry Men', value: '12 Angry Men' },
// ]

const ShareDocumentsModal: React.FC<Props> = (props) => {
  const {
    closeShareModal,
    openShareModalState,
    documentsToShare,
    shareDocuments,
  } = useDocumentShareModalContext();

  // Please clear the state after close
  const [selectedOptions, setSelectedOptions] = React.useState(
    [] as IAutoCompleteOption[]
  );
  const [permission, setPermission] = React.useState(
    "download" as ISharePermissions
  );
  const [isSharing, setIsSharing] = React.useState(false);
  const [error, setError] = React.useState("");

  function _handlePermissionChange(evt: any) {
    setPermission(evt.target.value);
  }
  const handlePermissionChange = React.useCallback(_handlePermissionChange, []);

  const onShareClick = React.useCallback(async () => {
    // validate emails
    const emails = map(selectedOptions, (opt) => opt.value);
    const wrongEmails = filter(emails, (e) => !validateEmail(e));

    if (isEmpty(wrongEmails) && !isEmpty(emails)) {
      setIsSharing(true);
      setError("");
      await shareDocuments(emails, permission);
      setIsSharing(false);
      closeShareModal(true);
      // clear state
      setSelectedOptions([]);
      setError("");
      setPermission("download");
    } else {
      const err = `Invalid emails ${wrongEmails.join(", ")}`;
      setError(err);
    }
  }, [selectedOptions, shareDocuments, closeShareModal, permission]);

  const onClose = React.useCallback(() => {
    // clear state
    setSelectedOptions([]);
    setError("");
    setPermission("download");

    // call context
    closeShareModal();
  }, [closeShareModal]);

  if (isEmpty(documentsToShare)) return null;
  return (
    <Dialog
      open={openShareModalState}
      onClose={onClose}
      disableEscapeKeyDown
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle>File Sharing</DialogTitle>
      <DialogContent>
        <Stack spacing={2}>
          {error && <Alert severity="error">{error}</Alert>}
          <FormControl>
            {/* <FormLabel sx={{ marginBottom: 0.5 }}>Add Recipient's Email IDs</FormLabel> */}
            <FormLabel
              sx={{
                mb: 1,
              }}
            >
              <Typography color={theme.palette.text.secondary}>
                Add Recipient's Email IDs
              </Typography>
            </FormLabel>
            <DCAutocomplete
              fullWidth
              ChipProps={{
                variant: "outlined",
                // color: "error"
              }}
              value={selectedOptions}
              multiple
              onChange={(_event, values: any) => {
                // 3 Types of values exist
                const sanatizedValues: IAutoCompleteOption[] = map(
                  values,
                  (val) => {
                    // Value selected with enter, right from the input
                    if (typeof val === "string" && !isEmpty(val)) {
                      return {
                        title: val,
                        value: val,
                      };
                    }
                    // Add "xxx" option created dynamically
                    // Regular option
                    return val;
                  }
                );

                setSelectedOptions(sanatizedValues);
              }}
              onBlur={(event) => {
                const val = (event.target as any).value;
                if (!isEmpty(val)) {
                  setSelectedOptions((prev) => [
                    ...prev,
                    { title: val, value: val },
                  ]);
                }
              }}
              filterOptions={(options, params) => {
                const { inputValue } = params;
                if (isEmpty(inputValue)) return options;
                return [
                  {
                    value: inputValue,
                    title: inputValue,
                    addNew: true,
                  },
                ];
              }}
              // fullWidth
              selectOnFocus
              handleHomeEndKeys
              clearOnBlur
              id="document-sharing-email-id-autocomplete"
              options={[]}
              getOptionLabel={(option: any) => {
                // Value selected with enter, right from the input
                if (typeof option === "string") {
                  return option;
                }
                // Add "xxx" option created dynamically
                if (option.title) {
                  return option.title;
                }
                // Regular option
                return option.title;
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  spellCheck={false}
                  placeholder="Enter Email IDs"
                />
              )}
            />
          </FormControl>
          <Box className="share-permissions-wrapper">
            <FormControl
              className="share-permission-btn"
              sx={{
                display: "flex",
                alignItems: "center",
                flexDirection: "row",
              }}
            >
              <Typography color={theme.palette.text.secondary}>
                Share permissions
              </Typography>

              <RadioGroup
                className="share-permission-btn-wrapper"
                row
                aria-label="permissions"
                value={permission}
                sx={{ marginLeft: 2.5 }}
                onChange={handlePermissionChange}
              >
                <FormControlLabel
                  value="viewOnly"
                  control={
                    <Radio
                      icon={<RadioUncheckedIcon sx={{ fontSize: 24 }} />}
                      checkedIcon={<RadioCheckedIcon sx={{ fontSize: 24 }} />}
                    />
                  }
                  label="View only"
                />
                <FormControlLabel
                  value="download"
                  control={
                    <Radio
                      icon={<RadioUncheckedIcon sx={{ fontSize: 24 }} />}
                      checkedIcon={<RadioCheckedIcon sx={{ fontSize: 24 }} />}
                    />
                  }
                  label="Download"
                />
              </RadioGroup>
            </FormControl>
          </Box>
          <Box
            sx={{
              display: "flex",
              // justifyContent: 'center',
              flexWrap: "wrap",
              listStyle: "none",
              gap: "10px",
              p: 0,
              m: 0,
            }}
            component="ul"
          >
            {map(documentsToShare, (doc, i) => {
              const truncate = (text: string, maxLength: number) => {
                if (text.length > maxLength) {
                  return `${text.substring(0, maxLength)}...`;
                }
                return text;
              };

              return (
                <ListItem sx={{ p: 0, width: "unset" }} key={i}>
                  <Chip key={i} label={truncate(getDocName(doc), 20)} />
                </ListItem>
              );
            })}
            {/* <Chip label='passport_082018_adogra.pdf' /> */}
            {/* <Chip label='passport_082018_adogra.pdf' /> */}
          </Box>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={onClose}>
          Cancel
        </Button>
        <Button
          variant="contained"
          aria-label="share document"
          disabled={isSharing || isEmpty(selectedOptions)}
          onClick={onShareClick}
        >
          Share
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ShareDocumentsModal;
