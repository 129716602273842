import React from "react";
import Carousel from 'react-material-ui-carousel'
import { Paper, Button, Typography, IconButton, ImageListItem, ImageListItemBar, ImageList, Box } from '@mui/material'
import Backdrop from '@mui/material/Backdrop';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { DcIconButton } from "../assets/theme/theme";

interface Props {
  open: boolean,
  handleClose: () => any
}

const WalkThroughModal: React.FC<Props> = ({ open, handleClose }) => {
  return <Backdrop
    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
    open={open}
  >
    <Box sx={{
      display: 'flex',
      flexDirection: "column"
    }}>

      <DcIconButton
        onClick={handleClose}
        sx={{
          color: 'white', position: "absolute",
          right: 0,
          top: 0,
        }}
        aria-label="Close Backdrop"
      >
        <CloseRoundedIcon />
      </DcIconButton>

      <Typography fontSize={25} fontWeight={400} variant="h1" color="white" py={4}>
        Let's take a quick walk through!
      </Typography>

      <Box sx={{
        display: "flex",
        alignItems: "center",
      }}>
        <Carousel sx={{
          height: '100%',
          width: '600px',
        }}>
          {
            items.map((item, i) => <Item key={i} item={item} />)
          }
        </Carousel>
      </Box>
    </Box>
  </Backdrop>
}

export default WalkThroughModal

var items = [
  {
    img: 'https://images.unsplash.com/photo-1551963831-b3b1ca40c98e',
    title: 'Breakfast',
    author: '@bkristastucchio',
  },
  {

    img: 'https://images.unsplash.com/photo-1551782450-a2132b4ba21d',
    title: 'Burger',
    author: '@rollelflex_graphy726',
  },
  {
    img: 'https://images.unsplash.com/photo-1516802273409-68526ee1bdd6',
    title: 'Basketball',
    author: '@tjdragotta',
  },
  {
    img: 'https://images.unsplash.com/photo-1518756131217-31eb79b20e8f',
    title: 'Fern',
    author: '@katie_wasserman',
  },
  {
    img: 'https://images.unsplash.com/photo-1597645587822-e99fa5d45d25',
    title: 'Mushrooms',
    author: '@silverdalex',
  }
]

function Item(props: any) {
  return (
    <ImageListItem>
      <img src={`${props.item.img}?w=600&fit=crop&auto=format`}
        srcSet={`${props.item.img}?w=600&fit=crop&auto=format&dpr=2 2x`}
        alt={props.item.title} />
      <ImageListItemBar
        title={props.item.title}
        subtitle={props.item.author}
      />
      {props.item.title}
    </ImageListItem>
  )
}