import * as React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
function CheckboxUncheckedIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <g
        id='Group_13867'
        data-name='Group 13867'
        transform='translate(-326 -600)'
      >
        <rect
          id='Rectangle_17707'
          data-name='Rectangle 17707'
          width='24'
          height='24'
          transform='translate(326 600)'
          fill='none'
        />
        <g
          id='Rectangle_17409'
          data-name='Rectangle 17409'
          transform='translate(328 602)'
          fill='#fff'
          stroke='currentColor'
          strokeWidth='1'
        >
          <rect width='20' height='20' rx='4' stroke='none' />
          <rect x='0.5' y='0.5' width='19' height='19' rx='3.5' fill='none' />
        </g>
      </g>
    </SvgIcon>
  );
}
export default CheckboxUncheckedIcon;
