import { DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material'
import React from 'react'
import { DCButton, DCDialog } from '../../assets/theme/theme'
import WarningAmberRoundedIcon from '@mui/icons-material/WarningAmberRounded';

interface Props {
  open: boolean,
  setOpen: (open: boolean) => void
}

const DocumentMaybeWrongWarning: React.FC<Props> = ({ open, setOpen }) => {
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <DCDialog open={open} onClose={handleClose} maxWidth="xs" fullWidth>
        <DialogTitle sx={{textAlign: "center", fontWeight: 600,display: "flex", justifyContent: "center", alignItems: "center", gap: 1}}><WarningAmberRoundedIcon /> Warning!</DialogTitle>
        <DialogContent>
          <Typography variant="body1" textAlign={"center"}>
            Please verify the extracted values and correct any errors using the edit feature, as AI-generated results may not be perfect.
          </Typography>
        </DialogContent>
        <DialogActions>
          <DCButton
            variant="contained"
            fullWidth
            size="medium"
            onClick={() => {
              handleClose();
            }}
          >
            Okay
          </DCButton>
        </DialogActions>
      </DCDialog>
    </div>
  )
}

export default DocumentMaybeWrongWarning