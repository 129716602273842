import { Typography, ListItemButton, ListItemText } from "@mui/material";
import React from "react";
import theme from "../../assets/theme/theme";
import { INotification } from "../../contexts/NotificationsContext";
import EditNotificationsIcon from "@mui/icons-material/EditNotifications";
import Moment from "react-moment";
import { INotificationsEnum } from "../../firebaseTypes";interface Props {
  note: INotification;
  onNotificationClick: (note: INotification) => any;
}
const NotificationManualEdit: React.FC<Props> = ({ note, onNotificationClick }) => {
  // const { archiveNotification } = useNotifications();
  return (
    <ListItemButton
      sx={{
        p: 2,
        mb : -0.1,
        bgcolor: note.seen === true ? "white" : theme.palette.primary.light,
        "&:hover": {
          bgcolor: note.seen === true ? theme.palette.grey[100] : "#d9e5ff" ,
        }
      }}
    >
      <EditNotificationsIcon
        sx={{
          color: theme.palette.text.secondary,
          marginRight: "10px",
          fontSize: "1.5rem"
        }}
      />
      {note.type === INotificationsEnum["edit-action-required"] && (
        <ListItemText
          onClick={() => onNotificationClick(note)}
          primary={
            <Typography
              component="span"
              variant={"body1"}
              flexBasis={166}
              minWidth={166}
              color={theme.palette.text.primary}
            >
              Your <span style={{ fontWeight: "bold" }}>{note.doc_type}</span> requires manual editing of few fields
            </Typography>
          }
          secondary={<Moment fromNow>{note.timeStamp}</Moment>}
        >  </ListItemText>
      )}
      {/* <IconButton>
        <CloseIcon
          onClick={() => archiveNotification(note)}
          sx={{
            color: theme.palette.text.secondary,
          }} />
      </IconButton> */}
    </ListItemButton>
  );
};

export default NotificationManualEdit;