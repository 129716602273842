import {
  Typography,
  Grid,
  CardContent,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Stack,
} from "@mui/material";
import React from "react";
import "./FAQPage.css";
import { Link } from "react-scroll";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AppBarV2 from "../AppBarV2";
import theme, { DCBaseCard } from "../../assets/theme/theme";
import ScanDocumentIcon from "../Shared/Icons/ScanDocumentIcon";
import { Box } from "@mui/system";
import SecurityIcon from "../Shared/Icons/SecurityIcon";
import MailIcon from "../Shared/Icons/MailIcon";
import GlobeIcon from "../Shared/Icons/GlobeIcon";

export interface Props {}

const FAQPage: React.FC<Props> = (props) => {
  return (
    <div className="faq_section"> 
      {/* <div className='faq_head__Style'>
        <div className='faq_logo__Style'>
          <Logo height={36} />
        </div>
      </div> */}

      <AppBarV2 />

      <div className="faq_main">
        <div className="faq_main__Heading">
          <Typography variant="h3" component="h2">
            What can we help you with?
          </Typography>
        </div>
        <Grid container spacing={3} alignItems="center">
          <Grid item xs={12} sm={12} md={3}>
            <Link
              smooth={true}
              duration={1000}
              to="first"
              className="faq_link__Style"
              offset={-88}
            >
              <DCBaseCard className="faq__card">
                <CardContent sx={{ p: `24px !important`, position: 'relative', zIndex: 1 }}>
                  <Box
                    sx={{
                      background: theme.palette.common.black,
                      width: 48,
                      height: 48,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      borderRadius: 20,
                      mb: 2,
                    }}
                  >
                    <ScanDocumentIcon
                      fontSize="medium"
                      sx={{ color: theme.palette.common.white }}
                    />
                  </Box>
                  <Stack
                    flexDirection="row"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Typography fontSize={20} fontWeight={500}>
                      Scanning Documents
                    </Typography>
                    <ArrowForwardIcon className="faq_icon__Style" />
                  </Stack>
                </CardContent>
              </DCBaseCard>
            </Link>
          </Grid>

          <Grid item xs={12} sm={12} md={3}>
            <Link
              smooth={true}
              duration={1000}
              to="second"
              className="faq_link__Style"
              offset={-88}
            >
              <DCBaseCard className="faq__card">
                <CardContent sx={{ p: `24px !important`, position: 'relative', zIndex: 1 }}>
                  <Box
                    sx={{
                      background: theme.palette.common.black,
                      width: 48,
                      height: 48,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      borderRadius: 20,
                      mb: 2,
                    }}
                  >
                    <SecurityIcon
                      fontSize="medium"
                      sx={{ color: theme.palette.common.white }}
                    />
                  </Box>
                  <Stack
                    flexDirection="row"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Typography fontSize={20} fontWeight={500}>
                      Security
                    </Typography>
                    <ArrowForwardIcon className="faq_icon__Style" />
                  </Stack>
                </CardContent>
              </DCBaseCard>
            </Link>
          </Grid>

          <Grid item xs={12} sm={12} md={3}>
            {" "}
            <Link
              smooth={true}
              duration={1000}
              to="third"
              className="faq_link__Style"
              offset={-88}
            >
              <DCBaseCard className="faq__card">
                <CardContent sx={{ p: `24px !important`, position: 'relative', zIndex: 1 }}>
                  <Box
                    sx={{
                      background: theme.palette.common.black,
                      width: 48,
                      height: 48,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      borderRadius: 20,
                      mb: 2,
                    }}
                  >
                    <GlobeIcon
                      fontSize="medium"
                      sx={{ color: theme.palette.common.white }}
                    />
                  </Box>
                  <Stack
                    flexDirection="row"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Typography fontSize={20} fontWeight={500}>
                      Web App
                    </Typography>
                    <ArrowForwardIcon className="faq_icon__Style" />
                  </Stack>
                </CardContent>
              </DCBaseCard>
            </Link>
          </Grid>

          <Grid item xs={12} sm={12} md={3}>
            {" "}
            <Link
              smooth={true}
              duration={1000}
              to="fourth"
              className="faq_link__Style"
              offset={-88}
            >
              <DCBaseCard className="faq__card">
                <CardContent sx={{ p: `24px !important`, position: 'relative', zIndex: 1 }}>
                  <Box
                    sx={{
                      background: theme.palette.common.black,
                      width: 48,
                      height: 48,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      borderRadius: 20,
                      mb: 2,
                    }}
                  >
                    <MailIcon
                      fontSize="medium"
                      sx={{ color: theme.palette.common.white }}
                    />
                  </Box>
                  <Stack
                    flexDirection="row"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Typography fontSize={20} fontWeight={500}>
                      Help/Contact us
                    </Typography>
                    <ArrowForwardIcon className="faq_icon__Style" />
                  </Stack>
                </CardContent>
              </DCBaseCard>
            </Link>
          </Grid>
        </Grid>

        <section className="faq_acc__Style" id="first">
          <div className="faq_typo__Style">
            <Typography variant="h3" component="h2">
              Scanning Documents
            </Typography>
          </div>
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography>How do I scan my documents?</Typography>
            </AccordionSummary>

            <AccordionDetails>
              <Typography>
                It’s super simple, just follow the instructions in the Scanning
                Tips.
              </Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography>
                How do I ensure good quality of my scanned documents?
              </Typography>
            </AccordionSummary>

            <AccordionDetails>
              <Typography>
                Follow the instructions in the Scanning Tips. Ensure good
                lighting, lay the document flat, and make sure the image is in
                focus (not blurry).
              </Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography>Why do I need to save documents as pdfs?</Typography>
            </AccordionSummary>

            <AccordionDetails>
              <Typography>
                PDFs are a lot smaller in size compared to images, so it helps
                us keep the storage costs down. Also, it helps ensure that the
                storage space isn’t misused.
              </Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography>
                What will happen if the quality of documents is not good enough?
              </Typography>
            </AccordionSummary>

            <AccordionDetails>
              <Typography>
                If the quality of the document image isn’t good, our algorithms
                won’t work properly. More simply, we may not be able to auto
                sort and parse them and you’ll have to manually fill in the
                info. Also, in case you share these low quality documents with a
                law firm, employer or govt. Agency etc. there's a high
                likelihood that they will be rejected for poor quality.
              </Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography>
                Why can’t I directly scan my documents on Immplify?
              </Typography>
            </AccordionSummary>

            <AccordionDetails>
              <Typography>
                Unfortunately, the Web App doesn’t allow scanning the documents.
                We are working on a mobile app which would allow you to scan the
                documents directly from the app in the future.
              </Typography>
            </AccordionDetails>
          </Accordion>
        </section>

        <section className="faq_acc__Style" id="second">
          <div className="faq_typo__Style">
            <Typography variant="h3" component="h2">
              Security
            </Typography>
          </div>
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography>
                How do I know my documents are safe and secure?
              </Typography>
            </AccordionSummary>

            <AccordionDetails>
              <Typography>
                Safety of your documents is our priority. We ensure that only
                you and no one else can access your account. All data transfer
                happens on a highly secure connection and the information is
                broken down into chunks and stored securely on multiple servers
                with encryption.
              </Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography>
                Do you store my personally identifiable information?
              </Typography>
            </AccordionSummary>

            <AccordionDetails>
              <Typography>
                Yes, we store personally identifiable information to provide you
                the services of the Immplify app. However, this information is
                never shared with anyone. Infact, even we at Immplify can’t view
                this information.
              </Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography>Is my data shared with anyone?</Typography>
            </AccordionSummary>

            <AccordionDetails>
              <Typography>
                No, we will never sell or share your data with anyone. However,
                please read the privacy policy for more details and some special
                cases.
              </Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography>
                Is all my data deleted once I deactivate my account?
              </Typography>
            </AccordionSummary>

            <AccordionDetails>
              <Typography>
                If you choose to cancel/deactivate your account, all your data
                will be removed. If you decide to restore services, a new
                account will have to be created.
              </Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography>How secure is document sharing feature?</Typography>
            </AccordionSummary>

            <AccordionDetails>
              <Typography>
                It’s the safest way to share documents with someone as the copy
                of the document isn’t sent via email but rather a link to the
                secure storage is shared. You can also set permissions for
                sharing i.e. view only or view and download.
              </Typography>
            </AccordionDetails>
          </Accordion>
        </section>

        <section className="faq_acc__Style" id="third">
          <div className="faq_typo__Style">
            <Typography variant="h3" component="h2">
              WebApp
            </Typography>
          </div>
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography>How do I upload my documents?</Typography>
            </AccordionSummary>

            <AccordionDetails>
              <Typography>
                After initial sign up and completing user profile setup, you
                would be automatically directed to the view/upload screen. To
                upload the documents, simply drag the documents onto the main
                screen or click the browse button to select the documents which
                you want to upload.
              </Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography>Which documents can I upload?</Typography>
            </AccordionSummary>

            <AccordionDetails>
              <Typography>
                You can upload any document in pdf format less than 100 mb in
                size. However, we support a specific set of documents for the
                automatic organisation and tracking features. The list of
                documents:
                <ul>
                  <li>Passport </li>
                  <li>US Visa</li>
                  <li>I-20</li>
                  <li>I-797</li>
                  <li>EAD</li>
                  <li>Drivers Licence</li>
                  <li>EU Visa</li>
                  <li>UK Visa</li>
                  <li>Resume</li>
                  <li>Degree Certificate</li>
                </ul>
              </Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography>
                What if I am unable to upload the documents?
              </Typography>
            </AccordionSummary>

            <AccordionDetails>
              <Typography>
                Make sure the document is in pdf format and is less than 100 mb.
                Sometimes, the upload can fail due to technical issues. Please
                retry a few times and if the problem persists, contact us at
                support@docucomb.com.
              </Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography>What if a document type is not detected?</Typography>
            </AccordionSummary>

            <AccordionDetails>
              <Typography>
                We automatically detect a specific set of documents which
                includes Passport, US Visa, I-20, I-797, EAD, Drivers Licence,
                EU Visa, UK Visa, Resume, Degree Certificate. However if your
                document type is in the mentioned list of However, if you a
                upload a document other than the ones listed above
              </Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography>
                What if the information extracted from the document is
                incorrect/empty?{" "}
              </Typography>
            </AccordionSummary>

            <AccordionDetails>
              <Typography>
                Do not worry if the information extracted from your documents is
                incomplete or incorrect. Simply go to the dashboard, then go to
                the particular document card and click edit option. Now, you can
                add incomplete information or edit any incorrect information.
                Once finished, hit the save button. Edited values will be shown
                in a different color.
              </Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography>Why are my documents renamed?</Typography>
            </AccordionSummary>

            <AccordionDetails>
              <Typography>
                This is one of the coolest features of Immplify. Documents are
                named in a meaningful way as they are often shared with lawyers,
                banks, employers etc. A good name helps identify document type
                and its important attributes without even opening the document.
              </Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography>How do I change the document names?</Typography>
            </AccordionSummary>

            <AccordionDetails>
              <Typography>
                In the document gallery view, simply go to the document and
                click on the name to edit the document name.
              </Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography>How do I delete my documents?</Typography>
            </AccordionSummary>

            <AccordionDetails>
              <Typography>
                You can delete a document by going to the document gallery,
                selecting the document, and clicking the delete button. Another
                way is to go to the document, click on more options, and delete
                the document. You can also select multiple documents at once and
                delete them.
              </Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography>How do I share my documents?</Typography>
            </AccordionSummary>

            <AccordionDetails>
              <Typography>
                You can delete a document by going to the ,
                selecting the document, and clicking the share button. Another
                way is to go to the document, click on more options, and share
                the document. You can also select multiple documents at once and
                share them.
                <br />
                When you share a document, a prompt opens up where you can add
                the email of the recipient and the permissions. Set permissions
                to “View only” if you don’t want the recipient to download/use
                the shared documents and to “Download” if you want the recipient
                to download/use the shared documents.
                <br />
                Upon sharing the document/s, the recipient will receive an email
                with a secure link to the document/s. If you set the permissions
                as “View only” the documents will appear with watermarks to
                avoid misuse by the recipient.
              </Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography>How do I download a copy of my documents?</Typography>
            </AccordionSummary>

            <AccordionDetails>
              <Typography>
                You can download a document by going to the document gallery,
                selecting the document, and clicking the download button.
                Another way is to go to the document, click on more options, and
                download the document. You can also select multiple documents at
                once and download them.
              </Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography>How do I print my documents?</Typography>
            </AccordionSummary>

            <AccordionDetails>
              <Typography>
                You can print a document by going to the document gallery,
                selecting the document, and clicking the print button. Another
                way is to go to the document, click on more options, and print
                the document. You can also select multiple documents at once and
                print them.
              </Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography>
                How can I view my documents on the dashboard?
              </Typography>
            </AccordionSummary>

            <AccordionDetails>
              <Typography>
                Simply go to the dashboard and click on the relevant card, then
                click on preview document button and the bottom of the card to
                view the corresponding document.
              </Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography>Why do I get notifications?</Typography>
            </AccordionSummary>

            <AccordionDetails>
              <Typography>
                Notifications are an important feature to notify you regarding a
                few things: <br />
                1 .Document expiration - the app will automatically track the
                expiration dates of various documents and notify you in a timely
                manner. For example, if your passport is getting expired, we’ll
                send you notifications 8 months, 4 months, 2 months, 1 month
                before expiration date and after it has expired. It will also
                provide a link to the blog post on how to renew the passport
                <br /> 2 .When Auto Detection of document fails, we will notify
                you to manually select the document type
                <br /> 3 .When Al/ML is unable to parse your documents, we will
                notify you to manually fill out the document details.
              </Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography>What is the “others” folder?</Typography>
            </AccordionSummary>

            <AccordionDetails>
              <Typography>
                It’s basically a catch all folder. When we are unable to detect
                a document type, it is put into the “others” folder. You can
                then move the document to either an auto generated or a custom
                folder.
              </Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography>How do I create a custom folder?</Typography>
            </AccordionSummary>

            <AccordionDetails>
              <Typography>
                Simply click on the folder with a “+” icon, under “Custom
                Folders” . You’ll be then asked to name the custom folder that
                gets created.
              </Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography>
                How can I move my documents across folders?
              </Typography>
            </AccordionSummary>

            <AccordionDetails>
              <Typography>
                You can move a document across folders by going to the document
                gallery view, selecting the document, clicking on more options,
                selecting “Move to another folder”, and then selecting the
                destination folder.
              </Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography>How can I change the view of the gallery?</Typography>
            </AccordionSummary>

            <AccordionDetails>
              <Typography>
                The gallery supports two views, the Tab View and the List View.
                You can switch between the two by simply toggling between the
                two using the switch on the top right corner of the document
                gallery view.
              </Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography>
                Why does the card of an uploaded document not appear on the
                dashboard?
              </Typography>
            </AccordionSummary>

            <AccordionDetails>
              <Typography>
                Currently, we only support a limited number of documents for
                automatic detection and parsing. If a document type is not
                supported or is undetected, an automated card for that document
                won’t be created. However, you can create a custom card in the
                dashboard and attach the respective document.
              </Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography>What are custom cards?</Typography>
            </AccordionSummary>

            <AccordionDetails>
              <Typography>
                Custom cards are templates for tracking the important
                information which we don’t automatically parse yet. You can
                create custom cards by using a default template or using
                employment, past address templates. You can also attach related
                documents with the custom cards.
              </Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography>
                What if I upload someone else’s document by mistake?
              </Typography>
            </AccordionSummary>

            <AccordionDetails>
              <Typography>
                No need to worry at all, we automatically detect and notify if
                it’s not your document. If we make a mistake in identifying, you
                can mark it as your document by clicking on the warning symbol
                on the document in the document gallery.
              </Typography>
            </AccordionDetails>
          </Accordion>
        </section>

        <section className="faq_acc__Style" id="fourth">
          <div className="faq_typo__Style">
            <Typography variant="h3" component="h2">
              Help/Contact us
            </Typography>
          </div>

          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography>How do I contact team Immplify?</Typography>
            </AccordionSummary>

            <AccordionDetails>
              <Typography>
                We are always available to help you, simply email us at
                support@docucomb.com
              </Typography>
            </AccordionDetails>
          </Accordion>
        </section>
      </div>
    </div>
  );
};

export default FAQPage;
