import * as React from "react";
import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";
function SecurityIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        width="24"
        height="24"
      >
        <path
          d="M12,22.75a.74.74,0,0,1-.237-.039c-1.589-.529-9.513-3.588-9.513-11.822V5a.749.749,0,0,1,.6-.735,29.063,29.063,0,0,0,8.8-2.921.819.819,0,0,1,.7-.014,28.345,28.345,0,0,0,8.792,2.935.749.749,0,0,1,.6.735v5.889c0,8.234-7.924,11.293-9.513,11.822A.74.74,0,0,1,12,22.75ZM3.75,5.612v5.277c0,6.864,6.418,9.66,8.25,10.317,1.832-.657,8.25-3.453,8.25-10.317V5.612a28.925,28.925,0,0,1-8.235-2.774A29.308,29.308,0,0,1,3.75,5.612Zm7.792,8.918,4-4a.75.75,0,0,0-1.061-1.06l-3.469,3.469L9.542,11.47a.75.75,0,0,0-1.061,1.06l2,2a.75.75,0,0,0,1.061,0Z"
          fill="currentColor"
        />
      </svg>
    </SvgIcon>
  );
}
export default SecurityIcon;
