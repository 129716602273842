import React, { useCallback } from "react";
import { useAuth } from "./AuthContext";
import EditTravelHistoryModalNew from "../components/TravelHistoryNew/EditTravelHistoryModalNew";
import { IResult, ITravelHistoryExtended } from "../firebaseTypes";
import { analytics, rtdb } from "../firebase";
import { ref, update } from "firebase/database";
import { logEvent } from "firebase/analytics";

export interface IEditTravelHistoryModalContextNew {
  // States
  open: boolean; // uses this to open/close modal
  selectedTravelHistory: ITravelHistoryExtended | null; // selected for editing
  isUpdatingTravelHistory: boolean; // uses this to give loading state to the update button
  setSelectedTravelHistory: React.Dispatch<
    React.SetStateAction<ITravelHistoryExtended | null>
  >; // uses this to set selected travel history

  // Functions
  openEditTravelHistoryModalFor: (
    travelHistory: ITravelHistoryExtended
  ) => void; // uses this to open modal for a travel history
  closeEditTravelHistoryModal: () => void; // uses this to close modal
  updateTravelHistory: () => Promise<IResult>; // uses this to update a travel history
  isSaveButtonDisabled: () => boolean; // uses this to enable/disable the update button
}

const defaultValues: IEditTravelHistoryModalContextNew = {
  // States
  open: false,
  selectedTravelHistory: null,
  isUpdatingTravelHistory: false,
  setSelectedTravelHistory: () => {}, // state --> setSelectedTravelHistory

  // Functions
  openEditTravelHistoryModalFor: () => {},
  closeEditTravelHistoryModal: () => {},
  updateTravelHistory: () => Promise.resolve({ success: false, error: "" }),
  isSaveButtonDisabled: () => false,
};

const EditTravelHistoryModalContextNew =
  React.createContext<IEditTravelHistoryModalContextNew>(defaultValues);

export function useEditTravelHistoryModalNew() {
  return React.useContext(EditTravelHistoryModalContextNew);
}

export interface Props {
  travelState: "usa" | "world";
}

export const EditTravelHistoryModalProviderNew: React.FC<Props> = (props) => {
  const [open, setOpen] = React.useState(defaultValues.open); // uses this to open/close modal
  const [selectedTravelHistory, setSelectedTravelHistory] =
    React.useState<ITravelHistoryExtended | null>(null); // selected for editing
  const [isUpdatingTravelHistory, setIsUpdatingTravelHistory] =
    React.useState(false); // uses this to give loading state to the update button
  const { currentUser } = useAuth();

  // Open modal for a travel history
  const openEditTravelHistoryModalFor = useCallback(
    (travelHistory: ITravelHistoryExtended) => {
      logEvent(analytics, "open_modal", {
        type: `edit_${props.travelState}_travel`,
      }); // log event to analytics
      setSelectedTravelHistory(travelHistory);
      setOpen(true);
    },
    [props.travelState]
  );

  // Close modal
  const closeEditTravelHistoryModal = useCallback(() => {
    logEvent(analytics, "close_modal", {
      type: `edit_${props.travelState}_travel`,
    }); // log event to analytics
    setSelectedTravelHistory(null);
    setOpen(false);
  }, [props.travelState]);

  // Update travel history
  const updateTravelHistory = useCallback(async () => {
    // Check if user is logged in and if a travel history is null
    if (
      !currentUser?.uid ||
      !selectedTravelHistory ||
      !selectedTravelHistory.id
    ) {
      return {
        success: false,
        error: "User not logged in or selected travel history is null",
      };
    }
    try {
      const refStr =
        props.travelState === "usa"
          ? `users/${currentUser.uid}/usa-travel-records/${selectedTravelHistory.id}`
          : `users/${currentUser.uid}/travel-records/${selectedTravelHistory.id}`;
      const travelRef = ref(rtdb, refStr);

      // adding the only required fields in database
      // Days are counted at front-end so no need to add it here
      await update(travelRef, {
        arrival_date: selectedTravelHistory.arrival_date || null,
        arrival_loc: selectedTravelHistory.arrival_loc || null,
        departure_date: selectedTravelHistory.departure_date || null,
        departure_loc: selectedTravelHistory.departure_loc || null,
        country: selectedTravelHistory.country || null,
        visa_status: selectedTravelHistory?.visa_status
          ? selectedTravelHistory?.visa_status.toUpperCase()
          : "", // convert to uppercase
        automated: false, // this is to make sure that the travel history is manually added
        isPresent: selectedTravelHistory.isPresent || null,
      });
      // update travel history in rtdb
      setIsUpdatingTravelHistory(false); // set loading state to false
      setOpen(false); // close modal
      return { success: true, error: "" };
    } catch (error: any) {
      console.error(error);
      // update travel history in rtdb
      setIsUpdatingTravelHistory(false); // set loading state to false
      setOpen(false); // close modal
      return { success: false, error: error.message };
    }
  }, [currentUser?.uid, props.travelState, selectedTravelHistory]);

  // All the modal validations start here

  // save (update) travel history button handler ( enables/disables the button)
  const isSaveButtonDisabled = useCallback(() => {
    // Check if user is logged in and if a travel history is null
    if (!selectedTravelHistory) {
      return true;
    }
    // Check if all the required fields are filled
    // doesn't check for visa status because it is not required
    if (selectedTravelHistory.isPresent) {
      if (
        !selectedTravelHistory.arrival_date ||
        !selectedTravelHistory.arrival_loc
      ) {
        // doesn't have all the required fields
        return true;
      } else {
        return false;
      }
    }
    if (
      !selectedTravelHistory.arrival_date ||
      !selectedTravelHistory.arrival_loc ||
      !selectedTravelHistory.departure_date ||
      !selectedTravelHistory.departure_loc
    ) {
      // doesn't have all the required fields
      return true;
    } else {
      return false;
    }
  }, [selectedTravelHistory]);

  return (
    <EditTravelHistoryModalContextNew.Provider
      value={{
        open,
        updateTravelHistory,
        openEditTravelHistoryModalFor,
        closeEditTravelHistoryModal,
        selectedTravelHistory,
        setSelectedTravelHistory,
        isSaveButtonDisabled,
        isUpdatingTravelHistory,
      }}
    >
      <EditTravelHistoryModalNew travelState={props.travelState} />
      {props.children}
    </EditTravelHistoryModalContextNew.Provider>
  );
};
