import { createContext, useCallback, useContext, useRef, useState } from "react"
import PDFMerger from "pdf-merger-js/browser";
import jsPDF from "jspdf";
import "jspdf/dist/polyfills.es.js";
import { useAuth } from "./AuthContext";

(window as any).global = window;
// @ts-ignore
window.Buffer = window.Buffer || require("buffer").Buffer;

(window as any).process = {
  env: { DEBUG: undefined },
  nextTick: function () {
    return null;
  },
};


export interface IPDFMergerContext {
  print: (downloadURLs: string[], downloadImgURLs: string[], isPublic?: boolean) => Promise<any>;
  isPrintProcessing: boolean;
  setIsPrintProcessing: React.Dispatch<React.SetStateAction<boolean>>;
}

const defaultValues: IPDFMergerContext = {
  print: async () => { },
  isPrintProcessing: false,
  setIsPrintProcessing: () => { }
}

const Context = createContext(defaultValues)

export const usePDFMergeContext = () => {
  return useContext(Context)
}

export const PDFMergerProvider: React.FC = (props) => {
  const [isPrintProcessing, setIsPrintProcessing] = useState(false)
  const [mergedPdfUrl, setMergedPdfUrl] = useState<string>("");
  const ref = useRef<HTMLIFrameElement>(null);

  const { currentUser } = useAuth();

  const print = useCallback(async (downloadURLs, downloadImgURLs, isPublic = false) => {

    if (downloadURLs.length === 0 && downloadImgURLs.length === 0) {
      setIsPrintProcessing(false);
      return
    }

    async function init() {
      setMergedPdfUrl("")
      setIsPrintProcessing(true);

      const merger = new PDFMerger();
      const token = await currentUser?.getIdToken();

      const headers = new Headers();
      !isPublic && headers.set('Authorization', `Bearer ${token ? token : ''}`);

      let allPromises: any = []

      async function fetchPDF(file: string) {
        const response = await fetch(file, { headers });
        const blob = await response.blob();
        await merger.add(blob);
      }

      async function fetchImg(file: string) {
        const response = await fetch(file, { headers });

        const blob = await response.blob();

        const img = await new Promise<HTMLImageElement>((resolve) => {
          let img = new Image();
          var urlCreator = window.URL || window.webkitURL;
          img.src = urlCreator.createObjectURL(blob);
          img.onload = () => resolve(img);
        });

        const pdf = new jsPDF({
          orientation: img.width > img.height ? "l" : "p",
          unit: "pt",
          format: [img.width, img.height],
        });

        const item = pdf.addImage({
          imageData: img,
          format: "JPEG",
          x: 0,
          y: 0,
          width: img.width,
          height: img.height,
        });

        await merger.add(item.output("blob"));
      }

      if (downloadURLs.length > 0) {
        for (const file of downloadURLs) {
          allPromises.push(fetchPDF(file))
        }
      }

      if (downloadImgURLs.length > 0) {
        for (const file of downloadImgURLs) {
          allPromises.push(fetchImg(file))
        }
      }

      await Promise.all(allPromises)
      const mergedPdf = await merger.saveAsBlob();
      const url = URL.createObjectURL(mergedPdf);

      setIsPrintProcessing(false);
      setMergedPdfUrl(url);
    }

    init().catch((err) => {
      setIsPrintProcessing(false);
      setMergedPdfUrl("")
      console.error(err)
    }).then(() => {
      // Giving sometime to browse to finish rendering pdf in iframe
      setTimeout(() => {
        if (ref && ref.current && ref.current.contentWindow) {
          ref.current.contentWindow.focus();
          ref.current.contentWindow.print();
        }
      }, 1000)
    });

  }, [currentUser])

  return <Context.Provider value={{
    print,
    isPrintProcessing,
    setIsPrintProcessing
  }}>
    <iframe
      className="iframe-blog"
      ref={ref}
      height={1000}
      src={`${mergedPdfUrl}`}
      title='pdf-viewer'
      style={{
        display: 'none'
      }}
      width='100%'
    >
    </iframe>
    {props.children}
  </Context.Provider>
}
