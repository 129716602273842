import { Box, Button } from "@mui/material";
import { FC } from "react";
import { DCConfirmDialog } from "../Shared/DCConfirmDialog";
import useTravelHistory from "./hooks/TravelHistory.hook";
import TravelEmptyState from "./TravelEmptyState";
import { TraveModal } from "./TraveModal";
import { TravelsTable } from "./TravelsTable";
import AddIcon from "@mui/icons-material/Add";
import { Fetching } from "../Shared/Fetching";
import FlightIcon from "@mui/icons-material/Flight";

interface Props {}
export const CustomTravelsTab: FC<Props> = () => {
  const {
    loadingStatusWorldTravels,
    airportList,
    worldTravels,
    travelRecords,
    isModalOpen,
    isDialogOpen,
    selectedTravel,
    isAllDialogOpen,
    selectedRows,
    onCloseAllDialog,
    onDeleteAllRowClick,
    onOpenModal,
    onCloseModal,
    onCloseDialog,
    onDeleteRowClick,
    onSaveWorldTravel,
    onSelectTravelForEdit,
    onDeleteWorldTravel,
    onDeleteWorldTravels,
  } = useTravelHistory();

  return (
    <Box>
      <Box
        display="flex"
        justifyContent="flex-end"
        gap={2}
        position="absolute"
        top={0}
        right={0}
      >
        <Button
          variant="contained"
          color="primary"
          startIcon={<AddIcon />}
          onClick={onOpenModal}
        >
          Add Travel Log
        </Button>
      </Box>
      <TraveModal
        travel={selectedTravel}
        open={isModalOpen}
        airportList={airportList}
        isWorldTravel={true}
        onClose={onCloseModal}
        onSave={onSaveWorldTravel}
      />

      <DCConfirmDialog
        open={isDialogOpen}
        onClose={onCloseDialog}
        onConfirm={() => selectedRows.forEach((s) => onDeleteWorldTravel(s))}
        confirmButtonText="DELETE"
      >
        Do you want to delete
        {selectedRows.length === 1
          ? "the record"
          : selectedRows.length + " records"}
        ?
      </DCConfirmDialog>

      <DCConfirmDialog
        open={isAllDialogOpen}
        onClose={onCloseAllDialog}
        onConfirm={() => onDeleteWorldTravels()}
        confirmButtonText="DELETE"
      >
        Do you want to delete <strong>ALL</strong> records?
      </DCConfirmDialog>

      {loadingStatusWorldTravels === "empty-results" && (
        <TravelEmptyState tab="custom" />
      )}

      {loadingStatusWorldTravels === "loading" && (
        <Fetching
          icon={<FlightIcon fontSize="small" color="primary" />}
          message={"Fetching World Travel History"}
        />
      )}

      {loadingStatusWorldTravels === "results" && (
        <TravelsTable
          travelRecords={worldTravels}
          onEditClick={onSelectTravelForEdit}
          onDeleteClick={onDeleteRowClick}
          onDeleteAllClick={onDeleteAllRowClick}
        />
      )}
    </Box>
  );
};
