import { StripeError } from "@stripe/stripe-js";
import { addYears, isAfter } from "date-fns";
import { get, onValue, push, ref, set, update } from "firebase/database";
import {
  createContext,
  FC,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { rtdb } from "../firebase";
import { IPaymentHistory } from "../firebaseTypes";
import { useAuth } from "./AuthContext";

interface IPaymentHistoryData extends IPaymentHistory {
  expired?: boolean;
}

interface IPaymentContext {
  loading: boolean;
  payments: IPaymentHistory[];
  validPayments: IPaymentHistory[];
  activePayment: IPaymentHistoryData | null;
  onStartPayment(
    priceId: string,
    price: string
  ): Promise<IPaymentHistory | null>;
  onCompletePayment(type: "Free" | "Pro", paymentId?: string): Promise<void>;
  onErrorPayment(paymentId: string, error: StripeError): Promise<void>;
  onCancelPaymentProcess(paymentId: string): Promise<void>;
  onCancelPayment(paymentId: string): Promise<void>;
}

const initialValue: IPaymentContext = {
  loading: false,
  payments: [],
  validPayments: [],
  activePayment: null,
  onStartPayment: () => Promise.resolve(null),
  onCompletePayment: () => Promise.resolve(),
  onErrorPayment: () => Promise.resolve(),
  onCancelPaymentProcess: () => Promise.resolve(),
  onCancelPayment: () => Promise.resolve(),
};

const Context = createContext<IPaymentContext>(initialValue);

export const usePaymentContext = () => useContext(Context);

export const PaymentContextProvider: FC = ({ children }) => {
  const [loading, setLoading] = useState(true);
  const { currentUser } = useAuth();
  const userID = useMemo(() => currentUser?.uid, [currentUser?.uid]);
  const [payments, setPayments] = useState<IPaymentHistory[]>([]);
  const validPayments = useMemo(
    () =>
      payments.filter((p) => p.status !== "pending" && p.status !== "cancel"),
    [payments]
  );
  const activePayment = useMemo<IPaymentHistoryData | null>(() => {
    let _active = payments.find(
      (p) => p.status === "success" && isAfter(p.expireDate, new Date())
    );

    if (!_active) {
      _active = payments.find((p) => p.status === "success");
    }

    if (!_active) {
      _active = payments.find(
        (p) => p.status === "user-canceled" && isAfter(p.expireDate, new Date())
      );
    }

    if (_active && _active.status === "success") {
      return {
        ..._active,
        expired: !isAfter(_active.expireDate, new Date()),
      };
    }

    return _active || null;
  }, [payments]);

  const getPayments = useCallback(() => {
    setLoading(true);
    const tRef = ref(rtdb, `users/${userID}/payments`);
    onValue(tRef, (snapshot) => {
      if (snapshot.exists()) {
        setPayments([]);
        snapshot.forEach((data) => {
          setPayments((prev: IPaymentHistory[]) => {
            return [
              {
                id: data.key,
                ...data.val(),
              },
              ...prev,
            ];
          });
        });
      } else {
        setPayments([]);
      }
      setLoading(false);
    });
  }, [userID]);

  // Payment precess has start
  const onStartPayment = async (
    priceId: string,
    price: string
  ): Promise<IPaymentHistory | null> => {
    if (userID) {
      const tRef = ref(rtdb, `users/${userID}/payments`);
      const newRef = push(tRef);
      await set(newRef, {
        paymentDate: 0,
        expireDate: 0,
        priceId: priceId,
        price: +price,
        status: "pending",
      } as IPaymentHistory);
      return get(newRef).then((s) => ({
        id: s.key,
        ...s.val(),
      }));
    }
    return null;
  };

  // Payment success or user select free
  const onCompletePayment = async (
    type: "Free" | "Pro",
    paymentId?: string
  ) => {
    if (userID) {
      const paymentDate = new Date().getTime();

      if (type === "Pro" && paymentId) {
        const tRef = ref(rtdb, `users/${userID}/payments/${paymentId}`);
        await update(tRef, {
          paymentDate: paymentDate,
          expireDate: addYears(paymentDate, 1).getTime(),
          status: "success",
          subscriptionPlan: "Early Adoption Plan",
          currency: "US Dollars",
        } as IPaymentHistory);
      } else if (type === "Free") {
        const tRef = ref(rtdb, `users/${userID}/payments`);
        const newRef = push(tRef);
        await set(newRef, {
          priceId: "",
          paymentDate: paymentDate,
          expireDate: addYears(paymentDate, 1).getTime(),
          status: "success",
          subscriptionPlan: "Early Adoption Plan",
          currency: "US Dollars",
        } as IPaymentHistory);
      }
    }
  };

  // Payment process returned error
  const onErrorPayment = async (paymentId: string, error: StripeError) => {
    if (userID && paymentId) {
      const tRef = ref(rtdb, `users/${userID}/payments/${paymentId}`);
      await update(tRef, {
        paymentDate: new Date().getTime(),
        status: "error",
        error,
      } as IPaymentHistory);
    }
  };

  // User Canceled payment process
  const onCancelPaymentProcess = async (paymentId: string) => {
    if (userID && paymentId) {
      const tRef = ref(rtdb, `users/${userID}/payments/${paymentId}`);
      await update(tRef, {
        status: "user-canceled",
      } as IPaymentHistory);
    }
  };

  // User Canceled
  const onCancelPayment = async (paymentId: string) => {
    if (userID && paymentId) {
      const tRef = ref(rtdb, `users/${userID}/payments/${paymentId}`);
      await update(tRef, {
        status: "user-canceled",
        userCanceledDate: new Date().getTime(),
      } as IPaymentHistory);
    }
  };

  useEffect(() => {
    getPayments();
  }, [userID, getPayments]);

  return (
    <Context.Provider
      value={{
        loading,
        payments,
        validPayments,
        activePayment,
        onStartPayment,
        onCompletePayment,
        onErrorPayment,
        onCancelPaymentProcess,
        onCancelPayment,
      }}
    >
      {children}
    </Context.Provider>
  );
};
