import React from "react";
import { Box } from "@mui/system";
import { Container, Grid, Typography } from "@mui/material";
import theme, {
  DCDashboardContent,
  DCDashboardPageHeader,
  DCLegalCheatSheetInfo,
  DCLegalSupportTabs,
  DCShadowCard,
  DCSupportSidebarMenu,
} from "../../assets/theme/theme";
import Illustration from "../../assets/images/illustrations/page_header_clouds.svg";

import { Link } from "react-router-dom";
import DocumentIcon from "../Shared/Icons/DocumentIcon";
export interface Props {}
function a11yProps(index: number) {
  return {
    id: `support-tabs-${index}`,
    "aria-controls": `support-tabpanel-${index}`,
  };
}
const LegalSupport: React.FC<Props> = (props) => {
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  return (
    <Box sx={{ height: "100%" }}>
      <DCDashboardPageHeader elevation={0}>
        <Grid container>
          <Grid item>
            <Typography
              variant="h1"
              component="h1"
              fontSize={24}
              fontWeight={"bold"}
            >
              Visa Form Assistance
            </Typography>
          </Grid>
          <Grid item position="relative" flex={1}>
            <img
              src={Illustration}
              className="page-header--ilustration"
              alt=""
            />
          </Grid>
        </Grid>
      </DCDashboardPageHeader>
      <DCDashboardContent>
        <Container disableGutters maxWidth={false}>
          <DCShadowCard>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }} px={4}>
              <DCLegalSupportTabs
                onChange={handleChange}
                value={value}
                aria-label="support tabs"
              >
                <Link
                  to="/"
                  {...a11yProps(0)}
                  className="legal-support-tab legal-support-tab--selected"
                >
                  Cheat Sheets
                </Link>
                <Link to="/" {...a11yProps(1)} className="legal-support-tab">
                  Ask an expert
                </Link>
                <Link to="/" {...a11yProps(2)} className="legal-support-tab">
                  FAQ’s
                </Link>
              </DCLegalSupportTabs>
            </Box>
            <Grid container>
              <Grid item minWidth={220}>
                <DCSupportSidebarMenu>
                  <Link
                    className="text-decoration-none support-sidebar--navlink support-sidebar--navlink_active"
                    to="/"
                  >
                    <DocumentIcon />
                    DS-160
                  </Link>
                  <Link
                    className="text-decoration-none support-sidebar--navlink"
                    to="/"
                  >
                    <DocumentIcon />
                    i983
                  </Link>
                  <Link
                    className="text-decoration-none support-sidebar--navlink"
                    to="/"
                  >
                    <DocumentIcon />
                    i765
                  </Link>
                </DCSupportSidebarMenu>
              </Grid>
              <Grid
                container
                item
                flex={1}
                style={{ height: 400 }}
                px={8}
                py={4}
              >
                <Grid item xs={6}>
                  <DCLegalCheatSheetInfo>
                    <Typography
                      variant="subtitle1"
                      gutterBottom
                      component="h2"
                      className="cheatsheet-title"
                      color="primary"
                    >
                      Personal Information
                    </Typography>
                    {/* <KeyValueItem
                      fontSize={14}
                      marginBottom={0.5}
                      label='Surname -'
                      className='summary-item'
                      textToCopy='Dogra'
                    >
                      Dogra
                    </KeyValueItem>
                    <KeyValueItem
                      fontSize={14}
                      marginBottom={0.5}
                      label='Nationality -'
                      className='summary-item'
                      textToCopy='Indian'
                    >
                      Indian
                    </KeyValueItem> */}
                  </DCLegalCheatSheetInfo>
                </Grid>
                <Grid item xs={6}>
                  <DCLegalCheatSheetInfo>
                    <Typography
                      variant="subtitle1"
                      gutterBottom
                      component="h2"
                      className="cheatsheet-title"
                      color="primary"
                    >
                      Student Visa Information
                    </Typography>
                    {/* <KeyValueItem
                      fontSize={14}
                      marginBottom={0.5}
                      label='SEVIS ID -'
                      className='summary-item'
                      textToCopy='Surnames'
                      isBold
                    >
                      N7825715623
                    </KeyValueItem>
                    <KeyValueItem
                      fontSize={14}
                      marginBottom={0.5}
                      label='Nationality -'
                      className='summary-item'
                      textToCopy='Name of School'
                    >
                      The University of Illinois at Chicago
                    </KeyValueItem> */}
                  </DCLegalCheatSheetInfo>
                </Grid>
                <Grid item xs={6}>
                  <DCLegalCheatSheetInfo>
                    <Typography
                      variant="subtitle1"
                      gutterBottom
                      component="h2"
                      className="cheatsheet-title"
                      color="primary"
                    >
                      U.S. Visa Information
                    </Typography>
                    {/* <KeyValueItem
                      fontSize={14}
                      marginBottom={0.5}
                      label='Visa 1'
                      className='summary-item'
                      isBold
                    />
                    <KeyValueItem
                      fontSize={14}
                      marginBottom={0.5}
                      label='Visa Number -'
                      className='summary-item'
                      textToCopy='Indian'
                    >
                      L6711890
                    </KeyValueItem> */}
                  </DCLegalCheatSheetInfo>
                </Grid>
                <Grid item xs={6}>
                  <DCLegalCheatSheetInfo>
                    <Typography
                      variant="subtitle1"
                      gutterBottom
                      component="h2"
                      className="cheatsheet-title"
                      color="primary"
                    >
                      Work History
                    </Typography>
                    {/* <KeyValueItem
                      fontSize={14}
                      marginBottom={0.5}
                      label='Employment 1'
                      className='summary-item'
                      isBold
                    />

                    <KeyValueItem
                      fontSize={14}
                      marginBottom={0.5}
                      label='Employer Name -'
                      className='summary-item'
                      textToCopy='Indian'
                    >
                      Amizin
                    </KeyValueItem> */}
                  </DCLegalCheatSheetInfo>
                </Grid>
              </Grid>
            </Grid>
          </DCShadowCard>
        </Container>
      </DCDashboardContent>
    </Box>
  );
};
export default LegalSupport;
