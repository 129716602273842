import {
  DialogContent,
  DialogActions,
  Button,
  DialogTitle,
} from "@mui/material";
import { useDeleteTravelHistoryModalContextNew } from "../../contexts/DeleteTravelHistoryModalContextNew";
import { DCDialog } from "../../assets/theme/theme";
import { LoadingButton } from "@mui/lab";
import { useCallback, useState } from "react";

export interface Props {
  travelState: "usa" | "world";
}

const DeleteTravelHistoryModalNew: React.FC<Props> = ({ travelState }) => {
  const [loading, setLoading] = useState(false);

  const {
    openDelete,
    closeDeleteTravelHistoryModal,
    selectedRowData,
    deleteSelectedTravelRecords,
  } = useDeleteTravelHistoryModalContextNew();
  const allSelected =
    travelState === "usa"
      ? selectedRowData.allSelectedUsa
      : selectedRowData.allSelectedWorld;
  const selectedRows =
    travelState === "usa"
      ? selectedRowData.selectedRowsUsa
      : selectedRowData.selectedRowsWorld;

  const onClick = useCallback(async () => {
    setLoading(true);
    await deleteSelectedTravelRecords();
    closeDeleteTravelHistoryModal();
    setLoading(false);
  }, [closeDeleteTravelHistoryModal, deleteSelectedTravelRecords]);

  return (
    <DCDialog disableEscapeKeyDown fullWidth maxWidth="xs" open={openDelete}>
      <DialogContent>
        <DialogTitle  className="text-delete">
          {allSelected
            ? `Are you sure you want to delete ALL(${selectedRows.length}) the travel records?`
            : `Are you sure you want to delete ${selectedRows.length} travel record(s)?`}
        </DialogTitle>
      </DialogContent>
      <DialogActions className="delete-all-blog">
        <Button
          variant="outlined"
          disabled={loading}
          onClick={() => {
            closeDeleteTravelHistoryModal();
          }}
        >
          CANCEL
        </Button>
        <LoadingButton
          variant="contained"
          color="error"
          loading={loading}
          onClick={onClick}
        >
          Yes, DELETE
        </LoadingButton>
      </DialogActions>
    </DCDialog>
  );
};

export default DeleteTravelHistoryModalNew;
