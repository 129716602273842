import { Box, DialogContent, DialogTitle, Tab, Tabs, Typography } from '@mui/material'
import theme, { DCDialog } from '../../assets/theme/theme'
import { useDocumentExpiryModal } from '../../contexts/DocumentExpiryModalContext'
import { getExpiryFieldValue } from '../../utils/documentUtils'
import { differenceInDays, format, formatDistance } from 'date-fns'
import { ErrorOutline, WarningAmberRounded } from '@mui/icons-material'
import { useState } from 'react'
import { usePdfPreviewModalContext } from '../../contexts/PdfPreviewModalContext'
import { useCustomCardModalContext } from '../../contexts/CustomCardModalContext'

interface Props {
  open: boolean,
  onClose: () => void
}

const DocumentExpiryModal: React.FC<Props> = ({ open, onClose }) => {
  const { documentsWithExpiry, customCardWithExpiry } = useDocumentExpiryModal();
  const { openModalForDocument } = usePdfPreviewModalContext();
  const { openModalForEdit } = useCustomCardModalContext();
  const [docExpiryState, setDocExpiryState] = useState<string>("auto");

  return (
    <DCDialog
      disableEscapeKeyDown
      open={open}
      onClose={onClose}
      scroll="paper"
      maxWidth="md"
      fullWidth
      sx={{
        "& .MuiDialog-container": {
          "& .MuiPaper-root": {
            height: "90%",
          },
        },
        overflowY: "scroll",
      }}>
      <DialogTitle sx={{ fontSize: 20, fontWeight: 600 }}>Document Expiry</DialogTitle>
      <DialogContent>
        <Tabs value={docExpiryState} aria-label="auto custom changer" sx={{ mb: 3 }}>
          <Tab
            value="auto"
            label="Auto generated Cards"
            onClick={() => {
              setDocExpiryState("auto");
            }}
          />
          <Tab
            value="custom"
            label="Custom Cards"
            onClick={() => {
              setDocExpiryState("custom");
            }}
          />
        </Tabs>
        {docExpiryState === "auto" && (
          <>
            {documentsWithExpiry.notExpired.length > 0 && (
              <Box sx={{ mb: 5 }}>
                <Typography sx={{ fontSize: 18, fontWeight: 600, display: "flex", gap: 1, alignItems: "center" }}>
                  <WarningAmberRounded sx={{ color: theme.palette.warning.main, fontSize: 18 }} /> Upcoming Expiration Dates
                </Typography>
                <Typography>
                  {documentsWithExpiry.notExpired.map((doc) => (
                    <>
                      {differenceInDays(new Date(getExpiryFieldValue(doc.doc_info)), new Date()) <= 90 ? (
                        <Box key={doc.id} sx={{ p: 2, borderRadius: 3, my: 1, bgcolor: theme.palette.warning.light, display: "flex", justifyContent: "space-between" }}>
                          <Typography onClick={() => openModalForDocument(doc)} sx={{ cursor: "pointer" }}>
                            <b>{doc?.doc_name_autogenerated}</b> is expiring on <b>{format(new Date(getExpiryFieldValue(doc.doc_info)), "PP")} </b>
                          </Typography>
                          <Box>
                            <Typography variant="caption">
                              {formatDistance(new Date(getExpiryFieldValue(doc.doc_info)), new Date())}
                            </Typography>
                          </Box>
                        </Box>
                      ) : (
                        <Box key={doc.id} sx={{ p: 2, borderRadius: 3, my: 1, bgcolor: theme.palette.primary.light, display: "flex", justifyContent: "space-between" }}>
                          <Typography onClick={() => openModalForDocument(doc)} sx={{ cursor: "pointer" }}>
                            <b>{doc?.doc_name_autogenerated}</b> is expiring on <b>{format(new Date(getExpiryFieldValue(doc.doc_info)), "PP")} </b>
                          </Typography>
                          <Box>
                            <Typography variant="caption">
                              {formatDistance(new Date(getExpiryFieldValue(doc.doc_info)), new Date())}
                            </Typography>
                          </Box>
                        </Box>
                      )}
                    </>
                  ))}
                </Typography>
              </Box>
            )}
            {documentsWithExpiry.alreadyExpired.length > 0 && (
              <Box sx={{ mb: 5 }}>
                <Typography sx={{ fontSize: 18, fontWeight: 600, display: "flex", gap: 1, alignItems: "center" }}>
                  <ErrorOutline sx={{ color: theme.palette.error.main, fontSize: 18 }} /> Expired Documents
                </Typography>
                <Typography>
                  {documentsWithExpiry.alreadyExpired.map((doc) => (
                    <Box key={doc.id} sx={{ p: 2, borderRadius: 3, my: 1, bgcolor: theme.palette.error.light }}>
                      <Typography onClick={() => openModalForDocument(doc)} sx={{ cursor: "pointer" }}>
                        <b>{doc?.doc_name_autogenerated}</b> has expired on <b>{format(new Date(getExpiryFieldValue(doc.doc_info)), "PP")}</b>
                      </Typography>
                    </Box>
                  ))}
                </Typography>
              </Box>
            )}
            {documentsWithExpiry.notExpired.length === 0 && documentsWithExpiry.alreadyExpired.length === 0 && (
              <Box sx={{ p: 2, borderRadius: 3, my: 1, bgcolor: theme.palette.primary.light, display: "flex", alignItems: "center", gap: 1 }}>
                <WarningAmberRounded sx={{ color: theme.palette.warning.main }} />
                <Typography>
                  No documents with expiry date found.
                </Typography>
              </Box>
            )}
          </>
        )}

        {docExpiryState === "custom" && (
          <>
            {customCardWithExpiry.notExpired.length > 0 && (
              <Box sx={{ mb: 5 }}>
                <Typography sx={{ fontSize: 18, fontWeight: 600, display: "flex", gap: 1, alignItems: "center" }}>
                  <WarningAmberRounded sx={{ color: theme.palette.warning.main, fontSize: 18 }} /> Upcoming Expiration Dates
                </Typography>
                <Typography>
                  {customCardWithExpiry.notExpired.map((doc) => (
                    <>
                      {differenceInDays(new Date(doc.expiry_date.seconds * 1000), new Date()) <= 90 ? (
                        <Box key={doc.doc_id} sx={{ p: 2, borderRadius: 3, my: 1, bgcolor: theme.palette.warning.light, display: "flex", justifyContent: "space-between" }}>
                          <Typography onClick={() => openModalForEdit(doc.doc_original)} sx={{ cursor: "pointer" }}>
                            <b>{doc.doc_title}</b> is expiring on <b>{format(new Date(doc.expiry_date.seconds * 1000), "PP")} </b>
                          </Typography>
                          <Box>
                            <Typography variant="caption">
                              {formatDistance(new Date(doc.expiry_date.seconds * 1000), new Date())}
                            </Typography>
                          </Box>
                        </Box>
                      ) : (
                        <Box key={doc.doc_id} sx={{ p: 2, borderRadius: 3, my: 1, bgcolor: theme.palette.primary.light, display: "flex", justifyContent: "space-between" }}>
                          <Typography onClick={() => openModalForEdit(doc.doc_original)} sx={{ cursor: "pointer" }}>
                            <b>{doc.doc_title}</b> is expiring on <b>{format(new Date(doc.expiry_date.seconds * 1000), "PP")} </b>
                          </Typography>
                          <Box>
                            <Typography variant="caption">
                              {formatDistance(new Date(doc.expiry_date.seconds * 1000), new Date())}
                            </Typography>
                          </Box>
                        </Box>
                      )}
                    </>
                  ))}
                </Typography>
              </Box>
            )}
            {customCardWithExpiry.alreadyExpired.length > 0 && (
              <Box sx={{ mb: 5 }}>
                <Typography sx={{ fontSize: 18, fontWeight: 600, display: "flex", gap: 1, alignItems: "center" }}>
                  <ErrorOutline sx={{ color: theme.palette.error.main, fontSize: 18 }} /> Expired Documents
                </Typography>
                <Typography>
                  {customCardWithExpiry.alreadyExpired.map((doc) => (
                    <Box key={doc.doc_id} sx={{ p: 2, borderRadius: 3, my: 1, bgcolor: theme.palette.error.light }}>
                      <Typography onClick={() => openModalForEdit(doc.doc_original)} sx={{ cursor: "pointer" }}>
                        <b>{doc.doc_title}</b> has expired on <b>{format(new Date(doc.expiry_date.seconds * 1000), "PP")}</b>
                      </Typography>
                    </Box>
                  ))}
                </Typography>
              </Box>
            )}
            {customCardWithExpiry.notExpired.length === 0 && customCardWithExpiry.alreadyExpired.length === 0 && (
              <Box sx={{ p: 2, borderRadius: 3, my: 1, bgcolor: theme.palette.primary.light, display: "flex", alignItems: "center", gap: 1 }}>
                <WarningAmberRounded sx={{ color: theme.palette.warning.main }} />
                <Typography>
                  No custom documents with expiry date found.
                </Typography>
              </Box>
            )}
          </>
        )}
      </DialogContent>
    </DCDialog>
  )
}

export default DocumentExpiryModal