import {
  Box,
  Divider,
  Grid,
  Hidden,
  Tooltip,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React from "react";
import theme, { DCPageSummary } from "../../assets/theme/theme";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
// @ts-ignore
import Flags from "country-flag-icons/react/3x2";
// @ts-ignore
import { hasFlag } from "country-flag-icons";
import ReportOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";
import DCCopyToClipboard from "../Shared/DCCopyToClipboard";
import { IDocument } from "../../firebaseTypes";
import {
  findField,
  findFieldDisplayValue,
  findFoldersFromTitle,
  isExpired,
} from "../../utils/documentUtils";
import { useDocumentContext } from "../../contexts/DocumentContext";
import { reduce, get, chunk, map, isEmpty, take, uniqueId } from "lodash";
import country_name_to_code from "../../utils/country_name_to_code.json";
import nationality_to_country_code from "../../utils/nationality_to_country_code.json";
import { useUserContext } from "../../contexts/UserContext";
import DisplayLockUnlock from "./DisplayLockUnlock";
import { SensitiveDataMask } from "../Shared/SensitiveDataMask";
import { ImmigrationSummaryIllustration } from "../Shared/Illustrations";

export interface KeyValueItemProps {
  label: React.ReactNode;
  className: string;
  textToCopy?: string;
  fontSize?: number;
  marginBottom?: number;
  isBold?: boolean;
  icon?: React.ReactNode;
}

function ExpireOrValidIcon({
  isDateExpired,
  expireDate,
}: {
  expireDate: string | null;
  isDateExpired: boolean;
}) {
  return isDateExpired ? (
    <Tooltip title={`Expired on ${expireDate}`} arrow placement="top">
      <ReportOutlinedIcon
        sx={{ marginLeft: 1 }}
        color="error"
        fontSize="small"
      />
    </Tooltip>
  ) : (
    <Tooltip title={`Valid until ${expireDate}`} arrow placement="top">
      <CheckCircleOutlineIcon
        sx={{ marginLeft: 1 }}
        color="success"
        fontSize="small"
      />
    </Tooltip>
  );
}

const KeyValueItem: React.FC<KeyValueItemProps> = (props) => {
  return (
    <Typography
      component="p"
      className={`${props.className} key-value--item`}
      mb={props.marginBottom ? props.marginBottom : 1}
      sx={{
        textOverflow: "ellipsis",
        overflow: "hidden",
        whiteSpace: "nowrap",
        lineHeight: "30px",
      }}
    >
      <Typography
        component="span"
        pr={1}
        fontSize={props.fontSize}
        color={theme.palette.text.secondary}
        fontWeight={props.isBold ? 600 : 400}
      >
        {props.label}
      </Typography>
      <DCCopyToClipboard text={props.textToCopy} icon={props.icon}>
        <Typography
          noWrap={false}
          alignItems="center"
          component="span"
          fontSize={props.fontSize}
          fontWeight={props.isBold ? 600 : 400}
        >
          {props.children}
        </Typography>
      </DCCopyToClipboard>
    </Typography>
  );
};

function VisaKeyVals(visa: IDocument) {
  const visaNumber = findFieldDisplayValue(visa.doc_info, "Visa No.")?.trim();
  const visaType = findFieldDisplayValue(visa.doc_info, "Visa Type");
  const expireDate = findFieldDisplayValue(visa.doc_info, "Expiry Date");
  const isDateExpired = isExpired(findField(visa.doc_info, "Expiry Date"));
  const [isInfoVisible, setIsInfoVisible] = React.useState(false);

  return [
    <KeyValueItem
      key={uniqueId()}
      label={`${visa.doc_type} No. / Type:`}
      className="summary-item"
      textToCopy={`${visaNumber}/${visaType}`}
      icon={
        visaNumber &&
        visaType && (
          <DisplayLockUnlock
            isInfoVisible={isInfoVisible}
            setIsInfoVisible={setIsInfoVisible}
            fieldLabel={"Visa Number"}
          />
        )
      }
    >
      {visaNumber && visaType ? (
        !isInfoVisible ? (
          <>
            {<SensitiveDataMask sensitiveValue={visaNumber} />} / {visaType}
          </>
        ) : (
          <>{`${visaNumber} / ${visaType}`}</>
        )
      ) : null}
    </KeyValueItem>,

    <KeyValueItem
      key={uniqueId()}
      label={`${visa.doc_type} Expiry Date:`}
      className="summary-item"
      textToCopy={expireDate as string}
      icon={
        <ExpireOrValidIcon
          expireDate={expireDate}
          isDateExpired={isDateExpired}
        />
      }
    >
      {expireDate ? <>{expireDate}</> : ""}
    </KeyValueItem>,
  ];
}

function DLKeyVals(dl: IDocument) {
  const dlNumber = findFieldDisplayValue(dl.doc_info, "License No.")?.trim();
  const state = findFieldDisplayValue(dl.doc_info, "State");
  const expireDate = findFieldDisplayValue(dl.doc_info, "Expiry Date");
  const isDateExpired = isExpired(findField(dl.doc_info, "Expiry Date"));
  const [isInfoVisible, setIsInfoVisible] = React.useState(false);

  return [
    <KeyValueItem
      key={uniqueId()}
      label="DL No. / State:"
      className="summary-item"
      textToCopy={`${dlNumber}/${state}`}
      icon={
        dlNumber &&
        state && (
          <DisplayLockUnlock
            isInfoVisible={isInfoVisible}
            setIsInfoVisible={setIsInfoVisible}
            fieldLabel={"DL Number"}
          />
        )
      }
    >
      {dlNumber && state ? (
        !isInfoVisible ? (
          <>
            {<SensitiveDataMask sensitiveValue={dlNumber} />} / {state}
          </>
        ) : (
          <>{`${dlNumber} / ${state}`}</>
        )
      ) : null}
    </KeyValueItem>,
    <KeyValueItem
      key={uniqueId()}
      label="DL Expiry Date:"
      className="summary-item"
      textToCopy={expireDate as string}
      icon={
        <ExpireOrValidIcon
          expireDate={expireDate}
          isDateExpired={isDateExpired}
        />
      }
    >
      {expireDate ? <>{expireDate}</> : ""}
    </KeyValueItem>,
  ];
}

function PassportKeyVals(passport: IDocument) {
  const passportNumber = findFieldDisplayValue(
    passport.doc_info,
    "Passport No."
  )?.trim();
  const expireDate = findFieldDisplayValue(passport.doc_info, "Expiry Date");
  const isDateExpired = isExpired(findField(passport.doc_info, "Expiry Date"));
  const [isInfoVisible, setIsInfoVisible] = React.useState(false);
  return [
    <KeyValueItem
      key={uniqueId()}
      label="Passport No.:"
      className="summary-item"
      textToCopy={passportNumber as string}
      icon={
        passportNumber && (
          <DisplayLockUnlock
            isInfoVisible={isInfoVisible}
            setIsInfoVisible={setIsInfoVisible}
            fieldLabel={"Passport No."}
          />
        )
      }
    >
      {passportNumber ? (
        !isInfoVisible ? (
          <>{<SensitiveDataMask sensitiveValue={passportNumber} />}</>
        ) : (
          <>{passportNumber}</>
        )
      ) : null}
    </KeyValueItem>,
    <KeyValueItem
      key={uniqueId()}
      label="Passport Expiry Date:"
      className="summary-item"
      textToCopy={expireDate as string}
      icon={
        <ExpireOrValidIcon
          expireDate={expireDate}
          isDateExpired={isDateExpired}
        />
      }
    >
      {expireDate ? <>{expireDate}</> : ""}
    </KeyValueItem>,
  ];
}

function CountryKeyVals(
  countryName: string | undefined,
  nationality: string | undefined
) {
  const code = countryName && get(country_name_to_code, [countryName], "");
  const hasCode = hasFlag(code);
  const FlagComponent = Flags[code];

  const nationalityCode =
    nationality && get(nationality_to_country_code, [nationality], "");
  const hasNationalityCode = hasFlag(nationalityCode);
  const NationalityFlagComponent = Flags[nationalityCode];

  return [
    <KeyValueItem
      key={uniqueId()}
      label="Nationality:"
      className="summary-item"
      textToCopy={nationality}
    >
      {nationality}
      {hasNationalityCode && (
        <NationalityFlagComponent
          style={{
            height: 12,
            marginLeft: 8,
          }}
        />
      )}
    </KeyValueItem>,

    <KeyValueItem
      key={uniqueId()}
      label="Country Residing:"
      className="summary-item"
      textToCopy={countryName}
    >
      {countryName}
      {hasCode && (
        <FlagComponent
          title={`${countryName} flag`}
          style={{
            height: 12,
            marginLeft: 8,
          }}
        />
      )}
    </KeyValueItem>,
  ];
}

export interface Props {}
const ImmigrationSummary: React.FC<Props> = (props) => {
  const { folders } = useDocumentContext();
  const { user } = useUserContext();
  const matchUpMd = useMediaQuery(theme.breakpoints.up("md"));
  // folders
  const passports = findFoldersFromTitle(folders, "passport");
  const visas = findFoldersFromTitle(folders, "visa usa");
  const dls = findFoldersFromTitle(folders, "drivers license");

  console.log("dls", dls);
  

  // unfolded
  const passportKeyVals = isEmpty(passports)
    ? PassportKeyVals({ doc_type: "Passport" } as any)
    : reduce(
        passports,
        (acc, folder) => {
          return [...acc, ...PassportKeyVals(get(folder.docs, [0]))];
        },
        [] as (JSX.Element | null)[]
      );

  const visaKeyVals = isEmpty(visas)
    ? VisaKeyVals({ doc_type: "Visa" } as any)
    : reduce(
        visas,
        (acc, folder) => {
          return [...acc, ...VisaKeyVals(get(folder.docs, [0]))];
        },
        [] as (JSX.Element | null)[]
      );

  const dlKeyVals = isEmpty(dls)
    ? DLKeyVals({ doc_type: "DL" } as any)
    : reduce(
        dls,
        (acc, folder) => {
          return [...acc, ...DLKeyVals(get(folder.docs, [0]))];
        },
        [] as (JSX.Element | null)[]
      );

  const keyVals = [
    ...passportKeyVals,
    ...CountryKeyVals(user?.country, user?.nationality),
    ...visaKeyVals,
    ...dlKeyVals,
  ].filter((x) => !!x) as JSX.Element[];

  const keyValChunks = take(chunk(keyVals, 4), 2);

  return (
    <DCPageSummary className="immigration-summary immigration-blog">
      <Box sx={{ display: matchUpMd ? "flex" : "block", width: "100%" }}>
        <Box>
          {passportKeyVals.length + dlKeyVals.length + dlKeyVals.length < 8 && (
            <Box
              sx={{
                background: theme.palette.background.default,
                height: "100%",
                display: "flex",
                mr: matchUpMd ? "20px" : 0,
                width: matchUpMd ? "calc(100% - 20px)" : "100%",
                marginBottom: matchUpMd ? "0px" : "16px",
                borderRadius: "10px",
                alignItems: "center",
                justifyContent: matchUpMd ? "flex-end" : "flex-start",
                color: theme.palette.primary.main,
                p: 2,
                pr: 3,
              }}
            >
              <Box
                sx={{
                  "@media screen and (max-width: 1366px)": {
                    display: "none",
                  },
                }}
              >
                <ImmigrationSummaryIllustration
                  height={"160"}
                  style={{
                    marginTop: "-5px",
                    marginRight: "3px",
                  }}
                />
              </Box>
              <Typography
                fontSize={20}
                lineHeight={1.4}
                fontWeight={500}
                component="h2"
                zIndex={1}
              >
                Immigration
                <Hidden mdDown>
                  <br />
                </Hidden>{" "}
                Summary
              </Typography>
            </Box>
          )}
        </Box>
        <Box
          sx={{
            display: "flex",
            flexWrap: { xs: "wrap", lg: "nowrap" },
            overflow: "hidden",
            flex: 1,
            alignItems: "center",
          }}
        >
          {map(keyValChunks, (ch, i) => {
            return (
              <span key={i}>
                <Grid
                  className="immigration-inner-blog"
                  key={`key-val-${i}`}
                  item
                  flex={i === 1 ? 1 : 0}
                  minWidth={i === 1 ? "auto" : "fit-content"}
                  flexBasis="auto"
                  px={3}
                  display="flex"
                  justifyContent="center"
                  flexDirection="column"
                  overflow="hidden"
                >
                  {ch}
                </Grid>
                {i !== keyValChunks.length - 1 && (
                  <Hidden lgDown key={`divider-${i}`}>
                    <Divider orientation="vertical" flexItem />
                  </Hidden>
                )}
              </span>
            );
          })}
        </Box>
        {/* <Grid
          item
          flex={1}
          px={3}
          display='flex'
          justifyContent='center'
          flexDirection='column'
        >
          <KeyValueItem
            label='Passport Number:'
            className='summary-item'
            textToCopy='07/10/2023'
          >
            <a href='#'>P11692420</a>
          </KeyValueItem>
          <KeyValueItem
            label='Passport Expiry Date:'
            className='summary-item'
            textToCopy='07/10/2023'
          >
            09/10/2023
            <CheckCircleOutlineIcon
              sx={{ marginLeft: 1 }}
              color='success'
              fontSize='small'
            />
          </KeyValueItem>
          <KeyValueItem
            label='Nationality:'
            className='summary-item'
            textToCopy='07/10/2023'
          >
            Indian
            <Flags.IN
              title='Indian flag'
              style={{
                height: 12,
                marginLeft: 8,
              }}
            />
          </KeyValueItem>
          <KeyValueItem
            label='Country Residing:'
            className='summary-item'
            textToCopy='07/10/2023'
          >
            USA
            <Flags.US
              title='United States'
              style={{
                height: 12,
                marginLeft: 8,
              }}
            />
          </KeyValueItem>
        </Grid>
        <Hidden mdDown>
          <Divider orientation='vertical' flexItem />
        </Hidden>
        <Grid
          item
          flex={1}
          px={3}
          display='flex'
          justifyContent='center'
          flexDirection='column'
        >
          <KeyValueItem
            label='Visa Number / Type:'
            className='summary-item'
            textToCopy='07/10/2023'
          >
            <a href='/'>L6711890</a> / H1B
          </KeyValueItem>
          <KeyValueItem
            label='Visa Expiry Date:'
            className='summary-item'
            textToCopy='07/10/2023'
          >
            07/10/2024
            <CheckCircleOutlineIcon
              sx={{ marginLeft: 1 }}
              color='success'
              fontSize='small'
            />
          </KeyValueItem>
          <KeyValueItem
            label='DL Number/State:'
            className='summary-item'
            textToCopy='07/10/2023'
          >
            <a href='/'>I5283659</a> / CA
          </KeyValueItem>
          <KeyValueItem
            label='DL Expiry Date:'
            className='summary-item'
            textToCopy='07/10/2023'
          >
            07/10/2023
            <CheckCircleOutlineIcon
              sx={{ marginLeft: 1 }}
              color='success'
              fontSize='small'
            />
          </KeyValueItem>
        </Grid>  */}
      </Box>
    </DCPageSummary>
  );
};

export default ImmigrationSummary;
