import React from "react";
import CircleIcon from "@mui/icons-material/Circle";
import { isEmptyStr } from "../../utils/utils";

export const SensitiveDataMask: React.FC<{ sensitiveValue: string }> = (props: {
  sensitiveValue: string;
}) => {
  if (isEmptyStr(props.sensitiveValue)) return null;
  return (
    <>
      {Array.from(Array(7).keys()).map((i) => {
        return (
          <CircleIcon
            key={i}
            sx={{
              fontSize: "6px",
              marginLeft: "3px",
              marginBottom: "2px",
            }}
          />
        );
      })}
    </>
  );
};
