/* eslint-disable @typescript-eslint/no-unused-expressions */
import { Typography, Box } from '@mui/material';
import custom_card_empty_state from "../../assets/images/illustrations/custom_card_empty_state.svg";
import automatic_card_empty_state from "../../assets/images/illustrations/data_processing.svg"

export interface Props {
    tab: "auto" | "custom"
}

const EmptyState: React.FC<Props> = (props) => {

    // const { createCustomCard } = useCreateCustomCardContext();

    return (
        <Box sx={{ 'display': 'flex', 'alignItems': "center", "marginTop": '75px', "mb": '75px', 'flexDirection': 'column' }}>
            <Box component='img' src={props.tab === "auto" ? automatic_card_empty_state : custom_card_empty_state} sx={{ width: '200px', mb: '25px' }} alt=" Looks Like you haven't added any custom card Yet!">
            </Box>
            <Typography fontSize={25} variant="h1" className='looks-like-blog'>
                {props.tab === "auto" ? "Looks Like you haven't uploaded any machine recognizable documents  Yet!" : "Looks Like you haven't added any custom card Yet!"}
            </Typography>
            {/* <Button variant="outlined" sx={{
                borderRadius: '10px',
                paddingInline: '18px',
                height: 46,
                boxShadow: 'none',
                fontWeight: 400,
                marginTop : '20px'
            }} 
            startIcon={<AddIcon />}
            size='large'
            onClick={createCustomCard}
            >Create New Card</Button> */}
        </Box>
    )
}

export default EmptyState