/* eslint-disable @typescript-eslint/no-unused-expressions */
import { Typography, Box, Tooltip } from "@mui/material";
import { FileRejection } from "react-dropzone";
import custom_card_empty_state from "../../assets/images/illustrations/custom_card_empty_state.svg";
import automatic_card_empty_state from "../../assets/images/illustrations/data_processing.svg";
import { UploadButton } from "../Shared/UploadButton";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";

export interface Props {
  tab: "usa" | "world";
  onUpload?(acceptedFiles: File[], fileRejections: FileRejection[]): void;
}

const TravelEmptyStateNew: React.FC<Props> = ({ tab, onUpload }) => {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        marginTop: "75px",
        mb: "75px",
        flexDirection: "column",
      }}
    >
      <Box
        component="img"
        src={
          tab === "usa" ? automatic_card_empty_state : custom_card_empty_state
        }
        sx={{ width: "200px", mb: "25px" }}
        alt="Looks Like you haven't added any custom travel yet!"
      ></Box>
      {tab === "usa" ? (
        <>
          <Typography fontSize={25} variant="h1">
            To get started, upload a USA Travel History document or create a
            travel log.{" "}
            <a
              onClick={() => {
                window.open(
                  window.location.origin + "/faqs",
                  "newwindow",
                  "width=1200,height=1080"
                );
              }}
            >
              Need Help?
            </a>
          </Typography>
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            mt={3}
          >
            <Tooltip
              title={
                <Box display="flex" alignItems="center">
                  <OpenInNewIcon sx={{ mr: 2 }} fontSize="small" />
                  <Typography>
                    <a
                      href="https://i94.cbp.dhs.gov/I94/#/history-search"
                      target="_blank"
                      style={{ color: "white" }}
                      rel="noreferrer"
                    >
                      Download
                    </a>{" "}
                    your recent Travel History.
                  </Typography>
                </Box>
              }
            >
              <span>
                <UploadButton
                  size="large"
                  uploadOptions={{
                    onDrop: onUpload,
                    accept: {
                      "application/pdf": [".pdf"],
                    },
                  }}
                >
                  Upload USA Travel History
                </UploadButton>
              </span>
            </Tooltip>
          </Box>
        </>
      ) : (
        <Typography fontSize={25} variant="h1">
          "To get started, create a travel log."
        </Typography>
      )}
    </Box>
  );
};

export default TravelEmptyStateNew;
