import {
  Typography,
  Box,
  CardActionArea,
  CardHeader,
} from '@mui/material';
import React from 'react';
import { IShareDoc } from '../../firebaseTypes';
import { DCDocumentCard } from "../../assets/theme/theme";

interface Props {
  doc: IShareDoc,
  index: number
  isViewOnly: boolean;
}

const SharePageCardExpired: React.FC<Props> = ({ doc, index, isViewOnly }) => {

  return (
    <DCDocumentCard
      sx={{
        minWidth: "320px",
        maxWidth: "380px",
        "&:hover": {
          boxShadow:
            "rgba(3, 3, 3, 0.16) 0px 8px 16px 0px, rgba(3, 3, 3, 0.08) 0px 0px 6px 0",
        },
        "& .MuiCardHeader-action": {
          alignSelf: "end",
        },
      }}
      variant="outlined"
    >
      <CardActionArea sx={{ cursor : "default"}}>
        {/* checkbox */}
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            float: 'left',
            marginTop: '5px',
            zIndex: 'tooltip'
          }}
        >
        </Box>
        {/* image */}
          <Box sx={{ py: 10, px: 5, textAlign: "center" }}>
            <Typography sx={{ fontSize: 16 }}>The document share link has been <b>expired</b>, and the content is no longer accessible. Kindly request the sender to share the document again, you will receive new link via email.</Typography>
          </Box>
      </CardActionArea>
      {/* document title & download icon */}
      
        <CardHeader
          sx={{
            overflowWrap: 'anywhere',
            pb: '5px',
            pt: '10px',
          }}
          title={<Typography sx={{mb: 1}} component='p'>{doc.doc_name}</Typography>}
        ></CardHeader>
    </DCDocumentCard>
  );
};

export default SharePageCardExpired;
