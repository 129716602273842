import {
  Typography,
  Box,
  CardActionArea,
  CardHeader,
  IconButton,
  Tooltip,
  CardMedia,
} from '@mui/material';
import { get } from 'lodash';
import React, { useCallback } from 'react';
import { usePublicDocumentSharePageContext } from '../../contexts/PublicDocumentSharePageContext';
import { IShareDoc } from '../../firebaseTypes';
import { downloadResource } from '../../utils/fetchUtils';
import DownloadIcon from '../Shared/Icons/DownloadDocumentIcon';
import WaterMark from '../Shared/WaterMark';
import CardCheckbox from '../Shared/DCCardCheckBox';
import { DCDocumentCard } from "../../assets/theme/theme";

interface Props {
  doc: IShareDoc,
  index: number
  isViewOnly: boolean;
}

const SharePageCard: React.FC<Props> = ({ doc, index, isViewOnly }) => {
  const {
    shareReq,
    setDocumentInViewIndex,
    selectDeselectDocument,
    deselectAll,
    selectedDocumentIndices,
  } = usePublicDocumentSharePageContext();
  const [showCheckboxState, setShowCheckboxState] = React.useState(false);

  const onDownloadClick = React.useCallback(async (downloadURL, doc_name) => {
    await downloadResource(downloadURL, doc_name);
  }, []);

  const showCheckbox = useCallback(() => {
    setShowCheckboxState(true);
  }, []);

  const hideCheckbox = useCallback(() => {
    setShowCheckboxState(false);
  }, []);

  const onCheckboxClick = useCallback(
    (evt: any) => {
      evt.stopPropagation();
      selectDeselectDocument(index);
    },
    [index, selectDeselectDocument]
  );

  return (
    <DCDocumentCard
      sx={{
        minWidth: "320px",
        maxWidth: "380px",
        "&:hover": {
          boxShadow:
            "rgba(3, 3, 3, 0.16) 0px 8px 16px 0px, rgba(3, 3, 3, 0.08) 0px 0px 6px 0",
        },
        "& .MuiCardHeader-action": {
          alignSelf: "end",
        },
      }}
      variant="outlined"
      onMouseLeave={hideCheckbox}
      onMouseEnter={showCheckbox}
    >
      <CardActionArea
        onClick={() => { 
            setDocumentInViewIndex(index);
            deselectAll();
            selectDeselectDocument(index);
        }}
      >
        {/* checkbox */}
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            float: 'left',
            marginTop: '5px',
            zIndex: 'tooltip'
          }}
        >
          {shareReq?.documents[index].is_expired ? null : (
            <CardCheckbox
              show={showCheckboxState}
              checked={selectedDocumentIndices[index]}
              onClick={onCheckboxClick}
            />)}
        </Box>
        {/* image */}
          <WaterMark>
            <CardMedia
              component="img"
              alt=""
              height="222px"
              image={get(shareReq, ['signedThumbnailURLs', index])}
            />
          </WaterMark>

      </CardActionArea>
      {/* document title & download icon */}
      
        <CardHeader
          sx={{
            overflowWrap: 'anywhere',
            pb: '5px',
            pt: '10px',
          }}
          action={
            !isViewOnly ?
              <Tooltip title='Download' arrow placement='top'>
                <IconButton
                  aria-label='download document'
                  onClick={() => onDownloadClick(get(shareReq, ['signedURLs', index]), doc.doc_name)}
                >
                  <DownloadIcon />
                </IconButton>
              </Tooltip>
              : null
          }
          title={<Typography sx={{mb: 1}} component='p'>{doc.doc_name}</Typography>}
        ></CardHeader>
    </DCDocumentCard>
  );
};

export default SharePageCard;
