import { logEvent } from "firebase/analytics";
import { filter, find, get, map } from "lodash";
import React from "react";
import { useParams } from "react-router-dom";
import { analytics } from "../firebase";
import { IDocument } from "../firebaseTypes";
import { findFolderFromSlug, toDocTypeSlug } from "../utils/documentUtils";
import { IFolder, useDocumentContext } from "./DocumentContext";

// Supposed to manage selection of documents, select all
export interface IDocumentGalleryContext {
  selectedDocuments: ISelectedDocuments;
  selectionModal: ISelectionModal; // for list view, stores doc Ids
  folderInView?: IFolder;
  selectDocIds: (docIds: string[], docType?: string) => void;
  selectDeselectDocument: (doc: IDocument) => void;
  isDocumentSelected: (doc: IDocument) => boolean;
  onAllSelectClick: (evt: React.MouseEvent<HTMLElement>) => void;
  clearSelection: () => void;
}

export interface ISelectionModal {
  [key: string]: string[];
}

const defaultValues: IDocumentGalleryContext = {
  selectedDocuments: {},
  selectionModal: {},
  selectDeselectDocument: () => {},
  selectDocIds: () => {},
  isDocumentSelected: () => {
    return false;
  },
  onAllSelectClick: () => {},
  clearSelection: () => {},
};

const DocumentGalleryContext =
  React.createContext<IDocumentGalleryContext>(defaultValues);

export function useDocumentGalleryContext() {
  return React.useContext(DocumentGalleryContext);
}

export interface ISelectedDocuments {
  [docId: string]: boolean;
}

export const DocumentGalleryProvider: React.FC = (props) => {
  const [selectedDocuments, setSelectedDocuments] = React.useState(
    defaultValues.selectedDocuments
  );
  const [selectionModal, setSelectionModal] = React.useState(
    defaultValues.selectionModal
  );

  const { docType } = useParams<{ docType: string }>();
  const { folders, getDocThumbnailUrl } = useDocumentContext();
  // const [folderInView, setFolderInView] = React.useState(findFolderFromSlug(docType, folders))
  const folderInView = findFolderFromSlug(docType, folders);

  // React.useEffect(() => {
  //   setFolderInView(findFolderFromSlug(docType, folders))
  // }, [docType, folders])

  React.useEffect(() => {
    map(folderInView?.docs, (doc) => {
      getDocThumbnailUrl(doc);
    });
  }, [folderInView, getDocThumbnailUrl]);

  const selectDeselectDocumentSelectionModal = React.useCallback(
    (doc: IDocument) => {
      setSelectionModal((prev) => {
        const docIds = get(prev, [doc.doc_type]) || [];
        // if exists then remove, if doesn't then add
        const afterRemovalDocIds = filter(docIds, (id) => id !== doc.id);

        if (afterRemovalDocIds.length === docIds.length) {
          // Doesn't exist, add
          const afterAddDocIds = [...docIds, doc.id];
          return {
            ...prev,
            [doc.doc_type]: afterAddDocIds,
          };
        }
        // exits, then remove
        return {
          ...prev,
          [doc.doc_type]: afterRemovalDocIds,
        };
      });
    },
    []
  );

  const selectDeselectDocument = React.useCallback(
    (doc: IDocument) => {
      // updates selectedDocuments and also keeps selectionModal in sync
      setSelectedDocuments((prev) => {
        const changes: ISelectedDocuments = {};
        changes[doc.id] = !prev[doc.id];
        return {
          ...prev,
          ...changes,
        };
      });
      selectDeselectDocumentSelectionModal(doc);
    },
    [selectDeselectDocumentSelectionModal]
  );

  const selectDocIds = React.useCallback(
    (docIds: string[], docType?: string) => {
      if (docType) {
        setSelectionModal((prev) => {
          const findRemovedIds = filter(prev[docType], (prevId) => {
            const exists = find(docIds, (id) => id === prevId);
            return exists ? false : true;
          });
          const changes: ISelectedDocuments = {};
          // added ids
          map(docIds, (id) => (changes[id] = true));
          // removed ids
          map(findRemovedIds, (id) => (changes[id] = false));
          setSelectedDocuments((prev) => ({
            ...prev,
            ...changes,
          }));

          return {
            ...prev,
            [docType]: docIds,
          };
        });

        // // overwrite selectedDocuments
        // const changes: ISelectedDocuments = {}
        // map(docIds, id => changes[id] = true)
        // setSelectedDocuments(changes)
      }
    },
    []
  );

  const isDocumentSelected = React.useCallback(
    (doc: IDocument): boolean => {
      return !!selectedDocuments[doc.id];
    },
    [selectedDocuments]
  );

  const onAllSelectClick = React.useCallback(
    (evt: React.MouseEvent<HTMLElement>) => {
      logEvent(analytics, "click_select_all");
      setSelectedDocuments((prev) => {
        const folderSlug = toDocTypeSlug(folderInView?.docType);
        const all = `${folderSlug}-all`;
        const prevAll = Boolean(prev[all]);
        const newAll = !prevAll;
        const changes: ISelectedDocuments = {
          [all]: newAll,
        };
        map(folderInView?.docs, (doc) => {
          changes[doc.id] = newAll;
        });

        // selectedDocuments changes
        if (newAll) {
          const docIds = map(folderInView?.docs, (doc) => doc.id);
          setSelectionModal((prev) => ({
            ...prev,
            [folderInView?.docType as string]: docIds,
          }));
        } else {
          // clear all selection
          setSelectionModal((prev) => ({
            ...prev,
            [folderInView?.docType as string]: [],
          }));
        }
        return { ...prev, ...changes };
      });
    },
    [folderInView?.docs, folderInView?.docType]
  );

  const clearSelection = React.useCallback(() => {
    setSelectedDocuments({});
    setSelectionModal({});
  }, []);

  return (
    <DocumentGalleryContext.Provider
      value={{
        selectedDocuments,
        selectionModal,
        folderInView,
        selectDocIds,
        selectDeselectDocument,
        isDocumentSelected,
        onAllSelectClick,
        clearSelection,
      }}
    >
      {props.children}
    </DocumentGalleryContext.Provider>
  );
};
