import React, { FC, useMemo, useState } from "react";
import  {
  DCDashboardPageHeader,
} from "../../assets/theme/theme";
import Illustration from "../../assets/images/illustrations/page_header_clouds.svg";
import {
  Grid,
  Typography,
  Box,
} from "@mui/material";

const TravelSummaryHeaderNew: FC = () => {
  return (
    <Box className="travel-history">
      <DCDashboardPageHeader elevation={0} sx={{ pb: 16 }}>
        <Grid container mb={1}>
          <Grid item>
            <Typography
              variant="h1"
              component="h1"
              fontWeight={600}
              fontSize={24}
            >
              Travel History
            </Typography>
          </Grid>
          <Grid item position="relative" flex={1}>
            <img
              src={Illustration}
              className="page-header--ilustration travel-history-ilustration"
              alt=""
            />
          </Grid>
        </Grid>
      
      </DCDashboardPageHeader>
    </Box>
  );
};

export default TravelSummaryHeaderNew;
