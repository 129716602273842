import { LoadingButton } from "@mui/lab";
import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import { find, map } from "lodash";
import React from "react";
import theme from "../../assets/theme/theme";
import { IDocument } from "../../firebaseTypes";
import constants from "../../constants";
import { patchData } from "../../utils/fetchUtils";
import { useAuth } from "../../contexts/AuthContext";
import { useUserContext } from "../../contexts/UserContext";
import { getDocName, toDocTypeSlug } from "../../utils/documentUtils";
import { useTagO1EB1ModalContext } from "../../contexts/TagO1EB1ModalContext";

export interface Props {
  document: IDocument;
}

const TagO1EB1DocModal: React.FC<Props> = (props) => {
  const { openModalState, closeModal } = useTagO1EB1ModalContext();
  const [isSavingDoc, setIsSavingDoc] = React.useState(false);
  const [folder, setFolder] = React.useState("");
  const [error, setError] = React.useState("");
  const { currentUser } = useAuth();
  const { user, addUserCustomFolder } = useUserContext();

  const isFolderAlreadyExists = React.useCallback(
    (folderName: string) => {
      //cannot be empty
      if (!folderName || folderName.length === 0) {
        return "Required!";
      }
      const doesAlreadyExist = find(
        user?.customFolders,
        (cf) => toDocTypeSlug(cf.name) === toDocTypeSlug(folderName)
      );
      // I am not checking existance in automatic folders because we allow users to create custom-folders with same names as auto folders and we differentiate both with custom flag in IFolder.
      if (!!doesAlreadyExist) {
        return `Folder already exists with similar name "${doesAlreadyExist.name}"`;
      }
      return "";
    },
    [user]
  );

  const markO1EB1Doc = React.useCallback(async () => {
    try {
      if (currentUser) {
        const token = await currentUser?.getIdToken();
        await patchData(
          `${constants.middleHost}/users/${currentUser.uid}/documents/${props.document.id}/mark-o1-eb1/`,
          {
            doc_type: folder,
          },
          {
            Authorization: `Bearer ${token}`,
          }
        );
      }
      setIsSavingDoc(false);
      closeModal();
    } catch (err) {
      console.error(err);
      setError("Something went wrong. Please retry.");
    }
  }, [closeModal, currentUser, folder, props.document.id]);

  const tagO1EB1Doc = React.useCallback(async () => {
    setIsSavingDoc(true);
    if (isFolderAlreadyExists(folder)) {
      await markO1EB1Doc();
    } else {
      await addUserCustomFolder(folder);
      await markO1EB1Doc();
    }
    
    setIsSavingDoc(false);
  }, [addUserCustomFolder, folder, isFolderAlreadyExists, markO1EB1Doc]);

  const handleChange = React.useCallback(
    (evt) => {
      setFolder(evt.target.value);
    },
    [setFolder]
  );

  return (
    <Dialog
      maxWidth="lg"
      open={openModalState}
      scroll="body"
      onClose={closeModal}
      disableEscapeKeyDown={true}
    >
      <DialogTitle  className="select-document-type-wrapper">
        Tag as O1/EB1 Document - 
        <Typography 
          className="select-document-popover"
          sx={{
            font: "inherit",
            fontWeight: 600,
            color: theme.palette.primary.main,
            ml: 1,
          }}
          component="span"
        >
          {getDocName(props.document)}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Box component="form" sx={{ display: "flex", flexWrap: "wrap" }}>
          <FormControl required fullWidth>
            {/* <InputLabel id="folder_select" htmlFor="folder">
              Select Document Type
            </InputLabel> */}
            <Select
              id="folder"
              labelId="folder_select"
              name="folder"
              onChange={handleChange}
              inputProps={{
                name: "folder",
                id: "folder",
              }}
              displayEmpty
              defaultValue=""
              value={folder || ""}
              // input={<DcInput />}
            >
              <MenuItem key="empty-label-doc-type" value="">
                <em>Select O1/EB1 Document Type</em>
              </MenuItem>
              {/* <ListSubheader disableSticky color="primary">
                Auto-generated Folders
              </ListSubheader>
              {map(folderOptions, (f, i) => {
                return (
                  <MenuItem value={f} key={`${f}-${i}`}>
                    {f}
                  </MenuItem>
                );
              })} */}
              {constants.O1EB1FoldersListManual &&
                map(constants.O1EB1FoldersListManual, (cf, i) => {
                  return (
                    <MenuItem value={cf} key={`${cf}-${i}`}>
                      {cf}
                    </MenuItem>
                  );
                })}
            </Select>
          </FormControl>
        </Box>
        {error && (
          <Alert
            variant="outlined"
            severity="error"
            sx={{
              mt: 4,
            }}
          >
            {error}
          </Alert>
        )}
      </DialogContent>

      <DialogActions>
        <Button variant="outlined" onClick={closeModal} disabled={isSavingDoc}>
          Cancel
        </Button>
        <LoadingButton
          loadingPosition="start"
          loading={isSavingDoc}
          autoFocus
          variant="contained"
          onClick={tagO1EB1Doc}
          disabled={folder === ""}
          aria-label="move document to another folder"
        >
          Tag as O1 / EB1
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default TagO1EB1DocModal;
