import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import { DCButton } from "../../assets/theme/theme";
import { useLogOutTimer } from "../../contexts/LogOutTimer";

const LoggedOutModal = () => {
  const { openLoggedOutModal, setLoggedOutModal } = useLogOutTimer();
  const handleClose = () => setLoggedOutModal(false);

  return (
    <div>
      <Dialog
        open={openLoggedOutModal}
        onClose={handleClose}
        maxWidth="xs"
        fullWidth
      >
        <DialogTitle>Logged out!</DialogTitle>
        <DialogContent>
          <Typography>
            Your session is logged out, due to inactivity.
          </Typography>
        </DialogContent>
        <DialogActions>
          <DCButton
            variant="contained"
            fullWidth
            onClick={() => {
              setLoggedOutModal(false);
            }}
          >
            OK
          </DCButton>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default LoggedOutModal;
