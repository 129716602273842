import React, { useCallback, useEffect, useState } from "react";
import { LoadingButton } from "@mui/lab";
import { Stack, Tooltip, Chip, Box } from "@mui/material";

//import { Button } from "react-scroll";
// import { ViewSelectorButtonGroup } from "../../assets/theme/theme";
import PrintIcon from "@mui/icons-material/Print";
import DownloadIcon from "../Shared/Icons/DownloadDocumentIcon";
// import GridIcon from "../Shared/Icons/GridIcon";
// import ListIcon from "../Shared/Icons/ListIcon";

import { usePublicDocumentSharePageContext } from "../../contexts/PublicDocumentSharePageContext";
import { usePDFMergeContext } from "../../contexts/PDFMergerContext";
import { IShareReq } from "../../firebaseTypes";
import filter from "lodash/filter";
import { get, map, reduce } from "lodash";
import { downloadResource } from "../../utils/fetchUtils";

const IS_SIGNED_URL = true;

function getSelectedDocuments(
  shareReq: IShareReq | undefined,
  selectedDocumentIndices: boolean[]
) {
  if (!shareReq) return [];
  return filter(shareReq.documents, (doc, i) => {
    if (get(selectedDocumentIndices, [i], false)) return true;
    return false;
  });
}

interface Props {}
const NavBar: React.FC<Props> = () => {
  const {
    selectedDocumentIndices,
    shareReq,
    deselectAll,
    // view,
    // setView,
    docDownloadUrls,
  } = usePublicDocumentSharePageContext();
  const { print, isPrintProcessing } = usePDFMergeContext();

  const [count, setCount] = useState(0);

  useEffect(() => {
    const newCount = reduce(
      selectedDocumentIndices,
      (acc, val) => (val ? acc + 1 : acc),
      0
    );
    setCount(newCount);
  }, [selectedDocumentIndices]);

  const clearSelection = useCallback(() => {
    deselectAll();
  }, [deselectAll]);

  const onPrintDocument = useCallback(async () => {
    await print(docDownloadUrls.docUrls, docDownloadUrls.imgUrls, IS_SIGNED_URL);
  }, [print, docDownloadUrls.docUrls, docDownloadUrls.imgUrls]);

  const onDownloadClick = useCallback(() => {
    const selectedDocs = getSelectedDocuments(
      shareReq,
      selectedDocumentIndices
    );
    map(selectedDocs, async (doc, index) => {
      const downloadURL = get(shareReq, ["signedURLs", index]);
      await downloadResource(downloadURL, get(doc, "doc_name", ""));
    });
  }, [selectedDocumentIndices, shareReq]);

  // const _handleAlignment = (
  //   event: React.MouseEvent<HTMLElement>,
  //   newView: any
  // ) => {
  //   setView(newView);
  // };
  // const handleAlignment = useCallback(_handleAlignment, [setView]);

  return (
    <Box component="span" sx={{ display : "flex", justifyContent : "right", marginRight: "60px" }}>
      <Stack spacing={2} direction="row" height={40} display={"flex"} alignItems={"center"}>
        {count > 0 && (
          <Tooltip title="Clear selection" placement="top" arrow>
            <Chip
              color="primary"
              label={`${count} item${count > 1 ? "s" : ""} selected`}
              onDelete={clearSelection}
            />
          </Tooltip>
        )}
        {shareReq?.permission === "download" && (
          <LoadingButton
            disabled={count === 0}
            onClick={onPrintDocument}
            loading={isPrintProcessing}
            loadingPosition="start"
            variant="contained"
            color="primary"
            startIcon={<PrintIcon />}
          >
            Print
          </LoadingButton>
        )}
        {shareReq?.permission === "download" && (
          <LoadingButton
            disabled={count === 0}
            onClick={onDownloadClick}
            loadingPosition="start"
            variant="contained"
            color="primary"
            startIcon={<DownloadIcon />}
          >
            Download
          </LoadingButton>
        )}

        {/* <Paper
          elevation={0}
          sx={{
            display: "flex",
            flexWrap: "wrap",
          }}
        >
          <ViewSelectorButtonGroup
            size="small"
            value={view}
            exclusive
            aria-label="View"
            color="primary"
            onChange={handleAlignment}
          >
            <ToggleButton value="grid" aria-label="left aligned" size="small">
              <GridIcon sx={{ fontSize: 18 }} />
            </ToggleButton>
            <ToggleButton value="list" aria-label="centered" size="small">
              <ListIcon sx={{ fontSize: 18 }} />
            </ToggleButton>
          </ViewSelectorButtonGroup>
        </Paper> */}
      </Stack>
    </Box>
  );
};

export default NavBar;
