import {
  Alert,
  Button,
  CircularProgress,
  Container,
  FormControl,
  Grid,
  Hidden,
  IconButton,
  InputLabel,
  Snackbar,
  Stack,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import React, { useState } from "react";
import Illustration from "../../assets/images/illustrations/auth_illustration.svg";
import theme, { DCButton, FormWrapper } from "../../assets/theme/theme";
import { useAuth } from "../../contexts/AuthContext";
import "react-phone-number-input/style.css";
import CloseIcon from "@mui/icons-material/Close";
import { useHistory } from "react-router-dom";
import OtpInput from "react-otp-input";
import { get } from "lodash";

import Logo from "../Shared/Logo";

export interface Props { }

const VerifyMfaDelete: React.FC<Props> = (props) => {
  const {
    phoneNumber,
    OTP,
    setOTP,
    verifyOTPDeleteAccount,
    openError,
    closeErrorMoadal,
    errMsg,
    buttonIsLoading,
    reSendOTPLogin,
    isOTPSent,
  } = useAuth();

  const [resendTimer, setResendTimer] = React.useState<number>(60);
  const history = useHistory();
  const verificationPhoneNumber = phoneNumber.replace("+", "");
  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={closeErrorMoadal}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  const [error] = useState("");

  const verifyOTPLocal = async (e: any) => {
    try {
      e.preventDefault();
      await verifyOTPDeleteAccount();
    } catch (e) {
      console.error("Error : ", e);
    }
  };

  React.useEffect(() => {
    if (isOTPSent) {
      if (resendTimer > 0) {
        setTimeout(() => {
          setResendTimer((prev) => prev - 1);
        }, 1000);
      }
    }
  }, [isOTPSent, resendTimer]);

  React.useEffect(() => {
    setTimeout(() => {
      const tags = document.getElementsByTagName("input");
      if (get(tags, [0])) {
        tags[0].focus();
      }
    }, 3000);
  }, []);


  React.useEffect(() => {
    if (!phoneNumber) {
      history.push("/");
    }
  }, [history, phoneNumber]);

  const handleOTPChange = (otp: any) => {
    setOTP(otp);
  };

  return (
    <Container maxWidth={false} disableGutters className="full-height">
      <div id="recaptcha-container"></div>
      <Grid container spacing={0} className="full-height">
        <Hidden>
          <Grid item xs={12} sm={6} bgcolor={theme.palette.background.default} className="verification-code">
            <Box
              display="flex"
              flex={1}
              flexDirection="column"
              className="full-height"
            >
              <Box width="80%" m="auto">
                <img src={Illustration} alt="Happy" width="100%" className="Illustration-verification-code" />
              </Box>
            </Box>
          </Grid>
        </Hidden>
        <Grid item sm={6} flex={1}>
          <FormWrapper className="verfication-code-main">
            <Box width="50%" mx="auto" maxWidth={450} className="verfication-code-inner-section">
              <form>
                <Stack direction="column" alignItems="center" spacing={6}>
                  <Box mb={"12px"}>
                    <Logo height={32} />
                  </Box>
                  <>
                    <Typography variant="h3" component="h1" fontWeight={500}>
                      Enter the Verification Code sent to
                    </Typography>
                    <Typography variant="h3" color="primary" component="span">
                      {verificationPhoneNumber}
                    </Typography>
                  </>
                  {error && (
                    <Box mt={4} mb={2}>
                      <Alert variant="outlined" severity="error">
                        {error}
                      </Alert>
                    </Box>
                  )}
                  <FormControl
                    variant="standard"
                    sx={{ width: "100%" }}
                    error={!!error}
                  >
                    <InputLabel shrink htmlFor="bootstrap-input">
                      Verification Code
                    </InputLabel>
                    <Box className="otp-verification">
                      <OtpInput
                        value={OTP}
                        onChange={(otp: any) => handleOTPChange(otp)}
                        numInputs={6}
                        renderInput={(props) => {
                          return <input {...props} className="verification-input" />;
                        }}
                        containerStyle={{
                          marginTop: "32px",
                          justifyContent: "space-between",
                        }}
                        inputStyle={{
                          width: "44px",
                          height: "50px",
                          fontSize: 14,
                          borderRadius: 12,
                          border: "1px solid rgba(0, 0, 0, 0.3)",
                        }}
                        shouldAutoFocus={true}
                      />
                    </Box>
                  </FormControl>
                  <DCButton
                    size="large"
                    variant="contained"
                    type="submit"
                    color="error"
                    fullWidth
                    sx={{ mt: "24px !important", minHeight: 44 }}
                    onClick={(e) => verifyOTPLocal(e)}
                    disabled={OTP.length !== 6}
                  >
                    {buttonIsLoading ? (
                      <CircularProgress size={24} sx={{ color: "white" }} />
                    ) : (
                      "Verify Code & Delete Account"
                    )}
                  </DCButton>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    {resendTimer === 0 ? (
                      <Button
                        fullWidth
                        disableRipple
                        size="large"
                        onClick={() => {
                          reSendOTPLogin();
                          setResendTimer(60);
                        }}
                      >
                        {" "}
                        Resend Verification Code
                      </Button>
                    ) : (
                      <Button size="large" fullWidth disableRipple disabled>
                        {" "}
                        Resend Verification Code in {resendTimer} seconds
                      </Button>
                    )}
                  </Box>
                </Stack>
                <Typography sx={{ textAlign: "center", mt: 8 }} color="error">
                  **Please do not refresh this page.
                </Typography>
              </form>
            </Box>
          </FormWrapper>
        </Grid>
        <Snackbar
          sx={{
            ".MuiSnackbarContent-root": {
              background: theme.palette.error.main,
            },
          }}
          open={openError}
          autoHideDuration={6000}
          message={errMsg}
          onClose={closeErrorMoadal}
          action={action}
        />
      </Grid>
    </Container>
  );
};

export default VerifyMfaDelete;
