import * as React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
function ChevronDownIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <g
        id='Group_13866'
        data-name='Group 13866'
        transform='translate(-430 -584)'
      >
        <rect
          id='Rectangle_17706'
          data-name='Rectangle 17706'
          width='24'
          height='24'
          transform='translate(430 584)'
          fill='none'
        />
        <g
          id='Group_13865'
          data-name='Group 13865'
          transform='translate(433 592)'
        >
          <path
            id='Path_25210'
            data-name='Path 25210'
            d='M20306,1101l9,9,9-9'
            transform='translate(-20306 -1101)'
            fill='none'
            stroke='currentColor'
            strokeLinecap='round'
            strokeWidth='1.2'
          />
        </g>
      </g>
    </SvgIcon>
  );
}
export default ChevronDownIcon;
