import { Stack, Typography, Box, Fade, Grow } from "@mui/material";
import React from "react";
import Folder from "../DashboardGallery/Folder";
import CreateFolder from "../CreateFolder";
import FolderInCreation from "../FolderInCreation";
import { getTimestamp } from "../../utils/dateTimeUtils";
import { useDocumentContext } from "../../contexts/DocumentContext";
import { find } from "lodash";

export interface Props {}

const CustomFolderSection: React.FC<Props> = (props) => {
  const [newFolderChildren, setNewFolderChildren] = React.useState(
    [] as React.ReactNode[]
  );
  const { folders } = useDocumentContext();

  const onCreateFolderClick = React.useCallback(() => {
    const newChild = (
      <FolderInCreation
        key={`new-folder-${getTimestamp()}`}
        setNewFolderChildren={setNewFolderChildren}
      />
    );
    // Add new child
    setNewFolderChildren([newChild]);
  }, []);

  return (
    <Stack direction="column" rowGap={5} className="custom-field-section">
      <Box mb={4}>
        <Typography variant="h4" fontSize={18} fontWeight={500}>
          Custom Folders
        </Typography>
        <Box
          display="grid"
          gridTemplateColumns="repeat(auto-fill,minmax(220px,1fr))"
          alignItems={"baseline"}
        >
          {/* create new folder */}
          <Box
            display="flex"
            alignItems="baseline"
            justifyContent="center"
            px={1}
            pt={5}
            key={"create-folder"}
          >
            <CreateFolder onClick={onCreateFolderClick} />
          </Box>
          <Grow>
            <>{newFolderChildren}</>
          </Grow>
          {folders
            .filter((f) => !!f.custom)
            .map((item, i) => {
              const inFolders = find(folders, (f) => f.title === item.title);
              if (!inFolders) return null;
              return (
                <Fade
                  in={!!item}
                  key={i}
                  {...(!!item ? { timeout: 150 * i + 1 } : {})}
                >
                  <Box
                    display="flex"
                    alignItems="baseline"
                    justifyContent="center"
                    px={1}
                    pt={5}
                    minHeight={196}
                  >
                    <Folder folder={item} />
                  </Box>
                </Fade>
              );
            })}
        </Box>
      </Box>
    </Stack>
  );
};

export default CustomFolderSection;
