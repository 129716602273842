import * as React from "react";
import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";
function RadioUncheckedIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      x
      <g
        id="Group_13871"
        data-name="Group 13871"
        transform="translate(-783 -219)"
      >
        <rect
          id="Rectangle_17708"
          data-name="Rectangle 17708"
          width="24"
          height="24"
          transform="translate(783 219)"
          fill="none"
        />
        <g
          id="Group_13870"
          data-name="Group 13870"
          transform="translate(-8 -1)"
        >
          <path
            id="Path_27336"
            data-name="Path 27336"
            d="M10,0A10,10,0,1,1,0,10,10,10,0,0,1,10,0Z"
            transform="translate(793 222)"
            fill="#8D949D"
          />
          <path
            id="Path_27337"
            data-name="Path 27337"
            d="M9,0A9,9,0,1,1,0,9,9,9,0,0,1,9,0Z"
            transform="translate(794 223)"
            fill="#FFF"
          />
        </g>
      </g>
    </SvgIcon>
  );
}
export default RadioUncheckedIcon;
