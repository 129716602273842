import { Typography, Paper, Divider } from "@mui/material";
import Box from "@mui/material/Box";
import { FC, useMemo } from "react";
import theme from "../../assets/theme/theme";
import CheckIcon from "@mui/icons-material/Check";
import BeenhereOutlinedIcon from "@mui/icons-material/BeenhereOutlined";
import FreeBreakfastOutlinedIcon from "@mui/icons-material/FreeBreakfastOutlined";
import { LoadingButton } from "@mui/lab";

interface Props {
  data: {
    type: "yearly" | "monthly";
    title: string;
    subTitle: string;
    price: string;
    includes: string[];
    includesTitle: string;
  };
  onSelect(type: string): void;
  loading: boolean;
}
export const PaymentCard: FC<Props> = ({ data, onSelect, loading }) => {
  const color = "primary";
  const convertedPrice = useMemo<{ price: string; cent: string }>(() => {
    let [_price, _cent] = data.price.split(".");
    if (!_price) _price = "0";
    if (!_cent) _cent = "00";
    return { price: _price, cent: _cent };
  }, [data.price]);
  return (
    <Paper
    className="payment-cards"
      elevation={24}
      onClick={() => onSelect(data.type)}
      sx={{
        padding: "32px",
        cursor: "pointer",
        display: "flex",
        gap: "32px",
        flexDirection: "row-reverse",
        justifyContent: "center",
        alignItems: "center",
        maxWidth: 900,
      }}
    >
      <Box minWidth={350} className="pay-it-plan-main">
        <Box mb={4} >
          <Box display="flex" alignItems="center">
            {data.type === "yearly" ? (
              <BeenhereOutlinedIcon fontSize="large" color={color} className="beenhere-icon"/>
            ) : (
              <FreeBreakfastOutlinedIcon fontSize="large" color={color} />
            )}
            <Typography variant="h2" fontWeight={500} marginLeft={1} className="Pay-it-plan-text">
              {data.title}
            </Typography>
          </Box>
          <Typography
            variant="subtitle1"
            fontSize={12}
            fontWeight={500}
            color={theme.palette.text.secondary}
          >
            {data.subTitle}
          </Typography>
        </Box>
        <Box display="flex" alignItems="flex-end" className="pay-it-plan-blog">
          <Typography variant="h3" fontSize={64} lineHeight={1} className="pay-it-price">
            ${convertedPrice.price}
          </Typography>
          <Typography variant="h3" fontSize={32} lineHeight={1}>
            .{convertedPrice.cent}
          </Typography>

          <Typography
            variant="subtitle1"
            fontSize={14}
            fontWeight={500}
            color={theme.palette.text.secondary}
            marginLeft={1}
          >
            {data.type === "yearly" ? "annually" : "monthly"}
          </Typography>
        </Box>
        <Box mt={4}>
          <LoadingButton loading={loading} variant="contained" fullWidth>
            Enroll now
          </LoadingButton>
        </Box>
      </Box>
      <Divider orientation="vertical" flexItem />
      <Box>
        <Box>
          <Typography variant="h4" fontSize={14} fontWeight={700}>
            {data.includesTitle}:
          </Typography>
        </Box>
        <Box>
          {data.includes.map((text) => (
            <Box display="flex" alignItems="center" my={2} maxWidth={350}>
              <CheckIcon color={color} />
              <Typography ml={1}>{text}</Typography>
            </Box>
          ))}
        </Box>
      </Box>
    </Paper>
  );
};
