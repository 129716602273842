import { useState } from "react";
import Tooltip from "@mui/material/Tooltip";
import CopyToClipboard from "react-copy-to-clipboard";
import { Typography, Box } from "@mui/material";

export default function DCCopyToClipboard(props: {
  text?: string;
  children?: any;
  icon?: any;
}) {
  const [tooltip, setTooltip] = useState("Copy");
  // const [copyIconStyle, setCopyIconStyle] = useState({
  //   display: 'none'
  // })
  const COPIED = "Copied!";

  function onCopy() {
    setTooltip(COPIED);
  }

  function onMouseEnter() {
    // setCopyIconStyle({
    //   display: 'inline-flex'
    // })
  }

  function onMouseOut() {
    setTooltip("Copy");
    // setCopyIconStyle({
    //   display: 'none'
    // })
  }

  if (!props.text) return <>{props.children}</>;

  return (
    <Typography
      sx={{ cursor: "pointer", verticalAlign: "middle" }}
      component="span"
      onMouseOut={onMouseOut}
      onMouseEnter={onMouseEnter}
    >
      <Tooltip title={tooltip} followCursor arrow placement="top">
        <span>
          <CopyToClipboard text={props.text} onCopy={onCopy}>
            {props.children}
          </CopyToClipboard>
        </span>
      </Tooltip>
      <Box
        component="span"
        sx={{
          verticalAlign: "middle",
        }}
      >
        {props.icon}
      </Box>
      {/* <CopyToClipboard text={props.text} onCopy={onCopy}>
          <IconButton
            disableRipple
            className='copy-to-clipboard'
            aria-label='copy'
            size='small'
            color="primary"
            sx={{
              ...copyIconStyle, ...{
                p: 0,
                pt: '10px',
                pl: 1,
                position: "absolute"
              }
            }}
            >
            {(tooltip === COPIED) ?
              <CheckIcon sx={{ fontSize: 16 }} />
              : <ContentCopyIcon sx={{ fontSize: 16 }} />}
              </IconButton>
            </CopyToClipboard> */}
    </Typography>
  );
}
