import prodAppConfig from "../config/appConfig.json"
import stageAppConfig from "../config/staging.appConfig.json"
import constants from "../constants"


export function getStripeConfig() {
  if (process.env.REACT_APP_ENV === 'stage') {
    return stageAppConfig.stripe
  }
  return prodAppConfig.stripe
}

export function getAppConfig() {
  if (process.env.REACT_APP_ENV === 'stage') {
    return stageAppConfig
  }
  return prodAppConfig
}

export function getFirebaseConfig() {
  if (process.env.REACT_APP_ENV === 'stage') {
    return stageAppConfig.firebase
  }
  return prodAppConfig.firebase
}

export function getMiddleHost() {
  const hosts = {
    prod: prodAppConfig.hosts.middle,
    stage: stageAppConfig.hosts.middle,
    local: "http://localhost:7070"
  }
  
  // if (window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1') {
  //   return hosts.local
  // }

  if (process.env.REACT_APP_ENV === 'stage') {
    return hosts.stage
  }
  return hosts.prod
}

export function getLogOutTimerConfig() {
  const long = {
    logOutAfter: 1000 * 420000,
    showModalAfter: 1000 * 300
  }
  const short = {
    logOutAfter: 1000 * 300 * 2,
    showModalAfter: 1000 * 300
  }

  return window.location.hostname === '127.0.0.1' ? long : short
}

export function getPrivacyPolicyUrl() {
  if (process.env.REACT_APP_ENV === 'stage') {
    return constants.publicAssets.privacyPolicyStage
  }
  return constants.publicAssets.privacyPolicyProd
}

export function getTermsOfServiceUrl() {
  if (process.env.REACT_APP_ENV === 'stage') {
    return constants.publicAssets.termsOfServiceStage
  }
  return constants.publicAssets.termsOfServiceProd
}
