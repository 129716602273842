// import { fromUnixTime } from "date-fns"

import { format } from "date-fns";
import { isValidDateString } from "./utils";

export function getTimestamp(): number {
  return new Date().getTime();
}

export function toUTCDateTime(dd: Date) {
  const _formattedStr = format(dd, "dd LLLL yyyy");
  const formattedStr = _formattedStr + " 00:00 UTC";
  return new Date(formattedStr);
}

export function dateFormat(secs : number) : string{
  if (!secs) return '';
  const milliseconds = secs * 1000;
  const dateObject = new Date(milliseconds);
  const formattedDate = format(dateObject, "yyyy-MM-dd");
  return formattedDate
}

export function toDateTime(secs: number) {
  // return fromUnixTime(secs)
  if (!secs) return null;
  const milliseconds = secs * 1000;
  const dateObject = new Date(milliseconds);
  const utcDate = new Date(
    dateObject.getUTCFullYear(),
    dateObject.getUTCMonth(),
    dateObject.getUTCDate()
  );
  return utcDate;

  // const t = new Date(1970, 0, 1); // Epoch
  // t.setSeconds(secs);
  // return t;
}

export function dateTimeToSeconds(dd: Date): number {
  // Logic: example taken from https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date/toISOString#examples
  // we need a string like 05 October 2011 14:48 UTC
  // format = dd LLLL yyyy
  // let today = new Date('05 October 2011 14:48 UTC')
  // console.log(today.toISOString())  // Returns 2011-10-05T14:48:00.000Z

  const _formattedStr = format(dd, "dd LLLL yyyy");
  const formattedStr = _formattedStr + " 00:00 UTC";
  const utcDate = new Date(formattedStr);
  const dateInMilliSecs = utcDate.getTime();
  const dateInSecs = Math.round(dateInMilliSecs / 1000);
  return dateInSecs;
}

export function parseDate(dateStr: string): Date | null {
  if(isValidDateString(dateStr)) {
    const [year, month, day] = dateStr.split(/-|\//) // split with either - or /
    try {
      const yearInt = parseInt(year)
      const monthInt = parseInt(month) - 1
      const dayInt = parseInt(day)
      return new Date(yearInt, monthInt, dayInt)
    } catch (err) {
      console.error(err)
      return null
    }
  }
  return null
}

