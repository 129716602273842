import * as React from "react";
import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";
function EyeIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        width="24"
        height="24"
      >
        <path
          strokeLinecap="round"
          strokeWidth="1.5"
          d="M22 12C22 14.5 19 20 12 20C5 20 2 14.5 2 12C2 9.5 5 4 12 4C19 4 22 9.5 22 12Z"
          stroke="currentColor"
        />
        <path
          strokeLinecap="round"
          strokeWidth="1.5"
          d="M15 12C15 13.6569 13.6569 15 12 15C10.3431 15 9 13.6569 9 12C9 10.3431 10.3431 9 12 9C13.6569 9 15 10.3431 15 12Z"
          stroke="currentColor"
        />
      </svg>
    </SvgIcon>
  );
}
export default EyeIcon;
