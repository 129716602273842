import React from "react"
import MoveToFolderModal from "../components/MoveToFolderModal"
import { IDocument } from "../firebaseTypes"

export interface IMoveToFolderModalContext {
  openModalState: boolean,
  openModal: () => any,
  closeModal: () => any,
}

const defaultValues: IMoveToFolderModalContext = {
  openModalState: false,
  openModal: () => { },
  closeModal: () => { },
}

const context = React.createContext(defaultValues)

export function useMoveToFolderModalContext() {
  return React.useContext(context)
}

export const MoveToFolderModalProvider: React.FC<{
  document: IDocument
}> = (props) => {
  const [open, setOpen] = React.useState(false)

  const openModal = React.useCallback(() => {
    setOpen(true)
  }, [])

  const closeModal = React.useCallback(() => {
    setOpen(false)
  }, [])

  return <context.Provider value={{
    openModalState: open,
    closeModal,
    openModal
  }}>
    <MoveToFolderModal document={props.document} />
    {props.children}
  </context.Provider>
}