import * as React from "react";
import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";
function ShareIcon(props: SvgIconProps) {
  return (
    <SvgIcon shapeRendering="geometricPrecision" {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        width="24"
        height="24"
      >
        <path
          d="M18,14.25a3.738,3.738,0,0,0-3.2,1.818L9.472,13.4a3.679,3.679,0,0,0,0-2.806L14.8,7.932A3.728,3.728,0,1,0,14.25,6a3.675,3.675,0,0,0,.051.506L8.637,9.338a3.75,3.75,0,1,0,0,5.324L14.3,17.494A3.675,3.675,0,0,0,14.25,18,3.75,3.75,0,1,0,18,14.25Zm0-10.5A2.25,2.25,0,1,1,15.75,6,2.253,2.253,0,0,1,18,3.75ZM6,14.25A2.25,2.25,0,1,1,8.25,12,2.253,2.253,0,0,1,6,14.25Zm12,6A2.25,2.25,0,1,1,20.25,18,2.253,2.253,0,0,1,18,20.25Z"
          fill="currentColor"
        />
      </svg>
    </SvgIcon>
  );
}
export default ShareIcon;
