import * as React from "react";
import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";
function PaperclipIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        width="24"
        height="24"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
          d="M20 5V14.5C20 18.6421 16.6421 22 12.5 22V22C8.35786 22 5 18.6421 5 14.5L5 7C5 4.23858 7.23858 2 10 2V2C12.7614 2 15 4.23858 15 7V14.5C15 15.8807 13.8807 17 12.5 17V17C11.1193 17 10 15.8807 10 14.5V7"
          stroke="currentColor"
        />
      </svg>
    </SvgIcon>
  );
}
export default PaperclipIcon;
