import React from "react";
import { DCBaseCard } from "../../assets/theme/theme";
import { Grid } from "@mui/material";
import { IFolder } from "../../contexts/DocumentContext";
import { filter, isEmpty } from "lodash";
import DashboardCardContents from "./DasboardCardContents";

export interface Props {
  folder: IFolder;
}
const DocumentStackCard: React.FC<Props> = ({ folder }) => {
  const stackableDocs = filter(folder.docs, (doc) => !isEmpty(doc.doc_info));
  if (stackableDocs.length === 0) return null;
  return (
    <Grid
      item
      xl={4}
      lg={6}
      sm={8}
      xs={4}
      position="relative"
      // Hardcoded the breakpoint for improving UI just in 14in Macbook
      sx={{
        "@media screen and (min-width: 1080px)": {
          minWidth: "100%"
        },
      }}
    >
      {stackableDocs.length > 1 ? (
        <DCBaseCard className="is-stacked" sx={{ marginTop: "22px" }}>
          <DashboardCardContents
            stackableDocs={stackableDocs}
            folderTitle={folder.title}
          />
        </DCBaseCard>
      ) : (
        <DCBaseCard sx={{ marginTop: "22px" }}>
          <DashboardCardContents
            stackableDocs={stackableDocs}
            folderTitle={folder.title}
          />
        </DCBaseCard>
      )}
    </Grid>
  );
};

export default DocumentStackCard;
