import { isEmpty } from "lodash"

export const isObject = (obj: any) => {
  return typeof obj === 'object' && obj !== null && !Array.isArray(obj)
}

export const isEmptyStr = (str?: string) => {
  return isEmpty((str || '')?.trim())
}

export function isValidDateString(dateStr: string): boolean {
  var regex = /^[0-9]{4}-[0-9]{2}-[0-9]{2}$/ // => YYYY-MM-DD
  return typeof dateStr === "string" && !isEmpty(dateStr) && regex.test(dateStr) && (new Date(dateStr)).toString() !== "Invalid Date"
}

export function readFile(file: File): Promise<string | ArrayBuffer | null> {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.onload = () => {
      resolve(fileReader.result)
    };
    fileReader.onerror = reject;
    fileReader.readAsDataURL(file);
  });
}

export function storageGetItem(key: string) {
  return sessionStorage.getItem(key);
}

export function storageSetItem(key: string, val: string) {
  try {
    sessionStorage.setItem(key, val);
  } catch (err) {
    sessionStorage.clear();
    sessionStorage.setItem(key, val);
  }
}