import React, { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import theme from "../../assets/theme/theme";
import { useDocumentUploadContext } from "../../contexts/DocumentUploadContext";
import { map } from "lodash";
import { readFile } from "../../utils/utils";

interface Props {
  selectedIndex: number;
  selectedFileType: string;
  onImageClick: (index: number) => void;
  onDeleteImageClick: (index: number) => void;
  imageFiles: File[];
}

const ImageSidebar: React.FC<Props> = ({
  selectedIndex,
  selectedFileType,
  onImageClick,
  onDeleteImageClick,
  imageFiles,
}) => {
  return (
    <>
      {map(imageFiles, (file, i) => {
        const backgroundStyle =
          selectedIndex === i && selectedFileType === "image"
            ? { backgroundColor: "#e2ebff" }
            : {};

        return (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              cursor: "pointer",
              padding: "0.7rem 1rem 0.7rem 1rem",
              "&:hover": { backgroundColor: "#e2ebff" },
              "&:active": { backgroundColor: "#e2ebff" },
              ...backgroundStyle,
              borderBottom: 1,
              borderColor: "grey.100",
            }}
            onClick={() => onImageClick(i)}
          >
            <Thumbnail file={file} />
            <Box>
              <Typography>{file.name}</Typography>
              <Typography
                component="span"
                onClick={() => onDeleteImageClick(i)}
                sx={{
                  textDecoration: "underline",
                  cursor: "pointer",
                  fontSize: "14px",
                  color: "grey.500",
                  "&:hover": { color: theme.palette.primary.main },
                }}
              >
                Delete
              </Typography>
            </Box>
          </Box>
        );
      })}
    </>
  );
};

const Thumbnail = (props: { file: File }) => {
  const [thumbnailSrc, setThumbnailSrc] = useState("");

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    async function init() {
      const result = await readFile(props.file);
      setThumbnailSrc(result as any);
    }
    init();
  }, [props.file]);

  return (
    <img
      src={thumbnailSrc}
      alt=""
      style={{
        width: "48px",
        height: "48px",
        marginRight: "10px",
        borderRadius: "5px",
      }}
    />
  );
};

export default ImageSidebar;
