import React from "react";
import DocumentExpiryModal from "../components/DocumentExpiryModal";
import { useDocumentContext } from "./DocumentContext";
import { ICustomDocument, IDocument } from "../firebaseTypes";
import { getExpiryFieldValue } from "../utils/documentUtils";
import { format } from "date-fns";
import { useCreateCustomCardContext } from "./CreateCustomCardContext";

export interface ICustomDocumentExpiryField {
  doc_id: string;
  doc_title: string;
  expiry_date: {
    seconds: number;
  };
  doc_type: string;
  doc_field_title: string;
  doc_original: ICustomDocument; 
}

export interface IDocumentExpiryModalContext {
  openDocumentExpiryModal: () => any;
  documentsWithExpiry: {
    alreadyExpired: IDocument[];
    notExpired: IDocument[];
  };
  customCardWithExpiry: {
    alreadyExpired: ICustomDocumentExpiryField[];
    notExpired: ICustomDocumentExpiryField[];
  };
}

const defaultValues: IDocumentExpiryModalContext = {
  openDocumentExpiryModal: () => { },
  documentsWithExpiry: {
    alreadyExpired: [],
    notExpired: [],
  },
  customCardWithExpiry: {
    alreadyExpired: [],
    notExpired: [],
  },
};

const context = React.createContext(defaultValues);

export function useDocumentExpiryModal() {
  return React.useContext(context);
}

export const DocumentExpiryModalProvider: React.FC = (props) => {
  const [open, setOpen] = React.useState(false);
  const [documentsWithExpiry, setDocumentsWithExpiry] = React.useState<{
    alreadyExpired: IDocument[];
    notExpired: IDocument[];
  }>(defaultValues.documentsWithExpiry);
  const [customCardWithExpiry, setCustomCardWithExpiry] = React.useState<{
    alreadyExpired: ICustomDocumentExpiryField[];
    notExpired: ICustomDocumentExpiryField[];
  }>(defaultValues.customCardWithExpiry);
  const { documents } = useDocumentContext();
  const { customCardArray } = useCreateCustomCardContext();


  const openDocumentExpiryModal = React.useCallback(() => {
    setOpen(true);
  }, []);

  const onClose = React.useCallback(() => {
    setOpen(false);
  }, []);

  const getDocumentsWithExpiry = React.useCallback(() => {
    let docWithExpiry: IDocument[] = [];
    documents.forEach((doc) => {
      if (getExpiryFieldValue(doc.doc_info)) {
        docWithExpiry.push(doc);
      }
    });

    // divide the array of docWithExpiry into object with two keys - alreadyExpired and notExpired based on todays date and expiry date
    const today = new Date();
    // format the date to yyyy-mm-dd using date-fns
    const formattedToday = format(today, "yyyy-MM-dd");

    const alreadyExpired = docWithExpiry.filter((doc) => {
      return getExpiryFieldValue(doc.doc_info) < formattedToday;
    });
    // sort the alreadyExpired array by expiry date
    alreadyExpired.sort((a, b) => {
      return getExpiryFieldValue(a.doc_info) > getExpiryFieldValue(b.doc_info) ? -1 : 1;
    });

    const notExpired = docWithExpiry.filter((doc) => {
      return getExpiryFieldValue(doc.doc_info) >= formattedToday;
    });
    // sort the notExpired array by expiry date
    notExpired.sort((a, b) => {
      return getExpiryFieldValue(a.doc_info) > getExpiryFieldValue(b.doc_info) ? 1 : -1;
    });

    setDocumentsWithExpiry({
      alreadyExpired,
      notExpired,
    });

  }, [documents]);

  const getCustomCardsWithExpiry = React.useCallback(() => {
    let customDocsWithExpiry: ICustomDocument[] = [];
    customCardArray.forEach((doc) => {
      // if any of the filed in the doc_info has trackingDates === true, doc_info is an array of objects
      if (doc.doc_info && doc.doc_info.length > 0) {
        doc.doc_info.forEach((docInfo) => {
          if (docInfo.trackingDates) {
            customDocsWithExpiry.push(doc);
          }
        });
      }
    });

    // divide the array of docWithExpiry into object with two keys - alreadyExpired and notExpired based on todays date and expiry date
    const formattedToday = new Date().getTime();

    let alreadyExpired: ICustomDocumentExpiryField[] = [];

    customDocsWithExpiry.forEach((doc) => {
      doc.doc_info && doc.doc_info.forEach((docInfo) => {
        if (docInfo.trackingDates) {
          if (docInfo.value.seconds*1000 < formattedToday) {
            alreadyExpired.push({
              doc_id: doc.id!,
              doc_title: doc.doc_title,
              expiry_date: docInfo.value,
              doc_type: doc.doc_type,
              doc_field_title: docInfo.title,
              doc_original: doc,
            });
          }
        }
      });
    });

    // Remove duplicate entries 
    alreadyExpired = alreadyExpired.filter((v, i, a) => a.findIndex(t => (t.doc_title === v.doc_title && t.doc_field_title === v.doc_field_title)) === i);
    // sort the alreadyExpired array by expiry date
    alreadyExpired.sort((a, b) => {
      return a.expiry_date.seconds > b.expiry_date.seconds ? -1 : 1;
    });

    let notExpired: ICustomDocumentExpiryField[] = []; 
    
    customDocsWithExpiry.forEach((doc) => {
      return doc.doc_info && doc.doc_info.forEach((docInfo) => {
        if (docInfo.trackingDates) {
          if (docInfo.value.seconds*1000 >= formattedToday) {
            notExpired.push({
              doc_id: doc.id!,
              doc_title: doc.doc_title,
              expiry_date: docInfo.value,
              doc_type: doc.doc_type,
              doc_field_title: docInfo.title,
              doc_original: doc,
            });
          }
        }
      });
    });

    // Remove duplicate entries
    notExpired = notExpired.filter((v, i, a) => a.findIndex(t => (t.doc_title === v.doc_title && t.doc_field_title === v.doc_field_title)) === i);
    // sort the notExpired array by expiry date
    notExpired.sort((a, b) => {
      return a.expiry_date.seconds > b.expiry_date.seconds ? 1 : -1;
    });

    setCustomCardWithExpiry({
      alreadyExpired,
      notExpired,
    });

  }, [customCardArray]);

  React.useEffect(() => {
    getDocumentsWithExpiry();
    getCustomCardsWithExpiry();
  }, [documents, getCustomCardsWithExpiry, getDocumentsWithExpiry]);

  return (
    <context.Provider
      value={{
        openDocumentExpiryModal,
        documentsWithExpiry,
        customCardWithExpiry,
      }}
    >
      <DocumentExpiryModal
        open={open}
        onClose={onClose}
      />
      {props.children}
    </context.Provider>
  );
};
