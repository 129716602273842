import {
  Typography,
  Link,
  Box,
  Grid,
  FormControlLabel,
  Radio,
  Paper,
} from "@mui/material";
import { get } from "lodash";
import React, { useCallback } from "react";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { usePublicDocumentSharePageContext } from "../../contexts/PublicDocumentSharePageContext";
import InfoIcon from "@mui/icons-material/Info";
import Logo from "../Shared/Logo";
import AccessAlarmOutlinedIcon from "@mui/icons-material/AccessAlarmOutlined";
import NavBar from "./NavBar";
import RadioCheckedIcon from "../Shared/Icons/RadioCheckedIcon";
import RadioUncheckedIcon from "../Shared/Icons/RadioUncheckedIcon";
import GridOrList from "./GridOrList";
import theme from "../../assets/theme/theme";
import constants from "../../constants";
import { addDays, format } from "date-fns";

const leftMargin = "60px";

const DocumentSharePage = () => {
  const {
    shareReq,
    documentInViewIndex,
    setDocumentInViewIndex,
    selectAll,
    deselectAll,
    view,
  } = usePublicDocumentSharePageContext();
  const sharedBy = shareReq?.shared_by.display_name;
  const [isSelectAll, setIsSelectAll] = React.useState(false);

  function _getSharePageBreadCrumbs() {
    const gotoAllDocs = useCallback(() => {
      setDocumentInViewIndex(-1);
    }, []);

    // shareReq has single document, return the name of document
    // if (get(shareReq, "documents.length") === 1) {
    //   const document = shareReq?.documents[0];
    //   return [
    //     <Box sx={{ marginTop: "28px", marginLeft: `${leftMargin}` }}>
    //       <Typography key="1" variant="subtitle2" sx={{ color: "black" }}>
    //         {document?.doc_name}
    //       </Typography>
    //     </Box>,
    //   ];
    // }

    // shareReq has multiple documents and a document is in view, return the full breadcrumb path
    if (documentInViewIndex > -1) {
      const document = get(shareReq, ["documents", documentInViewIndex]);
      return [
        <Box
          sx={{
            marginTop: "28px",
            marginBottom: "16px",
            width: "100%",
          }}
        >
          <Box
            sx={{
              marginLeft: `${leftMargin}`,
              display: "flex",
              alignItems: "center",
            }}
          >
            <Link
              key="1"
              onClick={gotoAllDocs}
              sx={{ textDecoration: "none", cursor: "pointer" }}
              fontWeight={"500"}
              fontSize={"0.9375rem"}
              lineHeight={"1.57"}
              color="text.primary"
            >
              ALL SHARED DOCUMENTS
            </Link>{" "}
            <NavigateNextIcon sx={{ color: "#CAC9C9" }} />
            <Typography
              key="2"
              component="span"
              variant="subtitle2"
              sx={{ color: "black" }}
            >
              {document?.doc_name}
            </Typography>
          </Box>
        </Box>,
      ];
    }

    if (shareReq!.documents.length > 0) {
      // shareReq has multiple documents and no doc is in view, return the all document breadcrumb
      if (documentInViewIndex === -1) {
        return [
          <Box
            sx={{
              marginTop: "28px",
              marginBottom: "16px",
              width: "100%",
            }}
          >
            <Box sx={{ marginLeft: `${leftMargin}` }}>
              <Typography key="1" color="text.primary" fontWeight={"500"}>
                ALL SHARED DOCUMENTS
              </Typography>
            </Box>
          </Box>,
        ];
      }
      // return empty if none of the cases match
      return [];
    }
  }

  const getSharePageBreadCrumbs = useCallback(_getSharePageBreadCrumbs, [
    shareReq,
    documentInViewIndex,
    setDocumentInViewIndex,
  ]);

  const onAllSelectClick = useCallback(() => {
    if (isSelectAll) {
      deselectAll();
      setIsSelectAll(false);
    } else {
      selectAll();
      setIsSelectAll(true);
    }
  }, [deselectAll, selectAll, isSelectAll]);

  return (
    <>
      <Paper
        sx={{
          width: "100%",
          minHeight: "90vh",
          display: "flex",
          flexDirection: "column",
          overflowX: "hidden",
        }}
      >
        <Box
          sx={{
            width: "100%",
            height: "100%",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Box
            sx={{
              width: "100%",
              height: "56px",
              border: `1px solid ${theme.palette.common.border}`,
              marginTop: "40px",
            }}
          >
            <Box
              sx={{
                float: "left",
                marginTop: "15px",
                marginLeft: `${leftMargin}`,
                display: "flex",
                alignItems: "center",
              }}
            >
              <InfoIcon /> &nbsp;
              <Typography variant="subtitle2" component="span">
                All documents, shared by this link are secured by Immplify.
              </Typography>
            </Box>
            <Box
              sx={{
                float: "right",
                marginTop: "15px",
                marginRight: "60px",
                display: "flex",
                alignItems: "center",
              }}
            >
              {" "}
              <Typography variant="subtitle2" component="span">
                The link is valid until &nbsp;
              </Typography>
              <AccessAlarmOutlinedIcon />
              &nbsp;
              <Typography variant="subtitle2" component="span" color="blue">
                {format(addDays(new Date(shareReq?.created_at.seconds * 1000), 5), constants.dateFormat)}
              </Typography>
            </Box>
          </Box>
          <Grid
            container
            sx={{
              width: "100%",
              marginTop: "40px",
            }}
          >
            <Grid item xs={12} md={6}>
              <Box sx={{ marginLeft: `${leftMargin}` }}>
                <Typography
                  variant="h1"
                  sx={{ fontWeight: "bold" }}
                >
                  Shared Documents{" "}
                  <Typography
                    variant="h1"
                    component="span"
                    color="primary"
                    sx={{ fontWeight: "bold", whiteSpace: "nowrap" }}
                  >
                    by {sharedBy}
                  </Typography>
                  <Typography variant="h5" component="span" sx={{ whiteSpace : "nowrap"}}>
                    {" "}on {format(new Date(shareReq?.created_at.seconds * 1000), constants.dateFormat)}
                  </Typography>
                </Typography>
              </Box>
            </Grid>
            {documentInViewIndex === -1 && (
              <Grid item xs={12} md={6}>
                <NavBar />
              </Grid>
            )}
          </Grid>
          {getSharePageBreadCrumbs()}

          {/* Select all */}
          {documentInViewIndex === -1 && view !== "list" && shareReq!.documents.length > 0 && (
            <Box sx={{ width: "100%" }}>
              <Box sx={{ marginLeft: `${leftMargin}` }}>
                <FormControlLabel
                  label={<Typography component="span">Select All</Typography>}
                  control={
                    <Radio
                      checked={isSelectAll}
                      onClick={onAllSelectClick}
                      icon={<RadioUncheckedIcon />}
                      checkedIcon={<RadioCheckedIcon />}
                    />
                  }
                />
              </Box>
            </Box>
          )}

          <GridOrList />
          <Box
            sx={{
              marginTop: "80px",
              marginBottom: "80px",
              justifyContent: "center",
              textAlign: "center",
            }}
          >
            <Logo height={32} />
          </Box>
        </Box>
      </Paper>
    </>
  );
};

export default DocumentSharePage;
