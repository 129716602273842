import React from 'react';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { useState } from 'react';
import { Grid, IconButton } from '@mui/material';
import { Box } from '@mui/system';

export interface IProps {
  totalPages: number;
  handlePageChange: (newPage: number) => any;
  showSinglePage?: boolean; // by default false
}

export default function _Pagination(props: IProps) {
  const [currentPageNumber, setCurrentPageNumber] = useState(1);

  if (props.totalPages === 1 && !props.showSinglePage) {
    return null;
  }

  function handleNext() {
    const next = currentPageNumber + 1;
    if (next <= props.totalPages) {
      setCurrentPageNumber(next);
      props.handlePageChange(next);
    }
  }

  function handlePrev() {
    const prev = currentPageNumber - 1;
    if (prev >= 1) {
      setCurrentPageNumber(prev);
      props.handlePageChange(prev);
    }
  }

  return (
    <Grid container fontSize={14} alignItems='center'>
      <IconButton disabled={currentPageNumber <= 1} onClick={handlePrev}>
        <ArrowBackIosIcon
          sx={{ fontSize: 14, right: -2, position: 'relative' }}
        />
      </IconButton>
      <Box mx={1} minWidth={32} textAlign='center'>
        {currentPageNumber}/{props.totalPages}
      </Box>
      <IconButton
        disabled={currentPageNumber >= props.totalPages}
        onClick={handleNext}
      >
        <ArrowForwardIosIcon sx={{ fontSize: 14 }} />
      </IconButton>
    </Grid>
  );
}
