import React from "react";
import WalkThroughModal from "../components/WalkThroughModal";

export interface IDocumentDeleteModalContext {
  // host will call
  openWalkThroughModal: (duration?: number) => any;
}

const defaultValues: IDocumentDeleteModalContext = {
  openWalkThroughModal: () => {},
};

const context = React.createContext(defaultValues);

export function useWalkThroughModalContext() {
  return React.useContext(context);
}

export const WalkThroughModalProvider: React.FC = (props) => {
  const [open, setOpen] = React.useState(false);

  const onClose = React.useCallback(() => {
    setOpen(false);
  }, []);

  const openWalkThroughModal = React.useCallback((duration = 0) => {
    setTimeout(() => {
      setOpen(true);
    }, duration);
  }, []);

  return (
    <context.Provider
      value={{
        openWalkThroughModal,
      }}
    >
      {/* just change open to open */}
      <WalkThroughModal open={false} handleClose={onClose} />
      {props.children}
    </context.Provider>
  );
};
