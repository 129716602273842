import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Box,
  Container,
  Stack,
  FormLabel,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
  Alert,
} from "@mui/material";
import { isEmpty, map } from "lodash";
import theme, { DcInput } from "../../assets/theme/theme";
import { useCallback, useState } from "react";
import { LoadingButton } from "@mui/lab";
import { rtdb } from "../../firebase";
import { useAuth } from "../../contexts/AuthContext";
import { push, ref, serverTimestamp } from "firebase/database";
import { useUserContext } from "../../contexts/UserContext";

const QUERY_TYPES = ["Immigration", "Taxes (CPA)", "Others"];

export function GetExpertHelp() {
  const [queryType, setQueryType] = useState<string | null>(null);
  const [queryTitle, setQueryTitle] = useState<string | null>(null);
  const [queryDesc, setQueryDesc] = useState<string | null>(null);
  const [contactInfo, setContactInfo] = useState<string | null>(null);
  const [radioValue, setRadioValue] = useState<string | null>(null);
  const [isSubmittingForm, setIsSubmittingForm] = useState(false);
  const [success, setSuccess] = useState(false);
  const [formError, setFormError] = useState<string | null>(null);

  const { currentUser } = useAuth();
  const { user } = useUserContext();

  const clearForm = useCallback(() => {
    setQueryType("");
    setQueryTitle("");
    setQueryDesc("");
    setContactInfo("");
    setRadioValue("");
    setIsSubmittingForm(false);
    setFormError("");
  }, []);

  const handleQueryTypeChange = useCallback(
    (evt) => {
      setQueryType(evt.target.value);
    },
    [setQueryType]
  );

  const handleQueryTitleChange = useCallback(
    (evt) => {
      setQueryTitle(evt.target.value);
    },
    [setQueryTitle]
  );

  const handleQueryDescChange = useCallback(
    (evt) => {
      setQueryDesc(evt.target.value);
    },
    [setQueryDesc]
  );

  const handleContactInfo = useCallback(
    (evt) => {
      setContactInfo(evt.target.value);
    },
    [setContactInfo]
  );

  const handleRadioChange = useCallback((evt) => {
    setRadioValue(evt.target.value);
  }, []);

  const validateForm = useCallback(() => {
    const result =
      !isEmpty(queryType) ||
      !isEmpty(queryTitle) ||
      !isEmpty(queryDesc) ||
      !isEmpty(contactInfo) ||
      !isEmpty(radioValue);

    if (!result) setFormError("Required fields missing!");
    return result;
  }, [contactInfo, queryDesc, queryTitle, queryType, radioValue]);

  const handleSubmit = useCallback(
    async (evt) => {
      try {
        evt.preventDefault();
        if (!validateForm()) return;

        setSuccess(false);
        setIsSubmittingForm(true);
        const refStr = `immigration_queries/${currentUser?.uid}`;
        const immigrationQueriesRef = ref(rtdb, refStr);

        await push(immigrationQueriesRef, {
          formData: {
            queryType,
            queryDesc,
            queryTitle,
            contactInfo,
            radioValue,
          },
          user: {
            email: currentUser?.email,
            firstName: user?.firstName,
            lastName: user?.lastName,
            displayName: currentUser?.displayName,
          },
          created_at: serverTimestamp(),
        });
        setIsSubmittingForm(false);
        setSuccess(true);
        clearForm();
      } catch (err) {
        console.error(err);
        setIsSubmittingForm(false);
        setFormError("Failed to submit form, please retry.");
      }
    },
    [
      clearForm,
      contactInfo,
      currentUser?.displayName,
      currentUser?.email,
      currentUser?.uid,
      queryDesc,
      queryTitle,
      queryType,
      radioValue,
      user?.firstName,
      user?.lastName,
      validateForm,
    ]
  );

  return (
    <Box bgcolor={theme.palette.common.white} sx={{ minHeight: "calc(100%)" }} className="get-expert-model">
      <Container maxWidth={false} disableGutters>
        <Stack
        className="get-expert-form-wrapper"
          sx={{
            display: "flex",
            justifyContent: "center",
            p: 6,
          }}
          spacing={4}
          
        >
          <Typography
            variant="h4"
            fontSize={24}
            fontWeight={600}
            color="primary"
          >
            Get Expert Help Form
            <Typography component="p" color={theme.palette.text.secondary}>
              Immigration & CPA support from our experts.
            </Typography>
          </Typography>

          <Stack
            direction="column"
            spacing={4}
            maxWidth={600}
            component="form"
            onSubmit={handleSubmit}
          >
            <FormControl variant="standard" required className="get-expert-form">
              <InputLabel shrink htmlFor="query-type" className="get-export-form-label">
                What kind of support do you need?
              </InputLabel>
              <Select
                className="get-expert-form-input"
                name="query-type"
                onChange={handleQueryTypeChange}
                inputProps={{
                  name: "query-type",
                  id: "query-type",
                }}
                displayEmpty
                placeholder="Select from the list"
                value={queryType || ""}
                input={<DcInput />}
              >
                <MenuItem key="empty-label-doc-type" disabled value="">
                  <em>Select from the list</em>
                </MenuItem>

                {map(QUERY_TYPES, (f, i) => {
                  return (
                    <MenuItem value={f} key={`${f}-${i}`}>
                      {f}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
            <FormControl variant="standard" required  className="get-expert-form">
              <InputLabel shrink htmlFor="query-title" className="get-export-form-label">
                Describe your problem briefly
              </InputLabel>
              <DcInput
                className="get-expert-form-input"
                name="query-title"
                value={queryTitle}
                required={true}
                onChange={handleQueryTitleChange}
                fullWidth
                placeholder="Your answer"
              />
            </FormControl>
            <FormControl variant="standard" required  className="get-expert-form">
              <InputLabel shrink htmlFor="query-desc"  className="get-export-form-label">
                Describe your situation/problem in detail
              </InputLabel>
              <DcInput
                className="get-expert-form-input"
                name="query-desc"
                value={queryDesc}
                required={true}
                onChange={handleQueryDescChange}
                fullWidth
                placeholder="Your answer"
                multiline
              />
            </FormControl>

            <FormControl variant="standard" required>
              <FormLabel htmlFor="form-radio-btns">
                Urgency of your request
              </FormLabel>
              <RadioGroup
                row
                name="form-radio-btns"
                onChange={handleRadioChange}
                defaultValue={"i-can-wait"}
                value={radioValue}
              >
                <FormControlLabel
                  value="i-can-wait"
                  control={<Radio />}
                  label="I can wait"
                />
                <FormControlLabel
                  value="moderately-urgent"
                  control={<Radio />}
                  label="Moderately urgent"
                />
                <FormControlLabel
                  value="its-urgent"
                  control={<Radio />}
                  label="It's urgent"
                />
              </RadioGroup>
            </FormControl>

            <FormControl variant="standard" required  className="get-expert-form">
              <InputLabel shrink htmlFor="contact-info"  className="get-export-form-label">
                How would you like to be contacted? Please share
              </InputLabel>
              <DcInput
                className="get-expert-form-input"
                name="contact-info"
                value={contactInfo}
                required={true}
                onChange={handleContactInfo}
                fullWidth
                placeholder="Your answer"
                multiline
              />
            </FormControl>
            {success && (
              <Alert variant="filled" severity="success">
                Great news! Your query has been successfully submitted. Our team
                will be in touch with you shortly. Please keep an eye on your
                email for further updates.
              </Alert>
            )}
            {formError && (
              <Alert variant="filled" severity="error">
                {formError}
              </Alert>
            )}
            <LoadingButton
              variant="contained"
              type="submit"
              loading={isSubmittingForm}
            >
              Submit
            </LoadingButton>
          </Stack>
        </Stack>
      </Container>
    </Box>
  );
}
