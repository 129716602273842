import React, { useEffect } from "react";
import Logo from "../Shared/Logo";
import { Box, Paper, Typography } from "@mui/material";
import PhonelinkEraseIcon from "@mui/icons-material/PhonelinkErase";
import theme from "../../assets/theme/theme";
import { logEvent } from "firebase/analytics";
import { analytics } from "../../firebase";
export interface Props {
  pageId: string
}

const PrivateWindowPage: React.FC<Props> = ({ pageId }: Props) => {

  useEffect(() => {
    logEvent(analytics, "private_window_view", {
      page_title: pageId,
    });
  }, [pageId])

  return (
    <Paper
      sx={{
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
        textAlign: "center",
        justifyContent: "center",
        alignItems: "center",
        padding: "0 20px 0 20px",
      }}
    >
      <Box
        sx={{
          width: "100%",
        }}
      >
        <Box>
          <PhonelinkEraseIcon
            sx={{ fontSize: "10rem", color: theme.palette.common.border }}
          />
        </Box>
        <Box
          sx={{
            marginTop: "30px",
            textAlign: "center",
          }}
        >
          <Typography variant="h2" sx={{ fontWeight: "bold" }}>
            Sorry, Private Browsing Mode Not Supported
          </Typography>
        </Box>
        <Box
          sx={{
            marginTop: "10px",
            textAlign: "center",
            maxWidth: "60%",
            mx: "auto"
          }}
        >
          <Typography variant="subtitle1">
            We're sorry, but our app does not currently support private browsing mode. To use our app, please switch to a regular browsing window. <br />
            Private browsing mode restricts certain functionalities that our app relies on to provide you with the best experience. By switching to a regular browsing window, you'll be able to enjoy all the features and capabilities of our app.<br />
            If you need any assistance or have any questions, please don't hesitate to contact our support team.<br />
            Thank you for your understanding.
          </Typography>
        </Box>
        <Box
          sx={{
            marginTop: "60px",
          }}
        >
          <Logo />
        </Box>
      </Box>
    </Paper>
  );
};

export default PrivateWindowPage;
