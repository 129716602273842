import {
  Typography,
  IconButton,
  Collapse,
  Box,
  List,
  ListItemText,
  Divider,
  ListItemButton,
} from "@mui/material";
import { CustomContentProps, useSnackbar, SnackbarContent } from "notistack";
import { forwardRef, useCallback, useState } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CloseIcon from "@mui/icons-material/Close";
import { INotification } from "../../contexts/NotificationsContext";
import theme, { DCButton, DCNotification } from "../../assets/theme/theme";
import { map } from "lodash";
import EditNotificationsIcon from "@mui/icons-material/EditNotifications";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";

export interface ManualEditRequiredCombinedSnackbarProps
  extends CustomContentProps {
  notes: INotification[];
  onNotificationClick: (note: INotification) => any;
}

const ManualEditRequiredCombinedSnackbar = forwardRef<
  HTMLDivElement,
  ManualEditRequiredCombinedSnackbarProps
>(({ id, notes, onNotificationClick }, ref) => {
  const { closeSnackbar } = useSnackbar();
  const [expanded, setExpanded] = useState(false);

  const handleExpandClick = useCallback((evt) => {
    evt.preventDefault();
    setExpanded((oldExpanded) => !oldExpanded);
  }, []);

  const handleDismiss = useCallback(() => {
    closeSnackbar(id);
  }, [id, closeSnackbar]);

  const handleOnNotificationClick = useCallback(
    (id, note) => {
      // closeSnackbar(id);
      setExpanded(false);
      onNotificationClick(note);
    },
    [onNotificationClick]
  );

  return (
    <SnackbarContent ref={ref}>
      <DCNotification 
        className="edit-notification-box"
        sx={{
          minWidth: 550,
        }}
      >
        <ListItemButton
          onClick={handleExpandClick}
          sx={{
            px: 2,
            py: 2,
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            // "&:hover": {
            //   bgcolor: theme.palette.primary.light,
            // },
          }}
        >
          <Box display={"flex"}>
            <EditNotificationsIcon
              sx={{
                color: theme.palette.primary.main,
              }}
            />
            <Typography
              sx={{
                mr: 5,
                ml: 1,
              }}
            >
              Fill in the <strong>missing values</strong> in these documents
            </Typography>
          </Box>
          <Box display="flex" alignSelf={"flex-end"}>
            <IconButton aria-label="Show more" size="small">
              {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </IconButton>
            <IconButton
              aria-label="close snackbar"
              size="small"
              onClick={handleDismiss}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </Box>
        </ListItemButton>
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <List
            dense={true}
            sx={{
              overflow: "auto",
              maxHeight: 270,
              p: 0,
            }}
          >
            {map(notes, (note, index) => {
              return (
                <>
                  <ListItemButton
                    onClick={() => handleOnNotificationClick(id, note)}
                    sx={{
                      px: 3,
                    }}
                  >
                    <ListItemText
                      primary={
                        <Typography
                          component="span"
                          variant={"body2"}
                          flexBasis={166}
                          minWidth={166}
                          color={theme.palette.text.secondary}
                        >
                          {note.doc_type}
                        </Typography>
                      }
                      secondary={<Typography> {note.title}</Typography>}
                    ></ListItemText>
                    <DCButton variant="text">Edit</DCButton>
                  </ListItemButton>

                  {index !== notes.length - 1 && (
                    <Divider variant="middle" component="li" />
                  )}
                </>
              );
            })}
          </List>
        </Collapse>
      </DCNotification>
    </SnackbarContent>
  );
});

ManualEditRequiredCombinedSnackbar.displayName =
  "ManualEditRequiredCombinedSnackbar";

export default ManualEditRequiredCombinedSnackbar;
