import React, { useCallback, useEffect } from "react";
import {
  CardHeader,
  CardContent,
  Collapse,
  CardActions,
  Button,
  Box,
  Tooltip,
  Typography,
} from "@mui/material";
import { get, map, sortBy, take, takeRight } from "lodash";
import { DcDocumentIconButton } from "../../assets/theme/theme";
import DocumentOptionsMenu from "../DocumentOptionsMenu";
import ChevronDownIcon from "../Shared/Icons/ChevronDownIcon";
import ChevronUpIcon from "../Shared/Icons/ChevronUpIcon";
import LockOpenOutlinedIcon from "@mui/icons-material/LockOpenOutlined";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { usePdfPreviewModalContext } from "../../contexts/PdfPreviewModalContext";

import Pagination from "./Pagination";
import { IDocument, IDocumentField } from "../../firebaseTypes";
import { getFieldDisplayValue } from "../../utils/documentUtils";
import DCCopyToClipboard from "../Shared/DCCopyToClipboard";
import theme from "../../assets/theme/theme";
import { useDocumentModalContext } from "../../contexts/DocumentModalContext";
import EyeIcon from "../Shared/Icons/EyeIcon";
import EditIcon from "../Shared/Icons/EditIcon";
import { logEvent } from "firebase/analytics";
import { analytics } from "../../firebase";
import UserIdIcon from "../Shared/Icons/UserIdIcon";
import constants from "../../constants";
import { SensitiveDataMask } from "../Shared/SensitiveDataMask";

interface Props {
  stackableDocs: any;
  folderTitle: String;
}

const DashboardCardContents: React.FC<Props> = ({
  stackableDocs,
  folderTitle,
}) => {
  const { openModalForDocument } = usePdfPreviewModalContext();
  const [expanded, setExpanded] = React.useState(false);
  const { openDocumentModalFor } = useDocumentModalContext();
  const [topDocumentIndex, setTopDocumentIndex] = React.useState(0);
  const [isPageChanged, setIsPageChanged] = React.useState(false);
  const onEditDocument = useCallback(
    (document: IDocument) => {
      openDocumentModalFor([document]);
    },
    [openDocumentModalFor]
  );
  const handlePageChange = useCallback((newPage: number) => {
    setTopDocumentIndex(newPage - 1);
    setIsPageChanged(!isPageChanged);
  }, [isPageChanged]);

  const topDocument = get(stackableDocs, topDocumentIndex);
  const docInfo = sortBy(topDocument.doc_info, (f) => f.priority || 1);
  const first4Fields = take(docInfo || [], 4);
  const emptyFieldsCount = 4 - first4Fields.length;

  const remainingFieldsCount = (docInfo || []).length - 4;
  const remainingFields =
    remainingFieldsCount > 0
      ? takeRight(docInfo || [], remainingFieldsCount)
      : [];

  const handleExpandClick = () => {
    setExpanded(!expanded);
    logEvent(analytics, "card_expand", { expanded });
  };
  const onEditBtnClick = useCallback(() => {
    onEditDocument(topDocument);
    logEvent(analytics, "click_edit", {
      document: JSON.stringify(topDocument),
    });
  }, [onEditDocument, topDocument]);

  const onPreviewBtnClick = useCallback(() => {
    openModalForDocument(topDocument);
    logEvent(analytics, "click_preview", {
      document: JSON.stringify(topDocument),
    });
  }, [openModalForDocument, topDocument]);
  return (
    <>
      <CardHeader
        avatar={<UserIdIcon color="primary" />}
        action={
          <Pagination
            totalPages={stackableDocs.length}
            handlePageChange={handlePageChange}
          />
        }
        title={folderTitle}
      />
      <CardContent>
        <Box display="flex" rowGap={0.5} flexDirection="column">
          {map(first4Fields, (field, i) => (
            <Field key={i} field={field} isPageChanged={isPageChanged} />
          ))}
          {map(new Array(emptyFieldsCount), (x, i) => {
            return <EmptyField key={i} />;
          })}
          {remainingFieldsCount > 0 && (
            <Collapse in={expanded} timeout="auto" unmountOnExit>
              <Box display="flex" rowGap={0.5} flexDirection="column">
                {map(remainingFields, (field, i) => (
                  <Field key={i} field={field} isPageChanged={isPageChanged} />
                ))}
              </Box>
            </Collapse>
          )}
        </Box>
      </CardContent>

      <CardActions>
        <Box
          sx={{
            display: "flex",
            width: "100%",
            justifyContent: "space-between",
          }}
        >
          <Box sx={{ float: "left", marginTop: "5px" }}>
            {remainingFieldsCount > 0 && (
              <Button
                onClick={handleExpandClick}
                variant="contained"
                color="secondary"
                className="showmore-button"
              >
                Show {expanded ? "Less" : `More (${remainingFieldsCount})`}
                {expanded ? (
                  <ChevronUpIcon sx={{ fontSize: 14, marginLeft: 1 }} />
                ) : (
                  <ChevronDownIcon sx={{ fontSize: 14, marginLeft: 1 }} />
                )}
              </Button>
            )}
          </Box>

          <Box sx={{ float: "right" }}>
            <Tooltip arrow title="Preview Document" placement="top">
              <DcDocumentIconButton
                aria-label="Preview Document"
                onClick={onPreviewBtnClick}
              >
                <EyeIcon fontSize="small" />
              </DcDocumentIconButton>
            </Tooltip>
            <Tooltip arrow title="Edit Document" placement="top">
              <DcDocumentIconButton
                aria-label="Edit Document"
                onClick={onEditBtnClick}
              >
                <EditIcon fontSize="small" />
              </DcDocumentIconButton>
            </Tooltip>
            <DocumentOptionsMenu document={topDocument} hideDelete={true} />
          </Box>
        </Box>
      </CardActions>
    </>
  );
};

function EmptyField() {
  return (
    <Box display="flex" alignItems="center">
      <Typography
        component="span"
        variant={"body1"}
        pr={1.5}
        flexBasis={166}
        minWidth={166}
        color={theme.palette.text.secondary}
      >
        &nbsp;
      </Typography>
    </Box>
  );
}

/* CARD FIELDS */
function Field({ field, isPageChanged }: { field: IDocumentField, isPageChanged: boolean }) {
  const value = getFieldDisplayValue(field);

  const [isInfoVisible, setIsInfoVisible] = React.useState(false);
  const sensitiveDataItems = constants.sensitiveDataItems;

  useEffect(() => {
    setIsInfoVisible(false); 
  }, [isPageChanged])
  
  return (
    <Box display="flex" alignItems="center">
      <Typography
        component="span"
        variant={"body1"}
        pr={1.5}
        flexBasis={166}
        minWidth={166}
        color={theme.palette.text.secondary}
      >
        {field.title}:
      </Typography>
      <Typography component="span" variant={"body1"} overflow="hidden">
        <DCCopyToClipboard text={value}>
          <Typography
            component="span"
            color={field.manual ? theme.palette.primary.main : "inherit"}
            display="block"
            noWrap
          >
            {value ? (
              <>
                {sensitiveDataItems.includes(
                  (field?.title || "")?.toLowerCase()?.trim()
                ) && !isInfoVisible ? (
                  <>{<SensitiveDataMask sensitiveValue={value} />} </>
                ) : (
                  value
                )}
              </>
            ) : null}
          </Typography>
        </DCCopyToClipboard>
      </Typography>
      {sensitiveDataItems.includes(
        (field?.title || "")?.toLowerCase()?.trim()
      ) && (
          <>
            {value ? (
              <Box
                sx={{
                  height: 24,
                  width: 24,
                  minHeight: 24,
                  minWidth: 24,
                  borderRadius: 24,
                  ml: 1,
                  background: isInfoVisible
                    ? theme.palette.error.light
                    : theme.palette.success.light,
                  marginLeft: "auto",
                  cursor: "pointer",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                component="span"
              >
                {!isInfoVisible ? (
                  <Tooltip arrow title={`View ${field.title}`} placement="top">
                    <LockOutlinedIcon
                      onClick={() => setIsInfoVisible(!isInfoVisible)}
                      sx={{
                        color: theme.palette.success.main,
                        fontSize: 16,
                      }}
                    />
                  </Tooltip>
                ) : (
                  <Tooltip arrow title={`Hide ${field.title}`} placement="top">
                    <LockOpenOutlinedIcon
                      onClick={() => setIsInfoVisible(!isInfoVisible)}
                      sx={{
                        color: theme.palette.error.main,
                        fontSize: 16,
                      }}
                    />
                  </Tooltip>
                )}
              </Box>) : null}
          </>
        )}
    </Box>
  );
}
export default DashboardCardContents;
