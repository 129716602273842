import { Box } from "@mui/material";
import React, { useEffect } from "react"
import './WatermarkStyle.css'

const WaterMark: React.FC = (props) => {
  const containerRef = React.useRef(null);

  useEffect(() => {
    const container: any = containerRef.current;
    container.dataset.watermark = `${container.dataset.watermark + '     '
      }`.repeat(9999);
  }, []);

  return <Box ref={containerRef} className="watermarked" data-watermark="immplify.com">
    {props.children}
  </Box>
}

export default WaterMark
