import React from "react";
import { Typography } from "@mui/material";
import DCCopyToClipboard from "./DCCopyToClipboard";
import theme from "../../assets/theme/theme";

export interface Props {
  label: React.ReactNode;
  textToCopy?: string;
  fontSize?: number;
  marginBottom?: number;
  isBold?: boolean;
  icon?: React.ReactNode;
}

export const KeyValueItem: React.FC<Props> = (props) => {
  return (
    <Typography
    className="summary-item-main"
      component="p"
      mb={props.marginBottom !== undefined ? props.marginBottom : 1}
    >
      <Typography
        component="span"
        pr={1}
        fontSize={props.fontSize}
        color={theme.palette.text.secondary}
        fontWeight={props.isBold ? 600 : 400}
      >
        {props.label}
      </Typography>
      <DCCopyToClipboard text={props.textToCopy} icon={props.icon}>
        <Typography
          noWrap={false}
          alignItems="center"
          component="span"
          fontSize={props.fontSize}
          fontWeight={props.isBold ? 600 : 400}
        >
          {props.children}
        </Typography>
      </DCCopyToClipboard>
    </Typography>
  );
};
