import { auth } from "../firebase";
import React from "react";
import LogOutTimerModal from "../components/LogOutTimerModal";
import LoggedOutModal from "../components/LogOutTimerModal/LoggedOutModal";
import constants from "../constants";
export interface ILogInTimeContext {
  openModal: boolean;
  setOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
  openLoggedOutModal: boolean;
  setLoggedOutModal: React.Dispatch<React.SetStateAction<boolean>>;
}

const defaultValues: ILogInTimeContext = {
  openModal: false,
  setOpenModal: () => {},
  openLoggedOutModal: false,
  setLoggedOutModal: () => {},
};

const LogOutTimer = React.createContext(defaultValues);

export function useLogOutTimer() {
  return React.useContext(LogOutTimer);
}

const LogOutTimerProvider: React.FC = (props) => {
  const [openModal, setOpenModal] = React.useState(defaultValues.openModal);
  const [openLoggedOutModal, setLoggedOutModal] = React.useState(
    defaultValues.openLoggedOutModal
  );
  // const { pathname } = useLocation();

  let inactivityTime = function () {
    let time: any = 0;
    let timeModal: any = 0;
    window.onload = onLoad;
    window.document.onmousemove = resetTimer;
    window.document.onmousedown = resetTimer; // touchscreen presses
    window.document.ontouchstart = resetTimer;
    window.document.onclick = resetTimer; // touchpad clicks
    window.document.onkeydown = resetTimer;
    window.document.addEventListener("scroll", resetTimer, true);
    window.document.addEventListener("mousemove", resetTimer, false);

    function onLoad() {
      resetTimer();
    }

    function resetTimer() {
      clearTimeout(time);
      clearTimeout(timeModal);
      time = setTimeout(logout, constants.logOutTimer.logOutAfter);
      timeModal = setTimeout(
        openAlertModal,
        constants.logOutTimer.showModalAfter
      );
    }

    function openAlertModal() {
      if (auth.currentUser) {
        setOpenModal(true);
      }
    }

    function logout() {
      try {
        if (auth.currentUser) {
          setLoggedOutModal(true);
          auth.signOut();
          setOpenModal(false);
          console.log("You are now logged out.");
        }
      } catch {
        console.log("you are already signed out!");
      }
    }
  };
  inactivityTime();

  return (
    <LogOutTimer.Provider
      value={{ openModal, setOpenModal, openLoggedOutModal, setLoggedOutModal }}
    >
      <LogOutTimerModal />
      <LoggedOutModal />
      {props.children}
    </LogOutTimer.Provider>
  );
};

export default LogOutTimerProvider;
