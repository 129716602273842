import { Box, Button, Tooltip, Typography } from "@mui/material";
import { FC } from "react";
import AddIcon from "@mui/icons-material/Add";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import TravelEmptyState from "./TravelEmptyState";
import { TraveModal } from "./TraveModal";
import { TravelsTable } from "./TravelsTable";
import { UploadButton } from "../Shared/UploadButton";
import { DCConfirmDialog } from "../Shared/DCConfirmDialog";
import useTravelHistory from "./hooks/TravelHistory.hook";
import { FileRejection } from "react-dropzone";
import { useDocumentUploadContext } from "../../contexts/DocumentUploadContext";
import { useTravelHistoryContext } from "../../contexts/TravelHistoryContext";
import { usePdfPreviewModalContext } from "../../contexts/PdfPreviewModalContext";
import { useUserContext } from "../../contexts/UserContext";
import { Link } from "react-router-dom";
import { Fetching } from "../Shared/Fetching";
import FlightIcon from "@mui/icons-material/Flight";

interface Props {}

const UsaTravelsMain: FC<Props> = () => {
  const {
    loadingStatusUSATravels,
    usaTravels,
    onDeleteRowClick,
    onDeleteAllRowClick,
    onSelectTravelForEdit,
    usaAirportList,
    isModalOpen,
    isDialogOpen,
    selectedTravel,
    isAllDialogOpen,
    selectedRows,
    onCloseAllDialog,
    onDeleteAllUsaTravels,
    onCloseModal,
    onCloseDialog,
    onSaveUsaTravel,
    onDeleteUsaTravel,
  } = useTravelHistory();
  const { createUploadRequests, createUploadRequestsFromRejectedFiles } =
    useDocumentUploadContext();
  const { user } = useUserContext();
  const { openModalForTravelDocument } = usePdfPreviewModalContext();

  const onClickShowFile = () => {
    if (user && user.travelHistoryFormat && user.travelHistoryPath) {
      openModalForTravelDocument({
        doc_format: user.travelHistoryFormat,
        path: user.travelHistoryPath,
      });
    }
  };

  const onUpload = (acceptedFiles: File[], fileRejections: FileRejection[]) => {
    createUploadRequests(acceptedFiles, "documents");
    createUploadRequestsFromRejectedFiles(fileRejections, "documents");
  };

  if (loadingStatusUSATravels === "loading") {
    return (
      <Fetching
        icon={<FlightIcon fontSize="small" color="primary" />}
        message={"Fetching USA Travel History"}
      />
    );
  }
  if (loadingStatusUSATravels === "empty-results") {
    return <TravelEmptyState tab="auto" onUpload={onUpload} />;
  }
  if (loadingStatusUSATravels === "results") {
    return (
      <>
        <TraveModal
          travel={selectedTravel}
          open={isModalOpen}
          airportList={usaAirportList}
          onClose={onCloseModal}
          onSave={onSaveUsaTravel}
        />

        <DCConfirmDialog
          open={isDialogOpen}
          onClose={onCloseDialog}
          onConfirm={() => selectedRows.forEach((s) => onDeleteUsaTravel(s))}
          confirmButtonText="DELETE"
        >
          Do you want to delete
          {selectedRows.length === 1
            ? "the record"
            : selectedRows.length + " records"}
          ?
        </DCConfirmDialog>

        <DCConfirmDialog
          open={isAllDialogOpen}
          onClose={onCloseAllDialog}
          onConfirm={() => onDeleteAllUsaTravels()}
          confirmButtonText="DELETE"
        >
          Do you want to delete <strong>ALL</strong> records?
        </DCConfirmDialog>
        <TravelsTable
          header={() => (
            <Tooltip
              title={
                <Typography>
                  Table below shows the auto populated values from the latest
                  uploaded{" "}
                  <Typography
                    component="span"
                    style={{
                      color: "white",
                      textDecoration: "underline",
                      cursor: "pointer",
                    }}
                    onClick={onClickShowFile}
                  >
                    Travel History
                  </Typography>{" "}
                  document. Click Upload USA Travel History button to update the
                  document.
                  <Link
                    to="/faqs"
                    style={{
                      color: "white",
                      textDecoration: "underline",
                    }}
                  >
                    Need Help?
                  </Link>
                </Typography>
              }
            >
              <InfoOutlinedIcon
                color="primary"
                sx={{ mt: 0.5, ml: 1 }}
                fontSize="small"
              />
            </Tooltip>
          )}
          travelRecords={usaTravels}
          onEditClick={onSelectTravelForEdit}
          onDeleteClick={onDeleteRowClick}
          onDeleteAllClick={onDeleteAllRowClick}
        />
      </>
    );
  }
  return null;
};

export const UsaTravelsTab: FC<Props> = () => {
  const { onOpenModal } = useTravelHistory();
  const { createUploadRequests, createUploadRequestsFromRejectedFiles } =
    useDocumentUploadContext();
  const { user } = useUserContext();

  const onUploadI94 = (
    acceptedFiles: File[],
    fileRejections: FileRejection[]
  ) => {
    createUploadRequests(acceptedFiles, "documents");
    createUploadRequestsFromRejectedFiles(fileRejections, "documents");
  };

  const onUpload = (acceptedFiles: File[], fileRejections: FileRejection[]) => {
    createUploadRequests(acceptedFiles, "documents");
    createUploadRequestsFromRejectedFiles(fileRejections, "documents");
  };

  return (
    <Box>
      <Box
        display="flex"
        justifyContent="flex-end"
        gap={2}
        position="absolute"
        top={0}
        right={0}
      >
        <UploadButton uploadOptions={{ onDrop: onUploadI94 }}>
          Upload I94
        </UploadButton>
        <Tooltip
          title={
            <Box display="flex" alignItems="center">
              <OpenInNewIcon sx={{ mr: 2 }} fontSize="small" />
              <Typography>
                <a
                  href="https://i94.cbp.dhs.gov/I94/#/history-search"
                  target="_blank"
                  style={{ color: "white" }}
                  rel="noreferrer"
                >
                  Download
                </a>{" "}
                your recent Travel History.
              </Typography>
            </Box>
          }
        >
          <span>
            <UploadButton
              uploadOptions={{
                onDrop: onUpload,
                accept: {
                  "application/pdf": [".pdf"],
                },
              }}
            >
              {user?.travelHistoryPath ? "Update" : "Upload"} USA Travel History
            </UploadButton>
          </span>
        </Tooltip>
        <Button
          variant="contained"
          color="primary"
          startIcon={<AddIcon />}
          onClick={onOpenModal}
        >
          Add USA Travel Log
        </Button>
      </Box>

      <UsaTravelsMain />
    </Box>
  );
};
