import { Snackbar } from "@mui/material";
import { doc, setDoc, serverTimestamp, collection } from "firebase/firestore";
import { isEmpty, map } from "lodash";
import React, { useCallback } from "react";
import ShareDocumentsModal from "../components/ShareDocumentsModal";
import constants from "../constants";
import { firestore } from "../firebase";
import { IDocument, ISharePermissions, IShareReq } from "../firebaseTypes";
import Grow from "@mui/material/Grow";
import { useUserContext } from "./UserContext";
import theme from "../assets/theme/theme";
import { getDocName } from "../utils/documentUtils";

export interface IDocumentShareModalContext {
  documentsToShare: IDocument[];
  openShareModalState: boolean;
  shareDocuments: (
    emails: string[],
    permission: ISharePermissions
  ) => Promise<void>;
  openShareModalFor: (doc: IDocument[]) => void;
  closeShareModal: (success?: boolean) => void;
}

const defaultValues: IDocumentShareModalContext = {
  documentsToShare: [],
  openShareModalState: false,
  shareDocuments: async () => {},
  openShareModalFor: () => {},
  closeShareModal: () => {},
};

const DocumentShareModalContext =
  React.createContext<IDocumentShareModalContext>(defaultValues);

export function useDocumentShareModalContext() {
  return React.useContext(DocumentShareModalContext);
}

export const DocumentShareModalProvider: React.FC = (props) => {
  const { user } = useUserContext();
  const [documentsToShare, setDocumentsToShare] = React.useState(
    defaultValues.documentsToShare
  );
  const [openShareModalState, setOpenShareModal] = React.useState(
    defaultValues.openShareModalState
  );
  const [openSnackBar, setOpenSnackBar] = React.useState(false);

  const closeShareModal = React.useCallback((success) => {
    if (success) {
      setOpenSnackBar(true);
    }
    setDocumentsToShare(defaultValues.documentsToShare);
    setOpenShareModal(false);
  }, []);

  const openShareModalFor = React.useCallback((docs: IDocument[]) => {
    setDocumentsToShare(docs);
    setOpenShareModal(true);
  }, []);

  const shareDocuments = React.useCallback(
    async (emails: string[], permission: ISharePermissions) => {
      if (!isEmpty(emails) && !isEmpty(documentsToShare)) {
        try {
          const _collection = collection(
            firestore,
            constants.collections.docShareRequest
          );
          const docRef = doc(_collection);
          const documents = map(documentsToShare, (doc) => ({
            id: doc.id,
            uid: doc.uid,
            doc_name: getDocName(doc),
            doc_type: doc.doc_type,
            doc_format: doc.doc_format,
            doc_path: doc.doc_path,
            doc_thumbnail_path: doc.doc_thumbnail_path,
          }));

          await setDoc(
            docRef,
            {
              documents,
              emails,
              status: "pending",
              created_at: serverTimestamp(),
              shared_by: {
                display_name: user?.firstName + " " + user?.lastName,
              },
              permission,
              redirect_host: window.location.origin,
            } as IShareReq,
            { merge: false }
          );
          console.log("todo:pooja:", docRef, "share");
        } catch (err) {
          console.error(err);
        } finally {
        }
      }
    },
    [documentsToShare, user?.firstName, user?.lastName]
  );

  const handleSnackbarClose = useCallback(() => {
    setOpenSnackBar(false);
  }, []);

  return (
    <DocumentShareModalContext.Provider
      value={{
        documentsToShare,
        openShareModalState,
        shareDocuments,
        openShareModalFor,
        closeShareModal,
      }}
    >
      <ShareDocumentsModal />
      {props.children}
      <Snackbar
        sx={{
          ".MuiSnackbarContent-root": {
            background: theme.palette.primary.main,
          },
        }}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        open={openSnackBar}
        TransitionComponent={Grow}
        message={`Successfully shared documents!`}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
      />
    </DocumentShareModalContext.Provider>
  );
};
