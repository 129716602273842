import * as React from "react";
import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

export default function DocumentShield(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        width="24"
        height="24"
      >
        <path
          d="M17,9a2.652,2.652,0,0,1-3-3V3H8A2.652,2.652,0,0,0,5,6V18a2.652,2.652,0,0,0,3,3h9a2.652,2.652,0,0,0,3-3V9Z"
          opacity=".4"
          fill="currentColor"
        />
        <path d="M17,9h3L14,3V6A2.652,2.652,0,0,0,17,9Z" fill="currentColor" />
        <path
          d="M15.5,12.116A12.584,12.584,0,0,1,12.506,11,15.248,15.248,0,0,1,9.5,12.167v1.944A4.206,4.206,0,0,0,12.5,18a4.206,4.206,0,0,0,3-3.889Z"
          fill="currentColor"
        />
      </svg>
    </SvgIcon>
  );
}
