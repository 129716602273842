import {
  Alert,
  Button,
  Container,
  Grid,
  Hidden,
  Typography,
} from '@mui/material';
import { Box } from '@mui/system';

import React, { useState } from 'react';
import { Redirect } from 'react-router-dom';
import Illustration from '../../assets/images/illustrations/auth_illustration.svg';
import { FormWrapper } from '../../assets/theme/theme';
import { useAuth } from '../../contexts/AuthContext';
import Logo from '../Shared/Logo';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

export interface Props { }
const EmailVerification: React.FC<Props> = (props) => {
  const [btnTxt, setBtnTxt] = useState("Send verification link")
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState("")
  const [success, setSuccess] = useState(false)

  const { currentUser, sendEmailVerificationToUser } = useAuth()

  function checkDisabled() {
    return loading
  }

  async function sendEmail() {
    try {
      setLoading(true)
      setError("")
      await sendEmailVerificationToUser()
      setSuccess(true)
      setBtnTxt("Re-send link")
    } catch (error: any) {
      setError(error.message)
      setSuccess(false)
    } finally {
      setLoading(false)
    }
  }

  if (!currentUser) {
    return <Redirect to='/' />;
  }

  if (currentUser && currentUser.emailVerified) {
    return <Redirect to='/' />;
  }

  return (
    <Container maxWidth={false} disableGutters className='full-height'>
      <Grid container spacing={0} className='full-height'>
        <Hidden mdDown>
          <Grid item xs={12} sm={7}>
            <Box
              display='flex'
              flex={1}
              flexDirection='column'
              className='full-height'
            >
              <Box mt={6} ml={8}>
                <Logo />
              </Box>
              <Box width='80%' m='auto'>
                <img src={Illustration} alt='Happy' width='100%' />
              </Box>
            </Box>
          </Grid>
        </Hidden>
        <Grid item sm={12} md={5} flex={1}>
          <FormWrapper>
            <Box width='70%' mx='auto'>
              <Hidden mdUp>
                <Box p={8} textAlign='center'>
                  <Logo height={50} />
                </Box>
              </Hidden>
              <Grid container spacing={0} justifyContent='space-between'>
                <Grid item>
                  <Typography variant='h3' component='h1' fontWeight={500}>
                    Verify Email
                  </Typography>
                </Grid>
              </Grid>
              <Box my={4}>
                Please click on the button to send verification link to your email id.
              </Box>
              <Box mt={4} mb={2}>
                <Button
                  variant="contained"
                  color="primary"
                  disabled={checkDisabled()}
                  onClick={sendEmail}
                  endIcon={<ArrowForwardIcon />}>
                  {btnTxt}
                </Button>
              </Box>
              {success && <Alert variant="outlined" severity="success">Sent successfully!</Alert>}
              {error && <Alert variant="outlined" severity="error">{error}</Alert>}
            </Box>
          </FormWrapper>
        </Grid>
      </Grid>
    </Container>
  );
};

export default EmailVerification;
