import React from 'react';

export interface Props {
  pageId: string
}

const LogInFlowLayout: React.FC<Props> = (props) => {
  return <>{props.children}</>;
};

export default LogInFlowLayout;
