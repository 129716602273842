import * as React from "react";
import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";
function TimesIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        width="24"
        height="24"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M6 18L18 6M6 6L18 18"
          stroke="currentColor"
        />
      </svg>
    </SvgIcon>
  );
}
export default TimesIcon;
