import React from "react"

export interface ISidebarContext {
  open: boolean,
  toggleSidebar: () => any
}

const defaultValues: ISidebarContext = {
  open: false,
  toggleSidebar: () => { }
}

const context = React.createContext(defaultValues)

export function useSidebarContext() {
  return React.useContext(context)
}

export const SidebarProvider: React.FC = (props) => {
  const [open, setOpen] = React.useState(true)

  const toggleSidebar = React.useCallback(() => {
    setOpen(prev => !prev)
  }, [])

  return <context.Provider value={{
    open,
    toggleSidebar
  }}>
    {props.children}
  </context.Provider>
}