import { Box, LinearProgress, Typography } from "@mui/material"
import React from "react"

export const Fetching: React.FC<{
  message: string,
  icon?: React.ReactNode
}> = ({
  message,
  icon
}) => {
    return <Box sx={{
      display: "flex",
      alignItems: "center",
      gap: 2,
      marginTop: "75px",
      mb: "75px",
      flexDirection: "column",
    }}>

      {icon}
      <Box sx={{ width: '100px' }}>
        <LinearProgress />
      </Box>
      <Typography fontSize={25} variant="h1">
        {message}
      </Typography>
    </Box>
  }