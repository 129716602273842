import React, { CSSProperties, FC } from "react";
// @ts-ignore
import Flags from "country-flag-icons/react/3x2";
// @ts-ignore
import { hasFlag } from "country-flag-icons";

interface Props {
  countryCode: string;
  style?: CSSProperties;
}
export const DCFlag: FC<Props> = ({ countryCode, style }) => {
  const FlagComponent = Flags[countryCode];
  return hasFlag(countryCode) ? <FlagComponent style={style} /> : null;
};
