import { AppBar, Toolbar, Box } from "@mui/material";
import React from "react";
import theme from "../../assets/theme/theme";
import Logo from "../Shared/Logo";

export interface Props {}

const AppBarV2: React.FC<Props> = (props) => {
  return (
    <AppBar
      enableColorOnDark
      position="sticky"
      color="inherit"
      elevation={0}
      sx={{
        bgcolor: theme.palette.background.default,
        boxShadow: "none",
        background: theme.palette.common.white,
        borderBottom: `1px solid ${theme.palette.common.border}`,
      }}
    >
      <Toolbar disableGutters>
        <Box
          sx={{
            flexBasis: 240,
            height: "64px",
            alignItems: "center",
            paddingInline: "18px",
            display: "flex",
            justifyContent: "center",
            paddingLeft: "12px",
            [theme.breakpoints.down("md")]: {
              flexBasis: "auto",
              height: 18,
              borderRight: "none",
            },
          }}
        >
          <Logo height={33} />
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default AppBarV2;
