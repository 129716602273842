import { Typography } from '@mui/material'
import constants from '../../constants'
import theme from '../../assets/theme/theme'

const maxLimit = constants.inputText.maxInputLimit

export const maxLimitMsg = () => {
    return (
        <Typography sx={{ color: theme.palette.error.main, fontSize: '0.8rem' }}>
            Max Characters: {maxLimit}
        </Typography>
    )
}
export const forbidSpecialCharMsg = () => {
    return (
        <Typography sx={{ color: theme.palette.error.main, fontSize: '0.8rem' }}>
            Special characters aren't allowed
        </Typography>
    )
} 