import React from "react";
import { useAuth } from "../../contexts/AuthContext";
import constants from "../../constants";
import { postData } from "../../utils/fetchUtils";
import { Stack, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { DCButton } from "../../assets/theme/theme";

export function ActiveSubscription() {
  const { currentUser } = useAuth();
  const [loadingManageBilling, setLoadingManageBilling] = React.useState(false);

  const onClickManageBillingInfo = React.useCallback(async () => {
    if (!currentUser?.uid) return;
    try {
      setLoadingManageBilling(true);
      const url = `${constants.middleHost}/stripe/create-customer-portal-session`;
      const data = {
        uid: currentUser?.uid,
      };
      const token = await currentUser?.getIdToken();
      const headers = {
        Authorization: `Bearer ${token}`,
      };
      const res = await postData(url, data, headers);
      setLoadingManageBilling(false);
      window.open(res.data.url, "_blank");
      window.close();
    } catch (error: any) {
      console.error(error);
      setLoadingManageBilling(false);
    }
  }, [currentUser]);

  return (
    <Stack
      margin={8}
      direction="column"
      justifyContent="center"
      alignItems="center"
      spacing={2}
      textAlign={"center"}
    >
      <Typography variant="h1" component="h1">
        🎉 You're a premium member of immplify family.
      </Typography>
      <Typography variant="h3" component="h3" fontSize={18}>
        To make changes to your subscription or update your billing information,
        please click the "Manage Billing Information" link below.
      </Typography>

      <LoadingButton
        aria-label="manage billing info here"
        variant="text"
        color="primary"
        loading={loadingManageBilling}
        onClick={onClickManageBillingInfo}
      >
        Manage Billing Info here
      </LoadingButton>
    </Stack>
  );
}
