import React, { useCallback, useEffect, useState } from "react";
import { DCTable } from "../Shared/DCTable";
import { ITravelHistoryExtended } from "../../firebaseTypes";
import { Box, Chip, Typography } from "@mui/material";
import { differenceInDays, format } from "date-fns";
import { useTravelHistoryContextNew } from "./TravelHistoryContextNew";
import { useEditTravelHistoryModalNew } from "../../contexts/EditTravelHistoryModalContextNew";
import theme, { DCPaper } from "../../assets/theme/theme";
import { KeyValueItem } from "../Shared/KeyValueItem";
import {
  ITravelFilterSummary,
  TravelHistoryTableFilterNew,
} from "./TravelHistoryTableFilterNew";
import {
  useDeleteTravelHistoryModalContextNew,
} from "../../contexts/DeleteTravelHistoryModalContextNew";
import DeleteIcon from "../Shared/Icons/DeleteIcon";
import { filter, find } from "lodash";
import Flags from "country-flag-icons/react/3x2";
import LoadingButton from "@mui/lab/LoadingButton";
import { Fetching } from "../Shared/Fetching";
import FlightIcon from "@mui/icons-material/Flight";
import TravelEmptyStateNew from "./TravelEmptyStateNew";
import { useDocumentUploadContext } from "../../contexts/DocumentUploadContext";
import { FileRejection } from "react-dropzone";
import { useUpdateCurrentLocationModal } from "../../contexts/UpdateCurrentLocationModalContext";

const MissingValue: React.FC = () => {
  return <>---</>;
};

const CurrentlyInTheCountry: React.FC = () => {
  return <Box>Currently in the country</Box>;
};

export interface Props {
  travelState: "usa" | "world";
}

const UsaTravelsTableNew = () => {
  const { uploadingUSATravelHistory } = useDocumentUploadContext();
  const { createUploadRequests, createUploadRequestsFromRejectedFiles } =
    useDocumentUploadContext();
  const { usaTravelRecords, loadingStatusUSATravels } =
    useTravelHistoryContextNew();
  const { handleUpdateCurrentLocation } = useUpdateCurrentLocationModal();
  const [filterSummaryTotal, setFilterSummaryTotal] =
    useState<ITravelFilterSummary | null>(null);
  const [filteredTravels, setFilteredTravels] = useState<
    ITravelHistoryExtended[]
  >([]);
  const [filterSummary, setFilterSummary] = useState<ITravelFilterSummary[]>(
    []
  );
  const { selectedRowData, setSelectedRowData, openDeleteTravelHistoryModal } =
    useDeleteTravelHistoryModalContextNew();
  const { openEditTravelHistoryModalFor } = useEditTravelHistoryModalNew();
  const onFilter = useCallback(
    (data: ITravelHistoryExtended[], summary: ITravelFilterSummary[]) => {
      setFilteredTravels(data);
      setFilterSummary(summary);
      setFilterSummaryTotal(
        summary.length
          ? {
              year: "Total Days",
              value: summary.reduce((p, c) => p + (c.value || 0), 0),
            }
          : null
      );

      // clear selectedRowData on filter apply
      setSelectedRowData((prev) => ({
        ...prev,
        selectedRowsUsa: [],
        allSelectedUsa: false,
        deleteAllUsaRecords: false,
      }));
    },
    [setSelectedRowData]
  );

  useEffect(() => {
    handleUpdateCurrentLocation(usaTravelRecords);
  }, [handleUpdateCurrentLocation, usaTravelRecords]);

  const onUpload = (acceptedFiles: File[], fileRejections: FileRejection[]) => {
    createUploadRequests(acceptedFiles, "documents");
    createUploadRequestsFromRejectedFiles(fileRejections, "documents");
  };

  if (loadingStatusUSATravels === "loading" || uploadingUSATravelHistory) {
    return (
      <Fetching
        icon={<FlightIcon fontSize="small" color="primary" />}
        message={"Fetching USA Travel History"}
      />
    );
  }

  if (loadingStatusUSATravels === "empty-results") {
    return <TravelEmptyStateNew tab="usa" onUpload={onUpload} />;
  }

  return (
    <DCPaper
      className="travels-heading"
      sx={{
        // border: "2px solid red",
        pb: 3,
      }}
    >
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb={2}
        px={3}
        pt={2}
      >
        <Box display="flex" alignItems="center">
          <Typography variant="h4" fontSize={20} fontWeight={500}>
            USA Travels
            <Flags.US
              title="United States"
              style={{
                height: 12,
                marginLeft: 8,
              }}
            />
          </Typography>
        </Box>
        <Box
          display="flex"
          columnGap={3}
          alignItems="right"
          justifyContent={"right"}
        >
          <Box
            display="flex"
            flexWrap={"wrap"}
            columnGap={1}
            rowGap={1}
            alignItems="center"
            justifyContent={"right"}
          >
            {filterSummary.map((f) => (
              <Chip
                key={f.year}
                color="info"
                variant="outlined"
                sx={{ backgroundColor: theme.palette.primary.light }}
                label={
                  <KeyValueItem label={`${f.year}:`} marginBottom={0}>
                    {f.value || "NA"}
                  </KeyValueItem>
                }
              />
            ))}
            {filterSummaryTotal && (
              <Chip
                key={filterSummaryTotal.year}
                color="warning"
                sx={{ backgroundColor: theme.palette.primary.main }}
                label={
                  <KeyValueItem
                    label={
                      <Typography component={"span"} sx={{ color: "white" }}>
                        {filterSummaryTotal.year} :
                      </Typography>
                    }
                    marginBottom={0}
                  >
                    {filterSummaryTotal.value}
                  </KeyValueItem>
                }
              />
            )}
          </Box>
          <TravelHistoryTableFilterNew
            travels={usaTravelRecords}
            onFilter={onFilter}
          />
          {selectedRowData.selectedRowsUsa.length > 0 && (
            <LoadingButton
              onClick={openDeleteTravelHistoryModal}
              variant="outlined"
              size="medium"
              color="error"
              startIcon={<DeleteIcon />}
            >
              Delete
            </LoadingButton>
          )}
        </Box>
      </Box>
      <DCTable
        initialOrder={{ order: "desc", orderBy: "arrival_date_parsed" }}
        columns={[
          {
            id: "arrival_loc_label",
            title: "Port Of Entry",
            children: (row: ITravelHistoryExtended) => (
              <Box>{row.arrival_loc_label || <MissingValue />}</Box>
            ),
          },
          {
            id: "departure_loc_label",
            title: "Port Of Exit",
            children: (row: ITravelHistoryExtended) => (
              <Box>{row.departure_loc_label || <MissingValue />}</Box>
            ),
          },
          {
            id: "arrival_date",
            title: "Arrival Date",
            children: (row: ITravelHistoryExtended) => (
              <Box>
                {row.arrival_date_parsed ? (
                  format(row.arrival_date_parsed, "MM-dd-yyyy")
                ) : (
                  <MissingValue />
                )}
              </Box>
            ),
          },
          {
            id: "departure_date",
            title: "Departure Date",
            children: (row: ITravelHistoryExtended) =>
              row.isPresent ? (
                <CurrentlyInTheCountry />
              ) : (
                <Box>
                  {row.departure_date_parsed ? (
                    format(row.departure_date_parsed, "MM-dd-yyyy")
                  ) : (
                    <MissingValue />
                  )}
                </Box>
              ),
          },
          {
            id: "day_count",
            title: "Day Count",
            children: (row: ITravelHistoryExtended) =>
              row.isPresent ? (
                <Box>
                  {row.arrival_date_parsed ? (
                    <>
                      {differenceInDays(new Date(), row.arrival_date_parsed)}+
                    </>
                  ) : (
                    <MissingValue />
                  )}
                </Box>
              ) : (
                <Box>
                  {row.departure_date_parsed && row.arrival_date_parsed ? (
                    differenceInDays(
                      row.departure_date_parsed,
                      row.arrival_date_parsed
                    ) + 1
                  ) : (
                    <MissingValue />
                  )}
                </Box>
              ),
          },
          {
            id: "visa_status",
            title: "Visa Type",
            children: (row: ITravelHistoryExtended) => (
              <Box>{row.visa_status || <MissingValue />}</Box>
            ),
          },
          {
            id: "type",
            title: "Type",
            children: (row: ITravelHistoryExtended) => (
              <Box>{row.automated ? "Auto" : "Manual"}</Box>
            ),
          },
        ]}
        rowStyle={(t: ITravelHistoryExtended) =>
          t.automated ? { backgroundColor: "rgba(69, 93, 238, 0.08)" } : {}
        }
        data={filteredTravels}
        disablePagination={true}
        onEditClick={openEditTravelHistoryModalFor}
        hasCheckbox={true}
        allSelected={selectedRowData.allSelectedUsa}
        selectedRows={selectedRowData.selectedRowsUsa}
        onSelectRow={(l, a) => {
          const isFilterApplied =
            filteredTravels.length < usaTravelRecords.length;

          if (isFilterApplied) {
            const selectedRowsUsa: string[] = filter(
              l,
              (id) => !!find(filteredTravels, (r) => r.id === id)
            );
            setSelectedRowData((prev) => ({
              ...prev,
              selectedRowsUsa,
              deleteAllUsaRecords: false,
              allSelectedUsa: a,
            }));
          } else {
            const selectedRowsUsa: string[] = filter(
              l,
              (id) => !!find(usaTravelRecords, (r) => r.id === id)
            );
            setSelectedRowData((prev) => ({
              ...prev,
              selectedRowsUsa,
              allSelectedUsa: a,
              deleteAllUsaRecords: a,
            }));
          }
        }}
      />
    </DCPaper>
  );
};

export const WorldTravelsTableNew = () => {
  const { worldTravelRecords, loadingStatusWorldTravels } =
    useTravelHistoryContextNew();
  const [filterSummaryTotal, setFilterSummaryTotal] =
    useState<ITravelFilterSummary | null>(null);
  const [filteredTravels, setFilteredTravels] = useState<
    ITravelHistoryExtended[]
  >([]);
  const [filterSummary, setFilterSummary] = useState<ITravelFilterSummary[]>(
    []
  );
  const { selectedRowData, setSelectedRowData, openDeleteTravelHistoryModal } =
    useDeleteTravelHistoryModalContextNew();
  const { openEditTravelHistoryModalFor } = useEditTravelHistoryModalNew();

  const onFilter = useCallback(
    (data: ITravelHistoryExtended[], summary: ITravelFilterSummary[]) => {
      setFilteredTravels(data);
      setFilterSummary(summary);
      setFilterSummaryTotal(
        summary.length
          ? {
              year: "Total Days",
              value: summary.reduce((p, c) => p + c.value, 0),
            }
          : null
      );
      // clear selectedRowData on filter apply
      setSelectedRowData((prev) => ({
        ...prev,
        selectedRowsWorld: [],
        allSelectedWorld: false,
        deleteAllWorldRecords: false,
      }));
    },
    [setSelectedRowData]
  );

  if (loadingStatusWorldTravels === "empty-results") {
    return <TravelEmptyStateNew tab="world" />;
  }

  if (loadingStatusWorldTravels === "loading") {
    return (
      <Fetching
        icon={<FlightIcon fontSize="small" color="primary" />}
        message={"Fetching World Travel History"}
      />
    );
  }

  return (
    <DCPaper
      sx={{
        // border: "2px solid red",
        pb: 3,
      }}
    >
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb={2}
        px={3}
        pt={2}
      >
        <Box display="flex" alignItems="center">
          <Typography variant="h4" fontSize={20} fontWeight={500}>
            World Travels
            {/* <GlobeIcon
              fontSize="inherit"
              sx={{
                ml: 1,
              }}
            /> */}
          </Typography>
          {/* {header && header()} */}
        </Box>
        <Box
          display="flex"
          columnGap={3}
          alignItems="right"
          justifyContent={"right"}
        >
          <Box
            display="flex"
            flexWrap={"wrap"}
            columnGap={1}
            rowGap={1}
            alignItems="center"
            justifyContent={"right"}
          >
            {filterSummary.map((f) => (
              <Chip
                key={f.year}
                color="info"
                variant="outlined"
                label={
                  <KeyValueItem label={`${f.year}:`} marginBottom={0}>
                    {f.value}
                  </KeyValueItem>
                }
              />
            ))}
            {filterSummaryTotal && (
              <Chip
                key={filterSummaryTotal.year}
                color="warning"
                variant="outlined"
                label={
                  <KeyValueItem
                    label={`${filterSummaryTotal.year}:`}
                    marginBottom={0}
                  >
                    {filterSummaryTotal.value}
                  </KeyValueItem>
                }
              />
            )}
          </Box>
          <TravelHistoryTableFilterNew
            travels={worldTravelRecords}
            onFilter={onFilter}
          />
          {selectedRowData.selectedRowsWorld.length > 0 && (
            <LoadingButton
              onClick={openDeleteTravelHistoryModal}
              variant="outlined"
              size="medium"
              color="error"
              startIcon={<DeleteIcon />}
            >
              Delete
            </LoadingButton>
          )}
        </Box>
      </Box>
      <DCTable
        initialOrder={{ order: "desc", orderBy: "arrival_date_parsed" }}
        columns={[
          {
            id: "arrival_loc_label",
            title: "Port Of Entry",
            children: (row: ITravelHistoryExtended) => (
              <Box>{row.arrival_loc_label || <MissingValue />}</Box>
            ),
          },
          {
            id: "departure_loc_label",
            title: "Port Of Exit",
            children: (row: ITravelHistoryExtended) => (
              <Box>{row.departure_loc_label || <MissingValue />}</Box>
            ),
          },
          {
            id: "visa_status",
            title: "Visa Type",
            children: (row: ITravelHistoryExtended) => (
              <Box>{row.visa_status || <MissingValue />}</Box>
            ),
          },
          {
            id: "arrival_date",
            title: "Arrival Date",
            children: (row: ITravelHistoryExtended) => (
              <Box>
                {row.arrival_date_parsed ? (
                  format(row.arrival_date_parsed, "MM-dd-yyyy")
                ) : (
                  <MissingValue />
                )}
              </Box>
            ),
          },
          {
            id: "departure_date",
            title: "Departure Date",
            children: (row: ITravelHistoryExtended) =>
              row.isPresent ? (
                <CurrentlyInTheCountry />
              ) : (
                <Box>
                  {row.departure_date_parsed ? (
                    format(row.departure_date_parsed, "MM-dd-yyyy")
                  ) : (
                    <MissingValue />
                  )}
                </Box>
              ),
          },
          {
            id: "day_count",
            title: "Day Count",
            children: (row: ITravelHistoryExtended) =>
              row.isPresent ? (
                <Box>
                  {row.arrival_date_parsed ? (
                    <>
                      {differenceInDays(new Date(), row.arrival_date_parsed)}+
                    </>
                  ) : (
                    <MissingValue />
                  )}
                </Box>
              ) : (
                <Box>
                  {row.departure_date_parsed && row.arrival_date_parsed ? (
                    differenceInDays(
                      row.departure_date_parsed,
                      row.arrival_date_parsed
                    ) + 1
                  ) : (
                    <MissingValue />
                  )}
                </Box>
              ),
          },
          {
            id: "type",
            title: "Type",
            children: (row: ITravelHistoryExtended) => (
              <Box>{row.automated ? "Auto" : "Manual"}</Box>
            ),
          },
        ]}
        rowStyle={(t: ITravelHistoryExtended) =>
          t.automated ? { backgroundColor: "rgba(69, 93, 238, 0.08)" } : {}
        }
        data={filteredTravels}
        disablePagination={true}
        onEditClick={openEditTravelHistoryModalFor}
        hasCheckbox={true}
        selectedRows={selectedRowData.selectedRowsWorld}
        allSelected={selectedRowData.allSelectedWorld}
        onSelectRow={(l, a) => {
          const isFilterApplied =
            filteredTravels.length < worldTravelRecords.length;

          if (isFilterApplied) {
            const selectedRowsWorld: string[] = filter(
              l,
              (id) => !!find(filteredTravels, (r) => r.id === id)
            );
            setSelectedRowData((prev) => ({
              ...prev,
              selectedRowsWorld,
              allSelectedWorld: a,
              deleteAllWorldRecords: false,
            }));
          } else {
            const selectedRowsWorld: string[] = filter(
              l,
              (id) => !!find(worldTravelRecords, (r) => r.id === id)
            );
            setSelectedRowData((prev) => ({
              ...prev,
              selectedRowsWorld,
              allSelectedWorld: a,
              deleteAllWorldRecords: a,
            }));
          }
        }}
      />
    </DCPaper>
  );
};

export const TravelsTableNew = (props: Props) => {
  return props.travelState === "usa" ? (
    <UsaTravelsTableNew />
  ) : (
    <WorldTravelsTableNew />
  );
};
