import { useEffect, useState } from "react";
import { useAuth } from "../../contexts/AuthContext";
import { ImageSource, blobToFile } from "@pqina/pintura";

export const useFetchDocumentFromURL = ({
  url,
  isSignedURL,
  fileFormat,
}: {
  url: string;
  isSignedURL: boolean;
  fileFormat: "pdf" | "image";
}) => {
  const [src, setSrc] = useState("" as ImageSource);
  const [isLoading, setIsLoading] = useState(false);
  const { currentUser } = useAuth();

  // reset state
  useEffect(() => {
    setIsLoading(false);
    setSrc("");
  }, [url]);

  useEffect(() => {
    async function init() {
      setIsLoading(true);
      const headers = new Headers();
      if (!isSignedURL && currentUser) {
        const token = await currentUser.getIdToken();
        headers.set("Authorization", `Bearer ${token}`);
      }
      const response = await fetch(url, { headers });
      const blob = await response.blob();
      if (fileFormat === "image") {
        const objectUrl = URL.createObjectURL(blob);
        setSrc(objectUrl);
      } else {
        try {
          const file = blobToFile(blob, "");
          setSrc(file);
        } catch (err) {
          console.error(err);
        }
      }
      setIsLoading(false);
    }
    init();
  }, [currentUser, fileFormat, isSignedURL, url]);

  return { src, isLoading };
};
