import React, { FC, useEffect, useMemo, useRef, useState } from "react";
import usePlacesService from "react-google-autocomplete/lib/usePlacesAutocompleteService";
import { getAppConfig } from "../../utils/environment";
import {
  Autocomplete,
  AutocompleteInputChangeReason,
  TextField,
  TextFieldProps,
} from "@mui/material";
import { debounce } from "lodash";
import { DCAutocomplete, DCAutocompleteTextField } from "../../assets/theme/theme";

const styles = {
  root: {
    width: "100%",
    color: "red",
  },
  input: {
    color: "blue",
  },
  menu: {
    backgroundColor: "green",
  },
  paper: {
    backgroundColor: "yellow",
  },
  popover: {
    backgroundColor: "orange",
  },
};

interface Props {
  defaultValue?: string;
  inputProps?: TextFieldProps;
  onChange(value: string, address: any): void;
}
export const DCAddressAutocomplete: FC<Props> = ({
  defaultValue,
  inputProps,
  onChange,
}) => {
  const firstTime = useRef(true);
  const [value, setValue] = useState<any>(null);
  const [inputValue, setInputValue] = useState(defaultValue || "");
  const config = useMemo(() => getAppConfig().googleMaps, []);
  const { placePredictions, getPlacePredictions } = usePlacesService({
    apiKey: config.API_KEY,
  });

  const onSearch = (val: string, reason: AutocompleteInputChangeReason) => {
    if (firstTime.current && defaultValue) {
      val = defaultValue;
      firstTime.current = false;
    }

    if (!(reason === "reset" && !val)) {
      setInputValue(val);
    }

    if (val) {
      getPlacePredictions({ input: val });
    }
  };

  const onSelect = (addressData: any) => {
    setValue(addressData?.id);
    onChange(addressData?.description, addressData);
  };

  return (
    <DCAutocomplete
      value={value}
      onChange={(_, addressData: any) => onSelect(addressData)}
      inputValue={inputValue}
      onInputChange={(_, inputValue, reason) => onSearch(inputValue, reason)}
      options={placePredictions}
      getOptionLabel={(o: any) => o?.description || ""}
      isOptionEqualToValue={(o:any) => (o ? o.id === value : false)}
      renderInput={(params) => <DCAutocompleteTextField {...params} {...inputProps} />}
      sx={inputProps?.sx}
    />
  );
};
