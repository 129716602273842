import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  FormControl,
  FormHelperText,
  InputLabel,
  Box,
  Typography,
  Grid,
  Hidden,
  Divider,
  Stack,
} from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import theme, { DcInput, DCLoadingButton } from "../../assets/theme/theme";
import { IDocument, IDocumentField } from "../../firebaseTypes";
import { get, isEmpty, map } from "lodash";
import {
  getDocName,
  getFieldDisplayValue,
  getFieldValue,
  getSimplifiedDocFormat,
  isDateField,
} from "../../utils/documentUtils";
import { useDocumentModalContext } from "../../contexts/DocumentModalContext";
import "react-calendar/dist/Calendar.css";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DesktopDatePicker from "@mui/lab/DesktopDatePicker";
import constants from "../../constants";
import { analytics } from "../../firebase";
import { logEvent } from "firebase/analytics";
import { isEmptyStr, isValidDateString } from "../../utils/utils";
import { useDocumentContext } from "../../contexts/DocumentContext";
import { usePDFMergeContext } from "../../contexts/PDFMergerContext";
import { usePreventRefreshDialogContext } from "../../contexts/PreventRefreshDialogContext";
import { useFetchDocumentFromURL } from "../Shared/FetchDocumentFromUrl";
import { PinturaImageViewer } from "../Shared/PinturaImageViewer";
import { PinturaPDFViewer } from "../Shared/PinturaPDFViewer";

export interface Props {}

export interface IBaseFieldProps {
  field: IDocumentField;
  index: number;
}

export interface ICalendarFieldProps extends IBaseFieldProps {}

export interface ITextFieldProps extends IBaseFieldProps {}

const CalendarField: React.FC<ICalendarFieldProps> = ({ field, index }) => {
  const [openPicker, setOpenPicker] = React.useState(false);
  const value = getFieldValue(field);
  const parsedDate = isValidDateString(value)
    ? new Date(value.replace(/-/g, "/"))
    : null;
  const { onChange } = useDocumentModalContext();
  const displayValue = getFieldDisplayValue(field);
  const isRequired = field.mandatory === 1;
  const error = isRequired && isEmpty(displayValue);

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <FormControl variant="standard" error={!!error}>
        <InputLabel
          error={error}
          required={isRequired}
          shrink
          htmlFor="bootstrap-input"
        >
          {field.title} &nbsp;
          {field.manual ? (
            <Box
              component="span"
              sx={{
                color: theme.palette.primary.main,
                fontWeight: 600,
              }}
            >
              (Modified)
            </Box>
          ) : null}
        </InputLabel>
      </FormControl>
      <DesktopDatePicker
        open={openPicker}
        onClose={() => setOpenPicker(false)}
        value={parsedDate}
        inputFormat={constants.dateFormat}
        disableOpenPicker
        onChange={(newValue) => {
          var dateStr = "";
          if (newValue) {
            const month = newValue.getMonth() + 1;
            const monthPrefix = month < 10 ? "0" : "";
            const date = newValue.getDate();
            const datePrefix = date < 10 ? "0" : "";
            const year = newValue.getFullYear();
            dateStr = `${year}-${monthPrefix}${month}-${datePrefix}${date}`;
          }
          onChange(index, dateStr);
        }}
        renderInput={({ inputRef, inputProps, InputProps }) => (
          <Box sx={{ display: "flex", alignItems: "center" }}>
            {/* @ts-ignore */}
            <DcInput
              {...inputProps}
              ref={inputRef}
              fullWidth
              readOnly
              placeholder="DD/MM/YYYY"
              onClick={() => setOpenPicker(true)}
            />
            {InputProps?.endAdornment}
          </Box>
        )}
      />
      {error && <FormHelperText error={error}>Required</FormHelperText>}
    </LocalizationProvider>
  );
};

const PlainTextField: React.FC<ITextFieldProps> = ({ field, index }) => {
  const { onChange } = useDocumentModalContext();
  const displayValue = getFieldDisplayValue(field);
  const isRequired = field.mandatory === 1;
  const error = isRequired && isEmpty(displayValue);

  return (
    <FormControl variant="standard" error={!!error} className="document-edit-form">
      <InputLabel
        error={error}
        required={isRequired}
        shrink
        htmlFor="bootstrap-input"
      >
        {field.title} &nbsp;
        {field.manual ? (
          <Box
            component="span"
            sx={{
              color: theme.palette.primary.main,
              fontWeight: 600,
            }}
          >
            (Modified)
          </Box>
        ) : null}
      </InputLabel>

      <DcInput
        error={error}
        placeholder={field.title}
        defaultValue={displayValue}
        onChange={(evt) => onChange(index, evt.target.value)}
        fullWidth
        multiline
      />
      {error && <FormHelperText error={error}>Required</FormHelperText>}
    </FormControl>
  );
};

const DocumentModal: React.FC<Props> = () => {
  const {
    documentQueue,
    index,
    isSavingDocument,
    openDocumentModalState,
    next,
    docInfo,
    downloadURL,
    closeDocumentModal,
    saveDocument,
  } = useDocumentModalContext();
  const { downloadDocument, getDocDownloadUrl } = useDocumentContext();
  const { print, isPrintProcessing } = usePDFMergeContext();
  const { showPreventRefreshDialog, hidePreventRefreshDialog } =
    usePreventRefreshDialogContext();
  const [isDownloadingDoc, setIsDownloadingDoc] = useState(false);

  const [document, setDocument] = React.useState(null as IDocument | null);
  const simpleDocFormat = getSimplifiedDocFormat(document?.doc_format);
  const { src, isLoading } = useFetchDocumentFromURL({
    url: downloadURL,
    isSignedURL: false,
    fileFormat: simpleDocFormat,
  });

  React.useEffect(() => {
    setDocument(get(documentQueue, [index], null));
  }, [documentQueue, index]);

  const isSaveDisabled = useCallback(() => {
    for (let i = 0; i < docInfo.length; i++) {
      if (
        docInfo[i].mandatory === 1 &&
        isEmptyStr(getFieldDisplayValue(docInfo[i]))
      ) {
        return true;
      }
    }
    return false;
  }, [docInfo]);

  const onSaveClick = useCallback(async () => {
    await saveDocument(document as IDocument, docInfo);
    logEvent(analytics, "click_save", {
      component: "document_edit_modal",
      document: JSON.stringify(document),
    });
  }, [document, docInfo, saveDocument]);

  const onDownloadClick = useCallback(async () => {
    setIsDownloadingDoc(true);
    document && (await downloadDocument(document));
    setIsDownloadingDoc(false);
  }, [document, downloadDocument]);

  const onPrintClick = useCallback(async () => {
    if (document) {
      const docs = [document];
      const selectedPdf = docs.filter(
        (img) => img.doc_format === "application/pdf"
      );
      const selectedImg = docs.filter(
        (img) =>
          img.doc_format === "image/jpeg" ||
          img.doc_format === "image/png" ||
          img.doc_format === "image/jpg"
      );

      const downloadURLs = map(selectedPdf, (d) => getDocDownloadUrl(d));

      const downloadImgURLs = map(selectedImg, (d) => getDocDownloadUrl(d));

      await print(downloadURLs, downloadImgURLs);
    }
  }, [document, print, getDocDownloadUrl]);

  const onClose = useCallback(
    (evt: any, reason: string) => {
      // reason could be "backdropClick" | "escapeKeyDown"
      if (reason === "escapeKeyDown" || reason === "backdropClick") {
        return;
      }
      closeDocumentModal();
      logEvent(analytics, "click_close", {
        component: "document_edit_modal",
        document: JSON.stringify(document),
      });
    },
    [closeDocumentModal, document]
  );

  useEffect(() => {
    if (openDocumentModalState)
      showPreventRefreshDialog(
        "All the changes made will be lost, are you sure want to continue?"
      );
    else hidePreventRefreshDialog();
  }, [
    hidePreventRefreshDialog,
    showPreventRefreshDialog,
    openDocumentModalState,
  ]);

  // return null
  if (!document) {
    return null;
  }

  return (
    <Dialog
      className="view-document-form-main"
      disableEscapeKeyDown
      open={openDocumentModalState}
      onClose={onClose}
      scroll="paper"
      maxWidth="lg"
      fullWidth
      aria-labelledby="view document popup"
      aria-describedby="view document popup"
      sx={{
        "& .MuiDialog-container": {
          "& .MuiPaper-root": {
            maxHeight: "90%",
          },
        },
        ".PinturaUtilMain, .PinturaUtilPanel, .PinturaUtilFooter": {
          padding: 0,
        },
        ".PinturaRootWrapper": {
          display: "flex",
          alignItems: "flex-start",
          padding: "0 10px",
        },
      }}
    >
      <DialogTitle>
        Document Edit{" "}
        <Typography
          sx={{
            font: "inherit",
            fontWeight: 600,
            color: theme.palette.primary.main,
          }}
          component="span"
        >
          {getDocName(document)}{" "}
          {documentQueue.length > 1 &&
            `${index + 1} of ${documentQueue.length}`}
        </Typography>
      </DialogTitle>
      <DialogContent
        className="document-edit-wrapper"
        sx={{
          paddingRight: "0 !important",
          overflow: "hidden",
          height: "600px",
        }}
      >
        <Grid
          className="document-edit-section"
          container
          wrap="nowrap"
          sx={{
            minHeight: "100%",
          }}
        >
          <Grid
          className="document-edit-main"
            item
            sm={7}
            sx={{
              height: "600px",
              position: "relative",
              overflow: "auto",
            }}
          >
            <Box
              sx={{
                height: "100%",
              }}
            >
              {!isLoading && simpleDocFormat === "pdf" ? (
                <PinturaPDFViewer
                  src={src}
                  printProps={{
                    isPrintProcessing: isPrintProcessing,
                    onPrintClick: onPrintClick,
                  }}
                  downloadProps={{
                    isDownloadingDoc: isDownloadingDoc,
                    onDownloadClick: onDownloadClick,
                  }}
                />
              ) : (
                <PinturaImageViewer src={src} disableEdit />
              )}
            </Box>
          </Grid>
          <Hidden mdDown>
            <Divider orientation="vertical" flexItem />
          </Hidden>
          <Grid
            className="document-edit-form-input"
            item
            xs={5}
            sx={{
              overflow: "auto",
              maxHeight: "calc(100vh - 302px)",
              minHeight: 650,
              paddingRight: 3,
            }}
          >
            <Stack
              className="document-edit-form-inner-section"
              direction="column"
              spacing={3}
              sx={{
                marginLeft: 6,
                marginBottom: 22,
              }}
            >
              {map(docInfo, (f, i) => {
                // check from title
                if (isDateField(f)) {
                  return (
                    <>
                      <CalendarField
                        key={`${document.id}-${i}`}
                        field={f}
                        index={i}
                      />
                    </>
                  );
                }

                return (
                  <>
                    <PlainTextField
                      key={`${document.id}-${i}`}
                      field={f}
                      index={i}
                    />
                  </>
                );
              })}
            </Stack>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          aria-label="close window"
          disabled={isSavingDocument}
          onClick={() => {
            window.document.documentElement.style.setProperty(
              "--editor-height",
              "100%"
            );
            closeDocumentModal();
          }}
        >
          Close
        </Button>
        <DCLoadingButton
          loadingPosition="start"
          loading={isSavingDocument}
          aria-label="save document"
          autoFocus
          variant="contained"
          color="primary"
          disabled={isSavingDocument || isSaveDisabled()}
          onClick={onSaveClick}
        >
          {next ? "Next" : "Save"}
        </DCLoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default DocumentModal;
