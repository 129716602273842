import { useMediaQuery } from "@mui/material";
import { logEvent } from "firebase/analytics";
import { get, isEmpty } from "lodash";
import React, { useEffect, useState } from "react";
import { Redirect } from "react-router-dom";
import theme from "../../assets/theme/theme";
import constants from "../../constants";
import { useAuth } from "../../contexts/AuthContext";
import { useDocumentContext } from "../../contexts/DocumentContext";
import { PDFMergerProvider } from "../../contexts/PDFMergerContext";
import { useUserContext } from "../../contexts/UserContext";
import { analytics } from "../../firebase";
import Spinner from "../Spinner";
// import MobileViewPage from "./MobileViewPage";
import { detectIncognito } from "detectincognitojs";
import PrivateWindowPage from "./PrivateWindowPage";

interface DCPageProps {
  layout: any;
  pageId: string;
  authRequired?: boolean; // by default true
  emailVerifyRequired?: boolean; // by default true
}

const DCPage: React.FC<DCPageProps> = (props) => {
  const { currentUser, isMultiFactorAuth } = useAuth();
  const { user, initializedUser } = useUserContext();
  const {
    documents,
    initializedDocumentsAndFolders: initalizedDocumentsAndFolders,
  } = useDocumentContext();
  const { children, emailVerifyRequired = true, authRequired = true } = props;
  const matchUpSm = useMediaQuery(theme.breakpoints.up("sm"));
  const [isPrivateWindow, setIsPrivateWindow] = useState(false);

  // set page title
  useEffect(() => {
    (document as any).title = get(
      constants.pageTitles,
      [props.pageId],
      "DocuComb"
    );
    logEvent(analytics, "page_view", {
      page_title: props.pageId,
    });
  }, [props.pageId]);

  // detect incognito
  useEffect(() => {
    detectIncognito().then((result) => {
      setIsPrivateWindow(result.isPrivate);
    });
  }, []);

  // render private window page
  if (isPrivateWindow) {
    return <PrivateWindowPage pageId={props.pageId} />;
  }

  // render mobile view page
  // if (!matchUpSm) {
  //   logEvent(analytics, "mobile_view", {
  //     page_title: props.pageId,
  //   });
  //   return <MobileViewPage />;
  // }

  // render public pages
  if (!authRequired) {
    return (
      <props.layout pageId={props.pageId}>
        <PDFMergerProvider>{children}</PDFMergerProvider>
      </props.layout>
    );
  }

  // simple logic - currentuser & user & documents are available when apis return
  // goto email-verify if required, depends on currentUser
  // goto profile-setup if required, depends on user
  // goto /documents if required, depends on documents
  // goto component if required

  if (currentUser) {
    // apperantly emailVerified is a strange variable, it is true for emailVerify page
    const emailVerified = emailVerifyRequired
      ? currentUser.emailVerified
      : true;

    if (
      isMultiFactorAuth === null &&
      props.pageId !== constants.pages.enrollMFA
    ) {
      console.log("Redirecting to enroll-mfa");
      
      return <Redirect to="/enroll-mfa" />;
    }
    if (!emailVerified && props.pageId !== constants.pages.emailVerify) {
      return <Redirect to="/email-verify" />;
    }
    if (props.pageId === constants.pages.emailVerify) {
      return <props.layout pageId={props.pageId}>{children}</props.layout>;
    }
    if (props.pageId === constants.pages.enrollMFA) {
      return <props.layout pageId={props.pageId}>{children}</props.layout>;
    }
    if (props.pageId === constants.pages.userProfileSetup) {
      return <props.layout pageId={props.pageId}>{children}</props.layout>;
    }
    if (!initializedUser && !initalizedDocumentsAndFolders) {
      // waiting for users & documents
      return <Spinner />;
    }
    if (
      initializedUser &&
      isEmpty(user?.firstName) &&
      props.pageId !== constants.pages.userProfileSetup &&
      isMultiFactorAuth !== null
    ) {
      return <Redirect to="/profile/setup" />;
    }
    if (
      initalizedDocumentsAndFolders &&
      documents.length === 0 &&
      props.pageId === constants.pages.dashboard
    ) {
      return <Redirect to="/documents" />;
    }

    if (initializedUser && initalizedDocumentsAndFolders) {
      return (
        <props.layout pageId={props.pageId}>
          <PDFMergerProvider>{children}</PDFMergerProvider>
        </props.layout>
      );
    }

    return <Spinner />;
  }
  return <Redirect to="/login" />;
};

export default DCPage;
