import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  ITravelHistory,
  ITravelHistoryExtended,
  LoadingStatus,
} from "../../firebaseTypes";
import { useAuth } from "../../contexts/AuthContext";
import { DataSnapshot, get, onValue, ref } from "firebase/database";
import { rtdb } from "../../firebase";
import { isObject } from "../../utils/utils";
import { debounce } from "lodash";
import { airportList } from "../../utils/travelHistoryUtils";
import { usaAirportList } from "../../utils/travelHistoryUtils";
import { isValidDateString } from "../../utils/utils";
import { useDocumentUploadContext } from "../../contexts/DocumentUploadContext";

export interface ITravelHistoryContextNew {
  usaTravelRecords: ITravelHistoryExtended[];
  worldTravelRecords: ITravelHistoryExtended[];
  loadingStatusUSATravels: LoadingStatus;
  loadingStatusWorldTravels: LoadingStatus;
  setLoadingStatusUSATravels: React.Dispatch<
    React.SetStateAction<
      "loading" | "empty-results" | "results" | "error" | "none"
    >
  >;
  setLoadingStatusWorldTravels: React.Dispatch<
    React.SetStateAction<
      "loading" | "empty-results" | "results" | "error" | "none"
    >
  >;
  getUsaTravelRecords: () => Promise<void>;
  getWorldTravelRecords: () => Promise<void>;
}

const defaultValues: ITravelHistoryContextNew = {
  usaTravelRecords: [],
  worldTravelRecords: [],
  loadingStatusUSATravels: "loading",
  loadingStatusWorldTravels: "loading",
  setLoadingStatusUSATravels: () => {},
  setLoadingStatusWorldTravels: () => {},
  getUsaTravelRecords: async () => {},
  getWorldTravelRecords: async () => {},
};

const context = createContext(defaultValues);

export function useTravelHistoryContextNew() {
  return useContext(context);
}

const getDate = (val: string) => {
  if (!val || val === "none" || !isValidDateString(val)) {
    return null;
  }
  return isValidDateString(val) ? new Date(val.replace(/-/g, "/")) : null;
};

const checkError = (t: ITravelHistory) => {
  return (
    t.arrival_loc === "Unavailable" ||
    t.arrival_loc === "none" ||
    t.departure_loc === "Unavailable" ||
    t.departure_loc === "none" ||
    t.arrival_date === "none" ||
    t.departure_date === "none" ||
    t.arrival_date === null ||
    t.departure_date === null ||
    t.arrival_loc === null ||
    t.departure_loc === null
  );
};

const airportCodeToLabel = (
  code: String,
  travelState: "usa" | "world"
): String => {
  const airports = travelState === "usa" ? usaAirportList : airportList;
  const airport = airports.find((a) => a.airport_code === code);

  return airport ? airport.label : "";
};

export const TravelHistoryProviderNew: React.FC = (props) => {
  const { uploadingUSATravelHistory, setUploadingUSATravelHistory } =
    useDocumentUploadContext();
  const { currentUser } = useAuth();
  const [usaTravelRecords, setUsaTravelRecords] = useState(
    defaultValues.usaTravelRecords
  );
  const [worldTravelRecords, setWorldTravelRecords] = useState(
    defaultValues.worldTravelRecords
  );
  const [loadingStatusUSATravels, setLoadingStatusUSATravels] = useState(
    defaultValues.loadingStatusUSATravels
  );
  const [loadingStatusWorldTravels, setLoadingStatusWorldTravels] = useState(
    defaultValues.loadingStatusUSATravels
  );
  const userID = useMemo(() => currentUser?.uid, [currentUser?.uid]);

  useEffect(() => {
    if (uploadingUSATravelHistory) {
      setLoadingStatusUSATravels("loading");
    }
  }, [uploadingUSATravelHistory]);

  const _debouncedOnValueUsa = async (snapshot: DataSnapshot) => {
    setLoadingStatusUSATravels("loading");
    if (snapshot.exists()) {
      setUsaTravelRecords([]);
      const tokenized_usaTravels: ITravelHistoryExtended[] = [];
      snapshot.forEach((data: any) => {
        const val = data.val();
        if (isObject(val)) {
          tokenized_usaTravels.push({
            id: data.key,
            arrival_date_parsed: getDate(val.arrival_date),
            departure_date_parsed:
              val.departure_date === "Present" || val.isPresent
                ? new Date()
                : getDate(val.departure_date),
            hasError: checkError(val),
            isPresent: val.departure_date === "Present" || val.isPresent,
            country: "USA",
            arrival_loc_label: airportCodeToLabel(val.arrival_loc, "usa"),
            departure_loc_label: airportCodeToLabel(val.departure_loc, "usa"),
            ...val,
          });
        }
      });
      setUsaTravelRecords(tokenized_usaTravels);
      if (tokenized_usaTravels.length > 0) {
        setLoadingStatusUSATravels("results");
        setUploadingUSATravelHistory(false);
      } else {
        setLoadingStatusUSATravels("empty-results");
      }
    } else {
      setUsaTravelRecords([]);
      setLoadingStatusUSATravels("empty-results");
    }
  };

  const _debouncedOnValueWorld = async (snapshot: DataSnapshot) => {
    setLoadingStatusWorldTravels("loading");

    if (snapshot.exists()) {
      setWorldTravelRecords([]);
      const tokenized_worldTravels: ITravelHistoryExtended[] = [];
      snapshot.forEach((data: any) => {
        const val = data.val();
        if (isObject(val)) {
          tokenized_worldTravels.push({
            id: data.key,
            arrival_date_parsed: getDate(val.arrival_date),
            departure_date_parsed:
              val.departure_date === "Present" || val.isPresent
                ? new Date()
                : getDate(val.departure_date),
            hasError: checkError(val),
            isPresent: val.departure_date === "Present" || val.isPresent,
            // country: "US",
            arrival_loc_label: airportCodeToLabel(val.arrival_loc, "world"),
            departure_loc_label: airportCodeToLabel(val.departure_loc, "world"),
            ...val,
          });
        }
      });

      setWorldTravelRecords(tokenized_worldTravels);
      if (tokenized_worldTravels.length > 0) {
        setLoadingStatusWorldTravels("results");
      } else {
        setLoadingStatusWorldTravels("empty-results");
      }
    } else {
      setWorldTravelRecords([]);
      setLoadingStatusWorldTravels("empty-results");
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedOnValueUsa = useCallback(
    debounce(_debouncedOnValueUsa, 5000, {
      leading: true,
    }),
    [currentUser]
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedOnValueWorld = useCallback(
    debounce(_debouncedOnValueWorld, 5000, {
      leading: true,
    }),
    [currentUser]
  );

  const fetchUsaTravelRecords = useCallback(() => {
    const tRef = ref(rtdb, `users/${userID}/usa-travel-records`);
    onValue(tRef, debouncedOnValueUsa);
  }, [userID, debouncedOnValueUsa]);

  const fetchWorldTravelRecords = useCallback(() => {
    const tRef = ref(rtdb, `users/${userID}/travel-records`);
    onValue(tRef, debouncedOnValueWorld);
  }, [userID, debouncedOnValueWorld]);

  const getUsaTravelRecords = useCallback(async () => {
    const tRef = ref(rtdb, `users/${userID}/usa-travel-records`);
    const snapshot = await get(tRef);
    _debouncedOnValueUsa(snapshot);
  }, [userID]);

  const getWorldTravelRecords = useCallback(async () => {
    const tRef = ref(rtdb, `users/${userID}/travel-records`);
    const snapshot = await get(tRef);
    _debouncedOnValueWorld(snapshot);
  }, [userID]);

  useEffect(() => {
    fetchUsaTravelRecords();
    fetchWorldTravelRecords();
  }, [fetchUsaTravelRecords, fetchWorldTravelRecords]);

  return (
    <context.Provider
      value={{
        usaTravelRecords,
        loadingStatusUSATravels,
        worldTravelRecords,
        loadingStatusWorldTravels,
        setLoadingStatusUSATravels,
        setLoadingStatusWorldTravels,
        getUsaTravelRecords,
        getWorldTravelRecords,
      }}
    >
      {props.children}
    </context.Provider>
  );
};
