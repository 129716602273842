import { Box, Button, Tab, Tabs, Tooltip, Typography } from "@mui/material";
import { logEvent } from "firebase/analytics";
import { analytics } from "../../firebase";
import AddIcon from "@mui/icons-material/Add";
import { useAddTravelHistoryModalContextNew } from "../../contexts/AddTravelHistoryModalContextNew";
import { UploadButton } from "../Shared/UploadButton";
import { FileRejection } from "react-dropzone";
import { useDocumentUploadContext } from "../../contexts/DocumentUploadContext";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { wrap } from "lodash";
import theme from "../../assets/theme/theme";

export interface ITravelHistoryTabsNewProps {
  travelState: "usa" | "world";
  setTravelState: React.Dispatch<React.SetStateAction<"usa" | "world">>;
}

export const TravelHistoryTabsNew = ({
  travelState,
  setTravelState,
}: ITravelHistoryTabsNewProps) => {
  const { openAddTravelHistoryModal } = useAddTravelHistoryModalContextNew();
  const { createUploadRequests, createUploadRequestsFromRejectedFiles } =
    useDocumentUploadContext();

  const onUpload = (acceptedFiles: File[], fileRejections: FileRejection[]) => {
    createUploadRequests(acceptedFiles, "documents");
    createUploadRequestsFromRejectedFiles(fileRejections, "documents");
  };

  return (
    <Box
      className="travel-history-tabs"
      position="relative"
      display="flex"
      justifyContent="space-between"
      alignItems="center"
    >
      <Box display="flex" my={3}>
        <Tabs value={travelState} aria-label="auto custom changer">
          <Tab 
            value="usa"
            label="USA Travels"
            onClick={() => {
              logEvent(analytics, "tab_change", {
                tab_name: "USA Travels",
                page_title: "TravelHistory",
              });
              setTravelState("usa");
            }}
          />
          <Tab
            value="world"
            label="World Travels"
            onClick={() => {
              logEvent(analytics, "tab_change", {
                tab_name: "World Travels",
                page_title: "TravelHistory",
              });
              setTravelState("world");
            }}
          />
        </Tabs>
      </Box>
      {travelState === "usa" && (
        <Box 
        sx={{
          '@media (max-width:600px)': {
            flexWrap: 'wrap',
          justifyContent:'center',
          },
        }}
        display="flex" justifyContent="flex-end" gap={2}>
          <Tooltip
            title={
              <Box display="flex" alignItems="center">
                <OpenInNewIcon sx={{ mr: 2 }} fontSize="small" />
                <Typography>
                  <a
                    href="https://i94.cbp.dhs.gov/I94/#/history-search"
                    target="_blank"
                    style={{ color: "white" }}
                    rel="noreferrer"
                  >
                    Download
                  </a>{" "}
                  your recent I94 document.
                </Typography>
              </Box>
            }
          >
            <span>
              <UploadButton  sx={{
              [theme.breakpoints.up('sm')]: {
                lineHight: 'normal'
              },
              [theme.breakpoints.up('md')]: {
                lineHight: 'normal'
              },
              [theme.breakpoints.up('lg')]: {
                lineHight: 'normal'
              },
            }}
            uploadOptions={{ onDrop: onUpload }}>
                Upload I94
              </UploadButton>
            </span>
          </Tooltip>

          <Tooltip
            title={
              <Box display="flex" alignItems="center">
                <OpenInNewIcon sx={{ mr: 2 }} fontSize="small" />
                <Typography>
                  <a
                    href="https://i94.cbp.dhs.gov/I94/#/history-search"
                    target="_blank"
                    style={{ color: "white" }}
                    rel="noreferrer"
                  >
                    Download
                  </a>{" "}
                  your recent Travel History.
                </Typography>
              </Box>
            }
          >
            <span>
              <UploadButton
                uploadOptions={{
                  onDrop: onUpload,
                  accept: {
                    "application/pdf": [".pdf"],
                  },
                }}
              >
                Upload USA Travel History
              </UploadButton>
            </span>
          </Tooltip>

          <Button
            variant="contained"
            color="primary"
            startIcon={<AddIcon />}
            onClick={openAddTravelHistoryModal}
          >
            Add USA Travel Log
          </Button>
        </Box>
      )}

      {travelState === "world" && (
        <Button
          sx={{
          [theme.breakpoints.up('xs')]: {
            marginBottom:'35px'
          },
          [theme.breakpoints.up('sm')]: {
            marginBottom:'0px'
          },
           }}
          variant="contained"
          color="primary"
          startIcon={<AddIcon />}
          onClick={openAddTravelHistoryModal}
        >
          Add World Travel Log
        </Button>
      )}
    </Box>
  );
};
